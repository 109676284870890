import { FC, Dispatch, SetStateAction } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCaretDown } from "react-icons/fa";
import { IDocument } from "../../../../services/api/documents";
import { downloadDocument } from "../utils/download";

interface IProps {
  cell: any;
  onReviewClick: Dispatch<SetStateAction<IDocument | null>>;
}

const ActionCell: FC<IProps> = ({ cell, onReviewClick }) => {
  const { t } = useTranslation("documents");

  const document: IDocument = cell.row.original;

  if (document.deletedAt) {
    return null;
  }

  return (
    <Dropdown>
      <Dropdown.Toggle size="sm" variant="outline-primary">
        <FaCaretDown />
      </Dropdown.Toggle>

      <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
        <Dropdown.Item onClick={() => onReviewClick(document)}>
          {t("review")}
        </Dropdown.Item>

        {!document.fileRemoved && (
          <Dropdown.Item
            onClick={() => downloadDocument(document.id, document.fileName)}
          >
            {t("download")}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { ActionCell };
