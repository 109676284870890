import { call, fork, put, take } from "typed-redux-saga";
import { translated } from "../../../i18n";
import { ApiResource } from "../../../services/api";
import { INotificationSettings } from "../../../services/api/notificationSettings";
import { notificationSettingsAPI } from "../../../services/api/notificationSettings";
import { showError, showSuccess } from "../../alert/store/slices";
import { loader } from "../../common/loaders/store/slices";
import { INotificationSettingsForm } from "../NotificationSettings";
import { notificationSettings } from "./slices";

function* notificationSettingsSaga() {
  yield* fork(fetchWatcher);
  yield* fork(updateWatcher);
}

function* fetchWatcher() {
  while (true) {
    yield take(notificationSettings.actions.fetchSettings);

    yield* call(fetchNotificationSettings);
  }
}

function* updateWatcher() {
  while (true) {
    const { payload } = yield take(notificationSettings.actions.updateSettings);

    yield* call(updateNotificationSettings, payload);
  }
}

function* fetchNotificationSettings() {
  const result: ApiResource<INotificationSettings> = yield* call(
    notificationSettingsAPI.fetchNotificationSettings
  );

  if (result && result.data) {
    yield put(notificationSettings.actions.setSettings(result.data));
  } else {
    yield put(showError(translated.errorWhileGettingSettings));
  }
}

function* updateNotificationSettings(data: INotificationSettingsForm) {
  yield put(loader.actions.startLoader());

  const result: ApiResource<INotificationSettings> = yield* call(
    notificationSettingsAPI.updateNotificationSettings,
    data
  );

  if (result && result.data) {
    yield put(notificationSettings.actions.setSettings(result.data));

    yield put(showSuccess(translated.settingsUpdated));
  } else {
    yield put(showError(translated.errorWhileUpdatingSettings));
  }

  yield put(loader.actions.stopLoader());
}

export { notificationSettingsSaga };
