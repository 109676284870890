import classNames from "classnames";
import { BsFillCaretUpFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Column, TableOptions } from "react-table";
import { AdminRoute } from "../../../../constants/routes";
import { adminFilesAPI, IFile } from "../../../../services/api/admin/files";
import store from "../../../../store";
import { formatDateTime } from "../../../../utils/date-format";
import { showError } from "../../../alert/store/slices";
import { ClientFilter } from "./filters/ClientFilter";
import { DateFilter } from "./filters/DateFilter";
import { DocumentDirectionFilter } from "./filters/DocumentDirectionFilter";
import { DocumentTypeFilter } from "./filters/DocumentTypeFilter";

const downloadDocument = async (id: number, fileName: string | null) => {
  try {
    const file = await adminFilesAPI.downloadDocument(id);

    if (file) {
      const link = window.document.createElement("a");

      link.href = window.URL.createObjectURL(file as any);
      link.download = fileName || "file";

      link.dispatchEvent(new MouseEvent("click"));
    }
  } catch (error) {
    store.dispatch(showError("File download failed."));
  }
};

const documentListColumn: Column<TableOptions<IFile>>[] = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "EDI ID",
    accessor: "documentId",
  },
  {
    Header: () => (
      <>
        <span>EDI client</span>
        <ClientFilter />
      </>
    ),
    accessor: "client",
    Cell: ({ cell }) => {
      const { client } = (cell.row.original as unknown) as IFile;

      return client ? <span>{client}</span> : <span>-</span>;
    },
  },
  {
    Header: () => (
      <>
        <span>EDI type</span>
        <DocumentTypeFilter />
      </>
    ),
    accessor: "documentType",
    Cell: ({ cell }) => {
      const { documentType } = (cell.row.original as unknown) as IFile;

      return documentType ? <span>{documentType}</span> : <span>-</span>;
    },
  },
  {
    Header: "Company AX code",
    accessor: "companyAxCode",
    Cell: ({ cell, value }) => {
      const { axStatusCode } = (cell.row.original as unknown) as IFile;

      return (
        <div className="company-code-column">
          <div>
            {value ? (
              <Link to={`${AdminRoute.Companies.List}?search=${value}`}>
                {value}
              </Link>
            ) : (
              <span>-</span>
            )}
          </div>
          <div>
            {axStatusCode ? (
              <span
                className={classNames(
                  "font-weight-bold",
                  axStatusCode === "N" && "text-success",
                  axStatusCode === "C" && "text-warning",
                  axStatusCode === "D" && "text-danger"
                )}
              >
                {axStatusCode}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    },
  },
  {
    Header: "Model",
    Cell: ({ cell }) => {
      const document = (cell.row.original as unknown) as IFile;

      if (!document.modelType || !document.model) {
        return <span>-</span>;
      }

      const modelType = document.modelType.replace("App\\", "");
      let route = "#";

      if (document.model) {
        if (modelType === "Project") {
          route = `${AdminRoute.Orders.List}?search=${document.model.ax_code}`;
        }

        if (modelType === "Application") {
          route = `${AdminRoute.Applications.List}?search=${document.model.id}`;
        }
      }

      return (
        <>
          <span>
            <Link to={route}>{modelType}</Link>
          </span>
          <span className="list-time">ID: {document.modelId}</span>
        </>
      );
    },
  },
  {
    Header: () => (
      <>
        <span>Direction</span>
        <DocumentDirectionFilter />
      </>
    ),
    accessor: "documentDirection",
    Cell: ({ cell }) => {
      const { documentDirection } = (cell.row.original as unknown) as IFile;

      return documentDirection ? (
        <span>{documentDirection}</span>
      ) : (
        <span>-</span>
      );
    },
  },
  {
    Header: () => (
      <>
        <span>
          <BsFillCaretUpFill /> Created at
        </span>
        <DateFilter />
      </>
    ),
    accessor: "createdAt",
    Cell: ({ cell }) => {
      const { createdAt } = (cell.row.original as unknown) as IFile;

      return <span>{formatDateTime(createdAt)}</span>;
    },
  },
  {
    Header: "File name",
    accessor: "fileName",
    Cell: ({ cell }) => {
      const document = (cell.row.original as unknown) as IFile;

      if (!document.fileName) {
        return <span>-</span>;
      }

      return (
        <Link
          to="#"
          onClick={() => downloadDocument(document.id, document.fileName)}
        >
          {cell.value}
        </Link>
      );
    },
  },
];

export { documentListColumn };
