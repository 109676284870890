import { useSelector } from "react-redux";
import { Workspace } from "../components/workspace-switcher/store/slice";

const useWorkspaceStatus = (workspace: Workspace) => {
  const currentWorkspace = useSelector(
    (state) => state.workspace.currentWorkspace
  );

  return currentWorkspace !== workspace;
};

export default useWorkspaceStatus;
