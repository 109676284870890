import { createSlice } from "@reduxjs/toolkit";

interface IState {}

const initialState: IState = {};

const adminMaintenance = createSlice({
  name: "adminMaintenance",
  initialState,
  reducers: {
    start: (state, action) => {
      //
    },
    stop: (state) => {
      //
    },
    reset: () => initialState,
  },
});

export { adminMaintenance };
