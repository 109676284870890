import { FC, useState, useCallback, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CarrierRoute } from "../../../../constants/routes";
import {
  ApplicationStatus,
  ApplicationType,
  IApplication,
} from "../../../../services/api/application";
import { ConfirmModal } from "../../../common/modals/ConfirmModal";
import { applications } from "../../store/slices";

interface IProps {
  cell: any;
}

const ActionCell: FC<IProps> = ({ cell }) => {
  const { t } = useTranslation("applicationList");

  const data: IApplication = cell.row.original;
  const id: number = data.id;
  const status = data.status ? data.status.code : "";
  const type = data.type ? data.type.code : "";
  const isDraft = status === ApplicationStatus.Open;

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const profile = useSelector((state) => state.profile);

  const deleteApplication = useCallback(
    (id: number) => dispatch(applications.actions.deleteApplication({ id })),
    [dispatch]
  );

  const handleDelete = () => {
    setDeleteModalVisible(false);

    deleteApplication(id);
  };

  const showLink = useMemo(() => {
    const link =
      type === ApplicationType.New
        ? CarrierRoute.Applications.ShowNewPartner
        : CarrierRoute.Applications.ShowExistingPartner;

    return type === ApplicationType.New
      ? link.replace(":applicationId", id.toString())
      : link.replace(":applicationId", id.toString());
  }, [type, id]);

  const editLink = useMemo(() => {
    const link =
      type === ApplicationType.New
        ? CarrierRoute.Applications.NewPartner
        : CarrierRoute.Applications.ExistingPartner;

    return type === ApplicationType.New
      ? link.replace(":applicationId", id.toString())
      : link.replace(":applicationId", id.toString());
  }, [type, id]);

  const editDisabled = profile.isManager;

  return (
    <>
      <Dropdown id="dropdown">
        <Dropdown.Toggle
          size="sm"
          variant="outline-primary"
          id="dropdown-toggle"
        >
          <FaCaretDown />
        </Dropdown.Toggle>

        <Dropdown.Menu popperConfig={{ strategy: "fixed" }}>
          {!!(isDraft && !editDisabled) ? (
            <>
              <Dropdown.Item onClick={() => history.push(editLink)}>
                {t("edit")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDeleteModalVisible(true)}>
                {t("delete")}
              </Dropdown.Item>
            </>
          ) : (
            <Dropdown.Item onClick={() => history.push(showLink)}>
              {t("review")}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      {isDraft && (
        <ConfirmModal
          question={t("deleteApplication") as string}
          isVisible={deleteModalVisible}
          handleDeny={() => setDeleteModalVisible(false)}
          handleConfirm={handleDelete}
        />
      )}
    </>
  );
};

export { ActionCell };
