import { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Accordion, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { AdminRoute } from "../../../constants/routes";
import { LanguageCode } from "../../../i18n";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import Editor from "../../editor/Editor";
import { Header } from "../../layout/Header";
import { adminStatisticPolicies } from "./store/slices";
import { StatisticGroup } from "../../../services/api/projects";

const StatisticPolicyStore = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedGroupCode, setSelectedGroupCode] = useState<StatisticGroup>(
    StatisticGroup.IKEA
  );

  const newPolicyPublished = useSelector(
    (state) => state.adminStatisticPolicies.newPolicyPublished
  );
  const isLoading = useSelector((state) => state.loader.isLoading);

  const storePolicy = useCallback(
    (groupCode: StatisticGroup) =>
      dispatch(adminStatisticPolicies.actions.storePolicy({ groupCode })),
    [dispatch]
  );

  const setNewPolicyData = useCallback(
    (languageCode: LanguageCode, html: string) =>
      dispatch(
        adminStatisticPolicies.actions.setNewPolicyData({ languageCode, html })
      ),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(adminStatisticPolicies.actions.resetState()),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  useEffect(() => {
    if (newPolicyPublished) {
      history.push(AdminRoute.StatisticPolicies.List);
    }
  }, [history, newPolicyPublished]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Header title="New policy" languageDisabled />
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ display: "flex" }}>
            <Form.Group controlId="group-code">
              <Form.Label>Select group:</Form.Label>
              <Form.Control
                name="group-code"
                as="select"
                value={selectedGroupCode}
                onChange={(e) =>
                  setSelectedGroupCode(e.target.value as StatisticGroup)
                }
              >
                {Object.values(StatisticGroup).map((code, index) => (
                  <option key={index} value={code}>
                    {code}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Accordion className="language-accordion">
            {Object.values(LanguageCode).map((languageCode) => (
              <Card key={languageCode}>
                <Accordion.Toggle as={Card.Header} eventKey={languageCode}>
                  <Link to="#">{languageCode.toUpperCase()}</Link>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={languageCode}>
                  <Card.Body>
                    <Editor
                      handleUpdate={(html) =>
                        setNewPolicyData(languageCode, html)
                      }
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="publish-wrapper">
            <ButtonLoader
              buttonDisabled={isLoading}
              disabled={isLoading}
              onClick={() => {
                if (selectedGroupCode) {
                  storePolicy(selectedGroupCode);
                }
              }}
            >
              Create
            </ButtonLoader>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StatisticPolicyStore;
