import { FC, useCallback, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { FilesTable } from "./table/FilesTable";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Header } from "../../layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { adminFiles } from "./store/slices";

const Files: FC = () => {
  const { search } = useLocation();
  const params = queryString.parse(search);

  const searchQuery = useSelector((state) => state.adminFiles.search);

  const dispatch = useDispatch();

  const handleSearch = useCallback(
    (query: string) => dispatch(adminFiles.actions.setSearch(query)),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(adminFiles.actions.reset()), [
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Container fluid>
      <Row>
        <Header
          title="Axapta files"
          handleSearch={handleSearch}
          initialSearch={(params.search as string) || searchQuery}
          languageDisabled
        />
      </Row>
      <Row>
        <FilesTable />
      </Row>
    </Container>
  );
};

export { Files };
