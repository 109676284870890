import { API, ApiResource, HTTPMethod, SuccessResponse } from ".";
import { log } from "../logger";
import queryString from "query-string";
import { IUser } from "./users";
import { IRegisterData } from "../../components/authentication/register/Register";
import { IResetPasswordData } from "../../components/authentication/forgot-password/ResetPassword";
import { ILoginData } from "../../components/authentication/login/Login";
import { IForgotPasswordData } from "../../components/authentication/forgot-password/ForgotPassword";

export interface ITokens {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

export interface IVerifyParameters {
  expires: string;
  hash: string;
  signature: string;
}

const hasTokens = (): boolean => {
  const jwtTokens = localStorage.getItem("jwt_tokens");

  if (jwtTokens) {
    try {
      const parsedTokens: ITokens | undefined = JSON.parse(jwtTokens);

      if (parsedTokens) {
        const { access_token, refresh_token } = parsedTokens;

        if (!!access_token && !!refresh_token) {
          return true;
        }
      }
    } catch (error) {
      log.error("Cannot parse JWT tokens", error);
    }
  }

  return false;
};

const getTokens = async ({ email, password }: ILoginData): Promise<ITokens> => {
  const formData = new FormData();

  formData.append("grant_type", "password");
  formData.append("scope", "*");
  formData.append("username", email);
  formData.append("password", password);

  const response = await API.request<ITokens>(
    `/oauth/token`,
    { method: HTTPMethod.POST },
    formData
  );

  return response;
};

const register = async (data: IRegisterData): Promise<ApiResource<IUser>> => {
  const response = await API.request<ApiResource<IUser>>(
    `/register`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const sendVerification = async (): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(`/verify`, {
    method: HTTPMethod.POST,
    version: "v2",
    jsonRequest: true,
  });

  return response;
};

const verify = async (
  userId: string,
  parameters: IVerifyParameters
): Promise<SuccessResponse> => {
  const params = queryString.stringify(parameters);

  const response = await API.request<SuccessResponse>(
    `/verify/${userId}?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const forgotPassword = async (
  data: IForgotPasswordData
): Promise<SuccessResponse> => {
  const params = queryString.stringify(data);

  const response = await API.request<SuccessResponse>(
    `/forgot-password?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const resetPassword = async (data: IResetPasswordData): Promise<any> => {
  const response = await API.request<any>(
    `/reset-password`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const authAPI = {
  hasTokens,
  getTokens,
  register,
  sendVerification,
  verify,
  forgotPassword,
  resetPassword,
};

export { authAPI };
