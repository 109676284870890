import { createSlice } from "@reduxjs/toolkit";

type UpdateBarState = {
  isVisible: boolean;
};

const initialState: UpdateBarState = {
  isVisible: false,
};

const updateBarSlice = createSlice({
  name: "updateBarSlice",
  initialState,
  reducers: {
    show: (state) => {
      state.isVisible = true;
    },
    reset: () => initialState,
  },
});

export default updateBarSlice;
