import { FC, useCallback } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { projectShow } from "../store/slices";
import { useTranslation } from "react-i18next";

enum Toggle {
  Planned = "planned-toogle",
  Confirmed = "confirmed-toggle",
}

const RouteToggle: FC = () => {
  const { t } = useTranslation("routeToggle");

  const dispatch = useDispatch();

  const isPlannedVisible = useSelector(
    (state) => state.projectShow.isPlannedVisible
  );
  const isConfirmedVisible = useSelector(
    (state) => state.projectShow.isConfirmedVisible
  );

  const setPlannedVisible = useCallback(
    (visible: boolean) =>
      dispatch(projectShow.actions.setPlannedVisible(visible)),
    [dispatch]
  );

  const setConfirmedVisible = useCallback(
    (visible: boolean) =>
      dispatch(projectShow.actions.setConfirmedVisible(visible)),
    [dispatch]
  );

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;
    const value = event.target.checked;

    switch (id) {
      case Toggle.Planned:
        setPlannedVisible(value);
        break;
      case Toggle.Confirmed:
        setConfirmedVisible(value);
        break;
    }
  };

  return (
    <Form inline className="route-toggle">
      <Form.Check
        type="switch"
        id={Toggle.Planned}
        label={t("planned")}
        checked={isPlannedVisible}
        onChange={handleSwitch}
      />
      <Form.Check
        type="switch"
        id={Toggle.Confirmed}
        label="GPS"
        checked={isConfirmedVisible}
        onChange={handleSwitch}
      />
    </Form>
  );
};

export { RouteToggle };
