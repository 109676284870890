import { FC, useCallback, useMemo } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CompaniesTable } from "./table/CompaniesTable";
import { showUserEmail } from "../utils/helpers";
import { adminUsers } from "../store/slices";
import { Header } from "../../../layout/Header";
import { Link, useParams } from "react-router-dom";
import { SubHeader } from "../../../layout/SubHeader";
import { AdminRoute } from "../../../../constants/routes";

const UserCompanies: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const user = useSelector((state) => state.adminUsers.userData);

  const dispatch = useDispatch();

  const search = useCallback(
    (search: string) =>
      dispatch(
        adminUsers.actions.setCompanySearch({
          search,
          userId,
        })
      ),
    [userId, dispatch]
  );

  const userEmail = useMemo(() => showUserEmail(user), [user]);

  return (
    <Container fluid>
      <Row>
        <Header
          title="User companies"
          subTitle={userEmail}
          handleSearch={search}
          languageDisabled
        />
        <SubHeader
          left={<div />}
          right={
            <Button
              as={Link}
              to={AdminRoute.Users.Companies.Assign.replace(":userId", userId)}
              style={{
                minWidth: "100px",
              }}
              className="assign-company-button"
            >
              Assign
            </Button>
          }
        />
      </Row>
      <Row>
        <CompaniesTable />
      </Row>
    </Container>
  );
};

export { UserCompanies };
