import { FC, useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaUserLock } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { LanguageCode } from "../../../i18n";
import { LanguagePills } from "../../common/language-pills/LanguagePills";
import { useDispatch, useSelector } from "react-redux";
import { policies } from "../store/slices";
import { Loader } from "../../common/loaders/Loader";

interface IProps {
  isVisible: boolean;
  handleDeny?: () => void;
}

const PrivacyPolicyModal: FC<IProps> = ({ isVisible, handleDeny }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("privacyPolicyModal");

  const [language, setLanguage] = useState<LanguageCode>(LanguageCode.EN);

  const policy = useSelector((state) => state.policies.privacyPolicy);
  const isLoading = useSelector((state) => state.policies.isLoading);

  const fetchPolicy = useCallback(
    () => dispatch(policies.actions.fetchPrivacyPolicy()),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(policies.actions.resetPrivacyPolicy()),
    [dispatch]
  );

  useEffect(() => {
    if (isVisible) {
      fetchPolicy();
    }

    return () => {
      reset();
    };
  }, [fetchPolicy, reset, isVisible]);

  return (
    <Modal
      show={isVisible}
      onHide={handleDeny}
      size="xl"
      className="private-policy-modal"
      scrollable
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          <FaUserLock /> {t("privacyPolicy")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LanguagePills
          onSelect={(language) => setLanguage(language as LanguageCode)}
          languages={Object.values(LanguageCode)}
          style={{
            textTransform: "uppercase",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        />
        <Loader isLoading={isLoading} />

        {policy && (
          <div dangerouslySetInnerHTML={{ __html: policy.data[language] }} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleDeny}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { PrivacyPolicyModal };
