import {
  call,
  fork,
  delay,
  put,
  select,
  take,
  takeLatest,
} from "typed-redux-saga";
import { translated } from "../../../../i18n";
import { CompanyRole } from "../../../../services/api/profile";
import { AppModule } from "../../../../services/api/settings";
import { usersAPI } from "../../../../services/api/users";
import { AppState } from "../../../../store";
import { showError, showSuccess } from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { profile } from "../../../profile/store/slices";
import { userList } from "./slices";

function* userListSaga() {
  yield* takeLatest(userList.actions.setSearch, debounceSearch);
  yield* fork(companyChangeListener);
  yield* fork(deleteListener);
  yield* fork(filterListener);
  yield* fork(perPageListener);
  yield* fork(paginationListener);
}

function* debounceSearch() {
  yield* delay(500);

  yield* call(fetchList);
}

function* companyChangeListener() {
  while (true) {
    const {
      payload: { appModule },
    } = yield take(profile.actions.setCompanyChanged);

    if (appModule === AppModule.Users) {
      yield put(userList.actions.resetPagination());

      yield* call(fetchList);
    }
  }
}

function* deleteListener() {
  while (true) {
    const { payload } = yield take(userList.actions.deleteUser);

    if (payload) {
      yield* call(deleteUser, payload);
    }
  }
}

function* filterListener() {
  while (true) {
    yield take(userList.actions.setFilter);

    yield* call(fetchList);
  }
}

function* perPageListener() {
  while (true) {
    yield take(userList.actions.setPerPage);

    yield* call(fetchList);
  }
}

function* paginationListener() {
  while (true) {
    yield take(userList.actions.setActivePage);

    yield* call(fetchList, true);
  }
}

function* fetchList(concatData?: boolean) {
  yield put(loader.actions.startLoader());

  const states = yield* select((state: AppState) => ({
    search: state.userList.search,
    filter: state.userList.filter,
    selectedCompany: state.profile.data?.selectedCompany,
    settings: state.settings.carrier,
    perPage: state.userList.perPage,
    page: state.userList.activePage,
  }));

  const { search, selectedCompany, perPage, page } = states;

  const settings = states.settings;
  const filter: CompanyRole | null = states.filter;

  let roleId = "";

  if (settings && filter) {
    const role = settings.users.companyRoles.find(
      (role) => role.code === filter
    );

    if (role) {
      roleId = role.id.toString();
    }
  }

  if (selectedCompany) {
    const result = yield* call(usersAPI.fetchList, selectedCompany.id, {
      search,
      roleIds: roleId,
      perPage,
      page,
    });

    if (result && result.data) {
      if (concatData) {
        yield put(userList.actions.concatData(result));
      } else {
        yield put(userList.actions.setData(result));
      }
    } else {
      yield put(showError(translated.errorWhileGettingUsers));
    }
  }

  yield put(loader.actions.stopLoader());
}

function* deleteUser(userId: string) {
  yield put(loader.actions.startLoader());

  const { selectedCompany } = yield* select((state: AppState) => ({
    selectedCompany: state.profile.data?.selectedCompany,
  }));

  if (selectedCompany) {
    const result = yield* call(usersAPI.deleteUser, selectedCompany.id, userId);

    if (result && result.success) {
      yield* call(fetchList);

      yield put(showSuccess(translated.userRemoved));
    } else {
      yield put(showError(translated.errorWhileDeletingUsers));
    }
  }

  yield put(loader.actions.stopLoader());
}

export { userListSaga };
