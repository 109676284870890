import { createSlice } from "@reduxjs/toolkit";
import {
  ClientCompany,
  IProjectShort,
  ProjectConfirmStatus,
  ProjectLocationTypeCode,
} from "../../../../../services/api/projects";
import { IPagination } from "../../../../../services/api";
import queryString from "query-string";

type DateRange = Date | null;

export interface IProjectListState {
  search: string;
  confirmStatus: ProjectConfirmStatus | null;
  locationStatus: ProjectLocationTypeCode | null;
  dateRange: DateRange[];
  onlyArchived: boolean;
  onlyAssigned: boolean;
  onlyIncomplete: boolean;
  selectedClientCompanyId: number | null;
  clientCompanies: ClientCompany[];
  clientCompaniesIsLoading: boolean;
}

export interface IProjectListGroupState {
  id: ProjectConfirmStatus;
  data: IProjectShort[];

  perPage: number;
  activePage: number;
  meta: IPagination | null;

  isLoading: boolean;
}

const initialState: IProjectListState = {
  search: "",
  confirmStatus: null,
  locationStatus: null,
  onlyArchived: false,
  onlyAssigned: false,
  onlyIncomplete: false,
  dateRange: [null, null],
  selectedClientCompanyId: null,
  clientCompanies: [],
  clientCompaniesIsLoading: false,
};

const generateState = (): IProjectListState => {
  const params = window.location.search;

  const { search } = queryString.parse(params);

  return {
    ...initialState,
    search: search && typeof search === "string" ? search : "",
  };
};

const projectList = createSlice({
  name: "projectList",
  initialState: generateState(),
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setConfirmStatus: (state, action) => {
      state.confirmStatus = action.payload;
    },
    setLocationStatus: (state, action) => {
      state.locationStatus = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setOnlyArchived: (state, action) => {
      state.onlyArchived = action.payload;
    },
    setOnlyAssigned: (state, action) => {
      state.onlyAssigned = action.payload;
    },
    setOnlyIncomplete: (state, action) => {
      state.onlyIncomplete = action.payload;
    },
    setClientCompanyId: (state, action) => {
      state.selectedClientCompanyId = action.payload;
    },
    fetchClientCompanies: (state) => {
      //
    },
    setClientCompanies: (state, action) => {
      state.clientCompanies = action.payload;
    },
    setClientCompaniesIsLoading: (state, action) => {
      state.clientCompaniesIsLoading = action.payload;
    },
    resetFilters: (state) => {
      state.selectedClientCompanyId = null;
      state.confirmStatus = null;
      state.locationStatus = null;
      state.dateRange = [null, null];
      state.onlyIncomplete = false;
      state.onlyAssigned = false;
      state.onlyArchived = false;
    },
    reset: (state) => {
      state = { ...initialState, clientCompanies: state.clientCompanies };
    },
  },
});

export { projectList };
