import { FC, Fragment, useCallback, useMemo } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TableOptions, useExpanded, useTable } from "react-table";
import { activityLogColumns } from "./columns";
import Pagination from "react-js-pagination";
import { adminActivityLog } from "../store/slices";
import { Loader } from "../../../common/loaders/Loader";
import { NoData } from "../../../common/no-data/NoData";
import { ShowPages } from "../../../common/show-pages/ShowPages";
import { ShowPagesInfo } from "../../../common/show-pages/ShowPagesInfo";
import { IActivityLogResponse } from "../../../../services/api/admin/activity-log";

const ActivityLogTable: FC = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.adminActivityLog.data);
  const activePage = useSelector((state) => state.adminActivityLog.activePage);
  const perPage = useSelector((state) => state.adminActivityLog.perPage);
  const meta = useSelector((state) => state.adminActivityLog.meta);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const setActivePage = useCallback(
    (page: number) => dispatch(adminActivityLog.actions.setActivePage(page)),
    [dispatch]
  );

  const setPerPage = useCallback(
    (pages: number) => dispatch(adminActivityLog.actions.setPerPage(pages)),
    [dispatch]
  );

  const renderRowSubComponent = useCallback(
    ({ row }: any) => (
      <pre>
        <code className={"code-block"}>
          {JSON.stringify(row.values, null, 2)}
        </code>
      </pre>
    ),
    []
  );

  const columns = useMemo(() => activityLogColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    visibleColumns,
  } = useTable<TableOptions<IActivityLogResponse>>(
    {
      columns,
      data,
      initialState: {
        pageIndex: activePage,
      },
    },
    useExpanded
  );

  return (
    <>
      <Table responsive striped bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <div className="list-loader">
                  <Loader />
                </div>
              </td>
            </tr>
          )}
          {rows.length === 0 && !isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <NoData />
              </td>
            </tr>
          )}
          {!isLoading &&
            rows.map((row, index) => {
              prepareRow(row);

              return (
                <Fragment key={index}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </Fragment>
              );
            })}
        </tbody>
      </Table>
      <div className="list-footer">
        <ShowPages perPage={perPage} setPerPage={setPerPage} />
        <ShowPagesInfo activePage={activePage} perPage={perPage} meta={meta} />
        <Pagination
          hideNavigation
          activePage={activePage}
          itemsCountPerPage={perPage}
          totalItemsCount={meta && meta.total ? meta.total : 0}
          pageRangeDisplayed={5}
          onChange={(page) => setActivePage(page)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </>
  );
};

export { ActivityLogTable };
