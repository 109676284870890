import { FC, useCallback, useEffect, useMemo } from "react";
import { Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TableOptions, usePagination, useSortBy, useTable } from "react-table";
import { companiesListColumn } from "./columns";
import { BsFillCaretDownFill } from "react-icons/bs";
import { BsFillCaretUpFill } from "react-icons/bs";
import { sortBy } from "./sortBy";
import Pagination from "react-js-pagination";
import { adminUsers } from "../../store/slices";
import { ICompany } from "../../../../../services/api/profile";
import { Loader } from "../../../../common/loaders/Loader";
import { NoData } from "../../../../common/no-data/NoData";

const CompaniesTable: FC = () => {
  const companies = useSelector((state) => state.adminUsers.companies);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const dispatch = useDispatch();

  const resetState = useCallback(
    () => dispatch(adminUsers.actions.resetCompaniesTable()),
    [dispatch]
  );
  const resetUser = useCallback(
    () => dispatch(adminUsers.actions.resetUser()),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      resetState();
      resetUser();
    };
  }, [resetState, resetUser]);

  const columns = useMemo(() => companiesListColumn(true), []);
  const defaultSortBy = useMemo(() => sortBy, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable<TableOptions<ICompany>>(
    {
      columns,
      data: companies,
      initialState: {
        sortBy: defaultSortBy,
        pageIndex: 0,
        pageSize: 10,
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <Table
        striped
        bordered
        responsive
        hover
        {...getTableProps()}
        className="users-table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <BsFillCaretDownFill />
                      ) : (
                        <BsFillCaretUpFill />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <div className="list-loader">
                  <Loader />
                </div>
              </td>
            </tr>
          )}
          {page.length === 0 && !isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <NoData />
              </td>
            </tr>
          )}
          {!isLoading &&
            page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="list-footer">
        <div className="show-pages">
          <span>Show</span>
          <Form.Control
            className="show-select"
            as="select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </Form.Control>
          <span>entries</span>
        </div>
        <div>
          Showing {pageIndex + 1} to {pageSize} of {companies.length} entries
        </div>
        <Pagination
          hideNavigation
          activePage={pageIndex + 1}
          itemsCountPerPage={pageSize}
          totalItemsCount={companies.length}
          pageRangeDisplayed={5}
          onChange={(page) => gotoPage(page - 1)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </>
  );
};

export { CompaniesTable };
