import { startCase } from "lodash";
import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "typed-redux-saga";
import {
  adminUsersAPI,
  IFetchUsersParams,
} from "../../../../../services/api/admin/users";
import { CompanyRole, Role } from "../../../../../services/api/profile";
import { AppState } from "../../../../../store";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../../alert/store/slices";
import { loader } from "../../../../common/loaders/store/slices";
import { adminUsers } from "../slices";

function* adminUserSagas() {
  yield* takeLatest(adminUsers.actions.setUserSearch, debounceSearch);
  yield* fork(userFetchListener);
  yield* fork(inviteUserListener);
  yield* fork(perPageListener);
  yield* fork(roleListener);
  yield* fork(companyRoleListener);
  yield* fork(paginationListener);
}

function* debounceSearch() {
  const data = yield* select((state: AppState) => state.adminUsers.usersData);

  if (data.length > 0) {
    yield delay(500);
  }

  yield* call(fetchUsers);
}

function* userFetchListener() {
  while (true) {
    const { payload: id } = yield take(adminUsers.actions.fetchUser);

    if (id) {
      yield* call(fetchUser, id);
    }
  }
}

function* inviteUserListener() {
  while (true) {
    const { payload: email } = yield take(adminUsers.actions.invite);

    if (email) {
      yield* call(invite, email);
    }
  }
}

function* perPageListener() {
  while (true) {
    yield take(adminUsers.actions.setUsersPerPage);

    yield* call(fetchUsers);
  }
}

function* roleListener() {
  while (true) {
    yield take(adminUsers.actions.setUsersRole);

    yield* call(fetchUsers);
  }
}

function* companyRoleListener() {
  while (true) {
    yield take(adminUsers.actions.setUsersCompanyRole);

    yield* call(fetchUsers);
  }
}

function* paginationListener() {
  while (true) {
    yield take(adminUsers.actions.setUsersActivePage);

    yield* call(fetchUsers);
  }
}

function* fetchUsers() {
  yield put(loader.actions.startLoader());

  const { userSearch, perPage, page, role, companyRole } = yield* select(
    (state: AppState) => ({
      userSearch: state.adminUsers.userSearch,
      role: state.adminUsers.usersRole,
      companyRole: state.adminUsers.usersCompanyRole,
      perPage: state.adminUsers.usersPerPage,
      page: state.adminUsers.usersActivePage,
    })
  );

  const requestParams: IFetchUsersParams = {
    search: userSearch,
    perPage,
    page,
    role: startCase(role) as Role,
    companyRole: startCase(companyRole) as CompanyRole,
  };

  const usersResponse = yield* call(adminUsersAPI.fetchUsers, requestParams);

  if (usersResponse && usersResponse.data && usersResponse.meta) {
    yield put(
      adminUsers.actions.setUsers({
        data: usersResponse.data,
        meta: usersResponse.meta,
      })
    );
  } else {
    yield put(showError("Something went wrong while getting users."));
  }

  yield put(loader.actions.stopLoader());
}

export function* fetchUser(id: string) {
  const userResponse = yield* call(adminUsersAPI.fetchUser, id);

  if (userResponse && userResponse.data) {
    yield put(adminUsers.actions.setUser(userResponse.data));
  } else {
    yield put(showError("Something went wrong while getting user."));
  }
}

function* invite(email: string) {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminUsersAPI.invite, email);

  if (response && response.data) {
    yield put(adminUsers.actions.setInvitedUser(response.data));

    yield put(showSuccess("User invited."));
  } else if (response && response.errors) {
    const firstErrorKey = Object.keys(response.errors)[0];

    yield put(
      showWarning(
        `${startCase(firstErrorKey)}: ${response.errors[firstErrorKey]}`
      )
    );
  } else {
    yield put(showError("Something went wrong while getting users."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminUserSagas };
