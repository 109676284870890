import { FC, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { IUser } from "../../../services/api/users";
import { CompanyRole, IRole } from "../../../services/api/profile";
import { userEdit } from "./store/slices";
import { CarrierRoute } from "../../../constants/routes";
import { Loader } from "../../common/loaders/Loader";
import { email } from "../../../constants/regex";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export interface IUserData {
  name: string;
  surname: string;
  email: string;
  phone: string;
  role: string;
}

interface IProps {
  user: IUser;
}

const UserEdit: FC<IProps> = ({ user }) => {
  const { t } = useTranslation("userEdit");

  const updated = useSelector((state) => state.userEdit.updated);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const settings = useSelector((state) => state.settings.carrier);
  const selectedCompany = useSelector(
    (state) => state.profile.data?.selectedCompany
  );

  const history = useHistory();

  const dispatch = useDispatch();

  const updateUser = useCallback(
    (data: IUserData) => dispatch(userEdit.actions.updateUser(data)),
    [dispatch]
  );

  const { register, errors, handleSubmit, setValue, watch } = useForm<
    IUserData
  >({
    defaultValues: {
      name: user.name || "",
      surname: user.surname || "",
      email: user.email,
      phone: user.phone || "",
    },
  });

  const onSubmit = (data: IUserData) => {
    updateUser(data);
  };

  useEffect(() => {
    if (user) {
      setValue([
        { name: user.name },
        { surname: user.surname },
        { email: user.email },
        { phone: user.phone || "" },
      ]);
    }
  }, [user, setValue]);

  useEffect(() => {
    if (updated) {
      history.push(
        CarrierRoute.Users.Show.replace(":userId", user.id.toString())
      );
    }
  }, [history, updated, user.id]);

  const selectedRoleId = watch("role");

  const selectedRole = useMemo(() => {
    if (settings) {
      const role = settings.users.companyRoles.find(
        (role) => role.id.toString() === selectedRoleId
      );

      if (role) {
        return role.code;
      }
    }

    return null;
  }, [settings, selectedRoleId]);

  if (!user || !settings || !selectedCompany) {
    return <Loader />;
  }

  let role: IRole<CompanyRole> | null = null;

  const company = user.companies.find(
    (company) => company.id === selectedCompany.id
  );

  if (company && company.roles && company.roles[0]) {
    role = company.roles[0];
  }

  return (
    <Col className="column" xs="12" md="8" lg="6" xl="4">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} controlId="name">
            <Form.Label>{t("firstName")}</Form.Label>
            <Form.Control
              name="name"
              type="text"
              ref={register({ required: false })}
              isInvalid={!!errors.name}
              maxLength={190}
              disabled
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.type === "required" && (t("requiredField") as any)}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="surname">
            <Form.Label>{t("lastName")}</Form.Label>
            <Form.Control
              name="surname"
              type="text"
              ref={register({ required: false })}
              isInvalid={!!errors.surname}
              maxLength={190}
              disabled
            />
            <Form.Control.Feedback type="invalid">
              {errors.surname?.type === "required" &&
                (t("requiredField") as any)}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="email">
            <Form.Label>{t("email")}</Form.Label>
            <Form.Control
              name="email"
              type="text"
              ref={register({ required: true, pattern: email })}
              isInvalid={!!errors.email}
              maxLength={190}
              disabled
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.type === "required" && (t("requiredField") as any)}
              {errors.email?.type === "pattern" && (t("invalidEmail") as any)}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="phone">
            <Form.Label>{t("phone")}</Form.Label>
            <Form.Control
              name="phone"
              type="text"
              ref={register({ required: false, minLength: 8 })}
              isInvalid={!!errors.phone}
              maxLength={190}
              disabled
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone?.type === "required" && (t("requiredField") as any)}
              {errors.phone?.type === "minLength" &&
                (t("tooShortNumber") as any)}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="role">
            <Form.Label>{t("role")}</Form.Label>
            <Form.Control
              name="role"
              as="select"
              ref={register({ required: true })}
              isInvalid={!!errors.role}
              defaultValue={role ? role.id : CompanyRole.Owner}
            >
              {Object.values(settings.users.companyRoles).map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Form.Control>
            {!!(
              selectedRole && [CompanyRole.Accountant].includes(selectedRole)
            ) && (
              <Form.Text className="text-danger">
                {t("accountantRoleHasReadOnly") as any}
              </Form.Text>
            )}
            <Form.Control.Feedback type="invalid">
              {errors.role?.type === "required" && "Required field."}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <div className="profile-buttons">
          <Button
            as={Link}
            variant="outline-secondary"
            to={CarrierRoute.Users.Show.replace(":userId", user.id.toString())}
            disabled={isLoading}
          >
            {t("cancel")}
          </Button>

          <Button variant="primary" type="submit" disabled={isLoading}>
            {t("save")}
          </Button>
        </div>
      </Form>
    </Col>
  );
};

export { UserEdit };
