import { FC } from "react";
import {
  IProjectConfirmStatus,
  ProjectConfirmStatus,
} from "../../../services/api/projects";

const StatusColor: { [key in ProjectConfirmStatus]: string } = {
  [ProjectConfirmStatus.WaitingForApproval]: "#17a2b8",
  [ProjectConfirmStatus.Confirmed]: "#3EB073",
  [ProjectConfirmStatus.Rejected]: "#ce181e",
  [ProjectConfirmStatus.ConfirmUpdate]: "#17a2b8",
  [ProjectConfirmStatus.Completed]: "#8BDC97",
};

interface IProps {
  status: IProjectConfirmStatus;
}

const StatusBadge: FC<IProps> = ({ status }) => {
  const color = StatusColor[status.code];

  return (
    <div className="confirm-status-title">
      <span style={{ fontSize: "12px", color }}>
        <span className={`dot`} style={{ backgroundColor: color }} />{" "}
        {status.name}
      </span>
    </div>
  );
};

export { StatusBadge };
