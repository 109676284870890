import { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import { AdminRoute } from "../../../../../constants/routes";
import { IUser } from "../../../../../services/api/users";
import { Role } from "../../../../../services/api/profile";

interface IProps {
  cell: any;
}

const ActionCell: FC<IProps> = ({ cell }) => {
  const user: IUser = cell.row.original;

  const isSuperAdmin = user.roles.find((role) => role.code === Role.SuperAdmin);
  const isManager = user.roles.find((role) => role.code === Role.Manager);

  return (
    <Dropdown id="dropdown">
      <Dropdown.Toggle size="sm" variant="outline-primary" id="dropdown-toggle">
        <FaCaretDown />
      </Dropdown.Toggle>

      <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
        <Dropdown.Item
          as={Link}
          to={`${AdminRoute.Users.EditRoles.replace(
            ":userId",
            user.id.toString()
          )}`}
          disabled={!!isSuperAdmin}
        >
          Manage roles
        </Dropdown.Item>
        {isManager && (
          <Dropdown.Item
            as={Link}
            to={`${AdminRoute.Users.EditDepartmentRoles.replace(
              ":userId",
              user.id.toString()
            )}`}
            disabled={!!isSuperAdmin}
          >
            Manage departments
          </Dropdown.Item>
        )}
        <Dropdown.Item
          as={Link}
          to={`${AdminRoute.Users.Companies.List.replace(
            ":userId",
            user.id.toString()
          )}`}
        >
          Manage companies
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { ActionCell };
