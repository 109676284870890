import { IChangePasswordData } from "../../components/profile/ChangePassword";
import { ApiResource, SuccessResponse, API, HTTPMethod } from ".";
import { IProfileData } from "../../components/profile/ProfileEdit";
import { IUser } from "./users";
import { LanguageCode } from "../../i18n";

export enum Role {
  SuperAdmin = "superAdmin",
  Admin = "admin",
  Carrier = "carrier",
  Manager = "carrierManager",
  CarrierAdmin = "carrierAdmin",
  Guest = "guest",
}

export enum CompanyRole {
  Owner = "owner", // Admin
  Manager = "manager", // Editor
  Accountant = "accountant", // Viewer
}

export interface IRole<T> {
  id: number;
  code: T;
  name: string;
}

export interface ICompanyLanguage {
  id: number;
  code: LanguageCode;
}

export interface ICompanyShort {
  id: number;
  name: string | null;
  axCode: string | null;
}

export interface ICompany extends ICompanyShort {
  code: string | null;
  vatCode: string | null;
  address: string | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
  approvedAt: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  applicationId: number | null;
  users?: IUser[];
  roles?: IRole<CompanyRole>[];
  language?: ICompanyLanguage;
  bankName: string;
  bankAccount: string;
  bankIban: string;
  bankSwift: string;
  bankCurrency: string;
  showQuestionnairePopup?: boolean;
}

export interface IWorkingConditions {
  id: number;
  text: string;
  enabled: boolean;
}

export enum SapEnv {
  DEV = "dev",
  QA = "qa",
}

export interface IProfile {
  id: number;
  name: string;
  surname: string;
  email: string;
  phone: string | null;
  companies: ICompany[];
  roles: IRole<Role>[];
  selectedCompany?: ICompany;
  forceUpdateProfile: boolean;
  forceUpdatePassword: boolean;
  showCarrierWizard: boolean;
  emailVerifiedAt: number;
  // privacyPolicyAccepted: boolean;
  termsPolicyAccepted: boolean;
  marketingPolicySubmitted: boolean | null;
  workingConditions?: IWorkingConditions;
  language: LanguageCode;
  isGroupLeader: boolean;
  isSapAccount: boolean;
  currentSapEnvironment: SapEnv | null;
}

const fetchProfile = async (): Promise<ApiResource<IProfile>> => {
  const response = await API.request<ApiResource<IProfile>>(`/profile`, {
    method: HTTPMethod.GET,
    version: "v2",
    jsonRequest: true,
  });

  return response;
};

const editProfile = async (
  data: IProfileData
): Promise<ApiResource<IProfile>> => {
  const response = await API.request<ApiResource<IProfile>>(
    `/profile`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({ ...data })
  );

  return response;
};

const deleteProfile = async (): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(`/profile`, {
    method: HTTPMethod.DELETE,
    version: "v2",
    jsonRequest: true,
  });

  return response;
};

const changePassword = async (
  data: IChangePasswordData
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/profile/change-password`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({ ...data })
  );

  return response;
};

const changeLanguage = async (code: LanguageCode): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/profile/change-language`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({ code })
  );

  return response;
};

const changeCompany = async (
  companyId: number
): Promise<ApiResource<ICompany>> => {
  const response = await API.request<ApiResource<ICompany>>(
    `/profile/company/${companyId}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const changeSapEnv = async (env: SapEnv): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/profile/change-sap-environment`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({ code: env })
  );

  return response;
};

const fetchCompanies = async (
  query: string,
  page: number
): Promise<ApiResource<ICompany>> => {
  const response = await API.request<ApiResource<ICompany>>(
    `/profile/companies?search=${query}&page=${page}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const profileAPI = {
  fetchProfile,
  editProfile,
  deleteProfile,
  changePassword,
  changeLanguage,
  changeCompany,
  changeSapEnv,
  fetchCompanies,
};

export { profileAPI };
