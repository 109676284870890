import { FC, useState } from "react";
import { Image } from "react-bootstrap";
import { Hotspot, IHotspotProps } from "../../../common/hotspot/Hotspot";
import { Hotspots } from "../../../common/hotspot/Hotspots";

interface IScreenshotProps {
  url: string;
  label?: string;
  hotspots: IHotspotProps[];
  imageLoaded: () => void;
}

const Screenshot: FC<IScreenshotProps> = ({
  url,
  label,
  hotspots,
  imageLoaded,
}) => {
  const [activeSpot, setActiveSpot] = useState<number>();

  const toggle = (order: number) => {
    setActiveSpot(order === activeSpot ? undefined : order);
  };

  return (
    <div className="screenshot desktop">
      {typeof label !== "undefined" && <h5>{label}</h5>}
      <Image
        src={url}
        onLoad={imageLoaded}
        style={{
          opacity: activeSpot ? 1 : 0.75,
        }}
      />
      <Hotspots activeSpot={activeSpot} toggle={toggle}>
        {hotspots.map((hotspot, index) => (
          <Hotspot key={index} {...hotspot} />
        ))}
      </Hotspots>
    </div>
  );
};

export { Screenshot };
