import { FC, useCallback, useMemo } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { AppModule } from "../../services/api/settings";
import { formatDateTime } from "../../utils/date-format";
import { maintenance as maintenanceSlice } from "./store/slices";

const MaintenanceAlert: FC = () => {
  const { t } = useTranslation("maintenance");
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const data = useSelector((state) => state.maintenance.data);
  const alertVisible = useSelector((state) => state.maintenance.alertVisible);

  const maintenance = useMemo(
    () => (data && data.length > 0 ? data[0] : null),
    [data]
  );

  const showAlert = useCallback(
    (show: boolean) => dispatch(maintenanceSlice.actions.showAlert(show)),
    [dispatch]
  );

  if (!maintenance) {
    return null;
  }

  const customPageMargin = `15px 30px 15px 100px`;
  const defaultPageMargin = "0px 0px 15px 0px";

  const showCustomPageMargin = pathname.includes(AppModule.Orders);

  return (
    <Alert
      variant="info"
      dismissible
      className="maintenance-alert"
      show={alertVisible}
      onClose={() => showAlert(false)}
      style={{
        margin: showCustomPageMargin ? customPageMargin : defaultPageMargin,
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: (t("weWouldLikeToInformYou") as string)
            .replace("{date}", formatDateTime(maintenance.date))
            .replace("{duration}", maintenance.durationHours.toString()),
        }}
      ></span>
    </Alert>
  );
};

export { MaintenanceAlert };
