import { createSlice } from "@reduxjs/toolkit";

export interface IRefreshModal {
  isVisible: boolean;
}

const initialState: IRefreshModal = {
  isVisible: false,
};

const refreshModal = createSlice({
  name: "refreshModal",
  initialState,
  reducers: {
    setVisibility: (state, action) => {
      state.isVisible = action.payload;
    },
    reset: () => initialState,
  },
});

export { refreshModal };
