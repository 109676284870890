import { API, HTTPMethod, SuccessResponse } from ".";

const checkSeenCarrierWizard = async (): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/profile/wizard/check-seen`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const wizardsAPI = {
  checkSeenCarrierWizard,
};

export { wizardsAPI };
