import { useCallback, useEffect } from "react";

export enum RecaptchaAction {
  Register = "register",
  // Login = "login",
  // ForgotPassword = "forgotPassword",
  // ResetPassword = "resetPassword",
  // AcceptInvite = "acceptInvite",
}

/**
 * Recaptcha hook
 */
const useRecaptcha = () => {
  const scriptId = "recaptcha";

  const loadRecaptcha = useCallback(() => {
    const scriptExists = document.getElementById(scriptId);

    if (!scriptExists) {
      const script = document.createElement("script");

      script.id = scriptId;
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;

      document.body.appendChild(script);
    }
  }, []);

  const validate = useCallback(
    (action: RecaptchaAction): Promise<string> =>
      new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          try {
            const token = grecaptcha.execute(
              `${process.env.REACT_APP_RECAPTCHA_KEY}`,
              {
                action,
              }
            );

            if (token) {
              resolve(token);
            } else {
              reject();
            }
          } catch (error) {
            reject(error);
          }
        });
      }),
    []
  );

  useEffect(() => {
    loadRecaptcha();
  }, [loadRecaptcha]);

  return { validate };
};

const removeRecaptcha = () => {
  const recaptchaScript = document.getElementById("recaptcha");
  const recaptchaBadge = document.getElementsByClassName("grecaptcha-badge");

  if (recaptchaScript) {
    recaptchaScript.remove();
  }

  if (recaptchaBadge && recaptchaBadge[0]) {
    recaptchaBadge[0].remove();
  }
};

export { useRecaptcha, removeRecaptcha };
