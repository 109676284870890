import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { documents } from "../../store/slices";
import { useTranslation } from "react-i18next";

const AuthorFilter: FC = () => {
  const { t } = useTranslation("documents");

  const showOnlyMy = useSelector((state) => state.documents.showOnlyMy);

  const dispatch = useDispatch();
  const setFilter = useCallback(
    (show: boolean) => dispatch(documents.actions.setShowOnlyMy(show)),
    [dispatch]
  );

  const handleCheck = (e: any) => {
    setFilter(e.target.checked);
  };

  return (
    <div
      className="custom-control custom-control-right custom-switch"
      style={{ zIndex: 0 }}
    >
      <input
        type="checkbox"
        className="custom-control-input"
        id="assigned-switch"
        onChange={handleCheck}
        checked={showOnlyMy}
      />
      <label className="custom-control-label" htmlFor="assigned-switch">
        {t("myDocuments")}
      </label>
    </div>
  );
};

export { AuthorFilter };
