import queryString from "query-string";
import { API, ApiResource, HTTPMethod } from "..";
import { IApplication } from "../application";

export interface IFetchApplicationsParams {
  search?: string;
  perPage?: number;
  page?: number;
}

const fetchApplications = async (
  requestParams: IFetchApplicationsParams
): Promise<ApiResource<IApplication[]>> => {
  const params = queryString.stringify({
    ...requestParams,
  });

  const response = await API.request<ApiResource<IApplication[]>>(
    `/admin/applications?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const adminApplicationsAPI = {
  fetchApplications,
};

export { adminApplicationsAPI };
