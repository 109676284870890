import { fork, take, call, put, select } from "typed-redux-saga";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../alert/store/slices";
import { formatBytes } from "../../../../utils/byte-format";
import { AppState } from "../../../../store";
import { translated } from "../../../../i18n";
import questionnaireModalSlice from "../slices";
import { IUpdateDocumentData } from "../../../application/form/FileDropdown";
import { companyAPI } from "../../../../services/api/company";

function* questionnaireDocumentSaga() {
  yield* fork(deleteDocumentListener);
  yield* fork(uploadDocumentListener);
  yield* fork(updateDocumentListener);
}

function* deleteDocumentListener() {
  while (true) {
    const { payload } = yield take(
      questionnaireModalSlice.actions.deleteDocument
    );

    if (payload.companyId && payload.documentId) {
      yield* call(deleteDocument, payload.companyId, payload.documentId);
    }
  }
}

function* uploadDocumentListener() {
  while (true) {
    const { payload } = yield take(
      questionnaireModalSlice.actions.uploadDocuments
    );

    if (payload.companyId && payload.documents) {
      yield* call(uploadDocuments, payload.companyId, payload.documents);
    }
  }
}

function* updateDocumentListener() {
  while (true) {
    const { payload } = yield take(
      questionnaireModalSlice.actions.updateDocument
    );

    if (payload.companyId && payload.documentId && payload.data) {
      yield* call(
        updateDocument,
        payload.companyId,
        payload.documentId,
        payload.data
      );
    }
  }
}

function* deleteDocument(companyId: string, documentId: string) {
  const result = yield* call(companyAPI.deleteDocument, companyId, documentId);

  if (result && result.success) {
    yield put(questionnaireModalSlice.actions.removeDocument(documentId));

    yield put(showSuccess(translated.applicationDocumentDeleted));
  } else {
    yield put(showError(translated.errorWhileDeletingDocument));
  }
}

function* uploadDocuments(companyId: string, documents: File[]) {
  const result = yield* call(companyAPI.uploadDocuments, companyId, documents);

  const settings = yield* select((state: AppState) => state.settings.carrier);

  if (result && result.data) {
    yield put(questionnaireModalSlice.actions.addDocuments(result.data));

    yield put(showSuccess(translated.documentsUploaded));
  } else if (
    settings &&
    result &&
    result.errors &&
    result.errors.size &&
    result.errors.size.length > 0
  ) {
    const message = `${translated.allFilesSizeExceeds} ${formatBytes(
      settings.general.maxUploadSize * 1000
    )}`;

    yield put(questionnaireModalSlice.actions.resetDocuments());

    yield put(showWarning(message));
  } else {
    yield put(showError(translated.errorWhileUploadingDocuments));
  }
}

function* updateDocument(
  companyId: string,
  documentId: string,
  data: IUpdateDocumentData
) {
  const result = yield* call(
    companyAPI.updateDocument,
    companyId,
    documentId,
    data
  );

  if (result && result.data) {
    yield put(questionnaireModalSlice.actions.replaceDocument(result.data));
  } else {
    yield put(questionnaireModalSlice.actions.resetDocuments());

    yield put(showError(translated.errorWhileUpdatingDocument));
  }
}

export default questionnaireDocumentSaga;
