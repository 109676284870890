import { fork, take, call, put, select } from "typed-redux-saga";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../alert/store/slices";
import { applications } from "./../slices";
import { applicationAPI } from "../../../../services/api/application";
import { IUpdateDocumentData } from "../../form/FileDropdown";
import { formatBytes } from "../../../../utils/byte-format";
import { AppState } from "../../../../store";
import { translated } from "../../../../i18n";

function* applicationDocumentSaga() {
  yield* fork(deleteDocumentListener);
  yield* fork(uploadDocumentListener);
  yield* fork(updateDocumentListener);
}

function* deleteDocumentListener() {
  while (true) {
    const { payload } = yield take(applications.actions.deleteDocument);

    if (payload.applicationId && payload.documentId) {
      yield* call(deleteDocument, payload.applicationId, payload.documentId);
    }
  }
}

function* uploadDocumentListener() {
  while (true) {
    const { payload } = yield take(applications.actions.uploadDocuments);

    if (payload.applicationId && payload.documents) {
      yield* call(uploadDocuments, payload.applicationId, payload.documents);
    }
  }
}

function* updateDocumentListener() {
  while (true) {
    const { payload } = yield take(applications.actions.updateDocument);

    if (payload.applicationId && payload.documentId && payload.data) {
      yield* call(
        updateDocument,
        payload.applicationId,
        payload.documentId,
        payload.data
      );
    }
  }
}

function* deleteDocument(applicationId: string, documentId: string) {
  const result = yield* call(
    applicationAPI.deleteDocument,
    applicationId,
    documentId
  );

  if (result && result.success) {
    const result = yield* call(applicationAPI.fetchApplication, applicationId);

    if (result && result.data) {
      yield put(applications.actions.resetDocuments(result.data.documents));
    }

    yield put(showSuccess(translated.applicationDocumentDeleted));
  } else {
    yield put(applications.actions.resetDocuments(null));

    yield put(showError(translated.errorWhileDeletingDocument));
  }
}

function* uploadDocuments(applicationId: string, documents: File[]) {
  const result = yield* call(
    applicationAPI.uploadDocuments,
    applicationId,
    documents
  );

  const settings = yield* select((state: AppState) => state.settings.carrier);

  if (result && result.data) {
    const result = yield* call(applicationAPI.fetchApplication, applicationId);

    if (result && result.data) {
      yield put(applications.actions.resetDocuments(result.data.documents));
    }

    yield put(showSuccess(translated.documentsUploaded));
  } else if (
    settings &&
    result &&
    result.errors &&
    result.errors.size &&
    result.errors.size.length > 0
  ) {
    const message = `${translated.allFilesSizeExceeds} ${formatBytes(
      settings.general.maxUploadSize * 1000
    )}`;

    yield put(applications.actions.resetDocuments(null));

    yield put(showWarning(message));
  } else {
    yield put(showError(translated.errorWhileUploadingDocuments));
  }
}

function* updateDocument(
  applicationId: string,
  documentId: string,
  data: IUpdateDocumentData
) {
  const result = yield* call(
    applicationAPI.updateDocument,
    applicationId,
    documentId,
    data
  );

  if (result && result.data) {
    const result = yield* call(applicationAPI.fetchApplication, applicationId);

    if (result && result.data) {
      yield put(applications.actions.resetDocuments(result.data.documents));
    }

    // yield put(showSuccess(translated.documentUpdated));
  } else {
    yield put(applications.actions.resetDocuments(null));

    yield put(showError(translated.errorWhileUpdatingDocument));
  }
}

export { applicationDocumentSaga };
