import queryString from "query-string";
import { API, ApiResource, HTTPMethod, SuccessResponse } from "..";
import { IEditRoleForm } from "../../../components/admin/users/UserRoleEdit";
import { CompanyRole, IRole, Role } from "../profile";
import { IUser, IUserShort } from "../users";

export enum RoleLevel {
  User = "user",
  Company = "company",
  Department = "department",
}

export interface IChangeCompanyRoleRequest {
  companyId: string;
  userId: string;
  roleId: string;
}

export interface IFetchUsersParams {
  search?: string;
  perPage?: number;
  page?: number;
  role?: Role;
  companyRole?: CompanyRole;
}

const fetchUsers = async (
  requestParams: IFetchUsersParams
): Promise<ApiResource<IUser[]>> => {
  const params = queryString.stringify({
    ...requestParams,
  });

  const response = await API.request<ApiResource<IUser[]>>(
    `/admin/users?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchUsersShort = async (
  requestParams: IFetchUsersParams
): Promise<ApiResource<IUserShort[]>> => {
  const params = queryString.stringify({
    ...requestParams,
  });

  const response = await API.request<ApiResource<IUserShort[]>>(
    `/super-admin/users/short?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchUser = async (userId: string): Promise<ApiResource<IUser>> => {
  const response = await API.request<ApiResource<IUser[]>>(
    `/admin/users/${userId}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchRoles = async (
  level: RoleLevel
): Promise<ApiResource<IRole<any>[]>> => {
  const params = queryString.stringify({
    level,
  });

  const response = await API.request<ApiResource<IRole<any>[]>>(
    `/roles?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const changeRole = async (
  userId: string,
  data: IEditRoleForm
): Promise<ApiResource<IUser>> => {
  const response = await API.request<ApiResource<IUser>>(
    `/admin/users/roles/user`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({
      userId,
      ...data,
    })
  );

  return response;
};

const syncDepartments = async (
  userId: string,
  roleIds: string[]
): Promise<ApiResource<IUser>> => {
  const data = JSON.stringify({
    userId,
    roleIds: roleIds.join(","),
  });

  const response = await API.request<ApiResource<IUser>>(
    `/admin/users/roles/department`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    data
  );

  return response;
};

const changeCompanyRole = async ({
  companyId,
  userId,
  roleId,
}: IChangeCompanyRoleRequest): Promise<ApiResource<IUser>> => {
  const data = JSON.stringify({
    companyId,
    userId,
    roleId,
  });

  const response = await API.request<ApiResource<IUser>>(
    `/admin/users/roles/company`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    data
  );

  return response;
};

const invite = async (email: string): Promise<ApiResource<IUser>> => {
  const data = JSON.stringify({
    email,
  });

  const response = await API.request<ApiResource<IUser>>(
    `/admin/users/invite`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    data
  );

  return response;
};

const assignCompany = async (
  userId: string,
  companyId: string
): Promise<ApiResource<IUser>> => {
  const data = JSON.stringify({
    userId,
    companyId,
  });

  const response = await API.request<ApiResource<IUser>>(
    `/admin/users/companies`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    data
  );

  return response;
};

const removeCompany = async (
  userId: string,
  companyId: string
): Promise<SuccessResponse> => {
  const data = JSON.stringify({
    userId,
    companyId,
  });

  const response = await API.request<SuccessResponse>(
    `/admin/users/companies`,
    {
      method: HTTPMethod.DELETE,
      version: "v2",
      jsonRequest: true,
    },
    data
  );

  return response;
};

const toggleConditions = async (userId: string): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/admin/users/${userId}/working-conditions/toggle`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const storeConditions = async (
  userId: string,
  text: string
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/admin/users/${userId}/working-conditions`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({
      text,
    })
  );

  return response;
};

const adminUsersAPI = {
  fetchUsers,
  fetchUsersShort,
  fetchUser,
  fetchRoles,
  changeRole,
  syncDepartments,
  changeCompanyRole,
  invite,
  assignCompany,
  removeCompany,
  toggleConditions,
  storeConditions,
};

export { adminUsersAPI };
