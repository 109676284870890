import { FC } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IProps {
  perPage: number;
  setPerPage: (pages: number) => void;
}

const ShowPages: FC<IProps> = ({ perPage, setPerPage }) => {
  const { t } = useTranslation("showPages");

  return (
    <div className="show-pages">
      <span>{t("show")}</span>
      <Form.Control
        className="show-select"
        as="select"
        value={perPage}
        onChange={(e) => {
          setPerPage(Number(e.target.value));
        }}
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
      </Form.Control>
      <span>{t("entries")}</span>
    </div>
  );
};

export { ShowPages };
