/**
 * Generates LatLng coordinates
 */
export const generateCoordinates = (
  lat: string | number,
  lng: string | number
): google.maps.LatLng =>
  new google.maps.LatLng((lat = Number(lat)), (lng = Number(lng)));

/**
 * Generates driving car symbol
 */
export const generateRouteSymbol = (
  mainColor: string
): google.maps.IconSequence => {
  const lineSymbol: google.maps.Symbol = {
    path: google.maps.SymbolPath.CIRCLE,
    scale: 8,
    strokeColor: mainColor ? mainColor : "#000000",
  };

  const icon: google.maps.IconSequence = {
    icon: lineSymbol,
    offset: "100%",
    fixedRotation: true,
  };

  return icon;
};

/**
 * Animates polyline circle
 */
export const animateRouteSymbol = (line: google.maps.Polyline) => {
  let count = 0;

  window.setInterval(function () {
    count = (count + 1) % 50;

    const icons = line.get("icons");

    icons[0].offset = count / 2 + "%";

    line.set("icons", icons);
  }, 20);
};

/**
 * Zooms map to fit coordinates bounds
 */
export const fitBounds = (
  map: google.maps.Map | null,
  coordinates: google.maps.LatLng[]
) => {
  if (map) {
    const latlngbounds = new google.maps.LatLngBounds();

    for (let i = 0; i < coordinates.length; i++) {
      latlngbounds.extend(coordinates[i]);
    }

    map.fitBounds(latlngbounds);
  }
};

/**
 * Zooms map to fit coordinates bounds
 */
export const centerMap = (
  map: google.maps.Map | null,
  coordinates?: google.maps.LatLng
) => {
  if (map && coordinates) {
    let latitudePadding;

    switch (map.getZoom()) {
      case 3:
        latitudePadding = 16;
        break;
      case 4:
        latitudePadding = 8;
        break;
      case 5:
        latitudePadding = 4;
        break;
      case 6:
        latitudePadding = 2;
        break;
      case 7:
        latitudePadding = 1;
        break;
      case 8:
        latitudePadding = 0.5;
        break;
      case 9:
        latitudePadding = 0.25;
        break;
      case 10:
        latitudePadding = 0.12;
        break;
      default:
        latitudePadding = 0;
        break;
    }

    const coordinatesWithPadding = generateCoordinates(
      coordinates.lat() + latitudePadding,
      coordinates.lng()
    );

    map.setCenter(coordinatesWithPadding);
  }
};

/**
 * Gets current position
 */
export const determineCurrentPosition = (
  plannedCoordinates: google.maps.LatLng[],
  confirmedCoordinates: google.maps.LatLng[]
): google.maps.LatLng | null => {
  if (confirmedCoordinates.length > 0) {
    return confirmedCoordinates[confirmedCoordinates.length - 1];
  }

  return null;
};
