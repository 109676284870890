import { FC, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FaAddressCard,
  FaFolderOpen,
  FaInfo,
  FaLocationArrow,
  FaUsers,
} from "react-icons/fa";
import { Step } from "../../common/stepper/Step";
import { Stepper } from "../../common/stepper/Stepper";
import { ApplicationPage } from "./pages/ApplicationPage";
import { DocumentPage } from "./pages/DocumentPage";
import { IntroPage } from "./pages/IntroPage";
import { ProjectPage } from "./pages/ProjectPage";
import { UserPage } from "./pages/UserPage";

enum Steps {
  Intro = "intro",
  Projects = "projects",
  Documents = "documents",
  Users = "users",
  Applications = "applications",
}

interface IProps {
  isVisible: boolean;
  handleDeny: () => void;
}

const CarrierWizardModal: FC<IProps> = ({ isVisible, handleDeny }) => {
  const { t } = useTranslation("carrierWizard");

  const [activeStep, setActiveStep] = useState<Steps>(Steps.Projects);

  const totalStepLength = useMemo(() => Object.keys(Steps).length, []);

  const activeStepIndex = useMemo(() => {
    const steps = Object.entries(Steps);

    return steps.findIndex(([key, step]) => activeStep === step);
  }, [activeStep]);

  const isLastStep = useMemo(() => totalStepLength <= activeStepIndex + 1, [
    totalStepLength,
    activeStepIndex,
  ]);

  const reset = () => {
    handleDeny();

    setTimeout(() => {
      setActiveStep(Steps.Intro);
    }, 1000);
  };

  const handleStepClick = (name: string) => {
    setActiveStep(name as Steps);
  };

  const handleNext = () => {
    if (isLastStep) {
      reset();
    } else {
      const nextStepIndex = activeStepIndex + 1;

      const nextStep = Object.entries(Steps).find(
        (step, index) => index === nextStepIndex
      );

      if (nextStep && nextStep[1]) {
        setActiveStep(nextStep[1] as Steps);
      }
    }
  };

  return (
    <Modal
      show={isVisible}
      onHide={reset}
      size="xl"
      className="carrier-wizard"
      scrollable
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span>{t("carrierPortalUpdated")}</span>
          <span>{t("whatNeedToKnow")}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stepper
          activeStep={activeStep}
          onClick={handleStepClick}
          style={{
            marginTop: "10px",
          }}
        >
          <Step
            icon={<FaInfo />}
            name={Steps.Intro}
            label={t(Steps.Intro) as string}
          />
          <Step
            icon={<FaLocationArrow />}
            name={Steps.Projects}
            label={t(Steps.Projects) as string}
          />
          <Step
            icon={<FaFolderOpen />}
            name={Steps.Documents}
            label={t(Steps.Documents) as string}
          />
          <Step
            icon={<FaUsers />}
            name={Steps.Users}
            label={t(Steps.Users) as string}
          />
          <Step
            icon={<FaAddressCard />}
            name={Steps.Applications}
            label={t(Steps.Applications) as string}
          />
        </Stepper>
        {activeStep === Steps.Intro && <IntroPage />}
        {activeStep === Steps.Projects && <ProjectPage />}
        {activeStep === Steps.Documents && <DocumentPage />}
        {activeStep === Steps.Users && <UserPage />}
        {activeStep === Steps.Applications && <ApplicationPage />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleNext}>
          {isLastStep ? t("close") : t("next")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { CarrierWizardModal };
