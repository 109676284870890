import { FC, useCallback, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Marker } from "./markers/Marker";
import {
  IProject,
  ProjectConfirmStatus,
} from "../../../../services/api/projects";
import {
  animateRouteSymbol,
  fitBounds,
  generateCoordinates,
  generateRouteSymbol,
  centerMap,
} from "./utils/maps-helper";
import { CurrentPositionMarker } from "./markers/CurrentPositionMarker";
import { useDispatch, useSelector } from "react-redux";
import { projectShow } from "../store/slices";
import { Loader } from "../../../common/loaders/Loader";
import { SelectionMarker } from "./markers/SelectionMarker";
import { ConfirmPositionModal } from "./modals/ConfirmPositionModal";
import { useTranslation } from "react-i18next";

export const plannedColor = "rgb(26, 122, 180, 0.9)";
export const confirmedColor = "rgb(62, 176, 115, 0.9)";
export const remainingColor = "rgb(55, 55, 55, 0.9)";

interface IProps {
  project: IProject;
}

const ProjectMap: FC<IProps> = ({ project }) => {
  const { t } = useTranslation("projectMap");

  const dispatch = useDispatch();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [maps, setMaps] = useState<typeof google.maps>();
  const [plannedPolyline, setPlannedPolyline] = useState<
    google.maps.Polyline
  >();
  const [confirmedPolyline, setConfirmedPolyline] = useState<
    google.maps.Polyline
  >();
  const [directionsRenderer, setDirectionsRenderer] = useState<
    google.maps.DirectionsRenderer
  >();
  const [directionsService, setDirectionsService] = useState<
    google.maps.DirectionsService
  >();
  const [hoveredLocationId, setHoveredLocationId] = useState<number>();
  const [hovered, setHovered] = useState<boolean>(false);

  const profile = useSelector((state) => state.profile);
  const data = useSelector((state) => state.projectShow.data);
  const selectedLocationId = useSelector(
    (state) => state.projectShow.selectedLocationId
  );
  const defaultPosition = useSelector(
    (state) => state.projectShow.defaultPosition
  );
  const plannedPositions = useSelector(
    (state) => state.projectShow.plannedPositions
  );
  const confirmedPositions = useSelector(
    (state) => state.projectShow.confirmedPositions
  );
  const currentPosition = useSelector(
    (state) => state.projectShow.currentPosition
  );
  const selectedPosition = useSelector(
    (state) => state.projectShow.selectedPositon
  );
  const isPlannedVisible = useSelector(
    (state) => state.projectShow.isPlannedVisible
  );
  const isConfirmedVisible = useSelector(
    (state) => state.projectShow.isConfirmedVisible
  );
  const isCurrentVisible = useSelector(
    (state) => state.projectShow.isCurrentVisible
  );

  const selectLocationId = useCallback(
    (id: number | null) =>
      dispatch(projectShow.actions.setSelectedLocationId(id)),
    [dispatch]
  );

  const setPlannedVisible = useCallback(
    (visible: boolean) =>
      dispatch(projectShow.actions.setPlannedVisible(visible)),
    [dispatch]
  );

  const setConfirmedVisible = useCallback(
    (visible: boolean) =>
      dispatch(projectShow.actions.setConfirmedVisible(visible)),
    [dispatch]
  );

  const isInactive =
    profile.isManager ||
    profile.hasAccountantRole ||
    !!project.archivedAt ||
    !!(
      project.confirmStatus &&
      [ProjectConfirmStatus.Completed, ProjectConfirmStatus.Rejected].includes(
        project.confirmStatus.code
      )
    );

  const handleSelect = (value: GoogleMapReact.ClickEventValue) => {
    if (!hovered) {
      selectLocationId(null);
    }
  };

  const handleMarkerClick = (locationId: number) => {
    if (data) {
      const allLocations = data.plannedLocations.concat(
        data.confirmedLocations
      );
      const locationExist = allLocations.some(
        (location) => location.id === Number(locationId)
      );

      if (locationExist && !hovered) {
        selectLocationId(Number(locationId));
      }
    }
  };

  const handleMarkerMouseEnter = (locationId: number, parameters: any) => {
    if (!selectedLocationId && !selectedPosition) {
      setHoveredLocationId(Number(locationId));
    }
  };

  const handleMarkerMouseLeave = (locationId: number, parameters: any) => {
    setHoveredLocationId(undefined);
  };

  useEffect(() => {
    setDirectionsService(new google.maps.DirectionsService());
    setDirectionsRenderer(new google.maps.DirectionsRenderer());
  }, []);

  useEffect(() => {
    if (project && maps && map && directionsService && directionsRenderer) {
      // Set planned route
      if (plannedPositions.length > 1) {
        const origin = plannedPositions[0];
        const destination = plannedPositions[plannedPositions.length - 1];
        const waypoints = plannedPositions.map((location) => ({
          location,
          stopover: true,
        }));

        directionsService.route(
          {
            origin,
            destination,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (response && status === "OK") {
              directionsRenderer.setDirections(response);

              const routePolyline = new maps.Polyline({
                path: response.routes[0].overview_path,
                icons: [generateRouteSymbol(plannedColor)],
                map,
                strokeWeight: 7,
                strokeOpacity: 0.5,
                strokeColor: plannedColor,
              });

              if (confirmedPositions.length === 0) {
                animateRouteSymbol(routePolyline);
              }

              setPlannedPolyline(routePolyline);
            }
          }
        );
      }

      // Set confirmed route
      if (confirmedPositions.length > 1) {
        const origin = confirmedPositions[0];
        const destination = confirmedPositions[confirmedPositions.length - 1];
        const waypoints = confirmedPositions.map((location) => ({
          location,
          stopover: true,
        }));

        directionsService.route(
          {
            origin,
            destination,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (response && status === "OK") {
              directionsRenderer.setDirections(response);

              const routePolyline = new maps.Polyline({
                path: response.routes[0].overview_path,
                map,
                strokeWeight: 7,
                strokeOpacity: 0.5,
                strokeColor: confirmedColor,
              });

              setConfirmedPolyline(routePolyline);
            }
          }
        );
      }

      // Set remaining route
      if (
        project.deliveryStatus !== "Cargo  delivered" &&
        plannedPositions.length > 0 &&
        confirmedPositions.length > 0
      ) {
        const origin = confirmedPositions[confirmedPositions.length - 1];
        const destination = plannedPositions[plannedPositions.length - 1];

        directionsService.route(
          {
            origin,
            destination,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (response && status === "OK") {
              directionsRenderer.setDirections(response);

              const routePolyline = new maps.Polyline({
                path: response.routes[0].overview_path,
                map,
                strokeWeight: 7,
                strokeOpacity: 0.5,
                strokeColor: remainingColor,
              });

              setConfirmedPolyline(routePolyline);
            }
          }
        );
      }
    }
  }, [
    project,
    maps,
    map,
    plannedPositions,
    confirmedPositions,
    directionsService,
    directionsRenderer,
    setPlannedVisible,
    setConfirmedVisible,
    t,
  ]);

  // Toggle planned
  useEffect(() => {
    if (map && plannedPolyline) {
      plannedPolyline.setMap(isPlannedVisible ? map : null);
    }
  }, [plannedPolyline, isPlannedVisible, map]);

  // Toggle confirmed
  useEffect(() => {
    if (map && confirmedPolyline) {
      confirmedPolyline.setMap(isConfirmedVisible ? map : null);
    }
  }, [confirmedPolyline, isConfirmedVisible, map]);

  // Autozoom to all locations
  useEffect(() => {
    if (map) {
      const allCoordinates = plannedPositions.concat(confirmedPositions);

      if (allCoordinates.length > 0) {
        fitBounds(map, allCoordinates);
      }
    }
  }, [plannedPositions, confirmedPositions, map]);

  // Center to selected location
  useEffect(() => {
    if (map && data && selectedLocationId) {
      const allLocations = data.plannedLocations.concat(
        data.confirmedLocations
      );

      const selectedLocation = allLocations.find(
        (location) => location.id === selectedLocationId
      );

      if (selectedLocation) {
        const coordinates = generateCoordinates(
          selectedLocation.latitude,
          selectedLocation.longtitude
        );

        centerMap(map, coordinates);
      }
    }
  }, [plannedPositions, confirmedPositions, map, data, selectedLocationId]);

  if (!data) {
    return <Loader isLoading={true} />;
  }

  return (
    <div className="project-map" style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY as string,
        }}
        yesIWantToUseGoogleMapApiInternals
        center={
          defaultPosition
            ? {
                lat: defaultPosition.lat(),
                lng: defaultPosition.lng(),
              }
            : undefined
        }
        defaultZoom={4}
        options={{
          gestureHandling: "greedy",
          fullscreenControl: false,
          streetViewControl: true,
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map);
          setMaps(maps);
        }}
        onChildClick={handleMarkerClick}
        onChildMouseEnter={handleMarkerMouseEnter}
        onChildMouseLeave={handleMarkerMouseLeave}
        onClick={handleSelect}
      >
        {isPlannedVisible &&
          data.plannedLocations.map((location) => (
            <Marker
              key={location.id}
              isSelected={selectedLocationId === location.id}
              isHovered={hoveredLocationId === location.id}
              lat={Number(location.latitude)}
              lng={Number(location.longtitude)}
              mainColor={plannedColor}
              data={location}
              disabled={isInactive}
              onHover={() => setHovered(true)}
              onLeave={() => setHovered(false)}
            />
          ))}
        {isConfirmedVisible &&
          data.confirmedLocations.map((location) => (
            <Marker
              key={location.id}
              isSelected={selectedLocationId === location.id}
              isHovered={hoveredLocationId === location.id}
              lat={Number(location.latitude)}
              lng={Number(location.longtitude)}
              mainColor={confirmedColor}
              data={location}
              disabled={isInactive}
              onHover={() => setHovered(true)}
              onLeave={() => setHovered(false)}
            />
          ))}
        {isCurrentVisible && currentPosition && (
          <CurrentPositionMarker
            mainColor={confirmedColor}
            lat={currentPosition.lat()}
            lng={currentPosition.lng()}
          />
        )}
        {selectedPosition && (
          <SelectionMarker
            lat={selectedPosition.lat()}
            lng={selectedPosition.lng()}
            mainColor={confirmedColor}
            disabled={isInactive}
            onHover={() => setHovered(true)}
            onLeave={() => setHovered(false)}
          />
        )}
      </GoogleMapReact>
      <ConfirmPositionModal />
    </div>
  );
};

export { ProjectMap };
