import { FC, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectList } from "../store/slices";
import { useTranslation } from "react-i18next";
import {
  IProjectLocationType,
  IProjectShort,
  ProjectConfirmStatus,
  ProjectLocationTypeCode,
} from "../../../../services/api/projects";
import {
  formatDateTime,
  formatUTCDate,
  formatUTCDateTime,
  formatUTCTime,
} from "../../../../utils/date-format";
import { formatCurrency } from "../../../../utils/currency-format";
import { useHistory, useParams } from "react-router-dom";
import { CarrierRoute } from "../../../../constants/routes";
import {
  FaArchive,
  FaExclamationTriangle,
  FaInfoCircle,
  FaTrailer,
  FaTruck,
  FaUser,
  FaRegTrashAlt,
  FaUndoAlt,
  FaCheck,
} from "react-icons/fa";
import { Tooltip } from "../Tooltip";
import { LocationStatusBadge } from "../LocationStatusBadge";
import classNames from "classnames";
import { Col, Form, Row } from "react-bootstrap";
import { StatusBadge } from "../StatusBadge";
import DatePicker from "react-datepicker";
import Select, { StylesConfig } from "react-select";
import { ConfirmModal } from "../../../common/modals/ConfirmModal";
import { ProjectContent } from "../../show/ProjectContent";
import { confirmedProjects } from "../store/slices/confirmed";
import { ListLoader } from "../ListLoader";
import { NoData } from "../../../common/no-data/NoData";
import { waitingProjects } from "../store/slices/waiting";
import { rejectedProjects } from "../store/slices/rejected";
import { completedProjects } from "../store/slices/completed";
import { ListFilter } from "../../../common/list-filter/ListFilter";
import { quickPayModal } from "../../quick-pay-modal/store/slice";
import { QuickPayModal } from "../../quick-pay-modal/QuickPayModal";
import useMobileScreen from "../../../../hooks/mobile-screen";
import ListGroup from "./ListGroup";
import MobileListGroup from "./MobileListGroup";

export interface IListColumn<T> {
  title: string;
  width: string;
  ContentColumn: React.FC<T>;
}

export interface IProjectForArchivation {
  id: number;
  status: ProjectConfirmStatus;
}

export type ListColumn = IListColumn<{
  data: IProjectShort;
  width: string;
}>;

export const getStatusColor = (code: ProjectConfirmStatus | null) => {
  let statusColor = "";

  switch (code) {
    case ProjectConfirmStatus.WaitingForApproval:
    case ProjectConfirmStatus.ConfirmUpdate:
      statusColor = "#17a2b8";
      break;
    case ProjectConfirmStatus.Confirmed:
      statusColor = "#3EB073";
      break;
    case ProjectConfirmStatus.Rejected:
      statusColor = "#ce181e";
      break;
    case ProjectConfirmStatus.Completed:
      statusColor = "#8BDC97";
      break;
  }

  return statusColor;
};

const dot = (projectConfirmStatus = null) => {
  const color = getStatusColor(projectConfirmStatus);

  return {
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color ? color : "none",
      borderRadius: 10,
      content: color ? '" "' : "",
      display: "block",
      marginRight: color ? 8 : 0,
      height: color ? 10 : 0,
      width: color ? 10 : 0,
    },
  };
};

const selectStyle: StylesConfig<any, false> = {
  control: (provided) => ({
    ...provided,
    width: "220px",
    border: "1px solid #ced4da",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #94d9b4",
    },
    minHeight: 31,
    height: 31,
    marginRight: "1rem",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0b5669" : undefined,
    "&:hover": {
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0b5669" : "#f1f2f4",
    },
    "&:active": {
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0b5669" : "#f1f2f4",
    },
    ...dot(state.data.value),
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
    ...dot(),
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 6px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
    cursor: "pointer",
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(data.value),
  }),
};

const mobileSelectStyle: StylesConfig<any, false> = {
  ...selectStyle,
  control: (provided) => ({
    ...provided,
    border: "1px solid #ced4da",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #94d9b4",
    },
    minHeight: 31,
    height: 31,
  }),
};

const ProjectTable: FC = () => {
  const isMobileScreen = useMobileScreen();

  const { orderId } = useParams<{ orderId: string }>();
  const { t } = useTranslation("projects");
  const dispatch = useDispatch();
  const history = useHistory();

  const [
    projectForArchivation,
    setProjectForArchivation,
  ] = useState<IProjectForArchivation | null>(null);
  const [
    projectForUnarchivation,
    setProjectForUnarchivation,
  ] = useState<IProjectForArchivation | null>(null);

  const profile = useSelector((state) => state.profile);

  const onlyAssigned = useSelector((state) => state.projectList.onlyAssigned);
  const onlyArchived = useSelector((state) => state.projectList.onlyArchived);
  const onlyIncomplete = useSelector(
    (state) => state.projectList.onlyIncomplete
  );

  const confirmed = useSelector((state) => state.confirmedProjects);
  const waiting = useSelector((state) => state.waitingProjects);
  const rejected = useSelector((state) => state.rejectedProjects);
  const completed = useSelector((state) => state.completedProjects);

  const selectedProject = useSelector((state) => state.projectShow.data);
  const confirmStatus = useSelector((state) => state.projectList.confirmStatus);
  const locationStatus = useSelector(
    (state) => state.projectList.locationStatus
  );
  const settings = useSelector((state) => state.settings.carrier);
  const [startDate, endDate] = useSelector(
    (state) => state.projectList.dateRange
  );
  // const clientCompanies = useSelector(
  //   (state) => state.projectList.clientCompanies
  // );
  // const clientCompaniesIsLoading = useSelector(
  //   (state) => state.projectList.clientCompaniesIsLoading
  // );
  // const selectedClientCompanyId = useSelector(
  //   (state) => state.projectList.selectedClientCompanyId
  // );

  // const fetchClientCompanies = useCallback(
  //   () => dispatch(projectList.actions.fetchClientCompanies()),
  //   [dispatch]
  // );

  // const setClientCompanyId = useCallback(
  //   (id: number | null) => dispatch(projectList.actions.setClientCompanyId(id)),
  //   [dispatch]
  // );

  const setConfirmStatus = useCallback(
    (status: ProjectConfirmStatus | null) =>
      dispatch(projectList.actions.setConfirmStatus(status)),
    [dispatch]
  );

  const setLocationStatus = useCallback(
    (status: ProjectLocationTypeCode | null) =>
      dispatch(projectList.actions.setLocationStatus(status)),
    [dispatch]
  );

  const setDateRange = useCallback(
    (dateRange: any) => dispatch(projectList.actions.setDateRange(dateRange)),
    [dispatch]
  );

  const setOnlyArchived = useCallback(
    (checked: boolean) =>
      dispatch(projectList.actions.setOnlyArchived(checked)),
    [dispatch]
  );

  const setOnlyAssigned = useCallback(
    (checked: boolean) =>
      dispatch(projectList.actions.setOnlyAssigned(checked)),
    [dispatch]
  );

  const setOnlyIncomplete = useCallback(
    (checked: boolean) =>
      dispatch(projectList.actions.setOnlyIncomplete(checked)),
    [dispatch]
  );

  const setConfirmedActivePage = useCallback(
    (page: number) => dispatch(confirmedProjects.actions.setActivePage(page)),
    [dispatch]
  );

  const setRejectedActivePage = useCallback(
    (page: number) => dispatch(rejectedProjects.actions.setActivePage(page)),
    [dispatch]
  );

  const setWaitingActivePage = useCallback(
    (page: number) => dispatch(waitingProjects.actions.setActivePage(page)),
    [dispatch]
  );

  const setCompletedActivePage = useCallback(
    (page: number) => dispatch(completedProjects.actions.setActivePage(page)),
    [dispatch]
  );

  const setQuickPayProject = useCallback(
    (project: IProjectShort | null) =>
      dispatch(quickPayModal.actions.setProject(project)),
    [dispatch]
  );

  const fetchMore = useCallback(
    (status: ProjectConfirmStatus) => {
      if (status === ProjectConfirmStatus.Confirmed) {
        setConfirmedActivePage(confirmed.activePage + 1);
      }

      if (status === ProjectConfirmStatus.WaitingForApproval) {
        setWaitingActivePage(waiting.activePage + 1);
      }

      if (status === ProjectConfirmStatus.Rejected) {
        setRejectedActivePage(rejected.activePage + 1);
      }

      if (status === ProjectConfirmStatus.Completed) {
        setCompletedActivePage(completed.activePage + 1);
      }
    },
    [
      confirmed,
      waiting,
      rejected,
      completed,
      setConfirmedActivePage,
      setWaitingActivePage,
      setRejectedActivePage,
      setCompletedActivePage,
    ]
  );

  const archive = useCallback(
    ({ id, status }: IProjectForArchivation) => {
      if (
        status === ProjectConfirmStatus.WaitingForApproval ||
        status === ProjectConfirmStatus.ConfirmUpdate
      ) {
        dispatch(waitingProjects.actions.archive(id));
      }

      if (status === ProjectConfirmStatus.Confirmed) {
        dispatch(confirmedProjects.actions.archive(id));
      }

      if (status === ProjectConfirmStatus.Rejected) {
        dispatch(rejectedProjects.actions.archive(id));
      }

      if (status === ProjectConfirmStatus.Completed) {
        dispatch(completedProjects.actions.archive(id));
      }
    },
    [dispatch]
  );

  const unarchive = useCallback(
    ({ id, status }: IProjectForArchivation) => {
      if (
        status === ProjectConfirmStatus.WaitingForApproval ||
        status === ProjectConfirmStatus.ConfirmUpdate
      ) {
        dispatch(waitingProjects.actions.unarchive(id));
      }

      if (status === ProjectConfirmStatus.Confirmed) {
        dispatch(confirmedProjects.actions.unarchive(id));
      }

      if (status === ProjectConfirmStatus.Rejected) {
        dispatch(rejectedProjects.actions.unarchive(id));
      }

      if (status === ProjectConfirmStatus.Completed) {
        dispatch(completedProjects.actions.unarchive(id));
      }
    },
    [dispatch]
  );

  // useEffect(() => {
  //   if (profile.data && profile.data.termsPolicyAccepted) {
  //     fetchClientCompanies();
  //   }
  // }, [fetchClientCompanies, profile]);

  const handleActiveRow = (id: number) => {
    const activeRowId = id.toString();

    if (selectedProject && activeRowId === orderId) {
      history.push(CarrierRoute.Orders.List.replace(":orderId?", ""));
    } else {
      history.push(CarrierRoute.Orders.List.replace(":orderId?", activeRowId));
    }
  };

  const selectedProjectId = Number(orderId);

  const isRightPanelOpen = useMemo(
    () =>
      !!(
        selectedProject &&
        selectedProject.confirmStatus &&
        [
          ProjectConfirmStatus.Confirmed,
          ProjectConfirmStatus.Rejected,
          ProjectConfirmStatus.Completed,
        ].includes(selectedProject.confirmStatus.code)
      ),
    [selectedProject]
  );

  const columns: ListColumn[] = useMemo(() => {
    const width = isRightPanelOpen ? `${100 / 6}%` : `${100 / 9}%`;

    const locationColumns: ListColumn[] = [
      {
        title: t("loadingInfo"),
        width,
        ContentColumn: ({
          data: { id, loadingPlace, loadingDateFrom, loadingDateTo },
          width,
        }) => {
          return (
            <div style={{ width, justifyContent: "flex-start" }}>
              {loadingPlace ? (
                <Tooltip id={`tooltip-${id}-loading`} text={t("loadingInfo")}>
                  <span style={{ marginBottom: "10px", fontWeight: 800 }}>
                    {loadingPlace}
                  </span>
                </Tooltip>
              ) : (
                t("noData")
              )}
              {loadingDateFrom && (
                <span className="faded">{formatUTCDate(loadingDateFrom)}</span>
              )}
              {loadingDateFrom && (
                <span className="faded">
                  {formatUTCTime(loadingDateFrom)}
                  {loadingDateTo && ` - ${formatUTCTime(loadingDateTo)}`}
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: t("unloadingInfo"),
        width,
        ContentColumn: ({
          data: { id, unloadingPlace, unloadingDateFrom, unloadingDateTo },
          width,
        }) => {
          return (
            <div style={{ width, justifyContent: "flex-start" }}>
              {unloadingPlace ? (
                <Tooltip
                  id={`tooltip-${id}-unloading`}
                  text={t("unloadingInfo")}
                >
                  <span style={{ marginBottom: "10px", fontWeight: 800 }}>
                    {unloadingPlace}
                  </span>
                </Tooltip>
              ) : (
                t("noData")
              )}
              {unloadingDateFrom && (
                <span className="faded">
                  {formatUTCDate(unloadingDateFrom)}
                </span>
              )}
              {unloadingDateFrom && (
                <span className="faded">
                  {formatUTCTime(unloadingDateFrom)}{" "}
                  {unloadingDateTo && ` - ${formatUTCTime(unloadingDateTo)}`}
                </span>
              )}
            </div>
          );
        },
      },
    ];

    const otherColumns: ListColumn[] = [
      {
        title: t("trailerType"),
        width,
        ContentColumn: ({
          data: { id, trailerType, temperatureType, temperature },
        }) => {
          return (
            <div style={{ width, justifyContent: "flex-start" }}>
              <Tooltip
                id={`tooltip-${id}-trailer-type`}
                text={t("trailerType")}
              >
                <span style={{ marginBottom: "10px", fontWeight: 800 }}>
                  {trailerType}
                </span>
              </Tooltip>

              <div className="faded">
                {temperatureType && (
                  <span style={{ display: "block" }}>
                    {t("mode")}: {temperatureType}
                  </span>
                )}

                {temperature && (
                  <span style={{ display: "block" }}>
                    {t("range")}: {temperature}
                  </span>
                )}
              </div>
            </div>
          );
        },
      },
    ];

    const columns: ListColumn[] = [
      {
        title: t("order"),
        width,
        ContentColumn: ({
          data: {
            id,
            axCode,
            confirmStatus,
            acceptedBy,
            acceptedAt,
            rejectedBy,
            rejectedAt,
            rejectedByClientCompany,
          },
          width,
        }) => (
          <div
            style={{
              width,
              fontWeight: 800,
              fontSize: "18px",
            }}
          >
            <Tooltip id={`tooltip-${id}-order`} text={t("order")}>
              <span className="text-danger">{axCode}</span>
            </Tooltip>

            {confirmStatus && <StatusBadge status={confirmStatus} />}
            {!!(
              confirmStatus &&
              confirmStatus.code === ProjectConfirmStatus.ConfirmUpdate
            ) ? (
              <span className="order-renewed">
                <FaExclamationTriangle /> {t("orderRenewed")}
              </span>
            ) : rejectedBy || rejectedByClientCompany ? (
              <Tooltip
                id={`tooltip-${id}-rejected-by`}
                text={`${t("rejectedBy")} ${
                  rejectedByClientCompany
                    ? rejectedByClientCompany.name
                    : rejectedBy
                    ? `${rejectedBy.name || ""} ${rejectedBy.surname || ""}`
                    : ""
                } ${rejectedAt ? formatDateTime(rejectedAt) : ""}`}
              >
                <span className="order-accepted">
                  <FaCheck />{" "}
                  {rejectedByClientCompany
                    ? rejectedByClientCompany.name
                    : rejectedBy
                    ? `${rejectedBy.name || ""} ${rejectedBy.surname || ""}`
                    : ""}
                </span>
              </Tooltip>
            ) : acceptedBy ? (
              <Tooltip
                id={`tooltip-${id}-accepted-by`}
                text={`${t("acceptedBy")} ${acceptedBy.name || ""} ${
                  acceptedBy.surname || ""
                } ${acceptedAt ? formatDateTime(acceptedAt) : ""}`}
              >
                <span className="order-accepted">
                  <FaCheck /> {acceptedBy.name || ""} {acceptedBy.surname || ""}
                </span>
              </Tooltip>
            ) : null}
          </div>
        ),
      },
      {
        title: t("loadType"),
        width,
        ContentColumn: ({
          data: {
            id,
            pallets,
            goodsType,
            weight,
            palletsWillChange,
            carriageValue,
            increasedSecurity,
          },
          width,
        }) => {
          const palletsNumber = pallets
            ? (pallets.match(/\d+/g) || []).join("")
            : "";

          const palletsType =
            pallets && pallets.includes("PAGAL_CMR") ? t("byCMR") : "";

          const palletsText = `${
            palletsNumber
              ? `${palletsNumber} ${t("pallets")}`
              : palletsType
              ? palletsType
              : ""
          }`;

          return (
            <div style={{ width, justifyContent: "flex-start" }}>
              <Tooltip id={`tooltip-${id}-load-type`} text={t("loadType")}>
                <span style={{ marginBottom: "10px", fontWeight: 800 }}>
                  {goodsType}
                </span>
              </Tooltip>

              {palletsText && (
                <span className="faded">
                  {palletsText}{" "}
                  {palletsWillChange && (
                    <Tooltip
                      id={`tooltip-${id}-pallets`}
                      text={t("willBeChanged")}
                      placement="right"
                    >
                      <FaInfoCircle
                        className="text-primary"
                        style={{ fontSize: "14px" }}
                      />
                    </Tooltip>
                  )}
                </span>
              )}
              {typeof weight !== "object" && (
                <span className="faded">{weight} KG</span>
              )}
              {carriageValue && (
                <>
                  {increasedSecurity ? (
                    <Tooltip
                      id={`tooltip-${id}-increased-security`}
                      text={t("highValueIncreasedSecurity")}
                      placement="top"
                    >
                      <span className={"bold increased-security"}>
                        {t("value")}: {carriageValue} EUR
                      </span>
                    </Tooltip>
                  ) : (
                    <span className={"bold"}>
                      {t("value")}: {carriageValue} EUR
                    </span>
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        title: t("truckTrailer"),
        width,
        ContentColumn: ({
          data: { id, truckPlates, trailerPlates, confirmStatus, driverName },
          width,
        }) => {
          const missingDetails =
            confirmStatus &&
            confirmStatus.code === ProjectConfirmStatus.Confirmed &&
            !driverName;

          return (
            <Tooltip
              id={`tooltip-${id}-truck-trailer`}
              text={t("truckTrailer")}
            >
              <div style={{ width }} className="driver-info">
                <div
                  className={classNames(
                    missingDetails && "driver-info-missing"
                  )}
                >
                  <span className={classNames(!driverName && "text-danger")}>
                    <FaUser /> {driverName ? driverName : t("noData")}
                  </span>
                </div>
                <hr />
                <span>
                  <FaTruck /> {truckPlates ? truckPlates : t("noData")}
                </span>
                <hr />
                <span>
                  <FaTrailer /> {trailerPlates ? trailerPlates : t("noData")}
                </span>
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: t("locationStatus"),
        width,
        ContentColumn: ({ data: { id, latestConfirmedStatus }, width }) => (
          <div style={{ width }}>
            <Tooltip
              id={`tooltip-${id}-order-status`}
              text={t("locationStatus")}
            >
              {latestConfirmedStatus && latestConfirmedStatus.type ? (
                <span>
                  <LocationStatusBadge status={latestConfirmedStatus.type} />
                </span>
              ) : (
                <span className="text-danger">{t("notStarted")}</span>
              )}
            </Tooltip>
          </div>
        ),
      },
      {
        title: t("createdAt"),
        width,
        ContentColumn: ({ data: { id, issueDate }, width }) => (
          <div style={{ width, fontWeight: 800 }}>
            <Tooltip id={`tooltip-${id}-created-at`} text={t("createdAt")}>
              <span>{formatUTCDateTime(issueDate)}</span>
            </Tooltip>
          </div>
        ),
      },
      {
        title: t("price"),
        width,
        ContentColumn: ({
          data: {
            id,
            totalCharge,
            totalChargeCurrency,
            freightCharge,
            freightChargeCurrency,
            extraCharge,
            extraChargeCurrency,
            showNewTotal,
            newTotal,
          },
          width,
        }) => {
          const isNewTotalVisible = !!(showNewTotal && newTotal);

          return (
            <div style={{ width }} className="text-danger">
              <Tooltip
                id={`tooltip-${id}-price`}
                text={isNewTotalVisible ? t("paymentAfterInvoice") : t("price")}
              >
                <span className="bold" style={{ fontSize: "18px" }}>
                  {isNewTotalVisible ? (
                    <>
                      {formatCurrency(newTotal)} {totalChargeCurrency || "EUR"}
                      <FaInfoCircle className="payment-after-icon" />
                    </>
                  ) : (
                    <>
                      {totalCharge ? formatCurrency(totalCharge) : "-"}{" "}
                      {totalChargeCurrency || "EUR"}
                    </>
                  )}
                </span>
              </Tooltip>

              {!!(freightCharge && freightChargeCurrency) && (
                <span className="faded">
                  {t("freight")}: {formatCurrency(freightCharge)}{" "}
                  {freightChargeCurrency}
                </span>
              )}
              {!!(extraCharge && extraChargeCurrency) && (
                <span className="faded">
                  {t("extra")}: {formatCurrency(extraCharge)}{" "}
                  {extraChargeCurrency}
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: "",
        width,
        ContentColumn: ({
          data: { id, allowArchive, allowUnarchive, confirmStatus },
        }) => {
          if (!!(allowUnarchive && !profile.hasAccountantRole)) {
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation();

                  if (confirmStatus) {
                    setProjectForUnarchivation({
                      id,
                      status: confirmStatus.code,
                    });
                  }
                }}
                style={{ width: "35px" }}
                className="archive-column"
              >
                <Tooltip id={`tooltip-${id}-unarchive`} text={t("unarchive")}>
                  <div>
                    <FaUndoAlt />
                  </div>
                </Tooltip>
              </div>
            );
          }

          if (!!(allowArchive && !profile.hasAccountantRole)) {
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation();

                  if (confirmStatus) {
                    setProjectForArchivation({
                      id,
                      status: confirmStatus.code,
                    });
                  }
                }}
                style={{ width: "35px" }}
                className="archive-column"
              >
                <Tooltip id={`tooltip-${id}-archive`} text={t("archive")}>
                  <div>
                    <FaRegTrashAlt />
                  </div>
                </Tooltip>
              </div>
            );
          }

          return <div style={{ width: "35px" }}></div>;
        },
      },
    ];

    if (!isRightPanelOpen) {
      columns.splice(1, 0, ...locationColumns);
      columns.splice(4, 0, ...otherColumns);
    }

    return columns;
  }, [t, isRightPanelOpen, profile]);

  const confirmStatuses = useMemo(
    () =>
      settings
        ? settings.projects.confirmStatuses.filter(
            (status) => status.code !== ProjectConfirmStatus.ConfirmUpdate
          )
        : [],
    [settings]
  );

  const locationStatuses = useMemo(() => {
    if (!settings) return [];

    return settings.projects.locationStatuses;
  }, [settings]);

  // const clientCompanyOption = useMemo(() => {
  //   const company = clientCompanies.find(
  //     (company) => company.id === selectedClientCompanyId
  //   );

  //   if (company) {
  //     return {
  //       label: company.name,
  //       value: company.id,
  //     };
  //   }

  //   return;
  // }, [clientCompanies, selectedClientCompanyId]);

  const orderStatusOption = useMemo(() => {
    const status = confirmStatuses.find(
      (status) => status.code === confirmStatus
    );

    return status
      ? {
          label: status.name,
          value: status.code,
        }
      : undefined;
  }, [confirmStatuses, confirmStatus]);

  const locationStatusOption = useMemo(() => {
    const status = locationStatuses.find(
      (status) => status.axCode === locationStatus
    );

    return status
      ? {
          label: status.name,
          value: status.axCode,
        }
      : undefined;
  }, [locationStatuses, locationStatus]);

  const orderStatusOptions = useMemo(
    () =>
      confirmStatuses.map((status) => ({
        label: status.name,
        value: status.code,
      })),
    [confirmStatuses]
  );
  // const clientCompanyOptions = clientCompanies.map((company) => ({
  //   label: company.name,
  //   value: company.id,
  // }));

  const locationStatusOptions = useMemo(() => {
    const {
      LoadingPlace,
      UnloadingPlace,
      CargoDelivered,
      OnTheRoad,
      Customs,
      Border,
    } = ProjectLocationTypeCode;

    const options = ([
      locationStatuses.find((status) => status.axCode === LoadingPlace),
      locationStatuses.find((status) => status.axCode === UnloadingPlace),
      locationStatuses.find((status) => status.axCode === CargoDelivered),
      locationStatuses.find((status) => status.axCode === OnTheRoad),
      locationStatuses.find((status) => status.axCode === Customs),
      locationStatuses.find((status) => status.axCode === Border),
    ].filter((status) => !!status) as IProjectLocationType[]).map((status) => ({
      label: status.name,
      value: status.axCode,
    }));

    options.push({
      label: t("other"),
      value: "other",
    } as any);

    return options;
  }, [locationStatuses, t]);

  const allIsLoading =
    confirmed.isLoading &&
    rejected.isLoading &&
    waiting.isLoading &&
    completed.isLoading;
  const someIsLoading =
    confirmed.isLoading ||
    rejected.isLoading ||
    waiting.isLoading ||
    completed.isLoading;
  const allIsEmpty =
    confirmed.data.length === 0 &&
    rejected.data.length === 0 &&
    completed.data.length === 0 &&
    waiting.data.length === 0;

  const showListLoader = !!(allIsEmpty && someIsLoading);
  const showNoData = !!(allIsEmpty && !allIsLoading);

  const filters = (
    <>
      <div>
        {/* <Select
          value={clientCompanyOption}
          onChange={(option) =>
            setClientCompanyId(option ? option.value : null)
          }
          options={clientCompanyOptions}
          isClearable
          isLoading={clientCompaniesIsLoading}
          styles={isMobileScreen ? mobileSelectStyle : selectStyle}
          placeholder={t("customer")}
        /> */}

        <Select
          value={orderStatusOption}
          onChange={(option) => setConfirmStatus(option ? option.value : null)}
          options={orderStatusOptions}
          isClearable
          styles={isMobileScreen ? mobileSelectStyle : selectStyle}
          placeholder={t("orderStatus")}
        />

        <Select
          value={locationStatusOption}
          onChange={(option) => setLocationStatus(option ? option.value : null)}
          options={locationStatusOptions}
          isClearable
          styles={isMobileScreen ? mobileSelectStyle : selectStyle}
          placeholder={t("locationStatus")}
        />

        <Form.Control
          as={DatePicker as any}
          autoComplete="off"
          dateFormat="yyyy-MM-dd"
          className="date-input"
          startDate={startDate}
          onChange={setDateRange}
          endDate={endDate}
          selectsRange
          placeholderText={t("date")}
          size="sm"
          maxDate={new Date()}
          isClearable
        />
      </div>

      <div>
        <div className="custom-control custom-control-right custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="incomplete-switch"
            onChange={(e) => setOnlyIncomplete(e.target.checked)}
            checked={onlyIncomplete}
          />

          <label className="custom-control-label" htmlFor="incomplete-switch">
            <span>{t("incompleteOrders")}</span>
          </label>
        </div>

        <div className="custom-control custom-control-right custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="assigned-switch"
            onChange={(e) => setOnlyAssigned(e.target.checked)}
            checked={onlyAssigned}
          />

          <label className="custom-control-label" htmlFor="assigned-switch">
            <span>{t("myAssignedOrders")}</span>
          </label>
        </div>

        <div className="custom-control custom-control-right custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="archived-switch"
            onChange={(e) => setOnlyArchived(e.target.checked)}
            checked={onlyArchived}
          />

          <label className="custom-control-label" htmlFor="archived-switch">
            <span>{t("archivedOrders")}</span>
          </label>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Row className="list-row">
        <Col
          id="list-content"
          style={{
            paddingRight: isRightPanelOpen ? "15px" : "30px",
          }}
        >
          <ListFilter>{filters}</ListFilter>

          {settings && <div className="list-filters">{filters}</div>}

          <div
            style={{ width: "100%", overflow: "hidden", padding: "0px 5px" }}
          >
            {showListLoader && <ListLoader />}
            {showNoData && <NoData />}

            {isMobileScreen ? (
              <>
                {waiting.data.length > 0 && (
                  <MobileListGroup
                    group={waiting}
                    handleActiveRow={handleActiveRow}
                    fetchMore={fetchMore}
                    profile={profile}
                    setProjectForArchivation={setProjectForArchivation}
                    setProjectForUnarchivation={setProjectForUnarchivation}
                  />
                )}

                {confirmed.data.length > 0 && (
                  <MobileListGroup
                    group={confirmed}
                    handleActiveRow={handleActiveRow}
                    fetchMore={fetchMore}
                    setQuickPayProject={setQuickPayProject}
                    profile={profile}
                    setProjectForArchivation={setProjectForArchivation}
                    setProjectForUnarchivation={setProjectForUnarchivation}
                  />
                )}

                {completed.data.length > 0 && (
                  <MobileListGroup
                    group={completed}
                    handleActiveRow={handleActiveRow}
                    fetchMore={fetchMore}
                    profile={profile}
                    setProjectForArchivation={setProjectForArchivation}
                    setProjectForUnarchivation={setProjectForUnarchivation}
                  />
                )}

                {rejected.data.length > 0 && (
                  <MobileListGroup
                    group={rejected}
                    handleActiveRow={handleActiveRow}
                    fetchMore={fetchMore}
                    profile={profile}
                    setProjectForArchivation={setProjectForArchivation}
                    setProjectForUnarchivation={setProjectForUnarchivation}
                  />
                )}
              </>
            ) : (
              <>
                {waiting.data.length > 0 && (
                  <ListGroup
                    group={waiting}
                    selectedProjectId={selectedProjectId}
                    columns={columns}
                    handleActiveRow={handleActiveRow}
                    fetchMore={fetchMore}
                  />
                )}

                {confirmed.data.length > 0 && (
                  <ListGroup
                    group={confirmed}
                    selectedProjectId={selectedProjectId}
                    columns={columns}
                    handleActiveRow={handleActiveRow}
                    fetchMore={fetchMore}
                    setQuickPayProject={setQuickPayProject}
                    isRightPanelOpen={isRightPanelOpen}
                  />
                )}

                {completed.data.length > 0 && (
                  <ListGroup
                    group={completed}
                    selectedProjectId={selectedProjectId}
                    columns={columns}
                    handleActiveRow={handleActiveRow}
                    fetchMore={fetchMore}
                  />
                )}

                {rejected.data.length > 0 && (
                  <ListGroup
                    group={rejected}
                    selectedProjectId={selectedProjectId}
                    columns={columns}
                    handleActiveRow={handleActiveRow}
                    fetchMore={fetchMore}
                  />
                )}
              </>
            )}
          </div>
        </Col>

        {!!selectedProjectId && <ProjectContent />}

        <ConfirmModal
          question={t("archiveProject") as string}
          icon={<FaArchive />}
          isVisible={!!projectForArchivation}
          handleDeny={() => setProjectForArchivation(null)}
          handleConfirm={() => {
            if (projectForArchivation) {
              archive(projectForArchivation);
            }

            setProjectForArchivation(null);
          }}
          body={
            <div>
              <p>{t("youWillBeAbleToSeeArchived")}</p>
            </div>
          }
        />
        <ConfirmModal
          question={t("unarchiveProject") as string}
          icon={<FaArchive />}
          isVisible={!!projectForUnarchivation}
          handleDeny={() => setProjectForUnarchivation(null)}
          handleConfirm={() => {
            if (projectForUnarchivation) {
              unarchive(projectForUnarchivation);
            }

            setProjectForUnarchivation(null);
          }}
        />
        <QuickPayModal handleClose={() => setQuickPayProject(null)} />
      </Row>
    </>
  );
};

export { ProjectTable };
