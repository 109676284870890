import { call, fork, put, take, takeLatest } from "typed-redux-saga";
import { AppModule } from "../../../../../services/api/settings";
import { profile } from "../../../../profile/store/slices";
import { projectList } from "../slices";
import { projectAPI } from "../../../../../services/api/projects";
import { showError } from "../../../../alert/store/slices";

function* projectListSaga() {
  yield* fork(companyChangeListener);
  yield* takeLatest(
    projectList.actions.fetchClientCompanies,
    handleClientCompanies
  );
}

function* companyChangeListener() {
  while (true) {
    const {
      payload: { appModule },
    } = yield take(profile.actions.setCompanyChanged);

    if (appModule === AppModule.Projects) {
      yield put(projectList.actions.reset());
    }
  }
}

function* handleClientCompanies() {
  yield put(projectList.actions.setClientCompaniesIsLoading(true));

  const result = yield* call(projectAPI.fetchClientCompanies);

  if (result && result.data) {
    yield put(projectList.actions.setClientCompanies(result.data));
  } else {
    yield put(
      showError("Something went wrong while fetchign client companies.")
    );
  }

  yield put(projectList.actions.setClientCompaniesIsLoading(false));
}

export { projectListSaga };
