import { FC, useCallback, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CarrierRoute } from "../../../constants/routes";
import useMobileScreen from "../../../hooks/mobile-screen";
import { ProjectConfirmStatus } from "../../../services/api/projects";
import { DetailPanel } from "./details/DetailPanel";
import { ProjectMap } from "./map/ProjectMap";
import { AlertPlace, ProjectAlerts } from "./ProjectAlerts";
import { ProjectReviewModal } from "./ProjectReviewModal";
import { ProjectReviewModalMobile } from "./ProjectReviewModalMobile";
import { projectShow } from "./store/slices";
import { Timeline } from "./timeline/Timeline";

const ProjectContent: FC = () => {
  const isMobileScreen = useMobileScreen();

  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation("projectPanel");

  const { orderId: projectId } = useParams<{ orderId: string }>();

  const data = useSelector((state) => state.projectShow.data);

  const fetchProject = useCallback(
    (id: string) => dispatch(projectShow.actions.fetchProject(id)),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(projectShow.actions.reset()), [
    dispatch,
  ]);

  useEffect(() => {
    if (projectId) {
      fetchProject(projectId);
    }

    return () => {
      reset();
    };
  }, [projectId, fetchProject, reset]);

  if (!data) return null;

  const showModal =
    data.confirmStatus &&
    ![
      ProjectConfirmStatus.Confirmed,
      ProjectConfirmStatus.Completed,
      ProjectConfirmStatus.Rejected,
    ].includes(data.confirmStatus.code);

  if (showModal) {
    return (
      <ProjectReviewModal
        project={data}
        onHide={() => {
          reset();
          history.push(CarrierRoute.Orders.List.replace(":orderId?", ""));
        }}
      />
    );
  }

  if (isMobileScreen) {
    return (
      <ProjectReviewModalMobile
        project={data}
        onHide={() => {
          reset();
          history.push(CarrierRoute.Orders.List.replace(":orderId?", ""));
        }}
      >
        <div className="order-header">
          <span className="title">{t("orderDetails")}</span>

          <div className="sub-title">
            <span>{data.axCode}</span>
            <span>
              {t("route")} {data.regionTag}
            </span>
          </div>
        </div>

        <ProjectAlerts project={data} place={AlertPlace.Panel} />

        <div
          style={{
            display: "flex",
            paddingBottom: "10px",
            height: "400px",
          }}
          className="location-data"
        >
          <div
            className="map-wrapper"
            style={{ minWidth: "300px", width: "100%" }}
          >
            <ProjectMap project={data} />
          </div>
          <div
            className="location-wrapper"
            style={{ minWidth: "300px", maxWidth: "300px" }}
          >
            <Timeline project={data} />
          </div>
        </div>
        <div>
          <DetailPanel project={data} />
        </div>
      </ProjectReviewModalMobile>
    );
  }

  return (
    <Col lg={5} id="panel-content">
      <div className="order-header">
        <span className="title">{t("orderDetails")}</span>

        <div className="sub-title">
          <span>{data.axCode}</span>

          <span>
            {t("route")} {data.regionTag}
          </span>
        </div>
      </div>

      <ProjectAlerts project={data} place={AlertPlace.Panel} />

      <div
        style={{
          display: "flex",
          paddingBottom: "10px",
          height: "400px",
        }}
      >
        <div style={{ minWidth: "300px", width: "100%" }}>
          <ProjectMap project={data} />
        </div>
        <div style={{ minWidth: "300px", maxWidth: "300px" }}>
          <Timeline project={data} />
        </div>
      </div>
      <div>
        <DetailPanel project={data} />
      </div>
    </Col>
  );
};

export { ProjectContent };
