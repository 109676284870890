import { FC } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Header } from "../../layout/Header";

const Queues: FC = () => {
  return (
    <Container fluid>
      <Row>
        <Header title="Queues" languageDisabled />
      </Row>
      <Row className="site-cache">
        <span>
          To access queues dashboard user must be logged in through azure gates.
        </span>
        <Button
          as={Link}
          className="cache-button"
          target="_blank"
          to="/horizon"
          style={{ width: "180px" }}
        >
          Open dashboard
        </Button>
      </Row>
    </Container>
  );
};

export { Queues };
