import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateNodesFromDOM } from "@lexical/html";
import { $getRoot, $insertNodes } from "lexical";
import { useEffect } from "react";

type Props = {
  html?: string;
};

const HtmlImportPlugin = ({ html }: Props) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (html) {
      editor.update(() => {
        $getRoot().clear();

        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const dom = parser.parseFromString(html, "text/html");

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);

        // Select the root
        $getRoot().select();

        // Insert them at a selection.
        $insertNodes(nodes);

        $getRoot().selectStart();
      });
    }
  }, [editor, html]);

  return null;
};

export default HtmlImportPlugin;
