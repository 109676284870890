export const startSession = () => {
  sessionStorage.removeItem("client-time");

  setInterval(() => {
    const seconds = sessionStorage.getItem("client-time");

    sessionStorage.setItem(
      "client-time",
      seconds ? (Number(seconds) + 1).toString() : "1"
    );
  }, 1000);
};
