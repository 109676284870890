import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { call, delay, put, select, takeLatest } from "typed-redux-saga";
import { translated } from "../../../../../i18n";
import { IFetchListRequest } from "../../../../../services/api/projects";
import { projectAPI } from "../../../../../services/api/projects";
import { AppModule } from "../../../../../services/api/settings";
import { AppState } from "../../../../../store";
import { showError, showSuccess } from "../../../../alert/store/slices";
import { loader } from "../../../../common/loaders/store/slices";
import { profile } from "../../../../profile/store/slices";
import { projectList } from "../slices";
import { completedProjects } from "../slices/completed";

function* completedProjectSaga() {
  yield* takeLatest(projectList.actions.setSearch, debounceSearch);
  yield* takeLatest(completedProjects.actions.setPerPage, fetchList);
  yield* takeLatest(completedProjects.actions.setActivePage, handlePagination);
  yield* takeLatest(profile.actions.setCompanyChanged, handleCompanyChange);
  yield* takeLatest(
    projectList.actions.setClientCompanyId,
    handleClientCompany
  );
  yield* takeLatest(projectList.actions.setConfirmStatus, handleConfirmStatus);
  yield* takeLatest(
    projectList.actions.setLocationStatus,
    handleLocationStatus
  );
  yield* takeLatest(projectList.actions.setDateRange, handleDateRange);
  yield* takeLatest(projectList.actions.setOnlyArchived, handleOnlyArchived);
  yield* takeLatest(projectList.actions.setOnlyAssigned, handleOnlyAssigned);
  yield* takeLatest(
    projectList.actions.setOnlyIncomplete,
    handleOnlyIncomplete
  );
  yield* takeLatest(completedProjects.actions.archive, archive);
  yield* takeLatest(completedProjects.actions.unarchive, unarchive);
}

function* debounceSearch() {
  yield* delay(500);

  yield* put(completedProjects.actions.reset());

  yield* call(fetchList);
}

function* handlePagination() {
  yield* call(fetchList, true);
}

function* handleCompanyChange({
  payload: { appModule },
}: PayloadAction<{ appModule: AppModule }>) {
  if (appModule === AppModule.Projects) {
    yield put(completedProjects.actions.reset());

    yield* call(fetchList);
  }
}

function* handleClientCompany() {
  yield put(completedProjects.actions.reset());

  yield* call(fetchList);
}

function* handleConfirmStatus() {
  yield put(completedProjects.actions.reset());

  yield* call(fetchList);
}

function* handleLocationStatus() {
  yield put(completedProjects.actions.reset());

  yield* call(fetchList);
}

function* handleDateRange() {
  yield put(completedProjects.actions.reset());

  yield* call(fetchList);
}

function* handleOnlyArchived() {
  yield put(completedProjects.actions.reset());

  yield* call(fetchList);
}

function* handleOnlyAssigned() {
  yield put(completedProjects.actions.reset());

  yield* call(fetchList);
}

function* handleOnlyIncomplete() {
  yield put(completedProjects.actions.reset());

  yield* call(fetchList);
}

function* fetchList(concatData?: boolean) {
  yield put(completedProjects.actions.setLoader(true));

  const {
    id,
    search,
    clientCompanyId,
    confirmStatus,
    locationStatus,
    dateRange,
    onlyArchived,
    onlyAssigned,
    onlyIncomplete,
    perPage,
    page,
    selectedCompany,
  } = yield* select((state: AppState) => ({
    id: state.completedProjects.id,
    search: state.projectList.search,
    clientCompanyId: state.projectList.selectedClientCompanyId,
    confirmStatus: state.projectList.confirmStatus,
    locationStatus: state.projectList.locationStatus,
    dateRange: state.projectList.dateRange,
    onlyArchived: state.projectList.onlyArchived,
    onlyAssigned: state.projectList.onlyAssigned,
    onlyIncomplete: state.projectList.onlyIncomplete,
    perPage: state.completedProjects.perPage,
    page: state.completedProjects.activePage,
    selectedCompany: state.profile.data?.selectedCompany,
  }));

  if (confirmStatus && confirmStatus !== id) {
    yield put(completedProjects.actions.setLoader(false));

    return;
  }

  if (selectedCompany) {
    const params: IFetchListRequest = {
      search,
      perPage,
      page,
      companyId: selectedCompany.id,
      onlyArchived: onlyArchived ? 1 : 0,
      onlyAssigned: onlyAssigned ? 1 : 0,
      onlyIncomplete: onlyIncomplete ? 1 : 0,
    };

    params.confirmStatus = id;

    if (locationStatus) {
      params.locationStatus = locationStatus;
    }

    if (clientCompanyId) {
      params.clientCompanyId = clientCompanyId;
    }

    if (dateRange) {
      const [startDate, endDate] = dateRange;

      if (startDate) {
        params.startDate = moment.utc(startDate).format();
      }

      if (endDate) {
        params.endDate = moment.utc(endDate).format();
      }
    }

    const result = yield* call(projectAPI.fetchList, params);

    if (result && result.data && result.meta) {
      if (concatData) {
        yield put(completedProjects.actions.concatData(result));
      } else {
        yield put(completedProjects.actions.setData(result));
      }
    } else {
      yield put(showError(translated.errorWhileGettingProjects));
    }
  }

  yield put(completedProjects.actions.setLoader(false));
}

function* archive({ payload: projectId }: PayloadAction<number>) {
  const result = yield* call(projectAPI.archiveProject, projectId);

  if (result && result.success) {
    yield* put(completedProjects.actions.reset());

    yield* call(fetchList);

    yield put(showSuccess(translated.projectArchived));
  } else {
    yield put(showError(translated.errorWhileArchivingProject));
  }

  yield put(loader.actions.stopLoader());
}

function* unarchive({ payload: projectId }: PayloadAction<number>) {
  const result = yield* call(projectAPI.unarchiveProject, projectId);

  if (result && result.success) {
    yield* put(completedProjects.actions.reset());

    yield* call(fetchList);

    yield put(showSuccess(translated.projectUnarchived));
  } else {
    yield put(showError(translated.errorWhileUnarchivingProject));
  }

  yield put(loader.actions.stopLoader());
}

export { completedProjectSaga };
