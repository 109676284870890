import { createSlice } from "@reduxjs/toolkit";

export interface IRegistrationState {
  registered: boolean;
}

const initialState: IRegistrationState = {
  registered: false,
};

const registration = createSlice({
  name: "registration",
  initialState,
  reducers: {
    register: (state, action) => {
      //
    },
    sendVerification: (state) => {
      //
    },
    verify: (state, actions) => {
      //
    },
    setRegistered: (state) => {
      state.registered = true;
    },
    reset: () => initialState,
  },
});

export { registration };
