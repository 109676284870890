import { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import { AdminRoute } from "../../../../../constants/routes";

interface IProps {
  cell: any;
}

const ActionCell: FC<IProps> = ({ cell }) => {
  return (
    <Dropdown id="dropdown">
      <Dropdown.Toggle size="sm" variant="outline-primary" id="dropdown-toggle">
        <FaCaretDown />
      </Dropdown.Toggle>

      <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
        <Dropdown.Item
          as={Link}
          to={`${AdminRoute.Companies.Update.replace(
            ":companyId",
            cell.row.original.id
          )}`}
        >
          Edit company
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { ActionCell };
