import { FC, useCallback, useEffect } from "react";
import { Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppModule } from "../../services/api/settings";
import { Loader } from "../common/loaders/Loader";
import { Header } from "../layout/Header";
import { userShow } from "./show/store/slices";
import { UserShow } from "./show/UserShow";

const User: FC = () => {
  const { t } = useTranslation("userShow");

  const { userId: id } = useParams<{ userId: string }>();

  const user = useSelector((state) => state.userShow.data);

  const dispatch = useDispatch();

  const fetchUser = useCallback(
    () => dispatch(userShow.actions.fetchUser(id)),
    [dispatch, id]
  );

  const reset = useCallback(() => dispatch(userShow.actions.reset()), [
    dispatch,
  ]);

  useEffect(() => {
    fetchUser();

    return () => {
      reset();
    };
  }, [fetchUser, reset]);

  return (
    <Container className="users" fluid>
      <Row>
        <Header
          title={t("user") as string}
          showCompanySelect
          companySelectDisabled
          appModule={AppModule.Users}
        />
      </Row>
      <Row>{user ? <UserShow user={user} /> : <Loader />}</Row>
    </Container>
  );
};

export { User };
