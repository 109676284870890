import { fork, take, call, put } from "typed-redux-saga";
import { projectAPI } from "../../../../services/api/projects";
import { projectChangeLog } from "./slice";

function* projectChangeLogSaga() {
  yield* fork(watchForFetch);
}

function* watchForFetch() {
  while (true) {
    const { payload } = yield take(projectChangeLog.actions.fetch);

    if (payload.id && payload.page) {
      yield* call(fetchLog, payload.id, payload.page);
    }
  }
}

function* fetchLog(id: number, page: number) {
  yield* put(projectChangeLog.actions.setIsLoading(true));

  const result = yield* call(projectAPI.fetchChangeLog, id, page);

  if (result && result.data && result.meta) {
    yield* put(projectChangeLog.actions.setData(result));
  }

  yield* put(projectChangeLog.actions.setIsLoading(false));
}

export { projectChangeLogSaga };
