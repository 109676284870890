import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AdminRoute } from "../../../constants/routes";
import { formatDateTime } from "../../../utils/date-format";
import { Header } from "../../layout/Header";
import { adminStatisticPolicies } from "./store/slices";
import { BsFillCaretUpFill } from "react-icons/bs";
import { SubHeader } from "../../layout/SubHeader";
import { StatisticGroup } from "../../../services/api/projects";
import { StatisticPolicy } from "../../../services/api/admin/statistic-policies";

const StatisticPolicies: FC = () => {
  const dispatch = useDispatch();

  const [selectedGroup, setSelectedGroup] = useState<StatisticGroup>();

  const policies = useSelector(
    (state) => state.adminStatisticPolicies.policies
  );
  const policiesLoading = useSelector(
    (state) => state.adminStatisticPolicies.policiesLoading
  );

  const fetchPolicies = useCallback(
    () => dispatch(adminStatisticPolicies.actions.fetchPolicies()),
    [dispatch]
  );

  const deletePolicy = useCallback(
    (policy: StatisticPolicy) =>
      dispatch(adminStatisticPolicies.actions.deletePolicy(policy)),
    [dispatch]
  );

  const activatePolicy = useCallback(
    (policy: StatisticPolicy) =>
      dispatch(adminStatisticPolicies.actions.activatePolicy(policy)),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(adminStatisticPolicies.actions.resetState()),
    [dispatch]
  );

  useEffect(() => {
    fetchPolicies();

    return () => {
      reset();
    };
  }, [fetchPolicies, reset]);

  const filteredPolicies = useMemo(() => {
    let filtered = policies;

    if (selectedGroup) {
      filtered = filtered.filter(
        (policy) => policy.group.code === selectedGroup
      );
    }

    return filtered;
  }, [policies, selectedGroup]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Header title="Special client policies" languageDisabled />
          <SubHeader
            left={<div />}
            right={
              <Button as={Link} to={AdminRoute.StatisticPolicies.Store}>
                New policy
              </Button>
            }
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ marginBottom: "5rem" }}>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>
                    <div>
                      <span>Group</span>
                      <Form.Group
                        controlId="client-group-code"
                        style={{ marginBottom: "0rem" }}
                      >
                        <Form.Control
                          name="client-group-code"
                          as="select"
                          size="sm"
                          value={selectedGroup}
                          onChange={(e) =>
                            setSelectedGroup(e.target.value as StatisticGroup)
                          }
                        >
                          <option value="">All</option>
                          {Object.values(StatisticGroup).map((code, index) => (
                            <option key={index} value={code}>
                              {code}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </th>
                  <th>
                    <BsFillCaretUpFill /> Created
                  </th>
                  <th>Orders</th>
                  <th>Activated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!!(
                  !policiesLoading &&
                  (policies.length === 0 || filteredPolicies.length === 0)
                ) && (
                  <tr>
                    <td colSpan={8}>No data</td>
                  </tr>
                )}

                {!policiesLoading &&
                  filteredPolicies.map((policy, index) => (
                    <tr key={index}>
                      <td>{policy.id}</td>
                      <td>{policy.group.code}</td>
                      <td>
                        {policy.created_at && (
                          <span>{formatDateTime(policy.created_at)}</span>
                        )}
                      </td>
                      <td>{policy.project_count}</td>
                      <td>
                        <span>
                          <Badge
                            variant={policy.is_active ? "success" : "danger"}
                          >
                            {policy.is_active ? "Active" : "Inactive"}
                          </Badge>
                        </span>
                      </td>
                      <td>
                        <Dropdown id="dropdown">
                          <Dropdown.Toggle
                            size="sm"
                            variant="outline-primary"
                            id="dropdown-toggle"
                          >
                            <FaCaretDown />
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            popperConfig={{ strategy: "fixed" }}
                            renderOnMount
                          >
                            <Dropdown.Item
                              as={Link}
                              to={`${AdminRoute.StatisticPolicies.Update.replace(
                                ":id",
                                policy.id.toString()
                              )}`}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={policy.is_active}
                              onClick={() => activatePolicy(policy)}
                            >
                              Activate
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={
                                policy.is_active || !!policy.project_count
                              }
                              onClick={() => deletePolicy(policy)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export { StatisticPolicies };
