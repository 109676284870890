import { API, HTTPMethod, SuccessResponse } from ".";
const acceptPolicies = async (): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(`/profile/policies`, {
    method: HTTPMethod.POST,
    version: "v2",
    jsonRequest: true,
  });

  return response;
};

const submitMarketingPolicy = async (
  consent: boolean
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/profile/marketing-policy`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({ consent })
  );

  return response;
};

const policyAPI = {
  acceptPolicies,
  submitMarketingPolicy,
};

export { policyAPI };
