import { FC, useMemo, useState } from "react";
import dashboardToSeeUrl from "../screenshots/dashboard-tosee.png";
import dashboardToDoUrl from "../screenshots/dashboard-todo.png";
import qpayUrl from "../screenshots/qpay.png";
import unconfirmedUrl from "../screenshots/order-modal-first.png";
import unconfirmedSecondUrl from "../screenshots/order-modal-second.png";
import confirmedResponsabilitiesUrl from "../screenshots/confirmed-order-responsibilities.png";
import confirmedDocumentsUrl from "../screenshots/confirmed-order-documents.png";
import confirmedChangelogUrl from "../screenshots/confirmed-order-changelog.png";
import { useTranslation } from "react-i18next";
import { IHotspotProps } from "../../../common/hotspot/Hotspot";
import { Screenshot } from "../screenshots/Screenshot";

const ProjectPage: FC = () => {
  const { t } = useTranslation("projectPageWizard");

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const toSeeHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 95, top: 155 },
        guideLines: [t("onTheOrdersPage")],
      },
      {
        order: 2,
        style: { left: 220, top: 162 },
        guideLines: [t("statusOfYourOrders")],
      },
      {
        order: 3,
        style: { left: 584, top: 430 },
        guideLines: [t("hoverOverOrderRow")],
      },
      {
        order: 4,
        style: { left: 190, top: 335 },
        guideLines: [t("statusOfYourAssignedOrders")],
      },
      {
        order: 5,
        style: { left: 200, top: 255 },
        guideLines: [t("documentsRelatedToOrder")],
      },
      {
        order: 6,
        style: { left: 195, top: 412 },
        guideLines: [t("managerWhoAccepted")],
      },
      {
        order: 7,
        style: { left: 843, top: 215 },
        guideLines: [t("dateYouReceivedTheOrder")],
      },
      {
        order: 8,
        style: { left: 653, top: 130 },
        guideLines: [t("filters")],
      },
    ],
    [t]
  );

  const todoHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 250, top: 92 },
        guideLines: [t("easilySwitchToAnotherCompany")],
      },
      {
        order: 2,
        style: { left: 603, top: 92 },
        guideLines: [t("searchForOrders")],
      },
      {
        order: 3,
        style: { left: 320, top: 130 },
        guideLines: [t("filterOrderByStatus")],
      },
      {
        order: 4,
        style: { left: 550, top: 130 },
        guideLines: [t("filterBySpecificTime")],
      },
      {
        order: 5,
        style: { left: 640, top: 150 },
        guideLines: [t("filterStillMissingOrders")],
      },
      {
        order: 6,
        style: { left: 710, top: 150 },
        guideLines: [t("filterOnlyAssignedOrders")],
      },
      {
        order: 7,
        style: { left: 770, top: 150 },
        guideLines: [t("filterArchivedOrders")],
      },
      {
        order: 8,
        style: { left: 585, top: 450 },
        guideLines: [t("clickOrderFindDetails")],
      },
      {
        order: 9,
        style: { left: 200, top: 130 },
        guideLines: [t("clientCompanyFilter")],
      },
    ],
    [t]
  );

  const qpayHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 990, top: 200 },
        guideLines: [t("qpServiceIsAvailable")],
      },
      {
        order: 2,
        style: { left: 990, top: 300 },
        guideLines: [t("qpServiceCanBeOrdered")],
      },
      {
        order: 3,
        style: { left: 990, top: 400 },
        guideLines: [t("qpPending")],
      },
      {
        order: 4,
        style: { left: 990, top: 500 },
        guideLines: [t("qpConfirmed")],
      },
    ],
    [t]
  );

  const unconfirmedHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 777, top: 245 },
        guideLines: [t("assignManagerForOrder")],
      },
    ],
    [t]
  );

  const unconfirmedSecondHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 743, top: 312 },
        guideLines: [t("enterTruckDriverInfo")],
      },
      {
        order: 3,
        style: { left: 428, top: 428 },
        guideLines: [t("readGeneralRules")],
      },
      {
        order: 4,
        style: { left: 561, top: 471 },
        guideLines: [t("acceptRejectOrder")],
      },
    ],
    [t]
  );

  const orderModalHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 700, top: 220 },
        guideLines: [t("readAllDetails")],
      },
      {
        order: 2,
        style: { left: 800, top: 220 },
        guideLines: [t("seeAllPeople")],
      },
      {
        order: 3,
        style: { left: 753, top: 300 },
        guideLines: [t("enterOrChangeTruckDriver")],
      },
      {
        order: 4,
        style: { left: 755, top: 400 },
        guideLines: [t("changeManager")],
      },
      {
        order: 5,
        style: { left: 935, top: 420 },
        guideLines: [t("girtekaManagerInfo")],
      },
      {
        order: 6,
        style: { left: 850, top: 500 },
        guideLines: [t("saveChanges")],
      },
    ],
    [t]
  );

  const orderModalSecondHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 900, top: 230 },
        guideLines: [t("newSectionDocuments")],
      },
      {
        order: 2,
        style: { left: 779, top: 360 },
        guideLines: [t("autoGeneratedOrders")],
      },
      {
        order: 3,
        style: { left: 970, top: 350 },
        guideLines: [t("abilityToUploadDocument")],
      },
    ],
    [t]
  );

  const orderModalThirdHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 985, top: 325 },
        guideLines: [t("changelog")],
      },
    ],
    [t]
  );

  return (
    <div className="module-page" style={{ opacity: isLoading ? 0 : 1 }}>
      <Screenshot
        url={dashboardToSeeUrl}
        label={t("dashboardWhatAbleToSee") as string}
        hotspots={toSeeHotspots}
        imageLoaded={() => setIsLoading(false)}
      />

      <Screenshot
        url={dashboardToDoUrl}
        label={t("dashboardWhatActions") as string}
        hotspots={todoHotspots}
        imageLoaded={() => setIsLoading(false)}
      />

      <Screenshot
        url={qpayUrl}
        hotspots={qpayHotspots}
        imageLoaded={() => setIsLoading(false)}
      />

      <hr />

      <Screenshot
        url={unconfirmedUrl}
        label={t("unconfirmedOrderView") as string}
        hotspots={unconfirmedHotspots}
        imageLoaded={() => setIsLoading(false)}
      />

      <Screenshot
        url={unconfirmedSecondUrl}
        hotspots={unconfirmedSecondHotspots}
        imageLoaded={() => setIsLoading(false)}
      />

      <hr />

      <Screenshot
        url={confirmedResponsabilitiesUrl}
        label={t("confirmedOrderView") as string}
        hotspots={orderModalHotspots}
        imageLoaded={() => setIsLoading(false)}
      />

      <Screenshot
        url={confirmedDocumentsUrl}
        hotspots={orderModalSecondHotspots}
        imageLoaded={() => setIsLoading(false)}
      />

      <Screenshot
        url={confirmedChangelogUrl}
        hotspots={orderModalThirdHotspots}
        imageLoaded={() => setIsLoading(false)}
      />
    </div>
  );
};

export { ProjectPage };
