import { FC } from "react";
import { Redirect } from "react-router-dom";
import { PublicRoute } from "../../constants/routes";
import { useSelector } from "react-redux";

const MaintenanceRedirect: FC = () => {
  const isVisible = useSelector((state) => state.maintenance.pageVisible);

  if (!isVisible) return null;

  return <Redirect to={PublicRoute.Maintenance} />;
};

export { MaintenanceRedirect };
