import { startCase } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Accordion, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { AdminRoute } from "../../../constants/routes";
import { LanguageCode } from "../../../i18n";
import {
  ProjectPolicyCode,
  ProjectPolicyTitleCode,
} from "../../../services/api/admin/project-policies";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import Editor from "../../editor/Editor";
import { Header } from "../../layout/Header";
import { adminProjectPolicies } from "./store/slices";
import { useTranslation } from "react-i18next";

const ProjectPolicyStore = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedClientCompanyId, setSelectedClientCompanyId] = useState<
    number
  >();
  const [selectedGroupCode, setSelectedGroupCode] = useState<ProjectPolicyCode>(
    ProjectPolicyCode.FirstPolicy
  );
  const [selectedTitleCode, setSelectedTitleCode] = useState<
    ProjectPolicyTitleCode
  >(ProjectPolicyTitleCode.ProceduralRules);

  const newPolicyPublished = useSelector(
    (state) => state.adminProjectPolicies.newPolicyPublished
  );
  const clientCompanies = useSelector(
    (state) => state.adminProjectPolicies.clientCompanies
  );
  const isLoading = useSelector((state) => state.loader.isLoading);

  const fetchClientCompanies = useCallback(
    () => dispatch(adminProjectPolicies.actions.fetchClientCompanies()),
    [dispatch]
  );

  const storePolicy = useCallback(
    (
      groupCode: ProjectPolicyCode,
      titleCode: ProjectPolicyTitleCode,
      clientCompanyId?: number
    ) =>
      dispatch(
        adminProjectPolicies.actions.storePolicy({
          groupCode,
          titleCode,
          clientCompanyId,
        })
      ),
    [dispatch]
  );

  const setNewPolicyData = useCallback(
    (languageCode: LanguageCode, html: string) =>
      dispatch(
        adminProjectPolicies.actions.setNewPolicyData({ languageCode, html })
      ),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(adminProjectPolicies.actions.resetState()),
    [dispatch]
  );

  useEffect(() => {
    fetchClientCompanies();

    return () => {
      reset();
    };
  }, [fetchClientCompanies, reset]);

  useEffect(() => {
    if (newPolicyPublished) {
      const url = selectedClientCompanyId
        ? `${AdminRoute.ProjectPolicies.List}?client_company_id=${selectedClientCompanyId}`
        : AdminRoute.ProjectPolicies.List;

      history.push(url);
    }
  }, [history, newPolicyPublished, selectedClientCompanyId]);

  const filteredTitles = useMemo(() => {
    if (selectedGroupCode === ProjectPolicyCode.FirstPolicy) {
      return [
        ProjectPolicyTitleCode.SpecialConditions,
        ProjectPolicyTitleCode.ProceduralRules,
      ];
    }

    if (selectedGroupCode === ProjectPolicyCode.SecondPolicy) {
      return [
        ProjectPolicyTitleCode.AdditionalInformation,
        ProjectPolicyTitleCode.SpecialAndGeneralConditions,
      ];
    }

    return Object.values(ProjectPolicyTitleCode);
  }, [selectedGroupCode]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Header title="New policy" languageDisabled />
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ display: "flex" }}>
            <Form.Group controlId="client-company-id">
              <Form.Label>Select company:</Form.Label>
              <Form.Control
                name="client-company-id"
                as="select"
                value={selectedClientCompanyId}
                onChange={(e) =>
                  setSelectedClientCompanyId(Number(e.target.value))
                }
              >
                <option value="">All</option>
                {Object.values(clientCompanies).map((company, index) => (
                  <option key={index} value={company.id}>
                    {startCase(company.name)}
                  </option>
                ))}
              </Form.Control>
              {!selectedClientCompanyId && (
                <Form.Text className="text-danger">
                  Policy will be created for each company.
                </Form.Text>
              )}
            </Form.Group>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ display: "flex" }}>
            <Form.Group controlId="group-code">
              <Form.Label>Select group:</Form.Label>
              <Form.Control
                name="group-code"
                as="select"
                value={selectedGroupCode}
                onChange={(e) =>
                  setSelectedGroupCode(e.target.value as ProjectPolicyCode)
                }
              >
                {Object.values(ProjectPolicyCode).map((code, index) => (
                  <option key={index} value={code}>
                    {startCase(code)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ display: "flex" }}>
            <Form.Group controlId="title-code">
              <Form.Label>Select title:</Form.Label>
              <Form.Control
                name="title-code"
                as="select"
                value={selectedTitleCode}
                onChange={(e) =>
                  setSelectedTitleCode(e.target.value as ProjectPolicyTitleCode)
                }
              >
                {Object.values(filteredTitles).map((code, index) => (
                  <option key={index} value={code}>
                    {t(code, { ns: "policies" })}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Accordion className="language-accordion">
            {Object.values(LanguageCode).map((languageCode) => (
              <Card key={languageCode}>
                <Accordion.Toggle as={Card.Header} eventKey={languageCode}>
                  <Link to="#">{languageCode.toUpperCase()}</Link>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={languageCode}>
                  <Card.Body>
                    <Editor
                      handleUpdate={(html) =>
                        setNewPolicyData(languageCode, html)
                      }
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="publish-wrapper">
            <ButtonLoader
              buttonDisabled={isLoading}
              disabled={isLoading}
              onClick={() => {
                if (selectedGroupCode && selectedTitleCode) {
                  storePolicy(
                    selectedGroupCode,
                    selectedTitleCode,
                    selectedClientCompanyId
                  );
                }
              }}
            >
              Create
            </ButtonLoader>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectPolicyStore;
