import { FC } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { INotificationSettings } from "../../services/api/notificationSettings";
import { ICarrierSettings } from "../../services/api/settings";
import { ButtonLoader } from "../common/button-loader/ButtonLoader";

export interface INotificationSettingsForm {
  types: { [type: string]: boolean };
  interval: string;
}

interface IProps {
  mainSettings: ICarrierSettings;
  settings: INotificationSettings;
  isLoading: boolean;
  updateSettings: (data: INotificationSettingsForm) => void;
}

const NotificationSettingsForm: FC<IProps> = ({
  mainSettings,
  settings,
  isLoading,
  updateSettings,
}) => {
  const { t } = useTranslation("notificationSettings");

  const { watch, register, handleSubmit } = useForm<INotificationSettingsForm>({
    defaultValues: settings,
  });

  const onSubmit = (data: INotificationSettingsForm) => {
    updateSettings(data);
  };

  const isRadioDisabled = Object.values(watch("types")).every(
    (enabled) => !enabled
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h4>{t("notifyMeWhen")}</h4>
        {mainSettings.notifications.types.map((type) => (
          <Form.Check
            key={type}
            ref={register}
            type="checkbox"
            id={type}
            name={`types.${type}`}
            label={t(type)}
          />
        ))}
      </div>
      <div>
        <h4>{t("sendEmail")}</h4>
        {mainSettings.notifications.intervals.map((interval) => (
          <>
            <Form.Check
              key={interval}
              ref={register}
              type="radio"
              id={interval}
              name="interval"
              value={interval}
              label={t(interval)}
              disabled={isRadioDisabled}
            />
          </>
        ))}
      </div>
      <ButtonLoader type="submit" disabled={isLoading}>
        {t("save")}
      </ButtonLoader>
    </Form>
  );
};

export { NotificationSettingsForm };
