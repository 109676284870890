import { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";

interface IProps {
  isVisible: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

const DeleteProfileModal: FC<IProps> = ({
  isVisible,
  handleClose,
  handleDelete,
}) => {
  const { t } = useTranslation("deleteProfileModal");

  return (
    <Modal show={isVisible} onHide={handleClose} backdrop="static">
      <Modal.Header className="no-bottom-border">
        <Modal.Title>
          <FaTrash /> {t("deleteProfile")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("ifYouDeleteProfile")}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button variant="primary" onClick={handleDelete}>
          {t("delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { DeleteProfileModal };
