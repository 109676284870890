import { Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { ApplicationQuestions } from "../application/form/NewPartnerForm";
import { FormContext, useForm } from "react-hook-form";
import { Loader } from "../common/loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import styles from "./questionnaireModal.module.scss";
import { FileDropzone } from "../common/files/FileDropzone";
import { FileDropdown } from "../application/form/FileDropdown";
import { AiFillSafetyCertificate } from "react-icons/ai";
import questionnaireModalSlice from "./store/slices";
import { ButtonLoader } from "../common/button-loader/ButtonLoader";

export type QuestionnaireForm = {
  questions: ApplicationQuestions;
};

type Props = {
  isVisible: boolean;
  handleDeny: () => void;
};

const QuestionnaireModal = ({ isVisible, handleDeny }: Props) => {
  const { t } = useTranslation("application");

  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings.carrier);
  const profile = useSelector((state) => state.profile.data);
  const documents = useSelector((state) => state.questionnaireModal.documents);
  const documentsFetching = useSelector(
    (state) => state.questionnaireModal.documentsFetching
  );
  const isSubmitting = useSelector(
    (state) => state.questionnaireModal.isSubmitting
  );
  const formSubmitted = useSelector(
    (state) => state.questionnaireModal.formSubmitted
  );

  const [
    showProductSafetyFileValidation,
    setShowProductSafetyFileValidation,
  ] = useState(false);
  const [
    showExpertiseSecurityFileValidation,
    setShowExpertiseSecurityFileValidation,
  ] = useState(false);

  const methods = useForm<QuestionnaireForm>({
    defaultValues: {
      questions: {
        productSafetyManagement: "",
        foodSafetyPolicy: "",
        haccpPlan: "",
        chemicalsInCleansing: "",
        pestControlSystem: "",
        cleaningSchedule: "",
        proceduresForOperation: "",
        expertiseSecurityManagement: "",
        securityPolicy: "",
        hvttCargo: "",
        secureVehicleParking: "",
        routesAndStops: "",
        driverAssessment: "",
      },
    },
  });

  const { register, handleSubmit, errors, clearError, watch } = methods;

  const uploadDocuments = useCallback(
    (documents: File[]) => {
      dispatch(
        questionnaireModalSlice.actions.uploadDocuments({
          companyId: profile?.selectedCompany?.id,
          documents,
        })
      );

      clearError("documents");
    },
    [profile, dispatch, clearError]
  );

  const updateDocument = useCallback(
    (documentId: number, documentTypeId: number) => {
      dispatch(
        questionnaireModalSlice.actions.updateDocument({
          companyId: profile?.selectedCompany?.id,
          documentId,
          data: {
            documentTypeId,
          },
        })
      );

      clearError("documents");
    },
    [profile, dispatch, clearError]
  );

  const deleteDocument = useCallback(
    (id: number) => {
      dispatch(
        questionnaireModalSlice.actions.deleteDocument({
          companyId: profile?.selectedCompany?.id,
          documentId: id,
        })
      );

      clearError("documents");
    },
    [profile, dispatch, clearError]
  );

  const submitForm = useCallback(
    (data: QuestionnaireForm) => {
      dispatch(
        questionnaireModalSlice.actions.submitForm({
          companyId: profile?.selectedCompany?.id,
          data,
        })
      );
    },
    [dispatch, profile]
  );

  const reset = useCallback(
    () => dispatch(questionnaireModalSlice.actions.reset()),
    [dispatch]
  );

  const handleClose = useCallback(() => {
    handleDeny();
    reset();
  }, [handleDeny, reset]);

  const validateFiles = (formData: QuestionnaireForm) => {
    let isValid = true;

    if (
      // has "ISO22001/ BRC/ IFS"
      Number(formData.questions.productSafetyManagement) &&
      !documents.find((document) => document.type?.code === "PS_COMP")
    ) {
      setShowProductSafetyFileValidation(true);
      if (productSafetyRef && productSafetyRef.current) {
        productSafetyRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
      isValid = false;
    } else if (showProductSafetyFileValidation) {
      setShowProductSafetyFileValidation(false);
    }
    if (
      // has "TAPA TSR/ ISO28001"
      Number(formData.questions.expertiseSecurityManagement) &&
      !documents.find((document) => document.type?.code === "SEC_COMP")
    ) {
      setShowExpertiseSecurityFileValidation(true);
      if (expertiseSecurityRef && expertiseSecurityRef.current) {
        expertiseSecurityRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
      isValid = false;
    } else if (showExpertiseSecurityFileValidation) {
      setShowExpertiseSecurityFileValidation(false);
    }
    if (isValid) {
      submitForm(formData);
    }
  };

  useEffect(() => {
    if (formSubmitted) {
      handleClose();
    }
  }, [formSubmitted, handleClose]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const productSafetyManagement = watch("questions.productSafetyManagement");
  const expertiseSecurityManagement = watch(
    "questions.expertiseSecurityManagement"
  );

  const showProductSafetyQuestions =
    productSafetyManagement !== "" && !Number(productSafetyManagement);
  const showExpertiseSecurityQuestions =
    expertiseSecurityManagement !== "" && !Number(expertiseSecurityManagement);

  const showProductSafetyFileMessage =
    productSafetyManagement !== "" && Boolean(Number(productSafetyManagement));
  const showExpertiseSecurityFileMessage =
    expertiseSecurityManagement !== "" &&
    Boolean(Number(expertiseSecurityManagement));

  const productSafetyRef = useRef<null | HTMLDivElement>(null);
  const expertiseSecurityRef = useRef<null | HTMLDivElement>(null);

  if (!settings) {
    return <Loader />;
  }

  return (
    <Modal
      show={isVisible}
      onHide={handleClose}
      size="xl"
      scrollable
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <AiFillSafetyCertificate /> {t("needYourCertificates")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormContext {...methods}>
          <Row>
            <Col />
            <Col lg={10}>
              <p className={styles.intro}>{t("asOurValuedSupllier")}</p>
            </Col>
            <Col />
          </Row>

          <hr />

          <Row className={styles.questionsRow}>
            <Col />
            <Col lg={10}>
              <Form
                id="questionnaire-form"
                onSubmit={handleSubmit(validateFiles)}
                autoComplete="off"
              >
                <div className={styles.mainQuestion}>
                  <div>{t("productSafetyManagement")}</div>
                  <div className={styles.radios} key="inline-radio">
                    <Form.Check
                      inline
                      label={t("yes")}
                      name="questions.productSafetyManagement"
                      value={1}
                      type="radio"
                      id={`productSafetyManagement-radio-1`}
                      ref={register({ required: true })}
                      isInvalid={!!errors.questions?.productSafetyManagement}
                    />
                    <Form.Check
                      inline
                      defaultChecked
                      label={t("no")}
                      name="questions.productSafetyManagement"
                      value={0}
                      type="radio"
                      id={`productSafetyManagement-radio-2`}
                      ref={register({ required: true })}
                      isInvalid={!!errors.questions?.productSafetyManagement}
                    />
                  </div>
                </div>

                {showProductSafetyFileMessage && (
                  <div className={styles.addDocumentWarning}>
                    <small
                      ref={productSafetyRef}
                      className={`${
                        showProductSafetyFileValidation
                          ? "text-danger"
                          : "text-secondary"
                      }`}
                    >
                      {t("addIsoToCompanyDocuments")}
                    </small>
                  </div>
                )}

                {showProductSafetyQuestions && (
                  <ListGroup
                    style={{ marginBottom: "2.5rem" }}
                    className={styles.listGroup}
                  >
                    <ListGroup.Item>
                      <div>{t("foodSafetyPolicy")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.foodSafetyPolicy"
                          value={1}
                          type="radio"
                          id={`foodSafetyPolicy-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.foodSafetyPolicy}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.foodSafetyPolicy"
                          value={0}
                          type="radio"
                          id={`foodSafetyPolicy-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.foodSafetyPolicy}
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>{t("haccpPlan")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.haccpPlan"
                          value={1}
                          type="radio"
                          id={`haccpPlan-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.haccpPlan}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.haccpPlan"
                          value={0}
                          type="radio"
                          id={`haccpPlan-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.haccpPlan}
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>{t("chemicalsInCleansing")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.chemicalsInCleansing"
                          value={1}
                          type="radio"
                          id={`chemicalsInCleansing-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.chemicalsInCleansing}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.chemicalsInCleansing"
                          value={0}
                          type="radio"
                          id={`chemicalsInCleansing-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.chemicalsInCleansing}
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>{t("pestControlSystem")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.pestControlSystem"
                          value={1}
                          type="radio"
                          id={`pestControlSystem-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.pestControlSystem}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.pestControlSystem"
                          value={0}
                          type="radio"
                          id={`pestControlSystem-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.pestControlSystem}
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>{t("cleaningSchedule")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.cleaningSchedule"
                          value={1}
                          type="radio"
                          id={`cleaningSchedule-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.cleaningSchedule}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.cleaningSchedule"
                          value={0}
                          type="radio"
                          id={`cleaningSchedule-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.cleaningSchedule}
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>{t("proceduresForOperation")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.proceduresForOperation"
                          value={1}
                          type="radio"
                          id={`proceduresForOperation-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.proceduresForOperation}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.proceduresForOperation"
                          value={0}
                          type="radio"
                          id={`proceduresForOperation-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.proceduresForOperation}
                        />
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                )}

                <div className={styles.mainQuestion}>
                  <div>{t("expertiseSecurityManagement")}</div>
                  <div className={styles.radios} key="inline-radio">
                    <Form.Check
                      inline
                      label={t("yes")}
                      name="questions.expertiseSecurityManagement"
                      value={1}
                      type="radio"
                      id={`expertiseSecurityManagement-radio-1`}
                      ref={register({ required: true })}
                      isInvalid={
                        !!errors.questions?.expertiseSecurityManagement
                      }
                    />
                    <Form.Check
                      inline
                      defaultChecked
                      label={t("no")}
                      name="questions.expertiseSecurityManagement"
                      value={0}
                      type="radio"
                      id={`expertiseSecurityManagement-radio-2`}
                      ref={register({ required: true })}
                      isInvalid={
                        !!errors.questions?.expertiseSecurityManagement
                      }
                    />
                  </div>
                </div>

                {showExpertiseSecurityFileMessage && (
                  <div className={styles.addDocumentWarning}>
                    <small
                      ref={expertiseSecurityRef}
                      className={`${
                        showExpertiseSecurityFileValidation
                          ? "text-danger"
                          : "text-secondary"
                      }`}
                    >
                      {t("addTapaToCompanyDocuments")}
                    </small>
                  </div>
                )}

                {showExpertiseSecurityQuestions && (
                  <ListGroup
                    className={styles.listGroup}
                    style={{ marginBottom: "2rem" }}
                  >
                    <ListGroup.Item>
                      <div>{t("securityPolicy")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.securityPolicy"
                          value={1}
                          type="radio"
                          id={`securityPolicy-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.securityPolicy}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.securityPolicy"
                          value={0}
                          type="radio"
                          id={`securityPolicy-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.securityPolicy}
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>{t("hvttCargo")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.hvttCargo"
                          value={1}
                          type="radio"
                          id={`hvttCargo-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.hvttCargo}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.hvttCargo"
                          value={0}
                          type="radio"
                          id={`hvttCargo-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.hvttCargo}
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>{t("secureVehicleParking")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.secureVehicleParking"
                          value={1}
                          type="radio"
                          id={`secureVehicleParking-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.secureVehicleParking}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.secureVehicleParking"
                          value={0}
                          type="radio"
                          id={`secureVehicleParking-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.secureVehicleParking}
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>{t("routesAndStops")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.routesAndStops"
                          value={1}
                          type="radio"
                          id={`routesAndStops-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.routesAndStops}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.routesAndStops"
                          value={0}
                          type="radio"
                          id={`routesAndStops-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.routesAndStops}
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>{t("driverAssessment")}</div>
                      <div className={styles.radios} key="inline-radio">
                        <Form.Check
                          inline
                          label={t("yes")}
                          name="questions.driverAssessment"
                          value={1}
                          type="radio"
                          id={`driverAssessment-radio-1`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.driverAssessment}
                        />
                        <Form.Check
                          inline
                          defaultChecked
                          label={t("no")}
                          name="questions.driverAssessment"
                          value={0}
                          type="radio"
                          id={`driverAssessment-radio-2`}
                          ref={register({ required: true })}
                          isInvalid={!!errors.questions?.driverAssessment}
                        />
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                )}
              </Form>
            </Col>
            <Col />
          </Row>

          <hr />

          <Row className={styles.documentsRow}>
            <Col />
            <Col lg={10} xl={10}>
              {documentsFetching && (
                <div className={styles.documentsFetching}>
                  <Loader isLoading />
                </div>
              )}
              <FileDropzone
                onDropAccepted={uploadDocuments}
                maxSize={settings.general.maxUploadSize * 1000}
                multiple
              />
              {documents.length > 0 && (
                <FileDropdown
                  files={documents}
                  onDelete={deleteDocument}
                  onUpdate={updateDocument}
                  required={true}
                  documentTypes={
                    settings
                      ? settings.applications.documentTypes.filter((type) =>
                          ["PS_COMP", "SEC_COMP"].includes(type.code as string)
                        )
                      : []
                  }
                />
              )}
            </Col>
            <Col />
          </Row>
        </FormContext>
      </Modal.Body>
      <Modal.Footer>
        <ButtonLoader
          disabled={isSubmitting}
          type="submit"
          form="questionnaire-form"
          variant="primary"
        >
          {t("submit")}
        </ButtonLoader>
      </Modal.Footer>
    </Modal>
  );
};

export default QuestionnaireModal;
