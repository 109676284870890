import * as Sentry from "@sentry/react";

const error = (message: string, event?: any) => {
  Sentry.captureMessage(message, "error");
};

const warning = (message: string, event?: any) => {
  // Sentry.captureMessage(message, "warning");
};

const info = (message: string, event?: any) => {
  // Sentry.captureMessage(message, "info");
};

/**
 * App logger
 */
const log = {
  error,
  warning,
  info,
};

export { log };
