import { FC, useCallback, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../layout/Header";
import { adminActivityLog } from "./store/slices";
import { ActivityLogTable } from "./table/ActivityLogTable";

const ActivityLog: FC = () => {
  const searchQuery = useSelector((state) => state.adminActivityLog.search);

  const dispatch = useDispatch();

  const search = useCallback(
    (query: string) => dispatch(adminActivityLog.actions.setSearch(query)),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(adminActivityLog.actions.reset()), [
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Container fluid>
      <Row>
        <Header
          title="Activity log"
          handleSearch={search}
          initialSearch={searchQuery}
          languageDisabled
        />
      </Row>
      <Row>
        <ActivityLogTable />
      </Row>
    </Container>
  );
};

export { ActivityLog };
