import { FC } from "react";
import { useSelector } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { PublicRoute as PublicRoutes } from "../../constants/routes";
import { authAPI } from "../../services/api/auth";
import { GlobalLoader } from "../common/loaders/GlobalLoader";
import { PublicTemplate } from "../layout/PublicTemplate";
import { LoginRedirect } from "./LoginRedirect";

interface IProps extends RouteProps {
  Component: React.FC<{}>;
}

const PublicRoute: FC<IProps> = ({ Component, ...rest }) => {
  const profile = useSelector((state) => state.profile);
  const translationsReady = useSelector((state) => state.translations.ready);

  const autoLoginEnabled = !!(
    rest.path && !rest.path.includes(PublicRoutes.Email.Verify)
  );

  return (
    <Route
      {...rest}
      render={() =>
        !translationsReady ? (
          <GlobalLoader />
        ) : autoLoginEnabled && (profile.data || authAPI.hasTokens()) ? (
          <LoginRedirect profile={profile} />
        ) : (
          <PublicTemplate>
            <Component />
          </PublicTemplate>
        )
      }
    />
  );
};

export { PublicRoute };
