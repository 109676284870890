import { BsFillCaretUpFill } from "react-icons/bs";
import { Column } from "react-table";
import { ClientCompany } from "../../../../services/api/projects";
import { formatDateTime } from "../../../../utils/date-format";
import { adminClientCompaniesAPI } from "../../../../services/api/admin/client-companies";
import store from "../../../../store";
import { showError } from "../../../alert/store/slices";
import { Link } from "react-router-dom";
import { ActionCell } from "./cells/ActionCell";

const downloadDocument = async (
  clientCompanyId: number,
  documentId: number,
  fileName: string | null
) => {
  try {
    const file = await adminClientCompaniesAPI.downloadDocument(
      clientCompanyId,
      documentId
    );

    if (file) {
      const link = window.document.createElement("a");

      link.href = window.URL.createObjectURL(file as any);
      link.download = fileName || "file";

      link.dispatchEvent(new MouseEvent("click"));
    }
  } catch (error) {
    store.dispatch(showError("File download failed."));
  }
};

const companiesListColumn: Column<ClientCompany>[] = [
  {
    Header: "AX code",
    accessor: "ax_code",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Code",
    accessor: "code",
  },
  {
    Header: "VAT code",
    accessor: "vat_code",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Postal code",
    accessor: "post_code",
  },
  {
    Header: "City",
    accessor: "city",
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    id: "Created at",
    Header: () => (
      <span>
        <BsFillCaretUpFill /> Created at
      </span>
    ),
    Cell: ({ cell }: any) => {
      const { created_at } = cell.row.original as ClientCompany;

      return <span>{formatDateTime(created_at)}</span>;
    },
  },
  {
    id: "Directors",
    Header: "Directors",
    Cell: ({ cell }: any) => {
      const { directors } = cell.row.original as ClientCompany;

      return directors && directors.length > 0 ? (
        <span>
          {directors
            .map((director) => `${director.first_name} ${director.last_name}`)
            .join(",")}
        </span>
      ) : (
        <span>-</span>
      );
    },
  },
  {
    Header: "Stamp",
    Cell: ({ cell }: any) => {
      const { id, documents } = (cell.row.original as unknown) as ClientCompany;

      if (!documents) {
        return <span>-</span>;
      }

      const stamp = documents[0];

      if (!stamp) return <span>-</span>;

      return (
        <Link
          to="#"
          onClick={() => downloadDocument(id, stamp.id, stamp.fileName)}
        >
          {stamp.fileName}
        </Link>
      );
    },
  },
  {
    Header: "Actions",
    Cell: (cell: any) => <ActionCell cell={cell} />,
    disableSortBy: true,
  },
];

export { companiesListColumn };
