import { call, put, select, takeLatest } from "typed-redux-saga";
import { adminGeneralPolicies } from "./slices";
import { loader } from "../../../common/loaders/store/slices";
import { adminGeneralPoliciesAPI } from "../../../../services/api/admin/general-policies";
import { showError, showSuccess } from "../../../alert/store/slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../../store";
import { GeneralPolicyCode } from "../../../../services/api/general-policies";

function* adminGeneralPolicySaga() {
  yield* takeLatest(adminGeneralPolicies.actions.fetchPolicies, fetchPolicies);
  yield* takeLatest(adminGeneralPolicies.actions.fetchPolicy, fetchPolicy);
  yield* takeLatest(adminGeneralPolicies.actions.storePolicy, storePolicy);
  yield* takeLatest(adminGeneralPolicies.actions.updatePolicy, updatePolicy);
  yield* takeLatest(adminGeneralPolicies.actions.deletePolicy, deletePolicy);
  yield* takeLatest(
    adminGeneralPolicies.actions.activatePolicy,
    activatePolicy
  );
}

function* fetchPolicies() {
  yield put(loader.actions.startLoader());

  const result = yield* call(adminGeneralPoliciesAPI.fetchPolicies);

  if (result && result.data) {
    yield put(adminGeneralPolicies.actions.setPolicies(result.data));
  } else {
    yield put(showError("Something went wrong while getting policy data."));
  }

  yield put(loader.actions.stopLoader());
}

function* fetchPolicy({ payload: id }: PayloadAction<string>) {
  yield put(loader.actions.startLoader());

  const result = yield* call(adminGeneralPoliciesAPI.fetchPolicy, id);

  if (result) {
    yield put(adminGeneralPolicies.actions.setPolicy(result));
  } else {
    yield put(showError("Something went wrong while getting policy data."));
  }

  yield put(loader.actions.stopLoader());
}

function* storePolicy({
  payload: { policyCode },
}: PayloadAction<{ policyCode: GeneralPolicyCode }>) {
  yield put(loader.actions.startLoader());

  const data = yield* select(
    (state: AppState) => state.adminGeneralPolicies.newPolicyData
  );

  const result = yield* call(
    adminGeneralPoliciesAPI.storePolicy,
    policyCode,
    data
  );

  if (result) {
    yield put(adminGeneralPolicies.actions.setNewPolicyPublished(true));
    yield put(showSuccess("New policy published. Activate it."));
  } else {
    yield put(showError("Something went wrong while creating policy."));
  }

  yield put(loader.actions.stopLoader());
}

function* updatePolicy({
  payload: { id, policyCode },
}: PayloadAction<{ id: number; policyCode: GeneralPolicyCode }>) {
  yield put(loader.actions.startLoader());

  const data = yield* select(
    (state: AppState) => state.adminGeneralPolicies.updatedPolicyData
  );

  const result = yield* call(
    adminGeneralPoliciesAPI.updatePolicy,
    id,
    policyCode,
    data
  );

  if (result && result.data) {
    yield put(adminGeneralPolicies.actions.setUpdatedPolicyPublished(true));
    yield put(showSuccess("Policy updated."));
  } else {
    yield put(showError("Something went wrong while updating policy."));
  }

  yield put(loader.actions.stopLoader());
}

function* deletePolicy({ payload: id }: PayloadAction<number>) {
  yield put(loader.actions.startLoader());

  const result = yield* call(adminGeneralPoliciesAPI.deletePolicy, id);

  if (result && result.success) {
    yield put(showSuccess("Policy deleted."));
  } else {
    yield put(showError("Something went wrong while deleting policy."));
  }

  yield put(adminGeneralPolicies.actions.fetchPolicies());

  yield put(loader.actions.stopLoader());
}

function* activatePolicy({ payload: id }: PayloadAction<number>) {
  yield put(loader.actions.startLoader());

  const result = yield* call(adminGeneralPoliciesAPI.activatePolicy, id);

  if (result) {
    yield put(showSuccess("Policy activated."));
  } else {
    yield put(showError("Something went wrong while activating policy."));
  }

  yield put(adminGeneralPolicies.actions.fetchPolicies());

  yield put(loader.actions.stopLoader());
}

export { adminGeneralPolicySaga };
