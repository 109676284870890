import { FC, useMemo, useState } from "react";
import screenshotURL from "../screenshots/applications.png";
import { useTranslation } from "react-i18next";
import { IHotspotProps } from "../../../common/hotspot/Hotspot";
import { Screenshot } from "../screenshots/Screenshot";

const ApplicationPage: FC = () => {
  const { t } = useTranslation("applicationPageWizard");

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const firstScreenshotHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 83, top: 212 },
        guideLines: [t("onApplicationsPageYouWillSee")],
      },
    ],
    [t]
  );

  const secondScreenshotHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 290, top: 146 },
        guideLines: [t("createMoreThanOneNewCompany")],
      },
      {
        order: 2,
        style: { left: 290, top: 220 },
        guideLines: [t("createMoreThanOneCompany")],
      },
      {
        order: 3,
        style: { right: 181, top: 348 },
        guideLines: [t("editOrDeleteCompany")],
      },
    ],
    [t]
  );

  return (
    <div className="module-page" style={{ opacity: isLoading ? 0 : 1 }}>
      <Screenshot
        url={screenshotURL}
        label={t("whatAbleToSee") as string}
        hotspots={firstScreenshotHotspots}
        imageLoaded={() => setIsLoading(false)}
      />

      <Screenshot
        url={screenshotURL}
        label={t("whatActionsCanTake") as string}
        hotspots={secondScreenshotHotspots}
        imageLoaded={() => setIsLoading(false)}
      />
    </div>
  );
};

export { ApplicationPage };
