import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "../../../services/api";
import { DocumentStatus } from "../../../services/api/documents";

interface IDocumentState {
  data: any[];
  perPage: number;
  activePage: number;
  meta: IPagination | null;
  search: string;
  showOnlyMy: boolean;
  showDeleted: boolean;
  status: DocumentStatus | null;
  typeId: number | null;
  isLoading: boolean;
}

const initialState: IDocumentState = {
  data: [],
  perPage: 20,
  activePage: 1,
  meta: null,
  search: "",
  showOnlyMy: false,
  showDeleted: false,
  status: null,
  typeId: null,
  isLoading: false,
};

const documents = createSlice({
  name: "documents",
  initialState,
  reducers: {
    fetchDocuments: (state, action) => {
      //
    },
    deleteDocument: (state, action) => {
      //
    },
    setData: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
    concatData: (state, action) => {
      state.data = state.data.concat(action.payload.data);
      state.meta = action.payload.meta;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.activePage = 1;
      state.data = [];
      state.isLoading = true;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setShowOnlyMy: (state, action) => {
      state.showOnlyMy = action.payload;
      state.activePage = 1;
      state.data = [];
      state.isLoading = true;
    },
    setShowDeleted: (state, action) => {
      state.showDeleted = action.payload;
      state.activePage = 1;
      state.data = [];
      state.isLoading = true;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
      state.activePage = 1;
      state.data = [];
      state.isLoading = true;
    },
    setTypeId: (state, action) => {
      state.typeId = action.payload;
      state.activePage = 1;
      state.data = [];
      state.isLoading = true;
    },
    resetData: (state) => {
      state.data = [];
    },
    resetPagination: (state) => {
      state.activePage = 1;
      state.meta = null;
    },
    reset: () => initialState,
  },
});

export { documents };
