import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./updateBar.module.scss";

const UpdateBar = () => {
  const { t } = useTranslation("updateBar");
  const isUpdateBarVisible = useSelector((state) => state.updateBar.isVisible);

  if (!isUpdateBarVisible) {
    return null;
  }

  const handleClick = () => {
    window.location.reload();
  };

  return (
    <div className={`bg-success ${styles.div}`}>
      <span>{t("appVersionUpdated")}</span>
      <Button onClick={handleClick} className={styles.button}>
        {t("reload")}
      </Button>
    </div>
  );
};

export default UpdateBar;
