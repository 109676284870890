import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal } from "react-bootstrap";
import { adminClientCompanies } from "./store/slices";
import { ClientCompany } from "../../../services/api/projects";

const StampModal = () => {
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState<File>();

  const company = useSelector(
    (state) => state.adminClientCompanies.companyForStampUpdate
  );
  const isLoading = useSelector(
    (state) => state.adminClientCompanies.stampUpdating
  );

  const setCompanyForStampUpdate = useCallback(
    (company: ClientCompany | null) =>
      dispatch(adminClientCompanies.actions.setCompanyForStampUpdate(company)),
    [dispatch]
  );

  const upload = useCallback(
    (company: ClientCompany, file: File) =>
      dispatch(adminClientCompanies.actions.uploadStamp({ company, file })),
    [dispatch]
  );

  if (!company) return null;

  return (
    <Modal
      show={!!company}
      onHide={() => setCompanyForStampUpdate(null)}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          style={{
            display: "flex",
            flexDirection: "column",
            overflowWrap: "anywhere",
          }}
        >
          <span>Upload stamp</span>
          <small className="text-secondary" style={{ fontSize: "14px" }}>
            {company.name}
          </small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.File.Input
          accept="image/jpeg, image/png"
          name="stamp"
          onChange={(e: any) => setSelectedFile(e.target.files[0])}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setCompanyForStampUpdate(null)}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (selectedFile) {
              upload(company, selectedFile);
            }
          }}
          disabled={isLoading || !selectedFile}
        >
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StampModal;
