import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "../../../../services/api";
import { ClientCompany } from "../../../../services/api/projects";

interface IClientCompaniesState {
  companies: any[];
  perPage: number;
  activePage: number;
  meta: IPagination | null;
  search: string;

  // Stamp
  companyForStampUpdate: ClientCompany | null;
  stampUpdating: boolean;

  // Director
  companyForDirectorUpdate: ClientCompany | null;
  directorUpdating: boolean;
}

const initialState: IClientCompaniesState = {
  companies: [],
  perPage: 20,
  activePage: 1,
  meta: null,
  search: "",

  // Stamp
  companyForStampUpdate: null,
  stampUpdating: false,

  // Director
  companyForDirectorUpdate: null,
  directorUpdating: false,
};

const adminClientCompanies = createSlice({
  name: "adminClientCompanies",
  initialState,
  reducers: {
    fetchCompanies: (state) => {
      //
    },
    setCompanies: (state, action) => {
      state.companies = action.payload.data;
      state.meta = action.payload.meta;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.activePage = 1;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    resetCompanies: (state) => {
      state.companies = [];
      state.perPage = 10;
      state.activePage = 1;
      state.meta = null;
      state.search = "";
    },

    // Stamp
    setCompanyForStampUpdate: (state, action) => {
      state.companyForStampUpdate = action.payload;
    },
    uploadStamp: (state, action) => {
      //
    },
    setStampUpdating: (state, action) => {
      state.stampUpdating = action.payload;
    },

    // Director
    setCompanyForDirectorUpdate: (state, action) => {
      state.companyForDirectorUpdate = action.payload;
    },
    updateDirector: (state, action) => {
      //
    },
    setDirectorUpdating: (state, action) => {
      state.directorUpdating = action.payload;
    },
    reset: () => initialState,
  },
});

export { adminClientCompanies };
