import { FC, useCallback } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { DocumentType } from "../../../../../services/api/admin/edi";
import { adminFiles } from "../../store/slices";

const DocumentTypeFilter: FC = () => {
  const dispatch = useDispatch();

  const setFilter = useCallback(
    (filter: string) => dispatch(adminFiles.actions.setDocumentType(filter)),
    [dispatch]
  );

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setFilter(e.target.value);
    } else {
      setFilter("");
    }
  };

  return (
    <div className="action-filter">
      <Form.Control
        onChange={handleSelect}
        as="select"
        size="sm"
        className="action-filter"
      >
        <option value="">All types</option>
        {Object.values(DocumentType).map((value, index) => (
          <option key={index}>{value}</option>
        ))}
      </Form.Control>
    </div>
  );
};

export { DocumentTypeFilter };
