import { take, call, put, fork, takeLatest, select } from "typed-redux-saga";
import { loader } from "../../../common/loaders/store/slices";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../alert/store/slices";
import { applications } from "../slices";
import {
  applicationAPI,
  IApplication,
} from "../../../../services/api/application";
import { translated } from "../../../../i18n";
import { AppState } from "../../../../store";
import { PayloadAction } from "@reduxjs/toolkit";
import { settings as settingsSlice } from "../../../settings/store/slices";

function* existingPartnerApplicationSaga() {
  yield* fork(showListener);
  yield* fork(storeListener);
  yield* takeLatest(
    applications.actions.storeExistingPartnerDraft,
    storeDraftListener
  );
  yield* takeLatest(
    applications.actions.updateExistingPartnerDraft,
    updateDraftListener
  );
}

function* storeDraftListener() {
  const settings = yield* select((state: AppState) => state.settings.carrier);

  if (!settings) {
    yield put(settingsSlice.actions.fetchCarrierSettings());
  }

  yield* call(storeDraft);
}

function* updateDraftListener({
  payload: { id, data, silent },
}: PayloadAction<{ id: string; data: IApplication; silent: boolean }>) {
  if (silent) {
    yield* call(updateDraftSilent, id, data);
  } else {
    yield* call(updateDraft, id, data);
  }
}

function* storeDraft() {
  const result = yield* call(applicationAPI.storeExistingPartnerDraft);

  if (result && result.data) {
    yield put(applications.actions.setData(result.data));

    yield put(applications.actions.setExistingPartnerDraftCreated());
  } else {
    yield put(showError(translated.errorWhileStoringApplication));
  }
}

function* updateDraft(id: string, data: IApplication) {
  yield put(loader.actions.startLoader());

  yield put(applications.actions.setData(data));

  const result = yield* call(
    applicationAPI.updateExistingPartnerDraft,
    id,
    data
  );

  if (result && result.data) {
    yield put(applications.actions.setDataSaved());

    yield put(showSuccess(translated.applicationUpdated));
  } else if (
    result &&
    result.errors &&
    result.errors.status &&
    result.errors.status.length > 0
  ) {
    yield put(showWarning(result.errors.status[0]));
  } else {
    yield put(showError(translated.errorWhileUpdatingApplication));
  }

  yield put(loader.actions.stopLoader());
}

function* updateDraftSilent(id: string, data: IApplication) {
  yield* call(applicationAPI.updateExistingPartnerDraft, id, data);

  yield put(applications.actions.resetData());
}

function* showListener() {
  while (true) {
    const { payload } = yield take(applications.actions.fetchApplication);

    yield* call(show, payload);
  }
}

function* storeListener() {
  while (true) {
    const { payload } = yield take(applications.actions.storeExistingPartner);

    if (payload.id && payload.data) {
      yield* call(store, payload.id, payload.data);
    }
  }
}

function* show(id: string) {
  yield put(loader.actions.startLoader());

  const result = yield* call(applicationAPI.fetchApplication, id);

  if (result && result.data) {
    yield put(applications.actions.setData(result.data));
  } else {
    yield put(showError(translated.errorWhileGettingApplication));
  }

  yield put(loader.actions.stopLoader());
}

function* store(id: string, data: IApplication) {
  yield put(loader.actions.startLoader());

  yield put(applications.actions.setData(data));

  const result = yield* call(applicationAPI.storeExistingPartner, id, data);

  if (result && result.data) {
    yield put(applications.actions.setDataSaved());

    yield put(showSuccess(translated.applicationSent));
  } else {
    yield put(showError(translated.errorWhileStoringApplication));
  }

  yield put(loader.actions.stopLoader());
}

export default existingPartnerApplicationSaga;
