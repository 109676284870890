import { startCase } from "lodash";
import { call, fork, put, select, take } from "typed-redux-saga";
import { translated } from "../../../../i18n";
import { IInviteUserRequest, usersAPI } from "../../../../services/api/users";
import { AppState } from "../../../../store";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { profile } from "../../../profile/store/slices";
import { settings as settingsSlice } from "../../../settings/store/slices";
import { IUserInvitationData } from "../Invitation";
import { IUserInviteData } from "../UserInvite";
import { userInvite } from "./slices";

function* userInviteSaga() {
  yield* fork(inviteListener);
  yield* fork(invitationListener);
}

function* inviteListener() {
  while (true) {
    const { payload } = yield take(userInvite.actions.inviteUser);

    const { profileData } = yield* select((state: AppState) => ({
      profileData: state.profile.data,
    }));

    if (profileData && payload) {
      yield* call(inviteUser, payload);
    } else {
      yield take(profile.actions.setProfileData);

      yield* call(inviteUser, payload);
    }
  }
}

function* invitationListener() {
  while (true) {
    const { payload } = yield take(userInvite.actions.acceptInvitation);

    if (payload) {
      yield* call(acceptInvitation, payload);
    }
  }
}

function* inviteUser(data: IUserInviteData) {
  yield put(loader.actions.startLoader());

  const { selectedCompany, settings } = yield* select((state: AppState) => ({
    selectedCompany: state.profile.data?.selectedCompany,
    settings: state.settings.carrier,
  }));

  if (!settings) {
    yield take(settingsSlice.actions.setCarrierSettings);
  }

  const requestData: IInviteUserRequest = {
    email: data.email,
    roleId: data.role,
  };

  if (selectedCompany) {
    const result = yield* call(
      usersAPI.inviteUser,
      selectedCompany.id,
      requestData
    );

    if (result && result.data) {
      yield put(userInvite.actions.setInvited());

      yield put(showSuccess(translated.userInvited));
    } else if (result && result.errors) {
      const firstErrorKey = Object.keys(result.errors)[0];

      yield put(
        showWarning(
          `${startCase(firstErrorKey)}: ${result.errors[firstErrorKey]}`
        )
      );
    } else {
      yield put(showError(translated.errorWhileInvitingUser));
    }
  }

  yield put(loader.actions.stopLoader());
}

function* acceptInvitation(data: IUserInvitationData) {
  yield put(loader.actions.startLoader());

  if (!data.token) {
    yield put(showError(translated.tokenMissing));

    yield put(loader.actions.stopLoader());

    return;
  }

  const result = yield* call(usersAPI.acceptInvitation, data);

  if (result && result.data) {
    yield put(userInvite.actions.setInvited());

    yield put(showSuccess(translated.accountCreatedPleaseLogin));
  } else if (result && result.errors) {
    const firstErrorKey = Object.keys(result.errors)[0];

    yield put(
      showWarning(
        `${startCase(firstErrorKey)}: ${result.errors[firstErrorKey]}`
      )
    );
  } else if (result && result.message && result.message.includes("expired")) {
    yield put(showWarning(translated.invitationExpired));
  } else {
    yield put(showError(translated.errorWhileCreatingAccount));
  }

  yield put(loader.actions.stopLoader());
}

export { userInviteSaga };
