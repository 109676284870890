import { startCase } from "lodash";
import { call, fork, put, select, take } from "typed-redux-saga";
import { CarrierRoute } from "../../../../constants/routes";
import { translated } from "../../../../i18n";
import { authAPI, IVerifyParameters } from "../../../../services/api/auth";
import store, { AppState } from "../../../../store";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { profile as profileSlice } from "../../../profile/store/slices";
import { IRegisterData } from "../Register";
import { registration } from "./slices";

function* registrationSaga() {
  yield* fork(registerWatcher);
  yield* fork(sendVerificationWatcher);
  yield* fork(verifyWatcher);
}

function* registerWatcher() {
  while (true) {
    const { payload } = yield take(registration.actions.register);

    if (payload) {
      yield* call(register, payload);
    }
  }
}

function* sendVerificationWatcher() {
  while (true) {
    yield take(registration.actions.sendVerification);

    yield* call(sendVerification);
  }
}

function* verifyWatcher() {
  while (true) {
    const { payload } = yield take(registration.actions.verify);

    if (payload && payload.userId && payload.parameters && payload.history) {
      yield* call(verify, payload.userId, payload.parameters, payload.history);
    }
  }
}

function* register(data: IRegisterData) {
  yield put(loader.actions.startLoader());

  const response = yield* call(authAPI.register, data);

  if (response && response.data) {
    yield put(registration.actions.setRegistered());

    yield put(showSuccess(translated.registrationSuccessful));
  } else if (response && response.errors) {
    const firstErrorKey = Object.keys(response.errors)[0];

    yield put(
      showWarning(
        `${startCase(firstErrorKey)}: ${response.errors[firstErrorKey]}`
      )
    );
  } else {
    yield put(showError(translated.errorWhileRegistering));
  }

  yield put(loader.actions.stopLoader());
}

function* sendVerification() {
  yield put(loader.actions.startLoader());

  const response = yield* call(authAPI.sendVerification);

  if (response && response.success) {
    yield put(showSuccess(translated.verificationSent));
  } else {
    yield put(showError(translated.errorWhileResendingVerification));
  }

  yield put(loader.actions.stopLoader());
}

function* verify(userId: string, data: IVerifyParameters, history: any) {
  yield put(loader.actions.startLoader());

  const response = yield* call(authAPI.verify, userId, data);

  if (response && response.success) {
    store.dispatch(showSuccess(translated.emailVerified));

    const profile = yield* select((state: AppState) => state.profile.data);

    if (profile) {
      yield put(profileSlice.actions.fetchProfile());

      yield take(profileSlice.actions.setProfileData);

      yield call(history.push, CarrierRoute.Applications.List);
    } else {
      yield call(history.push, "/");
    }
  } else if (response && response.message) {
    yield put(showWarning(response.message));
  } else {
    yield put(showError(translated.errorWhileVerifyingEmail));
  }

  yield put(loader.actions.stopLoader());
}

export { registrationSaga };
