const replaceNullsToStrings = (data: any): any => {
  if (data) {
    const newObject: any = {};

    Object.keys(data).forEach((key) => {
      if (typeof data[key] === "object") {
        newObject[key] = replaceNullsToStrings(data[key]);
      } else {
        newObject[key] = data[key] === null ? "" : data[key];
      }
    });

    return newObject;
  }
};

export { replaceNullsToStrings };
