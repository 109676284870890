import { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { authAPI } from "../../services/api/auth";
import { GlobalLoader } from "../common/loaders/GlobalLoader";
import { PrivateTemplate } from "../layout/PrivateTemplate";
import workspaceSwitcherSlice, {
  Workspace,
} from "../workspace-switcher/store/slice";
import useWorkspaceStatus from "../../hooks/workspace-status";

const workspace = Workspace.ProfileSpace;

interface IProps extends RouteProps {
  Component: React.FC<{}>;
}

const GeneralRoute: FC<IProps> = ({ Component, ...rest }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.data);
  const translationsReady = useSelector((state) => state.translations.ready);
  const profileSynced = useSelector(
    (state) => state.translations.profileSynced
  );
  const workspaceChanged = useWorkspaceStatus(workspace);

  const changeWorkspace = useCallback(
    (workspace: Workspace) =>
      dispatch(workspaceSwitcherSlice.actions.change(workspace)),
    [dispatch]
  );

  const showGlobalLoader = useMemo(
    () =>
      authAPI.hasTokens() && (!profile || !translationsReady || !profileSynced),
    [profile, translationsReady, profileSynced]
  );

  useEffect(() => {
    if (workspaceChanged) {
      changeWorkspace(workspace);
    }
  }, [workspaceChanged, changeWorkspace]);

  return (
    <Route
      {...rest}
      render={() =>
        showGlobalLoader ? (
          <GlobalLoader />
        ) : !profile ? (
          <Redirect to="/" />
        ) : (
          <PrivateTemplate>
            <Component />
          </PrivateTemplate>
        )
      }
    />
  );
};

export { GeneralRoute };
