import { useCallback, useEffect } from "react";
import { Row, Container, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NotificationItem } from "./NotificationItem";
import { NotificationTypeTabs } from "./NotificationTypeTabs";
import { Header } from "../../layout/Header";
import { notifications } from "../store/slices";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { NoData } from "../../common/no-data/NoData";
import { Loader } from "../../common/loaders/Loader";
import styles from "../Notification.module.scss";
import { NotificationType } from "../types";

export const Notifications = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const { type } = queryString.parse(search);

  const data = useSelector((state) => state.notifications.data);
  const meta = useSelector((state) => state.notifications.meta);
  const isLoading = useSelector((state) => state.notifications.isLoading);

  const fetchNotifications = useCallback(
    (type: NotificationType, page: number) => {
      dispatch(notifications.actions.fetchNotifications({ type, page }));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchNotifications(type as NotificationType, 1);
  }, [fetchNotifications, type]);

  const { t } = useTranslation("notifications");

  return (
    <Container fluid>
      <Row>
        <Header title={t("notifications")} />
      </Row>
      <Row className={styles.notificationContent}>
        <Col xl={6} style={{ paddingLeft: "0px" }}>
          <Row className={styles.notificationsSubtitle}>
            <Col>
              {t("showing")} {meta.to || 0}/{meta.total || 0}
            </Col>
          </Row>

          <NotificationTypeTabs type={type as NotificationType} />

          <hr />

          {isLoading && (
            <div className={styles.loader}>
              <Loader isLoading />
            </div>
          )}

          {!isLoading && !data.length && (
            <NoData style={{ marginTop: "1rem" }} />
          )}

          {!isLoading &&
            data &&
            data.map((notification, index) => (
              <NotificationItem key={index} notification={notification} />
            ))}

          {!isLoading && !!(meta.current_page < meta.last_page) && (
            <div className={styles.notificationsMore}>
              <Button
                onClick={() =>
                  fetchNotifications(
                    type as NotificationType,
                    meta.current_page + 1
                  )
                }
                variant="outline-secondary"
              >
                {t("loadMore")}
              </Button>
            </div>
          )}
        </Col>
        <Col />
      </Row>
    </Container>
  );
};
