import { Link } from "react-router-dom";
import { Column, TableOptions } from "react-table";
import { AdminRoute } from "../../../../constants/routes";
import { adminDocumentsAPI } from "../../../../services/api/admin/documents";
import { IDocument } from "../../../../services/api/documents";
import store from "../../../../store";
import { formatUnixDate, formatUnixTime } from "../../../../utils/date-format";
import { showError } from "../../../alert/store/slices";
import { BsFillCaretUpFill } from "react-icons/bs";
import { DocumentStatusTooltip } from "../DocumentStatusTooltip";

const downloadDocument = async (id: number, fileName: string) => {
  try {
    const file = await adminDocumentsAPI.downloadDocument(id);

    if (file) {
      const link = window.document.createElement("a");

      link.href = window.URL.createObjectURL(file as any);
      link.download = fileName;

      link.dispatchEvent(new MouseEvent("click"));
    }
  } catch (error) {
    store.dispatch(showError("File download failed."));
  }
};

const documentListColumn: Column<TableOptions<IDocument>>[] = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "File name",
    accessor: "fileName",
    Cell: ({ cell }) => {
      const document = (cell.row.original as unknown) as IDocument;

      return (
        <Link
          to="#"
          onClick={() => downloadDocument(document.id, document.fileName)}
        >
          {cell.value}
        </Link>
      );
    },
  },
  {
    Header: "Model",
    Cell: ({ cell }) => {
      const document = (cell.row.original as unknown) as IDocument;
      const modelType = document.modelType.replace("App\\", "");
      let route = "#";

      if (document.model) {
        if (modelType === "Project") {
          route = `${AdminRoute.Orders.List}?search=${document.model.ax_code}`;
        }

        if (modelType === "Application") {
          route = `${AdminRoute.Applications.List}?search=${document.model.id}`;
        }
      }

      return (
        <>
          <span>
            <Link to={route}>{modelType}</Link>
          </span>
          <span className="list-time">ID: {document.modelId}</span>
        </>
      );
    },
  },
  {
    Header: "Project AX code",
    Cell: ({ cell }) => {
      const document = (cell.row.original as unknown) as IDocument;

      if (document.model && document.model.ax_code) {
        return document.model.ax_code;
      }

      return <span>-</span>;
    },
  },
  {
    Header: "AX message",
    Cell: ({ cell }) => {
      const { axMessage } = (cell.row.original as unknown) as IDocument;

      return axMessage ? axMessage : <span>-</span>;
    },
  },
  {
    Header: "Type",
    Cell: ({ cell }) => {
      const { type } = (cell.row.original as unknown) as IDocument;

      if (!type) {
        return <span>-</span>;
      }

      return type.name;
    },
  },
  {
    Header: "Status",
    Cell: ({ cell }) => {
      const { status } = (cell.row.original as unknown) as IDocument;

      if (!status) {
        return <span>-</span>;
      }

      return <DocumentStatusTooltip name={status.name} code={status.code} />;
    },
  },
  {
    Header: "Comment",
    Cell: ({ cell }) => {
      const { comment } = (cell.row.original as unknown) as IDocument;

      return comment ? comment : <span>-</span>;
    },
  },
  {
    Header: "Data",
    Cell: ({ cell }) => {
      const { data } = (cell.row.original as unknown) as IDocument;

      if (!data) return <span>-</span>;

      return (
        <div>
          {data.map(({ value, type }) => {
            const dataName = type.name;
            const dataCode = type.code;
            let dataValue = value;

            if (dataCode === "DATA_DOC_DATE" && typeof value === "number") {
              dataValue = `${formatUnixDate(value)} ${formatUnixTime(value)}`;
            }

            if (
              (dataCode === "DATA_DOC_AMOUNT" ||
                dataCode === "DATA_DOC_AMNOUNT") &&
              typeof value === "object"
            ) {
              dataValue = `${value.amount} ${value.currency}`;
            }

            return (
              <>
                <span>{dataName}</span>
                <span className="list-time">{dataValue as any}</span>
              </>
            );
          })}
        </div>
      );
    },
  },
  {
    Header: "Created by",
    Cell: ({ cell }) => {
      const { createdBy: user } = (cell.row.original as unknown) as IDocument;

      if (!user) {
        return <span>-</span>;
      }

      return (
        <>
          <span>
            <Link to={`${AdminRoute.Users.List}?search=${user.email}`}>
              {user.email}
            </Link>
          </span>
          <span className="list-time">ID: {user.id}</span>
        </>
      );
    },
  },
  {
    Header: "Created at",
    Cell: ({ cell }) => {
      const { createdAt } = (cell.row.original as unknown) as IDocument;

      return (
        <span>{`${formatUnixDate(createdAt)} ${formatUnixTime(
          createdAt
        )}`}</span>
      );
    },
  },
  {
    id: "Updated at",
    Header: () => (
      <span>
        <BsFillCaretUpFill /> Updated at
      </span>
    ),
    Cell: ({ cell }) => {
      const { createdAt } = (cell.row.original as unknown) as IDocument;

      return (
        <span>{`${formatUnixDate(createdAt)} ${formatUnixTime(
          createdAt
        )}`}</span>
      );
    },
  },
];

export { documentListColumn };
