import { call, fork, put, select, take } from "typed-redux-saga";
import { translated } from "../../../../i18n";
import { usersAPI } from "../../../../services/api/users";
import { AppState } from "../../../../store";
import { showError, showSuccess } from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { profile } from "../../../profile/store/slices";
import { userShow } from "./slices";

function* userShowSaga() {
  yield* fork(fetchListener);
  yield* fork(removeListener);
}

function* fetchListener() {
  while (true) {
    const { payload } = yield take(userShow.actions.fetchUser);

    const { profileData } = yield* select((state: AppState) => ({
      profileData: state.profile.data,
    }));

    if (profileData && payload) {
      yield* call(fetchUser, payload);
    } else {
      yield take(profile.actions.setProfileData);

      yield* call(fetchUser, payload);
    }
  }
}

function* removeListener() {
  while (true) {
    const { payload } = yield take(userShow.actions.removeUser);

    if (payload) {
      yield* call(deleteUser, payload);
    }
  }
}

function* fetchUser(userId: string) {
  yield put(loader.actions.startLoader());

  const { selectedCompany } = yield* select((state: AppState) => ({
    selectedCompany: state.profile.data?.selectedCompany,
  }));

  if (selectedCompany) {
    const result = yield* call(usersAPI.fetchUser, selectedCompany.id, userId);

    if (result && result.data) {
      yield put(userShow.actions.setData(result));
    } else {
      yield put(showError(translated.errorWhileGettingUsers));
    }
  }

  yield put(loader.actions.stopLoader());
}

function* deleteUser(userId: string) {
  yield put(loader.actions.startLoader());

  const { selectedCompany } = yield* select((state: AppState) => ({
    selectedCompany: state.profile.data?.selectedCompany,
  }));

  if (selectedCompany) {
    const result = yield* call(usersAPI.deleteUser, selectedCompany.id, userId);

    if (result && result.success) {
      yield put(userShow.actions.setRemoved());

      yield put(showSuccess(translated.userRemoved));
    } else {
      yield put(showError(translated.errorWhileDeletingUsers));
    }
  }

  yield put(loader.actions.stopLoader());
}

export { userShowSaga };
