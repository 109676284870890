import classNames from "classnames";
import { FC, CSSProperties } from "react";
import { FaExclamationCircle } from "react-icons/fa";

interface IProps {
  defaultClassName?: string;
  className?: string;
  text: string;
  style: CSSProperties;
  onClick: () => void;
}

const ProjectAlert: FC<IProps> = ({
  defaultClassName,
  className,
  text,
  style,
  onClick,
}) => (
  <div
    className={classNames(
      defaultClassName || "alert-warning",
      className && className
    )}
    style={style}
    onClick={onClick}
  >
    <FaExclamationCircle /> {text}
  </div>
);

export { ProjectAlert };
