export const superAdminPrefix = "/super-admin";
export const adminPrefix = "/admin";
export const carrierPrefix = "/carrier";

export const PublicRoot = {
  Register: "/register",
  Email: "/email",
  Password: "/password",
  Invitation: "/invitation",
  NotFound: "/not-found",
  Maintenance: "/maintenance",
  Logout: "/logout",
};

export const SuperAdminRoot = {
  EDI: superAdminPrefix + "/edi",
  Mailing: superAdminPrefix + "/mailing",
  Translations: superAdminPrefix + "/translations",
  Settings: superAdminPrefix + "/settings",
  Maintenance: superAdminPrefix + "/maintenance",
};

export const AdminRoot = {
  Policies: adminPrefix + "/policies",
  GeneralPolicies: adminPrefix + "/general-policies",
  ProjectPolicies: adminPrefix + "/project-policies",
  StatisticPolicies: adminPrefix + "/statistic-policies",
  ActivityLog: adminPrefix + "/activity-log",
  Users: adminPrefix + "/users",
  Orders: adminPrefix + "/orders",
  Documents: adminPrefix + "/documents",
  Applications: adminPrefix + "/applications",
  Companies: adminPrefix + "/companies",
  ClientCompanies: adminPrefix + "/client-companies",
  Files: adminPrefix + "/files",
};

export const CarrierRoot = {
  Orders: carrierPrefix + "/orders",
  Applications: carrierPrefix + "/applications",
  Projects: carrierPrefix + "/projects",
  Users: carrierPrefix + "/users",
  Documents: carrierPrefix + "/documents",
  Settings: carrierPrefix + "/settings",
  MailSettings: carrierPrefix + "/mail-settings",
  Notifications: carrierPrefix + "/notifications",
};

export const GeneralRoot = {
  Profile: "/profile",
};

/**
 * Public APP routes
 */
export const PublicRoute = {
  Root: "/",
  Register: PublicRoot.Register,
  Email: {
    Verify: PublicRoot.Email + "/verify/:userId",
  },
  Password: {
    Forgot: PublicRoot.Password + "/forgot",
    Reset: PublicRoot.Password + "/reset/:token",
  },
  Invitation: PublicRoot.Invitation + "/:token",
  NotFound: PublicRoot.NotFound,
  Maintenance: PublicRoot.Maintenance,
  Logout: PublicRoot.Logout,
  PrivacyPolicy: "/privacy-policy",
};

export const CarrierRoute = {
  Orders: {
    List: CarrierRoot.Orders + "/:orderId?",
  },
  Applications: {
    List: CarrierRoot.Applications,
    ShowNewPartner: CarrierRoot.Applications + "/:applicationId}",
    ShowExistingPartner: CarrierRoot.Applications + "/:applicationId/existing",
    NewPartner: CarrierRoot.Applications + "/:applicationId/new-partner",
    ExistingPartner:
      CarrierRoot.Applications + "/:applicationId/existing-partner",
  },
  Users: {
    List: CarrierRoot.Users,
    Show: CarrierRoot.Users + "/:userId",
    Edit: CarrierRoot.Users + "/:userId/edit",
    Invite: CarrierRoot.Users + "/invite",
  },
  Documents: {
    List: CarrierRoot.Documents,
  },
};

export const GeneralRoute = {
  Profile: {
    Show: GeneralRoot.Profile,
    Edit: GeneralRoot.Profile + "/edit",
    ChangePassword: GeneralRoot.Profile + "/change-password",
  },
  MailSettings: CarrierRoot.MailSettings,
  Notifications: CarrierRoot.Notifications,
};

export const SuperAdminRoute = {
  EDI: {
    Upload: SuperAdminRoot.EDI + "/upload",
  },
  Mailing: {
    TestTemplates: SuperAdminRoot.Mailing + "/test-templates",
    SendIssueMail: SuperAdminRoot.Mailing + "/send-issue-mail",
  },
  Settings: {
    Cache: SuperAdminRoot.Settings + "/cache",
    Sessions: SuperAdminRoot.Settings + "/sessions",
    Queues: SuperAdminRoot.Settings + "/queues",
    Logs: SuperAdminRoot.Settings + "/logs",
  },
  Maintenance: {
    Show: SuperAdminRoot.Maintenance,
  },
};

export const AdminRoute = {
  GeneralPolicies: {
    List: AdminRoot.GeneralPolicies,
    Store: AdminRoot.GeneralPolicies + "/new",
    Update: AdminRoot.GeneralPolicies + "/:id",
  },
  ProjectPolicies: {
    List: AdminRoot.ProjectPolicies,
    Store: AdminRoot.ProjectPolicies + "/new",
    Update: AdminRoot.ProjectPolicies + "/:id",
  },
  StatisticPolicies: {
    List: AdminRoot.StatisticPolicies,
    Store: AdminRoot.StatisticPolicies + "/new",
    Update: AdminRoot.StatisticPolicies + "/:id",
  },
  ActivityLog: {
    List: AdminRoot.ActivityLog,
  },
  Users: {
    List: AdminRoot.Users,
    Invite: AdminRoot.Users + "/invite",
    Show: AdminRoot.Users + "/:userId",
    EditRoles: AdminRoot.Users + "/:userId/roles",
    EditDepartmentRoles: AdminRoot.Users + "/:userId/department-roles",
    Companies: {
      List: AdminRoot.Users + "/:userId/companies",
      EditRoles: AdminRoot.Users + "/:userId/companies/:companyId/roles",
      Assign: AdminRoot.Users + "/:userId/companies/assign",
    },
    ManageConditions: AdminRoot.Users + "/:userId/manage-conditions",
  },
  Orders: {
    List: AdminRoot.Orders,
  },
  Documents: {
    List: AdminRoot.Documents,
  },
  Applications: {
    List: AdminRoot.Applications,
  },
  Companies: {
    List: AdminRoot.Companies,
    Create: AdminRoot.Companies + "/create",
    Update: AdminRoot.Companies + "/:companyId",
  },
  ClientCompanies: {
    List: AdminRoot.ClientCompanies,
  },
  Files: {
    List: AdminRoot.Files,
  },
};
