import queryString from "query-string";
import { API, ApiResource, HTTPMethod } from "..";
import { ICompanyForm } from "../../../components/admin/companies/form/CompanyForm";
import { ICompany } from "../profile";

export interface ICompaniesRequest {
  onlyUserId?: string;
  withoutUserId?: string;
  search?: string;
}

const fetchCompanies = async (
  requestParameters: ICompaniesRequest
): Promise<ApiResource<ICompany[]>> => {
  const params = queryString.stringify(requestParameters as any);

  const response = await API.request<ApiResource<ICompany[]>>(
    `/admin/companies?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchCompaniesShort = async (
  requestParameters: ICompaniesRequest
): Promise<ApiResource<ICompany[]>> => {
  const params = queryString.stringify(requestParameters as any);

  const response = await API.request<ApiResource<ICompany[]>>(
    `/admin/companies/short?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchCompany = async (id: string): Promise<ApiResource<ICompany>> => {
  const response = await API.request<ApiResource<ICompany>>(
    `/admin/companies/${id}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const storeCompany = async (
  data: ICompanyForm
): Promise<ApiResource<ICompany>> => {
  const response = await API.request<ApiResource<ICompany>>(
    `/admin/companies`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const updateCompany = async (
  id: string,
  data: ICompanyForm
): Promise<ApiResource<ICompany>> => {
  const response = await API.request<ApiResource<ICompany>>(
    `/admin/companies/${id}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const adminCompaniesAPI = {
  fetchCompanies,
  fetchCompaniesShort,
  fetchCompany,
  storeCompany,
  updateCompany,
};

export { adminCompaniesAPI };
