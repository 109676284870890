import { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DocumentGroupCode,
  IDocument,
} from "../../../../../services/api/documents";
import { IProject } from "../../../../../services/api/projects";
import { projectShow } from "../../store/slices";
import { DocumentPreviewModal } from "../modals/DocumentPreviewModal";
import { DocumentItem } from "../DocumentItem";
import { UploadDocument } from "../UploadDocument";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { DocumentsLoader } from "../../DocumentsLoader";
import { NoData } from "../../../../common/no-data/NoData";
import useMobileScreen from "../../../../../hooks/mobile-screen";

interface IProps {
  project: IProject;
}

const DocumentTab: FC<IProps> = ({ project }) => {
  const isMobileScreen = useMobileScreen();

  const { t } = useTranslation("documentsTab");

  const [previewDocument, setPreviewDocument] = useState<IDocument>();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const isLoading = useSelector(
    (state) => state.projectShow.isDocumentsLoading
  );
  const documentCount = useSelector((state) => state.projectShow.documentCount);
  const documents = useSelector((state) => state.projectShow.documents);

  const profile = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const fetchDocuments = useCallback(
    () => dispatch(projectShow.actions.fetchDocuments()),
    [dispatch]
  );

  const resetDocuments = useCallback(
    () => dispatch(projectShow.actions.resetDocuments()),
    [dispatch]
  );

  useEffect(() => {
    fetchDocuments();

    return () => {
      resetDocuments();
    };
  }, [fetchDocuments, resetDocuments]);

  const isInactive = profile.isManager || profile.hasAccountantRole;

  return (
    <>
      <div className="documents-body">
        {!!(!documentCount && isInactive) && <NoData />}
        {!!documentCount && (
          <div id="documents-scroll-area">
            <div className="list-header">
              <span style={{ width: isMobileScreen ? "100%" : "55%" }}>
                {t("type")}
              </span>
              <span style={{ width: isMobileScreen ? "100%" : "25%" }}>
                {t("status")}
              </span>
              <span style={{ width: isMobileScreen ? "100%" : "20%" }}>
                {t("uploaded")}
              </span>
            </div>
            {isLoading && <DocumentsLoader />}
            {documents.map((document, index) => (
              <DocumentItem
                key={index}
                document={document}
                strikeThroughPaymentDay={
                  !!(
                    project.acceptedCreditInvoiceExists &&
                    document.type &&
                    document.type.group &&
                    document.type.group.code !== DocumentGroupCode.CreditOrder
                  )
                }
                onClick={() => {
                  setPreviewDocument(document);
                  setPreviewVisible(true);
                }}
              />
            ))}
          </div>
        )}

        {!isInactive && (
          <div
            className="upload-button-div"
            style={documents.length > 0 ? { width: "200px" } : {}}
          >
            <UploadDocument />
          </div>
        )}
      </div>
      {previewDocument && (
        <DocumentPreviewModal
          isVisible={previewVisible}
          isInactive={isInactive || !!project.archivedAt}
          document={previewDocument}
          strikeThroughPaymentDay={
            !!(
              project.acceptedCreditInvoiceExists &&
              previewDocument.type &&
              previewDocument.type.group &&
              previewDocument.type.group.code !== DocumentGroupCode.CreditOrder
            )
          }
          onClose={() => {
            setPreviewDocument(undefined);
            setPreviewVisible(false);
          }}
        />
      )}
    </>
  );
};

export { DocumentTab };
