import { createSlice } from "@reduxjs/toolkit";
import { LanguageCode } from "../../../../i18n";
import {
  StatisticPolicy,
  StatisticPolicyData,
} from "../../../../services/api/admin/statistic-policies";

type State = {
  policies: StatisticPolicy[];
  policy: StatisticPolicy | null;
  newPolicyData: StatisticPolicyData;
  newPolicyPublished: boolean;
  updatedPolicyData: StatisticPolicyData;
  updatedPolicyPublished: boolean;

  policiesLoading: boolean;
};

const initialState: State = {
  policies: [],
  policy: null,
  newPolicyData: {
    en: "",
    ru: "",
    pl: "",
    lt: "",
  },
  newPolicyPublished: false,
  updatedPolicyData: {
    en: "",
    ru: "",
    pl: "",
    lt: "",
  },
  updatedPolicyPublished: false,
  policiesLoading: false,
};

const adminStatisticPolicies = createSlice({
  name: "adminStatisticPolicies",
  initialState,
  reducers: {
    fetchPolicies: (state) => {
      //
    },
    setPolicies: (state, action) => {
      state.policies = action.payload;
    },
    fetchPolicy: (state, action) => {
      //
    },
    setPolicy: (state, action) => {
      state.policy = action.payload;
    },
    storePolicy: (state, action) => {
      //
    },
    setNewPolicyData: (state, action) => {
      const {
        languageCode,
        html,
      }: { languageCode: LanguageCode; html: string } = action.payload;

      state.newPolicyData[languageCode] = html;
    },
    setNewPolicyPublished: (state, action) => {
      state.newPolicyPublished = action.payload;
    },
    updatePolicy: (state, action) => {
      //
    },
    setUpdatedPolicyData: (state, action) => {
      const {
        languageCode,
        html,
      }: { languageCode: LanguageCode; html: string } = action.payload;

      state.updatedPolicyData[languageCode] = html;
    },
    setUpdatedPolicyPublished: (state, action) => {
      state.updatedPolicyPublished = action.payload;
    },
    deletePolicy: (state, action) => {
      //
    },
    activatePolicy: (state, action) => {
      //
    },
    setPoliciesLoading: (state, action) => {
      state.policiesLoading = action.payload;
    },
    resetState: () => initialState,
  },
});

export { adminStatisticPolicies };
