import { FC, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { adminSettingsAPI } from "../../../services/api/admin/settings";
import { showError, showSuccess } from "../../alert/store/slices";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { Header } from "../../layout/Header";

const Session: FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleSessionReset = async () => {
    setLoading(true);

    const response = await adminSettingsAPI.resetSessions();

    setLoading(false);

    if (response && response.success) {
      dispatch(showSuccess("Session cleared."));
    } else {
      dispatch(showError("Something went wrong while reseting sessions."));
    }
  };

  return (
    <Container fluid>
      <Row>
        <Header title="Sessions" languageDisabled />
      </Row>
      <Row className="site-cache">
        <span>Flush all users access tokens (including this account).</span>
        <ButtonLoader
          className="cache-button"
          onClick={handleSessionReset}
          disabled={isLoading}
        >
          Flush
        </ButtonLoader>
      </Row>
    </Container>
  );
};

export { Session };
