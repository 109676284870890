import { Column, TableOptions } from "react-table";
import { ICompany } from "../../../../../services/api/profile";
import { RolesCell } from "../../table/cells/RolesCell";
import { ActionCell } from "./cells/ActionCell";

const companiesListColumn = (
  withoutRolesColumn: boolean
): Column<TableOptions<ICompany>>[] => {
  const columns: Column<TableOptions<ICompany>>[] = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "AX code",
      accessor: "axCode",
    },
    {
      Header: "Roles",
      accessor: "roles",
      Cell: (cell) => <RolesCell cell={cell} />,
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (cell) => <ActionCell cell={cell} />,
      disableSortBy: true,
    },
  ];

  return withoutRolesColumn
    ? columns.filter((column) => column.accessor !== "roles")
    : columns;
};

export { companiesListColumn };
