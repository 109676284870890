import { FC } from "react";
import { Badge, BadgeProps } from "react-bootstrap";
import { ApplicationStatus } from "../../../../services/api/application";

const StatusColor: { [key in ApplicationStatus]: BadgeProps["variant"] } = {
  [ApplicationStatus.Open]: "warning",
  [ApplicationStatus.Filled]: "secondary",
  [ApplicationStatus.Approved]: "success",
  [ApplicationStatus.Rejected]: "danger",
};

const StatusName: { [key in ApplicationStatus]: BadgeProps["variant"] } = {
  [ApplicationStatus.Open]: "Draft",
  [ApplicationStatus.Filled]: "Submitted",
  [ApplicationStatus.Approved]: "Approved",
  [ApplicationStatus.Rejected]: "Rejected",
};

interface IProps {
  status: ApplicationStatus;
}

const StatusBadge: FC<IProps> = ({ status }) => {
  return (
    <Badge className="status-badge" variant={StatusColor[status]}>
      {StatusName[status]}
    </Badge>
  );
};

export { StatusBadge };
