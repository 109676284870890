import { FC, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaRegEnvelope, FaPhoneAlt, FaKey } from "react-icons/fa";
import { Button, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GeneralRoute } from "../../constants/routes";
import { Loader } from "../common/loaders/Loader";
import { DeleteProfileModal } from "./modals/DeleteProfileModal";
import { profile as profileSlice } from "./store/slices";
import { registration } from "../authentication/register/store/slices";
import { useTranslation } from "react-i18next";
import { Header } from "../layout/Header";
import { policies } from "../policies/store/slices";
import { Workspace } from "../workspace-switcher/store/slice";
import { SapEnvSelectModal } from "./modals/SapEnvSelectModal";

const Profile: FC = () => {
  const { t } = useTranslation("profile");

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [sapEnvModalOpen, setSapEnvModalOpen] = useState<boolean>(false);

  const profile = useSelector((state) => state.profile);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const currentWorkspace = useSelector(
    (state) => state.workspace.currentWorkspace
  );

  const user = profile.data;

  const dispatch = useDispatch();

  const deleteProfile = useCallback(
    () => dispatch(profileSlice.actions.deleteProfile()),
    [dispatch]
  );

  const resendVerification = useCallback(
    () => dispatch(registration.actions.sendVerification()),
    [dispatch]
  );

  const submitMarketingPolicy = useCallback(
    (consent: boolean) =>
      dispatch(profileSlice.actions.submitMarketingPolicy(consent)),
    [dispatch]
  );

  const toggleMarketingPolicy = useCallback(
    () => dispatch(policies.actions.toggleMarketingPolicy()),
    [dispatch]
  );

  const handleDelete = () => {
    deleteProfile();
    setDeleteModalVisible(false);
  };

  if (!user) {
    return <Loader />;
  }

  return (
    <Container fluid className="profile">
      <Row>
        <Header
          title={t("myProfile") as string}
          languageDisabled={
            !!(
              currentWorkspace &&
              [Workspace.AdminSpace, Workspace.DeveloperSpace].includes(
                currentWorkspace
              )
            )
          }
        />
      </Row>
      <Row>
        <div>
          <div className="info">
            <span className="name">
              {user.name} {user.surname}
            </span>
            <span className="faded">
              {user.roles
                .map((role) => {
                  let name = role.name;

                  // If doesn't have translation
                  // that mean it's department role
                  if (name.includes("roles.")) {
                    name = `${name.replace("roles.", "")} ${t("leader")}`;
                  }

                  return name;
                })
                .join(", ")}
            </span>
          </div>
          <div className="details">
            <span>
              <FaRegEnvelope /> {user.email}{" "}
              <span className="faded">
                {user.emailVerifiedAt
                  ? `(${t("verified")})`
                  : `(${t("notVerified")})`}
              </span>
            </span>
            <span>
              <FaPhoneAlt /> {user.phone || "-"}
            </span>
            <span>
              <FaKey /> ************{" "}
              {!!(user.emailVerifiedAt && !profile.isManager) && (
                <Link to={GeneralRoute.Profile.ChangePassword}>
                  {t("change")}
                </Link>
              )}
            </span>
          </div>
          {profile.data && (
            <div className="marketing">
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={!!profile.data.marketingPolicySubmitted}
                onChange={(e) => submitMarketingPolicy(e.target.checked)}
                label={
                  <>
                    <span>{t("iAgreeProcessing")}</span>{" "}
                    <Link to="#" onClick={toggleMarketingPolicy}>
                      {t("directMarketing")}
                    </Link>
                  </>
                }
              />
            </div>
          )}

          <div className="profile-buttons">
            {!profile.isManager && (
              <Button
                onClick={() => {
                  setDeleteModalVisible(true);
                }}
                variant="outline-secondary"
                disabled={isLoading}
              >
                {t("delete")}
              </Button>
            )}
            {user.emailVerifiedAt ? (
              <Button
                as={Link}
                to={GeneralRoute.Profile.Edit}
                variant="primary"
              >
                {t("edit")}
              </Button>
            ) : (
              <Button
                className="resend-button"
                onClick={() => resendVerification()}
                variant="primary"
                disabled={isLoading}
              >
                {t("resendVerification")}
              </Button>
            )}
            {user.isSapAccount && (
              <Button variant="danger" onClick={() => setSapEnvModalOpen(true)}>
                Change SAP environment
              </Button>
            )}
          </div>
        </div>
        <DeleteProfileModal
          handleClose={() => {
            setDeleteModalVisible(false);
          }}
          handleDelete={handleDelete}
          isVisible={deleteModalVisible}
        />
        <SapEnvSelectModal
          user={user}
          isVisible={sapEnvModalOpen}
          onClose={() => {
            setSapEnvModalOpen(false);
          }}
        />
      </Row>
    </Container>
  );
};

export { Profile };
