import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "../../../../services/api";
import { ICompany } from "../../../../services/api/profile";

interface ICompaniesState {
  companies: any[];
  perPage: number;
  activePage: number;
  meta: IPagination | null;
  search: string;

  company: ICompany | null;
  companyUpdated: boolean;
}

const initialState: ICompaniesState = {
  companies: [],
  perPage: 10,
  activePage: 1,
  meta: null,
  search: "",

  company: null,
  companyUpdated: false,
};

const adminCompanies = createSlice({
  name: "adminCompanies",
  initialState,
  reducers: {
    fetchCompanies: (state, action) => {
      //
    },
    fetchCompany: (state, action) => {
      //
    },
    storeCompany: (state, action) => {
      //
    },
    updateCompany: (state, action) => {
      //
    },
    setCompanies: (state, action) => {
      state.companies = action.payload.data;
      state.meta = action.payload.meta;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.activePage = 1;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setCompanyUpdated: (state) => {
      state.companyUpdated = true;
    },
    resetCompany: (state) => {
      state.company = null;
      state.companyUpdated = false;
    },
    resetCompanies: (state) => {
      state.companies = [];
      state.perPage = 10;
      state.activePage = 1;
      state.meta = null;
      state.search = "";
    },
    reset: () => initialState,
  },
});

export { adminCompanies };
