import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Container,
  Image,
  ListGroup,
} from "react-bootstrap";
import { FormContext, useForm } from "react-hook-form";
import {
  FaBuilding,
  FaDirections,
  FaFileAlt,
  FaInfoCircle,
  FaQuestionCircle,
  FaShieldAlt,
  FaThumbsUp,
} from "react-icons/fa";
import {
  IApplication,
  IApplicationRecommendation,
} from "../../../services/api/application";
import { email, vatNumber } from "../../../constants/regex";
import { StatusBadge } from "../StatusBadge";
import { FileDropzone } from "../../common/files/FileDropzone";
import { QuestionTooltip } from "../../common/tooltips/QuestionTooltip";
import { FileDropdown } from "./FileDropdown";
import { useDispatch, useSelector } from "react-redux";
import { applications } from "../store/slices";
import { Loader } from "../../common/loaders/Loader";
import { InfoAlert, InfoAlertStatus } from "../../alert/InfoAlert";
import { useTranslation } from "react-i18next";
import { Header } from "../../layout/Header";

export type NoNullFields<Ob> = {
  [K in keyof Ob]: Ob[K] extends object
    ? NoNullFields<Ob[K]>
    : NonNullable<Ob[K]>;
};

export type ApplicationQuestions = {
  productSafetyManagement: boolean | "";
  foodSafetyPolicy: boolean | "";
  haccpPlan: boolean | "";
  chemicalsInCleansing: boolean | "";
  pestControlSystem: boolean | "";
  cleaningSchedule: boolean | "";
  proceduresForOperation: boolean | "";
  expertiseSecurityManagement: boolean | "";
  securityPolicy: boolean | "";
  hvttCargo: boolean | "";
  secureVehicleParking: boolean | "";
  routesAndStops: boolean | "";
  driverAssessment: boolean | "";
};

export type ILongApplicationForm = NoNullFields<IApplication> & {
  trans_id: string | null;
  recommendations: IApplicationRecommendation[];
  questions: ApplicationQuestions;
};

interface IProps {
  data: ILongApplicationForm;
  reviewMode?: boolean;
  documentsFetching?: boolean;
  onSubmit: (data: ILongApplicationForm) => void;
  onUpdateDraft: (data: ILongApplicationForm, silent?: boolean) => void;
}

const NewPartnerForm: FC<IProps> = ({
  data,
  reviewMode,
  documentsFetching,
  onSubmit,
  onUpdateDraft,
}) => {
  const { t } = useTranslation("application");

  const dispatch = useDispatch();

  const [required, setRequired] = useState<boolean>(true);
  const [showTransId, setShowTransId] = useState<boolean>(
    !!data.trans_id ||
      (data.recommendations && data.recommendations.length === 0)
  );
  const [
    showProductSafetyFileValidation,
    setShowProductSafetyFileValidation,
  ] = useState(false);
  const [
    showExpertiseSecurityFileValidation,
    setShowExpertiseSecurityFileValidation,
  ] = useState(false);

  const settings = useSelector((state) => state.settings.carrier);

  const methods = useForm<ILongApplicationForm>({
    defaultValues: data,
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    clearError,
    watch,
  } = methods;

  const uploadDocuments = useCallback(
    (documents: File[]) => {
      dispatch(
        applications.actions.uploadDocuments({
          applicationId: data.id,
          documents,
        })
      );

      clearError("documents");
    },
    [data.id, dispatch, clearError]
  );

  const updateDocument = useCallback(
    (documentId: number, documentTypeId: number) => {
      dispatch(
        applications.actions.updateDocument({
          applicationId: data.id,
          documentId,
          data: {
            documentTypeId,
          },
        })
      );

      clearError("documents");
    },
    [data, dispatch, clearError]
  );

  const deleteDocument = useCallback(
    (id: number) => {
      dispatch(
        applications.actions.deleteDocument({
          applicationId: data.id,
          documentId: id,
        })
      );

      clearError("documents");
    },
    [data.id, dispatch, clearError]
  );

  const validateFiles = (formData: ILongApplicationForm) => {
    let isValid = true;

    const documents = Object.values(data.documents);

    if (
      // has "ISO22001/ BRC/ IFS"
      Number(formData.questions.productSafetyManagement) &&
      !documents.find((document) => document.type?.code === "PS_COMP")
    ) {
      setShowProductSafetyFileValidation(true);

      if (productSafetyRef && productSafetyRef.current) {
        productSafetyRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }

      isValid = false;
    } else if (showProductSafetyFileValidation) {
      setShowProductSafetyFileValidation(false);
    }

    if (
      // has "TAPA TSR/ ISO28001"
      Number(formData.questions.expertiseSecurityManagement) &&
      !documents.find((document) => document.type?.code === "SEC_COMP")
    ) {
      setShowExpertiseSecurityFileValidation(true);

      if (expertiseSecurityRef && expertiseSecurityRef.current) {
        expertiseSecurityRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }

      isValid = false;
    } else if (showExpertiseSecurityFileValidation) {
      setShowExpertiseSecurityFileValidation(false);
    }

    if (isValid) {
      onSubmit(formData);
    }
  };

  useEffect(() => {
    if (!required) {
      handleSubmit((data) => {
        onUpdateDraft(data);
      })();

      setRequired(true);
    }
  }, [required, handleSubmit, onUpdateDraft]);

  useEffect(() => {
    if (data.documents) {
      Object.values(data.documents).forEach((document, index) => {
        setValue(
          `documents.${index}.type.id`,
          document.type ? document.type.id : ""
        );
      });
    }
  }, [data.documents, setValue]);

  const productSafetyManagement = watch("questions.productSafetyManagement");
  const expertiseSecurityManagement = watch(
    "questions.expertiseSecurityManagement"
  );

  const showProductSafetyQuestions =
    productSafetyManagement !== "" && !Number(productSafetyManagement);
  const showExpertiseSecurityQuestions =
    expertiseSecurityManagement !== "" && !Number(expertiseSecurityManagement);

  const showProductSafetyFileMessage =
    productSafetyManagement !== "" && Boolean(Number(productSafetyManagement));
  const showExpertiseSecurityFileMessage =
    expertiseSecurityManagement !== "" &&
    Boolean(Number(expertiseSecurityManagement));

  const productSafetyRef = useRef<null | HTMLDivElement>(null);
  const expertiseSecurityRef = useRef<null | HTMLDivElement>(null);

  const renderRecommendationCard = useCallback(
    (index: number) => {
      const getError = (
        section: string,
        index: number,
        key: string
      ): any | null => {
        const error = errors as any;

        return (
          error &&
          error[section] &&
          error[section][index] &&
          error[section][index][key]
        );
      };

      const hasError = (section: string, index: number, key: string): boolean =>
        !!getError(section, index, key);

      const getErrorType = (
        section: string,
        index: number,
        key: string
      ): string | null => {
        const error = getError(section, index, key);

        return error ? error.type : null;
      };

      return (
        <Card key={index}>
          <Card.Header>
            <FaThumbsUp /> {t("recommendation")} #{index + 1}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col></Col>
              <Col lg="8">
                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId={`recommendations.${index}.companyName`}
                  >
                    <Form.Label>{t("companyName")}</Form.Label>
                    <Form.Control
                      name={`recommendations.${index}.companyName`}
                      type="text"
                      ref={register({
                        required,
                        validate: (value) =>
                          required ? value.trim() !== "" : true,
                      })}
                      isInvalid={
                        !!hasError("recommendations", index, "companyName")
                      }
                      maxLength={60}
                      disabled={reviewMode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {["required", "validate"].includes(
                        getErrorType(
                          "recommendations",
                          index,
                          "companyName"
                        ) as string
                      ) && t("requiredField")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId={`recommendations.${index}.companyAddress`}
                  >
                    <Form.Label>{t("companyAddress")}</Form.Label>
                    <Form.Control
                      name={`recommendations.${index}.companyAddress`}
                      type="text"
                      ref={register({
                        required,
                        validate: (value) =>
                          required ? value.trim() !== "" : true,
                      })}
                      isInvalid={
                        !!hasError("recommendations", index, "companyAddress")
                      }
                      maxLength={60}
                      disabled={reviewMode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {["required", "validate"].includes(
                        getErrorType(
                          "recommendations",
                          index,
                          "companyAddress"
                        ) as string
                      ) && t("requiredField")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId={`recommendations.${index}.name`}
                  >
                    <Form.Label>{t("firstName")}</Form.Label>
                    <Form.Control
                      name={`recommendations.${index}.name`}
                      type="text"
                      ref={register({
                        required,
                        validate: (value) =>
                          required ? value.trim() !== "" : true,
                      })}
                      isInvalid={!!hasError("recommendations", index, "name")}
                      maxLength={60}
                      disabled={reviewMode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {["required", "validate"].includes(
                        getErrorType("recommendations", index, "name") as string
                      ) && t("requiredField")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId={`recommendations.${index}.surname`}
                  >
                    <Form.Label>{t("lastName")}</Form.Label>
                    <Form.Control
                      name={`recommendations.${index}.surname`}
                      type="text"
                      ref={register({
                        required,
                        validate: (value) =>
                          required ? value.trim() !== "" : true,
                      })}
                      isInvalid={
                        !!hasError("recommendations", index, "surname")
                      }
                      maxLength={60}
                      disabled={reviewMode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {["required", "validate"].includes(
                        getErrorType(
                          "recommendations",
                          index,
                          "surname"
                        ) as string
                      ) && t("requiredField")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId={`recommendations.${index}.position`}
                  >
                    <Form.Label>{t("position")}</Form.Label>
                    <Form.Control
                      name={`recommendations.${index}.position`}
                      type="text"
                      ref={register({
                        required,
                        validate: (value) =>
                          required ? value.trim() !== "" : true,
                      })}
                      isInvalid={
                        !!hasError("recommendations", index, "position")
                      }
                      maxLength={60}
                      disabled={reviewMode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {["required", "validate"].includes(
                        getErrorType(
                          "recommendations",
                          index,
                          "position"
                        ) as string
                      ) && t("requiredField")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId={`recommendations.${index}.email`}
                  >
                    <Form.Label>{t("emailAddress")}</Form.Label>
                    <Form.Control
                      name={`recommendations.${index}.email`}
                      type="text"
                      ref={register({
                        required,
                        pattern: email,
                        validate: (value) =>
                          required ? value.trim() !== "" : true,
                      })}
                      isInvalid={!!hasError("recommendations", index, "email")}
                      maxLength={60}
                      disabled={reviewMode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {["required", "validate"].includes(
                        getErrorType(
                          "recommendations",
                          index,
                          "email"
                        ) as string
                      ) && t("requiredField")}
                      {getErrorType("recommendations", index, "email") ===
                        "pattern" && t("invalidEmail")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId={`recommendations.${index}.phone`}
                  >
                    <Form.Label>{t("mobilePhone")}</Form.Label>
                    <Form.Control
                      name={`recommendations.${index}.phone`}
                      type="text"
                      ref={register({
                        required,
                        validate: (value) =>
                          required ? value.trim() !== "" : true,
                      })}
                      isInvalid={!!hasError("recommendations", index, "phone")}
                      maxLength={60}
                      disabled={reviewMode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {["required", "validate"].includes(
                        getErrorType(
                          "recommendations",
                          index,
                          "phone"
                        ) as string
                      ) && t("requiredField")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Col>
              <Col></Col>
            </Row>
          </Card.Body>
        </Card>
      );
    },
    [register, required, reviewMode, t, errors]
  );

  if (!settings) {
    return <Loader />;
  }

  return (
    <Container fluid>
      <Row className="application-form-header">
        <Header
          title={t("newPartnerApplication") as string}
          titleBadge={
            data && data.status && <StatusBadge status={data.status} />
          }
        />
      </Row>
      <Row className="application-form">
        <Col></Col>
        <Col md="10" lg="8" xl="6">
          {data.rejectComment && (
            <InfoAlert
              label={t("cancellationComment") as string}
              message={data.rejectComment}
              status={InfoAlertStatus.Error}
            />
          )}

          <FormContext {...methods}>
            <Form onSubmit={handleSubmit(validateFiles)} autoComplete="off">
              <Card>
                <Card.Header>
                  <FaBuilding /> {t("company")}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col></Col>
                    <Col lg="8">
                      <h3>{t("details")}</h3>
                      <Form.Row>
                        <Form.Group as={Col} controlId="company.name">
                          <Form.Label>{t("companyName")}</Form.Label>
                          <Form.Control
                            name="company.name"
                            type="text"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.company?.name}
                            maxLength={120}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.company?.name?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group
                          as={Col}
                          controlId="company.registrationNumber"
                        >
                          <Form.Label>
                            {t("companyRegistrationNumber")}
                          </Form.Label>
                          <Form.Control
                            name="company.registrationNumber"
                            type="text"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.company?.registrationNumber}
                            maxLength={60}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.company?.registrationNumber?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="company.vatNumber">
                          <Form.Label>{t("vatNumber")}</Form.Label>
                          <Form.Control
                            name="company.vatNumber"
                            type="text"
                            ref={register({
                              required,
                              pattern: vatNumber,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.company?.vatNumber}
                            maxLength={20}
                            disabled={reviewMode}
                            style={{ textTransform: "uppercase" }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.company?.vatNumber?.type as string
                            ) && t("requiredField")}
                            {errors.company?.vatNumber?.type === "pattern" &&
                              t("vatNumberValidation")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <h3>{t("address")}</h3>

                      <Form.Row>
                        <Form.Group as={Col} controlId="address.street">
                          <Form.Label>{t("street")}</Form.Label>
                          <Form.Control
                            name="address.street"
                            type="text"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.address?.street}
                            maxLength={60}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.address?.street?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="address.city">
                          <Form.Label>{t("cityZip")}</Form.Label>
                          <Form.Control
                            name="address.city"
                            type="text"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.address?.city}
                            maxLength={40}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.address?.city?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="address.country">
                          <Form.Label>{t("country")}</Form.Label>
                          <Form.Control
                            name="address.country"
                            as="select"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.address?.country}
                            disabled={reviewMode}
                            defaultValue=""
                          >
                            <option disabled value="">
                              {t("selectCountry")}
                            </option>
                            {settings &&
                              settings.general.countries.map(
                                (country, index) => (
                                  <option key={index} value={country.code}>
                                    {country.name}
                                  </option>
                                )
                              )}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.address?.country?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <h3>{t("bankAccount")}</h3>

                      <Form.Row>
                        <Form.Group as={Col} controlId="bankAccount.bankName">
                          <Form.Label>{t("bankName")}</Form.Label>
                          <Form.Control
                            name="bankAccount.bankName"
                            type="text"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.bankAccount?.bankName}
                            maxLength={190}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.bankAccount?.bankName?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="bankAccount.number">
                          <Form.Label>{t("accountNumber")}</Form.Label>
                          <Form.Control
                            name="bankAccount.number"
                            type="text"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.bankAccount?.number}
                            maxLength={34}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.bankAccount?.number?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="bankAccount.swift">
                          <Form.Label>{t("swift")}</Form.Label>
                          <Form.Control
                            name="bankAccount.swift"
                            type="text"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.bankAccount?.swift}
                            maxLength={190}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.bankAccount?.swift?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="bankAccount.currency">
                          <Form.Label>{t("currency")}</Form.Label>
                          <Form.Control
                            name="bankAccount.currency"
                            as="select"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.bankAccount?.currency}
                            disabled={reviewMode}
                            defaultValue=""
                          >
                            <option disabled value="">
                              {t("selectCurrency")}
                            </option>
                            {settings &&
                              settings.general.currencies.map(
                                (currency, index) => (
                                  <option key={index} value={currency.code}>
                                    {currency.name}
                                  </option>
                                )
                              )}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.bankAccount?.currency?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <h3>{t("companyContacts")}</h3>

                      <Form.Row>
                        <Form.Group as={Col} controlId="company.email">
                          <Form.Label>{t("emailAddress")}</Form.Label>
                          <Form.Control
                            name="company.email"
                            type="text"
                            ref={register({
                              required,
                              pattern: email,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.company?.email}
                            maxLength={190}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.company?.email?.type as string
                            ) && (t("requiredField") as any)}
                            {errors.company?.email?.type === "pattern" &&
                              (t("invalidEmail") as any)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="company.phone">
                          <Form.Label>{t("phoneOptional")}</Form.Label>
                          <Form.Control
                            name="company.phone"
                            type="text"
                            ref={register()}
                            isInvalid={!!errors.company?.phone}
                            maxLength={30}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.company?.phone?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Col>
                    <Col></Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="transId-card">
                <Card.Header>
                  <FaInfoCircle /> {t("transId")}
                </Card.Header>
                <Card.Body>
                  <Row className="logo-row">
                    <Image src="/images/trans-eu.png" alt="Trans.eu" />
                  </Row>
                  <Row>
                    <Col></Col>
                    <Col lg="8">
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          controlId="trans_id"
                          className="group-column"
                        >
                          <Form.Check
                            checked={showTransId}
                            onChange={(e) => setShowTransId(e.target.checked)}
                            name="showTransId"
                            type="checkbox"
                            label={`${t("iHaveTransId")}:`}
                            disabled={reviewMode}
                            className="trans-id-check"
                          />
                          <Form.Control
                            name="trans_id"
                            type="text"
                            ref={register({
                              required: required ? showTransId : false,
                            })}
                            isInvalid={!!errors.trans_id}
                            maxLength={60}
                            disabled={!showTransId || reviewMode}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            style={{ marginLeft: "1rem" }}
                          >
                            {["required", "validate"].includes(
                              errors.trans_id?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Col>
                    <Col></Col>
                  </Row>
                </Card.Body>
              </Card>

              {!showTransId &&
                [0, 1].map((index) => renderRecommendationCard(index))}

              <Card>
                <Card.Header>
                  <FaShieldAlt /> {t("insurance")}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col></Col>
                    <Col lg="8">
                      <Form.Row>
                        <Form.Group as={Col} controlId="insurance.brokerName">
                          <Form.Label>{t("companyBrokerName")}</Form.Label>
                          <Form.Control
                            name="insurance.brokerName"
                            type="text"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.insurance?.brokerName}
                            maxLength={30}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.insurance?.brokerName?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="insurance.phone">
                          <Form.Label>{t("contactPhone")}</Form.Label>
                          <Form.Control
                            name="insurance.phone"
                            type="text"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.insurance?.phone}
                            maxLength={190}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.insurance?.phone?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="insurance.email">
                          <Form.Label>{t("emailAddress")}</Form.Label>
                          <Form.Control
                            name="insurance.email"
                            type="text"
                            ref={register({
                              required,
                              pattern: email,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.insurance?.email}
                            maxLength={190}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.insurance?.email?.type as string
                            ) && (t("requiredField") as any)}
                            {errors.insurance?.email?.type === "pattern" &&
                              (t("invalidEmail") as any)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Col>
                    <Col></Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="directions-card">
                <Card.Header>
                  <span>
                    <FaDirections /> {t("directions")}
                  </span>
                  <QuestionTooltip
                    explanation={t("pleaseChooseMainDirection")}
                    iconStyle="white"
                  />
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col></Col>
                    <Col lg="8">
                      <Form.Row>
                        <Form.Group as={Col} controlId="direction.id">
                          <Form.Label>{t("direction")}</Form.Label>
                          <Form.Control
                            name="direction.id"
                            as="select"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.direction?.id}
                            disabled={reviewMode}
                            defaultValue=""
                          >
                            <option disabled value="">
                              {t("selectDirection")}
                            </option>
                            {settings &&
                              settings.applications.directions.map(
                                (direction, index) => (
                                  <option key={index} value={direction.id}>
                                    {direction.name}
                                  </option>
                                )
                              )}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.direction?.id?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Col>
                    <Col></Col>
                  </Row>
                </Card.Body>
              </Card>

              {data.questions && (
                <Card className="questionnaire-card">
                  <Card.Header>
                    <span>
                      <FaQuestionCircle /> {t("questionnaire")}
                    </span>
                    <QuestionTooltip
                      explanation={t("yourAnswersAreImportant")}
                      iconStyle="white"
                    />
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col></Col>
                      <Col xl="8">
                        <div className="main-question">
                          <div>{t("productSafetyManagement")}</div>
                          <div className="radios" key="inline-radio">
                            <Form.Check
                              inline
                              label={t("yes")}
                              name="questions.productSafetyManagement"
                              value={1}
                              type="radio"
                              id={`productSafetyManagement-radio-1`}
                              ref={register({
                                required,
                              })}
                              isInvalid={
                                !!errors.questions?.productSafetyManagement
                              }
                              disabled={reviewMode}
                            />
                            <Form.Check
                              inline
                              defaultChecked
                              label={t("no")}
                              name="questions.productSafetyManagement"
                              value={0}
                              type="radio"
                              id={`productSafetyManagement-radio-2`}
                              ref={register({
                                required,
                              })}
                              isInvalid={
                                !!errors.questions?.productSafetyManagement
                              }
                              disabled={reviewMode}
                            />
                          </div>
                        </div>

                        {showProductSafetyFileMessage && (
                          <small
                            ref={productSafetyRef}
                            className={`add-document-warning ${
                              showProductSafetyFileValidation
                                ? "text-danger"
                                : "text-secondary"
                            }`}
                          >
                            {t("addIsoToCompanyDocuments")}
                          </small>
                        )}

                        {showProductSafetyQuestions && (
                          <ListGroup style={{ marginBottom: "2.5rem" }}>
                            <ListGroup.Item>
                              <div>{t("foodSafetyPolicy")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.foodSafetyPolicy"
                                  value={1}
                                  type="radio"
                                  id={`foodSafetyPolicy-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.foodSafetyPolicy
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.foodSafetyPolicy"
                                  value={0}
                                  type="radio"
                                  id={`foodSafetyPolicy-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.foodSafetyPolicy
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("haccpPlan")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.haccpPlan"
                                  value={1}
                                  type="radio"
                                  id={`haccpPlan-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.haccpPlan}
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.haccpPlan"
                                  value={0}
                                  type="radio"
                                  id={`haccpPlan-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.haccpPlan}
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("chemicalsInCleansing")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.chemicalsInCleansing"
                                  value={1}
                                  type="radio"
                                  id={`chemicalsInCleansing-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.chemicalsInCleansing
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.chemicalsInCleansing"
                                  value={0}
                                  type="radio"
                                  id={`chemicalsInCleansing-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.chemicalsInCleansing
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("pestControlSystem")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.pestControlSystem"
                                  value={1}
                                  type="radio"
                                  id={`pestControlSystem-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.pestControlSystem
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.pestControlSystem"
                                  value={0}
                                  type="radio"
                                  id={`pestControlSystem-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.pestControlSystem
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("cleaningSchedule")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.cleaningSchedule"
                                  value={1}
                                  type="radio"
                                  id={`cleaningSchedule-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.cleaningSchedule
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.cleaningSchedule"
                                  value={0}
                                  type="radio"
                                  id={`cleaningSchedule-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.cleaningSchedule
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("proceduresForOperation")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.proceduresForOperation"
                                  value={1}
                                  type="radio"
                                  id={`proceduresForOperation-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.proceduresForOperation
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.proceduresForOperation"
                                  value={0}
                                  type="radio"
                                  id={`proceduresForOperation-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.proceduresForOperation
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                        )}

                        <hr />

                        <div className="main-question">
                          <div>{t("expertiseSecurityManagement")}</div>
                          <div className="radios" key="inline-radio">
                            <Form.Check
                              inline
                              label={t("yes")}
                              name="questions.expertiseSecurityManagement"
                              value={1}
                              type="radio"
                              id={`expertiseSecurityManagement-radio-1`}
                              ref={register({
                                required,
                              })}
                              isInvalid={
                                !!errors.questions?.expertiseSecurityManagement
                              }
                              disabled={reviewMode}
                            />
                            <Form.Check
                              inline
                              defaultChecked
                              label={t("no")}
                              name="questions.expertiseSecurityManagement"
                              value={0}
                              type="radio"
                              id={`expertiseSecurityManagement-radio-2`}
                              ref={register({
                                required,
                              })}
                              isInvalid={
                                !!errors.questions?.expertiseSecurityManagement
                              }
                              disabled={reviewMode}
                            />
                          </div>
                        </div>

                        {showExpertiseSecurityFileMessage && (
                          <small
                            ref={expertiseSecurityRef}
                            className={`add-document-warning ${
                              showExpertiseSecurityFileValidation
                                ? "text-danger"
                                : "text-secondary"
                            }`}
                          >
                            {t("addTapaToCompanyDocuments")}
                          </small>
                        )}

                        {showExpertiseSecurityQuestions && (
                          <ListGroup>
                            <ListGroup.Item>
                              <div>{t("securityPolicy")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.securityPolicy"
                                  value={1}
                                  type="radio"
                                  id={`securityPolicy-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.securityPolicy}
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.securityPolicy"
                                  value={0}
                                  type="radio"
                                  id={`securityPolicy-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.securityPolicy}
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("hvttCargo")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.hvttCargo"
                                  value={1}
                                  type="radio"
                                  id={`hvttCargo-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.hvttCargo}
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.hvttCargo"
                                  value={0}
                                  type="radio"
                                  id={`hvttCargo-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.hvttCargo}
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("secureVehicleParking")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.secureVehicleParking"
                                  value={1}
                                  type="radio"
                                  id={`secureVehicleParking-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.secureVehicleParking
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.secureVehicleParking"
                                  value={0}
                                  type="radio"
                                  id={`secureVehicleParking-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.secureVehicleParking
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("routesAndStops")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.routesAndStops"
                                  value={1}
                                  type="radio"
                                  id={`routesAndStops-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.routesAndStops}
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.routesAndStops"
                                  value={0}
                                  type="radio"
                                  id={`routesAndStops-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.routesAndStops}
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("driverAssessment")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.driverAssessment"
                                  value={1}
                                  type="radio"
                                  id={`driverAssessment-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.driverAssessment
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.driverAssessment"
                                  value={0}
                                  type="radio"
                                  id={`driverAssessment-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.driverAssessment
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                        )}
                      </Col>
                      <Col></Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}

              <Card className="documents-card">
                <Card.Header>
                  <span>
                    <FaFileAlt /> {t("companyDocuments")}{" "}
                  </span>
                  <QuestionTooltip
                    explanation={`${t(
                      "allowedFiles"
                    )}: images, pdf, word, excel, text documents.`}
                    iconStyle="white"
                  />
                </Card.Header>
                <Card.Body>
                  {documentsFetching && (
                    <div className="documents-fetching">
                      <Loader isLoading />
                    </div>
                  )}
                  {!reviewMode && (
                    <FileDropzone
                      onDropAccepted={uploadDocuments}
                      maxSize={settings.general.maxUploadSize * 1000}
                      multiple
                    />
                  )}
                  {data && data.documents && (
                    <FileDropdown
                      files={data.documents}
                      onDelete={deleteDocument}
                      onUpdate={updateDocument}
                      required={required}
                      disabled={reviewMode}
                      documentTypes={
                        settings ? settings.applications.documentTypes : []
                      }
                    />
                  )}
                  <div className="required-documents">
                    <p>
                      <span
                        dangerouslySetInnerHTML={{ __html: t("requiredDocs") }}
                      />{" "}
                      <strong>
                        {showProductSafetyFileMessage &&
                          `, ${t("isoDocument")}`}
                        {showExpertiseSecurityFileMessage &&
                          `, ${t("tapaDocument")}`}
                      </strong>
                      .
                    </p>
                  </div>
                </Card.Body>
              </Card>

              <div className="form-buttons">
                <Button
                  variant="outline-secondary"
                  onClick={() => setRequired(false)}
                  disabled={reviewMode}
                >
                  {t("saveDraft")}
                </Button>

                <Button type="submit" disabled={reviewMode}>
                  {t("submit")}
                </Button>
              </div>
            </Form>
          </FormContext>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export { NewPartnerForm };
