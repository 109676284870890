import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../../../services/api/users";

export interface IUserState {
  data: IUser | null;
  removed: boolean;
}

const initialState: IUserState = {
  data: null,
  removed: false,
};

const userShow = createSlice({
  name: "userShow",
  initialState,
  reducers: {
    fetchUser: (state, action) => {
      //
    },
    setData: (state, action) => {
      state.data = action.payload.data;
    },
    removeUser: (state, action) => {
      //
    },
    setRemoved: (state) => {
      state.removed = true;
    },
    reset: () => initialState,
  },
});

export { userShow };
