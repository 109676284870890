import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "typed-redux-saga";
import {
  IFetchDocumentParams,
  documentsAPI,
} from "../../../services/api/documents";
import { AppState } from "../../../store";
import { loader } from "../../common/loaders/store/slices";
import { documents } from "./slices";
import { showError, showSuccess } from "../../alert/store/slices";
import { translated } from "../../../i18n";
import { profile } from "../../profile/store/slices";
import { AppModule } from "../../../services/api/settings";

function* documentSagas() {
  yield* takeLatest(documents.actions.setSearch, debounceSearch);
  yield* fork(perPageListener);
  yield* fork(paginationListener);
  yield* fork(authorFilterListener);
  yield* fork(deletedFilterListener);
  yield* fork(statusFilterListener);
  yield* fork(typeFilterListener);
  yield* fork(companyChangeListener);
  yield* fork(deleteDocumentListener);
}

function* debounceSearch() {
  yield* delay(500);

  yield* call(fetchDocuments);
}

function* perPageListener() {
  while (true) {
    yield take(documents.actions.setPerPage);

    yield* call(fetchDocuments);
  }
}

function* paginationListener() {
  while (true) {
    yield take(documents.actions.setActivePage);

    yield* call(fetchDocuments, true);
  }
}

function* authorFilterListener() {
  while (true) {
    yield take(documents.actions.setShowOnlyMy);

    yield* call(fetchDocuments);
  }
}

function* deletedFilterListener() {
  while (true) {
    yield take(documents.actions.setShowDeleted);

    yield* call(fetchDocuments);
  }
}

function* statusFilterListener() {
  while (true) {
    yield take(documents.actions.setStatus);

    yield* call(fetchDocuments);
  }
}

function* typeFilterListener() {
  while (true) {
    yield take(documents.actions.setTypeId);

    yield* call(fetchDocuments);
  }
}

function* companyChangeListener() {
  while (true) {
    const {
      payload: { appModule },
    } = yield take(profile.actions.setCompanyChanged);

    if (appModule === AppModule.Documents) {
      yield put(documents.actions.resetPagination());

      yield* call(fetchDocuments);
    }
  }
}

function* deleteDocumentListener() {
  while (true) {
    const { payload: documentId } = yield take(
      documents.actions.deleteDocument
    );

    if (documentId) {
      yield* call(deleteDocument, documentId);
    }
  }
}

function* fetchDocuments(concatData?: boolean) {
  yield put(loader.actions.startLoader());

  const {
    search,
    perPage,
    page,
    showDeleted,
    showOnlyMy,
    status,
    typeId,
    selectedCompany,
  } = yield select((state: AppState) => ({
    search: state.documents.search,
    perPage: state.documents.perPage,
    page: state.documents.activePage,
    showDeleted: state.documents.showDeleted,
    showOnlyMy: state.documents.showOnlyMy,
    status: state.documents.status,
    typeId: state.documents.typeId,
    selectedCompany: state.profile.data?.selectedCompany,
  }));

  const requestParams: IFetchDocumentParams = {
    search,
    perPage,
    page,
    showDeleted,
    showOnlyMy,
    status,
    typeId,
    companyId: selectedCompany.id,
  };

  const response = yield* call(documentsAPI.fetchDocuments, requestParams);

  if (response && response.data && response.meta) {
    if (concatData) {
      yield put(documents.actions.concatData(response));
    } else {
      yield put(documents.actions.setData(response));
    }
  } else {
    yield put(showError(translated.errorWhileGettingDocuments));
  }

  yield put(loader.actions.stopLoader());
}

function* deleteDocument(documentId: string) {
  const result = yield* call(documentsAPI.deleteDocument, documentId);

  if (result && result.success) {
    yield put(showSuccess(translated.documentDeleted));

    yield call(fetchDocuments);
  } else {
    yield put(showError(translated.errorWhileDeletingDocument));
  }
}

export { documentSagas };
