import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "typed-redux-saga";
import { fetchUser } from ".";
import {
  adminUsersAPI,
  IChangeCompanyRoleRequest,
  RoleLevel,
} from "../../../../../services/api/admin/users";
import { AppState } from "../../../../../store";
import { showError, showSuccess } from "../../../../alert/store/slices";
import { loader } from "../../../../common/loaders/store/slices";
import { adminUsers } from "../slices";
import { fetchCompany } from "./companies";

function* adminUsersCompaniesRolesSaga() {
  yield* takeLatest(adminUsers.actions.changeCompanyRole, changeCompanyRole);
  yield* takeLatest(adminUsers.actions.fetchCompanyRoles, handleCompanyRoles);
}

function* handleCompanyRoles({
  payload: { userId, companyId },
}: PayloadAction<{ userId: string; companyId: string }>) {
  if (userId) {
    const { user } = yield* select((state: AppState) => ({
      user: state.adminUsers.userData,
    }));

    if (!user) {
      yield* call(fetchUser, userId);
    }
  }

  if (companyId) {
    yield* call(fetchCompany, companyId);
  }
  yield* call(fetchCompanyRoles);
}

function* changeCompanyRole({
  payload: { userId, roleId, companyId },
}: PayloadAction<{ userId: string; roleId: string; companyId: string }>) {
  yield put(loader.actions.startLoader());

  const request: IChangeCompanyRoleRequest = {
    userId,
    roleId,
    companyId,
  };

  const response = yield* call(adminUsersAPI.changeCompanyRole, request);

  if (response && response.data) {
    yield put(adminUsers.actions.setUpdatedUser(response.data));

    yield put(showSuccess("User company role changed."));
  } else {
    yield put(
      showError("Something went wrong while changing user company role.")
    );
  }

  yield put(loader.actions.stopLoader());
}

function* fetchCompanyRoles() {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminUsersAPI.fetchRoles, RoleLevel.Company);

  if (response && response.data) {
    yield put(adminUsers.actions.setRoles(response.data));
  } else {
    yield put(showError("Something went wrong while getting roles."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminUsersCompaniesRolesSaga };
