import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "../../../../services/api";
import {
  CompanyRole,
  ICompany,
  ICompanyShort,
  IRole,
  Role,
} from "../../../../services/api/profile";
import { IUser } from "../../../../services/api/users";

interface IUsersState {
  usersData: any[];
  userData: IUser | null;
  roles: IRole<Role>[];
  departments: IRole<string>[];
  carrierCompanyRoles: IRole<CompanyRole>[];
  invitedUser: IUser | null;
  updatedUser: IUser | null;
  companies: any[];
  companiesShort: ICompanyShort[];
  company: ICompany | null;
  userSearch: string;
  companySearch: string;
  usersPerPage: number;
  usersActivePage: number;
  usersRole?: Role;
  usersCompanyRole?: CompanyRole;
  meta: IPagination | null;
}

const initialState: IUsersState = {
  usersData: [],
  userData: null,
  roles: [],
  departments: [],
  carrierCompanyRoles: [],
  invitedUser: null,
  updatedUser: null,
  companies: [],
  companiesShort: [],
  company: null,
  userSearch: "",
  companySearch: "",
  usersPerPage: 10,
  usersActivePage: 1,
  usersRole: undefined,
  usersCompanyRole: undefined,
  meta: null,
};

const adminUsers = createSlice({
  name: "adminUsers",
  initialState,
  reducers: {
    fetchUsers: (state) => {
      //
    },
    fetchUser: (state, action) => {
      //
    },
    invite: (state, action) => {
      //
    },
    changeRole: (state, action) => {
      //
    },
    changeDepartmentRole: (state, action) => {
      //
    },
    changeCompanyRole: (state, action) => {
      //
    },
    fetchUserRoles: (state, action) => {
      //
    },
    fetchDepartmentRoles: (state, action) => {
      //
    },
    fetchCompanyRoles: (state, action) => {
      //
    },
    fetchCompanies: (state, action) => {
      //
    },
    fetchCompanyShort: (state, action) => {
      //
    },
    assignCompany: (state, action) => {
      //
    },
    removeCompany: (state, action) => {
      //
    },
    toggleConditions: (state, action) => {
      //
    },
    storeConditions: (state, action) => {
      //
    },
    setUsers: (state, action) => {
      state.usersData = action.payload.data;
      state.meta = action.payload.meta;
    },
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setInvitedUser: (state, action) => {
      state.invitedUser = action.payload;
    },
    setUpdatedUser: (state, action) => {
      state.updatedUser = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setCarrierCompanyRoles: (state, action) => {
      state.carrierCompanyRoles = action.payload;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setCompaniesShort: (state, action) => {
      state.companiesShort = action.payload;
    },
    setUserSearch: (state, action) => {
      state.userSearch = action.payload;
    },
    setUsersActivePage: (state, action) => {
      state.usersActivePage = action.payload;
    },
    setUsersPerPage: (state, action) => {
      state.usersPerPage = action.payload;
    },
    setUsersRole: (state, action) => {
      state.usersRole = action.payload;
    },
    setUsersCompanyRole: (state, action) => {
      state.usersCompanyRole = action.payload;
    },
    setCompanySearch: (state, action) => {
      state.companySearch = action.payload.search;
    },
    resetInvitedUser: (state) => {
      state.invitedUser = null;
    },
    resetCompany: (state) => {
      state.company = null;
    },
    resetCompaniesShort: (state) => {
      state.companiesShort = [];
    },
    resetUpdatedUser: (state) => {
      state.updatedUser = null;
    },
    resetUser: (state) => {
      state.userData = null;
    },
    resetUserRoles: (state) => {
      state.roles = [];
    },
    resetDepartments: (state) => {
      state.departments = [];
    },
    resetCompanyRoles: (state) => {
      state.carrierCompanyRoles = [];
    },
    resetUsersTable: (state) => {
      state.usersData = [];
      state.userSearch = "";
      state.usersPerPage = 10;
      state.usersActivePage = 1;
      state.meta = null;
    },
    resetCompaniesTable: (state) => {
      state.companies = [];
      state.companySearch = "";
    },
    resetCompanies: (state) => {
      state.companies = [];
    },
    reset: () => initialState,
  },
});

export { adminUsers };
