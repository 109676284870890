import { createSlice } from "@reduxjs/toolkit";
import { ICarrierSettings } from "../../../services/api/settings";

export interface ISettingsState {
  carrier: ICarrierSettings | null;
  isLoading: boolean;
}

const initialState: ISettingsState = {
  carrier: null,
  isLoading: true,
};

const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    fetchCarrierSettings: (state) => {
      //
    },
    setCarrierSettings: (state, action) => {
      state.carrier = action.payload;
      state.isLoading = false;
    },
    reset: () => initialState,
  },
});

export { settings };
