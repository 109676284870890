import { FC, useCallback, useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Cover } from "../cover/Cover";
import { PublicTemplate } from "../layout/PublicTemplate";
import { LanguageSwitcher } from "../translations/LanguageSwitcher";
import { useHistory } from "react-router-dom";
import { maintenance } from "./store/slices";
import { useDispatch } from "react-redux";

const MaintenancePage: FC = () => {
  const { t } = useTranslation("maintenancePage");
  const dispatch = useDispatch();
  const history = useHistory();

  const [seconds, setSeconds] = useState(10);

  const showPage = useCallback(
    (show: boolean) => dispatch(maintenance.actions.showPage(show)),
    [dispatch]
  );

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds <= 0) {
        showPage(false);
        history.push("/");
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [history, seconds, showPage]);

  return (
    <PublicTemplate>
      <Container className="error-page" fluid>
        <Cover />

        <Row className="language-row">
          <LanguageSwitcher />
        </Row>
        <Row>
          <Col />
          <Col xs={12} md={8} lg={8} xl={6}>
            <h1>{t("applicationIsDown")}</h1>
            <p>{t("pleaseComeBackLater")}</p>
          </Col>
          <Col />
        </Row>
      </Container>
    </PublicTemplate>
  );
};

export { MaintenancePage };
