import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "../../../../services/api";
import { ProjectChangeLog } from "../../../../services/api/projects";

export interface ChangeLogState {
  data: ProjectChangeLog[];
  meta: IPagination | null;
  isLoading: boolean;
}

const initialState: ChangeLogState = {
  data: [],
  meta: null,
  isLoading: false,
};

const projectChangeLog = createSlice({
  name: "projectChangeLog",
  initialState,
  reducers: {
    fetch: (state, action) => {
      //
    },
    setData: (state, action) => {
      if (action.payload.data.length > 0) {
        state.data = [...state.data, ...action.payload.data];
      }

      if (action.payload.meta) {
        state.meta = action.payload.meta;
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    reset: () => initialState,
  },
});

export { projectChangeLog };
