import { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { GeneralRoute } from "../../../constants/routes";
import { IProfileState } from "../../profile/store/slices";
import { useSelector } from "react-redux";
import { RoleFirstPage } from "../../routes/LoginRedirect";
import { Role } from "../../../services/api/profile";
import { Workspace } from "../../workspace-switcher/store/slice";

interface IProps {
  collapsed: boolean;
  profile: IProfileState;
}

const SidebarLogo: FC<IProps> = ({ collapsed, profile }) => {
  const history = useHistory();
  const currentWorkspace = useSelector(
    (state) => state.workspace.currentWorkspace
  );

  const logoRoute = useMemo(() => {
    const isVerified =
      profile && profile.data ? profile.data.emailVerifiedAt : false;

    if (isVerified) {
      if (
        currentWorkspace === Workspace.DeveloperSpace &&
        profile.isSuperAdmin
      ) {
        return RoleFirstPage[Role.SuperAdmin];
      }

      if (currentWorkspace === Workspace.AdminSpace && profile.isAdmin) {
        return RoleFirstPage[Role.Admin];
      }

      if (currentWorkspace === Workspace.CarrierSpace && profile.isCarrier) {
        return RoleFirstPage[Role.Carrier];
      }

      if (currentWorkspace === Workspace.CarrierSpace && profile.isManager) {
        return RoleFirstPage[Role.Manager];
      }

      if (currentWorkspace === Workspace.CarrierSpace && profile.isGuest) {
        return RoleFirstPage[Role.Guest];
      }
    }

    return GeneralRoute.Profile.Show;
  }, [profile, currentWorkspace]);

  return (
    <div
      className="sidebar-logo"
      onClick={() => {
        history.push(logoRoute);
      }}
    >
      <img
        src="/images/logo-icon.svg"
        width="40px"
        alt="Everwest"
        style={{
          display: collapsed ? "block" : "none",
        }}
      />
      <img
        src="/images/logo.svg"
        width="150px"
        alt="Everwest"
        style={{
          display: collapsed ? "none" : "block",
        }}
      />
    </div>
  );
};

export { SidebarLogo };
