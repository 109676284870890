import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IPagination } from "../../../services/api";

interface IProps {
  activePage: number;
  perPage: number;
  meta: IPagination | null;
}

const ShowPagesInfo: FC<IProps> = ({ activePage, perPage, meta }) => {
  const { t } = useTranslation("showPagesInfo");

  return (
    <div>
      {(t("showingEntries") as string)
        .replace("{activePage}", activePage.toString())
        .replace("{perPage}", perPage.toString())
        .replace("{pages}", meta && meta.total ? meta.total.toString() : "0")}
    </div>
  );
};

export { ShowPagesInfo };
