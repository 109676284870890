import { FC } from "react";
import { Badge } from "react-bootstrap";
import { IProjectLocationType } from "../../../services/api/projects";

interface IProps {
  status: IProjectLocationType;
}

const LocationStatusBadge: FC<IProps> = ({ status }) => {
  return (
    <Badge className="status-badge" variant="dark">
      {status.name}
    </Badge>
  );
};

export { LocationStatusBadge };
