import { Container } from "react-bootstrap";
import { Footer } from "./Footer";

type Props = {
  children: React.ReactNode;
};

const PublicTemplate = ({ children }: Props) => {
  return (
    <Container fluid className="public-template">
      {children}
      <Footer />
    </Container>
  );
};

export { PublicTemplate };
