import { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFileAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { LanguageCode } from "../../../../../i18n";
import { ProjectPolicy } from "../../../../../services/api/admin/project-policies";
import { LanguagePills } from "../../../../common/language-pills/LanguagePills";

interface IProps {
  policy: ProjectPolicy;
  visible: boolean;
  onHide: () => void;
}

const AdditionalInformationModal: FC<IProps> = ({
  policy,
  visible,
  onHide,
}) => {
  const { t } = useTranslation("additionalInformationModal");

  const profile = useSelector((state) => state.profile.data);

  const [language, setLanguage] = useState<LanguageCode>(
    profile ? profile.language : LanguageCode.EN
  );

  const title = policy.title.code;
  const text = policy.data[language];

  return (
    <Modal
      show={visible}
      onHide={onHide}
      size="xl"
      scrollable
      className="private-policy-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <FaFileAlt /> {t(title, { ns: "policies" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowX: "hidden", textAlign: "left" }}>
        <LanguagePills
          onSelect={(language) => setLanguage(language as LanguageCode)}
          style={{
            textTransform: "uppercase",
            display: "flex",
            justifyContent: "flex-end",
          }}
          defaultLanguage={language}
        />
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { AdditionalInformationModal };
