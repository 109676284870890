import { FC } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { AppState } from "../../../store";

interface IProps {
  className?: string;
  isLoading?: boolean;
  style?: React.CSSProperties;
}

const Loader: FC<IProps> = ({
  className,
  isLoading: isLoadingCustom,
  style,
}) => {
  const isLoadingGlobal = useSelector(
    (state: AppState) => state.loader.isLoading
  );

  const isLoading: boolean =
    typeof isLoadingCustom === "undefined" ? isLoadingGlobal : isLoadingCustom;

  if (!isLoading) return null;

  return (
    <div
      className={classNames("spinner-border", "text-primary", className)}
      role="status"
      style={style}
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export { Loader };
