import { FC, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { ClientCompany } from "../../../../../services/api/projects";
import { adminClientCompanies } from "../../store/slices";
import { useHistory } from "react-router";
import { AdminRoute } from "../../../../../constants/routes";

interface IProps {
  cell: any;
}

const ActionCell: FC<IProps> = ({ cell }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const setCompanyForStampUpdate = useCallback(
    (company: ClientCompany | null) =>
      dispatch(adminClientCompanies.actions.setCompanyForStampUpdate(company)),
    [dispatch]
  );

  const setCompanyForDirectorUpdate = useCallback(
    (company: ClientCompany | null) =>
      dispatch(
        adminClientCompanies.actions.setCompanyForDirectorUpdate(company)
      ),
    [dispatch]
  );

  const company: ClientCompany = cell.row.original;

  const redirectToPolicies = () => {
    history.push(
      AdminRoute.ProjectPolicies.List + "?client_company_id=" + company.id
    );
  };

  return (
    <Dropdown id="dropdown">
      <Dropdown.Toggle size="sm" variant="outline-primary" id="dropdown-toggle">
        <FaCaretDown />
      </Dropdown.Toggle>

      <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
        <Dropdown.Item onClick={() => setCompanyForDirectorUpdate(company)}>
          Edit director
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setCompanyForStampUpdate(company)}>
          Upload stamp
        </Dropdown.Item>
        <Dropdown.Item onClick={redirectToPolicies}>
          Show policies
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { ActionCell };
