import { FC } from "react";
import { Redirect } from "react-router";
import {
  AdminRoute,
  CarrierRoute,
  GeneralRoute,
  SuperAdminRoute,
} from "../../constants/routes";
import { authAPI } from "../../services/api/auth";
import { GlobalLoader } from "../common/loaders/GlobalLoader";
import { IProfileState } from "../profile/store/slices";
import { Role } from "../../services/api/profile";

export const RoleFirstPage = {
  [Role.SuperAdmin]: SuperAdminRoute.EDI.Upload,
  [Role.Admin]: AdminRoute.Users.List,
  [Role.Carrier]: CarrierRoute.Orders.List.replace("/:orderId?", ""),
  [Role.Manager]: CarrierRoute.Orders.List.replace("/:orderId?", ""),
  [Role.Guest]: GeneralRoute.Profile.Show,
};

interface IProps {
  profile: IProfileState;
}

const LoginRedirect: FC<IProps> = ({ profile }) => {
  if (!profile.data && !authAPI.hasTokens()) {
    return <Redirect to="/" />;
  }

  if (profile.data) {
    if (profile.isSuperAdmin) {
      return <Redirect to={RoleFirstPage[Role.SuperAdmin]} />;
    }

    if (profile.isAdmin) {
      return <Redirect to={RoleFirstPage[Role.Admin]} />;
    }

    if (profile.isCarrier) {
      return <Redirect to={RoleFirstPage[Role.Carrier]} />;
    }

    if (profile.isManager) {
      return <Redirect to={RoleFirstPage[Role.Manager]} />;
    }

    if (profile.isGuest) {
      if (profile.data.emailVerifiedAt) {
        return <Redirect to={CarrierRoute.Applications.List} />;
      }

      return <Redirect to={RoleFirstPage[Role.Guest]} />;
    }
  }

  return <GlobalLoader />;
};

export { LoginRedirect };
