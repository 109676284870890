import { OnChangePlugin as OnChangePluginOriginal } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";

type Props = {
  handleUpdate: (html: string) => void;
};

const OnChangePlugin = ({ handleUpdate }: Props) => {
  const [editor] = useLexicalComposerContext();

  return (
    <OnChangePluginOriginal
      onChange={(editorState) => {
        editorState.read(() => {
          const htmlString = $generateHtmlFromNodes(editor);

          handleUpdate(htmlString);
        });
      }}
    />
  );
};

export default OnChangePlugin;
