import { FC } from "react";
import { Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Header } from "../layout/Header";
import { CreateButtons } from "./CreateButtons";
import { ApplicationList } from "./list/ApplicationList";

const Applications: FC = () => {
  const { t } = useTranslation("applicationList");

  return (
    <Container className="applications" fluid>
      <Row>
        <Header title={t("applications") as string} />
      </Row>
      <Row
        style={{
          marginTop: "30px",
        }}
      >
        <CreateButtons />
      </Row>
      <Row>
        <ApplicationList />
      </Row>
    </Container>
  );
};

export { Applications };
