import { call, delay, put, select, takeLatest } from "typed-redux-saga";
import {
  adminProjectsAPI,
  IFetchProjectParams,
} from "../../../../services/api/admin/projects";
import { AppState } from "../../../../store";
import { showError } from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { adminProjects } from "./slices";

function* adminProjectSagas() {
  yield* takeLatest(adminProjects.actions.setSearch, debounceSearch);
  yield* takeLatest(adminProjects.actions.setPerPage, fetchProjects);
  yield* takeLatest(adminProjects.actions.setActivePage, fetchProjects);
}

function* debounceSearch() {
  const data = yield* select((state: AppState) => state.adminProjects.data);

  if (data.length > 0) {
    yield delay(500);
  }

  yield* call(fetchProjects);
}

function* fetchProjects() {
  yield put(loader.actions.startLoader());

  const { search, perPage, page } = yield* select((state: AppState) => ({
    search: state.adminProjects.search,
    perPage: state.adminProjects.perPage,
    page: state.adminProjects.activePage,
  }));

  const requestParams: IFetchProjectParams = {
    search,
    perPage,
    page,
  };

  const response = yield* call(adminProjectsAPI.fetchProjects, requestParams);

  if (response && response.data && response.meta) {
    yield put(
      adminProjects.actions.setData({
        data: response.data,
        meta: response.meta,
      })
    );
  } else {
    yield put(showError("Something went wrong while getting projects."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminProjectSagas };
