import React from "react";
import { CSSProperties, useMemo } from "react";
import { FC } from "react";

interface IProps {
  activeStep: string;
  style?: CSSProperties;
  onClick?: (name: string) => void;
  children: React.ReactNode;
}

const Stepper: FC<IProps> = ({ activeStep, style, children, onClick }) => {
  const childrenWithProps: any = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement<any>(child, {
        onClick,
        activeStep,
        isActive: child.props.name === activeStep,
      });
    }

    return child;
  });

  const clonedChildren = useMemo(
    () =>
      [...childrenWithProps]
        .map((child, i) =>
          i < childrenWithProps.length - 1
            ? [child, <div key={i} className="divider" />]
            : [child]
        )
        .reduce((a, b) => a.concat(b)),
    [childrenWithProps]
  );

  return (
    <div className="stepper" style={style}>
      {clonedChildren}
    </div>
  );
};

export { Stepper };
