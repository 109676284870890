import { translated } from "../../../../i18n";
import { documentsAPI } from "../../../../services/api/documents";
import store from "../../../../store";
import { showError } from "../../../alert/store/slices";

const downloadDocument = async (id: number, fileName: string) => {
  try {
    const file = await documentsAPI.downloadDocument(id);

    if (file) {
      const link = window.document.createElement("a");

      link.href = window.URL.createObjectURL(file as any);
      link.download = fileName;

      link.dispatchEvent(new MouseEvent("click"));
    }
  } catch (error) {
    store.dispatch(showError(translated.fileDownloadFailed));
  }
};

export { downloadDocument };
