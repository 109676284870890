import { loader } from "../components/common/loaders/store/slices";
import { alert } from "../components/alert/store/slices";
import { profile } from "../components/profile/store/slices";
import { applications } from "../components/application/store/slices";
import { settings } from "../components/settings/store/slices";
import { projectList } from "../components/projects/list/store/slices";
import { projectShow } from "../components/projects/show/store/slices";
import { userList } from "../components/users/list/store/slices";
import { userShow } from "../components/users/show/store/slices";
import { userEdit } from "../components/users/edit/store/slices";
import { userInvite } from "../components/users/invite/store/slices";
import { authentication } from "../components/authentication/login/store/slices";
import { registration } from "../components/authentication/register/store/slices";
import { forgotPassword } from "../components/authentication/forgot-password/store/slices";
import { translations } from "../components/translations/store/slice";
import { documents } from "../components/documents/store/slices";
import { notificationSettings } from "../components/notification-settings/store/slices";
import { adminApplications } from "../components/admin/applications/store/slices";
import { adminActivityLog } from "../components/admin/activity-log/store/slices";
import { adminDocuments } from "../components/admin/documents/store/slices";
import { adminProjects } from "../components/admin/projects/store/slices";
import { adminUsers } from "../components/admin/users/store/slices";
import { policies } from "../components/policies/store/slices";
import { adminCompanies } from "../components/admin/companies/store/slices";
import { carrierWizard } from "../components/wizards/carrier/store/slices";
import { maintenance } from "../components/maintenance/store/slices";
import { adminMaintenance } from "../components/admin/maintenance/store/slices";
import { adminFiles } from "../components/admin/files/store/slices";
import { confirmedProjects } from "../components/projects/list/store/slices/confirmed";
import { waitingProjects } from "../components/projects/list/store/slices/waiting";
import { rejectedProjects } from "../components/projects/list/store/slices/rejected";
import { refreshModal } from "../components/refresh-modal/store/slices";
import { completedProjects } from "../components/projects/list/store/slices/completed";
import { quickPayModal } from "../components/projects/quick-pay-modal/store/slice";
import { projectChangeLog } from "../components/projects/change-log/store/slice";
import updateBarSlice from "../components/update-bar/store/slice";
import { adminClientCompanies } from "../components/admin/client-companies/store/slices";
import { adminGeneralPolicies } from "../components/admin/general-policies/store/slices";
import { adminProjectPolicies } from "../components/admin/project-policies/store/slices";
import workspaceSwitcherSlice from "../components/workspace-switcher/store/slice";
import { notifications } from "../components/notifications/store/slices";
import { adminStatisticPolicies } from "../components/admin/statistic-policies/store/slices";
import questionnaireModalSlice from "../components/questionnaire-modal/store/slices";

const rootReducer = {
  loader: loader.reducer,
  alert: alert.reducer,
  translations: translations.reducer,
  maintenance: maintenance.reducer,
  refreshModal: refreshModal.reducer,
  updateBar: updateBarSlice.reducer,
  workspace: workspaceSwitcherSlice.reducer,

  authentication: authentication.reducer,
  registration: registration.reducer,
  forgotPassword: forgotPassword.reducer,

  profile: profile.reducer,
  settings: settings.reducer,
  notifications: notifications.reducer,
  notificationSettings: notificationSettings.reducer,
  policies: policies.reducer,
  carrierWizard: carrierWizard.reducer,
  questionnaireModal: questionnaireModalSlice.reducer,

  applications: applications.reducer,

  projectShow: projectShow.reducer,
  projectList: projectList.reducer,
  confirmedProjects: confirmedProjects.reducer,
  waitingProjects: waitingProjects.reducer,
  rejectedProjects: rejectedProjects.reducer,
  completedProjects: completedProjects.reducer,
  quickPayModal: quickPayModal.reducer,
  projectChangeLog: projectChangeLog.reducer,

  userList: userList.reducer,
  userShow: userShow.reducer,
  userEdit: userEdit.reducer,
  userInvite: userInvite.reducer,

  documents: documents.reducer,

  // Admin
  adminApplications: adminApplications.reducer,
  adminGeneralPolicies: adminGeneralPolicies.reducer,
  adminProjectPolicies: adminProjectPolicies.reducer,
  adminStatisticPolicies: adminStatisticPolicies.reducer,
  adminActivityLog: adminActivityLog.reducer,
  adminDocuments: adminDocuments.reducer,
  adminProjects: adminProjects.reducer,
  adminUsers: adminUsers.reducer,
  adminCompanies: adminCompanies.reducer,
  adminClientCompanies: adminClientCompanies.reducer,
  adminMaintenance: adminMaintenance.reducer,
  adminFiles: adminFiles.reducer,
};

export { rootReducer };
