import { FC, useCallback } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { projectShow } from "../../store/slices";
import { SelectionMarkerPanel } from "../panels/SelectionMarkerPanel";

const hoverSize = 40;

export interface ISelectionMarkerProps extends google.maps.LatLngLiteral {
  lat: number;
  lng: number;
  mainColor?: string;
  disabled?: boolean;
  onHover: () => void;
  onLeave: () => void;
}

const SelectionMarker: FC<ISelectionMarkerProps> = (props) => {
  const { mainColor, onLeave } = props;

  const dispatch = useDispatch();

  const closeSelection = useCallback(
    () => dispatch(projectShow.actions.setSelectedPosition(null)),
    [dispatch]
  );

  return (
    <div
      className="marker"
      style={{
        position: "absolute",
        color: mainColor ? mainColor : "#000000",
        fontSize: `${hoverSize}px`,
      }}
    >
      <SelectionMarkerPanel
        close={() => {
          onLeave();
          closeSelection();
        }}
        {...props}
      />
      <FaMapMarkerAlt />
    </div>
  );
};

export { SelectionMarker };
