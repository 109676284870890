import { call, fork, put, take } from "typed-redux-saga";
import { notifications } from "./slices";
import {
  UnreadNotificationsCount,
  Notification,
  NotificationType,
} from "../types";
import { ApiResource, SuccessResponse } from "../../../services/api";
import { notificationsAPI } from "../../../services/api/notifications";
import { showError } from "../../alert/store/slices";
import { translated } from "../../../i18n";

function* notificationsSaga() {
  yield* fork(fetchWatcher);
  yield* fork(fetchRecentWatcher);
  yield* fork(fetchUnreadCountWatcher);
  yield* fork(markAsReadWatcher);
}

function* fetchWatcher() {
  while (true) {
    const { payload } = yield take(notifications.actions.fetchNotifications);

    yield* call(fetchNotifications, payload.type, payload.page);
  }
}

function* fetchRecentWatcher() {
  while (true) {
    yield take(notifications.actions.fetchRecentNotifications);

    yield* call(fetchRecentNotifications);
  }
}

function* fetchUnreadCountWatcher() {
  while (true) {
    yield take(notifications.actions.fetchUnreadCount);

    yield* call(fetchUnreadCount);
  }
}

function* markAsReadWatcher() {
  while (true) {
    const { payload: ids } = yield take(notifications.actions.markAsRead);

    yield* call(markAsRead, ids);

    if (ids.length === 0) {
      yield put(notifications.actions.fetchRecentNotifications());
      yield put(notifications.actions.fetchUnreadCount());
    }
  }
}

function* fetchNotifications(type: NotificationType | null, page: number) {
  yield put(notifications.actions.setIsLoading(true));

  const result: ApiResource<Notification[]> = yield* call(
    notificationsAPI.fetchNotifications,
    type,
    page
  );

  if (result && result.data && result.meta) {
    yield put(notifications.actions.setNotifications(result));

    if (result.data.length) {
      const ids = result.data.map(({ id }) => id);

      if (!ids.length) return;

      yield* call(markAsRead, ids);
    }
  } else {
    yield put(showError(translated.errorWhileGettingNotifications));
  }

  yield put(notifications.actions.setIsLoading(false));
}

function* fetchRecentNotifications() {
  yield put(notifications.actions.setIsRecentLoading(true));

  const result: ApiResource<Notification[]> = yield* call(
    notificationsAPI.fetchRecentNotifications
  );

  if (result && result.data) {
    yield put(notifications.actions.setRecentNotifications(result));

    if (result.data.length) {
      const ids = result.data.map(({ id }) => id);

      if (!ids.length) return;

      yield* call(markAsRead, ids);
    }
  } else {
    yield put(showError(translated.errorWhileGettingNotifications));
  }

  yield put(notifications.actions.setIsRecentLoading(false));
}

function* fetchUnreadCount() {
  yield put(notifications.actions.setIsUnreadCountLoading(true));

  const result: ApiResource<UnreadNotificationsCount> = yield* call(
    notificationsAPI.fetchUnreadNotificationsCount
  );

  if (result && result.data !== null) {
    yield put(notifications.actions.setUnreadCount(result.data));
  }

  yield put(notifications.actions.setIsUnreadCountLoading(false));
}

function* markAsRead(ids: number[]) {
  const result: SuccessResponse = yield* call(notificationsAPI.markAsRead, ids);

  if (!result || !result?.success) {
    yield put(showError(translated.errorWhileUpdatingNotifications));
  }
}

export { notificationsSaga };
