import { useTranslation } from "react-i18next";
import styles from "./passwordRules.module.scss";

const PasswordRules = () => {
  const { t } = useTranslation("passwordRules");

  return (
    <div className={styles.div}>
      <ul>
        <li>{t("minCharsRequired")}</li>
        <li>{t("oneUpperCaseRequired")}</li>
        <li>{t("oneLowerCaseRequired")}</li>
        <li>{t("oneNumberRequired")}</li>
        <li>{t("dictionaryWord")}</li>
        <li>{t("repetetiveChars")}</li>
        <li>{t("sequentialChars")}</li>
        <li>{t("mustMatch")}</li>
      </ul>
    </div>
  );
};

export default PasswordRules;
