import moment from "moment";
import { Column, TableOptions } from "react-table";
import { DateFilter } from "./filters/DateFilter";
import { Link } from "react-router-dom";
import { IActivityLogResponse } from "../../../../services/api/admin/activity-log";
import { AdminRoute } from "../../../../constants/routes";
import { BsFillCaretUpFill } from "react-icons/bs";
import { Button } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";

const activityLogColumns: Column<TableOptions<IActivityLogResponse>>[] = [
  {
    Header: () => (
      <>
        <BsFillCaretUpFill /> <span>Date</span>
        <DateFilter />
      </>
    ),
    accessor: "created_at",
    Cell: ({ value }) => (
      <span>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</span>
    ),
    disableSortBy: true,
  },
  {
    Header: "Description",
    accessor: "description",
    disableSortBy: true,
  },
  {
    Header: "Subject",
    Cell: ({ cell }) => {
      const { subject_type, subject_id } = cell.row.original;

      if (!subject_type || !subject_id) return <span>-</span>;

      let route = null;

      const subjectName = subject_type.replace("App\\", "");

      if (subjectName === "Project") {
        route = `${AdminRoute.Orders.List}?search=${subject_id}`;
      }

      if (subjectName === "Application") {
        route = `${AdminRoute.Applications.List}?search=${subject_id}`;
      }

      if (subjectName === "Media") {
        route = `${AdminRoute.Documents.List}?search=${subject_id}`;
      }

      if (subjectName === "User") {
        route = `${AdminRoute.Users.List}?search=${subject_id}`;
      }

      if (subjectName === "Company") {
        route = `${AdminRoute.Companies.List}?search=${subject_id}`;
      }

      return (
        <>
          <span>
            {route ? <Link to={route}>{subjectName}</Link> : subjectName}
          </span>
          <span className="list-time">ID: {subject_id}</span>
        </>
      );
    },
  },
  {
    Header: "Causer",
    Cell: ({ cell }) => {
      const { causer_type, causer_id } = cell.row.original;

      if (!causer_type || !causer_id) return <span>-</span>;

      let route = null;

      const causerName = causer_type.replace("App\\", "");

      if (causerName === "User") {
        route = `${AdminRoute.Users.List}?search=${causer_id}`;
      }

      return (
        <>
          <span>
            {route ? <Link to={route}>{causerName}</Link> : causerName}
          </span>
          <span className="list-time">ID: {causer_id}</span>
        </>
      );
    },
  },
  {
    Header: "Additional info",
    accessor: "properties",
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <Button variant="primary">
            <FaMinus />
          </Button>
        ) : (
          <Button variant="secondary">
            <FaPlus />
          </Button>
        )}
      </span>
    ),
    disableSortBy: true,
  },
];

export { activityLogColumns };
