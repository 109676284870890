import classNames from "classnames";
import { useState } from "react";
import { FC } from "react";

interface IProps {
  name: string;
  label: string;
  icon: JSX.Element;
  isActive?: boolean;
  onClick?: (name: string) => void;
}

const Step: FC<IProps> = ({ label, name, icon, isActive, onClick }) => {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <div
      className="step"
      onClick={() => (onClick ? onClick(name) : undefined)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={classNames(
          "circle",
          hovered && "hovered",
          isActive && "active"
        )}
      >
        <div>{icon}</div>
      </div>
      <div className="label">{label}</div>
    </div>
  );
};

export { Step };
