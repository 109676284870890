import { createSlice } from "@reduxjs/toolkit";
import { IDocument } from "../../../../services/api/documents";
import { IProject } from "../../../../services/api/projects";
import { IUser } from "../../../../services/api/users";
import { PanelTab } from "../details/DetailPanel";
import { IProjectUpdateForm } from "../details/tabs/ResponsibilitiesTab";
import { determineCurrentPosition } from "../map/utils/maps-helper";
import { filterInvalidPositions, mapPositions } from "./mappers";

export interface IProjectShowState {
  data: IProject | null;
  isLoading: boolean;
  isUpdating: boolean;
  isManagersLoading: boolean;
  isDocumentsLoading: boolean;
  managers: IUser[];
  documents: IDocument[];
  documentCount: number;

  // Panel
  tab: PanelTab;

  // Coordinates
  defaultPosition: google.maps.LatLng | null;
  currentPosition: google.maps.LatLng | null;
  selectedPositon: google.maps.LatLng | null;
  plannedPositions: google.maps.LatLng[];
  confirmedPositions: google.maps.LatLng[];

  // Visibility
  isPlannedVisible: boolean;
  isConfirmedVisible: boolean;
  isCurrentVisible: boolean;
  isConfirmationVisible: boolean;

  selectedLocationId: number | null;
}

const initialState: IProjectShowState = {
  data: null,
  isLoading: false,
  isUpdating: false,
  isManagersLoading: false,
  isDocumentsLoading: false,
  managers: [],
  documents: [],
  documentCount: 0,

  tab: PanelTab.OtherDetails,

  defaultPosition:
    typeof google !== "undefined"
      ? new google.maps.LatLng({
          lat: 52.711534194801835,
          lng: 15.000216904867461,
        })
      : null,
  currentPosition: null,
  selectedPositon: null,
  plannedPositions: [],
  confirmedPositions: [],

  isPlannedVisible: false,
  isConfirmedVisible: false,
  isCurrentVisible: false,
  isConfirmationVisible: false,

  selectedLocationId: null,
};

const projectShow = createSlice({
  name: "projectShow",
  initialState,
  reducers: {
    fetchProject: (state, action) => {
      state.isLoading = true;
    },
    fetchDocuments: (state) => {
      state.isDocumentsLoading = true;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
      state.isDocumentsLoading = false;

      if (action.payload) {
        state.documentCount = action.payload.length;
      }
    },
    updateProject: (state, action) => {
      state.isUpdating = true;
    },
    setUpdated: (state, action) => {
      const {
        truckPlates,
        trailerPlates,
        driverFullName,
        driverPhone,
        carrierManagerId,
      }: IProjectUpdateForm = action.payload;

      state.isUpdating = false;

      if (state.data) {
        state.data = {
          ...state.data,
          truckPlates,
          trailerPlates,
          driverName: driverFullName,
          driverPhone,
          carrierManager:
            state.managers.find((manager) => manager.id === carrierManagerId) ||
            null,
        };

        state.documentCount = state.documentCount + 1;
      }
    },
    acceptProject: (state, action) => {
      state.isLoading = true;
    },
    rejectProject: (state, action) => {
      state.isLoading = true;
    },
    fetchManagers: (state) => {
      state.isManagersLoading = true;
    },
    setManagers: (state, action) => {
      state.managers = action.payload;
      state.isManagersLoading = false;
    },
    setData: (state, action) => {
      const project = action.payload.data as IProject;
      const documentCount: number = action.payload.meta.documentCount;
      const plannedPositions = mapPositions(project.plannedLocations);
      const confirmedPositions = mapPositions(project.confirmedLocations);
      const currentPosition = determineCurrentPosition(
        plannedPositions,
        confirmedPositions
      );

      state.data = {
        ...project,
        plannedLocations: filterInvalidPositions(project.plannedLocations),
        confirmedLocations: filterInvalidPositions(project.confirmedLocations),
      };
      state.documentCount = documentCount;
      state.isLoading = false;

      state.plannedPositions = plannedPositions;
      state.confirmedPositions = confirmedPositions;
      state.currentPosition = currentPosition;

      state.isPlannedVisible = true;
      state.isConfirmedVisible = true;
      state.isCurrentVisible = true;
    },
    confirmStatus: (state, action) => {
      state.isConfirmationVisible = false;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    uploadDocument: (state, action) => {
      //
    },
    setSelectedPosition: (state, action) => {
      state.selectedPositon = action.payload;
    },
    setSelectedLocationId: (state, action) => {
      state.selectedLocationId = action.payload;

      state.selectedPositon = null;
    },
    setPlannedVisible: (state, action) => {
      state.isPlannedVisible = action.payload;
    },
    setConfirmedVisible: (state, action) => {
      state.isConfirmedVisible = action.payload;
    },
    setCurrentVisible: (state, action) => {
      state.isCurrentVisible = action.payload;
    },
    setConfirmationVisible: (state, action) => {
      state.isConfirmationVisible = action.payload;
    },
    resetDocuments: (state) => {
      state.documents = [];
      state.isDocumentsLoading = false;
    },
    reset: () => initialState,
  },
});

export { projectShow };
