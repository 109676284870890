import { BsFillCaretUpFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Column, TableOptions } from "react-table";
import { AdminRoute } from "../../../../constants/routes";
import { IDocumentShort } from "../../../../services/api/documents";
import { IProjectShortAdmin } from "../../../../services/api/projects";
import { formatCurrency } from "../../../../utils/currency-format";
import {
  formatUTCTime,
  formatUTCDate,
  formatDateTime,
} from "../../../../utils/date-format";

const renderDateTime = (dateFrom: string | null, dateTo: string | null) => {
  const date = formatUTCDate(dateFrom);
  const timeFrom = formatUTCTime(dateFrom);
  const timeTo = formatUTCTime(dateTo);

  const dateString = date ? `${date}, ` : "";
  const timeFromString = timeFrom ? timeFrom : "";
  const timeToString = timeTo ? `-${timeTo}` : "";

  return dateString + timeFromString + timeToString;
};

const projectListColumns: Column<TableOptions<IProjectShortAdmin>>[] = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Project",
    accessor: "axCode",
  },
  {
    Header: "Company AX code",
    Cell: ({ cell }) => {
      const project = (cell.row.original as unknown) as IProjectShortAdmin;
      const companyAxCode = project.company.axCode;

      return project.company ? (
        <Link to={`${AdminRoute.Companies.List}?search=${companyAxCode}`}>
          {companyAxCode}
        </Link>
      ) : (
        <span>-</span>
      );
    },
  },
  {
    Header: "Client company AX code",
    Cell: ({ cell }) => {
      const project = (cell.row.original as unknown) as IProjectShortAdmin;
      const { ax_code, name } = project.clientCompany;

      return project.clientCompany ? (
        <Link to={`${AdminRoute.ClientCompanies.List}?search=${name}`}>
          {ax_code}
        </Link>
      ) : (
        <span>-</span>
      );
    },
  },
  {
    Header: "Loading info",
    accessor: "loadingPlace",
    Cell: ({ cell }) => {
      const project = (cell.row.original as unknown) as IProjectShortAdmin;

      return (
        <div>
          <span>{cell.value}</span>
          <span className="list-time">
            {renderDateTime(project.loadingDateFrom, project.loadingDateTo)}
          </span>
        </div>
      );
    },
  },
  {
    Header: "Unloading info",
    accessor: "unloadingPlace",
    Cell: ({ cell }) => {
      const project = (cell.row.original as unknown) as IProjectShortAdmin;

      return (
        <div>
          <span>{cell.value}</span>
          <span className="list-time">
            {renderDateTime(project.unloadingDateFrom, project.unloadingDateTo)}
          </span>
        </div>
      );
    },
  },
  {
    Header: "Total price",
    accessor: "totalPrice",
    Cell: ({ row: { original } }) => {
      const project = (original as unknown) as IProjectShortAdmin;

      if (!project.totalCharge) {
        return <span>-</span>;
      }

      return (
        <span>
          {formatCurrency(project.totalCharge)} {project.totalChargeCurrency}
        </span>
      );
    },
  },
  {
    Header: "Documents",
    Cell: ({ row }) => {
      const project = (row.original as unknown) as IProjectShortAdmin;

      if (!project.documents) {
        return <span>-</span>;
      }

      if (project.documents.length === 0) {
        return <span>-</span>;
      }

      return (
        <ul>
          {project.documents.map((document, index) => (
            <li key={index}>
              <Link
                to={AdminRoute.Documents.List + `?search=${document.fileName}`}
              >
                {document.fileName}
              </Link>
            </li>
          ))}
        </ul>
      );
    },
  },
  {
    id: "Updated at",
    Header: () => (
      <span>
        <BsFillCaretUpFill /> Updated at
      </span>
    ),
    Cell: ({ cell }) => {
      const { updatedAt } = (cell.row.original as unknown) as IDocumentShort;

      return <span>{formatDateTime(updatedAt)}</span>;
    },
  },
];

export { projectListColumns };
