import { FC, useCallback } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { projectShow } from "../../store/slices";
import { ISelectionMarkerProps } from "../markers/SelectionMarker";

interface IProps extends ISelectionMarkerProps {
  close: () => void;
}

const SelectionMarkerPanel: FC<IProps> = ({
  lat,
  lng,
  mainColor,
  close,
  disabled,
  onHover,
  onLeave,
}) => {
  const { t } = useTranslation("projectMarkerPanel");

  const dispatch = useDispatch();

  const confirm = useCallback(
    () => dispatch(projectShow.actions.setConfirmationVisible(true)),
    [dispatch]
  );

  return (
    <div
      className="marker-panel"
      style={{
        color: "white",
        backgroundColor: mainColor,
      }}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <div className="close" onClick={close}>
        <FaTimes />
      </div>
      <div className="panel-header">{t("newAddress")}</div>
      <div className="panel-body">
        <div>
          <span>{t("latitude")}</span>
          <span>{lat}</span>
        </div>

        <div>
          <span>{t("longtitude")}</span>
          <span>{lng}</span>
        </div>
      </div>
      <div className="confirmation">
        <Button variant="light" size="sm" onClick={confirm} disabled={disabled}>
          {t("confirm")}
        </Button>
      </div>
    </div>
  );
};

export { SelectionMarkerPanel };
