import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AdminRoute } from "../../../constants/routes";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { Loader } from "../../common/loaders/Loader";
import { Header } from "../../layout/Header";
import { adminUsers } from "./store/slices";
import { showUserEmail } from "./utils/helpers";

interface IManageConditionsForm {
  text: string;
}

const ManageConditions: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const isLoading = useSelector((state) => state.loader.isLoading);
  const user = useSelector((state) => state.adminUsers.userData);
  const updatedUser = useSelector((state) => state.adminUsers.updatedUser);

  const [isChecked, setIsChecked] = useState<boolean>();

  const history = useHistory();

  const { register, handleSubmit, setValue, errors } = useForm<
    IManageConditionsForm
  >();

  const userEmail = useMemo(() => showUserEmail(user), [user]);

  const dispatch = useDispatch();

  const fetchUser = useCallback(
    () => dispatch(adminUsers.actions.fetchUser(userId)),
    [dispatch, userId]
  );

  const toggleConditions = useCallback(
    (enabled: boolean) =>
      dispatch(adminUsers.actions.toggleConditions({ userId, enabled })),
    [dispatch, userId]
  );

  const storeConditions = useCallback(
    (text: string) =>
      dispatch(adminUsers.actions.storeConditions({ userId, text })),
    [dispatch, userId]
  );

  const resetUpdatedUser = useCallback(
    () => dispatch(adminUsers.actions.resetUpdatedUser()),
    [dispatch]
  );

  const resetUser = useCallback(
    () => dispatch(adminUsers.actions.resetUser()),
    [dispatch]
  );

  const onSubmit = (data: IManageConditionsForm) => {
    storeConditions(data.text);
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
    toggleConditions(!isChecked);
  };

  useEffect(() => {
    fetchUser();

    return () => {
      resetUpdatedUser();
      resetUser();
    };
  }, [fetchUser, resetUpdatedUser, resetUser]);

  useEffect(() => {
    if (user && user.workingConditions) {
      const { enabled, text } = user.workingConditions;

      setIsChecked(enabled);
      setValue("text", text);
    }
  }, [user, setValue]);

  useEffect(() => {
    if (updatedUser) {
      history.push(AdminRoute.Users.List.replace(":userId", userId));
    }
  }, [updatedUser, userId, history]);

  return (
    <Container fluid className="manage-conditions">
      <Row>
        <Header
          title="Manage conditions"
          subTitle={userEmail}
          languageDisabled
        />
      </Row>
      <Row>
        {user ? (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} controlId="enabled">
                <Form.Check
                  type="switch"
                  id="enabled"
                  name="enabled"
                  label="Enable working conditions"
                  checked={isChecked}
                  onChange={handleToggle}
                />
                <Form.Text>User will be prompted when sending order.</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="text">
                <Form.Label>Conditions</Form.Label>
                <Form.Control
                  name="text"
                  as="textarea"
                  rows={10}
                  ref={register({ required: true })}
                  disabled={!isChecked}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.text?.type === "required" && "Required field."}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="update">
                <ButtonLoader
                  type="submit"
                  disabled={isLoading}
                  buttonDisabled={!isChecked}
                >
                  Update
                </ButtonLoader>
              </Form.Group>
            </Form.Row>
          </Form>
        ) : (
          <Loader />
        )}
      </Row>
    </Container>
  );
};

export { ManageConditions };
