import { call, put, select, takeLatest } from "typed-redux-saga";
import { adminStatisticPolicies } from "./slices";
import { loader } from "../../../common/loaders/store/slices";
import { showError, showSuccess } from "../../../alert/store/slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../../store";
import {
  StatisticPolicy,
  adminStatisticPoliciesAPI,
} from "../../../../services/api/admin/statistic-policies";
import { StatisticGroup } from "../../../../services/api/projects";

function* adminStatisticPolicySaga() {
  yield* takeLatest(
    adminStatisticPolicies.actions.fetchPolicies,
    fetchPolicies
  );
  yield* takeLatest(adminStatisticPolicies.actions.fetchPolicy, fetchPolicy);
  yield* takeLatest(adminStatisticPolicies.actions.storePolicy, storePolicy);
  yield* takeLatest(adminStatisticPolicies.actions.updatePolicy, updatePolicy);
  yield* takeLatest(adminStatisticPolicies.actions.deletePolicy, deletePolicy);
  yield* takeLatest(
    adminStatisticPolicies.actions.activatePolicy,
    activatePolicy
  );
}

function* fetchPolicies() {
  yield put(adminStatisticPolicies.actions.setPoliciesLoading(true));

  const result = yield* call(adminStatisticPoliciesAPI.fetchPolicies);

  if (result && result.data) {
    yield put(adminStatisticPolicies.actions.setPolicies(result.data));
  } else {
    yield put(showError("Something went wrong while getting policy data."));
  }

  yield put(adminStatisticPolicies.actions.setPoliciesLoading(false));
}

function* fetchPolicy({ payload: id }: PayloadAction<string>) {
  yield put(loader.actions.startLoader());

  const result = yield* call(adminStatisticPoliciesAPI.fetchPolicy, id);

  if (result) {
    yield put(adminStatisticPolicies.actions.setPolicy(result));
  } else {
    yield put(showError("Something went wrong while getting policy data."));
  }

  yield put(loader.actions.stopLoader());
}

function* storePolicy({
  payload: { groupCode },
}: PayloadAction<{
  groupCode: StatisticGroup;
}>) {
  yield put(loader.actions.startLoader());

  const data = yield* select(
    (state: AppState) => state.adminStatisticPolicies.newPolicyData
  );

  const result = yield* call(
    adminStatisticPoliciesAPI.storePolicy,
    groupCode,
    data
  );

  if (result && result.data) {
    yield put(adminStatisticPolicies.actions.setNewPolicyPublished(true));
    yield put(showSuccess("New policies created. Activate them."));
  } else {
    yield put(showError("Something went wrong while creating policy."));
  }

  yield put(loader.actions.stopLoader());
}

function* updatePolicy({
  payload: { id, groupCode },
}: PayloadAction<{
  id: number;
  groupCode: StatisticGroup;
}>) {
  yield put(loader.actions.startLoader());

  const data = yield* select(
    (state: AppState) => state.adminStatisticPolicies.updatedPolicyData
  );

  const result = yield* call(
    adminStatisticPoliciesAPI.updatePolicy,
    id,
    groupCode,
    data
  );

  if (result && result.data) {
    yield put(adminStatisticPolicies.actions.setUpdatedPolicyPublished(true));
    yield put(showSuccess("Policy updated."));
  } else {
    yield put(showError("Something went wrong while updating policy."));
  }

  yield put(loader.actions.stopLoader());
}

function* deletePolicy({ payload: policy }: PayloadAction<StatisticPolicy>) {
  yield put(loader.actions.startLoader());

  const result = yield* call(adminStatisticPoliciesAPI.deletePolicy, policy.id);

  if (result && result.success) {
    yield put(showSuccess("Policy deleted."));
  } else {
    yield put(showError("Something went wrong while deleting policy."));
  }

  yield put(adminStatisticPolicies.actions.fetchPolicies());

  yield put(loader.actions.stopLoader());
}

function* activatePolicy({ payload: policy }: PayloadAction<StatisticPolicy>) {
  yield put(loader.actions.startLoader());

  const result = yield* call(
    adminStatisticPoliciesAPI.activatePolicy,
    policy.id
  );

  if (result && result.data) {
    yield put(showSuccess("Policy activated."));
  } else {
    yield put(showError("Something went wrong while activating policy."));
  }

  yield put(adminStatisticPolicies.actions.fetchPolicies());

  yield put(loader.actions.stopLoader());
}

export { adminStatisticPolicySaga };
