import { FC, useCallback, useEffect, useMemo } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { DateTimeInput } from "../../common/datetime-input/DateTimeInput";
import { Header } from "../../layout/Header";
import { adminMaintenance } from "./store/slices";

export interface IMaintenanceForm {
  date: Date;
  durationHours: number;
}

const Maintenance: FC = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.loader.isLoading);
  const data = useSelector((state) => state.maintenance.data);

  const maintenanceEnabled = useMemo(() => data.length > 0, [data]);

  const start = useCallback(
    (data: IMaintenanceForm) => dispatch(adminMaintenance.actions.start(data)),
    [dispatch]
  );

  const stop = useCallback(() => dispatch(adminMaintenance.actions.stop()), [
    dispatch,
  ]);

  const { register, errors, handleSubmit, control, watch, setValue } = useForm<
    IMaintenanceForm
  >();

  const onSubmit = (data: IMaintenanceForm) => {
    if (maintenanceEnabled) {
      stop();

      setValue([{ date: undefined }, { durationHours: undefined }]);
    } else {
      start(data);
    }
  };

  useEffect(() => {
    if (data && data[0]) {
      setValue([
        { date: new Date(data[0].date) },
        { durationHours: data[0].durationHours },
      ]);
    }
  }, [data, setValue]);

  return (
    <Container fluid>
      <Row>
        <Header title="Maintenance mode" languageDisabled />
      </Row>
      <Row className="maintenance-form">
        <p>Schedule portal maintenance. Alert in all pages will popup.</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} controlId="date">
              <Form.Label>Date</Form.Label>
              <DateTimeInput
                name={`date`}
                isInvalid={!!errors.date}
                selected={watch("date")}
                showTimeSelect
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                control={control}
                className="date-input"
                disabled={maintenanceEnabled}
              />
              <Form.Control.Feedback type="invalid">
                {errors.date?.type === "required" && "Required field."}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="durationHours">
              <Form.Label>Duration hours</Form.Label>
              <Form.Control
                name="durationHours"
                type="number"
                min={1}
                ref={register}
                isInvalid={!!errors.durationHours}
                maxLength={190}
                disabled={maintenanceEnabled}
              />
              <Form.Control.Feedback type="invalid">
                {errors.durationHours?.type === "required" && "Required field."}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="maintenance-buttons">
              <ButtonLoader
                disabled={isLoading}
                buttonDisabled={
                  !maintenanceEnabled &&
                  (!watch("date") || !watch("durationHours"))
                }
                type="submit"
              >
                {maintenanceEnabled ? "Stop" : "Start"}
              </ButtonLoader>
            </Form.Group>
          </Form.Row>
        </Form>
      </Row>
    </Container>
  );
};

export { Maintenance };
