import { ApiResource, API, HTTPMethod, SuccessResponse } from ".";
import queryString from "query-string";
import { IDocument, IDocumentShort } from "./documents";
import { IConfirmationForm } from "../../components/projects/show/map/modals/ConfirmPositionModal";
import { ICompany, ICompanyShort } from "./profile";
import { INewDocumentForm } from "../../components/projects/show/details/modals/NewDocumentModal";
import moment from "moment";
import { adjustTimezone } from "../../utils/timezone";
import { IUser } from "./users";
import { IProjectUpdateForm } from "../../components/projects/show/details/tabs/ResponsibilitiesTab";
import { InvoiceFormData } from "../../components/projects/quick-pay-modal/QuickPayModal";
import { ProjectPolicy } from "./admin/project-policies";
import { StatisticPolicy } from "./admin/statistic-policies";

export enum StatisticGroup {
  IKEA = "IKEA",
  Ferrero = "Ferrero",
  Unilever = "UNILEVER",
  Amazon = "AMAZON",
}
export interface IProjectStatisticGroup {
  id: number;
  code: StatisticGroup;
}

export interface IProjectStatistic {
  id: number;
  code: string;
  group: IProjectStatisticGroup;
}

export interface IFetchListRequest {
  search?: string;
  perPage?: number;
  page?: number;
  companyId?: number;
  confirmStatus?: ProjectConfirmStatus;
  locationStatus?: ProjectLocationTypeCode;
  startDate?: string;
  endDate?: string;
  onlyArchived?: number;
  onlyAssigned?: number;
  onlyIncomplete?: number;
  clientCompanyId?: number;
}

export enum ProjectConfirmStatus {
  WaitingForApproval = "waitingForApproval",
  Confirmed = "confirmed",
  Rejected = "rejected",
  ConfirmUpdate = "confirmUpdate",
  Completed = "completed",
}

export interface IProjectConfirmStatus {
  id: number;
  name: string;
  code: ProjectConfirmStatus;
}

export interface IManager {
  id: number;
  name: string | null;
  surname: string | null;
  email: string | null;
  phone: string | null;
  position: string | null;
}

export enum ProjectLocationTypeCode {
  LoadingPlace = "ATV_I_PAKR",
  UnloadingPlace = "ATV_I_ISKR",
  Loaded = "PAKRAUTA",
  CargoDelivered = "ISKRAUTA",
  Customs = "MUITINE",
  Border = "SIENA",
  OnTheRoad = "VAZIUOJA",
}

export interface IProjectLocationType {
  id: number;
  name: string;
  axCode: ProjectLocationTypeCode;
}

export interface IShipmentFixation {
  type: string;
  number: number;
}

export interface IProjectLocation {
  addressLine: string | null;
  addressName: string | null;
  city: string | null;
  comment: string | null;
  confirmedAt: string | null;
  confirmedBy: string | null;
  country: string | null;
  dateFrom: string | null;
  dateTo: string | null;
  id: number;
  latitude: string;
  longtitude: string;
  region: string | null;
  type: IProjectLocationType;
  isPlanned: boolean;
  locationName: string;
  refNumber: string | null;
}

export interface IVatGroup {
  id: number;
  code: string;
  value: string;
}

export interface IProjectShortAdmin {
  id: number;
  axCode: string | null;
  loadingPlace: string | null;
  loadingDateFrom: string | null;
  loadingDateTo: string | null;
  unloadingPlace: string | null;
  unloadingDateFrom: string | null;
  unloadingDateTo: string | null;
  totalCharge: string | null;
  totalChargeCurrency: string | null;
  updatedAt: string;
  documents: IDocumentShort[];
  company: ICompanyShort;
  clientCompany: ClientCompany;
}

export interface IProjectShort extends IProjectBase {
  latestConfirmedStatus: IProjectLocation | null;
  archivedBy: IUser | null;
  acceptedBy: IUser | null;
  rejectedBy: IUser | null;
  rejectedByClientCompany: ClientCompany | null;
  allowArchive: boolean;
  allowUnarchive: boolean;
  isQuickPayVisible: boolean;
  isQuickPayEnabled: boolean;
  isQuickPaySubmitted: boolean;
  showNewTotal: boolean;
  newTotal: string | null;
  company: ICompany;
  vatGroup: IVatGroup | null;
}
export interface IProject extends IProjectBase {
  confirmedLocations: IProjectLocation[];
  plannedLocations: IProjectLocation[];
  manager: IManager | null;
  company: ICompany;
  hasNotApprovedDocuments: boolean;
  hasMissingCrm: boolean;
  hasMissingInvoice: boolean;
  deliveryStatus: string | null;
  shipmentFixation: IShipmentFixation[] | null;
  projectFeatures: string | null;
  specialPaymentTerm: string | null;
  standardPaymentTerm: string | null;
  crmPaymentNote: string | null;
  driverPhone: string | null;
  carrierManager: IUser | null;
  statistic?: IProjectStatistic;
  regionTag: string;
  nonConfirmed: boolean;
  clientOrderNumber: string | null;
  changeLogCount: number;
  firstPolicy?: ProjectPolicy;
  secondPolicy?: ProjectPolicy;
  statisticPolicy?: StatisticPolicy;
}

export type Director = {
  id: number;
  first_name: string;
  last_name: string;
};

export enum ClientCompanyAxCode {
  Everwest = "LT_GIRTLOG",
}

export enum ClientCompanyName {
  Everwest = "UAB Everwest",
}

export type ClientCompany = {
  id: number;
  ax_code: ClientCompanyAxCode;
  name: ClientCompanyName;
  code: string;
  vat_code: string;
  address: string;
  city: string;
  post_code: string;
  country: string;
  created_at: string;
  directors?: Director[];
  documents?: IDocument[];
};

interface IProjectBase {
  id: number;
  axCode: string | null;
  refNumber: string | null;
  loadingPlace: string | null;
  loadingDateFrom: string | null;
  loadingDateTo: string | null;
  unloadingPlace: string | null;
  unloadingDateFrom: string | null;
  unloadingDateTo: string | null;
  truckPlates: string | null;
  trailerPlates: string | null;
  trailerType: string | null;
  goodsType: string | null;
  weight: string | null;
  pallets: string | null;
  temperature: string | null;
  temperatureType: string | null;
  freightCharge: string | null;
  freightChargeCurrency: string | null;
  extraCharge: string | null;
  extraChargeCurrency: string | null;
  totalCharge: string | null;
  totalChargeCurrency: string | null;
  updatedAt: string;
  issueDate: string;
  palletsWillChange: boolean | null;
  carriageValue: string | null;
  driverName: string | null;
  confirmStatus: IProjectConfirmStatus | null;
  archivedAt: string | null;
  acceptedAt: string | null;
  rejectedAt: string | null;
  documents?: IDocument[];
  paymentUntil?: string | null;
  acceptedCreditInvoiceExists: boolean;
  increasedSecurity: boolean;
  clientCompany: ClientCompany;
}

export type ProjectLocationLog = {
  status: string | null;
  address_name: string | null;
  city: string | null;
  postal_code: string | null;
  region: string | null;
  address_line: string | null;
  country: string | null;
  period_start: string | null;
  period_end: string | null;
  coordinates_lat: number | null;
  coordinates_lng: number | null;
  location_name: string | null;
  ref_number: string | null;
};

export type ProjectLog = {
  temperature: string | null;
  temperature_type: string | null;
  goods_type: string | null;
  trailer_type: string | null;
  ref_number: null;
  total_allowance_charge_amount: number | null;
  total_allowance_charge_currency: string | null;
  freight_allowance_charge_amount: number | null;
  freight_allowance_charge_currency: string | null;
  extra_allowance_charge_amount: number | null;
  extra_allowance_charge_currency: string | null;
};

export type ProjectChangeLog = {
  id: number;
  project_id: number;
  data: ProjectLog;
  location_data: ProjectLocationLog[];
  created_at: string;
  before: ProjectChangeLog;
};

const fetchList = async (
  requestParameters?: IFetchListRequest
): Promise<ApiResource<IProjectShort[]>> => {
  let params = "";

  if (requestParameters) {
    params = queryString.stringify(requestParameters);
  }

  const response = await API.request<ApiResource<IProjectShort[]>>(
    `/carrier/orders?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchDocuments = async (
  projectId: number
): Promise<ApiResource<IDocument[]>> => {
  const response = await API.request(`/carrier/orders/${projectId}/document`, {
    method: HTTPMethod.GET,
    version: "v2",
    jsonRequest: true,
  });

  return response;
};

const fetchProject = async (
  id: string
): Promise<ApiResource<IProject, { documentCount: number }>> => {
  const response = await API.request<ApiResource<IProject>>(
    `/carrier/orders/${id}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchChangeLog = async (
  id: number,
  page: number
): Promise<ApiResource<ProjectChangeLog>> => {
  const response = await API.request<ApiResource<ProjectChangeLog>>(
    `/carrier/orders/${id}/change-log?page=${page}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const updateProject = async (
  id: number,
  data: IProjectUpdateForm
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/carrier/orders/${id}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const acceptProject = async (
  id: number,
  data: IProjectUpdateForm
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/carrier/orders/${id}/accept`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const generateQuickPay = async (
  id: number,
  data: InvoiceFormData & { agreed_with_latest_additional_info_policy: boolean }
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/carrier/orders/${id}/quick-pay/generate`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const rejectProject = async (id: number): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/carrier/orders/${id}/reject`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const archiveProject = async (id: number): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/carrier/orders/${id}/archive`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const unarchiveProject = async (id: number): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/carrier/orders/${id}/archive`,
    {
      method: HTTPMethod.DELETE,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const confirmStatus = async (
  projectId: string,
  data: IConfirmationForm<number>,
  plannedLocationId?: number | null
): Promise<ApiResource<IProjectLocation>> => {
  const response = await API.request<ApiResource<IProjectLocation>>(
    `/carrier/orders/${projectId}/status`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({
      ...data,
      statusTypeId: data.status,
      plannedLocationId,
    })
  );

  return response;
};

const uploadDocument = async (
  projectId: string,
  formData: INewDocumentForm,
  document: File
): Promise<ApiResource<IDocument>> => {
  const data = new FormData();

  data.append(`document`, document);

  Object.entries(formData).forEach(([key, value]) => {
    let preparedValue = value;

    if (value instanceof Object) {
      preparedValue = JSON.stringify(value);
    }

    if (value instanceof Date) {
      preparedValue = moment(adjustTimezone(value, "remove")).unix();
    }

    data.append(key, preparedValue);
  });

  const response = await API.request<ApiResource<IDocument>>(
    `/carrier/orders/${projectId}/document`,
    {
      method: HTTPMethod.POST,
      version: "v2",
    },
    data
  );

  return response;
};

const uploadQuickPayDocument = async (
  projectId: number,
  data: InvoiceFormData & { agreed_with_latest_additional_info_policy: boolean }
): Promise<SuccessResponse> => {
  const formData = new FormData();

  if (data.file) {
    formData.append(`document`, data.file);
  }

  formData.append(`discount`, data.discount);
  formData.append(
    `agreed_with_latest_additional_info_policy`,
    data.agreed_with_latest_additional_info_policy ? "1" : "0"
  );

  const response = await API.request<SuccessResponse>(
    `/carrier/orders/${projectId}/quick-pay/upload`,
    {
      method: HTTPMethod.POST,
      version: "v2",
    },
    formData
  );

  return response;
};

const fetchClientCompanies = async (): Promise<
  ApiResource<ClientCompany[]>
> => {
  const response = await API.request<ApiResource<ClientCompany[]>>(
    `/carrier/client-companies`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const projectAPI = {
  fetchList,
  fetchProject,
  fetchDocuments,
  acceptProject,
  rejectProject,
  updateProject,
  confirmStatus,
  archiveProject,
  unarchiveProject,
  uploadDocument,
  uploadQuickPayDocument,
  generateQuickPay,
  fetchChangeLog,
  fetchClientCompanies,
};

export { projectAPI };
