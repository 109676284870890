import { NotificationProps } from "../types";
import styles from "../Notification.module.scss";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { FaRegBuilding } from "react-icons/fa";
import { formatDateTime } from "../../../utils/date-format";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { projectList } from "../../projects/list/store/slices";
import { CarrierRoute } from "../../../constants/routes";
import { profile } from "../../profile/store/slices";
import { AppModule } from "../../../services/api/settings";

export const NotificationItem = ({ notification }: NotificationProps) => {
  const { t } = useTranslation("notifications");
  const dispatch = useDispatch();
  const history = useHistory();

  const { is_read, company, type, ax_code, message_key, date } = notification;

  const profileState = useSelector((state) => state.profile);

  const redirect = useCallback(() => {
    if (notification.model_type === "App\\Project") {
      dispatch(projectList.actions.resetFilters());

      const route =
        CarrierRoute.Orders.List.replace(
          ":orderId?",
          notification.model_id.toString()
        ) + `?search=${notification.ax_code}`;

      history.push(route);

      if (notification.company?.id !== profileState.data?.selectedCompany?.id) {
        dispatch(
          profile.actions.changeCompany({
            id: notification.company?.id,
            appModule: AppModule.Projects,
          })
        );
      }
    }

    if (notification.model_type === "App\\Application") {
      history.push(CarrierRoute.Applications.List);
    }
  }, [dispatch, notification, history, profileState]);

  return (
    <Row
      className={styles.notificationRow}
      onClick={redirect}
      style={{ borderLeft: is_read ? "none" : "3px solid #3EB073" }}
    >
      <Col sm className={styles.notificationLeft}>
        {company && (
          <span className={styles.companyName}>
            <FaRegBuilding />
            {company.name}
          </span>
        )}
        <span
          className={styles.notificationStatus}
          style={{ fontWeight: is_read ? "initial" : "800" }}
        >
          {t(type)}
        </span>
        <span className={styles.notificationNumber}>{ax_code}</span>
      </Col>

      <Col className={styles.notificationText}>{t(message_key)}</Col>
      <Col className={styles.notificationDate}>{formatDateTime(date)}</Col>
    </Row>
  );
};
