import { FC } from "react";
import { Props } from "react-select/async";
import AsyncSelect from "react-select/async";

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: "auto",
    maxWidth: "350px",
    border: "1px solid #ced4da",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #94d9b4",
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: "black",
    backgroundColor: state.isSelected && "#f1f2f4",
    "&:hover": {
      color: "black",
      backgroundColor: "#f1f2f4",
    },
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      color: "white",
      backgroundColor: "#0b5669",
    },
  }),
};

interface IProps extends Props<any, boolean> {}

const SelectAsyncSearch: FC<IProps> = (props) => {
  return <AsyncSelect styles={customStyles} {...props} />;
};

export { SelectAsyncSearch };
