import { createSlice } from "@reduxjs/toolkit";
import { LanguageCode } from "../../../../i18n";
import {
  GeneralPolicy,
  GeneralPolicyData,
} from "../../../../services/api/general-policies";

interface IPoliciesState {
  policies: GeneralPolicy[];
  policy: GeneralPolicy | null;
  newPolicyData: GeneralPolicyData;
  newPolicyPublished: boolean;
  updatedPolicyData: GeneralPolicyData;
  updatedPolicyPublished: boolean;
}

const initialState: IPoliciesState = {
  policies: [],
  policy: null,
  newPolicyData: {
    en: "",
    ru: "",
    pl: "",
    lt: "",
  },
  newPolicyPublished: false,
  updatedPolicyData: {
    en: "",
    ru: "",
    pl: "",
    lt: "",
  },
  updatedPolicyPublished: false,
};

const adminGeneralPolicies = createSlice({
  name: "adminGeneralPolicies",
  initialState,
  reducers: {
    fetchPolicies: (state) => {
      //
    },
    setPolicies: (state, action) => {
      state.policies = action.payload;
    },
    fetchPolicy: (state, action) => {
      //
    },
    setPolicy: (state, action) => {
      state.policy = action.payload;
    },
    storePolicy: (state, action) => {
      //
    },
    setNewPolicyData: (state, action) => {
      const {
        languageCode,
        html,
      }: { languageCode: LanguageCode; html: string } = action.payload;

      state.newPolicyData[languageCode] = html;
    },
    setNewPolicyPublished: (state, action) => {
      state.newPolicyPublished = action.payload;
    },
    updatePolicy: (state, action) => {
      //
    },
    setUpdatedPolicyData: (state, action) => {
      const {
        languageCode,
        html,
      }: { languageCode: LanguageCode; html: string } = action.payload;

      state.updatedPolicyData[languageCode] = html;
    },
    setUpdatedPolicyPublished: (state, action) => {
      state.updatedPolicyPublished = action.payload;
    },
    deletePolicy: (state, action) => {
      //
    },
    activatePolicy: (state, action) => {
      //
    },
    resetState: () => initialState,
  },
});

export { adminGeneralPolicies };
