import queryString from "query-string";
import { API, ApiResource, HTTPMethod } from "..";

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export interface IActivityLogRequest {
  search?: string;
  perPage?: number;
  page?: number;
  sortBy?: string;
  sortDirection?: SortDirection;
  filter?: string;
  dateFrom?: number;
  dateTo?: number;
}

export interface IActivityLogResponse {
  createdAt: string;
  userId: number | null;
  userEmail: string | null;
  actionName: string;
  companyId: number | null;
  companyAxCode: string | null;
  companyName: string | null;
  details: any | null;
  model: any | null;
  modelHistory: any | null;
  modelType: string;
  modelId: number;
}

const fetch = async (
  requestParams: IActivityLogRequest
): Promise<ApiResource<IActivityLogResponse[]>> => {
  const params = queryString.stringify(requestParams as any);

  const response = await API.request<ApiResource<IActivityLogResponse[]>>(
    `/admin/activity-log?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const adminActivityLogAPI = {
  fetch,
};

export { adminActivityLogAPI };
