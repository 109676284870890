import { createSlice } from "@reduxjs/toolkit";
import { LanguageCode } from "../../../../i18n";
import {
  ProjectPolicy,
  ProjectPolicyData,
} from "../../../../services/api/admin/project-policies";
import { ClientCompany } from "../../../../services/api/projects";

interface IProjectPolicyState {
  policies: ProjectPolicy[];
  clientCompanies: ClientCompany[];
  policy: ProjectPolicy | null;
  newPolicyData: ProjectPolicyData;
  newPolicyPublished: boolean;
  updatedPolicyData: ProjectPolicyData;
  updatedPolicyPublished: boolean;

  policiesLoading: boolean;
  clientCompaniesLoading: boolean;
}

const initialState: IProjectPolicyState = {
  policies: [],
  clientCompanies: [],
  policy: null,
  newPolicyData: {
    en: "",
    ru: "",
    pl: "",
    lt: "",
  },
  newPolicyPublished: false,
  updatedPolicyData: {
    en: "",
    ru: "",
    pl: "",
    lt: "",
  },
  updatedPolicyPublished: false,
  policiesLoading: false,
  clientCompaniesLoading: false,
};

const adminProjectPolicies = createSlice({
  name: "adminProjectPolicies",
  initialState,
  reducers: {
    fetchPolicies: (state, action) => {
      //
    },
    setPolicies: (state, action) => {
      state.policies = action.payload;
    },
    fetchPolicy: (state, action) => {
      //
    },
    setPolicy: (state, action) => {
      state.policy = action.payload;
    },
    storePolicy: (state, action) => {
      //
    },
    setNewPolicyData: (state, action) => {
      const {
        languageCode,
        html,
      }: { languageCode: LanguageCode; html: string } = action.payload;

      state.newPolicyData[languageCode] = html;
    },
    setNewPolicyPublished: (state, action) => {
      state.newPolicyPublished = action.payload;
    },
    updatePolicy: (state, action) => {
      //
    },
    setUpdatedPolicyData: (state, action) => {
      const {
        languageCode,
        html,
      }: { languageCode: LanguageCode; html: string } = action.payload;

      state.updatedPolicyData[languageCode] = html;
    },
    setUpdatedPolicyPublished: (state, action) => {
      state.updatedPolicyPublished = action.payload;
    },
    deletePolicy: (state, action) => {
      //
    },
    activatePolicy: (state, action) => {
      //
    },
    fetchClientCompanies: (state) => {
      //
    },
    setClientCompanies: (state, action) => {
      state.clientCompanies = action.payload;
    },
    setClientCompaniesLoading: (state, action) => {
      state.clientCompaniesLoading = action.payload;
    },
    setPoliciesLoading: (state, action) => {
      state.policiesLoading = action.payload;
    },
    resetState: () => initialState,
  },
});

export { adminProjectPolicies };
