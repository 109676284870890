import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "typed-redux-saga";
import { adminUsersAPI } from "../../../../../services/api/admin/users";
import { showError, showSuccess } from "../../../../alert/store/slices";
import { loader } from "../../../../common/loaders/store/slices";
import { adminUsers } from "../slices";

function* adminUsersConditionsSaga() {
  yield* takeLatest(adminUsers.actions.toggleConditions, toggleConditions);
  yield* takeLatest(adminUsers.actions.storeConditions, storeConditions);
}

function* toggleConditions({
  payload: { userId, enabled },
}: PayloadAction<{ userId: string; enabled?: boolean }>) {
  if (typeof enabled === "undefined") {
    return;
  }

  const response = yield* call(adminUsersAPI.toggleConditions, userId);

  if (response && response.success) {
    yield put(
      showSuccess(`User conditions ${enabled ? "enabled" : "disabled"}.`)
    );
  } else {
    yield put(showError("Something went wrong while toggling conditions."));
  }
}

function* storeConditions({
  payload: { userId, text },
}: PayloadAction<{ userId: string; text: string }>) {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminUsersAPI.storeConditions, userId, text);

  if (response && response.success) {
    yield put(showSuccess("User conditions updated."));
  } else {
    yield put(showError("Something went wrong while updating conditions."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminUsersConditionsSaga };
