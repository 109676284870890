import { call, fork, put, select, take } from "typed-redux-saga";
import { fetchUser } from ".";
import {
  adminUsersAPI,
  RoleLevel,
} from "../../../../../services/api/admin/users";
import { AppState } from "../../../../../store";
import { showError, showSuccess } from "../../../../alert/store/slices";
import { loader } from "../../../../common/loaders/store/slices";
import { IEditRoleForm } from "../../UserRoleEdit";
import { adminUsers } from "../slices";

function* adminUsersRolesSaga() {
  yield* fork(fetchUserRolesListener);
  yield* fork(roleChangeListener);
}

function* fetchUserRolesListener() {
  while (true) {
    const { payload } = yield take(adminUsers.actions.fetchUserRoles);

    if (payload.userId) {
      const { user } = yield* select((state: AppState) => ({
        user: state.adminUsers.userData,
      }));

      if (!user) {
        yield* call(fetchUser, payload.userId);
      }
    }

    yield* call(fetchUserRoles);
  }
}

function* roleChangeListener() {
  while (true) {
    const { payload } = yield take(adminUsers.actions.changeRole);

    if (payload.userId && payload.data) {
      yield* call(changeRole, payload.userId, payload.data);
    }
  }
}

function* changeRole(userId: string, data: IEditRoleForm) {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminUsersAPI.changeRole, userId, data);

  if (response && response.data) {
    yield put(adminUsers.actions.setUpdatedUser(response.data));

    yield put(showSuccess("User role changed."));
  } else {
    yield put(showError("Something went wrong while changing role."));
  }

  yield put(loader.actions.stopLoader());
}

function* fetchUserRoles() {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminUsersAPI.fetchRoles, RoleLevel.User);

  if (response && response.data) {
    yield put(adminUsers.actions.setRoles(response.data));
  } else {
    yield put(showError("Something went wrong while getting roles."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminUsersRolesSaga };
