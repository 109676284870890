import { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFilter } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import useMobileScreen from "../../../hooks/mobile-screen";
import { AppModule } from "../../../services/api/settings";

type Props = {
  children: React.ReactNode;
};

const ListFilter = ({ children }: Props) => {
  const isMobileScreen = useMobileScreen();
  const { t } = useTranslation("listFilters");
  const { pathname } = useLocation();

  const [isVisible, setVisible] = useState<boolean>(false);

  const marginTop = useMemo(() => {
    const showCustomPageMargin = pathname.includes(AppModule.Orders);

    return showCustomPageMargin && isMobileScreen ? "15px" : "0px";
  }, [pathname, isMobileScreen]);

  return (
    <div className="list-filter" style={{ marginTop }}>
      <Button block onClick={() => setVisible(true)}>
        <FaFilter /> {t("filters")}
      </Button>
      <Modal
        className="list-filter-modal"
        show={isVisible}
        onHide={() => setVisible(false)}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>
            <FaFilter /> {t("filters")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setVisible(false)}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export { ListFilter };
