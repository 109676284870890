import { FC } from "react";
import { Badge, BadgeProps } from "react-bootstrap";
import { DocumentStatus, IDocumentStatus } from "../../services/api/documents";

const StatusColor: { [key in DocumentStatus]: BadgeProps["variant"] } = {
  [DocumentStatus.Pending]: "secondary",
  [DocumentStatus.Accepted]: "success",
  [DocumentStatus.Rejected]: "danger",
  [DocumentStatus.Uploaded]: "info",
  [DocumentStatus.Generated]: "success",
  [DocumentStatus.Waiting]: "warning",
};

interface IProps {
  status: IDocumentStatus;
}

const StatusBadge: FC<IProps> = ({ status }) => {
  return (
    <Badge className="status-badge" variant={StatusColor[status.code]}>
      {status.name}
    </Badge>
  );
};

export { StatusBadge };
