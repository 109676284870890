import { FC, useCallback } from "react";
import { Row, Container, Image, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { email } from "../../../constants/regex";
import { useDispatch, useSelector } from "react-redux";
import { authentication } from "./store/slices";
import { Link } from "react-router-dom";
import { PublicRoute } from "../../../constants/routes";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { useTranslation } from "react-i18next";
import { MaintenanceAlert } from "../../maintenance/MaintenanceAlert";
import { LanguageSwitcher } from "../../translations/LanguageSwitcher";
import { Cover } from "../../cover/Cover";

export interface ILoginData {
  email: string;
  password: string;
}

const Login: FC = () => {
  const { t } = useTranslation("login");

  const isLoading = useSelector((state) => state.loader.isLoading);

  const dispatch = useDispatch();

  const fetchTokens = useCallback(
    (data: ILoginData) => dispatch(authentication.actions.fetchTokens(data)),
    [dispatch]
  );

  const { register, errors, handleSubmit } = useForm<ILoginData>();

  const onSubmit = async (data: ILoginData) => {
    fetchTokens({
      ...data,
    });
  };

  return (
    <Container className="login" fluid>
      <Cover />
      <Row className="language-row">
        <LanguageSwitcher />
      </Row>
      <Row className="form-row">
        <Col />
        <Col xs={12} md={6} lg={5} xl={3}>
          <Row>
            <Col className="logo-place">
              <Link to="/">
                <Image src="/images/logo.svg" />
              </Link>
            </Col>
          </Row>
          <MaintenanceAlert />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} controlId="email">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  ref={register({ required: true, pattern: email })}
                  isInvalid={!!errors.email}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.type === "required" && t("requiredField")}
                  {errors.email?.type === "pattern" && t("invalidEmail")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="password">
                <Form.Label>{t("password")}</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  ref={register({
                    required: true,
                  })}
                  isInvalid={!!errors.password}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.type === "required" && t("requiredField")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <div className="login-buttons">
              <Link to={PublicRoute.Password.Forgot}>
                {t("forgotPassword")}
              </Link>
              <ButtonLoader
                variant="primary"
                type="submit"
                disabled={isLoading}
              >
                {t("login")}
              </ButtonLoader>
            </div>
          </Form>

          <hr />

          <Row className="register-button">
            <Col>
              <h5>{t("dontHaveAccount")}</h5>
              <Button
                as={Link}
                to={PublicRoute.Register}
                variant="outline-secondary"
              >
                {t("register")}
              </Button>
            </Col>
          </Row>

          <Row className="manager-link">
            <Col>
              <span>
                {t("joinManager")} <a href="/login/microsoft">{t("here")}</a>
              </span>
            </Col>
          </Row>
        </Col>
        <Col />
      </Row>
    </Container>
  );
};

export { Login };
