import { call, delay, put, select, takeLatest } from "typed-redux-saga";
import { adminClientCompaniesAPI } from "../../../../services/api/admin/client-companies";
import { IFetchDocumentParams } from "../../../../services/api/documents";
import { AppState } from "../../../../store";
import { showError, showSuccess } from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { adminClientCompanies } from "./slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ClientCompany, Director } from "../../../../services/api/projects";
import { adminDirectorsAPI } from "../../../../services/api/admin/directors";

function* adminClientCompaniesSagas() {
  yield* takeLatest(adminClientCompanies.actions.setSearch, debounceSearch);
  yield* takeLatest(adminClientCompanies.actions.setPerPage, fetchCompanies);
  yield* takeLatest(adminClientCompanies.actions.setActivePage, fetchCompanies);
  yield* takeLatest(
    adminClientCompanies.actions.uploadStamp,
    handleUploadStamp
  );
  yield* takeLatest(
    adminClientCompanies.actions.updateDirector,
    handleUpdateDirector
  );
}

function* debounceSearch() {
  const data = yield* select(
    (state: AppState) => state.adminClientCompanies.companies
  );

  if (data.length > 0) {
    yield delay(500);
  }

  yield* call(fetchCompanies);
}

function* fetchCompanies() {
  yield put(loader.actions.startLoader());

  const { search, perPage, page } = yield* select((state: AppState) => ({
    search: state.adminClientCompanies.search,
    perPage: state.adminClientCompanies.perPage,
    page: state.adminClientCompanies.activePage,
  }));

  const requestParams: IFetchDocumentParams = {
    search,
    perPage,
    page,
  };

  const response = yield* call(
    adminClientCompaniesAPI.fetchClientCompanies,
    requestParams
  );

  if (response && response.data && response.meta) {
    yield put(
      adminClientCompanies.actions.setCompanies({
        data: response.data,
        meta: response.meta,
      })
    );
  } else {
    yield put(
      showError("Something went wrong while getting client companies.")
    );
  }

  yield put(loader.actions.stopLoader());
}

function* handleUploadStamp({
  payload: { company, file },
}: PayloadAction<{ company: ClientCompany; file: File }>) {
  yield put(adminClientCompanies.actions.setStampUpdating(true));

  const response = yield* call(
    adminClientCompaniesAPI.uploadStamp,
    company.id,
    file
  );

  if (response && response.data) {
    yield put(adminClientCompanies.actions.reset());
    yield call(fetchCompanies);

    yield put(showSuccess("Stamp uploaded."));
  } else {
    yield put(showError("Something went wrong while uploading stamp."));
  }

  yield put(adminClientCompanies.actions.setStampUpdating(false));
}

function* handleUpdateDirector({
  payload: { director, firstName, lastName },
}: PayloadAction<{ director: Director; firstName: string; lastName: string }>) {
  yield put(adminClientCompanies.actions.setDirectorUpdating(true));

  const response = yield* call(
    adminDirectorsAPI.updateDirector,
    director.id,
    firstName,
    lastName
  );

  if (response && response.data) {
    yield put(adminClientCompanies.actions.reset());
    yield call(fetchCompanies);

    yield put(showSuccess("Director updated."));
  } else {
    yield put(showError("Something went wrong while updating director."));
  }

  yield put(adminClientCompanies.actions.setDirectorUpdating(false));
}

export { adminClientCompaniesSagas };
