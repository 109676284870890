import { Dropdown } from "react-bootstrap";
import { FaRegBell, FaSync } from "react-icons/fa";
import { NotificationDropdown } from "./NotificationDropdown";
import { useDispatch, useSelector } from "react-redux";
import { CSSProperties, useCallback, useEffect } from "react";
import { notifications } from "../store/slices";
import styles from "../Notification.module.scss";
import useMobileScreen from "../../../hooks/mobile-screen";
import { useTranslation } from "react-i18next";

export const NotificationBell = () => {
  const { unreadCount } = useSelector((state) => state.notifications);
  const { t } = useTranslation("notifications");
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile);
  const isUnreadCountLoading = useSelector(
    (state) => state.notifications.isUnreadCountLoading
  );
  const isUnreadCountRefreshEnabled = useSelector(
    (state) => state.notifications.isUnreadCountRefreshEnabled
  );

  const isMobileScreen = useMobileScreen();

  const fetchUnreadNotificationsCount = useCallback(
    () => dispatch(notifications.actions.fetchUnreadCount()),
    [dispatch]
  );
  const fetchRecentNotifications = useCallback(
    () => dispatch(notifications.actions.fetchRecentNotifications()),
    [dispatch]
  );
  const enableUnreadCountRefresh = useCallback(
    () => dispatch(notifications.actions.enableUnreadCountRefresh()),
    [dispatch]
  );
  const resetRecent = useCallback(
    () => dispatch(notifications.actions.resetRecent()),
    [dispatch]
  );

  useEffect(() => {
    if (profile.isCarrier) {
      const interval = setInterval(
        () => fetchUnreadNotificationsCount(),
        30000
      );

      enableUnreadCountRefresh();

      return () => {
        clearInterval(interval);
      };
    }
  }, [profile, fetchUnreadNotificationsCount, enableUnreadCountRefresh]);

  useEffect(() => {
    if (!isUnreadCountRefreshEnabled) {
      fetchUnreadNotificationsCount();
    }
  }, [isUnreadCountRefreshEnabled, fetchUnreadNotificationsCount]);

  const dropdownStyle: CSSProperties = isMobileScreen
    ? {
        position: "relative",
        backgroundColor: "#f1f2f4",
        color: "rgba(0, 0, 0, 0.5)",
        fontSize: "16px",
        borderRight: "none",
        border: "none",
        padding: 0,
        marginLeft: "8px",
      }
    : {
        position: "relative",
        backgroundColor: "white",
        color: "black",
        fontSize: "20px",
        boxShadow: "none",
        borderRight: "none",
        border: "none",
      };

  return (
    <Dropdown
      onToggle={(show) => {
        if (show) {
          fetchRecentNotifications();
        } else {
          resetRecent();
          fetchUnreadNotificationsCount();
        }
      }}
    >
      <Dropdown.Toggle style={dropdownStyle}>
        {isMobileScreen ? (
          <div className={styles.mobileNotificationIcon}>
            <FaRegBell />
            <span>{t("notifications")}</span>
            <span className={styles.mobileCountBadge}>
              {isUnreadCountLoading ? <FaSync /> : unreadCount}
            </span>
          </div>
        ) : (
          <>
            <FaRegBell />
            <span className={styles.countBadge}>
              {isUnreadCountLoading ? <FaSync /> : unreadCount}
            </span>
          </>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <NotificationDropdown />
      </Dropdown.Menu>
    </Dropdown>
  );
};
