import { fork, take, call, put } from "typed-redux-saga";
import { showError, showSuccess } from "../../../alert/store/slices";
import questionnaireModalSlice from "../slices";
import { companyAPI } from "../../../../services/api/company";
import { QuestionnaireForm } from "../../QuestionnaireModal";
import { profile } from "../../../profile/store/slices";
import { translated } from "../../../../i18n";

function* questionnaireModalSaga() {
  yield* fork(submitFormListener);
}

function* submitFormListener() {
  while (true) {
    const { payload } = yield take(questionnaireModalSlice.actions.submitForm);

    if (payload.companyId && payload.data) {
      yield* call(submitForm, payload.companyId, payload.data);
    }
  }
}

function* submitForm(companyId: string, data: QuestionnaireForm) {
  const result = yield* call(
    companyAPI.submitQuestionnaire,
    companyId,
    data.questions
  );

  if (result && result.success) {
    yield put(questionnaireModalSlice.actions.setFormSubmitted());

    yield put(profile.actions.fetchProfile());

    yield put(showSuccess(translated.formSubmitted));
  } else if (result && result.message) {
    yield put(showError(result.message));
  }
}

export default questionnaireModalSaga;
