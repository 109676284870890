import { FC, useCallback, useEffect, useMemo } from "react";
import { Col, Table } from "react-bootstrap";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Column, TableOptions, useSortBy, useTable } from "react-table";
import { Loader } from "../../common/loaders/Loader";
import { NoData } from "../../common/no-data/NoData";
import { applications } from "../store/slices";
import { Link } from "react-router-dom";
import { CarrierRoute } from "../../../constants/routes";
import {
  ApplicationStatus,
  ApplicationType,
  IApplication,
} from "../../../services/api/application";
import { ActionCell } from "./cells/ActionCell";
import { StatusBadge } from "../StatusBadge";
import { formatUnixDate } from "../../../utils/date-format";
import { useTranslation } from "react-i18next";

const ApplicationList: FC = () => {
  const { t } = useTranslation("applicationList");

  const isLoading = useSelector((state) => state.loader.isLoading);
  const listData = useSelector((state) => state.applications.listData);

  const dispatch = useDispatch();

  const fetchList = useCallback(
    () => dispatch(applications.actions.fetchList()),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(applications.actions.resetList()), [
    dispatch,
  ]);

  useEffect(() => {
    fetchList();

    return () => {
      reset();
    };
  }, [fetchList, reset]);

  const columns: Column<TableOptions<IApplication[]>>[] = useMemo(
    () => [
      {
        Header: t("vatPayerCode") as string,
        accessor: "company.vatNumber",
        Cell: ({ cell, value }) => {
          let url: string = "";

          const application = (cell.row.original as unknown) as IApplication;

          const type = application.type;
          const status = application.status;

          const isNewPartner = type.code === ApplicationType.New;
          const isExistingPartner = type.code === ApplicationType.Existing;

          const hasDraftStatus = status.code === ApplicationStatus.Open;

          if (isNewPartner) {
            url = hasDraftStatus
              ? CarrierRoute.Applications.NewPartner.replace(
                  ":applicationId",
                  application.id.toString()
                )
              : CarrierRoute.Applications.ShowNewPartner.replace(
                  ":applicationId",
                  application.id.toString()
                );
          }

          if (isExistingPartner) {
            url = hasDraftStatus
              ? CarrierRoute.Applications.ExistingPartner.replace(
                  ":applicationId",
                  application.id.toString()
                )
              : CarrierRoute.Applications.ShowExistingPartner.replace(
                  ":applicationId",
                  application.id.toString()
                );
          }

          return value ? <Link to={url}>{value}</Link> : <span>-</span>;
        },
      },
      {
        Header: t("companyName") as string,
        accessor: "company.name",
        Cell: ({ value }) => (value ? value : <span>-</span>),
      },
      {
        Header: t("type") as string,
        accessor: "type.name",
        Cell: ({ value }) => (value ? value : <span>-</span>),
      },
      {
        Header: t("status") as string,
        accessor: "status",
        Cell: ({ value }) =>
          value ? <StatusBadge status={value} /> : <span>-</span>,
      },
      {
        Header: t("submittedAt") as string,
        accessor: "submittedAt",
        Cell: ({ value }) => (value ? formatUnixDate(value) : <span>-</span>),
      },
      {
        Header: t("actions") as string,
        accessor: "actions",
        Cell: (cell) => <ActionCell cell={cell} />,
        disableSortBy: true,
      },
    ],
    [t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<TableOptions<IApplication[]>>(
    {
      columns,
      data: listData,
    },
    useSortBy
  );

  return (
    <>
      <Col xs={1} />
      <Col>
        <Table
          striped
          bordered
          hover
          {...getTableProps()}
          className="application-list"
          responsive
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BsFillCaretDownFill />
                        ) : (
                          <BsFillCaretUpFill />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading && (
              <tr>
                <td colSpan={columns.length}>
                  <div className="list-loader">
                    <Loader />
                  </div>
                </td>
              </tr>
            )}
            {rows.length === 0 && !isLoading && (
              <tr>
                <td colSpan={columns.length}>
                  <NoData />
                </td>
              </tr>
            )}

            {!isLoading &&
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Col>
      <Col xs={1} />
    </>
  );
};

export { ApplicationList };
