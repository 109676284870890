import { FC, useCallback, useEffect, useMemo } from "react";
import { Table } from "react-bootstrap";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Column, TableOptions, useSortBy, useTable } from "react-table";
import { IUser } from "../../../services/api/users";
import { Loader } from "../../common/loaders/Loader";
import { NoData } from "../../common/no-data/NoData";
import { userList } from "./store/slices";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CarrierRoute } from "../../../constants/routes";
import { ActionCell } from "./cells/ActionCell";
import InfiniteScroll from "react-infinite-scroll-component";

const UserList: FC = () => {
  const { t } = useTranslation("userList");
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.userList.isLoading);
  const data = useSelector((state) => state.userList.data);
  const activePage = useSelector((state) => state.userList.activePage);
  const meta = useSelector((state) => state.userList.meta);

  const setActivePage = useCallback(
    (pages: number) => dispatch(userList.actions.setActivePage(pages)),
    [dispatch]
  );

  const resetPagination = useCallback(
    () => dispatch(userList.actions.resetPagination()),
    [dispatch]
  );

  const fetchMore = useCallback(() => {
    if (meta && activePage < meta.total) {
      setActivePage(activePage + 1);
    }
  }, [activePage, meta, setActivePage]);

  const columns: Column<TableOptions<IUser[]>>[] = useMemo(
    () => [
      {
        Header: t("email") as string,
        accessor: "email",
        Cell: ({ cell }) => {
          const user = (cell.row.original as unknown) as IUser;

          return (
            <Link
              to={CarrierRoute.Users.Show.replace(
                ":userId",
                user.id.toString()
              )}
            >
              {user.email}
            </Link>
          );
        },
      },
      {
        Header: t("name") as string,
        accessor: "name",
      },
      {
        Header: t("surname") as string,
        accessor: "surname",
      },
      {
        Header: t("phone") as string,
        accessor: "phone",
      },
      {
        Header: t("roles") as string,
        Cell: ({ cell }) => {
          const user = (cell.row.original as unknown) as IUser;
          const company = user.companies[0];

          if (company && company.roles) {
            const roles = company.roles.map((role) => role.name);

            return <span>{roles.join(", ")}</span>;
          }

          return <span>-</span>;
        },
      },
      {
        Header: t("actions") as string,
        accessor: "actions",
        Cell: (cell) => <ActionCell cell={cell} />,
        disableSortBy: true,
      },
    ],
    [t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<TableOptions<IUser[]>>(
    {
      columns,
      data,
    },
    useSortBy
  );

  useEffect(() => {
    return () => {
      resetPagination();
    };
  }, [resetPagination]);

  const hasMore = useMemo(
    () => !!(rows.length > 0 && meta && rows.length !== meta.total),
    [meta, rows]
  );

  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={fetchMore}
      hasMore={hasMore}
      loader={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loader isLoading={true} />
        </div>
      }
      style={{ overflow: "hidden" }}
    >
      <Table
        responsive
        striped
        bordered
        hover
        {...getTableProps()}
        className="users-list"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header") as any}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <BsFillCaretDownFill />
                      ) : (
                        <BsFillCaretUpFill />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <div className="list-loader">
                  <Loader />
                </div>
              </td>
            </tr>
          )}
          {rows.length === 0 && !isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <NoData />
              </td>
            </tr>
          )}

          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render("Cell") as any}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </InfiniteScroll>
  );
};

export { UserList };
