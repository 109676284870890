import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { FaUserLock } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
// import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { policies } from "../store/slices";
import { LanguageCode } from "../../../i18n";
import { LanguagePills } from "../../common/language-pills/LanguagePills";
import { policies } from "../store/slices";
import { Loader } from "../../common/loaders/Loader";

interface ITermsModalForm {
  // privacyAccepted: boolean;
  termsAccepted: boolean;
}

interface IProps {
  isVisible: boolean;
  handleDeny?: () => void;
  handleConfirm?: () => void;
}

const TermsAndConditionsModal: FC<IProps> = ({
  isVisible,
  handleDeny,
  handleConfirm,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("termsPolicyModal");

  const [language, setLanguage] = useState<LanguageCode>(LanguageCode.EN);

  const policy = useSelector((state) => state.policies.termsPolicy);
  const isLoading = useSelector((state) => state.policies.isLoading);
  const isSubmitting = useSelector((state) => state.loader.isLoading);

  const fetchPolicy = useCallback(
    () => dispatch(policies.actions.fetchTermsPolicy()),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(policies.actions.resetTermsPolicy()),
    [dispatch]
  );

  useEffect(() => {
    if (isVisible) {
      fetchPolicy();
    }

    return () => {
      reset();
    };
  }, [fetchPolicy, reset, isVisible]);

  const { register, handleSubmit, watch } = useForm<ITermsModalForm>();

  // const toggleTermsConditions = useCallback(
  //   () => dispatch(policies.actions.toggleTermsConditions()),
  //   [dispatch]
  // );

  // const togglePrivacyPolicy = useCallback(
  //   () => dispatch(policies.actions.togglePrivacyPolicy()),
  //   [dispatch]
  // );

  return (
    <Modal
      show={isVisible}
      onHide={handleDeny ? handleDeny : () => undefined}
      size="xl"
      className="private-policy-modal"
      scrollable
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          <FaUserLock /> {t("termsAndConditions")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LanguagePills
          onSelect={(language) => setLanguage(language as LanguageCode)}
          style={{
            textTransform: "uppercase",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        />
        <Loader isLoading={isLoading} />

        {policy && (
          <div dangerouslySetInnerHTML={{ __html: policy.data[language] }} />
        )}
      </Modal.Body>
      <Modal.Footer>
        {handleConfirm && (
          <Form onSubmit={handleSubmit(handleConfirm)}>
            <Form.Row>
              <Form.Group as={Col} controlId="termsAccepted">
                <Form.Check
                  name="termsAccepted"
                  type="checkbox"
                  ref={register}
                  label={
                    <>
                      {t("iHaveReadAndAgreeToThe")} {t("termsAndConditions")}
                    </>
                  }
                />
              </Form.Group>
            </Form.Row>

            {/* <Form.Row>
              <Form.Group as={Col} controlId="privacyAccepted">
                <Form.Check
                  name="privacyAccepted"
                  type="checkbox"
                  ref={register}
                  label={
                    <>
                      {t("iHaveReadAndAgreeGirtekaLogistics")}{" "}
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTermsConditions();
                          togglePrivacyPolicy();
                        }}
                      >
                        {t("privacyPolicy")}
                      </Link>
                    </>
                  }
                />
              </Form.Group>
            </Form.Row> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ButtonLoader
                variant="primary"
                onClick={handleConfirm}
                disabled={isSubmitting}
                buttonDisabled={
                  // !watch("privacyAccepted") || !watch("termsAccepted")
                  !watch("termsAccepted")
                }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "35px",
                }}
              >
                {t("agree")}
              </ButtonLoader>
            </div>
          </Form>
        )}
        {handleDeny && (
          <Button
            variant="outline-primary"
            onClick={handleDeny}
            disabled={isLoading}
          >
            {t("close")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export { TermsAndConditionsModal };
