import { FC, useState, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CarrierRoute } from "../../../../constants/routes";
import { IUser } from "../../../../services/api/users";
import { ConfirmModal } from "../../../common/modals/ConfirmModal";
import { userList } from "../store/slices";

interface IProps {
  cell: any;
}

const ActionCell: FC<IProps> = ({ cell }) => {
  const { t } = useTranslation("userList");

  const data: IUser = cell.row.original;
  const id: number = data.id;

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const profile = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const history = useHistory();

  const deleteUser = useCallback(
    (id: number) => dispatch(userList.actions.deleteUser(id)),
    [dispatch]
  );

  const handleDelete = () => {
    setDeleteModalVisible(false);

    deleteUser(id);
  };

  if (!profile.data) return null;

  return (
    <>
      <Dropdown id="dropdown">
        <Dropdown.Toggle
          size="sm"
          variant="outline-primary"
          id="dropdown-toggle"
        >
          <FaCaretDown />
        </Dropdown.Toggle>

        <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
          <Dropdown.Item
            onClick={() => {
              history.push(
                CarrierRoute.Users.Show.replace(":userId", id.toString())
              );
            }}
          >
            {t("review")}
          </Dropdown.Item>
          {!profile.hasAccountantRole && (
            <>
              <Dropdown.Item
                onClick={() =>
                  history.push(
                    CarrierRoute.Users.Edit.replace(":userId", id.toString())
                  )
                }
              >
                {t("edit")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDeleteModalVisible(true)}>
                {t("remove")}
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <ConfirmModal
        question={t("removeFromCompany") as string}
        isVisible={deleteModalVisible}
        handleDeny={() => setDeleteModalVisible(false)}
        handleConfirm={handleDelete}
      />
    </>
  );
};

export { ActionCell };
