import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { policies } from "../policies/store/slices";

interface IProps {
  loggedIn?: boolean;
}

const Footer: FC<IProps> = ({ loggedIn }) => {
  const { t } = useTranslation("footer");

  const dispatch = useDispatch();

  const togglePrivacyPolicy = useCallback(
    () => dispatch(policies.actions.togglePrivacyPolicy()),
    [dispatch]
  );

  const toggleMarketingPolicy = useCallback(
    () => dispatch(policies.actions.toggleMarketingPolicy()),
    [dispatch]
  );

  const toggleTermsConditions = useCallback(
    () => dispatch(policies.actions.toggleTermsConditions()),
    [dispatch]
  );

  const currentTime = new Date();
  const currentYear = currentTime.getFullYear();

  return (
    <>
      <footer className="footer">
        <div>
          <span>
            {(t("copyRightGirtekaLogistics") as string).replace(
              "{year}",
              currentYear.toString()
            )}
          </span>
        </div>
        <div className="policy-links">
          {!!loggedIn && (
            <>
              <Link to="#" onClick={togglePrivacyPolicy}>
                {t("privacyPolicy")}
              </Link>
              <Link to="#" onClick={toggleMarketingPolicy}>
                {t("marketingPolicy")}
              </Link>
              <Link to="#" onClick={toggleTermsConditions}>
                {t("termsAndConditions")}
              </Link>
            </>
          )}
        </div>
      </footer>
    </>
  );
};

export { Footer };
