import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "../../../services/api";
import { CompanyRole, IProfile, Role } from "../../../services/api/profile";
import { findRole } from "./helpers";

export type CompanyShort = {
  id: number;
  name: string;
  ax_code: string;
};

export interface IProfileState {
  data: IProfile | null;

  // User roles
  isCarrier: boolean;
  isManager: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isGuest: boolean;
  isGroupLeader: boolean;

  // Company user roles
  hasOwnerRole: boolean;
  hasManagerRole: boolean;
  hasAccountantRole: boolean;

  passwordChanged: boolean;
  profileEdited: boolean;

  companySearch: string;
  companies: CompanyShort[];
  companiesMeta: IPagination | null;
  companiesIsLoading: boolean;
}

const initialState: IProfileState = {
  data: null,

  isCarrier: false,
  isManager: false,
  isAdmin: false,
  isSuperAdmin: false,
  isGuest: false,
  isGroupLeader: false,

  hasOwnerRole: false,
  hasManagerRole: false,
  hasAccountantRole: false,

  passwordChanged: false,
  profileEdited: false,

  companySearch: "",
  companies: [],
  companiesMeta: null,
  companiesIsLoading: false,
};

const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    fetchProfile: (state) => {
      //
    },
    setProfileData: (state, action) => {
      const user: IProfile = action.payload;

      state.isGroupLeader = user.isGroupLeader;

      if (findRole(user.roles, Role.Guest)) {
        state.isGuest = true;
      }

      if (findRole(user.roles, Role.Carrier)) {
        state.isCarrier = true;
      }

      if (findRole(user.roles, Role.Manager)) {
        state.isManager = true;
      }

      if (findRole(user.roles, Role.Admin)) {
        state.isAdmin = true;
      }

      if (findRole(user.roles, Role.SuperAdmin)) {
        state.isSuperAdmin = true;
      }

      if (user.selectedCompany && user.selectedCompany.roles) {
        if (findRole(user.selectedCompany.roles, CompanyRole.Owner)) {
          state.hasOwnerRole = true;
        }

        if (findRole(user.selectedCompany.roles, CompanyRole.Manager)) {
          state.hasManagerRole = true;
        }

        if (findRole(user.selectedCompany.roles, CompanyRole.Accountant)) {
          state.hasAccountantRole = true;
        }
      }

      state.data = action.payload;
    },
    editProfile: (state, action) => {
      //
    },
    changePassword: (state, action) => {
      //
    },
    deleteProfile: (state) => {
      //
    },
    setPasswordChanged: (state) => {
      if (state.data) {
        state.data.forceUpdatePassword = false;
      }

      state.passwordChanged = true;
    },
    setProfileEdited: (state) => {
      if (state.data) {
        state.data.forceUpdateProfile = false;
      }

      state.profileEdited = true;
    },
    changeCompany: (state, action) => {
      //
    },
    setCompanyChanged: (state, action) => {
      if (state.data) {
        const selectedCompany = action.payload.data;

        state.data.selectedCompany = selectedCompany;

        state.hasAccountantRole = false;
        state.hasManagerRole = false;
        state.hasOwnerRole = false;

        if (selectedCompany && selectedCompany.roles) {
          if (findRole(selectedCompany.roles, CompanyRole.Owner)) {
            state.hasOwnerRole = true;
          }

          if (findRole(selectedCompany.roles, CompanyRole.Manager)) {
            state.hasManagerRole = true;
          }

          if (findRole(selectedCompany.roles, CompanyRole.Accountant)) {
            state.hasAccountantRole = true;
          }
        }
      }
    },
    setPoliciesAccepted: (state) => {
      if (state.data) {
        // state.data.privacyPolicyAccepted = true;
        state.data.termsPolicyAccepted = true;
      }
    },
    setMarketingPolicySubmitted: (state, action) => {
      if (state.data) {
        state.data.marketingPolicySubmitted = action.payload;
      }
    },
    submitMarketingPolicy: (state, action) => {
      //
    },
    hideCarrierWizard: (state) => {
      if (state.data) {
        state.data.showCarrierWizard = false;
      }
    },
    logoutProfile: (state) => {
      //
    },
    resetActionStates: (state) => {
      state.passwordChanged = false;
      state.profileEdited = false;
    },
    reset: () => initialState,

    setCompanySearch: (state, action) => {
      state.companySearch = action.payload;
      state.companies = [];
      state.companiesMeta = null;
      state.companiesIsLoading = true;
    },
    loadMoreCompanies: (state, action) => {
      state.companiesIsLoading = true;

      if (state.companiesMeta) {
        state.companiesMeta.current_page = action.payload;
      }
    },
    setCompanies: (state, action) => {
      state.companies = action.payload.data;
      state.companiesMeta = action.payload.meta;
    },
    addCompanies: (state, action) => {
      state.companies = [...state.companies, ...action.payload.data];
      state.companiesMeta = action.payload.meta;
    },
    setIsCompaniesLoading: (state, action) => {
      state.companiesIsLoading = action.payload;
    },
    changeSapEnv: (state, action) => {
      if (state.data) {
        state.data.currentSapEnvironment = action.payload;
      }
    },
    resetCompanies: (state) => {
      state.companySearch = "";
      state.companies = [];
      state.companiesIsLoading = false;
      state.companiesMeta = null;
    },
  },
});

export { profile };
