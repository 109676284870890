import { FC } from "react";
import { OverlayTrigger, Tooltip as TooltipOriginal } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";

interface IProps {
  id: string;
  text: string;
  placement?: Placement;
  children: any;
}

const Tooltip: FC<IProps> = ({ id, text, placement, children }) => {
  return (
    <OverlayTrigger
      placement={placement || "top"}
      overlay={<TooltipOriginal id={id}>{text}</TooltipOriginal>}
    >
      {children}
    </OverlayTrigger>
  );
};

export { Tooltip };
