import { CSSProperties, useCallback, useState } from "react";
import { FaDesktop, FaSortDown } from "react-icons/fa";
import WorkspaceSelectModal from "./WorkspaceSelectModal";
import { useDispatch, useSelector } from "react-redux";
import { startCase } from "lodash";
import workspaceSwitcherSlice, { Workspace } from "./store/slice";

type Props = {
  style?: CSSProperties;
};

const WorkspaceSwitcher = ({ style }: Props) => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const profile = useSelector((state) => state.profile.data);

  const currentWorkspace = useSelector(
    (state) => state.workspace.currentWorkspace
  );

  const changeWorkspace = useCallback(
    (workspace: Workspace) =>
      dispatch(workspaceSwitcherSlice.actions.change(workspace)),
    [dispatch]
  );

  return (
    <div className="workspace-switcher" style={style}>
      <span onClick={() => setModalOpen(true)}>
        <FaDesktop /> {currentWorkspace && startCase(currentWorkspace)}{" "}
        <FaSortDown
          style={{
            margin: "0px 0px 8px 5px",
          }}
        />
      </span>
      <WorkspaceSelectModal
        profile={profile}
        currentWorkspace={currentWorkspace}
        changeWorkspace={changeWorkspace}
        isVisible={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </div>
  );
};

export default WorkspaceSwitcher;
