import { FC, useMemo, useState } from "react";
import screenshotURL from "../screenshots/documents.png";
import { useTranslation } from "react-i18next";
import { IHotspotProps } from "../../../common/hotspot/Hotspot";
import { Screenshot } from "../screenshots/Screenshot";

const DocumentPage: FC = () => {
  const { t } = useTranslation("documentPageWizard");

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const hotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 90, top: 155 },
        guideLines: [t("onTheDocumentsPage")],
      },
      {
        order: 2,
        style: { left: 170, top: 120 },
        guideLines: [t("filterDocumentsByStatus")],
      },
      {
        order: 3,
        style: { left: 300, top: 120 },
        guideLines: [t("filterDocumentsByType")],
      },
      {
        order: 4,
        style: { left: 425, top: 120 },
        guideLines: [t("filterOwnUploadedDocuments")],
      },
      {
        order: 5,
        style: { left: 520, top: 120 },
        guideLines: [t("filterAllDeletedDocuments")],
      },
      {
        order: 6,
        style: { left: 960, top: 210 },
        guideLines: [t("reviewDownloadDocuments")],
      },
    ],
    [t]
  );

  return (
    <div className="module-page" style={{ opacity: isLoading ? 0 : 1 }}>
      <Screenshot
        url={screenshotURL}
        label={t("documentsWhatActions") as string}
        hotspots={hotspots}
        imageLoaded={() => setIsLoading(false)}
      />
    </div>
  );
};

export { DocumentPage };
