import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Overlay,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FaStopwatch,
  FaQuestionCircle,
  FaCloudUploadAlt,
  FaTrash,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { formatUTCDate } from "../../../utils/date-format";
import { FileDropzone } from "../../common/files/FileDropzone";
import { Discount, quickPayModal } from "./store/slice";
import { formatBytes } from "../../../utils/byte-format";
import { PDFObject } from "react-pdfobject";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { Link } from "react-router-dom";
import { AdditionalInformationModal } from "../show/details/modals/AdditionalInformationModal";
import { ProjectPolicyCode } from "../../../services/api/admin/project-policies";

export type InvoiceFormData = {
  discount: Discount;
  file?: File;
  serial?: string;
  number?: string;
  bankName?: string;
  bankSwift?: string;
  bankIban?: string;
};

type Props = {
  handleClose: () => void;
};

const QuickPayModal = ({ handleClose }: Props) => {
  const { t } = useTranslation("quickPayModal");
  const dispatch = useDispatch();

  const [invoiceSerial, setInvoiceSerial] = useState<string>("");
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [bankName, setBankName] = useState<string>("");
  const [bankSwift, setBankSwift] = useState<string>("");
  const [bankIban, setBankIban] = useState<string>("");

  const [showInvoiceSerialMissing, setShowInvoiceSerialMissing] = useState<
    boolean
  >(false);
  const [showInvoiceNumberMissing, setShowInvoiceNumberMissing] = useState<
    boolean
  >(false);
  const [showBankNameMissing, setShowBankNameMissing] = useState<boolean>(
    false
  );
  const [showBankSwiftMissing, setShowBankSwiftMissing] = useState<boolean>(
    false
  );
  const [showBankIbanMissing, setShowBankIbanMissing] = useState<boolean>(
    false
  );

  const [documentForUpload, setUploadDocument] = useState<File>();

  const invoiceSerialRef = useRef<HTMLInputElement | null>(null);
  const invoiceNumberRef = useRef<HTMLInputElement | null>(null);
  const bankNameRef = useRef<HTMLInputElement | null>(null);
  const bankSwiftRef = useRef<HTMLInputElement | null>(null);
  const bankIbanRef = useRef<HTMLInputElement | null>(null);

  const confirmedPolicies = useSelector(
    (state) => state.quickPayModal.confirmedPolicies
  );
  const project = useSelector((state) => state.quickPayModal.project);
  const showEntryModal = useSelector(
    (state) => state.quickPayModal.showEntryModal
  );
  const showConfirmationModal = useSelector(
    (state) => state.quickPayModal.showConfirmationModal
  );
  const showUploadModal = useSelector(
    (state) => state.quickPayModal.showUploadModal
  );
  const showInvoiceModal = useSelector(
    (state) => state.quickPayModal.showInvoiceModal
  );
  const showAdditionalInfoModal = useSelector(
    (state) => state.quickPayModal.showAdditionalInfoModal
  );
  const latestPolicies = useSelector(
    (state) => state.quickPayModal.latestPolicies
  );
  const isLatestPoliciesLoading = useSelector(
    (state) => state.quickPayModal.isLatestPoliciesLoading
  );
  const discount = useSelector((state) => state.quickPayModal.discount);
  const isLoading = useSelector((state) => state.quickPayModal.isLoading);
  const modalMustBeClosed = useSelector(
    (state) => state.quickPayModal.modalMustBeClosed
  );
  const profile = useSelector((state) => state.profile);
  const settings = useSelector((state) => state.settings.carrier);

  const setDiscount = useCallback(
    (discount: Discount) =>
      dispatch(quickPayModal.actions.setDiscount(discount)),
    [dispatch]
  );
  const setConfirmedPolicies = useCallback(
    (confirmed: boolean) =>
      dispatch(quickPayModal.actions.setConfirmedPolicies(confirmed)),
    [dispatch]
  );
  const setShowConfirmationModal = useCallback(
    (show: boolean) =>
      dispatch(quickPayModal.actions.setShowConfirmationModal(show)),
    [dispatch]
  );
  const setShowUploadModal = useCallback(
    (show: boolean) => dispatch(quickPayModal.actions.setShowUploadModal(show)),
    [dispatch]
  );
  const setShowInvoiceModal = useCallback(
    (show: boolean) =>
      dispatch(quickPayModal.actions.setShowInvoiceModal(show)),
    [dispatch]
  );
  const setShowAdditionalInfoModal = useCallback(
    (show: boolean) =>
      dispatch(quickPayModal.actions.setShowAdditionalInfoModal(show)),
    [dispatch]
  );
  const generateInvoice = useCallback(
    (projectId: number, data: InvoiceFormData) =>
      dispatch(quickPayModal.actions.generateInvoice({ projectId, data })),
    [dispatch]
  );
  const uploadInvoice = useCallback(
    (projectId: number, data: InvoiceFormData) =>
      dispatch(quickPayModal.actions.uploadInvoice({ projectId, data })),
    [dispatch]
  );
  const fetchLatestPolicies = useCallback(
    (projectId: number) =>
      dispatch(quickPayModal.actions.fetchLatestPolicies(projectId)),
    [dispatch]
  );
  const reset = useCallback(() => dispatch(quickPayModal.actions.reset()), [
    dispatch,
  ]);

  const close = useCallback(() => {
    handleClose();

    setInvoiceSerial("");
    setInvoiceNumber("");
    setBankName("");
    setBankSwift("");
    setBankIban("");
    setShowInvoiceSerialMissing(false);
    setShowInvoiceNumberMissing(false);
    setShowBankNameMissing(false);
    setShowBankSwiftMissing(false);
    setShowBankIbanMissing(false);
    setUploadDocument(undefined);

    reset();
  }, [handleClose, reset]);

  const showUploadPreview = useMemo(() => {
    if (documentForUpload) {
      const fileType = documentForUpload.type;

      return ["application/pdf", "image/jpeg", "image/png"].includes(fileType);
    }

    return false;
  }, [documentForUpload]);

  useEffect(() => {
    if (modalMustBeClosed) {
      close();
    }
  }, [close, modalMustBeClosed]);

  useEffect(() => {
    if (project) {
      fetchLatestPolicies(project.id);

      setBankName(project.company.bankName);
      setBankIban(project.company.bankIban);
      setBankSwift(project.company.bankSwift);
    }
  }, [project, fetchLatestPolicies]);

  const newPaymentDay = useMemo(() => {
    let daysToAdd = 0;

    switch (discount) {
      case Discount.Five:
        daysToAdd = 3;
        break;
      case Discount.Four:
        daysToAdd = 7;
        break;
      case Discount.Three:
        daysToAdd = 14;
        break;
    }

    return moment().add(daysToAdd, "days").format("YYYY-MM-DD");
  }, [discount]);

  const feeSum = useMemo(() => {
    if (project && project.freightCharge) {
      const feePercentage = Number(discount);
      const freightSum = Number(project.freightCharge);

      return (feePercentage / 100) * freightSum;
    }

    return 0;
  }, [project, discount]);

  const vatSum = useMemo(() => {
    if (project && project.vatGroup && project.vatGroup.value) {
      const vat = Number(project.vatGroup.value);

      return (feeSum / 100) * vat;
    }

    return 0.0;
  }, [project, feeSum]);

  const newFreight = useMemo(() => {
    if (project && project.freightCharge) {
      return Number(project.freightCharge) - feeSum;
    }

    return 0;
  }, [project, feeSum]);

  const generalSum = useMemo(() => {
    return feeSum + vatSum;
  }, [feeSum, vatSum]);

  const latestSecondPolicy = useMemo(
    () =>
      latestPolicies.find(
        (policy) => policy.group.code === ProjectPolicyCode.SecondPolicy
      ) || null,
    [latestPolicies]
  );

  if (!project || !settings) return null;

  const isInactive = profile.hasAccountantRole || profile.isManager;

  const handleUpload = () => {
    uploadInvoice(project.id, { discount, file: documentForUpload });
  };

  const handleGenerateInvoice = () => {
    if (!invoiceSerial && invoiceSerialRef.current) {
      invoiceSerialRef.current.focus();

      if (!showInvoiceSerialMissing) {
        setShowInvoiceSerialMissing(true);

        setTimeout(() => {
          setShowInvoiceSerialMissing(false);
        }, 5000);
      }

      return;
    }

    if (!invoiceNumber && invoiceNumberRef.current) {
      invoiceNumberRef.current.focus();

      if (!showInvoiceNumberMissing) {
        setShowInvoiceNumberMissing(true);

        setTimeout(() => {
          setShowInvoiceNumberMissing(false);
        }, 5000);
      }

      return;
    }

    if (!bankName && bankNameRef.current) {
      bankNameRef.current.focus();

      if (!showBankNameMissing) {
        setShowBankNameMissing(true);

        setTimeout(() => {
          setShowBankNameMissing(false);
        }, 5000);
      }

      return;
    }

    if (!bankSwift && bankSwiftRef.current) {
      bankSwiftRef.current.focus();

      if (!showBankSwiftMissing) {
        setShowBankSwiftMissing(true);

        setTimeout(() => {
          setShowBankSwiftMissing(false);
        }, 5000);
      }

      return;
    }

    if (!bankIban && bankIbanRef.current) {
      bankIbanRef.current.focus();

      if (!showBankIbanMissing) {
        setShowBankIbanMissing(true);

        setTimeout(() => {
          setShowBankIbanMissing(false);
        }, 5000);
      }

      return;
    }

    generateInvoice(project.id, {
      serial: invoiceSerial,
      number: invoiceNumber,
      discount,
      bankName,
      bankIban,
      bankSwift,
    });
  };

  return (
    <>
      <Modal
        show={showEntryModal}
        size="xl"
        scrollable
        backdrop="static"
        className="quick-pay-entry-modal"
        onHide={close}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FaStopwatch /> {t("quickPayment")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "hidden" }}>
          <Row className="percents-row">
            <div className="radios-wrapper">
              <Col>
                <Form.Check type="radio" id={`radio-1`}>
                  <Form.Check.Input
                    value={Discount.Five}
                    name="discount"
                    type="radio"
                    isStatic
                    onChange={(e: any) => setDiscount(e.target.value)}
                    checked={discount === Discount.Five}
                  />
                  <Form.Check.Label>3 d.</Form.Check.Label>
                  <Form.Text>
                    {t("discount")} {Discount.Five}%
                  </Form.Text>
                </Form.Check>
              </Col>
              <Col>
                <Form.Check type="radio" id={`radio-2`}>
                  <Form.Check.Input
                    value={Discount.Four}
                    name="discount"
                    type="radio"
                    isStatic
                    onChange={(e: any) => setDiscount(e.target.value)}
                    checked={discount === Discount.Four}
                  />
                  <Form.Check.Label>7 d.</Form.Check.Label>
                  <Form.Text>
                    {t("discount")} {Discount.Four}%
                  </Form.Text>
                </Form.Check>
              </Col>
              <Col>
                <Form.Check type="radio" id={`radio-3`}>
                  <Form.Check.Input
                    value={Discount.Three}
                    name="discount"
                    type="radio"
                    isStatic
                    onChange={(e: any) => setDiscount(e.target.value)}
                    checked={discount === Discount.Three}
                  />
                  <Form.Check.Label>14 d.</Form.Check.Label>
                  <Form.Text>
                    {t("discount")} {Discount.Three}%
                  </Form.Text>
                </Form.Check>
              </Col>
            </div>
          </Row>

          <Row className="order-row">
            <Col>
              <div>{t("number")}</div>
              <div>1.</div>
            </Col>
            <Col>
              <div>{t("order")}</div>
              <div>{project.axCode}</div>
            </Col>
            <Col>
              <div>{t("freight")}</div>
              <div>
                {project.freightCharge
                  ? `${Number(project.freightCharge).toFixed(2)} ${
                      project.freightChargeCurrency
                    }`
                  : "-"}
              </div>
            </Col>
            <Col>
              <div>{t("paymentUntil")}</div>
              <div>
                {project.paymentUntil
                  ? formatUTCDate(project.paymentUntil)
                  : "-"}
              </div>
            </Col>
            <Col>
              <div>{t("discount")}</div>
              <div>{discount}%</div>
            </Col>
            <Col className="faded-column">
              <div>{t("newPaymentDay")}</div>
              <div>{newPaymentDay}*</div>
            </Col>
            <Col className="faded-column">
              <div>{t("newFreight")}</div>
              <div>
                {newFreight.toFixed(2)} {project.freightChargeCurrency}
              </div>
            </Col>
          </Row>

          <Row className="info-row">
            <Col>
              <p>
                <strong>
                  {t("paymentWillBeDone")} {newPaymentDay}.
                </strong>
              </p>
              <p className="conditions">
                <ul>
                  <li>{t("ifPaymentDaySaturday")}</li>
                  <li>{t("ifPaymentDaySunday")}</li>
                  <li>{t("ifPaymentDayHoliday")}</li>
                </ul>
              </p>
            </Col>
          </Row>

          <Row style={{ marginBottom: "1rem" }}>
            <Col>{t("invoiceWillBeSentToEmail")}</Col>
          </Row>

          {latestPolicies.length > 0 && (
            <Row>
              <Col>
                <Form.Check
                  checked={confirmedPolicies}
                  onChange={(event) =>
                    setConfirmedPolicies(event.target.checked)
                  }
                  name="latestPolicyConsent"
                  type="checkbox"
                  label={
                    <Link
                      onClick={() => setShowAdditionalInfoModal(true)}
                      to="#"
                    >
                      {t("iAgreeWithLatestAdditionalInfoPolicy")}
                    </Link>
                  }
                  disabled={isInactive}
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={close}>
            {t("close")}
          </Button>
          <ButtonLoader
            disabled={isLatestPoliciesLoading}
            buttonDisabled={isInactive || !confirmedPolicies}
            onClick={() => setShowConfirmationModal(true)}
          >
            {t("nextStep")}
          </ButtonLoader>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showConfirmationModal}
        backdrop="static"
        className="quick-pay-option-modal"
        onHide={close}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FaQuestionCircle /> {t("generateInvoice")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "hidden" }}>
          <span>{t("youCanUploadOrGenerateInvoice")}</span>
          <Alert variant="warning" style={{ margin: "1rem 0rem 0rem 0rem" }}>
            <span
              dangerouslySetInnerHTML={{
                __html: t("ifYouChooseUploadInvoice").replace(
                  ":companyName",
                  project.clientCompany.name
                ) as string,
              }}
            />
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUploadModal(true)}>
            {t("uploadMy")}
          </Button>
          <Button
            disabled={!project.vatGroup}
            onClick={() => setShowInvoiceModal(true)}
          >
            {t("previewInvoice")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showInvoiceModal}
        size="xl"
        scrollable
        backdrop="static"
        className="quick-pay-invoice-modal"
        onHide={close}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{t("creditInvoice")}</span>
            <span>
              {t("date")} {moment().format("YYYY-MM-DD")}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "hidden" }}>
          <Row className="meta-data-row">
            <div>
              <Col>
                <Form.Group controlId="serialNumber">
                  <Form.Label>{t("invoiceSerialNumber")}</Form.Label>
                  <Form.Control
                    ref={invoiceSerialRef}
                    type="text"
                    size="sm"
                    value={invoiceSerial}
                    maxLength={255}
                    onChange={(e) => setInvoiceSerial(e.target.value)}
                  />
                  <Overlay
                    target={invoiceSerialRef.current}
                    show={showInvoiceSerialMissing}
                    placement="top"
                  >
                    {(props) => (
                      <Tooltip id="invoice-serial-missing-tooltip" {...props}>
                        {t("dataIsMissing")}
                      </Tooltip>
                    )}
                  </Overlay>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="number">
                  <Form.Label>{t("invoiceNumber")}</Form.Label>
                  <Form.Control
                    ref={invoiceNumberRef}
                    type="text"
                    size="sm"
                    value={invoiceNumber}
                    maxLength={255}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                  />
                  <Overlay
                    target={invoiceNumberRef.current}
                    show={showInvoiceNumberMissing}
                    placement="top"
                  >
                    {(props) => (
                      <Tooltip id="invoice-number-missing-tooltip" {...props}>
                        {t("dataIsMissing")}
                      </Tooltip>
                    )}
                  </Overlay>
                </Form.Group>
              </Col>
            </div>
          </Row>
          <Row className="supplier-customer-header-row">
            <Col>{t("supplier")}</Col>
            <Col>{t("customer")}</Col>
          </Row>
          <Row className="supplier-customer-row">
            <Col>
              <div>
                <span>{t("name")}:</span>{" "}
                <span>{project.company.name || "-"}</span>
              </div>
              <div>
                <span>{t("companyCode")}:</span>{" "}
                <span>{project.company.code || "-"}</span>
              </div>
              <div>
                <span>{t("vatCode")}:</span>{" "}
                <span>{project.company.vatCode || "-"}</span>
              </div>
              <div>
                <span>{t("address")}:</span>{" "}
                <span>{project.company.address || "-"}</span>
              </div>
              <div className="bank-input-div">
                <span>{t("bankName")}:</span>{" "}
                <Form.Control
                  ref={bankNameRef}
                  type="text"
                  size="sm"
                  value={bankName}
                  maxLength={255}
                  onChange={(e) => setBankName(e.target.value)}
                />
                <Overlay
                  target={bankNameRef.current}
                  show={showBankNameMissing}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="bank-name-missing-tooltip" {...props}>
                      {t("dataIsMissing")}
                    </Tooltip>
                  )}
                </Overlay>
              </div>
              <div className="bank-input-div">
                <span>{t("swift")}:</span>{" "}
                <Form.Control
                  ref={bankSwiftRef}
                  type="text"
                  size="sm"
                  value={bankSwift}
                  maxLength={255}
                  onChange={(e) => setBankSwift(e.target.value)}
                />
                <Overlay
                  target={bankSwiftRef.current}
                  show={showBankSwiftMissing}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="bank-swift-missing-tooltip" {...props}>
                      {t("dataIsMissing")}
                    </Tooltip>
                  )}
                </Overlay>
              </div>
              <div className="bank-input-div">
                <span>{t("iban")}:</span>{" "}
                <Form.Control
                  ref={bankIbanRef}
                  type="text"
                  size="sm"
                  value={bankIban}
                  maxLength={255}
                  onChange={(e) => setBankIban(e.target.value)}
                />
                <Overlay
                  target={bankIbanRef.current}
                  show={showBankIbanMissing}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="bank-iban-missing-tooltip" {...props}>
                      {t("dataIsMissing")}
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </Col>
            <Col>
              <div>
                <span>{t("name")}:</span>{" "}
                <span>{project.clientCompany.name}</span>
              </div>
              <div>
                <span>{t("companyCode")}:</span>{" "}
                <span>{project.clientCompany.code}</span>
              </div>
              <div>
                <span>{t("vatCode")}:</span>{" "}
                <span> {project.clientCompany.vat_code}</span>
              </div>
              <div>
                <span>{t("address")}:</span>{" "}
                <span>
                  {[
                    project.clientCompany.address,
                    project.clientCompany.city,
                    project.clientCompany.post_code,
                    project.clientCompany.country,
                  ]
                    .filter((data) => !!data)
                    .join(", ")}
                </span>
              </div>
            </Col>
          </Row>

          <Row className="order-row">
            <Col>
              <Table responsive>
                <thead>
                  <tr>
                    <th>{t("nameOfService")}</th>
                    <th>{t("unit")}</th>
                    <th>{t("amount")}</th>
                    <th>{t("discount")}</th>
                    <th>{t("vat")} %</th>
                    <th>{t("price")} EUR</th>
                    <th>{t("sum")} EUR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {t("quickPayment")} {project.axCode}
                    </td>
                    <td>{t("transportation")}</td>
                    <td>1</td>
                    <td>
                      <Form.Control
                        as="select"
                        defaultValue={discount}
                        onChange={(e: any) => setDiscount(e.target.value)}
                      >
                        <option value={Discount.Five}>
                          {Discount.Five}% (3 d.)
                        </option>
                        <option value={Discount.Four}>
                          {Discount.Four}% (7 d.)
                        </option>
                        <option value={Discount.Three}>
                          {Discount.Three}% (14 d.)
                        </option>
                      </Form.Control>
                    </td>
                    <td>
                      {project.vatGroup ? `${project.vatGroup.value}` : "-"}
                    </td>
                    <td>{feeSum.toFixed(2)}</td>
                    <td>{feeSum.toFixed(2)}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="sum-up-row">
            <Col>
              <div>
                <div>
                  <div>
                    <span>{t("total")} EUR</span>
                    <span>{feeSum.toFixed(2)}</span>
                  </div>
                  <div>
                    <span>{t("vat")} EUR</span>
                    <span>{vatSum.toFixed(2)}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span>{t("generalSum")} EUR</span>
                    <span>{generalSum.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="bottom-data-row">
            <Col>
              <p>
                {project.company.country === "LT"
                  ? t("transportServiceTax")
                  : t("foreignSupplierInvoice")}
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={close}>
            {t("close")}
          </Button>
          <ButtonLoader disabled={isLoading} onClick={handleGenerateInvoice}>
            {t("generate")}
          </ButtonLoader>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showUploadModal}
        backdrop="static"
        className="quick-pay-upload-modal"
        size={showUploadPreview ? "xl" : undefined}
        onHide={close}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FaCloudUploadAlt /> {t("uploadInvoice")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "hidden" }}>
          <Row>
            <Col className="dropzone-place">
              {documentForUpload ? (
                <div className="dropzone-list">
                  {documentForUpload.name} {formatBytes(documentForUpload.size)}
                  <FaTrash onClick={() => setUploadDocument(undefined)} />
                </div>
              ) : (
                <FileDropzone
                  onDropAccepted={(files) => setUploadDocument(files[0])}
                  maxSize={settings.general.maxUploadSize * 1000}
                />
              )}
            </Col>
            {!!(documentForUpload && showUploadPreview) && (
              <Col>
                <PDFObject
                  url={URL.createObjectURL(documentForUpload)}
                  containerId="pdf-reader"
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close} variant="outline-secondary">
            {t("close")}
          </Button>
          <ButtonLoader
            disabled={isLoading}
            buttonDisabled={!documentForUpload}
            onClick={handleUpload}
          >
            {t("upload")}
          </ButtonLoader>
        </Modal.Footer>
      </Modal>

      {latestSecondPolicy && (
        <AdditionalInformationModal
          policy={latestSecondPolicy}
          visible={showAdditionalInfoModal}
          onHide={() => setShowAdditionalInfoModal(false)}
        />
      )}
    </>
  );
};

export { QuickPayModal };
