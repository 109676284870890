import { FC, useCallback } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { EDIClient } from "../../../../../services/api/admin/files";
import { adminFiles } from "../../store/slices";

const ClientFilter: FC = () => {
  const dispatch = useDispatch();

  const setFilter = useCallback(
    (filter: string) => dispatch(adminFiles.actions.setClient(filter)),
    [dispatch]
  );

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setFilter(e.target.value);
    } else {
      setFilter("");
    }
  };

  return (
    <div className="action-filter">
      <Form.Control
        onChange={handleSelect}
        as="select"
        size="sm"
        className="action-filter"
      >
        <option value="">All clients</option>
        {Object.values(EDIClient).map((value, index) => (
          <option key={index}>{value}</option>
        ))}
      </Form.Control>
    </div>
  );
};

export { ClientFilter };
