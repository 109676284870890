import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CarrierRoute } from "../../../constants/routes";
import { Loader } from "../../common/loaders/Loader";
import { applications } from "../store/slices";
import {
  IShortApplicationForm,
  ExistingPartnerForm,
} from "./ExistingPartnerForm";
import { replaceNullsToStrings } from "../utils/mappers";

interface IProps {
  reviewMode?: boolean;
}

const ExistingPartnerApplication: FC<IProps> = ({ reviewMode }) => {
  const { applicationId: id } = useParams<{ applicationId: string }>();

  const history = useHistory();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.loader.isLoading);
  const data = useSelector((state) => state.applications.data);
  const dataSaved = useSelector((state) => state.applications.dataSaved);
  const documentsFetching = useSelector(
    (state) => state.applications.documentsFetching
  );

  const fetchApplication = useCallback(
    (id: string) => dispatch(applications.actions.fetchApplication(id)),
    [dispatch]
  );
  const store = useCallback(
    (data: IShortApplicationForm) =>
      dispatch(applications.actions.storeExistingPartner({ id, data })),
    [id, dispatch]
  );
  const updateDraft = useCallback(
    (data: IShortApplicationForm, silent: boolean = false) =>
      dispatch(
        applications.actions.updateExistingPartnerDraft({ id, data, silent })
      ),
    [id, dispatch]
  );
  const reset = useCallback(() => dispatch(applications.actions.resetData()), [
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  useEffect(() => {
    if (!data && id) {
      fetchApplication(id);
    }
  }, [data, id, fetchApplication]);

  useEffect(() => {
    if (dataSaved) {
      history.push(CarrierRoute.Applications.List);
    }
  }, [dataSaved, history]);

  if (!data || isLoading) {
    return <Loader />;
  }

  return (
    <ExistingPartnerForm
      data={replaceNullsToStrings(data)}
      onSubmit={store}
      onUpdateDraft={updateDraft}
      reviewMode={reviewMode}
      documentsFetching={documentsFetching}
    />
  );
};

export { ExistingPartnerApplication };
