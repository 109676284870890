import { createSlice } from "@reduxjs/toolkit";
import { IMaintenance } from "../../../services/api/maintenance";

export interface IMaintenanceState {
  data: IMaintenance[];
  alertVisible: boolean;
  pageVisible: boolean;
}

const initialState: IMaintenanceState = {
  data: [],
  alertVisible: false,
  pageVisible: false,
};

const maintenance = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    showAlert: (state, action) => {
      state.alertVisible = action.payload;
    },
    showPage: (state, action) => {
      state.pageVisible = action.payload;
    },
  },
});

export { maintenance };
