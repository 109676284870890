import { FC } from "react";

interface IProps {
  collapsed: boolean;
  onClick: () => void;
}

const SidebarOverlay: FC<IProps> = ({ collapsed, onClick }) => {
  if (collapsed) {
    return null;
  }

  return <div className="sidebar-overlay" onClick={onClick}></div>;
};

export { SidebarOverlay };
