import { FC, useCallback, useEffect, useMemo } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, ErrorMessage, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { showUserEmail } from "../utils/helpers";
import { Option } from "react-select/src/filters";
import { AdminRoute } from "../../../../constants/routes";
import { Loader } from "../../../common/loaders/Loader";
import { CompanyRole, ICompanyShort } from "../../../../services/api/profile";
import { SelectSearch } from "../../../common/select/SelectSearch";
import { adminUsers } from "../store/slices";
import { Header } from "../../../layout/Header";

interface IUserCompanyAssignForm {
  company: Option;
  roleId: string;
}

const UserCompanyAssign: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const user = useSelector((state) => state.adminUsers.userData);
  const updatedUser = useSelector((state) => state.adminUsers.updatedUser);
  const companies = useSelector((state) => state.adminUsers.companiesShort);
  const roles = useSelector((state) => state.adminUsers.roles);

  const history = useHistory();

  const { register, handleSubmit, control, errors, watch } = useForm<
    IUserCompanyAssignForm
  >();

  const dispatch = useDispatch();
  const fetchCompanies = useCallback(
    (userId: string) =>
      dispatch(adminUsers.actions.fetchCompanyShort({ userId })),
    [dispatch]
  );
  const assignCompany = useCallback(
    (companyId: string, roleId: string) =>
      dispatch(adminUsers.actions.assignCompany({ userId, companyId, roleId })),
    [dispatch, userId]
  );
  const fetchCompanyRoles = useCallback(
    (userId: string) =>
      dispatch(adminUsers.actions.fetchCompanyRoles({ userId })),
    [dispatch]
  );
  const resetUpdatedUser = useCallback(
    () => dispatch(adminUsers.actions.resetUpdatedUser()),
    [dispatch]
  );
  const resetCompanies = useCallback(
    () => dispatch(adminUsers.actions.resetCompaniesShort()),
    [dispatch]
  );
  const resetRoles = useCallback(
    () => dispatch(adminUsers.actions.resetCompanyRoles()),
    [dispatch]
  );

  const onSubmit = ({ company, roleId }: IUserCompanyAssignForm) => {
    assignCompany(company.value, roleId);
  };

  const userEmail = useMemo(() => showUserEmail(user), [user]);

  useEffect(() => {
    fetchCompanies(userId);
    fetchCompanyRoles(userId);

    return () => {
      resetUpdatedUser();
      resetCompanies();
      resetRoles();
    };
  }, [
    userId,
    fetchCompanies,
    resetUpdatedUser,
    resetCompanies,
    fetchCompanyRoles,
    resetRoles,
  ]);

  useEffect(() => {
    if (updatedUser) {
      history.push(AdminRoute.Users.Companies.List.replace(":userId", userId));
    }
  }, [updatedUser, userId, history]);

  const selectedRoleId = watch("roleId");

  const selectedRole = useMemo(() => {
    if (roles) {
      const role = roles.find((role) => role.id.toString() === selectedRoleId);

      if (role) {
        return role.code;
      }
    }

    return null;
  }, [roles, selectedRoleId]);

  return (
    <Container fluid className="user-role">
      <Row>
        <Header title="User company" subTitle={userEmail} languageDisabled />
      </Row>
      <Row>
        <p>Choose company and role:</p>
      </Row>
      <Row>
        {!user || roles.length === 0 ? (
          <Loader />
        ) : (
          <Form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Form.Group as={Col} controlId="company">
                <Controller
                  name="company"
                  as={SelectSearch}
                  options={(companies as ICompanyShort[])
                    .filter((company) => !!company.name)
                    .map((company) => ({
                      label: `${company.name} ${
                        company.axCode ? `${company.axCode}` : ""
                      }`,
                      value: company.id,
                    }))}
                  control={control}
                  rules={{ required: true }}
                />
                <Form.Text className="text-danger">
                  <ErrorMessage
                    errors={errors}
                    name="company"
                    message="This is required"
                  />
                </Form.Text>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} controlId="company">
                <Form.Control
                  as="select"
                  name="roleId"
                  ref={register({ required: true })}
                  style={{ width: "200px" }}
                >
                  {roles.map((role, index) => (
                    <option key={index} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Control>
                {(selectedRole as any) === CompanyRole.Accountant && (
                  <Form.Text className="text-danger">
                    Viewer role has read-only permissions.
                  </Form.Text>
                )}
              </Form.Group>
            </Row>

            <Button
              type="submit"
              style={{
                minWidth: "100px",
              }}
            >
              Assign
            </Button>
          </Form>
        )}
      </Row>
    </Container>
  );
};

export { UserCompanyAssign };
