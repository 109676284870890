import { FC, useCallback, useEffect } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UsersTable } from "./table/UsersTable";
import queryString from "query-string";
import { Link, useLocation } from "react-router-dom";
import { adminUsers } from "./store/slices";
import { Header } from "../../layout/Header";
import { SubHeader } from "../../layout/SubHeader";
import { AdminRoute } from "../../../constants/routes";
import { StatusFilter } from "../../common/status-filter/StatusFilter";
import { CompanyRole, Role } from "../../../services/api/profile";

const Users: FC = () => {
  const { search } = useLocation();

  const params = queryString.parse(search);

  const searchQuery = useSelector((state) => state.adminUsers.userSearch);
  const usersRole = useSelector((state) => state.adminUsers.usersRole);
  const usersCompanyRole = useSelector(
    (state) => state.adminUsers.usersCompanyRole
  );

  const dispatch = useDispatch();

  const handleSearch = useCallback(
    (query: string) => dispatch(adminUsers.actions.setUserSearch(query)),
    [dispatch]
  );

  const setUsersRole = useCallback(
    (role: any) => dispatch(adminUsers.actions.setUsersRole(role)),
    [dispatch]
  );

  const setUsersCompanyRole = useCallback(
    (role: any) => dispatch(adminUsers.actions.setUsersCompanyRole(role)),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(adminUsers.actions.reset()), [
    dispatch,
  ]);

  const handleRoleFilter = (selectedRole: Role | string) => {
    if (selectedRole === usersRole) {
      setUsersRole(null);
    } else {
      setUsersRole(selectedRole);
    }
  };

  const handleCompanyRoleFilter = (selectedRole: CompanyRole | string) => {
    if (selectedRole === usersCompanyRole) {
      setUsersCompanyRole(null);
    } else {
      setUsersCompanyRole(selectedRole);
    }
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Container className="users-container" fluid>
      <Row>
        <Header
          title="Users"
          handleSearch={handleSearch}
          initialSearch={(params.search as string) || searchQuery}
          languageDisabled
        />
        <SubHeader
          left={
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <StatusFilter
                statuses={Object.entries(Role).map(([name, code]) => ({
                  name,
                  code,
                }))}
                activeStatus={usersRole}
                handleClick={handleRoleFilter}
              />
              {usersRole === Role.Carrier && (
                <StatusFilter
                  statuses={Object.entries(CompanyRole).map(([name, code]) => ({
                    name:
                      code === CompanyRole.Owner
                        ? "Admin"
                        : code === CompanyRole.Manager
                        ? "Editor"
                        : code === CompanyRole.Accountant
                        ? "Viewer"
                        : "",
                    code,
                  }))}
                  activeStatus={usersCompanyRole}
                  className="company-role-filter"
                  handleClick={handleCompanyRoleFilter}
                />
              )}
            </div>
          }
          right={
            <Button
              as={Link}
              to={AdminRoute.Users.Invite}
              style={{
                minWidth: "100px",
              }}
              className="assign-company-button"
            >
              Invite
            </Button>
          }
        />
      </Row>
      <Row>
        <UsersTable />
      </Row>
    </Container>
  );
};

export { Users };
