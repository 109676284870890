import { FC, useCallback, useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { alert } from "./store/slices";

interface IToastData {
  title: string;
  className: string;
}

const Toaster: FC = () => {
  const { t } = useTranslation("toaster");

  const [hideQueue, setHideQueue] = useState<string[]>([]);

  const queue = useSelector((state) => state.alert.queue);

  const dispatch = useDispatch();

  const hide = useCallback((id: string) => dispatch(alert.actions.hide(id)), [
    dispatch,
  ]);

  const getToastData = useCallback(
    (variant: string): IToastData | null => {
      let toastData: IToastData | null = null;

      switch (variant) {
        case "success":
          toastData = {
            title: t("success"),
            className: "toast-success",
          };
          break;
        case "danger":
          toastData = {
            title: t("error"),
            className: "toast-danger",
          };
          break;
        case "info":
          toastData = {
            title: t("info"),
            className: "toast-info",
          };
          break;
        case "warning":
          toastData = {
            title: t("warning"),
            className: "toast-warning",
          };
          break;
      }

      return toastData;
    },
    [t]
  );

  useEffect(() => {
    if (!!queue.length) {
      queue.forEach(({ id }) => {
        if (!hideQueue.includes(id)) {
          setTimeout(() => {
            hide(id);
          }, 8000);

          setHideQueue(hideQueue.concat(id));
        }
      });
    }
  }, [queue, hideQueue, hide]);

  return (
    <div className="toaster">
      {queue.map(({ id, variant, message }, index) => {
        if (!variant) {
          return null;
        }

        const toastData = getToastData(variant);

        if (!toastData) {
          return null;
        }

        const { className, title } = toastData;

        return (
          <Toast key={index} onClose={() => hide(id)}>
            <Toast.Header className={className}>
              <strong className="mr-auto">{title}</strong>
              <small>{t("justNow")}</small>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        );
      })}
    </div>
  );
};

export { Toaster };
