import moment from "moment-timezone";

const formatUnixDate = (date: number | null): string => {
  if (date === null) {
    return "";
  }

  return moment.utc(date, "X").format("YYYY-MM-DD");
};

const formatUnixTime = (time: number | null): string => {
  if (time === null) {
    return "";
  }

  return moment.utc(time, "X").format("HH:mm");
};

const formatUnixDateTime = (dateTime: number | null): string => {
  if (dateTime === null) {
    return "";
  }

  return moment.utc(dateTime, "X").format("YYYY-MM-DD HH:mm");
};

const formatUnixDateTimeWithTimezone = (dateTime: number | null): string => {
  if (dateTime === null) {
    return "";
  }

  return moment(dateTime, "X").format("YYYY-MM-DD HH:mm");
};

/**
 * ISO to timezone friendly date
 *
 * 2019-08-29T08:00:00.000000Z ====> 2019-08-29
 */
const formatDate = (dateTime: string | null): string => {
  if (dateTime === null) {
    return "";
  }

  return moment(dateTime).format("YYYY-MM-DD");
};

/**
 * ISO to timezone friendly date time
 *
 * 2019-08-29T08:00:00.000000Z ====> 2019-08-29 10:00
 */
const formatDateTime = (dateTime: string | null): string => {
  if (dateTime === null) {
    return "";
  }

  return moment(dateTime).format("YYYY-MM-DD HH:mm");
};

/**
 * ISO to UTC date time
 *
 * 2019-08-29T08:00:00.000000Z ====> 2019-08-29 08:00
 */
const formatUTCDateTime = (dateTime: string | null): string => {
  if (dateTime === null) {
    return "";
  }

  return moment.utc(dateTime).format("YYYY-MM-DD HH:mm");
};

/**
 * ISO to UTC date
 *
 * 2019-08-29T08:00:00.000000Z ====> 2019-08-29
 */
const formatUTCDate = (dateTime: string | null): string => {
  if (dateTime === null) {
    return "";
  }

  return moment.utc(dateTime).format("YYYY-MM-DD");
};

/**
 * ISO to UTC time
 *
 * 2019-08-29T08:00:00.000000Z ====> 08:00
 */
const formatUTCTime = (dateTime: string | null): string => {
  if (dateTime === null) {
    return "";
  }

  return moment.utc(dateTime).format("HH:mm");
};

export {
  formatUnixDate,
  formatUnixTime,
  formatUnixDateTime,
  formatUnixDateTimeWithTimezone,
  formatDateTime,
  formatDate,
  formatUTCDateTime,
  formatUTCDate,
  formatUTCTime,
};
