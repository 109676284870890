import { NotificationProps } from "../types";
import styles from "../Notification.module.scss";
import { useTranslation } from "react-i18next";
import { FaRegBuilding } from "react-icons/fa";
import { formatDate, formatDateTime } from "../../../utils/date-format";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CarrierRoute } from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { profile } from "../../profile/store/slices";
import { AppModule } from "../../../services/api/settings";
import { projectList } from "../../projects/list/store/slices";

export const NotificationItem = ({ notification }: NotificationProps) => {
  const { t } = useTranslation("notifications");
  const history = useHistory();
  const dispatch = useDispatch();

  const { is_read, company, type, ax_code, message_key, date } = notification;

  const profileState = useSelector((state) => state.profile);

  const redirect = useCallback(() => {
    if (notification.model_type === "App\\Project") {
      dispatch(projectList.actions.resetFilters());

      const route =
        CarrierRoute.Orders.List.replace(
          ":orderId?",
          notification.model_id.toString()
        ) + `?search=${notification.ax_code}`;

      history.push(route);

      if (notification.company?.id !== profileState.data?.selectedCompany?.id) {
        dispatch(
          profile.actions.changeCompany({
            id: notification.company?.id,
            appModule: AppModule.Projects,
          })
        );
      }
    }

    if (notification.model_type === "App\\Application") {
      history.push(CarrierRoute.Applications.List);
    }
  }, [dispatch, notification, history, profileState]);

  return (
    <>
      <div
        className={styles.notificationItem}
        style={{ borderLeft: is_read ? "none" : "3px solid #3EB073" }}
        onClick={redirect}
      >
        <div className={styles.notificationHeader}>
          <div className={styles.notificationTitle}>
            {company && (
              <span className={styles.companyName}>
                <FaRegBuilding />
                {company.name}
              </span>
            )}
            <span
              className={styles.notificationType}
              style={{ fontWeight: is_read ? "initial" : "800" }}
            >
              {t(type)}
            </span>
            <span className={styles.axCode}>{ax_code}</span>
          </div>
          <div className={styles.notificationBody}>{t(message_key)}</div>
        </div>
        <div className={styles.notificationDate}>
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id="tooltip">{formatDateTime(date)}</Tooltip>}
          >
            <span>{formatDate(date)}</span>
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
};
