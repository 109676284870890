import { FC } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Header } from "../../layout/Header";

const Logs: FC = () => {
  return (
    <Container fluid>
      <Row>
        <Header title="Logs" languageDisabled />
      </Row>
      <Row className="site-cache">
        <span>
          To access system logs dashboard user must be logged in through azure
          gates.
        </span>
        <Button
          as={Link}
          className="cache-button"
          target="_blank"
          to="/logs"
          style={{ width: "180px" }}
        >
          Open dashboard
        </Button>
      </Row>
    </Container>
  );
};

export { Logs };
