import { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { registration } from "./store/slices";
import queryString from "query-string";

export interface IRegisterData {
  email: string;
  name: string;
  surname: string;
  phone: string;
  password: string;
  password_confirmation: string;
}

const Verify: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const { search } = useLocation();

  const history = useHistory();

  const dispatch = useDispatch();

  const verify = useCallback(() => {
    const parameters = queryString.parse(search);

    return dispatch(
      registration.actions.verify({
        userId,
        parameters,
        history,
      })
    );
  }, [dispatch, search, userId, history]);

  useEffect(() => {
    verify();
  }, [verify]);

  return null;
};

export { Verify };
