import { fork } from "typed-redux-saga";
import { adminApplicationSagas } from "../components/admin/applications/store/sagas";
import { adminActivityLogSagas } from "../components/admin/activity-log/store/sagas";
import { adminCompaniesSagas } from "../components/admin/companies/store/sagas";
import { adminDocumentSagas } from "../components/admin/documents/store/sagas";
import { adminProjectSagas } from "../components/admin/projects/store/sagas";
import { adminUserSagas } from "../components/admin/users/store/sagas";
import { adminUsersCompaniesSaga } from "../components/admin/users/store/sagas/companies";
import { adminUsersCompaniesRolesSaga } from "../components/admin/users/store/sagas/companies-roles";
import { adminUsersConditionsSaga } from "../components/admin/users/store/sagas/conditions";
import { adminUsersRolesSaga } from "../components/admin/users/store/sagas/roles";
import { alertSaga } from "../components/alert/store/saga";
import { applicationSaga } from "../components/application/store/sagas";
import { forgotPasswordSaga } from "../components/authentication/forgot-password/store/saga";
import { authenticationSaga } from "../components/authentication/login/store/saga";
import { registrationSaga } from "../components/authentication/register/store/saga";
import { documentSagas } from "../components/documents/store/sagas";
import { translationSaga } from "../components/translations/store/saga";
import { notificationSettingsSaga } from "../components/notification-settings/store/saga";
import { policySagas } from "../components/policies/store/sagas";
import { profileSaga } from "../components/profile/store/sagas";
import { confirmedProjectSaga } from "../components/projects/list/store/sagas/confirmed";
import { projectShowSaga } from "../components/projects/show/store/saga";
import { settingsSaga } from "../components/settings/store/saga";
import { userEditSaga } from "../components/users/edit/store/saga";
import { userInviteSaga } from "../components/users/invite/store/saga";
import { userListSaga } from "../components/users/list/store/saga";
import { userShowSaga } from "../components/users/show/store/saga";
import { carrierWizardSagas } from "../components/wizards/carrier/store/sagas";
import { maintenanceSagas } from "../components/maintenance/store/sagas";
import { adminMaintenanceSagas } from "../components/admin/maintenance/store/sagas";
import { adminFilesSagas } from "../components/admin/files/store/sagas";
import { waitingProjectSaga } from "../components/projects/list/store/sagas/waiting";
import { rejectedProjectSaga } from "../components/projects/list/store/sagas/rejected";
import { projectListSaga } from "../components/projects/list/store/sagas";
import { completedProjectSaga } from "../components/projects/list/store/sagas/completed";
import { quickPaySaga } from "../components/projects/quick-pay-modal/store/saga";
import { adminUserDepartmentsSaga } from "../components/admin/users/store/sagas/departments";
import { projectChangeLogSaga } from "../components/projects/change-log/store/saga";
import { adminClientCompaniesSagas } from "../components/admin/client-companies/store/sagas";
import { adminGeneralPolicySaga } from "../components/admin/general-policies/store/sagas";
import { adminProjectPolicySaga } from "../components/admin/project-policies/store/sagas";
import { notificationsSaga } from "../components/notifications/store/saga";
import { adminStatisticPolicySaga } from "../components/admin/statistic-policies/store/sagas";
import questionnaireDocumentSaga from "../components/questionnaire-modal/store/sagas/documents";
import questionnaireModalSaga from "../components/questionnaire-modal/store/sagas";

function* rootSaga() {
  yield* fork(alertSaga);
  yield* fork(translationSaga);
  yield* fork(maintenanceSagas);
  yield* fork(authenticationSaga);
  yield* fork(registrationSaga);
  yield* fork(forgotPasswordSaga);

  yield* fork(settingsSaga);
  yield* fork(notificationsSaga);
  yield* fork(notificationSettingsSaga);
  yield* fork(profileSaga);

  yield* fork(initializeCarrierSagas);
  yield* fork(initializeAdminSagas);
}

function* initializeCarrierSagas() {
  yield* fork(applicationSaga);
  yield* fork(projectListSaga);
  yield* fork(confirmedProjectSaga);
  yield* fork(waitingProjectSaga);
  yield* fork(rejectedProjectSaga);
  yield* fork(completedProjectSaga);
  yield* fork(projectShowSaga);
  yield* fork(userListSaga);
  yield* fork(userShowSaga);
  yield* fork(userEditSaga);
  yield* fork(userInviteSaga);
  yield* fork(documentSagas);
  yield* fork(policySagas);
  yield* fork(carrierWizardSagas);
  yield* fork(quickPaySaga);
  yield* fork(projectChangeLogSaga);
  yield* fork(questionnaireDocumentSaga);
  yield* fork(questionnaireModalSaga);
}

function* initializeAdminSagas() {
  yield* fork(adminApplicationSagas);
  yield* fork(adminGeneralPolicySaga);
  yield* fork(adminProjectPolicySaga);
  yield* fork(adminStatisticPolicySaga);
  yield* fork(adminActivityLogSagas);
  yield* fork(adminDocumentSagas);
  yield* fork(adminProjectSagas);
  yield* fork(adminUserSagas);
  yield* fork(adminUsersRolesSaga);
  yield* fork(adminUserDepartmentsSaga);
  yield* fork(adminUsersCompaniesSaga);
  yield* fork(adminUsersCompaniesRolesSaga);
  yield* fork(adminUsersConditionsSaga);
  yield* fork(adminCompaniesSagas);
  yield* fork(adminClientCompaniesSagas);
  yield* fork(adminMaintenanceSagas);
  yield* fork(adminFilesSagas);
}

export { rootSaga };
