import { FC, useMemo, useState } from "react";
import screenshotURL from "../screenshots/users.png";
import { useTranslation } from "react-i18next";
import { IHotspotProps } from "../../../common/hotspot/Hotspot";
import { Screenshot } from "../screenshots/Screenshot";

const UserPage: FC = () => {
  const { t } = useTranslation("userPageWizard");

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const firstScreenshotHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 90, top: 181 },
        guideLines: [t("onUsersPageYouWillSee")],
      },
    ],
    [t]
  );

  const secondScreenshotHotspots: IHotspotProps[] = useMemo(
    () => [
      {
        order: 1,
        style: { left: 592, top: 90 },
        guideLines: [t("searchUsersByDesiredKeyword")],
      },
      {
        order: 2,
        style: { left: 200, top: 120 },
        guideLines: [t("filterUsersByManagementRoles")],
      },
      {
        order: 3,
        style: { right: 68, top: 120 },
        guideLines: [t("createNewUserByEnteringEmail")],
      },
      {
        order: 4,
        style: { right: 98, top: 220 },
        guideLines: [
          t("viewUserProfile"),
          t("changeUserManagementRole"),
          t("removeUser"),
        ],
      },
    ],
    [t]
  );

  return (
    <div className="module-page" style={{ opacity: isLoading ? 0 : 1 }}>
      <Screenshot
        url={screenshotURL}
        label={t("whatAbleToSee") as string}
        hotspots={firstScreenshotHotspots}
        imageLoaded={() => setIsLoading(false)}
      />

      <Screenshot
        url={screenshotURL}
        label={t("whatActionsCanTake") as string}
        hotspots={secondScreenshotHotspots}
        imageLoaded={() => setIsLoading(false)}
      />
    </div>
  );
};

export { UserPage };
