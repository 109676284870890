import { FC, useCallback } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  FaPhoneAlt,
  FaRegEnvelope,
  FaTrailer,
  FaTruck,
  FaUser,
  FaUserTie,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  IProject,
  ProjectConfirmStatus,
} from "../../../../../services/api/projects";
import { ButtonLoader } from "../../../../common/button-loader/ButtonLoader";
import { projectShow } from "../../store/slices";
import Select, { StylesConfig } from "react-select";
import { NoData } from "../../../../common/no-data/NoData";
import { Tooltip } from "../../../list/Tooltip";

const selectStyle = (isInactive: boolean): StylesConfig<any, false> => ({
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    border: `1px solid ${state.hasValue || isInactive ? "#ced4da" : "#ce181e"}`,
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${
        state.hasValue || isInactive ? "#ced4da" : "#ce181e"
      }`,
    },
    minHeight: 31,
    height: 31,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0b5669" : undefined,
    "&:hover": {
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0b5669" : "#f1f2f4",
    },
    "&:active": {
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0b5669" : "#f1f2f4",
    },
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 6px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
});

interface IProps {
  project: IProject;
}

export interface IProjectUpdateForm {
  driverFullName: string;
  driverPhone: string;
  trailerPlates: string;
  truckPlates: string;
  carrierManagerId: any;
  // ferryOfferRequested: boolean;
}

const ResponsibilitiesTab: FC<IProps> = ({ project }) => {
  const { t } = useTranslation("responsibilitiesTab");

  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile);
  const isUpdating = useSelector((state) => state.projectShow.isUpdating);
  const isManagersLoading = useSelector(
    (state) => state.projectShow.isManagersLoading
  );
  const managers = useSelector((state) => state.projectShow.managers);

  const {
    register,
    errors,
    handleSubmit,
    watch,
    control,
    triggerValidation,
  } = useForm<IProjectUpdateForm>({
    mode: "onChange",
    defaultValues: {
      driverFullName: project.driverName || "",
      driverPhone: project.driverPhone || "",
      trailerPlates: project.trailerPlates || "",
      truckPlates: project.truckPlates || "",
      carrierManagerId:
        project.archivedAt && !project.carrierManager
          ? null
          : project.carrierManager
          ? {
              label: `${project.carrierManager.name} ${project.carrierManager.surname}`,
              value: project.carrierManager.id,
            }
          : null,
    },
  });

  const carrierManagerId = watch("carrierManagerId");

  const update = useCallback(
    (data: IProjectUpdateForm) =>
      dispatch(projectShow.actions.updateProject(data)),
    [dispatch]
  );

  const fetchManagers = useCallback(
    () => dispatch(projectShow.actions.fetchManagers()),
    [dispatch]
  );

  const onSubmit = (data: IProjectUpdateForm) => {
    update({
      ...data,
      carrierManagerId: data.carrierManagerId.value,
    });
  };

  const isInactive =
    !!project.nonConfirmed ||
    !!project.archivedAt ||
    profile.hasAccountantRole ||
    profile.isManager ||
    !!(
      project.confirmStatus &&
      [ProjectConfirmStatus.Completed, ProjectConfirmStatus.Rejected].includes(
        project.confirmStatus.code
      )
    );

  useEffect(() => {
    fetchManagers();

    if (!isInactive) {
      triggerValidation();
    }
  }, [fetchManagers, triggerValidation, isInactive]);

  const selectedManager = useMemo(() => {
    if (carrierManagerId && carrierManagerId.value) {
      return (
        managers.find((manager) => carrierManagerId.value === manager.id) ||
        null
      );
    }

    return null;
  }, [managers, carrierManagerId]);

  const dataChanged = useMemo(() => {
    if (!project.carrierManager) return true;

    let carrierManagerChanged = false;

    if (project.carrierManager && carrierManagerId) {
      carrierManagerChanged =
        carrierManagerId.value !== project.carrierManager.id;
    }

    return (
      carrierManagerChanged ||
      watch("driverFullName") !== project.driverName ||
      watch("driverPhone") !== project.driverPhone ||
      watch("truckPlates") !== project.truckPlates ||
      watch("trailerPlates") !== project.trailerPlates
    );
  }, [project, watch, carrierManagerId]);

  const managerOptions = useMemo(
    () =>
      managers
        .filter((manager) => !!manager.name && !!manager.surname)
        .map((manager) => ({
          label: `${manager.name} ${manager.surname}`,
          value: manager.id,
        })),
    [managers]
  );

  const submitDisabled =
    !dataChanged ||
    !watch("driverFullName") ||
    !watch("driverPhone") ||
    !carrierManagerId;

  return (
    <div className="responsibilities-tab">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="truck-data">
          <span>
            <FaTruck /> {t("truckData")}
          </span>

          <Form.Group xs={12} sm={6} as={Col} controlId="driverFullName">
            <Form.Label>
              <FaUser /> {t("driverFullName")}
            </Form.Label>
            <Form.Control
              name="driverFullName"
              type="text"
              ref={register({ required: true })}
              isInvalid={!!errors.driverFullName}
              maxLength={190}
              size="sm"
              disabled={isInactive}
            />
          </Form.Group>

          <Form.Group xs={12} sm={6} as={Col} controlId="driverPhone">
            <Form.Label>
              <FaPhoneAlt /> {t("driverPhone")}
            </Form.Label>
            <Form.Control
              name="driverPhone"
              type="text"
              ref={register({ required: true })}
              isInvalid={!!errors.driverPhone}
              maxLength={190}
              size="sm"
              disabled={isInactive}
            />
          </Form.Group>

          <Form.Group xs={12} sm={6} as={Col} controlId="truckPlates">
            <Form.Label>
              <FaTruck /> {t("truckPlates")}
            </Form.Label>
            <Form.Control
              name="truckPlates"
              type="text"
              ref={register}
              isInvalid={!!errors.truckPlates}
              maxLength={190}
              size="sm"
              disabled
            />
          </Form.Group>

          <Form.Group xs={12} sm={6} as={Col} controlId="trailerPlates">
            <Form.Label>
              <FaTrailer /> {t("trailerPlates")}
            </Form.Label>
            <Form.Control
              name="trailerPlates"
              type="text"
              ref={register}
              isInvalid={!!errors.trailerPlates}
              maxLength={190}
              size="sm"
              disabled
            />
          </Form.Group>
        </Row>
        <Row style={{ margin: "0px" }}>
          <Col xs={12} sm={6} className="carrier-manager">
            <span>
              <FaUserTie /> {t("manager")}
            </span>

            <Form.Group controlId="carrierManagerId">
              <Controller
                as={Select as any}
                name="carrierManagerId"
                type="text"
                isInvalid={!!errors.carrierManagerId}
                maxLength={190}
                size="sm"
                value={carrierManagerId}
                options={managerOptions}
                isClearable
                styles={selectStyle(isInactive)}
                placeholder={t("search")}
                isLoading={isManagersLoading}
                control={control}
                rules={{ required: true }}
                isDisabled={isInactive}
              />
            </Form.Group>

            {!!(
              !selectedManager &&
              !!project.archivedAt &&
              !isManagersLoading
            ) && <NoData />}

            {!!(selectedManager && selectedManager.phone) && (
              <Tooltip id="phone" text={selectedManager.phone || ""}>
                <span className="detail">
                  <FaPhoneAlt /> {selectedManager.phone}
                </span>
              </Tooltip>
            )}

            {!!(selectedManager && selectedManager.email) && (
              <Tooltip id="email" text={selectedManager.email || ""}>
                <span className="detail">
                  <FaRegEnvelope /> {selectedManager.email}
                </span>
              </Tooltip>
            )}
          </Col>
          <Col xs={12} sm={6} className="manager">
            {project.manager && (
              <>
                <span>
                  <img
                    src="/images/logo-icon.svg"
                    width="40px"
                    alt="Everwest"
                    style={{
                      display: "block",
                    }}
                  />

                  <span>{project.clientCompany.name}</span>
                </span>

                <Tooltip id="phone" text={project.manager.name || ""}>
                  <span className="detail">
                    <FaUser /> {project.manager.name || "-"}
                  </span>
                </Tooltip>

                <Tooltip id="phone" text={project.manager.phone || ""}>
                  <span className="detail">
                    <FaPhoneAlt /> {project.manager.phone || "-"}
                  </span>
                </Tooltip>

                <Tooltip id="email" text={project.manager.email || ""}>
                  <span className="detail">
                    <FaRegEnvelope /> {project.manager.email || "-"}
                  </span>
                </Tooltip>
              </>
            )}
          </Col>
        </Row>
        <Row className="save-changes">
          <ButtonLoader
            type="submit"
            disabled={isUpdating}
            buttonDisabled={isInactive || submitDisabled}
          >
            {t("saveChanges")}
          </ButtonLoader>
        </Row>
      </Form>
    </div>
  );
};

export { ResponsibilitiesTab };
