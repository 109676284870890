import { FC } from "react";
import { FaFileAlt, FaInfoCircle } from "react-icons/fa";
import { IDocument, DocumentType } from "../../../../services/api/documents";
import { formatUnixDate } from "../../../../utils/date-format";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useMobileScreen from "../../../../hooks/mobile-screen";
import { DocumentStatusTooltip } from "../../../admin/documents/DocumentStatusTooltip";

interface IProps {
  document: IDocument;
  strikeThroughPaymentDay?: boolean;
  onClick: () => void;
}

const DocumentItem: FC<IProps> = ({
  document,
  strikeThroughPaymentDay,
  onClick,
}) => {
  const isMobileScreen = useMobileScreen();

  const { t } = useTranslation("documentItem");

  if (!document.status) return null;

  let name: string = document.type ? document.type.name : t("order");

  if (document.type && document.type.code === DocumentType.Invoice) {
    name = `${t("invoice")}: ${name}`;
  }

  return (
    <div className="document-item" onClick={onClick}>
      <span
        style={{ width: isMobileScreen ? "unset" : "10%" }}
        className={`doc-${document.status.code} document-icon`}
      >
        <FaFileAlt />
      </span>
      <span style={{ width: isMobileScreen ? "100%" : "45%" }}>{name}</span>
      <span
        style={{ width: isMobileScreen ? "100%" : "25%" }}
        className={`doc-${document.status.code}`}
      >
        <span className="status">
          <DocumentStatusTooltip
            name={document.status.name}
            code={document.status.code}
          />
          {document.axMessage && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="question-tooltip">{document.axMessage}</Tooltip>
              }
            >
              <FaInfoCircle />
            </OverlayTrigger>
          )}
        </span>
        {document.paymentUntil && (
          <span
            className="faded"
            style={{
              fontSize: "10px",
              textDecoration: strikeThroughPaymentDay ? "line-through" : "none",
            }}
          >
            {t("payment")}: {formatUnixDate(document.paymentUntil)}
          </span>
        )}
      </span>
      <span style={{ width: isMobileScreen ? "100%" : "20%" }}>
        {formatUnixDate(document.createdAt)}
      </span>
    </div>
  );
};

export { DocumentItem };
