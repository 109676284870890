import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IProject } from "../../../../services/api/projects";
import { NoData } from "../../../common/no-data/NoData";
import { RouteToggle } from "../map/RouteToggle";
import { projectShow } from "../store/slices";
import { TimelineItem } from "./TimelineItem";

export enum LocationType {
  Planned = "planned",
  Confirmed = "confirmed",
}

interface IProps {
  project: IProject;
}

const Timeline: FC<IProps> = ({
  project: { id, plannedLocations, confirmedLocations },
}) => {
  const dispatch = useDispatch();

  const selectedLocationId = useSelector(
    (state) => state.projectShow.selectedLocationId
  );
  const isPlannedVisible = useSelector(
    (state) => state.projectShow.isPlannedVisible
  );
  const isConfirmedVisible = useSelector(
    (state) => state.projectShow.isConfirmedVisible
  );

  const selectLocationId = useCallback(
    (id: number | null) =>
      dispatch(projectShow.actions.setSelectedLocationId(id)),
    [dispatch]
  );

  const handleItemClick = (id: number) => {
    if (id === selectedLocationId) {
      selectLocationId(null);
    } else {
      selectLocationId(id);
    }
  };

  const locations = useMemo(() => {
    const planned = isPlannedVisible ? plannedLocations : [];
    const confirmed = isConfirmedVisible ? confirmedLocations : [];

    return planned.concat(confirmed);
  }, [
    confirmedLocations,
    isConfirmedVisible,
    isPlannedVisible,
    plannedLocations,
  ]);

  let currentLocationId: number | null = null;

  if (confirmedLocations.length > 0) {
    currentLocationId = confirmedLocations[confirmedLocations.length - 1].id;
  }

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      const body = ref.current;

      body.scrollTo(0, body.scrollHeight);
    }
  }, [ref]);

  return (
    <Card className="timeline">
      <Card.Body className="timeline-body" ref={ref}>
        {locations.length === 0 && <NoData />}
        {locations.map((location, index) => (
          <TimelineItem
            key={location.id}
            location={location}
            type={
              location.isPlanned ? LocationType.Planned : LocationType.Confirmed
            }
            isActive={selectedLocationId === location.id}
            isCurrent={location.id === currentLocationId}
            onClick={() => handleItemClick(location.id)}
          />
        ))}
      </Card.Body>
      <Card.Footer>
        <RouteToggle />
      </Card.Footer>
    </Card>
  );
};

export { Timeline };
