import { FC, useCallback, useEffect } from "react";
import { Row, Container, Image, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { email as emailRegex } from "../../../constants/regex";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { forgotPassword } from "./store/slices";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../translations/LanguageSwitcher";
import { Cover } from "../../cover/Cover";
import PasswordRules from "../../password-rules/PasswordRules";

export interface IResetPasswordData {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

const ResetPassword: FC = () => {
  const { t } = useTranslation("resetPassword");
  const { token } = useParams<{ token: string }>();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { email } = queryString.parse(search);

  const isLoading = useSelector((state) => state.loader.isLoading);
  const passwordReseted = useSelector(
    (state) => state.forgotPassword.passwordReseted
  );

  const resetPassword = useCallback(
    (data: IResetPasswordData) => {
      return dispatch(forgotPassword.actions.resetPassword(data));
    },
    [dispatch]
  );

  const reset = useCallback(() => dispatch(forgotPassword.actions.reset()), [
    dispatch,
  ]);

  const { register, errors, handleSubmit, watch } = useForm<IResetPasswordData>(
    {
      defaultValues: {
        email: typeof email === "string" ? email : "",
      },
    }
  );

  const onSubmit = async (data: IResetPasswordData) => {
    resetPassword({
      ...data,
      token,
    });
  };

  useEffect(() => {
    if (passwordReseted) {
      history.push("/");
    }
  }, [passwordReseted, history]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Container className="forgot-password" fluid>
      <Cover />

      <Row className="language-row">
        <LanguageSwitcher />
      </Row>
      <Row className="form-row">
        <Col />
        <Col xs={12} md={6} lg={5} xl={3}>
          <Row>
            <Col className="logo-place">
              <Link to="/">
                <Image src="/images/logo.svg" />
              </Link>
            </Col>
          </Row>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} controlId="email">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  ref={register({ required: true, pattern: emailRegex })}
                  isInvalid={!!errors.email}
                  maxLength={190}
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.type === "required" && t("requiredField")}
                  {errors.email?.type === "pattern" && t("invalidEmal")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="password">
                <Form.Label>{t("password")}</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  ref={register({
                    required: true,
                    minLength: 8,
                    validate: {
                      containsUpper: (value) => /[A-Z]/.test(value),
                      containsLower: (value) => /[a-z]/.test(value),
                      containsDigits: (value) => /[0-9]/.test(value),
                    },
                  })}
                  isInvalid={!!errors.password}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.type === "required" && t("requiredField")}
                  {errors.password?.type === "minLength" &&
                    t("minCharsRequired")}
                  {errors.password?.type === "containsUpper" &&
                    t("oneUpperCaseRequired")}
                  {errors.password?.type === "containsLower" &&
                    t("oneLowerCaseRequired")}
                  {errors.password?.type === "containsDigits" &&
                    t("oneNumberRequired")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="password_confirmation">
                <Form.Label>{t("confirmPassword")}</Form.Label>
                <Form.Control
                  name="password_confirmation"
                  type="password"
                  ref={register({
                    required: true,
                    minLength: 8,
                    validate: (value) => value === watch("password"),
                  })}
                  isInvalid={!!errors.password_confirmation}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation?.type === "required" &&
                    t("requiredField")}
                  {errors.password_confirmation?.type === "validate" &&
                    t("passwordsDontMatch")}
                  {errors.password_confirmation?.type === "minLength" &&
                    t("minCharsRequired")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <PasswordRules />
            </Form.Row>

            <div className="register-button">
              <ButtonLoader
                variant="primary"
                type="submit"
                disabled={isLoading}
              >
                {t("reset")}
              </ButtonLoader>
            </div>
          </Form>
        </Col>
        <Col />
      </Row>
    </Container>
  );
};

export { ResetPassword };
