import { createSlice } from "@reduxjs/toolkit";

export interface IUserEditState {
  invited: boolean;
  accepted: boolean;
  expired: boolean;
}

const initialState: IUserEditState = {
  invited: false,
  accepted: false,
  expired: false,
};

const userInvite = createSlice({
  name: "userInvite",
  initialState,
  reducers: {
    inviteUser: (state, action) => {
      //
    },
    acceptInvitation: (state, action) => {
      //
    },
    setInvited: (state) => {
      state.invited = true;
    },
    setAccepted: (state) => {
      state.accepted = true;
    },
    setExpired: (state) => {
      state.expired = true;
    },
    reset: () => initialState,
  },
});

export { userInvite };
