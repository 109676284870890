import { PayloadAction } from "@reduxjs/toolkit";
import { startCase } from "lodash";
import { call, delay, put, select, takeLatest } from "typed-redux-saga";
import { adminCompaniesAPI } from "../../../../services/api/admin/companies";
import { IFetchDocumentParams } from "../../../../services/api/documents";
import { AppState } from "../../../../store";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { ICompanyForm } from "../form/CompanyForm";
import { adminCompanies } from "./slices";

function* adminCompaniesSagas() {
  yield* takeLatest(adminCompanies.actions.setSearch, debounceSearch);
  yield* takeLatest(adminCompanies.actions.setPerPage, fetchCompanies);
  yield* takeLatest(adminCompanies.actions.setActivePage, fetchCompanies);
  yield* takeLatest(adminCompanies.actions.fetchCompany, fetchCompany);
  yield* takeLatest(adminCompanies.actions.storeCompany, storeCompany);
  yield* takeLatest(adminCompanies.actions.updateCompany, updateCompany);
}

function* debounceSearch() {
  const data = yield* select(
    (state: AppState) => state.adminCompanies.companies
  );

  if (data.length > 0) {
    yield delay(500);
  }

  yield* call(fetchCompanies);
}

function* fetchCompanies() {
  yield put(loader.actions.startLoader());

  const { search, perPage, page } = yield* select((state: AppState) => ({
    search: state.adminCompanies.search,
    perPage: state.adminCompanies.perPage,
    page: state.adminCompanies.activePage,
  }));

  const requestParams: IFetchDocumentParams = {
    search,
    perPage,
    page,
  };

  const response = yield* call(adminCompaniesAPI.fetchCompanies, requestParams);

  if (response && response.data && response.meta) {
    yield put(
      adminCompanies.actions.setCompanies({
        data: response.data,
        meta: response.meta,
      })
    );
  } else {
    yield put(showError("Something went wrong while getting companies."));
  }

  yield put(loader.actions.stopLoader());
}

function* fetchCompany({ payload: companyId }: PayloadAction<string>) {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminCompaniesAPI.fetchCompany, companyId);

  if (response && response.data) {
    yield put(adminCompanies.actions.setCompany(response.data));
  } else {
    yield put(showError("Something went wrong while fetching company."));
  }

  yield put(loader.actions.stopLoader());
}

function* storeCompany({ payload: data }: PayloadAction<ICompanyForm>) {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminCompaniesAPI.storeCompany, data);

  if (response && response.data) {
    yield put(showSuccess("Company created."));

    yield put(adminCompanies.actions.setCompanyUpdated());
  } else if (response && response.errors) {
    const firstErrorKey = Object.keys(response.errors)[0];

    yield put(
      showWarning(
        `${startCase(firstErrorKey)}: ${response.errors[firstErrorKey]}`
      )
    );
  } else {
    yield put(showError("Something went wrong while storing company."));
  }

  yield put(loader.actions.stopLoader());
}

function* updateCompany({
  payload: { companyId, data },
}: PayloadAction<{ companyId: string; data: ICompanyForm }>) {
  yield put(loader.actions.startLoader());

  const response = yield* call(
    adminCompaniesAPI.updateCompany,
    companyId,
    data
  );

  if (response && response.data) {
    yield put(showSuccess("Company updated."));

    yield put(adminCompanies.actions.setCompanyUpdated());
  } else if (response && response.errors) {
    const firstErrorKey = Object.keys(response.errors)[0];

    yield put(
      showWarning(
        `${startCase(firstErrorKey)}: ${response.errors[firstErrorKey]}`
      )
    );
  } else {
    yield put(showError("Something went wrong while updating company."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminCompaniesSagas };
