import classNames from "classnames";
import { FC, useCallback, useEffect } from "react";
import { Row, Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CarrierRoute } from "../../constants/routes";
import { CompanyRole } from "../../services/api/profile";
import { AppModule } from "../../services/api/settings";
import { ListFilter } from "../common/list-filter/ListFilter";
import { StatusFilter } from "../common/status-filter/StatusFilter";
import { Header } from "../layout/Header";
import { SubHeader } from "../layout/SubHeader";
import { userList } from "./list/store/slices";
import { UserList } from "./list/UserList";

const Users: FC = () => {
  const { t } = useTranslation("userList");

  const profile = useSelector((state) => state.profile);
  const filter = useSelector((state) => state.userList.filter);
  const searchQuery = useSelector((state) => state.userList.search);
  const settings = useSelector((state) => state.settings.carrier);

  const dispatch = useDispatch();

  const setSearch = useCallback(
    (search: string) => dispatch(userList.actions.setSearch(search)),
    [dispatch]
  );

  const resetData = useCallback(() => dispatch(userList.actions.resetData()), [
    dispatch,
  ]);

  const setFilter = useCallback(
    (status: any) => dispatch(userList.actions.setFilter(status)),
    [dispatch]
  );

  const handleFilter = (status: CompanyRole | string) => {
    if (status === filter) {
      setFilter(null);
    } else {
      setFilter(status);
    }
  };

  useEffect(() => {
    return () => {
      resetData();
    };
  }, [resetData]);

  if (!profile.data) return null;

  return (
    <Container className="users-container" fluid>
      <Row>
        <Header
          title={t("users") as string}
          handleSearch={setSearch}
          initialSearch={searchQuery}
          showCompanySelect
          appModule={AppModule.Users}
        />
        <SubHeader
          left={
            settings && settings.users ? (
              <>
                <StatusFilter
                  statuses={settings.users.companyRoles}
                  activeStatus={filter}
                  className="user-filter"
                  handleClick={handleFilter}
                />
                <ListFilter>
                  <StatusFilter
                    statuses={settings.users.companyRoles}
                    activeStatus={filter}
                    className="user-filter-mobile"
                    handleClick={handleFilter}
                  />
                </ListFilter>
              </>
            ) : (
              <div />
            )
          }
          right={
            <Button
              as={Link}
              to={CarrierRoute.Users.Invite}
              className={classNames(
                "invite-button",
                (profile.hasAccountantRole || profile.hasManagerRole) &&
                  "disabled"
              )}
            >
              {t("invite")}
            </Button>
          }
        />
      </Row>
      <Row>
        <UserList />
      </Row>
    </Container>
  );
};

export { Users };
