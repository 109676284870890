import { createSlice } from "@reduxjs/toolkit";
import { Notification } from "../types";
import { IPagination } from "../../../services/api";

interface INotificationsState {
  data: Notification[];
  recent: Notification[];
  meta: IPagination;
  unreadCount: number;
  isLoading: boolean;
  isRecentLoading: boolean;
  isUnreadCountLoading: boolean;
  isUnreadCountRefreshEnabled: boolean;
}

const initialState: INotificationsState = {
  data: [],
  recent: [],
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 1,
    from: 1,
    to: 1,
    path: "",
  },
  unreadCount: 0,
  isLoading: false,
  isRecentLoading: false,
  isUnreadCountLoading: false,
  isUnreadCountRefreshEnabled: false,
};

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    fetchNotifications: (state, action) => {},
    setNotifications: (state, { payload: { data: newData, meta } }) => {
      const { data: oldData } = state;

      state.data =
        oldData && meta.current_page > 1 ? [...oldData, ...newData] : newData;
      state.meta = meta;
    },
    fetchRecentNotifications: () => {},
    setRecentNotifications: (state, action) => {
      state.recent = action.payload.data;
    },
    fetchUnreadCount: () => {},
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload;
    },
    markAsRead: (state, action) => {},
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsRecentLoading: (state, action) => {
      state.isRecentLoading = action.payload;
    },
    setIsUnreadCountLoading: (state, action) => {
      state.isUnreadCountLoading = action.payload;
    },
    enableUnreadCountRefresh: (state) => {
      state.isUnreadCountRefreshEnabled = true;
    },
    resetRecent: (state) => {
      state.recent = [];
    },
    reset: () => initialState,
  },
});

export { notifications };
