import { startCase } from "lodash";
import { BsFillCaretUpFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Column, TableOptions } from "react-table";
import { AdminRoute } from "../../../../constants/routes";
import { IApplication } from "../../../../services/api/application";
import { formatUnixDate } from "../../../../utils/date-format";
import { StatusBadge } from "./StatusBadge";

const projectListColumns: Column<TableOptions<IApplication>>[] = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "VAT Payer Code",
    accessor: "company.vatNumber",
    Cell: ({ value }) => (value ? value : <span>-</span>),
  },
  {
    Header: "Company name",
    accessor: "company.name",
    Cell: ({ value }) => (value ? value : <span>-</span>),
  },
  {
    Header: "Type",
    accessor: "type.name",
    Cell: ({ value }) => (value ? startCase(value) : <span>-</span>),
  },
  {
    Header: "Status",
    accessor: "status.code",
    Cell: ({ value }) =>
      value ? <StatusBadge status={value.toLowerCase()} /> : <span>-</span>,
  },
  {
    Header: "Submitted at",
    accessor: "submittedAt",
    Cell: ({ value }) =>
      value ? <span>{formatUnixDate(value)}</span> : <span>-</span>,
  },
  {
    Header: () => (
      <span>
        <BsFillCaretUpFill /> Updated at
      </span>
    ),
    accessor: "updatedAt",
    Cell: ({ value }) =>
      value ? <span>{formatUnixDate(value)}</span> : <span>-</span>,
  },
  {
    Header: "Created by",
    Cell: ({ cell }) => {
      const { user } = (cell.row.original as unknown) as IApplication;

      return user ? (
        <>
          <span>
            <Link to={`${AdminRoute.Users.List}?search=${user.email}`}>
              {user.email}
            </Link>
          </span>
          <span className="list-time">ID: {user.id}</span>
        </>
      ) : (
        <span>-</span>
      );
    },
  },
  {
    Header: "Documents",
    Cell: ({ row }) => {
      const application = (row.original as unknown) as IApplication;

      if (!application.documents.length) {
        return <span>-</span>;
      }

      return (
        <ul>
          {application.documents.map((document, index) => (
            <li key={index}>
              <Link
                to={AdminRoute.Documents.List + `?search=${document.fileName}`}
              >
                {document.fileName}
              </Link>
            </li>
          ))}
        </ul>
      );
    },
  },
];

export { projectListColumns };
