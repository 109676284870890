import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "../../../../services/api";

interface IActivityLogState {
  data: any[];
  search: string;
  perPage: number;
  activePage: number;
  meta: IPagination | null;
  dateFrom: string | null;
  dateTo: string | null;
}

const initialState: IActivityLogState = {
  data: [],
  search: "",
  perPage: 10,
  activePage: 1,
  meta: null,
  dateFrom: null,
  dateTo: null,
};

const adminActivityLog = createSlice({
  name: "adminActivityLog",
  initialState,
  reducers: {
    storeData: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.activePage = 1;
    },
    setDates: (state, action) => {
      if (typeof action.payload.dateFrom !== "undefined") {
        state.dateFrom = action.payload.dateFrom;
      }

      if (typeof action.payload.dateTo !== "undefined") {
        state.dateTo = action.payload.dateTo;
      }
    },
    reset: () => initialState,
  },
});

export { adminActivityLog };
