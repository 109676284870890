import { API, HTTPMethod, SuccessResponse } from "..";
import { LanguageCode } from "../../../i18n";

const testEmailTemplates = async (
  email: string,
  language: LanguageCode
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/super-admin/mailing/test-templates?email=${email}&language=${language}`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const sendTechnicalIssuesMail = async (
  userIds: string[]
): Promise<SuccessResponse> => {
  const response = await API.request(
    `/super-admin/mailing/send-issue-mail`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({ userIds })
  );

  return response;
};

const adminMailingAPI = {
  sendTechnicalIssuesMail,
  testEmailTemplates,
};

export { adminMailingAPI };
