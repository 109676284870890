import { FC, useMemo } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import useMobileScreen from "../../hooks/mobile-screen";
import { AppModule } from "../../services/api/settings";

const RoleAlert: FC = () => {
  const isMobileScreen = useMobileScreen();

  const { t } = useTranslation("roleAlert");
  const { pathname } = useLocation();

  const profile = useSelector((state) => state.profile);
  const isAdmin = profile.isAdmin;
  const isSuperAdmin = profile.isSuperAdmin;
  const isGuest = profile.isGuest;

  const text = useMemo(() => {
    if (profile.data) {
      const selectedCompany = profile.data.selectedCompany;
      const isManager = profile.isManager;
      const isGroupLeader = profile.data.roles.some((role) =>
        role.name.includes("roles.")
      );
      const isViewer = profile.hasAccountantRole;
      const isEditor = profile.hasManagerRole;

      if (isGroupLeader) {
        return t("yourAccountHasGroupLeaderRole");
      }

      if (isManager) {
        return t("yourAccountHasManagerRole");
      }

      if (selectedCompany && selectedCompany.name) {
        if (isViewer) {
          return t("yourAccountHasViewerRole").replace(
            "{company}",
            selectedCompany.name
          );
        }

        if (isEditor) {
          return t("yourAccountHasEditorRole").replace(
            "{company}",
            selectedCompany.name
          );
        }
      }
    }
  }, [t, profile]);

  const margin = useMemo(() => {
    const showCustomPageMargin = pathname.includes(AppModule.Orders);

    if (showCustomPageMargin) {
      if (isMobileScreen) {
        return `15px`;
      }

      return `15px 30px 15px 100px`;
    }

    return "0px 0px 15px 0px";
  }, [pathname, isMobileScreen]);

  if (isAdmin || isSuperAdmin || isGuest) return null;

  return (
    <Alert
      style={{ margin }}
      className="role-alert"
      variant="info"
      show={!!text}
    >
      {text}
    </Alert>
  );
};

export { RoleAlert };
