import { useCallback, useEffect, useState } from "react";
import { ListGroup, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FaCaretDown,
  FaCaretUp,
  FaHistory,
  FaMapMarkerAlt,
  FaTruckMoving,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { IProject } from "../../../services/api/projects";
import { formatDateTime, formatUTCDateTime } from "../../../utils/date-format";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { Loader } from "../../common/loaders/Loader";
import { NoData } from "../../common/no-data/NoData";
import { projectChangeLog } from "./store/slice";
import React from "react";

type Props = {
  project: IProject;
};

const ChangeLog = ({ project }: Props) => {
  const { t } = useTranslation("projectChangeLog");
  const dispatch = useDispatch();

  const [openedLogIds, setOpenedLogIds] = useState<number[]>([]);

  const data = useSelector((state) => state.projectChangeLog.data);
  const meta = useSelector((state) => state.projectChangeLog.meta);
  const isLoading = useSelector((state) => state.projectChangeLog.isLoading);

  const fetchLog = useCallback(
    (id: number, page: number) =>
      dispatch(projectChangeLog.actions.fetch({ id, page })),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(projectChangeLog.actions.reset()), [
    dispatch,
  ]);

  useEffect(() => {
    fetchLog(project.id, 1);

    return () => {
      reset();
    };
  }, [project, fetchLog, reset]);

  const getComparisonForHeader = (
    before: string | null,
    after: string | null
  ) => {
    if (before === after) {
      return null;
    }

    return (
      <div>
        <span className="before-changes">{before}</span>{" "}
        <span className="after-changes">{after}</span>
      </div>
    );
  };

  const getComparison = (before: string | null, after: string | null) => {
    if (before === after) {
      return after;
    }

    return (
      <div>
        <span className="before-changes">{before}</span>{" "}
        <span className="after-changes">{after}</span>
      </div>
    );
  };

  const getPriceComparison = (
    beforeAmount: number | null,
    beforeCurrency: string | null,
    afterAmount: number | null,
    afterCurrency: string | null
  ) => {
    const before = `${beforeAmount} ${beforeCurrency}`;
    const after = `${afterAmount} ${afterCurrency}`;

    if (before === after && afterAmount !== null) {
      return (
        <span>
          {afterAmount} {afterCurrency}
        </span>
      );
    }

    return (
      <div>
        {beforeAmount !== null && (
          <span className="before-changes">
            {beforeAmount} {beforeCurrency}
          </span>
        )}{" "}
        {afterAmount !== null && (
          <span className="after-changes">
            {afterAmount} {afterCurrency}
          </span>
        )}
      </div>
    );
  };

  const getPriceComparisonForHeader = (
    beforeAmount: number | null,
    beforeCurrency: string | null,
    afterAmount: number | null,
    afterCurrency: string | null
  ) => {
    const before = `${beforeAmount} ${beforeCurrency}`;
    const after = `${afterAmount} ${afterCurrency}`;

    if (before === after && afterAmount !== null) {
      return null;
    }

    return (
      <div>
        {beforeAmount !== null && (
          <span className="before-changes">
            {beforeAmount} {beforeCurrency}
          </span>
        )}{" "}
        {afterAmount !== null && (
          <span className="after-changes">
            {afterAmount} {afterCurrency}
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      {!!(isLoading && data.length === 0) && <Loader isLoading={isLoading} />}

      <ListGroup>
        {data.map((log, index) => {
          const isActive = openedLogIds.includes(log.id);
          const { before, data, location_data } = log;

          return (
            <React.Fragment key={index}>
              <ListGroup.Item
                action
                onClick={() =>
                  setOpenedLogIds(
                    isActive
                      ? [...openedLogIds].filter((id) => id !== log.id)
                      : [...openedLogIds, log.id]
                  )
                }
                className="change-log-item"
              >
                <div>
                  <FaHistory className="text-primary" />{" "}
                  {formatDateTime(log.created_at)}
                  {!!(before && !isActive) && (
                    <>
                      {getComparisonForHeader(
                        before.data.goods_type,
                        data.goods_type
                      )}
                      {getComparisonForHeader(
                        before.data.trailer_type,
                        data.trailer_type
                      )}
                      {getComparisonForHeader(
                        before.data.temperature,
                        data.temperature
                      )}
                      {getComparisonForHeader(
                        before.data.ref_number,
                        data.ref_number
                      )}
                      {getPriceComparisonForHeader(
                        before.data.total_allowance_charge_amount,
                        before.data.total_allowance_charge_currency,
                        data.total_allowance_charge_amount,
                        data.total_allowance_charge_currency
                      )}
                      {getPriceComparisonForHeader(
                        before.data.freight_allowance_charge_amount,
                        before.data.freight_allowance_charge_currency,
                        data.freight_allowance_charge_amount,
                        data.freight_allowance_charge_currency
                      )}
                      {getPriceComparisonForHeader(
                        before.data.extra_allowance_charge_amount,
                        before.data.extra_allowance_charge_currency,
                        data.extra_allowance_charge_amount,
                        data.extra_allowance_charge_currency
                      )}
                    </>
                  )}
                </div>
                <div>{isActive ? <FaCaretUp /> : <FaCaretDown />}</div>
              </ListGroup.Item>

              {isActive && (
                <ListGroup.Item>
                  <Table responsive className="change-log-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("loadType")}</th>
                        <th>{t("trailerType")}</th>
                        <th>{t("temperature")}</th>
                        <th>{t("refNo")}</th>
                        <th>{t("totalPrice")}</th>
                        <th>{t("freightPrice")}</th>
                        <th>{t("extraPrice")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="change-log-row-icon">
                          <FaTruckMoving />
                        </td>
                        <td>
                          {before
                            ? getComparison(
                                before.data.goods_type,
                                data.goods_type
                              )
                            : data.goods_type}
                        </td>
                        <td>
                          {before
                            ? getComparison(
                                before.data.trailer_type,
                                data.trailer_type
                              )
                            : data.trailer_type}
                        </td>
                        <td>
                          {before
                            ? getComparison(
                                before.data.temperature,
                                data.temperature
                              )
                            : data.temperature}
                        </td>
                        <td>
                          {before
                            ? getComparison(
                                before.data.ref_number,
                                data.ref_number
                              )
                            : data.ref_number}
                        </td>
                        <td>
                          {before
                            ? getPriceComparison(
                                before.data.total_allowance_charge_amount,
                                before.data.total_allowance_charge_currency,
                                data.total_allowance_charge_amount,
                                data.total_allowance_charge_currency
                              )
                            : data.total_allowance_charge_amount !== null
                            ? `${data.total_allowance_charge_amount}
                            ${data.total_allowance_charge_currency}`
                            : "-"}
                        </td>
                        <td>
                          {before
                            ? getPriceComparison(
                                before.data.freight_allowance_charge_amount,
                                before.data.freight_allowance_charge_currency,
                                data.freight_allowance_charge_amount,
                                data.freight_allowance_charge_currency
                              )
                            : data.freight_allowance_charge_amount !== null
                            ? `${data.freight_allowance_charge_amount}
                            ${data.freight_allowance_charge_currency}`
                            : "-"}
                        </td>
                        <td>
                          {before
                            ? getPriceComparison(
                                before.data.extra_allowance_charge_amount,
                                before.data.extra_allowance_charge_currency,
                                data.extra_allowance_charge_amount,
                                data.extra_allowance_charge_currency
                              )
                            : data.extra_allowance_charge_amount !== null
                            ? `${data.extra_allowance_charge_amount}
                            ${data.extra_allowance_charge_currency}`
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <br />
                  <div className="also-please-check">
                    {t("alsoPleaseCheck")}
                  </div>
                  <br />
                  <Table responsive className="change-log-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("status")}</th>
                        <th>{t("addressName")}</th>
                        <th>{t("addressLine")}</th>
                        <th>{t("postalCode")}</th>
                        <th>{t("city")}</th>
                        <th>{t("region")}</th>
                        <th>{t("country")}</th>
                        <th>{t("start")}</th>
                        <th>{t("end")}</th>
                        <th>{t("latitude")}</th>
                        <th>{t("longtitude")}</th>
                        <th>{t("locationName")}</th>
                        <th>{t("refNumber")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {location_data.map((location) => {
                        return (
                          <tr>
                            <td className="change-log-row-icon">
                              <FaMapMarkerAlt />
                            </td>
                            <td>{location.status || "-"}</td>
                            <td>{location.address_name || "-"}</td>
                            <td>{location.address_line || "-"}</td>
                            <td className="no-wrap">
                              {location.postal_code || "-"}
                            </td>
                            <td>{location.city || "-"}</td>
                            <td>{location.region || "-"}</td>
                            <td>{location.country || "-"}</td>
                            <td className="no-wrap">
                              {location.period_start
                                ? formatUTCDateTime(location.period_start)
                                : "-"}
                            </td>
                            <td className="no-wrap">
                              {location.period_end
                                ? formatUTCDateTime(location.period_end)
                                : "-"}
                            </td>
                            <td>{location.coordinates_lat || "-"}</td>
                            <td>{location.coordinates_lng || "-"}</td>
                            <td>{location.location_name || "-"}</td>
                            <td>{location.ref_number || "-"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </ListGroup.Item>
              )}
            </React.Fragment>
          );
        })}

        {!!(data.length === 0 && !isLoading) && <NoData />}

        {!!(meta && meta.current_page < meta.last_page) && (
          <div className="load-more">
            <ButtonLoader
              onClick={() => fetchLog(project.id, meta.current_page + 1)}
              variant="outline-secondary"
              size="sm"
              disabled={isLoading}
            >
              {t("loadMore")}
            </ButtonLoader>
          </div>
        )}
      </ListGroup>
    </>
  );
};

export { ChangeLog };
