import { createSlice } from "@reduxjs/toolkit";

export interface IAuthState {}

const initialState: IAuthState = {};

const authentication = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    fetchTokens: (state, action) => {
      //
    },
  },
});

export { authentication };
