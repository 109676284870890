import { FC, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { BsCalendar, BsClock } from "react-icons/bs";

const DateTimeInput: FC<any> = (props) => {
  const ref = useRef(null);

  const handleIconClick = () => {
    if (ref && ref.current) {
      const input: any = ref.current;

      input.setOpen(true);
      input.setFocus(true);
    }
  };

  return (
    <InputGroup className="datetime-input">
      <Controller
        as={
          <Form.Control as={DatePicker as any} autoComplete="off" ref={ref} />
        }
        {...props}
      />
      <InputGroup.Append>
        <InputGroup.Text
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
          }}
        >
          {props.showTimeSelectOnly ? <BsClock /> : <BsCalendar />}
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
};

export { DateTimeInput };
