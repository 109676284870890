import { FC, useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { Badge, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  FaCaretSquareDown,
  FaCaretSquareUp,
  FaFileAlt,
  FaInfoCircle,
  FaPhoneAlt,
  FaRegEnvelope,
  FaTrailer,
  FaTruck,
  FaUser,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import { LanguageCode } from "../../../i18n";
import { IProject, ProjectConfirmStatus } from "../../../services/api/projects";
import { formatUTCDate, formatUTCTime } from "../../../utils/date-format";
import { Loader } from "../../common/loaders/Loader";
import { NoData } from "../../common/no-data/NoData";
import { ChangeLogModal } from "../change-log/ChangeLogModal";
import { Tooltip } from "../list/Tooltip";
import { IProjectUpdateForm } from "./details/tabs/ResponsibilitiesTab";
import { DocumentLinksExpandable } from "./DocumentLinksExpandable";
import { AlertPlace, ProjectAlerts } from "./ProjectAlerts";
import { projectShow } from "./store/slices";
import { ConfirmModal } from "../../common/modals/ConfirmModal";

const selectStyle = (isInactive: boolean): StylesConfig<any, false> => ({
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    border: `1px solid #ced4da`,
    boxShadow: "none",
    "&:hover": {
      border: `1px solid #94d9b4`,
    },
    minHeight: 31,
    height: 31,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0b5669" : undefined,
    "&:hover": {
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0b5669" : "#f1f2f4",
    },
    "&:active": {
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0b5669" : "#f1f2f4",
    },
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 6px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
});

interface IProps {
  project: IProject;
  onHide: () => void;
}

const ProjectReviewModal: FC<IProps> = ({ project, onHide }) => {
  const { t } = useTranslation("projectReviewModal");

  const dispatch = useDispatch();

  const [acceptData, setAcceptData] = useState<IProjectUpdateForm>();
  const [rejectWarningOpened, setRejectWarningOpened] = useState<boolean>(
    false
  );
  const [changeLogOpened, setChangeLogOpened] = useState<boolean>(false);
  const [expandedRowId, setExpandedRowId] = useState<number | null>(null);
  // const [ferryOfferSelected, setFerryOfferSelected] = useState<boolean>(false);

  const profile = useSelector((state) => state.profile);
  const isManagersLoading = useSelector(
    (state) => state.projectShow.isManagersLoading
  );
  const managers = useSelector((state) => state.projectShow.managers);

  const fetchManagers = useCallback(
    () => dispatch(projectShow.actions.fetchManagers()),
    [dispatch]
  );

  const update = useCallback(
    (data: IProjectUpdateForm) =>
      dispatch(projectShow.actions.updateProject(data)),
    [dispatch]
  );

  const accept = useCallback(
    (data: IProjectUpdateForm) =>
      dispatch(projectShow.actions.acceptProject(data)),
    [dispatch]
  );

  const reject = useCallback(
    () => dispatch(projectShow.actions.rejectProject(project.id)),
    [dispatch, project]
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    errors,
    triggerValidation,
    getValues,
  } = useForm<IProjectUpdateForm>({
    mode: "onChange",
    defaultValues: {
      driverFullName: project.driverName || "",
      driverPhone: project.driverPhone || "",
      trailerPlates: project.trailerPlates || "",
      truckPlates: project.truckPlates || "",
      carrierManagerId: !!(project.archivedAt && !project.carrierManager)
        ? null
        : project.carrierManager
        ? {
            label: `${project.carrierManager.name} ${project.carrierManager.surname}`,
            value: project.carrierManager.id,
          }
        : profile.isManager
        ? null
        : profile.data
        ? {
            label: `${profile.data.name} ${profile.data.surname}`,
            value: profile.data.id,
          }
        : null,
    },
  });

  const carrierManagerId = watch("carrierManagerId");

  const handleAccept = () => {
    const data = acceptData;

    if (data) {
      accept({
        ...data,
        carrierManagerId: data.carrierManagerId.value,
        // ferryOfferRequested: !!ferryOfferSelected,
      });
      onHide();
    }
  };

  const handleReject = () => {
    reject();
    onHide();
  };

  const handleUpdate = (data: IProjectUpdateForm) => {
    update({
      ...data,
      carrierManagerId: data.carrierManagerId.value,
      // ferryOfferRequested: ferryOfferSelected,
    });
    onHide();
  };

  const managerOptions = useMemo(
    () =>
      managers
        .filter((manager) => !!manager.name && !!manager.surname)
        .map((manager) => ({
          label: `${manager.name} ${manager.surname}`,
          value: manager.id,
        })),
    [managers]
  );

  const selectedManager = useMemo(() => {
    if (carrierManagerId && carrierManagerId.value) {
      return (
        managers.find((manager) => carrierManagerId.value === manager.id) ||
        null
      );
    }

    return null;
  }, [managers, carrierManagerId]);

  const palletsColumn = useMemo(() => {
    const { pallets, palletsWillChange } = project;

    const palletsNumber = pallets ? (pallets.match(/\d+/g) || []).join("") : "";

    const palletsType =
      pallets && pallets.includes("PAGAL_CMR") ? t("byCMR") : "";

    const palletsText = `${
      palletsNumber
        ? `${palletsNumber} ${t("pallets")}`
        : palletsType
        ? palletsType
        : ""
    }`;

    return palletsText ? (
      <span className="pallets-column">
        {palletsText}{" "}
        {palletsWillChange && (
          <Tooltip
            id={`tooltip-pallets-column`}
            text={t("willBeChanged")}
            placement="right"
          >
            <FaInfoCircle
              className="text-primary"
              style={{ fontSize: "14px" }}
            />
          </Tooltip>
        )}
      </span>
    ) : null;
  }, [project, t]);

  const dataChanged = useMemo(() => {
    if (!project.carrierManager) return true;

    let carrierManagerChanged = false;

    if (project.carrierManager && carrierManagerId) {
      carrierManagerChanged =
        carrierManagerId.value !== project.carrierManager.id;
    }

    return (
      carrierManagerChanged ||
      watch("driverFullName") !== project.driverName ||
      watch("driverPhone") !== project.driverPhone ||
      watch("truckPlates") !== project.truckPlates ||
      watch("trailerPlates") !== project.trailerPlates
    );
  }, [project, watch, carrierManagerId]);

  const projectRejected = project.confirmStatus
    ? project.confirmStatus.code === ProjectConfirmStatus.Rejected
    : false;
  const projectWaitingApproval = project.confirmStatus
    ? project.confirmStatus.code === ProjectConfirmStatus.WaitingForApproval
    : false;
  const projectWaitingUpdateConfirm = project.confirmStatus
    ? project.confirmStatus.code === ProjectConfirmStatus.ConfirmUpdate
    : false;
  const projectUpdatable = project.confirmStatus
    ? [ProjectConfirmStatus.Confirmed].includes(project.confirmStatus.code)
    : false;

  const isInactive =
    !!project.archivedAt ||
    profile.hasAccountantRole ||
    projectRejected ||
    profile.isManager;

  useEffect(() => {
    fetchManagers();

    if (!isInactive) {
      triggerValidation();
    }
  }, [fetchManagers, triggerValidation, isInactive]);

  const updateDisabled =
    !dataChanged ||
    !watch("driverFullName") ||
    !watch("driverPhone") ||
    !carrierManagerId;

  const language = profile.data
    ? profile.data.language
    : project.company.language
    ? project.company.language.code
    : LanguageCode.EN;

  const firstPolicyTitle = project.firstPolicy
    ? project.firstPolicy.title.code
    : "";
  const firstPolicyText = project.firstPolicy
    ? project.firstPolicy.data[language]
    : "";

  const shipmentFixation = project.shipmentFixation
    ? project.shipmentFixation.filter(({ type, number }) => !!Number(number))
    : [];

  return (
    <>
      <Modal
        show={!changeLogOpened && !acceptData && !rejectWarningOpened}
        onHide={onHide}
        size="xl"
        scrollable
        className="project-review-modal"
        id="order-review-modal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FaFileAlt /> {t("orderReview")}
          </Modal.Title>
          <ProjectAlerts project={project} place={AlertPlace.Modal} />
        </Modal.Header>
        <Modal.Body
          style={{
            overflowX: "hidden",
            textAlign: "left",
          }}
        >
          <Row className="subheader">
            <div>
              <span style={{ marginRight: "15px" }}>{t("orderDetails")}</span>{" "}
              <span style={{ color: "lightgray", fontWeight: "normal" }}>
                {project?.axCode}
              </span>
            </div>
            <div>
              <span style={{ color: "lightgray", fontWeight: "normal" }}>
                {t("route")} {project.regionTag}
              </span>
            </div>
          </Row>
          <Form>
            <Row style={{ marginBottom: "15px", position: "relative" }}>
              <Col className="customer">
                <span>{t("customer")}</span>
                <Row>
                  <Col>
                    <span>{project.clientCompany.name}</span>
                    <span>
                      {[
                        project.clientCompany.address,
                        project.clientCompany.city,
                        project.clientCompany.post_code,
                        project.clientCompany.country,
                      ]
                        .filter((data) => !!data)
                        .join(", ")}
                    </span>
                    <span>{project.clientCompany.code}</span>
                    <span>{project.clientCompany.vat_code}</span>
                  </Col>
                  {project.manager && (
                    <Col className="customer-manager">
                      <span style={{ marginBottom: "10px" }}>
                        {project.manager.name}
                      </span>
                      <span>
                        <FaPhoneAlt /> {project.manager.phone || "-"}
                      </span>
                      <span>
                        <FaRegEnvelope /> {project.manager.email || "-"}
                      </span>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col className="supplier">
                <span>{t("supplier")}</span>
                <Row>
                  <Col>
                    <span>{project.company.name}</span>
                    <span>
                      {[
                        project.company.address,
                        project.company.city,
                        project.company.postalCode,
                        project.company.country,
                      ]
                        .filter((data) => !!data)
                        .join(", ")}
                    </span>
                    <span>{project.company.code}</span>
                    <span>{project.company.vatCode}</span>
                  </Col>
                  <Col className="supplier-manager">
                    <Form.Group controlId="carrierManagerId">
                      <Controller
                        as={Select as any}
                        name="carrierManagerId"
                        type="text"
                        ref={register}
                        maxLength={190}
                        size="sm"
                        value={carrierManagerId}
                        options={managerOptions}
                        styles={selectStyle(isInactive)}
                        placeholder={t("search")}
                        isLoading={isManagersLoading}
                        control={control}
                        rules={{ required: true }}
                        isDisabled={isInactive}
                      />
                    </Form.Group>

                    {!!(!selectedManager && isManagersLoading) && (
                      <div className="managers-loader">
                        <Loader isLoading={true} />
                      </div>
                    )}

                    {!!(!selectedManager && !isManagersLoading) && (
                      <NoData text={t("managerNotAssignedYet")} />
                    )}

                    {selectedManager && (
                      <>
                        <Tooltip id="phone" text={selectedManager.phone || ""}>
                          <span className="detail">
                            <FaPhoneAlt /> {selectedManager.phone || "-"}
                          </span>
                        </Tooltip>

                        <Tooltip id="email" text={selectedManager.email || ""}>
                          <span className="detail">
                            <FaRegEnvelope /> {selectedManager.email || "-"}
                          </span>
                        </Tooltip>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginBottom: "15px" }}>
              <Col className="order-conditions">
                <div>
                  <span>{t("orderConditions")} </span>
                  <span>
                    <Link
                      to="#"
                      onClick={() => setChangeLogOpened(true)}
                      className="text-warning"
                    >
                      {t("changeLog")}
                      <Badge className="change-log-count" variant="primary">
                        {project.changeLogCount}
                      </Badge>
                    </Link>
                  </span>
                </div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{t("goodsType")}</th>
                      <th>{t("weight")} (KG)</th>
                      <th>{t("pallets")}</th>
                      <th>{t("shipmentFixation")}</th>
                      <th>{t("carriageValue")} (EUR)</th>
                      <th>{t("trailerType")}</th>
                      <th>{t("temperature")}</th>
                      <th>{t("refNumber")}</th>
                      <th>{t("clientOrderNumber")}</th>
                      <th>{t("other")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{project.goodsType || "-"}</td>
                      <td>{project.weight || "-"}</td>
                      <td>{palletsColumn || "-"}</td>
                      <td>
                        {shipmentFixation.length
                          ? shipmentFixation
                              .filter(({ type, number }) => !!Number(number))
                              .map(({ type, number }) => (
                                <span style={{ display: "block" }}>
                                  {type}: {number}
                                </span>
                              ))
                          : "-"}
                      </td>
                      <td>{project.carriageValue || "-"}</td>
                      <td>{project.trailerType || "-"}</td>
                      <td>
                        {project.temperatureType && (
                          <span style={{ display: "block" }}>
                            {t("mode")}: {project.temperatureType}
                          </span>
                        )}

                        {project.temperature && (
                          <span style={{ display: "block" }}>
                            {t("range")}: {project.temperature}
                          </span>
                        )}
                      </td>
                      <td>{project.refNumber || "-"}</td>
                      <td>{project.clientOrderNumber || "-"}</td>
                      <td>{project.projectFeatures || "-"}</td>
                    </tr>
                  </tbody>
                </Table>

                <Table responsive className="locations-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{t("locationType")}</th>
                      <th>{t("date")}</th>
                      <th>{t("locationName")}</th>
                      <th>{t("address")}</th>
                      <th>{t("coordinates")}</th>
                      <th>{t("refNumber")}</th>
                      <th>{t("comments")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project.plannedLocations.map((location) => (
                      <tr key={location.id}>
                        <td>
                          {!!(
                            location.comment && location.comment.length > 30
                          ) && (
                            <span
                              onClick={() => {
                                if (expandedRowId === location.id) {
                                  setExpandedRowId(null);
                                } else {
                                  setExpandedRowId(location.id);
                                }
                              }}
                              className="expander"
                            >
                              {expandedRowId === location.id ? (
                                <FaCaretSquareUp />
                              ) : (
                                <FaCaretSquareDown />
                              )}
                            </span>
                          )}
                        </td>
                        <td>{location.type.name || "-"}</td>
                        <td>
                          {location.dateFrom && (
                            <span style={{ display: "block" }}>
                              {formatUTCDate(location.dateFrom)}
                            </span>
                          )}
                          {location.dateFrom && (
                            <span>
                              {formatUTCTime(location.dateFrom)}
                              {location.dateFrom &&
                                ` - ${formatUTCTime(location.dateTo)}`}
                            </span>
                          )}
                        </td>
                        <td>{location.addressName || "-"}</td>
                        <td>
                          {[
                            location.city,
                            location.addressLine,
                            location.country,
                          ]
                            .filter((name) => !!name)
                            .join(", ")}
                        </td>
                        <td>
                          {`${location.latitude}, ${location.longtitude}` ||
                            "-"}
                        </td>
                        <td>{location.refNumber || "-"}</td>
                        <td>
                          {location.comment ? (
                            <span>
                              {expandedRowId === location.id
                                ? location.comment
                                : location.comment.slice(0, 30) + "..."}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row
              className="special-conditions"
              style={{ marginBottom: "15px", position: "relative" }}
            >
              <Col>
                <span>
                  {t(firstPolicyTitle, {
                    ns: "policies",
                  })}
                </span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: firstPolicyText,
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "15px" }}>
              <Col className="payment-conditions">
                <span>{t("paymentConditions")}</span>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>{t("freight")}</th>
                      <th>{t("specialPaymentTerm")}</th>
                      <th>{t("crmPaymentNote")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className="text-primary"
                        style={{ fontWeight: 800, fontSize: "18px" }}
                      >
                        {project.freightCharge} {project.freightChargeCurrency}
                      </td>
                      <td>
                        {project.specialPaymentTerm ? (
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {project.specialPaymentTerm.replace(/[^0-9]/g, "")}{" "}
                            <Tooltip
                              id={`tooltip-pallets-column`}
                              text={t("conditionApplies")}
                              placement="right"
                            >
                              <FaInfoCircle
                                className="text-primary"
                                style={{
                                  fontSize: "14px",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          </span>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="text-warning">
                        {project.crmPaymentNote || "-"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col className="truck-data">
                <span>{t("truckData")}</span>

                <div className="truck-data-form">
                  <Form.Row>
                    <Form.Label column xs={4} htmlFor="driverFullName">
                      <FaUser /> {t("driverFullName")}
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="driverFullName"
                        id="driverFullName"
                        type="text"
                        ref={register({ required: projectUpdatable })}
                        isInvalid={!!errors.driverFullName}
                        maxLength={190}
                        size="sm"
                        disabled={isInactive}
                      />
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Form.Label column xs={4} htmlFor="driverPhone">
                      <FaPhoneAlt /> {t("driverPhone")}
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="driverPhone"
                        id="driverPhone"
                        type="text"
                        ref={register({ required: projectUpdatable })}
                        isInvalid={!!errors.driverPhone}
                        maxLength={190}
                        size="sm"
                        disabled={isInactive}
                      />
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Form.Label column xs={4} htmlFor="truckPlates">
                      <FaTruck /> {t("truckPlates")}
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="truckPlates"
                        id="truckPlates"
                        type="text"
                        ref={register}
                        isInvalid={!!errors.truckPlates}
                        maxLength={190}
                        size="sm"
                        disabled
                      />
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Form.Label column xs={4} htmlFor="trailerPlates">
                      <FaTrailer /> {t("trailerPlates")}
                    </Form.Label>
                    <Col>
                      <Form.Control
                        name="trailerPlates"
                        id="trailerPlates"
                        type="text"
                        ref={register}
                        isInvalid={!!errors.trailerPlates}
                        maxLength={190}
                        size="sm"
                        disabled
                      />
                    </Col>
                  </Form.Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <DocumentLinksExpandable
                  project={project}
                  defaultLanguage={language}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        {!profile.isManager && (
          <Modal.Footer>
            {/* {!!(projectWaitingApproval || projectWaitingUpdateConfirm) && (
              <Row>
                <Col>
                  <Form.Group controlId="ferry_offer" className="ferry-offer">
                    <Form.Check
                      name="ferry_offer"
                      type="checkbox"
                      label={t("iWouldLikeToReceiveFerryOffer")}
                      onChange={(event) =>
                        setFerryOfferSelected(event.target.checked)
                      }
                      checked={ferryOfferSelected}
                      disabled={isInactive}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )} */}

            <Row>
              <Col>
                {projectRejected && (
                  <Button variant="outline-secondary" onClick={() => onHide()}>
                    {t("close")}
                  </Button>
                )}
                {projectUpdatable && (
                  <Button
                    onClick={handleSubmit(handleUpdate)}
                    disabled={isInactive || updateDisabled}
                  >
                    {t("submitChanges")}
                  </Button>
                )}
                {!!(projectWaitingApproval || projectWaitingUpdateConfirm) && (
                  <>
                    <Button
                      disabled={isInactive}
                      variant="outline-secondary"
                      onClick={() => setRejectWarningOpened(true)}
                      style={{ marginRight: "1rem" }}
                    >
                      {t("rejectOrder")}
                    </Button>
                    <Button
                      onClick={() => setAcceptData(getValues())}
                      variant="primary"
                      disabled={!carrierManagerId || isInactive}
                    >
                      {t("acceptOrder")}
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Modal.Footer>
        )}
      </Modal>

      {acceptData && (
        <ConfirmModal
          question={t("warning")}
          isVisible={true}
          handleDeny={() => setAcceptData(undefined)}
          handleConfirm={handleSubmit(handleAccept)}
          body={
            <div>
              <span>{t("doYouReallyWantAccept")}</span>
            </div>
          }
        />
      )}

      {rejectWarningOpened && (
        <ConfirmModal
          question={t("warning")}
          isVisible={true}
          handleDeny={() => setRejectWarningOpened(false)}
          handleConfirm={handleReject}
          body={
            <div>
              <span>{t("doYouReallyWantReject")}</span>
            </div>
          }
        />
      )}

      {changeLogOpened && (
        <ChangeLogModal
          project={project}
          handleClose={() => setChangeLogOpened(false)}
        />
      )}
    </>
  );
};

export { ProjectReviewModal };
