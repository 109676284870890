import { IProjectLocation } from "../../../../services/api/projects";
import { generateCoordinates } from "../map/utils/maps-helper";

const filterInvalidPositions = (
  locations: IProjectLocation[]
): IProjectLocation[] =>
  locations.filter(({ latitude, longtitude }) => !!latitude && !!longtitude);

const mapPositions = (locations: IProjectLocation[]): google.maps.LatLng[] =>
  filterInvalidPositions(locations).map(({ latitude, longtitude }) =>
    generateCoordinates(latitude, longtitude)
  );

export { mapPositions, filterInvalidPositions };
