import { Login } from "../authentication/login/Login";
import { Register } from "../authentication/register/Register";
import { Documents } from "../documents/Documents";
import { Profile } from "../profile/Profile";
import { Projects } from "../projects/Projects";
import { Applications as AdminApplications } from "./../admin/applications/Applications";
import { Projects as AdminProjects } from "./../admin/projects/Projects";
import { UserInvite as AdminUserInvite } from "./../admin/users/UserInvite";
import { Users as AdminUsers } from "./../admin/users/Users";
import { Documents as AdminDocuments } from "./../admin/documents/Documents";
import { ActivityLog } from "../admin/activity-log/ActivityLog";
import { UploadXml } from "../admin/edi/UploadXml";
import { Cache } from "../admin/cache/Cache";
import { TemplateTest } from "../admin/mailing/TemplateTest";
import { IssueMail } from "../admin/mailing/IssueMail";
import { Maintenance } from "../admin/maintenance/Maintenance";
import { Companies } from "../admin/companies/Companies";
import { Company } from "../admin/companies/Company";
import { ManageConditions } from "../admin/users/ManageConditions";
import { UserCompanies } from "../admin/users/companies/UserCompanies";
import { UserCompanyAssign } from "../admin/users/companies/UserCompanyAssign";
import { UserRoleEdit } from "../admin/users/UserRoleEdit";
import { UserCompanyRoleEdit } from "../admin/users/companies/UserCompanyRoleEdit";
import { Verify } from "../authentication/register/Verify";
import { ForgotPassword } from "../authentication/forgot-password/ForgotPassword";
import { ResetPassword } from "../authentication/forgot-password/ResetPassword";
import { Invitation } from "../users/invite/Invitation";
import { Logout } from "../logout/Logout";
import { ProfileEdit } from "../profile/ProfileEdit";
import { ChangePassword } from "../profile/ChangePassword";
import { NotificationSettings } from "../notification-settings/NotificationSettings";
import { Applications } from "../application/Applications";
import { NewPartnerApplication } from "../application/form/NewPartnerApplication";
import { ExistingPartnerApplication } from "../application/form/ExistingPartnerApplication";
import { Users } from "../users/Users";
import { User } from "../users/User";
import { UserUpdate } from "../users/UserUpdate";
import {
  AdminRoute,
  CarrierRoute,
  GeneralRoute,
  PublicRoute,
  SuperAdminRoute,
} from "../../constants/routes";
import { UserInvite } from "../users/invite/UserInvite";
import { Files } from "../admin/files/Files";
import { OrdersTemplate } from "../layout/OrdersTemplate";
import { Session } from "../admin/session/Session";
import { Queues } from "../admin/queues/Queues";
import { Logs } from "../admin/logs/Logs";
import { DepartmentRoleEdit } from "../admin/users/DepartmentRoleEdit";
import { ClientCompanies } from "../admin/client-companies/ClientCompanies";
import { GeneralPolicies } from "../admin/general-policies/GeneralPolicies";
import GeneralPolicyEdit from "../admin/general-policies/GeneralPolicyEdit";
import GeneralPolicyStore from "../admin/general-policies/GeneralPolicyStore";
import { ProjectPolicies } from "../admin/project-policies/ProjectPolicies";
import ProjectPolicyEdit from "../admin/project-policies/ProjectPolicyEdit";
import ProjectPolicyStore from "../admin/project-policies/ProjectPolicyStore";
import { MaintenancePage } from "../maintenance/MaintenancePage";
import { Notifications } from "../notifications/Page/Notifications";
import { StatisticPolicies } from "../admin/statistic-policies/StatisticPolicies";
import StatisticPolicyStore from "../admin/statistic-policies/StatisticPolicyStore";
import StatisticPolicyEdit from "../admin/statistic-policies/StatisticPolicyEdit";

export enum RouteType {
  Public,
  SuperAdmin,
  Admin,
  General,
  Carrier,
}

interface IRoute {
  Component: React.FC<{}>;
  exact: boolean;
  path: string;
  Template?: any;
}

export const superAdminRoutes: IRoute[] = [
  {
    Component: UploadXml,
    exact: true,
    path: SuperAdminRoute.EDI.Upload,
  },
  {
    Component: Cache,
    exact: true,
    path: SuperAdminRoute.Settings.Cache,
  },
  {
    Component: Session,
    exact: true,
    path: SuperAdminRoute.Settings.Sessions,
  },
  {
    Component: Queues,
    exact: true,
    path: SuperAdminRoute.Settings.Queues,
  },
  {
    Component: Logs,
    exact: true,
    path: SuperAdminRoute.Settings.Logs,
  },
  {
    Component: TemplateTest,
    exact: true,
    path: SuperAdminRoute.Mailing.TestTemplates,
  },
  {
    Component: IssueMail,
    exact: true,
    path: SuperAdminRoute.Mailing.SendIssueMail,
  },
  {
    Component: Maintenance,
    exact: true,
    path: SuperAdminRoute.Maintenance.Show,
  },
];

export const adminRoutes: IRoute[] = [
  {
    Component: GeneralPolicies,
    exact: true,
    path: AdminRoute.GeneralPolicies.List,
  },
  {
    Component: GeneralPolicyStore,
    exact: true,
    path: AdminRoute.GeneralPolicies.Store,
  },
  {
    Component: GeneralPolicyEdit,
    exact: true,
    path: AdminRoute.GeneralPolicies.Update,
  },
  {
    Component: ProjectPolicies,
    exact: true,
    path: AdminRoute.ProjectPolicies.List,
  },
  {
    Component: ProjectPolicyStore,
    exact: true,
    path: AdminRoute.ProjectPolicies.Store,
  },
  {
    Component: ProjectPolicyEdit,
    exact: true,
    path: AdminRoute.ProjectPolicies.Update,
  },
  {
    Component: StatisticPolicies,
    exact: true,
    path: AdminRoute.StatisticPolicies.List,
  },
  {
    Component: StatisticPolicyStore,
    exact: true,
    path: AdminRoute.StatisticPolicies.Store,
  },
  {
    Component: StatisticPolicyEdit,
    exact: true,
    path: AdminRoute.StatisticPolicies.Update,
  },
  {
    Component: ActivityLog,
    exact: true,
    path: AdminRoute.ActivityLog.List,
  },
  {
    Component: AdminApplications,
    exact: true,
    path: AdminRoute.Applications.List,
  },
  {
    Component: AdminProjects,
    exact: true,
    path: AdminRoute.Orders.List,
  },
  {
    Component: AdminDocuments,
    exact: true,
    path: AdminRoute.Documents.List,
  },
  {
    Component: AdminUserInvite,
    exact: true,
    path: AdminRoute.Users.Invite,
  },
  {
    Component: UserRoleEdit,
    exact: true,
    path: AdminRoute.Users.EditRoles,
  },
  {
    Component: DepartmentRoleEdit,
    exact: true,
    path: AdminRoute.Users.EditDepartmentRoles,
  },
  {
    Component: UserCompanyRoleEdit,
    exact: true,
    path: AdminRoute.Users.Companies.EditRoles,
  },
  {
    Component: UserCompanyAssign,
    exact: true,
    path: AdminRoute.Users.Companies.Assign,
  },
  {
    Component: UserCompanies,
    exact: true,
    path: AdminRoute.Users.Companies.List,
  },
  {
    Component: ManageConditions,
    exact: true,
    path: AdminRoute.Users.ManageConditions,
  },
  {
    Component: AdminUsers,
    exact: true,
    path: AdminRoute.Users.List,
  },
  {
    Component: Company,
    exact: true,
    path: AdminRoute.Companies.Create,
  },
  {
    Component: Company,
    exact: true,
    path: AdminRoute.Companies.Update,
  },
  {
    Component: Companies,
    exact: true,
    path: AdminRoute.Companies.List,
  },
  {
    Component: ClientCompanies,
    exact: true,
    path: AdminRoute.ClientCompanies.List,
  },
  {
    Component: Files,
    exact: true,
    path: AdminRoute.Files.List,
  },
];

export const publicRoutes: IRoute[] = [
  {
    Component: Login,
    exact: true,
    path: "/",
  },
  {
    Component: Register,
    exact: true,
    path: PublicRoute.Register,
  },
  {
    Component: Verify,
    exact: true,
    path: PublicRoute.Email.Verify,
  },
  {
    Component: ForgotPassword,
    exact: true,
    path: PublicRoute.Password.Forgot,
  },
  {
    Component: ResetPassword,
    exact: true,
    path: PublicRoute.Password.Reset,
  },
  {
    Component: Invitation,
    exact: true,
    path: PublicRoute.Invitation,
  },
  {
    Component: Logout,
    exact: true,
    path: PublicRoute.Logout,
  },
  {
    Component: MaintenancePage,
    exact: true,
    path: PublicRoute.Maintenance,
  },
];

export const generalRoutes: IRoute[] = [
  {
    Component: ChangePassword,
    exact: true,
    path: GeneralRoute.Profile.ChangePassword,
  },
  {
    Component: ProfileEdit,
    exact: true,
    path: GeneralRoute.Profile.Edit,
  },
  {
    Component: Profile,
    exact: true,
    path: GeneralRoute.Profile.Show,
  },
  {
    Component: NotificationSettings,
    exact: true,
    path: GeneralRoute.MailSettings,
  },
];

export const carrierRoutes: IRoute[] = [
  {
    Component: Projects,
    exact: true,
    path: CarrierRoute.Orders.List,
    Template: OrdersTemplate,
  },
  {
    Component: Documents,
    exact: true,
    path: CarrierRoute.Documents.List,
  },
  {
    Component: UserInvite,
    exact: true,
    path: CarrierRoute.Users.Invite,
  },
  {
    Component: UserUpdate,
    exact: true,
    path: CarrierRoute.Users.Edit,
  },
  {
    Component: User,
    exact: true,
    path: CarrierRoute.Users.Show,
  },
  {
    Component: Users,
    exact: true,
    path: CarrierRoute.Users.List,
  },
  {
    Component: ExistingPartnerApplication,
    exact: true,
    path: CarrierRoute.Applications.ExistingPartner,
  },
  {
    Component: NewPartnerApplication,
    exact: true,
    path: CarrierRoute.Applications.NewPartner,
  },
  {
    Component: () => <ExistingPartnerApplication reviewMode />,
    exact: true,
    path: CarrierRoute.Applications.ShowExistingPartner,
  },
  {
    Component: () => <NewPartnerApplication reviewMode />,
    exact: true,
    path: CarrierRoute.Applications.ShowNewPartner,
  },
  {
    Component: Applications,
    exact: true,
    path: CarrierRoute.Applications.List,
  },
  {
    Component: Notifications,
    exact: true,
    path: GeneralRoute.Notifications,
  },
];
