import { FC, useCallback } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaGlobeEurope } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { ILanguage } from "../../i18n";
import { ILanguageState, translations } from "./store/slice";

interface IProps {
  languageState: ILanguageState;
  isVisible: boolean;
  onClose: () => void;
}

const LanguageSelectModal: FC<IProps> = ({
  languageState,
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation("languageSelectModal");

  const dispatch = useDispatch();

  const changeLanguage = useCallback(
    (language: ILanguage) => dispatch(translations.actions.change(language)),
    [dispatch]
  );

  return (
    <Modal show={isVisible} onHide={onClose} className="company-select-modal">
      <Modal.Header>
        <Modal.Title>
          <FaGlobeEurope /> {t("selectLanguage")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup defaultActiveKey={`#${languageState.current.code}`}>
          {languageState.selectable.map((language, index) => (
            <ListGroup.Item
              action
              key={index}
              eventKey={`#${language.code}`}
              onClick={() => {
                setTimeout(() => {
                  changeLanguage(language);
                  onClose();
                }, 50);
              }}
            >
              {language.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export { LanguageSelectModal };
