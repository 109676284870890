import { FC, useCallback, useEffect, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { IProjectLocation } from "../../../../../services/api/projects";
import { projectShow } from "../../store/slices";
import { MarkerPanel } from "../panels/MarkerPanel";

const normalSize = 30;
const hoverSize = 40;

export interface IMarkerProps extends google.maps.LatLngLiteral {
  lat: number;
  lng: number;
  data: IProjectLocation;
  isSelected: boolean;
  mainColor?: string;
  isHovered?: boolean;
  disabled?: boolean;
  onHover: () => void;
  onLeave: () => void;
}

const Marker: FC<IMarkerProps> = (props) => {
  const { mainColor, isHovered, isSelected, onLeave } = props;

  const [size, setSize] = useState<number>(normalSize);

  const dispatch = useDispatch();

  const selectLocationId = useCallback(
    (id: number | null) =>
      dispatch(projectShow.actions.setSelectedLocationId(id)),
    [dispatch]
  );

  useEffect(() => {
    if (isHovered && size !== hoverSize) {
      setSize(hoverSize);
    }

    if (isSelected && size !== hoverSize) {
      setSize(hoverSize);
    }

    if (!isHovered && !isSelected && size === hoverSize) {
      setSize(normalSize);
    }
  }, [isHovered, size, isSelected]);

  return (
    <div
      className="marker"
      style={{
        position: "absolute",
        color: mainColor ? mainColor : "#000000",
        fontSize: `${size}px`,
      }}
    >
      {(isSelected || isHovered) && (
        <MarkerPanel
          allData={isSelected}
          close={() => {
            onLeave();
            selectLocationId(null);
          }}
          {...props}
        />
      )}
      <FaMapMarkerAlt />
    </div>
  );
};

export { Marker };
