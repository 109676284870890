import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../../../services/api/users";

export interface IUserEditState {
  data: IUser | null;
  updated: boolean;
}

const initialState: IUserEditState = {
  data: null,
  updated: false,
};

const userEdit = createSlice({
  name: "userEdit",
  initialState,
  reducers: {
    fetchUser: (state, action) => {
      //
    },
    setData: (state, action) => {
      state.data = action.payload.data;
    },
    updateUser: (state, action) => {
      //
    },
    setUpdated: (state) => {
      state.updated = true;
    },
    reset: () => initialState,
  },
});

export { userEdit };
