import React, { FC } from "react";
import { Alert, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaFileAlt, FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  IDocument,
  DocumentType,
  DocumentStatus,
} from "../../../../../services/api/documents";
import {
  formatUnixDate,
  formatUnixDateTimeWithTimezone,
} from "../../../../../utils/date-format";
import { InfoAlert, InfoAlertStatus } from "../../../../alert/InfoAlert";
import { StatusBadge } from "../../../../documents/StatusBadge";
import { downloadDocument } from "../../../../documents/table/utils/download";
import { PDFReader } from "../../../../common/pdf-reader/PDFReader";
import { useTranslation } from "react-i18next";

interface IProps {
  isVisible: boolean;
  document: IDocument;
  isInactive?: boolean;
  strikeThroughPaymentDay?: boolean;
  onClose: () => void;
  onDelete?: () => void;
}

const DocumentPreviewModal: FC<IProps> = ({
  isVisible,
  document,
  isInactive,
  strikeThroughPaymentDay,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslation("projectDocumentPreview");

  let name: string = document.type ? document.type.name : "order";

  if (document.type && document.type.code === DocumentType.Invoice) {
    name = `${t("invoice")}: ${name}`;
  }

  let alertStatus = null;

  if (document.status) {
    switch (document.status.code) {
      case DocumentStatus.Accepted:
        alertStatus = InfoAlertStatus.Success;
        break;
      case DocumentStatus.Rejected:
        alertStatus = InfoAlertStatus.Error;
        break;
      case DocumentStatus.Pending:
        alertStatus = InfoAlertStatus.Pending;
        break;
      case DocumentStatus.Uploaded:
        alertStatus = InfoAlertStatus.Info;
        break;
    }
  }

  return (
    <Modal
      show={isVisible}
      onHide={onClose}
      dialogClassName="document-preview-modal"
      animation={false}
      size="xl"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FaFileAlt /> {name}{" "}
          {document.status && (
            <div className="status-div">
              <StatusBadge status={document.status} />
              {document.status.code === DocumentStatus.Waiting && (
                <span className="waiting-info ">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="question-tooltip">
                        {t("waitingForOriginal", {
                          ns: "documentItem",
                        })}
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle />
                  </OverlayTrigger>
                </span>
              )}
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="document-info">
          <div>
            {!!(document.axMessage && alertStatus !== null) && (
              <InfoAlert
                status={alertStatus}
                message={document.axMessage || ""}
                label={`${t("message")}:`}
              />
            )}
            <h3>{document.fileName}</h3>
            <hr />
            <div className="document-info-body">
              <div>
                <span>{t("uploaded")}:</span>{" "}
                {formatUnixDateTimeWithTimezone(document.createdAt)}{" "}
                (Europe/Vilnius){" "}
                {document.createdBy
                  ? `by ${document.createdBy.name} ${document.createdBy.surname}`
                  : ""}
              </div>
              {document.paymentUntil && (
                <div>
                  <span>{t("paymentUntil")}:</span>{" "}
                  <span
                    style={{
                      textDecoration: strikeThroughPaymentDay
                        ? "line-through"
                        : "none",
                    }}
                  >
                    {document.paymentUntil
                      ? formatUnixDate(document.paymentUntil)
                      : "-"}
                  </span>
                </div>
              )}
              {document.data &&
                document.data.map(({ value, type }, index) => {
                  let val = value;

                  if (typeof value === "number") {
                    val = formatUnixDate(value);
                  }

                  if (value instanceof Object) {
                    val = Object.values(value)
                      .map((value) => value)
                      .join(" ");
                  }

                  return (
                    <div key={index}>
                      <span>{type.name}:</span>{" "}
                      <span>{(val as any) || "-"}</span>
                    </div>
                  );
                })}
              <div>
                <span>{t("comment")}:</span>{" "}
                {document.comment ? document.comment : "-"}
              </div>
            </div>
          </div>
          <div className="document-info-buttons">
            {onDelete &&
              document.status &&
              document.status.code === DocumentStatus.Rejected && (
                <Button
                  variant="outline-secondary"
                  onClick={() => onDelete()}
                  style={{ marginRight: "15px" }}
                  disabled={isInactive}
                >
                  {t("delete")}
                </Button>
              )}
            <Button
              as={Link}
              to="#"
              onClick={() => downloadDocument(document.id, document.fileName)}
              variant="primary"
            >
              {t("download")}
            </Button>
          </div>
        </div>
        {!document.fileRemoved ? (
          <PDFReader documentId={document.id} />
        ) : (
          <div className="document-removed">
            <Alert variant="warning">{t("documentRemoved")}</Alert>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export { DocumentPreviewModal };
