import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "../../../../services/api";
import { CompanyRole } from "../../../../services/api/profile";

export interface IUserListState {
  data: any[];
  search: string;
  filter: CompanyRole | null;
  perPage: number;
  activePage: number;
  meta: IPagination | null;
  isLoading: boolean;
}

const initialState: IUserListState = {
  data: [],
  search: "",
  filter: null,
  perPage: 20,
  activePage: 1,
  meta: null,
  isLoading: false,
};

const userList = createSlice({
  name: "userList",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
    concatData: (state, action) => {
      state.data = state.data.concat(action.payload.data);
      state.meta = action.payload.meta;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.activePage = 1;
      state.data = [];
      state.isLoading = true;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.activePage = 1;
      state.data = [];
      state.isLoading = true;
    },
    deleteUser: (state, action) => {
      //
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    resetData: (state) => {
      state.data = [];
    },
    resetPagination: (state) => {
      state.activePage = 1;
      state.meta = null;
    },
    reset: () => initialState,
  },
});

export { userList };
