import { FC, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Header } from "../../layout/Header";
import { adminUsersAPI } from "../../../services/api/admin/users";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { SelectAsyncSearch } from "../../common/select/SelectAsyncSearch";
import { adminMailingAPI } from "../../../services/api/admin/mailing";
import { Option } from "react-select/src/filters";
import { showError, showSuccess } from "../../alert/store/slices";
import { useDispatch } from "react-redux";

interface ITemplateTestForm {
  users?: Option[];
}

const IssueMail: FC = () => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);

  const { handleSubmit, control, watch } = useForm<ITemplateTestForm>();

  const onSubmit = async (data: ITemplateTestForm) => {
    if (data && data.users) {
      setLoading(true);

      const response = await adminMailingAPI.sendTechnicalIssuesMail(
        data.users.map((option) => option.value)
      );

      if (response && response.success) {
        dispatch(showSuccess("Emails sent."));
      } else {
        dispatch(showError("Something went wrong while sending emails."));
      }

      setLoading(false);
    }
  };

  const promiseOptions = async (search: string) => {
    const response = await adminUsersAPI.fetchUsersShort({ search });

    if (response && response.data) {
      return response.data.map((user) => ({
        label: `${user.email}`,
        value: user.id,
      }));
    }

    return [];
  };

  return (
    <Container fluid>
      <Row>
        <Header title="Technical issues email" languageDisabled />
      </Row>
      <Row>
        <p>Send "technical issues occured" email for selected people.</p>
      </Row>
      <Row className="issue-send">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Form.Group controlId="users" as={Col}>
              <Controller
                name="users"
                as={SelectAsyncSearch}
                isMulti
                cacheOptions
                loadOptions={promiseOptions}
                control={control}
                className="multi-select"
              />
            </Form.Group>
          </Row>

          <ButtonLoader
            buttonDisabled={!watch("users")}
            disabled={isLoading}
            type="submit"
          >
            Send
          </ButtonLoader>
        </Form>
      </Row>
      <Row>
        <div className="text-example">
          <b>Email text example:</b>
          <i>
            Partners portal had some issues related to your account. Please be
            informed that we have already fixed that. You can login to the
            portal and use it as usual. Also if you find any other issues please
            let us know: partners.info@everwest.net
          </i>
        </div>
      </Row>
    </Container>
  );
};

export { IssueMail };
