import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import exampleTheme from "./themes/ExampleTheme";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import OnChangePlugin from "./plugins/OnChangePlugin";
import HtmlImportPlugin from "./plugins/HtmlImportPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";

type Props = {
  readOnly?: boolean;
  handleUpdate: (html: string) => void;
  initialHtml?: string;
};

export default function Editor({ readOnly, handleUpdate, initialHtml }: Props) {
  return (
    <>
      <LexicalComposer
        initialConfig={{
          namespace: "Test editor",
          theme: exampleTheme,
          onError(error) {
            throw error;
          },
          nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
          ],
          editable: typeof readOnly !== "undefined" ? !readOnly : true,
        }}
      >
        <div className="editor-container">
          <ToolbarPlugin readOnly={readOnly} />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={
                <div className="editor-placeholder">
                  Enter some plain text...
                </div>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <OnChangePlugin handleUpdate={handleUpdate} />
            <HtmlImportPlugin html={initialHtml} />
          </div>
        </div>
      </LexicalComposer>
    </>
  );
}
