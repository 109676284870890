import { createSlice } from "@reduxjs/toolkit";
import { ProjectPolicy } from "../../../../services/api/admin/project-policies";
import { IProjectShort } from "../../../../services/api/projects";

export enum Discount {
  Five = "5",
  Four = "4",
  Three = "3",
}

export interface IQuickPayModal {
  project: IProjectShort | null;
  discount: Discount;
  showEntryModal: boolean;
  showConfirmationModal: boolean;
  showUploadModal: boolean;
  showInvoiceModal: boolean;
  showAdditionalInfoModal: boolean;
  isLoading: boolean;
  modalMustBeClosed: boolean;

  latestPolicies: ProjectPolicy[];
  isLatestPoliciesLoading: boolean;
  confirmedPolicies: boolean;
}

const initialState: IQuickPayModal = {
  project: null,
  discount: Discount.Five,
  showEntryModal: false,
  showConfirmationModal: false,
  showUploadModal: false,
  showInvoiceModal: false,
  showAdditionalInfoModal: false,
  isLoading: false,
  modalMustBeClosed: false,

  latestPolicies: [],
  isLatestPoliciesLoading: false,
  confirmedPolicies: false,
};

const quickPayModal = createSlice({
  name: "quickPayModal",
  initialState,
  reducers: {
    setProject: (state, action) => {
      state.project = action.payload;
      state.showEntryModal = !!action.payload;
    },
    setShowConfirmationModal: (state, action) => {
      state.showEntryModal = false;
      state.showConfirmationModal = action.payload;
    },
    setShowUploadModal: (state, action) => {
      state.showConfirmationModal = false;
      state.showUploadModal = action.payload;
    },
    setShowInvoiceModal: (state, action) => {
      state.showConfirmationModal = false;
      state.showInvoiceModal = action.payload;
    },
    setShowAdditionalInfoModal: (state, action) => {
      state.showEntryModal = !action.payload;
      state.showAdditionalInfoModal = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setModalMustBeClosed: (state, action) => {
      state.modalMustBeClosed = action.payload;
    },
    generateInvoice: (state, action) => {
      //
    },
    uploadInvoice: (state, action) => {
      //
    },
    fetchLatestPolicies: (state, action) => {
      //
    },
    setLatestPolicies: (state, action) => {
      state.latestPolicies = action.payload;
    },
    setLatestPoliciesLoading: (state, action) => {
      state.isLatestPoliciesLoading = action.payload;
    },
    setConfirmedPolicies: (state, action) => {
      state.confirmedPolicies = action.payload;
    },
    reset: () => initialState,
  },
});

export { quickPayModal };
