import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import {
  formatDateTime,
  formatUTCDateTime,
} from "../../../../../utils/date-format";
import { IMarkerProps } from "../markers/Marker";

interface IProps extends IMarkerProps {
  allData: boolean;
  close: () => void;
}

const MarkerPanel: FC<IProps> = ({
  data,
  allData,
  mainColor,
  close,
  onHover,
  onLeave,
}) => {
  const { t } = useTranslation("projectMarkerPanel");

  return (
    <div
      className="marker-panel"
      style={{
        color: "white",
        backgroundColor: mainColor,
      }}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {allData && (
        <div className="close" onClick={close}>
          <FaTimes />
        </div>
      )}
      <div
        className="panel-header"
        style={{ marginTop: allData ? "2rem" : "0rem" }}
      >
        {data.addressName}
      </div>
      <div className="panel-body">
        <div>
          <span>{t("start")}</span>
          <span>{formatUTCDateTime(data.dateFrom)} (local)</span>
        </div>

        <div>
          <span>{t("end")}</span>
          <span>{formatUTCDateTime(data.dateTo)} (local)</span>
        </div>

        <div>
          <span>{t("type")}</span>
          <span>{data.type && data.type.name ? data.type.name : "-"}</span>
        </div>

        {allData && (
          <>
            <div>
              <span>{t("latitude")}</span>
              <span>{data.latitude}</span>
            </div>

            <div>
              <span>{t("longtitude")}</span>
              <span>{data.longtitude}</span>
            </div>

            {!data.isPlanned && <hr className="panel-divider" />}

            {!data.isPlanned && (
              <div>
                <span>{t("confirmedBy")}</span>
                <span>{data.confirmedBy ? data.confirmedBy : "GPS"}</span>
              </div>
            )}

            {data.confirmedAt && (
              <div>
                <span>{t("confirmedAt")}</span>
                <span>{formatDateTime(data.confirmedAt)}</span>
              </div>
            )}
          </>
        )}
      </div>
      {!allData && (
        <div className="expand-info">{t("clickToSeeMoreDetails")}</div>
      )}
    </div>
  );
};

export { MarkerPanel };
