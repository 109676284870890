import { FC, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { email } from "../../../constants/regex";
import { LanguageCode } from "../../../i18n";
import { adminMailingAPI } from "../../../services/api/admin/mailing";
import { showError, showSuccess } from "../../alert/store/slices";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { Header } from "../../layout/Header";

interface ITemplateTestForm {
  email: string;
  language: LanguageCode;
}

const TemplateTest: FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, watch } = useForm<
    ITemplateTestForm
  >();

  const onSubmit = async (data: ITemplateTestForm) => {
    setLoading(true);

    const response = await adminMailingAPI.testEmailTemplates(
      data.email,
      data.language
    );

    setLoading(false);

    if (response && response.success) {
      dispatch(showSuccess("Emails sent."));
    } else {
      dispatch(showError("Something went wrong while sending emails."));
    }
  };

  return (
    <Container fluid>
      <Row>
        <Header title="Email templates" languageDisabled />
      </Row>
      <Row>
        <p>Send all existing emails with random data for testing purposes.</p>
      </Row>
      <Row className="template-test">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="email-input"
                name="email"
                type="text"
                ref={register({ required: true, pattern: email })}
                isInvalid={!!errors.email}
                maxLength={190}
                autoComplete="off"
                style={{ width: "200px" }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.type === "required" && "Required field."}
                {errors.email?.type === "pattern" && "Must be a valid email."}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="language">
              <Form.Label>Language</Form.Label>
              <Form.Control
                className="language-select"
                name="language"
                as="select"
                ref={register({ required: true })}
                isInvalid={!!errors.language}
                style={{ width: "100px" }}
              >
                {Object.values(LanguageCode).map((code) => (
                  <option value={code}>{code.toUpperCase()}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.language?.type === "required" && "Required field."}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <ButtonLoader
            buttonDisabled={!watch("email")}
            disabled={isLoading}
            type="submit"
          >
            Send
          </ButtonLoader>
        </Form>
      </Row>
    </Container>
  );
};

export { TemplateTest };
