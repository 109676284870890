import classNames from "classnames";
import { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface IProps {
  collapsed: boolean;
  icon: JSX.Element;
  text: string;
  link?: string;
  active: boolean;
  onClick: (link?: string) => void;
}

const SidebarItem: FC<IProps> = ({
  collapsed,
  icon,
  text,
  link,
  active,
  onClick,
}) => {
  const content = (
    <div
      className={classNames("sidebar-item", active && "sidebar-item-active")}
      style={{
        justifyContent: collapsed ? "center" : "initial",
      }}
      onClick={() => onClick(link)}
    >
      <div
        style={
          collapsed
            ? {}
            : {
                marginLeft: "25px",
                marginRight: "15px",
              }
        }
      >
        {icon}
      </div>
      {!collapsed && <div>{text}</div>}
    </div>
  );

  if (!collapsed) {
    return content;
  }

  return (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={text}>{text}</Tooltip>}
    >
      {content}
    </OverlayTrigger>
  );
};

export { SidebarItem };
