import classNames from "classnames";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IProject, ProjectConfirmStatus } from "../../../services/api/projects";
import { PanelTab } from "./details/DetailPanel";
import { ProjectAlert } from "./ProjectAlert";
import { projectShow } from "./store/slices";
import { scrollToContent } from "./utils/scroller";

export enum AlertPlace {
  Panel,
  Modal,
}

interface IProps {
  place: AlertPlace;
  project: IProject;
}

interface INoteSettings {
  text: string;
  className?: string;
  tab?: PanelTab;
}

const ProjectAlerts: FC<IProps> = ({ place, project }) => {
  const { t } = useTranslation("projectAlerts");

  const dispatch = useDispatch();

  const setTab = useCallback(
    (tab: PanelTab) => dispatch(projectShow.actions.setTab(tab)),
    [dispatch]
  );

  const notifications = useMemo(() => {
    let notes: INoteSettings[] = [];

    if (project.increasedSecurity) {
      notes.push({
        text: t("highValueIncreasedSecurity"),
        className: "alert-danger",
      });
    }

    if (!!project.archivedAt) {
      notes.push({
        text: t("projectIsArchived"),
      });

      return notes;
    }

    if (project && project.confirmStatus) {
      const projectUpdatable =
        [ProjectConfirmStatus.Confirmed].includes(project.confirmStatus.code) &&
        !project.nonConfirmed;

      const missingData =
        projectUpdatable &&
        (!project.carrierManager ||
          !project.trailerPlates ||
          !project.truckPlates ||
          !project.driverName ||
          !project.driverPhone);

      if (missingData) {
        notes.push({
          text: t("pleaseFillTruckData"),
          tab: PanelTab.Responsibilities,
        });
      }

      const projectNeedsApproval = [
        ProjectConfirmStatus.ConfirmUpdate,
      ].includes(project.confirmStatus.code);

      if (projectNeedsApproval) {
        notes.push({
          text: t("thereAreSomeChanges"),
        });
      }

      if (place === AlertPlace.Panel) {
        if (project.hasMissingCrm && project.hasMissingInvoice) {
          notes.push({
            text: t("hasMissingCrmAndInvoice"),
            tab: PanelTab.Documents,
          });
        } else if (project.hasMissingCrm) {
          notes.push({
            text: t("hasMissingCrm"),
            tab: PanelTab.Documents,
          });
        } else if (project.hasMissingInvoice) {
          notes.push({
            text: t("hasMissingInvoice"),
            tab: PanelTab.Documents,
          });
        }

        if (project.hasNotApprovedDocuments) {
          notes.push({
            text: t("hasRejectedDocument"),
            tab: PanelTab.Documents,
          });
        }

        if (project.confirmStatus.code === ProjectConfirmStatus.Completed) {
          notes.push({
            text: t("projectCompleted"),
            tab: PanelTab.Documents,
          });
        }

        if (project.confirmStatus.code === ProjectConfirmStatus.Rejected) {
          notes.push({
            text: t("projectRejected"),
            tab: PanelTab.Documents,
          });
        }
      }
    } else {
      notes.push({
        text: t("projectMissingStatus"),
      });
    }

    return notes;
  }, [project, place, t]);

  const handleClick = (note: INoteSettings) => {
    if (place === AlertPlace.Panel && note.tab) {
      setTab(note.tab);

      scrollToContent(100);
    }
  };

  return (
    <>
      {notifications.map((note, index) => (
        <ProjectAlert
          key={index}
          style={place === AlertPlace.Panel ? { cursor: "pointer" } : {}}
          className={classNames(
            note.className,
            place === AlertPlace.Panel ? "panel-alert" : "modal-alert"
          )}
          text={note.text}
          onClick={() => handleClick(note)}
        />
      ))}
    </>
  );
};

export { ProjectAlerts };
