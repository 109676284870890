import { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { profile } from "../profile/store/slices";

const Logout: FC = () => {
  const dispatch = useDispatch();

  const logout = useCallback(() => dispatch(profile.actions.logoutProfile()), [
    dispatch,
  ]);

  useEffect(() => {
    logout();
  }, [logout]);

  return null;
};

export { Logout };
