import { CSSProperties, FC } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  style?: CSSProperties;
  text?: string;
}

const NoData: FC<IProps> = ({ style, text }) => {
  const { t } = useTranslation("noData");

  return (
    <div className="no-data" style={style}>
      {text ? text : t("noData")}
    </div>
  );
};

export { NoData };
