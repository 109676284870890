import { PayloadAction } from "@reduxjs/toolkit";
import { call, delay, put, select, takeLatest } from "typed-redux-saga";
import { fetchUser } from ".";
import {
  adminCompaniesAPI,
  ICompaniesRequest,
} from "../../../../../services/api/admin/companies";
import { adminUsersAPI } from "../../../../../services/api/admin/users";
import { AppState } from "../../../../../store";
import { showError, showSuccess } from "../../../../alert/store/slices";
import { loader } from "../../../../common/loaders/store/slices";
import { adminUsers } from "../slices";
import { profile } from "../../../../profile/store/slices";

function* adminUsersCompaniesSaga() {
  yield* takeLatest(adminUsers.actions.setCompanySearch, debounceSearch);
  yield* takeLatest(adminUsers.actions.fetchCompanies, handleCompaniesFetch);
  yield* takeLatest(
    adminUsers.actions.fetchCompanyShort,
    handleCompanyShortFetch
  );
  yield* takeLatest(adminUsers.actions.assignCompany, assignCompany);
  yield* takeLatest(adminUsers.actions.removeCompany, removeCompany);
}

function* debounceSearch({ payload }: PayloadAction<{ userId?: string }>) {
  const data = yield* select((state: AppState) => state.adminUsers.companies);

  if (data.length > 0) {
    yield delay(500);
  }

  if (payload.userId) {
    const { user } = yield* select((state: AppState) => ({
      user: state.adminUsers.userData,
    }));

    if (!user) {
      yield* call(fetchUser, payload.userId);
    }

    yield* call(fetchCompanies, {
      onlyUserId: payload.userId,
    });
  }
}

function* handleCompaniesFetch({
  payload: { userId },
}: PayloadAction<{ userId: string }>) {
  if (userId) {
    yield* call(fetchCompanies, {
      withoutUserId: userId,
    } as ICompaniesRequest);
  }
}

function* handleCompanyShortFetch({
  payload: { userId },
}: PayloadAction<{ userId: string }>) {
  if (userId) {
    yield* call(fetchCompanyShort, {
      withoutUserId: userId,
    });
  } else {
    yield* call(fetchCompanyShort);
  }
}

function* fetchCompanies(companiesRequest: ICompaniesRequest) {
  yield put(loader.actions.startLoader());

  const { companySearch: search } = yield* select((state: AppState) => ({
    companySearch: state.adminUsers.companySearch,
  }));

  companiesRequest.search = search;

  const result = yield* call(
    adminCompaniesAPI.fetchCompanies,
    companiesRequest
  );

  if (result && result.data) {
    yield put(adminUsers.actions.setCompanies(result.data));
  } else {
    yield put(showError("Something went wrong while getting companies."));
  }

  yield put(loader.actions.stopLoader());
}

function* fetchCompanyShort(companiesRequest: ICompaniesRequest = {}) {
  yield put(loader.actions.startLoader());

  const result = yield* call(
    adminCompaniesAPI.fetchCompaniesShort,
    companiesRequest
  );

  if (result && result.data) {
    yield put(adminUsers.actions.setCompaniesShort(result.data));
  } else {
    yield put(showError("Something went wrong while getting companies."));
  }

  yield put(loader.actions.stopLoader());
}

export function* fetchCompany(id: string) {
  const response = yield* call(adminCompaniesAPI.fetchCompany, id);

  if (response && response.data) {
    yield put(adminUsers.actions.setCompany(response.data));
  } else {
    yield put(showError("Something went wrong while getting company."));
  }
}

function* assignCompany({
  payload: { userId, roleId, companyId },
}: PayloadAction<{ userId: string; companyId: string; roleId: string }>) {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminUsersAPI.assignCompany, userId, companyId);

  yield* call(adminUsersAPI.changeCompanyRole, {
    userId,
    roleId,
    companyId,
  });

  if (response && response.data) {
    yield put(adminUsers.actions.setUpdatedUser(response.data));

    yield put(showSuccess("User assigned to company."));
  } else {
    yield put(showError("Something went wrong while getting users."));
  }

  yield put(loader.actions.stopLoader());
}

function* removeCompany({
  payload: { userId, companyId },
}: PayloadAction<{ userId: string; companyId: string }>) {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminUsersAPI.removeCompany, userId, companyId);

  if (response && response.success) {
    yield put(profile.actions.fetchProfile());

    const { search } = yield* select((state: AppState) => ({
      search: state.adminUsers.companySearch,
    }));

    yield put(adminUsers.actions.setCompanySearch({ userId, search }));

    yield put(showSuccess("User removed from company."));
  } else {
    yield put(showError("Something went wrong while getting users."));
  }
}

export { adminUsersCompaniesSaga };
