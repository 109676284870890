import { useCallback, useEffect } from "react";
import { Container, Row, Col, Accordion, Card, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { LanguageCode } from "../../../i18n";
import { Header } from "../../layout/Header";
import { adminStatisticPolicies } from "./store/slices";
import Editor from "../../editor/Editor";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { AdminRoute } from "../../../constants/routes";
import { StatisticGroup } from "../../../services/api/projects";

const StatisticPolicyEdit = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const policy = useSelector((state) => state.adminStatisticPolicies.policy);
  const updatedPolicyPublished = useSelector(
    (state) => state.adminStatisticPolicies.updatedPolicyPublished
  );
  const isLoading = useSelector((state) => state.loader.isLoading);

  const fetchPolicy = useCallback(
    (id: string) => dispatch(adminStatisticPolicies.actions.fetchPolicy(id)),
    [dispatch]
  );

  const updatePolicy = useCallback(
    (id: number, groupCode: StatisticGroup) =>
      dispatch(
        adminStatisticPolicies.actions.updatePolicy({
          id,
          groupCode,
        })
      ),
    [dispatch]
  );

  const setUpdatedPolicyData = useCallback(
    (languageCode: LanguageCode, html: string) =>
      dispatch(
        adminStatisticPolicies.actions.setUpdatedPolicyData({
          languageCode,
          html,
        })
      ),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(adminStatisticPolicies.actions.resetState()),
    [dispatch]
  );

  useEffect(() => {
    if (id) {
      fetchPolicy(id);
    }

    return () => {
      reset();
    };
  }, [id, fetchPolicy, reset]);

  useEffect(() => {
    if (policy && updatedPolicyPublished) {
      history.push(AdminRoute.StatisticPolicies.List);
    }
  }, [history, policy, updatedPolicyPublished]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Header
            title="Edit policy"
            subTitle={policy ? policy.group.code : ""}
            languageDisabled
          />
        </Col>
      </Row>

      {policy?.is_active && (
        <Row>
          <Col>
            <Alert variant="warning">
              Policy is active. Read only mode activated.
            </Alert>
          </Col>
        </Row>
      )}

      {!!policy?.project_count && (
        <Row>
          <Col>
            <Alert variant="warning">
              Policy has assigned orders. Read only mode activated.
            </Alert>
          </Col>
        </Row>
      )}

      {policy && (
        <>
          <Row>
            <Col>
              <Accordion className="language-accordion">
                {Object.values(LanguageCode).map((languageCode) => (
                  <Card key={languageCode}>
                    <Accordion.Toggle as={Card.Header} eventKey={languageCode}>
                      <Link to="#">{languageCode.toUpperCase()}</Link>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={languageCode}>
                      <Card.Body>
                        <Editor
                          initialHtml={policy.data[languageCode]}
                          handleUpdate={(html) =>
                            setUpdatedPolicyData(languageCode, html)
                          }
                          readOnly={policy.is_active || !!policy.project_count}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="publish-wrapper">
                <ButtonLoader
                  buttonDisabled={
                    policy.is_active || !!policy.project_count || isLoading
                  }
                  disabled={isLoading}
                  onClick={() => {
                    if (policy) {
                      updatePolicy(policy.id, policy.group.code);
                    }
                  }}
                >
                  Update
                </ButtonLoader>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default StatisticPolicyEdit;
