/**
 * Example: 1 or 100 or 2000
 */
const integer = /^\d+$/;

/**
 * Example: 1 or 100 or 1,02 or 100,100
 */
const floatOrInteger = /^[+-]?\d+(\.\d+)?$/;

/**
 * Example: 2 or 2.20
 */
const currency = /^-?(?:0|[1-9]\d{0,2}(?:,?\d{3})*)(?:\.\d+)?$/i;

/**
 * Example: jonas@company.com
 */
const email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * Example: asdf1
 */
const containsNumber = /\d/;

/**
 * Example: ABCD654
 */
const onlyLatinNumberAndLetters = /(^[A-Za-z0-9]+$)+/;

/**
 * Example: LT999999999999
 */
const vatNumber = /(^[A-Z|a-z]{2}[0-9]+$)/;

export {
  integer,
  floatOrInteger,
  currency,
  email,
  containsNumber,
  onlyLatinNumberAndLetters,
  vatNumber,
};
