import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal } from "react-bootstrap";
import { adminClientCompanies } from "./store/slices";
import { ClientCompany, Director } from "../../../services/api/projects";

const DirectorModal = () => {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const company = useSelector(
    (state) => state.adminClientCompanies.companyForDirectorUpdate
  );
  const isLoading = useSelector(
    (state) => state.adminClientCompanies.directorUpdating
  );

  const setCompanyForDirectorUpdate = useCallback(
    (company: ClientCompany | null) =>
      dispatch(
        adminClientCompanies.actions.setCompanyForDirectorUpdate(company)
      ),
    [dispatch]
  );

  const update = useCallback(
    (director: Director, firstName: string, lastName: string) =>
      dispatch(
        adminClientCompanies.actions.updateDirector({
          director,
          firstName,
          lastName,
        })
      ),
    [dispatch]
  );

  const director = useMemo(() => {
    if (company && company.directors && company.directors[0]) {
      return company.directors[0];
    }

    return null;
  }, [company]);

  useEffect(() => {
    if (director) {
      setFirstName(director.first_name);
      setLastName(director.last_name);
    }
  }, [director]);

  if (!company) return null;

  return (
    <Modal
      show={!!company}
      onHide={() => setCompanyForDirectorUpdate(null)}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          style={{
            display: "flex",
            flexDirection: "column",
            overflowWrap: "anywhere",
          }}
        >
          <span>Edit director</span>
          <small className="text-secondary" style={{ fontSize: "14px" }}>
            {company.name}
          </small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="director-first-name">
          <Form.Label>First name</Form.Label>
          <Form.Control
            value={firstName}
            max={100}
            name="director-first-name"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="director-last-name">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            value={lastName}
            max={100}
            name="director-last-name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setCompanyForDirectorUpdate(null)}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (director) {
              update(director, firstName, lastName);
            }
          }}
          disabled={isLoading || !firstName || !lastName}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DirectorModal;
