import { FC, useCallback, useEffect } from "react";
import { Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../common/loaders/Loader";
import { Header } from "../layout/Header";
import { NotificationSettingsForm } from "./NotificationSettingsForm";
import { notificationSettings } from "./store/slices";

export interface INotificationSettingsForm {
  types: { [type: string]: boolean };
  interval: string;
}

const NotificationSettings: FC = () => {
  const { t } = useTranslation("notificationSettings");

  const mainSettings = useSelector((state) => state.settings.carrier);
  const settings = useSelector((state) => state.notificationSettings.data);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const dispatch = useDispatch();

  const fetchSettings = useCallback(
    () => dispatch(notificationSettings.actions.fetchSettings()),
    [dispatch]
  );

  const updateSettings = useCallback(
    (data: INotificationSettingsForm) =>
      dispatch(notificationSettings.actions.updateSettings(data)),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(notificationSettings.actions.reset()),
    [dispatch]
  );

  useEffect(() => {
    fetchSettings();

    return () => {
      reset();
    };
  }, [fetchSettings, reset]);

  return (
    <Container className="notification-settings" fluid>
      <Row>
        <Header title={t("notificationSettings") as string} />
      </Row>
      <Row>
        <div>
          {!mainSettings || !settings ? (
            <Loader isLoading={true} />
          ) : (
            <NotificationSettingsForm
              mainSettings={mainSettings}
              settings={settings}
              updateSettings={updateSettings}
              isLoading={isLoading}
            />
          )}
        </div>
      </Row>
    </Container>
  );
};

export { NotificationSettings };
