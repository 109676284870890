import { FC, useCallback, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { adminUsers } from "../../../store/slices";
import { AdminRoute } from "../../../../../../constants/routes";
import { Role } from "../../../../../../services/api/profile";

interface IProps {
  cell: any;
}

const ActionCell: FC<IProps> = ({ cell }) => {
  const { userId } = useParams<{ userId: string }>();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.adminUsers.userData);

  const removeCompany = useCallback(
    (userId: string, companyId: string) =>
      dispatch(adminUsers.actions.removeCompany({ userId, companyId })),
    [dispatch]
  );

  const handleRemove = () => {
    removeCompany(userId, cell.row.original.id);
  };

  const canEditRoles = useMemo(() => {
    if (user && user.roles && user.roles.length > 0) {
      return !!user.roles.find((role) => role.code !== Role.Manager);
    }

    return false;
  }, [user]);

  return (
    <Dropdown id="dropdown">
      <Dropdown.Toggle size="sm" variant="outline-primary" id="dropdown-toggle">
        <FaCaretDown />
      </Dropdown.Toggle>

      <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
        {canEditRoles && (
          <Dropdown.Item
            as={Link}
            to={`${AdminRoute.Users.Companies.EditRoles.replace(
              ":companyId",
              cell.row.original.id
            ).replace(":userId", userId)}`}
          >
            Manage roles
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={handleRemove}>Remove</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { ActionCell };
