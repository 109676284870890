import { call, put, select, takeLatest } from "typed-redux-saga";
import { adminProjectPolicies } from "./slices";
import { loader } from "../../../common/loaders/store/slices";
import {
  adminProjectPoliciesAPI,
  ProjectPolicy,
  ProjectPolicyTitleCode,
} from "../../../../services/api/admin/project-policies";
import { showError, showSuccess } from "../../../alert/store/slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../../store";
import { ProjectPolicyCode } from "../../../../services/api/admin/project-policies";
import { adminClientCompaniesAPI } from "../../../../services/api/admin/client-companies";

function* adminProjectPolicySaga() {
  yield* takeLatest(adminProjectPolicies.actions.fetchPolicies, fetchPolicies);
  yield* takeLatest(adminProjectPolicies.actions.fetchPolicy, fetchPolicy);
  yield* takeLatest(adminProjectPolicies.actions.storePolicy, storePolicy);
  yield* takeLatest(adminProjectPolicies.actions.updatePolicy, updatePolicy);
  yield* takeLatest(adminProjectPolicies.actions.deletePolicy, deletePolicy);
  yield* takeLatest(
    adminProjectPolicies.actions.activatePolicy,
    activatePolicy
  );
  yield* takeLatest(
    adminProjectPolicies.actions.fetchClientCompanies,
    fetchClientCompanies
  );
}

function* fetchPolicies({
  payload: clientCompanyId,
}: PayloadAction<number | undefined>) {
  yield put(adminProjectPolicies.actions.setPoliciesLoading(true));

  const result = yield* call(
    adminProjectPoliciesAPI.fetchPolicies,
    clientCompanyId
  );

  if (result && result.data) {
    yield put(adminProjectPolicies.actions.setPolicies(result.data));
  } else {
    yield put(showError("Something went wrong while getting policy data."));
  }

  yield put(adminProjectPolicies.actions.setPoliciesLoading(false));
}

function* fetchPolicy({ payload: id }: PayloadAction<string>) {
  yield put(loader.actions.startLoader());

  const result = yield* call(adminProjectPoliciesAPI.fetchPolicy, id);

  if (result) {
    yield put(adminProjectPolicies.actions.setPolicy(result));
  } else {
    yield put(showError("Something went wrong while getting policy data."));
  }

  yield put(loader.actions.stopLoader());
}

function* storePolicy({
  payload: { groupCode, titleCode, clientCompanyId },
}: PayloadAction<{
  groupCode: ProjectPolicyCode;
  titleCode: ProjectPolicyTitleCode;
  clientCompanyId?: number;
}>) {
  yield put(loader.actions.startLoader());

  const data = yield* select(
    (state: AppState) => state.adminProjectPolicies.newPolicyData
  );

  const result = yield* call(
    adminProjectPoliciesAPI.storePolicy,
    groupCode,
    titleCode,
    data,
    clientCompanyId
  );

  if (result && result.data) {
    yield put(adminProjectPolicies.actions.setNewPolicyPublished(true));
    yield put(showSuccess("New policies created. Activate them."));
  } else {
    yield put(showError("Something went wrong while creating policy."));
  }

  yield put(loader.actions.stopLoader());
}

function* updatePolicy({
  payload: { id, groupCode, titleCode, clientCompanyId },
}: PayloadAction<{
  id: number;
  groupCode: ProjectPolicyCode;
  titleCode: ProjectPolicyTitleCode;
  clientCompanyId: number;
}>) {
  yield put(loader.actions.startLoader());

  const data = yield* select(
    (state: AppState) => state.adminProjectPolicies.updatedPolicyData
  );

  const result = yield* call(
    adminProjectPoliciesAPI.updatePolicy,
    id,
    groupCode,
    titleCode,
    clientCompanyId,
    data
  );

  if (result && result.data) {
    yield put(adminProjectPolicies.actions.setUpdatedPolicyPublished(true));
    yield put(showSuccess("Policy updated."));
  } else {
    yield put(showError("Something went wrong while updating policy."));
  }

  yield put(loader.actions.stopLoader());
}

function* deletePolicy({ payload: policy }: PayloadAction<ProjectPolicy>) {
  yield put(loader.actions.startLoader());

  const result = yield* call(adminProjectPoliciesAPI.deletePolicy, policy.id);

  if (result && result.success) {
    yield put(showSuccess("Policy deleted."));
  } else {
    yield put(showError("Something went wrong while deleting policy."));
  }

  yield put(
    adminProjectPolicies.actions.fetchPolicies(policy.client_company.id)
  );

  yield put(loader.actions.stopLoader());
}

function* activatePolicy({ payload: policy }: PayloadAction<ProjectPolicy>) {
  yield put(loader.actions.startLoader());

  const result = yield* call(adminProjectPoliciesAPI.activatePolicy, policy.id);

  if (result && result.data) {
    yield put(showSuccess("Policy activated."));
  } else {
    yield put(showError("Something went wrong while activating policy."));
  }

  yield put(
    adminProjectPolicies.actions.fetchPolicies(policy.client_company.id)
  );

  yield put(loader.actions.stopLoader());
}

function* fetchClientCompanies() {
  yield put(adminProjectPolicies.actions.setClientCompaniesLoading(true));

  const result = yield* call(adminClientCompaniesAPI.fetchClientCompanies);

  if (result && result.data) {
    yield put(adminProjectPolicies.actions.setClientCompanies(result.data));
  } else {
    yield put(showError("Something went wrong while getting policy data."));
  }

  yield put(adminProjectPolicies.actions.setClientCompaniesLoading(false));
}

export { adminProjectPolicySaga };
