import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExternalLinkAlt, FaFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { LanguageCode } from "../../../i18n";
import { ProjectPolicyTitleCode } from "../../../services/api/admin/project-policies";
import { IProject } from "../../../services/api/projects";
import { AdditionalInformationModal } from "./details/modals/AdditionalInformationModal";
import { SpecialRequirementsModal } from "./details/modals/SpecialRequirementsModal";

interface IProps {
  project: IProject;
  defaultLanguage?: LanguageCode;
  style?: React.CSSProperties;
}

const DocumentLinks: FC<IProps> = ({ project, style, defaultLanguage }) => {
  const { t } = useTranslation("documentLinks");

  const [additionalVisible, setAdditionalVisible] = useState<boolean>(false);
  const [specialVisible, setSpecialVisible] = useState<boolean>(false);

  const generalRulesUrl = useMemo(() => {
    switch (defaultLanguage) {
      case LanguageCode.EN:
        return "https://www.girteka.eu/legal-notices/legal-notices-for-carriers";
      case LanguageCode.PL:
        return "https://www.girteka.eu/dokumenty-prawne-dla-przewoznikow";
      case LanguageCode.LT:
        return "https://www.girteka.eu/lt/teisiniai-dokumentai-vezejams";
      case LanguageCode.RU:
        return "https://www.girteka.eu/ru/juridicheskie-dokumenty/juridicheskie-dokumenty-dlja-perevozchikov";
      default:
        return "https://www.girteka.eu/legal-notices/legal-notices-for-carriers";
    }
  }, [defaultLanguage]);

  return (
    <>
      <div className="document-links" style={style}>
        {project.secondPolicy && (
          <div>
            <span>
              <FaFileAlt />
              <Link to="#" onClick={() => setAdditionalVisible(true)}>
                {t(project.secondPolicy.title.code, {
                  ns: "policies",
                }).toUpperCase()}
              </Link>
            </span>
          </div>
        )}
        {project.statisticPolicy && (
          <>
            <hr />
            <div>
              <span>
                <FaFileAlt />
                <Link to="#" onClick={() => setSpecialVisible(true)}>
                  {t("specialClientRequirements")}
                </Link>
              </span>
            </div>
          </>
        )}
        {!!(
          project.secondPolicy &&
          project.secondPolicy.title.code ===
            ProjectPolicyTitleCode.AdditionalInformation
        ) && (
          <>
            <hr />
            <div>
              <span>
                <FaFileAlt />
                <Link
                  to={{
                    pathname: generalRulesUrl,
                  }}
                  target="_blank"
                >
                  {t("generalRulesForCarriers")}
                </Link>
              </span>
              <Link
                to={{
                  pathname: generalRulesUrl,
                }}
                target="_blank"
              >
                <FaExternalLinkAlt />
              </Link>
            </div>
          </>
        )}
      </div>
      {project.secondPolicy && (
        <AdditionalInformationModal
          policy={project.secondPolicy}
          visible={additionalVisible}
          onHide={() => setAdditionalVisible(false)}
        />
      )}
      {project.statisticPolicy && (
        <SpecialRequirementsModal
          isVisible={specialVisible}
          statisticPolicy={project.statisticPolicy}
          onHide={() => setSpecialVisible(false)}
        />
      )}
    </>
  );
};

export { DocumentLinks };
