import { FC, useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FaUserLock } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { LanguageCode } from "../../../i18n";
import { LanguagePills } from "../../common/language-pills/LanguagePills";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { policies } from "../store/slices";
import { Loader } from "../../common/loaders/Loader";

interface IProps {
  isVisible: boolean;
  handleDeny?: () => void;
  handleConfirm?: (consent: boolean) => void;
}

const MarketingPolicyModal: FC<IProps> = ({
  isVisible,
  handleDeny,
  handleConfirm,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("marketingPolicyModal");

  const [language, setLanguage] = useState<LanguageCode>(LanguageCode.EN);
  const [checked, setChecked] = useState(false);

  const policy = useSelector((state) => state.policies.marketingPolicy);
  const isLoading = useSelector((state) => state.policies.isLoading);
  const isSubmitting = useSelector((state) => state.loader.isLoading);

  const fetchPolicy = useCallback(
    () => dispatch(policies.actions.fetchMarketingPolicy()),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(policies.actions.resetMarketingPolicy()),
    [dispatch]
  );

  useEffect(() => {
    if (isVisible) {
      fetchPolicy();
    }

    return () => {
      reset();
    };
  }, [fetchPolicy, reset, isVisible]);

  useEffect(() => {
    if (!isVisible && checked) {
      setChecked(false);
    }
  }, [checked, isVisible]);

  return (
    <Modal
      show={isVisible}
      onHide={handleDeny}
      size="xl"
      className="marketing-policy-modal"
      scrollable
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          <FaUserLock /> {t("marketingPolicy")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LanguagePills
          onSelect={(language) => setLanguage(language as LanguageCode)}
          languages={Object.values(LanguageCode)}
          style={{
            textTransform: "uppercase",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        />

        <Loader isLoading={isLoading} />

        {policy && (
          <div dangerouslySetInnerHTML={{ __html: policy.data[language] }} />
        )}
      </Modal.Body>
      <Modal.Footer>
        {handleConfirm ? (
          <>
            <Form.Check
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              id="marketingConsent"
              name="marketingConsent"
              type="checkbox"
              label={t("iAgreeProcessing")}
              className="consent-check"
            />
            <ButtonLoader
              id="submit"
              disabled={isSubmitting}
              onClick={() => handleConfirm(checked)}
            >
              {t("submit")}
            </ButtonLoader>
          </>
        ) : (
          <Button variant="outline-primary" onClick={handleDeny}>
            {t("close")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default MarketingPolicyModal;
