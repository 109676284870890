import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "../../../../services/api";
import {
  DocumentDirection,
  EDIClient,
} from "../../../../services/api/admin/files";

interface IFileState {
  data: any[];
  perPage: number;
  activePage: number;
  meta: IPagination | null;
  search: string;
  documentType: DocumentType | null;
  documentDirection: DocumentDirection | null;
  client: EDIClient | null;
  dateFrom: string | null;
  dateTo: string | null;
}

const initialState: IFileState = {
  data: [],
  perPage: 10,
  activePage: 1,
  meta: null,
  search: "",
  documentType: null,
  documentDirection: null,
  client: null,
  dateFrom: null,
  dateTo: null,
};

const adminFiles = createSlice({
  name: "adminFiles",
  initialState,
  reducers: {
    fetchDocuments: (state, action) => {
      //
    },
    setData: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.activePage = 1;
    },
    setDocumentType: (state, action) => {
      state.documentType = action.payload;
      state.activePage = 1;
    },
    setDocumentDirection: (state, action) => {
      state.documentDirection = action.payload;
      state.activePage = 1;
    },
    setClient: (state, action) => {
      state.client = action.payload;
      state.activePage = 1;
    },
    setDates: (state, action) => {
      if (typeof action.payload.dateFrom !== "undefined") {
        state.dateFrom = action.payload.dateFrom;
      }

      if (typeof action.payload.dateTo !== "undefined") {
        state.dateTo = action.payload.dateTo;
      }

      state.activePage = 1;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    reset: () => initialState,
  },
});

export { adminFiles };
