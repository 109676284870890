import { FC } from "react";
import { Props, StylesConfig } from "react-select";
import Select from "react-select";

const customStyles: StylesConfig<any, false> = {
  control: (provided: any) => ({
    ...provided,
    width: "100%",
    maxWidth: "350px",
    border: "1px solid #ced4da",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #94d9b4",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0b5669" : undefined,
    "&:hover": {
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0b5669" : "#f1f2f4",
    },
    "&:active": {
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0b5669" : "#f1f2f4",
    },
  }),
};

interface IProps extends Props {}

const SelectSearch: FC<IProps> = (props) => {
  return <Select styles={customStyles} {...props} />;
};

export { SelectSearch };
