import { FC, useCallback, useEffect } from "react";
import { Row, Container, Image, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { email } from "../../../constants/regex";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { forgotPassword } from "./store/slices";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../translations/LanguageSwitcher";
import { LanguageCode } from "../../../i18n";
import { Cover } from "../../cover/Cover";

export interface IForgotPasswordData {
  email: string;
  languageCode: LanguageCode;
}

const ForgotPassword: FC = () => {
  const { t } = useTranslation("forgotPassword");

  const isLoading = useSelector((state) => state.loader.isLoading);
  const resetEmailSent = useSelector(
    (state) => state.forgotPassword.resetEmailSent
  );
  const languageCode = useSelector((state) => state.translations.current.code);

  const history = useHistory();
  const dispatch = useDispatch();

  const sendResetEmail = useCallback(
    (data: IForgotPasswordData) =>
      dispatch(forgotPassword.actions.sendForgotPassword(data)),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(forgotPassword.actions.reset()), [
    dispatch,
  ]);

  const { register, errors, handleSubmit } = useForm<IForgotPasswordData>();

  const onSubmit = async (data: IForgotPasswordData) => {
    sendResetEmail({
      ...data,
      languageCode,
    });
  };

  useEffect(() => {
    if (resetEmailSent) {
      history.push("/");
    }
  }, [resetEmailSent, history]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Container className="forgot-password" fluid>
      <Cover />

      <Row className="language-row">
        <LanguageSwitcher />
      </Row>
      <Row className="form-row">
        <Col />
        <Col xs={12} md={6} lg={5} xl={3}>
          <Row>
            <Col className="logo-place">
              <Link to="/">
                <Image src="/images/logo.svg" />
              </Link>
            </Col>
          </Row>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} controlId="email">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  ref={register({ required: true, pattern: email })}
                  isInvalid={!!errors.email}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.type === "required" && t("requiredField")}
                  {errors.email?.type === "pattern" && t("invalidEmail")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <div className="register-button">
              <ButtonLoader
                variant="primary"
                type="submit"
                disabled={isLoading}
              >
                {t("send")}
              </ButtonLoader>
            </div>
          </Form>
        </Col>
        <Col />
      </Row>
    </Container>
  );
};

export { ForgotPassword };
