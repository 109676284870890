import { call, put } from "typed-redux-saga";
import { maintenanceAPI } from "../../../services/api/maintenance";
import { maintenance } from "./slices";

function* maintenanceSagas() {
  yield* call(checkMaintenanceStatus);
}

function* checkMaintenanceStatus() {
  const response = yield* call(maintenanceAPI.check);

  if (response && response.data) {
    yield put(maintenance.actions.setData(response.data));

    if (response.data.length > 0) {
      yield put(maintenance.actions.showAlert(true));
    }
  }
}

export { maintenanceSagas, checkMaintenanceStatus };
