import { FC, useCallback } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { adminFiles } from "../../store/slices";
import DatePicker from "react-datepicker";
import { FaTimes } from "react-icons/fa";

const DateFilter: FC = () => {
  const dateFrom = useSelector((state) => state.adminFiles.dateFrom);
  const dateTo = useSelector((state) => state.adminFiles.dateTo);

  const dispatch = useDispatch();
  const setDates = useCallback(
    (dates: any) => dispatch(adminFiles.actions.setDates(dates)),
    [dispatch]
  );

  return (
    <div className="date-filter">
      <div>
        <Form.Control
          onChange={(dateFrom) => setDates({ dateFrom })}
          as={DatePicker as any}
          autoComplete="off"
          size="sm"
          selected={dateFrom ? new Date(dateFrom) : undefined}
          showTimeSelect
          dateFormat="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          placeholderText="Date from"
        />
        {dateFrom && <FaTimes onClick={() => setDates({ dateFrom: null })} />}
      </div>
      <div>
        <Form.Control
          onChange={(dateTo) => setDates({ dateTo })}
          as={DatePicker as any}
          autoComplete="off"
          size="sm"
          selected={dateTo ? new Date(dateTo) : undefined}
          showTimeSelect
          dateFormat="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          placeholderText="Date to"
        />
        {dateTo && <FaTimes onClick={() => setDates({ dateTo: null })} />}
      </div>
    </div>
  );
};

export { DateFilter };
