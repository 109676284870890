import { API, ApiResource, HTTPMethod } from ".";

const fetchPolicies = async (projectId: number): Promise<ApiResource<any>> => {
  const response = await API.request<ApiResource<any>>(
    `/carrier/project-policies/${projectId}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const projectPolicyAPI = {
  fetchPolicies,
};

export { projectPolicyAPI };
