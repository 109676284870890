import { FC } from "react";
import { IProfileState } from "../../profile/store/slices";
import { SidebarLogo } from "./SidebarLogo";
import { SidebarToggle } from "./SidebarToggle";

interface IProps {
  collapsed: boolean;
  profile: IProfileState;
  bottomItems: JSX.Element;
  onToggle: () => void;
  children: React.ReactNode;
}

const SidebarLayout: FC<IProps> = ({
  collapsed,
  profile,
  bottomItems,
  onToggle,
  children,
}) => {
  return (
    <div
      className="sidebar"
      style={{
        width: collapsed ? "70px" : "230px",
      }}
    >
      <div>
        <SidebarToggle collapsed={collapsed} onClick={onToggle} />
        <SidebarLogo collapsed={collapsed} profile={profile} />
        <div className="sidebar-content">{children}</div>
      </div>
      <div>
        <div className="sidebar-content">{bottomItems}</div>
      </div>
    </div>
  );
};

export { SidebarLayout };
