import { call, put, takeLatest } from "typed-redux-saga";
import { showError } from "../../alert/store/slices";
import { translated } from "../../../i18n";
import { policies } from "./slices";
import { policyAPI } from "../../../services/api/policies";
import { profile } from "../../profile/store/slices";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  generalPolicyAPI,
  GeneralPolicyCode,
} from "../../../services/api/general-policies";

function* policySagas() {
  yield* takeLatest(policies.actions.acceptPolicies, acceptPolicies);
  yield* takeLatest(
    policies.actions.submitMarketingPolicy,
    submitMarketingPolicy
  );
  yield* takeLatest(policies.actions.fetchPrivacyPolicy, fetchPrivacyPolicy);
  yield* takeLatest(
    policies.actions.fetchMarketingPolicy,
    fetchMarketingPolicy
  );
  yield* takeLatest(policies.actions.fetchTermsPolicy, fetchTermsPolicy);
}

function* acceptPolicies() {
  yield put(policies.actions.setIsLoading(true));

  const result = yield* call(policyAPI.acceptPolicies);

  if (result && result.success) {
    yield put(policies.actions.toggleTermsConditions());

    yield put(profile.actions.setPoliciesAccepted());
  } else {
    yield put(showError(translated.errorWhileAcceptingPolicy));
  }

  yield put(policies.actions.setIsLoading(false));
}

function* submitMarketingPolicy({ payload: consent }: PayloadAction<boolean>) {
  yield put(policies.actions.setIsLoading(true));

  const result = yield* call(policyAPI.submitMarketingPolicy, consent);

  if (result && result.success) {
    yield put(profile.actions.setMarketingPolicySubmitted(consent));

    yield put(policies.actions.toggleMarketingPolicy());
  } else {
    yield put(showError(translated.errorWhileAcceptingPolicy));
  }

  yield put(policies.actions.setIsLoading(false));
}

function* fetchPrivacyPolicy() {
  yield put(policies.actions.setIsLoading(true));

  const result = yield* call(
    generalPolicyAPI.fetchPolicy,
    GeneralPolicyCode.PrivacyPolicy
  );

  if (result && result.data) {
    yield put(policies.actions.setPrivacyPolicy(result));
  } else {
    yield put(showError("Error while fetching policy."));
  }

  yield put(policies.actions.setIsLoading(false));
}

function* fetchMarketingPolicy() {
  yield put(policies.actions.setIsLoading(true));

  const result = yield* call(
    generalPolicyAPI.fetchPolicy,
    GeneralPolicyCode.MarketingPolicy
  );

  if (result && result.data) {
    yield put(policies.actions.setMarketingPolicy(result));
  } else {
    yield put(showError("Error while fetching policy."));
  }

  yield put(policies.actions.setIsLoading(false));
}

function* fetchTermsPolicy() {
  yield put(policies.actions.setIsLoading(true));

  const result = yield* call(
    generalPolicyAPI.fetchPolicy,
    GeneralPolicyCode.TermsPolicy
  );

  if (result && result.data) {
    yield put(policies.actions.setTermsPolicy(result));
  } else {
    yield put(showError("Error while fetching policy."));
  }

  yield put(policies.actions.setIsLoading(false));
}

export { policySagas };
