import { FC } from "react";
import ContentLoader from "react-content-loader";

const DocumentsLoader: FC = () => {
  const wrapper = document.getElementById("documents-scroll-area");

  const width = wrapper ? wrapper.scrollWidth - 10 : 400;

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={330}
      viewBox={`0 0 ${width} 330`}
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      style={{ marginLeft: "10px" }}
    >
      <rect x="0" y="0" rx="5" ry="5" width={width} height="35" />
      <rect x="0" y="45" rx="5" ry="5" width={width} height="35" />
      <rect x="0" y="90" rx="5" ry="5" width={width} height="35" />
      <rect x="0" y="135" rx="5" ry="5" width={width} height="35" />
      <rect x="0" y="180" rx="5" ry="5" width={width} height="35" />
      <rect x="0" y="225" rx="5" ry="5" width={width} height="35" />
      <rect x="0" y="270" rx="5" ry="5" width={width} height="35" />
    </ContentLoader>
  );
};

export { DocumentsLoader };
