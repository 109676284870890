import { createSlice } from "@reduxjs/toolkit";

export interface ICarrierWizardState {
  isVisible: boolean;
}

const initialState: ICarrierWizardState = {
  isVisible: false,
};

const carrierWizard = createSlice({
  name: "carrierWizard",
  initialState,
  reducers: {
    toggle: (state) => {
      state.isVisible = !state.isVisible;
    },
    checkSeen: (state) => {
      //
    },
    reset: () => initialState,
  },
});

export { carrierWizard };
