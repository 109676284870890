import { CSSProperties, FC } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { Role } from "../../../services/api/profile";

interface IProps {
  statuses: any;
  activeStatus: any;
  className?: string;
  style?: CSSProperties;
  handleClick: (status: any) => void;
}

const StatusFilter: FC<IProps> = ({
  statuses,
  activeStatus,
  className,
  style,
  handleClick,
}) => {
  return (
    <div className={className} style={style}>
      <ButtonGroup>
        {Object.values(statuses).map((status: any, index) => {
          if (status.code === Role.SuperAdmin) return null;
          if (status.code === Role.CarrierAdmin) return null;

          return (
            <Button
              variant="outline-light"
              onClick={() => handleClick(status.code)}
              active={status.code === activeStatus}
              key={index}
              className="status-filter"
            >
              {status.name}
            </Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
};

export { StatusFilter };
