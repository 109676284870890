import i18n from "i18next";
import { registerLocale } from "react-datepicker";
import { initReactI18next } from "react-i18next";
import { ru, pl, lt } from "date-fns/locale";

export enum LanguageCode {
  EN = "en",
  RU = "ru",
  PL = "pl",
  LT = "lt",
}

export enum LanguageName {
  English = "English",
  Russian = "Russian",
  Polish = "Polish",
  Lithuanian = "Lithuanian",
}

export enum FlagCode {
  GB = "gb",
  RU = "ru",
  PL = "pl",
  LT = "lt",
}

export interface ILanguage {
  code: LanguageCode;
  name: LanguageName;
  flagCode: FlagCode;
}

export const languages = [
  {
    code: LanguageCode.EN,
    name: LanguageName.English,
    flagCode: FlagCode.GB,
  },
  {
    code: LanguageCode.RU,
    name: LanguageName.Russian,
    flagCode: FlagCode.RU,
  },
  {
    code: LanguageCode.PL,
    name: LanguageName.Polish,
    flagCode: FlagCode.PL,
  },
  {
    code: LanguageCode.LT,
    name: LanguageName.Lithuanian,
    flagCode: FlagCode.LT,
  },
];

i18n.use(initReactI18next).init({
  resources: {
    en: {
      notifications: {
        all: "All",
        notifications: "Notifications",
        showing: "Showing",
        loadMore: "Load more",
        viewAllNotifications: "View all notifications",
        errorWhileGettingNotifications: "Error while getting notifications.",
        errorWhileUpdatingNotifications: "Error while updating notifications.",
        errorWhileDeletingNotifications: "Error while deleting notifications.",
        application: "Application status",
        document: "Document status",
        project: "Project status",
        projectCreated: "New order has been created.",
        projectUpdated: "Order has been updated.",
        documentUploaded: "New document has been uploaded.",
        documentUpdated: "Document status has been updated.",
        applicationAccepted: "Application has been confirmed.",
        applicationRejected: "Application has been rejected.",
        markAllAsRead: "Mark all as read",
      },
      login: {
        partnersLogin: "Partners Login",
        email: "Email",
        password: "Password",
        forgotPassword: "Forgot password?",
        login: "Login",
        dontHaveAccount: "Don't have an account?",
        register: "Register",
        requiredField: "Required field.",
        invalidEmail: "Invalid email.",
        tooMuchLetters: "Too much letters",
        pleaseLogin: "Please login.",
        invalidUsernamePassword: "Invalid username or password. Try again.",
        joinManager: "Join if you are Everwest manager",
        here: "here",
        inPartnershipWith: "In partnership with",
      },
      registration: {
        partnersRegistration: "Partners Registration",
        email: "Email",
        name: "Name",
        lastName: "Last name",
        phone: "Phone",
        password: "Password",
        confirmPassword: "Confirm password",
        register: "Register",
        requiredField: "Required field.",
        invalidEmail: "Invalid email.",
        tooShortNumber: "Too short number.",
        minCharsRequired: "Minimum 8 characteres required.",
        oneUpperCaseRequired: "At least 1 upper case letter required.",
        oneLowerCaseRequired: "At least 1 lower case letter required.",
        oneNumberRequired: "At least 1 number required.",
        passwordsDontMatch: "Passwords don't match.",
        registrationSuccessful:
          "Registration successful. Please confirm email.",
        errorWhileRegistering: "Something went wrong while registering.",
        verificationSent: "Verification sent.",
        errorWhileResendingVerification:
          "Something went wrong while resending verification.",
        emailVerified: "Email verifed.",
        errorWhileVerifyingEmail: "Something went wrong while verifying email.",
        iHaveReadAndAgreeGirtekaLogistics:
          "I have read and agree to the Everwest",
        privacyPolicy: "Privacy Policy",
        iHaveReadAndAgreeToThe: "I have read and agree to the",
        termsAndConditions: "Terms and conditions",
        iAgreeProcessing:
          "I agree to the processing of my contact data for direct marketing purposes by Everwest group companies in accordance with",
        directMarketing: "Direct marketing policy.",
      },
      forgotPassword: {
        partnersForgotPassword: "Partners Forgot password",
        email: "Email",
        send: "Send",
        requiredField: "Required field.",
        invalidEmail: "Invalid email.",
        resetLinkSent: "Reset link sent. Please check your email.",
        errorWhileSendingLink:
          "Something went wrong while sending forgot password link.",
      },
      resetPassword: {
        partnersResetPassword: "Partners Reset password",
        email: "Email",
        password: "Password",
        confirmPassword: "Confirm password",
        reset: "Reset",
        requiredField: "Required field.",
        invalidEmail: "Invalid email.",
        minCharsRequired: "Minimum 8 characteres required.",
        oneUpperCaseRequired: "At least 1 upper case letter required.",
        oneLowerCaseRequired: "At least 1 lower case letter required.",
        oneNumberRequired: "At least 1 number required.",
        passwordsDontMatch: "Passwords don't match.",
        passwordResetedPleaseLogin: "Password reseted. Please login.",
        errorWhileResetingPassword:
          "Something went wrong while reseting password.",
      },
      invitation: {
        invitation: "Invitation",
        email: "Email",
        name: "Name",
        lastName: "Last name",
        phone: "Phone",
        password: "Password",
        confirmPassword: "Confirm password",
        createAccount: "Create account",
        requiredField: "Required field.",
        invalidEmail: "Invalid email.",
        tooShortNumber: "Too short number.",
        minCharsRequired: "Minimum 8 characteres required.",
        oneUpperCaseRequired: "At least 1 upper case letter required.",
        oneLowerCaseRequired: "At least 1 lower case letter required.",
        oneNumberRequired: "At least 1 number required.",
        passwordsDontMatch: "Passwords don't match.",
        iHaveReadAndAgreeGirtekaLogistics:
          "I have read and agree to the Everwest",
        privacyPolicy: "Privacy Policy",
        iHaveReadAndAgreeToThe: "I have read and agree to the",
        termsAndConditions: "Terms and conditions",
        iAgreeProcessing:
          "I agree to the processing of my contact data for direct marketing purposes by Everwest group companies in accordance with",
        directMarketing: "Direct marketing policy.",
      },
      profile: {
        myProfile: "My profile",
        change: "Change",
        edit: "Edit",
        resendVerification: "Resend verification",
        delete: "Delete",
        verified: "Verified",
        notVerified: "Not verified",
        pleaseVerifyEmail: "Please verify your email address.",
        errorWhileFetchingProfile:
          "Something went wrong while fetching profile.",
        errorWhileAcceptingPolicy:
          "Something went wrong while accepting policy.",
        leader: "Leader",
        manager: "Manager",
        iAgreeProcessing:
          "I agree to the processing of my contact data for direct marketing purposes by Everwest group companies in accordance with",
        directMarketing: "Direct marketing policy.",
      },
      profileEdit: {
        pleaseUpdateProfile:
          "Please update profile in order to continue using portal.",
        myProfile: "My profile",
        firstName: "First name",
        lastName: "Last name",
        requiredField: "Required field.",
        email: "Email",
        invalidEmail: "Invalid email.",
        phone: "Phone",
        tooShortNumber: "Too short number.",
        cancel: "Cancel",
        save: "Save",
        profileInformationUpdated: "Profile information updated.",
        errorWhileUpdatingProfile:
          "Something went wrong while updating profile.",
      },
      deleteProfileModal: {
        deleteProfile: "Delete profile?",
        ifYouDeleteProfile: "After delete you will not be able to login again.",
        cancel: "Cancel",
        delete: "Delete",
        profileDeleted: "Profile deleted.",
        errorWhileDeletingProfile:
          "Something went wrong while deleting profile.",
      },
      changePassword: {
        yourPasswordExpired:
          "Your password expired. Please update to a new one.",
        changePassword: "Change password",
        existingPassword: "Existing password",
        requiredField: "Required field.",
        minCharsRequired: "Minimum 8 characteres required.",
        oneUpperCaseRequired: "At least 1 upper case letter required.",
        oneLowerCaseRequired: "At least 1 lower case letter required.",
        oneNumberRequired: "At least 1 number required.",
        passwordsDontMatch: "Passwords don't match.",
        confirmPassword: "Confirm password",
        cancel: "Cancel",
        save: "Save",
        passwordChanged: "Password changed.",
        errorWhileChangingPassword:
          "Something went wrong while changing password.",
      },
      userEdit: {
        userEdit: "User edit",
        firstName: "First name",
        requiredField: "Required field.",
        lastName: "Last name",
        email: "Email",
        invalidEmail: "Invalid email.",
        phone: "Phone",
        tooShortNumber: "Too short number.",
        role: "Role",
        cancel: "Cancel",
        save: "Save",
        errorWhileFetchingUser: "Something went wrong while getting user.",
        userUpdated: "User updated.",
        errorWhileUpdatingUser: "Something went wrong while updating user.",
        accountantRoleHasReadOnly: "Viewer role has read-only permissions.",
      },
      userInvitation: {
        userInvitation: "User invitation",
        email: "Email",
        requiredField: "Required field.",
        invalidEmail: "Invalid email.",
        role: "Role",
        invite: "Invite",
        userInvited: "User invited",
        errorWhileInvitingUser: "Something went wrong while inviting user.",
        tokenMissing: "Token missing",
        accountCreatedPleaseLogin: "Account created. Please login.",
        invitationExpired: "Invitation expired.",
        errorWhileCreatingAccount:
          "Something went wrong while creating account.",
        accountantRoleHasReadOnly: "Viewer role has read-only permissions.",
      },
      userList: {
        users: "Users",
        invite: "Invite",
        review: "Review",
        edit: "Edit",
        remove: "Remove",
        removeFromCompany: "Remove from company",
        email: "Email",
        name: "Name",
        surname: "Surname",
        phone: "Phone",
        roles: "Roles",
        actions: "Actions",
        errorWhileGettingUsers: "Something went wrong while getting users.",
        userRemoved: "User removed.",
        errorWhileDeletingUsers: "Something went wrong while deleting user.",
      },
      userShow: {
        user: "User",
        changeRole: "Change role",
        removeFromCompany: "Remove from company",
      },
      notFound: {
        pageNotFound: "Page not found",
        pageDoesNotExist: "Page with current URL does not exist.",
        goBack: "Go back",
      },
      header: {
        profile: "Profile",
        notificationSettings: "Notification settings",
        logout: "Logout",
        projects: "Projects",
        documents: "Documents",
        users: "Users",
        applications: "Applications",
      },
      sidebar: {
        applications: "Applications",
        orders: "Orders",
        contacts: "Contacts",
        projects: "Orders",
        users: "Users",
        adminPanel: "Admin panel",
        developerPanel: "Developer panel",
        profile: "Profile",
        logout: "Logout",
        documents: "Documents",
        notifications: "Notifications",
        language: "Language",
        activityLog: "Activity Log",
        ediUploader: "EDI uploader",
        translations: "Translations",
        cache: "Cache",
        emailTemplates: "Email templates",
        issueMail: "Issue mail",
        companies: "Companies",
        help: "Help",
        feedback: "Feedback",
        maintenance: "Maintenance",
        files: "Files",
        addresses: "Addresses",
        sessions: "Sessions",
        inPartnershipWith: "In partnership with",
        mailSettings: "Mail settings",
      },
      contacts: {
        languages: "Languages:",
        contactUs: "Contact us",
      },
      fileDropzone: {
        dropFileHere: "Drop file here",
        dragDropByClicking: "Drag and drop or select file/s by clicking here",
        someFilesNotUploaded: "Some files cannot be uploaded",
        tryAgain: "Try again",
        dragDropFilesHere: "Drag and drop your files here or:",
        browseFiles: "Browse files",
      },
      confirmModal: {
        no: "No",
        yes: "Yes",
      },
      searchInput: {
        search: "Search",
      },
      showPages: {
        show: "Show",
        entries: "entries",
      },
      showPagesInfo: {
        showingEntries: "Showing {activePage} to {perPage} of {pages} entries",
      },
      toaster: {
        justNow: "Just now",
        success: "Success",
        error: "Error",
        info: "Info",
        warning: "Warning",
      },
      addressInput: {
        searchNew: "Search new",
        selectExisting: "Select existing",
        selectAddress: "Select address",
        errorWhileGettingAddresses:
          "Something went wrong while getting addresses.",
      },
      applicationFiles: {
        selectType: "Select type",
        requiredField: "Required field.",
        allFiles: "All files",
        totalSizeLimit: "Total size limit",
        documentDeleted: "Document deleted.",
        errorWhileDeletingDocument:
          "Something went wrong while deleting document.",
        documentsUploaded: "Documents uploaded.",
        errorWhileUploadingDocuments:
          "Something went wrong while uploading documents.",
        allFilesSizeExceeds: "All files size exceeds",
        documentUpdated: "Document updated.",
        errorWhileUpdatingDocument:
          "Something went wrong while updating documents.",
      },
      application: {
        recommendation: "Recommendation",
        companyName: "Company name",
        requiredField: "Required field.",
        companyAddress: "Company address",
        firstName: "First name",
        lastName: "Last name",
        position: "Position",
        emailAddress: "E-mail address",
        invalidEmail: "Invalid email.",
        mobilePhone: "Mobile phone",
        existingPartnerApplication: "Existing Partner Application",
        newPartnerApplication: "New Partner Application",
        cancellationComment: "Cancellation comment:",
        company: "Company",
        details: "Details",
        companyRegistrationNumber: "Company registration number",
        vatNumber: "VAT number",
        vatNumberValidation:
          "Incorrect VAT format. Valid example: LT999999999999.",
        address: "Address",
        street: "Street",
        cityZip: "City, ZIP code",
        country: "Country",
        selectCountry: "Select country",
        bankAccount: "Bank account",
        bankName: "Bank name",
        accountNumber: "Account number (IBAN)",
        swift: "SWIFT",
        currency: "Currency",
        selectCurrency: "Select currency",
        companyContacts: "Company contacts",
        phoneOptional: "Phone (optional)",
        insurance: "Insurance",
        companyBrokerName: "Company / Broker name",
        contactPhone: "Contact phone",
        companyDocuments: "Company documents",
        allowedFiles: "Allowed files",
        saveDraft: "Save draft",
        submit: "Submit",
        errorWhileStoringApplication:
          "Something went wrong while storing application.",
        applicationUpdated: "Application updated.",
        errorWhileUpdatingApplication:
          "Something went wrong while updating application.",
        errorWhileGettingApplication:
          "Something went wrong while fetching application.",
        applicationSent: "Application sent. Please wait for approval.",
        directions: "Directions",
        direction: "Direction",
        selectDirection: "Select direction",
        pleaseChooseMainDirection: "Please choose the main operated direction.",
        transId: "Trans ID",
        iHaveTransId: "I have Trans ID",
        requiredDocs:
          "Required documents: <strong>VAT registration certificate</strong>, <strong>Company registration certificate</strong>,  <strong>Carrier's license</strong> (not required for freight forwarder), at least one document of <strong>CMR insurance</strong> or <strong>Freight forwarder's insurance</strong>",
        questionnaire: "Questionnaire",
        yourAnswersAreImportant:
          "Your answers are important for further cooperation.",
        yes: "Yes",
        no: "No",
        productSafetyManagement:
          "Do you have a certificate proving your expertise in product safety management (ISO22001/ BRC/ IFS)?",
        foodSafetyPolicy: "Do you have a Food/ Product Safety Policy?",
        haccpPlan: "Do you have a HACCP plan?",
        chemicalsInCleansing:
          "Do you track chemicals used in cleaning processes relevant for product safety?",
        pestControlSystem: "Do you have a pest control system?",
        cleaningSchedule:
          "Do you have a cleaning schedule and procedure for trailers and facilities?",
        proceduresForOperation:
          "Do you have procedures for operation and maintenance of all vehicles and equipment?  ",
        expertiseSecurityManagement:
          "Do you have a certificate proving your expertise in security management (TAPA TSR/ ISO28001)?",
        securityPolicy: "Do you have a Security Policy?",
        hvttCargo:
          "Are there specific procedures for the handling of high value theft targeted (HVTT) cargo in place?",
        secureVehicleParking:
          "Do the drivers' Journey Plans include safe and secure vehicle parking?",
        routesAndStops: "Do you plan routes and stops in advance?",
        driverAssessment:
          "Do You have a background check for driver assessment transporting high value goods?",
        addIsoToCompanyDocuments:
          'Add ISO22001/ BRC/ IFS to "Company documents" section.',
        addTapaToCompanyDocuments:
          'Add TAPA TSR/ ISO28001 to "Company documents" section.',
        isoDocument: "ISO22001/ BRC/ IFS",
        tapaDocument: "TAPA TSR/ ISO28001",
        asOurValuedSupllier:
          "As our valued supplier and part of Everwest supply chain, we kindly ask You to provide us with Your company's up-to-date certification information. This helps us ensure the quality of services, comply with regulatory and customer requirements. Please fill the short questionnaire below and attach the documentation needed.",
        needYourCertificates: "Need your certificates",
        formSubmitted: "Form successfully submitted.",
      },
      applicationList: {
        applications: "Applications",
        edit: "Edit",
        delete: "Delete",
        review: "Review",
        deleteApplication: "Delete application?",
        vatPayerCode: "VAT Payer Code",
        companyName: "Company name",
        type: "Type",
        status: "Status",
        submittedAt: "Submitted at",
        actions: "Actions",
        errorWhileGettingApplications:
          "Something went wrong while getting applications.",
        applicationDeleted: "Application deleted.",
        errorWhileDeletingApplication:
          "Something went wrong while deleting application.",
      },
      applicationCreateButtons: {
        newGirtekaPartner: "New Partner",
        chooseOptionIfNotPartnerYet:
          "Choose this option if you are not Partner yet.",
        existingGirtekaPartner: "Existing Partner",
        chooseOptionIfNotRegistered:
          "Choose this option if you are already Partner.",
      },
      projectStatusConfirmModal: {
        confirmNewPosition: "Confirm new position",
        confirmPlannedPosition: "Confirm planned position",
        positionStatus: "Position status",
        chooseStatus: "Choose status",
        requiredField: "Required field.",
        statusDate: "Status date",
        comment: "Comment",
        cancel: "Cancel",
        confirm: "Confirm",
        time: "Time",
        projectStatusConfirmed: "Project status confirmed.",
        errorWhileConfirmingStatus:
          "Something went wrong while confirming status.",
        dateMustBeBeforeTomorrow: "Date must be before tomorrow.",
        dateCannotBeSevenDaysEarlierThanNow:
          "Date cannot be 7 days earlier than now.",
        thereIsNoConfirmedLocationAtLoadedPlace:
          "There is no confirmed location at Loaded place.",
        cargoLocationTime: "cargo location time",
        otherCargoStatesCanBeMarkedOnly:
          "Other cargo states can be marked only when loading position is present.",
      },
      projectMarkerPanel: {
        time: "Time:",
        type: "Type:",
        latitude: "Latitude:",
        longtitude: "Longtitude:",
        confirmedBy: "Confirmed by:",
        confirmedAt: "Confirmed at:",
        comment: "Comment:",
        clickToSeeMoreDetails: "Click to see more details...",
        newAddress: "New address",
        confirm: "Confirm",
        start: "Start",
        end: "End",
      },
      notificationSettings: {
        notificationSettings: "Mail settings",
        notifyMeWhen: "Notify me when:",
        sendEmail: "Send email:",
        save: "Save",
        errorWhileGettingSettings: "Error while getting notification settings.",
        settingsUpdated: "Settings updated.",
        errorWhileUpdatingSettings:
          "Error while updating notification settings.",
        instantly: "Instantly",
        periodically: "Daily (17:00 PM Vilnius/Europe)",
        projectCreated: "Project created",
        projectUpdated: "Project updated",
        documentAccepted: "Document accepted",
        documentRejected: "Document rejected",
      },
      newDocumentModal: {
        uploadDocument: "Upload document",
        group: "Group",
        chooseGroup: "Choose group",
        requiredField: "Required field.",
        type: "Type",
        chooseType: "Choose type",
        currency: "Currency",
        comment: "Comment",
        cancel: "Cancel",
        upload: "Upload",
        documentUploaded: "Document uploaded.",
        errorWhileUploadingDocument:
          "Something went wrong while uploading document.",
        fieldMustContainValidValues: "Field must contain valid values.",
        youWillNotBeAbleToDeleteThisFileLater:
          "You will not be able to delete this file later.",
        invalidPattern: "Valid symbols: 0-9, '-', '/'.",
      },
      companySelectModal: {
        selectCompany: "Select company",
        errorWhileChangingCompany:
          "Something went wrong while changing company.",
        loadMore: "Load more",
      },
      languageSelectModal: {
        selectLanguage: "Select language",
        errorWhileLoadingTranslations:
          "Something went wrong while loading translations.",
      },
      projects: {
        projects: "Orders",
        project: "Project",
        loadingInfo: "Loading info",
        unloadingInfo: "Unloading info",
        truckTrailer: "Truck trailer",
        loadType: "Load type",
        price: "Price",
        freight: "Freight",
        extra: "Extra",
        errorWhileGettingProjects:
          "Something went wrong while getting projects.",
        errorWhileGettingProject: "Something went wrong while getting project.",
        refNo: "Ref. No.",
        pallets: "pallets",
        byCMR: "(by CMR)",
        order: "Order",
        createdAt: "Created at",
        orderStatus: "Order status",
        locationStatus: "Location status",
        notStarted: "Not started",
        noData: "No data",
        myAssignedOrders: "My assigned",
        archivedOrders: "Archived",
        incompleteOrders: "Incomplete",
        date: "Created at",
        archive: "Archive",
        unarchive: "Unarchive",
        archived: "Archived",
        details: "Details",
        other: "Other",
        projectArchived: "Project archived.",
        projectUnarchived: "Project unarchived.",
        errorWhileArchivingProject:
          "Something went wrong while archiving project.",
        errorWhileUnarchivingProject:
          "Something went wrong while unarchiving project.",
        archiveProject: "Archive project?",
        unarchiveProject: "Unarchive project?",
        willBeChanged: "Pallet exchange",
        value: "Value",
        waitingForApproval: "Waiting for approval",
        confirmed: "Confirmed",
        pending: "Pending",
        rejected: "Rejected",
        loadMore: "Load more",
        orderRenewed: "Order renewed",
        trailerType: "Trailer type",
        acceptedBy: "Accepted by",
        rejectedBy: "Rejected by",
        youWillBeAbleToSeeArchived:
          'You will be able to see archived orders by filtering "Archived"',
        completed: "Completed",
        documentStatus: "Document status",
        unkownDocumentType: "Uknown document type",
        uploaded: "Uploaded",
        payment: "Payment",
        noDocumentsUploaded: "No documents uploaded",
        projectDocuments: "Project documents",
        mode: "Mode",
        range: "Range",
        quickPayment: "Quick payment",
        quickPaymentTooltip:
          "Quick payment is available once and only when Invoice and CMR are accepted.",
        quickPaymentTooltipSubmitted: "Credit invoice is already submitted.",
        quickPaymentTooltipUploadOrGenerate:
          "Upload or generate credit invoice.",
        paymentAfterInvoice:
          "The price was recalculated after accepting the credit invoice.",
        highValueIncreasedSecurity: "HIGH VALUE / INCREASED SECURITY",
        customer: "Customer",
      },
      projectDocumentPreview: {
        invoice: "Invoice",
        message: "Message",
        uploaded: "Uploaded",
        paymentUntil: "Payment until",
        comment: "Comment",
        download: "Download",
        delete: "Delete",
        documentDeleted: "Document deleted.",
        errorWhileDeletingProject:
          "Something went wrong while deleting project.",
        order: "Order",
        documentRemoved:
          "Document was removed. If you still need it, please contact your manager.",
      },
      projectPanel: {
        orderDetails: "ORDER DETAILS",
        route: "Route:",
      },
      detailsPanel: {
        otherDetails: "Other details",
        responsibilities: "Responsibilities",
        documents: "Documents",
        changeLog: "Change log",
      },
      documentTab: {
        deleteDocument: 'Delete "{document}" document?',
      },
      documentAlert: {
        allDocumentsAreGood: "All documents are good.",
        pleaseNoticeRejectedPendingDocuments:
          "Please notice that you have rejected and/or pending documents.",
        missingCmrInvoice:
          "Missing CMR and/or Invoice: Transport service documents.",
      },
      documentItem: {
        invoice: "Invoice",
        uploaded: "Uploaded",
        payment: "Payment",
        order: "Order",
        waitingForOriginal: "Waiting for the original documents.",
      },
      uploadDocumentModal: {
        uploadDocument: "Upload document",
      },
      projectMap: {
        somethingWentWrongWhileLoading:
          "Something went wrong while loading map.",
      },
      routeToggle: {
        planned: "Planned",
        confirmed: "Confirmed",
      },
      documents: {
        documents: "Documents",
        project: "Project",
        group: "Group",
        type: "Type",
        attributes: "Attributes",
        comment: "Comment",
        status: "Status",
        statusNote: "Status note",
        createdBy: "Created by",
        deletedBy: "Deleted by",
        download: "Download",
        myDocuments: "Only my documents",
        showDeleted: "Include deleted",
        allStatuses: "All statuses",
        fileDownloadFailed:
          "Document was removed. If you still need it, please contact your manager.",
        errorWhileGettingDocuments:
          "Something went wrong while getting documents.",
        review: "Review",
        actions: "Actions",
        deleteDocument: 'Delete "{document}" document?',
        documentStatus: "Document status",
        documentType: "Document type",
      },
      pdfReader: {
        filePreviewFailed:
          "Document was removed. If you still need it, please contact your manager.",
        filePreviewNotSupported:
          "File preview is not supported on this browser.",
      },
      settings: {
        errorWhileDownloadingSettings:
          "Something went wrong while downloading settings.",
      },
      noData: {
        noData: "No data",
      },
      formInfo: {
        noData: "No data",
      },
      footer: {
        copyRightGirtekaLogistics: "© {year} Everwest",
        termsAndConditions: "Terms and conditions",
        privacyPolicy: "Privacy Policy",
        marketingPolicy: "Marketing Policy",
      },
      termsPolicyModal: {
        termsAndConditions: "Terms and conditions",
        agree: "Agree",
        close: "Close",
        iHaveReadAndAgreeGirtekaLogistics:
          "I have read and agree to the Everwest",
        privacyPolicy: "Privacy Policy",
        iHaveReadAndAgreeToThe: "I have read and agree to the",
      },
      privacyPolicyModal: {
        privacyPolicy: "Privacy Policy",
        close: "Close",
      },
      marketingPolicyModal: {
        marketingPolicy: "Marketing Policy",
        iAgreeProcessing:
          "I agree to the processing of my contact data for direct marketing purposes by Everwest group companies in accordance with Direct marketing policy.",
        submit: "Submit",
        close: "Close",
      },
      orderStatus: {
        draft:
          "Order has not been sent to the manager yet, waiting for your confirmation.",
        pending: "Waiting confirmation from manager.",
        cancelled: "You have cancelled your order by yourself.",
        rejected: "Rejected by the manager.",
        confirmed:
          "Order has been successfully confirmed and plan will be prepared soon.",
        planned: "Your order has been assigned to carrier and truck/trailer.",
        active: "Your cargo is on the road.",
        delivered: "All cargo has been unloaded at all unloading locations.",
        completed:
          "All cargo has been unloaded at all unloading locations and CMR document is attached.",
      },
      carrierWizard: {
        carrierPortalUpdated: "Carrier portal has been updated.",
        whatNeedToKnow: "What do you need to know?",
        next: "Next",
        close: "Close",
        intro: "Intro",
        projects: "Projects",
        documents: "Documents",
        users: "Users",
        applications: "Applications",
      },
      introPageWizard: {
        weHaveUpdatedDesign:
          "We have updated the design solution and optimized certain features to make our system convenient for our customers.",
        pleaseReviewWhatChanged: "Please review what has been changed.",
      },
      projectPageWizard: {
        dashboardWhatAbleToSee: "Dashboard. What I'm able to see?",
        onTheOrdersPage:
          "On the Orders page, you will see all your Orders sent to you with detailed information about cargo transportation.",
        statusOfYourOrders: "Status of your orders.",
        hoverOverOrderRow:
          "Hover over to see more detailed information about order.",
        statusOfYourAssignedOrders: "Status of your orders.",
        documentsRelatedToOrder: "Documents related to order.",
        managerWhoAccepted: "Manager who accepted the order.",
        dateYouReceivedTheOrder: "The date you received the order.",
        filters: "Filters to easily manage orders.",
        dashboardWhatActions: "Dashboard. What actions can I take?",
        easilySwitchToAnotherCompany: "Easily switch to another company.",
        searchForOrders: "Search for orders by the desired keyword.",
        filterOrderByStatus: "Filter orders by order status.",
        filterBySpecificTime: "Filter by specific time period.",
        filterStillMissingOrders:
          "Filter orders that are still missing documents (CMR and INOICE: transport service)",
        filterOnlyAssignedOrders:
          "Filter only the orders you (as manager) have assigned.",
        filterArchivedOrders:
          "Filter archived orders. Order will be automatically archived after two months or by yourself at any time when order is rejected.",
        clickOrderFindDetails: "Click on the order and find out the details.",
        unconfirmedOrderView:
          "Unconfirmed order view. What actions can I take?",
        assignManagerForOrder: "Assign manager for the order.",
        clickSeeLocationTypeComment:
          "Click and see the full comment for the location type.",
        enterTruckDriverInfo: "Enter truck driver information.",
        readAdditionalInfo: "Read the additional information.",
        readGeneralRules: "Read general rules for carriers.",
        acceptRejectOrder: "Accept or reject the order.",
        confirmedOrderView: "Confirmed order view. What actions can I take?",
        readAllDetails: "Read all the details of the order.",
        seeAllPeople:
          "See all the people in charge who are responsible for delivering the cargo.",
        enterOrChangeTruckDriver: "Enter or change truck driver information.",
        changeManager: "Change manager for the order.",
        girtekaManagerInfo: "Everwest manager information.",
        saveChanges:
          "Save changes. After that digital order will be generated in documents tab.",
        newSectionDocuments:
          "New section of documents where you‘re able to upload documents.",
        autoGeneratedOrders: "Automatically generated orders.",
        abilityToUploadDocument:
          "The ability to upload and view a document before uploading it to the system.",
        qpServiceIsAvailable:
          "Service is available when CMR and INVOICE documents are uploaded and approved.",
        qpServiceCanBeOrdered:
          "When the required documents already approved, service can be ordered.",
        qpPending: "Invoice submitted, awaiting confirmation from accounting.",
        qpConfirmed:
          "Quick payment confirmed and will be made within the expected time.",
        changelog:
          "You can quickly and conveniently see the changes to your order information.",
        clientCompanyFilter:
          "You can filter orders according to Everwest group companies.",
        ableToSeeGroupOrders: "You able to see Everwest group orders.",
      },
      documentPageWizard: {
        documentsWhatActions: "Documents. What actions can I take?",
        onTheDocumentsPage:
          "On the Documents page, you will see all your uploaded documents that are assigned to a specific project with detailed information.",
        filterDocumentsByStatus: "Filter documents by document status.",
        filterDocumentsByType: "Filter documents by document type.",
        filterOwnUploadedDocuments: "Filter only your own uploaded documents.",
        filterAllDeletedDocuments: "Filter all deleted documents.",
        reviewDownloadDocuments: "Review and download documents.",
      },
      userPageWizard: {
        whatAbleToSee: "What I'm able to see?",
        onUsersPageYouWillSee:
          "On the Users page, you'll see all the users you've created and assigned to the company with detailed information.",
        whatActionsCanTake: "What actions can I take?",
        searchUsersByDesiredKeyword: "Search for users by the desired keyword.",
        filterUsersByManagementRoles: "Filter users by user management roles.",
        createNewUserByEnteringEmail:
          "Create a new user by entering an email and choosing a management role.",
        viewUserProfile: "View user profile.",
        changeUserManagementRole: "Change the user management role.",
        removeUser: "Remove user.",
      },
      applicationPageWizard: {
        whatAbleToSee: "What I'm able to see?",
        onApplicationsPageYouWillSee:
          "On the Applications page you will see all the companies you have created and their statuses.",
        whatActionsCanTake: "What actions can I take?",
        createMoreThanOneCompany:
          "Create more than one company that belongs to you, which is already a partner of Everwest.",
        createMoreThanOneNewCompany:
          "Create more than one new company that belongs to you and it‘s not a partner of Everwest.",
        editOrDeleteCompany:
          'Edit or delete a company which one has the status "Draft"',
      },
      hotspot: {
        previous: "Previous",
        next: "Next",
      },
      feedbackModal: {
        sendFeedback: "Send feedback",
        topic: "Topic",
        idea: "I want to share an idea.",
        issue: "I want to report a problem.",
        comment: "Comment",
        requiredField: "Required field.",
        close: "Close",
        send: "Send",
        feedbackSent: "Feedback sent.",
        errorWhileSendingFeedback:
          "Something went wrong while sending feedback.",
        thisFormIsForDevelopmentPurposes:
          "This form is for website development purposes.",
      },
      maintenance: {
        weWouldLikeToInformYou:
          "We would like to inform you that we are going to update portal on <strong>{date}</strong>. The portal will be unavailable for about <strong>{duration} hours</strong>. Thank you for your patience.",
        errorWhileGettingMaintenanceStatus:
          "Something went wrong while getting maintenance status.",
      },
      addressSearchInput: {
        search: "Search",
      },
      otherDetailsTab: {
        specialConditions:
          "Special conditions for loading and / or transportation of cargo:",
        specialPaymentTerm: "Payment term",
        standardPaymentTerm: "Standard payment term:",
        crmPaymentNote: "CMR:",
        conditionApplies:
          "The VAT rate is applied depending on the route, at the official exchange rate on the date of unloading specified in the Forwarding Order.",
      },
      documentLinks: {
        generalRulesForCarriers: "GENERAL RULES FOR CARRIERS",
        additionalInformation: "ADDITIONAL INFORMATION",
        specialClientRequirements: "SPECIAL CLIENT REQUIREMENTS",
      },
      additionalInformationModal: {
        additionalInformation: "Additional information",
        close: "Close",
      },
      responsibilitiesTab: {
        truckData: "Truck driver",
        driverFullName: "Driver full name",
        driverPhone: "Drive phone",
        truckPlates: "Truck nr.",
        trailerPlates: "Trailer nr.",
        manager: "Manager",
        search: "Search",
        saveChanges: "Save changes",
        projectUpdated: "Project updated.",
        errorWhileUpdatingProject:
          "Something went wrong while updating project",
        errorWhileGettingManagers:
          "Something went wrong while getting managers",
        documentWillBeGenerated: "Document will be generated shortly.",
      },
      projectAlerts: {
        pleaseFillTruckData: "Please fill the details of truck driver.",
        projectMissingStatus: "Order is missing status.",
        thereAreSomeChanges:
          "There are some changes to this order. Please check the details and confirm the order.",
        hasMissingCrmAndInvoice:
          "Missing CMR and Invoice: Transport service documents.",
        hasMissingCrm: "Missing CMR document.",
        hasMissingInvoice: "Missing Invoice: Transport service document.",
        hasRejectedDocument: "Please notice that you have rejected document.",
        projectIsArchived: "Project is archived.",
        projectCompleted: "Project completed. You can still upload documents.",
        projectRejected: "Project rejected. You can still upload documents.",
        highValueIncreasedSecurity: "HIGH VALUE / INCREASED SECURITY",
      },
      projectReviewModal: {
        orderReview: "Order review",
        orderDetails: "ORDER DETAILS",
        customer: "Customer",
        supplier: "Supplier",
        orderConditions: "Order conditions",
        goodsType: "Goods type",
        weight: "Weight",
        pallets: "Pallets",
        shipmentFixation: "Shipment fixation",
        carriageValue: "Carriage value",
        trailerType: "Trailer type",
        temperature: "Temperature",
        refNumber: "Ref number",
        other: "Other",
        locationType: "Location",
        date: "Date",
        locationName: "Location name",
        address: "Address",
        coordinates: "Coordinates",
        comments: "Comments",
        byCMR: "(by CMR)",
        willBeChanged: "Pallet exchange",
        search: "Search",
        specialConditions:
          "Special conditions for loading and / or transportation of cargo:",
        paymentConditions: "Payment conditions",
        freight: "Freight",
        specialPaymentTerm: "Payment term",
        standardPaymentTerm: "Standard payment term:",
        crmPaymentNote: "CMR:",
        truckData: "Truck driver",
        driverFullName: "Driver full name",
        driverPhone: "Drive phone",
        truckPlates: "Truck nr.",
        trailerPlates: "Trailer nr.",
        rejectOrder: "Reject order",
        acceptOrder: "Accept order",
        projectAccepted: "Order accepted.",
        projectRejected: "Order rejected.",
        close: "Close",
        submitChanges: "Submit changes",
        managerNotAssignedYet: "Manager not assigned yet",
        route: "Route:",
        conditionApplies:
          "The VAT rate is applied depending on the route, at the official exchange rate on the date of unloading specified in the Forwarding Order.",
        mode: "Mode",
        range: "Range",
        clientOrderNumber: "Client order number",
        changeLog: "Change log",
        warning: "Warning",
        doYouReallyWantReject: "Do you really want to reject this order?",
        doYouReallyWantAccept: "Do you really want to accept this order?",
        iWouldLikeToReceiveFerryOffer: "I would like to receive a ferry offer.",
      },
      specialRequirementsModal: {
        specialCarrierRequirements: "Special carrier requirements",
        close: "Close",
      },
      documentsTab: {
        erroWhileDownloadingDocuments:
          "Something went wrong while downloading documents.",
        uploadDocument: "Upload document",
        type: "Type",
        status: "Status",
        uploaded: "Uploaded",
        payment: "Payment",
        order: "Order",
      },
      refreshModal: {
        somethingHasChanged: "Something has changed",
        reloadPage: "Reload page",
        pleaseTryLoginAgain: "Please try to login again.",
      },
      roleAlert: {
        yourAccountHasManagerRole:
          "Your account has Manager role with read-only permissions. You can only see orders that you are assigned to.",
        yourAccountHasGroupLeaderRole:
          "Your account has Group Leader role with read-only permissions. You can only see your group orders.",
        yourAccountHasViewerRole:
          "Your account has Viewer role with read-only permissions for {company} company.",
        yourAccountHasEditorRole:
          "Your account has Editor role for {company} company. It means you won't be able manage users.",
      },
      listFilters: {
        filters: "Filters",
        close: "Close",
      },
      quickPayModal: {
        quickPayment: "Quick payment",
        discount: "Discount",
        number: "No.",
        order: "Order",
        freight: "Price",
        paymentUntil: "Payment until",
        newPaymentDay: "New payment day",
        newFreight: "New price",
        paymentWillBeDone: "*Payment will be done until",
        ifPaymentDaySaturday:
          "If the quick payment day is Saturday - the payment is made one day earlier, on Friday.",
        ifPaymentDaySunday:
          "If the quick payment day is Sunday - the payment is made a day later, on Monday.",
        ifPaymentDayHoliday:
          "If the quick payment coincides with a public holiday in the Republic of Lithuania - the payment will be made on the next working day.",
        weekendAndWorkdaysNotIncluded:
          "Weekends and non-work days are not included.",
        invoiceWillBeSentToEmail:
          'Invoice will be sent by email. Also it will be available in "Documents" section.',
        close: "Close",
        nextStep: "Next step",
        invoiceWillBeGenerated:
          "Invoice will be generated shortly. Check documents section.",
        generateInvoice: "Submit Credit Invoice",
        youCanUploadOrGenerateInvoice:
          "You can upload your Invoice or choose our invoice template.",
        uploadMy: "Upload",
        previewInvoice: "Preview before generating",
        creditInvoice: "CREDIT INVOICE",
        invoiceSerialNumber: "Serial:",
        invoiceNumber: "No.:",
        supplier: "SUPPLIER",
        customer: "CUSTOMER",
        name: "Company",
        companyCode: "Reg. code",
        vatCode: "VAT tax code",
        address: "Address",
        bankName: "Name of the bank",
        bankAccountNumber: "Bank account number",
        swift: "SWIFT",
        iban: "IBAN",
        nameOfService: "Name of service",
        unit: "Unit",
        transportation: "Transportation",
        amount: "Amount",
        vat: "VAT",
        price: "Price",
        sum: "Sum",
        total: "Total",
        generalSum: "Total sum",
        transportServiceTax:
          "Transport services are taxed at a 0% VAT rate according to Article 45 Part 1 of the VAT Law of the Republic of Lithuania.",
        foreignSupplierInvoice:
          "In accordance with articles 44 and 196 of the Council Directive of 28 November 2006, 06/112/EC, the recipient of the service will be subject to reverse charge in the country of receipt",
        generate: "Generate",
        dataIsMissing: "Data is missing",
        invoiceUploaded: "Invoice uploaded.",
        uploadInvoice: "Upload invoice",
        upload: "Upload",
        date: "Date:",
        iAgreeWithLatestAdditionalInfoPolicy:
          "I agree with latest “Special and general conditions for forwarding orders”",
        ifYouChooseUploadInvoice:
          "If you choose to “Upload Invoice” Please pay attention that invoice should be issued to <b>:companyName</b> company.",
      },
      projectChangeLog: {
        loadType: "Load type",
        trailerType: "Trailer type",
        temperature: "Temperature",
        refNo: "Ref. No.",
        status: "Status",
        country: "Country",
        city: "City",
        postalCode: "Post code",
        latitude: "Latitude",
        longtitude: "Longtitude",
        refNumber: "Ref number",
        addressName: "Address name",
        addressLine: "Address line",
        region: "Region",
        start: "Start",
        end: "End",
        locationName: "Location name",
        totalPrice: "Total price",
        freightPrice: "Freight price",
        extraPrice: "Extra price",
        loadMore: "Load more",
        alsoPleaseCheck: "Also please check cargo movement information.",
      },
      projectChangeLogModal: {
        changeLog: "Change log",
        close: "Close",
      },
      updateBar: {
        appVersionUpdated:
          "Website version updated. Please reload the page to see changes.",
        reload: "Reload",
      },
      passwordRules: {
        minCharsRequired: "Minimum 8 characteres required.",
        oneUpperCaseRequired: "At least 1 upper case letter required.",
        oneLowerCaseRequired: "At least 1 lower case letter required.",
        oneNumberRequired: "At least 1 number required.",
        dictionaryWord: "Cannot have dictionary word.",
        repetetiveChars: "Cannot have repetetive characters.",
        sequentialChars: "Cannot have sequential characters.",
        mustMatch: "Passwords must match.",
      },
      policies: {
        privacy_policy: "Privacy policy",
        terms_policy: "Terms and conditions",
        marketing_policy: "Marketing policy",
        additional_information: "Additional information",
        special_conditions:
          "Special conditions for loading and / or transportation of cargo",
        special_and_general_conditions:
          "Special and general conditions for forwarding orders",
        procedural_rules: "Procedural rules for forwarding orders",
      },
      maintenancePage: {
        applicationIsDown: "Application is down for scheduled maintenance.",
        pleaseComeBackLater: "Please come back later.",
      },
      documentStatus: {
        uploaded: "Waiting for the registration in accounting system.",
        pending:
          "Document registered in accounting system and waiting for the review.",
        rejected: "Document was rejected, the reason in the comment section.",
        accepted: "Document was accepted by accountant.",
        generated: "Document was generated automatically by the system.",
        waiting: "Waiting for the original documents.",
      },
    },
    pl: {
      notifications: {
        all: "Wszystkie",
        notifications: "Powiadomienia",
        showing: "Wyświetlanie",
        loadMore: "Pokaż więcej",
        viewAllNotifications: "Zobacz wszystkie powiadomienia",
        errorWhileGettingNotifications: "Błąd podczas otrzymywania powiadomień",
        errorWhileUpdatingNotifications: "Błąd przy aktualizacji powiadomień",
        errorWhileDeletingNotifications: "Błąd przy usuwaniu powiadomień",
        application: "Status aplikacji",
        document: "Status dokumentu",
        project: "Status projektu",
        projectCreated: "Stworzono nowe zamówienie",
        projectUpdated: "Zamówienie zostało zaktualizowane",
        documentUploaded: "Nowy dokument został załadowany",
        documentUpdated: "Status dokumentu został zaktualizowany",
        applicationAccepted: "Aplikacja została potwierdzona",
        applicationRejected: "Aplikacja została odrzucona",
        markAllAsRead: "Oznacz wszystkie jako przeczytane",
      },
      login: {
        partnersLogin: "Logowanie do systemu",
        email: "E-mail",
        password: "Hasło",
        forgotPassword: "Zapomniałeś hasło?",
        login: "Login",
        dontHaveAccount: "Nie masz konta?",
        register: "Rejestracja",
        requiredField: "Wymagane pole.",
        invalidEmail: "Nieprawidłowy e-mail.",
        tooMuchLetters: "Za dużo liter",
        pleaseLogin: "Proszę się zalogować.",
        invalidUsernamePassword:
          "Nieprawidłowa nazwa użytkownika lub hasło. Spróbuj ponownie.",
        joinManager: "Logowanie dla pracowników Girteki",
        here: "tu",
        inPartnershipWith: "W partnerstwie z",
      },
      registration: {
        partnersRegistration: "Rejestracja Partnerów",
        email: "E-mail",
        name: "Imię",
        lastName: "Nazwisko",
        phone: "Telefon",
        password: "Hasło",
        confirmPassword: "Potwierdź hasło",
        register: "Rejestracja",
        requiredField: "Wymagane pole.",
        invalidEmail: "Nieprawidłowy e-mail.",
        tooShortNumber: "Zbyt krótka liczba.",
        minCharsRequired: "Wymagane minimum 8 znaków.",
        oneUpperCaseRequired: "Wymagana co najmniej 1 duża litera.",
        oneLowerCaseRequired: "Wymagana co najmniej 1 mała litera.",
        oneNumberRequired: "Co najmniej 1 liczba wymagana.",
        passwordsDontMatch: "Hasła nie pasują do siebie.",
        registrationSuccessful:
          "Rejestracja zakończona sukcesem. Proszę potwierdzić adres e-mail.",
        errorWhileRegistering: "Coś poszło nie tak podczas rejestracji.",
        verificationSent: "Weryfikacja wysłana.",
        errorWhileResendingVerification:
          "Coś poszło nie tak podczas ponownego wysyłania weryfikacji.",
        emailVerified: "E-mail zweryfikowany.",
        errorWhileVerifyingEmail:
          "Coś poszło nie tak podczas weryfikacji e-mail.",
        iHaveReadAndAgreeGirtekaLogistics:
          "Przeczytałem i zgadzam się na Politykę Prywatności",
        privacyPolicy: "Everwest",
        iHaveReadAndAgreeToThe: "Przeczytałem i zgadzam się na",
        termsAndConditions: "Warunki Użytkowania",
        iAgreeProcessing:
          "Wyrażam zgodę na przetwarzanie moich danych kontaktowych w celach marketingu bezpośredniego przez spółki z grupy Everwest zgodnie z",
        directMarketing: "Polityką marketingu bezpośredniego.",
      },
      forgotPassword: {
        partnersForgotPassword: "Zapomniałem hasła dla partnerów",
        email: "E-mail",
        send: "Wyślij",
        requiredField: "Wymagane pole.",
        invalidEmail: "Nieprawidłowy e-mail.",
        resetLinkSent:
          "Link resetujący hasło wysłany. Proszę sprawdzić swój e-mail.",
        errorWhileSendingLink:
          "Coś poszło nie tak podczas wysyłania linku zapomnianego hasła.",
      },
      resetPassword: {
        partnersResetPassword: "Resetuj hasło dla partnerów",
        email: "E-mail",
        password: "Hasło",
        confirmPassword: "Potwierdź hasło",
        reset: "Resetuj",
        requiredField: "Wymagane pole.",
        invalidEmail: "Nieprawidłowy e-mail.",
        minCharsRequired: "Wymagane minimum 8 znaków.",
        oneUpperCaseRequired: "Wymagana co najmniej 1 duża litera.",
        oneLowerCaseRequired: "Wymagana co najmniej 1 mała litera.",
        oneNumberRequired: "Co najmniej 1 liczba wymagana.",
        passwordsDontMatch: "Hasła nie pasują do siebie.",
        passwordResetedPleaseLogin: "Hasło zresetowane. Proszę się zalogować.",
        errorWhileResetingPassword:
          "Coś poszło nie tak podczas resetowania hasła.",
      },
      invitation: {
        invitation: "Zaproszenie",
        email: "E-mail",
        name: "Imię",
        lastName: "Nazwisko",
        phone: "Telefon",
        password: "Hasło",
        confirmPassword: "Potwierdź hasło",
        createAccount: "Utwórz konto",
        requiredField: "Wymagane pole.",
        invalidEmail: "Nieprawidłowy e-mail.",
        tooShortNumber: "Zbyt krótka liczba.",
        minCharsRequired: "Wymagane minimum 8 znaków.",
        oneUpperCaseRequired: "Wymagana co najmniej 1 duża litera.",
        oneLowerCaseRequired: "Wymagana co najmniej 1 mała litera.",
        oneNumberRequired: "Co najmniej 1 liczba wymagana.",
        passwordsDontMatch: "Hasła nie pasują do siebie.",
        iHaveReadAndAgreeGirtekaLogistics:
          "Przeczytałem i zgadzam się na Politykę Prywatności",
        privacyPolicy: "Everwest",
        iHaveReadAndAgreeToThe: "Przeczytałem i zgadzam się na",
        termsAndConditions: "Warunki Użytkowania",
        iAgreeProcessing:
          "Wyrażam zgodę na przetwarzanie moich danych kontaktowych w celach marketingu bezpośredniego przez spółki z grupy Everwest zgodnie z",
        directMarketing: "Polityką marketingu bezpośredniego.",
      },
      profile: {
        myProfile: "Mój profil",
        change: "Zmień",
        edit: "Edytuj",
        resendVerification: "Ponowne wysłanie weryfikacji",
        delete: "Usuń",
        verified: "Zweryfikowano",
        notVerified: "Nie zweryfikowano",
        pleaseVerifyEmail: "Proszę zweryfikować swój adres e-mail.",
        errorWhileFetchingProfile:
          "Coś poszło nie tak podczas pobierania profilu.",
        errorWhileAcceptingPolicy:
          "Coś poszło nie tak podczas akceptacji polityki.",
        leader: "Lider",
        manager: "Kierownik",
        iAgreeProcessing:
          "Wyrażam zgodę na przetwarzanie moich danych kontaktowych w celach marketingu bezpośredniego przez spółki z grupy Everwest zgodnie z",
        directMarketing: "Polityką marketingu bezpośredniego.",
      },
      profileEdit: {
        pleaseUpdateProfile:
          "Proszę zaktualizować profil, aby móc dalej korzystać z portalu.",
        myProfile: "Mój profil",
        firstName: "Imię",
        lastName: "Nazwisko",
        requiredField: "Wymagane pole.",
        email: "E-mail",
        invalidEmail: "Nieprawidłowy e-mail.",
        phone: "Telefon",
        tooShortNumber: "Zbyt krótka liczba.",
        cancel: "Anuluj",
        save: "Zapisz",
        profileInformationUpdated: "Zaktualizowane informacje o profilu.",
        errorWhileUpdatingProfile:
          "Coś poszło nie tak podczas aktualizacji profilu.",
      },
      deleteProfileModal: {
        deleteProfile: "Usunąć profil?",
        ifYouDeleteProfile:
          "Po usunięciu nie będziesz mógł się ponownie zalogować.",
        cancel: "Anuluj",
        delete: "Usuń",
        profileDeleted: "Profil usunięty.",
        errorWhileDeletingProfile:
          "Coś poszło nie tak podczas usuwania profilu.",
      },
      changePassword: {
        yourPasswordExpired:
          "Twoje hasło wygasło. Proszę zaktualizować do nowej wersji.",
        changePassword: "Zmiana hasła",
        existingPassword: "Obecne hasło",
        requiredField: "Wymagane pole.",
        minCharsRequired: "Wymagane minimum 8 znaków.",
        oneUpperCaseRequired: "Wymagana co najmniej 1 duża litera.",
        oneLowerCaseRequired: "Wymagana co najmniej 1 mała litera.",
        oneNumberRequired: "Co najmniej 1 liczba wymagana.",
        passwordsDontMatch: "Hasła nie pasują do siebie.",
        confirmPassword: "Potwierdź hasło",
        cancel: "Anuluj",
        save: "Zapisz",
        passwordChanged: "Hasło zostało zmienione.",
        errorWhileChangingPassword: "Coś poszło nie tak podczas zmiany hasła.",
      },
      userEdit: {
        userEdit: "Edytuj użytkownika",
        firstName: "Imię",
        requiredField: "Wymagane pole.",
        lastName: "Nazwisko",
        email: "E-mail",
        invalidEmail: "Nieprawidłowy e-mail.",
        phone: "Telefon",
        tooShortNumber: "Zbyt krótka liczba.",
        role: "Rola",
        cancel: "Anuluj",
        save: "Zapisz",
        errorWhileFetchingUser:
          "Coś poszło nie tak podczas pobierania użytkownika.",
        userUpdated: "Użytkownik zaktualizowany.",
        errorWhileUpdatingUser:
          "Coś poszło nie tak podczas aktualizacji użytkownika.",
        accountantRoleHasReadOnly: "Gość, może tylko wyswietlić informację",
      },
      userInvitation: {
        userInvitation: "Zaproszenie użytkownika",
        email: "E-mail",
        requiredField: "Wymagane pole.",
        invalidEmail: "Nieprawidłowy e-mail.",
        role: "Rola",
        invite: "Zaproś",
        userInvited: "Użytkownik zaproszony",
        errorWhileInvitingUser:
          "Coś poszło nie tak podczas zapraszania użytkownika.",
        tokenMissing: "Brak tokena",
        accountCreatedPleaseLogin: "Konto założone. Proszę się zalogować.",
        invitationExpired: "Zaproszenie wygasło.",
        errorWhileCreatingAccount:
          "Coś poszło nie tak podczas tworzenia konta.",
        accountantRoleHasReadOnly: "Gość, może tylko wyswietlić informację",
      },
      userList: {
        users: "Użytkownicy",
        invite: "Zaproś",
        review: "Przegląd",
        edit: "Edytuj",
        remove: "Usuń",
        removeFromCompany: "Usuń z firmy",
        email: "E-mail",
        name: "Imię",
        surname: "Nazwisko",
        phone: "Telefon",
        roles: "Role",
        actions: "Działania",
        errorWhileGettingUsers:
          "Coś poszło nie tak podczas pobierania użytkowników.",
        userRemoved: "Użytkownik usunięty.",
        errorWhileDeletingUsers:
          "Coś poszło nie tak podczas usuwania użytkownika.",
      },
      userShow: {
        user: "Użytkownik",
        changeRole: "Zmień rolę",
        removeFromCompany: "Usuń z firmy",
      },
      notFound: {
        pageNotFound: "Nie znaleziono strony",
        pageDoesNotExist: "Strona o takim adresie URL nie istnieje.",
        goBack: "Wróć",
      },
      header: {
        profile: "Profil",
        notificationSettings: "Ustawienia poczty",
        logout: "Wyloguj się",
        projects: "Zlecenia",
        documents: "Dokumenty",
        users: "Użytkownicy",
        applications: "Konta",
      },
      sidebar: {
        applications: "Konta",
        orders: "Zamówienia",
        contacts: "Kontakty",
        projects: "Zlecenia",
        users: "Użytkownicy",
        adminPanel: "Panel administracyjny",
        developerPanel: "Panel deweloperski",
        profile: "Profil",
        logout: "Wyloguj się",
        documents: "Dokumenty",
        notifications: "Powiadomienia",
        language: "Język",
        ediUploader: "Wgrywanie EDI",
        translations: "Tłumaczenia",
        cache: "Podręczne",
        emailTemplates: "Szablony e-mail",
        issueMail: "Wyślij maila",
        companies: "Firmy",
        help: "Pomoc",
        feedback: "Opinia",
        maintenance: "Konserwacja",
        files: "Pliki",
        addresses: "Adresy",
        sessions: "Sesja",
        inPartnershipWith: "W partnerstwie z",
        mailSettings: "Ustawienia poczty",
      },
      contacts: {
        languages: "Języki:",
        contactUs: "Skontaktuj się z nami",
      },
      fileDropzone: {
        dropFileHere: "Upuść plik tutaj",
        dragDropByClicking:
          "Przeciągnij i upuść lub wybierz pliki klikając tutaj",
        someFilesNotUploaded: "Niektórych plików nie można wgrać",
        tryAgain: "Spróbuj ponownie",
        dragDropFilesHere: "Przeciągnij i upuść swoje pliki tutaj lub:",
        browseFiles: "Przeglądaj pliki",
      },
      confirmModal: {
        no: "Nie",
        yes: "Tak",
      },
      searchInput: {
        search: "Szukaj",
      },
      showPages: {
        show: "Pokaż",
        entries: "wpisy",
      },
      showPagesInfo: {
        showingEntries:
          "Wyświetlanie {activePage} do {perPage} z {pages} wpisów",
      },
      toaster: {
        justNow: "Właśnie teraz",
        success: "Sukces",
        error: "Błąd",
        info: "Info",
        warning: "Ostrzeżenie",
      },
      addressInput: {
        searchNew: "Szukaj nowych",
        selectExisting: "Wybierz istniejące",
        selectAddress: "Wybierz adres",
        errorWhileGettingAddresses:
          "Coś poszło nie tak podczas pobierania adresów.",
      },
      applicationFiles: {
        selectType: "Wybierz typ",
        requiredField: "Wymagane pole.",
        allFiles: "Wszystkie pliki",
        totalSizeLimit: "Limit rozmiaru całkowitego",
        documentDeleted: "Dokument usunięty.",
        errorWhileDeletingDocument:
          "Coś poszło nie tak podczas usuwania dokumentu.",
        documentsUploaded: "Dokumenty wgrane.",
        errorWhileUploadingDocuments:
          "Coś poszło nie tak podczas wgrywania dokumentów.",
        allFilesSizeExceeds: "Rozmiar wszystkich plików przekracza",
        documentUpdated: "Dokument zaktualizowany.",
        errorWhileUpdatingDocument:
          "Coś poszło nie tak podczas aktualizacji dokumentów.",
      },
      application: {
        recommendation: "Zalecenie",
        companyName: "Nazwa firmy",
        requiredField: "Wymagane pole.",
        companyAddress: "Adres firmy",
        firstName: "Imię",
        lastName: "Nazwisko",
        position: "Pozycja",
        emailAddress: "Adres e-mail",
        invalidEmail: "Nieprawidłowy e-mail.",
        mobilePhone: "Telefon komórkowy",
        newPartnerApplication: "Wniosek Nowego Partnera",
        existingPartnerApplication: "Wniosek Istniejącego Partnera",
        cancellationComment: "Komentarz do rezygnacji:",
        company: "Firma",
        details: "Szczegóły",
        companyRegistrationNumber: "Numer rejestracyjny przedsiębiorstwa",
        vatNumber: "Numer VAT",
        vatNumberValidation:
          "Nieprawidłowy format podatku VAT. Prawidłowy przykład: LT999999999999.",
        address: "Adres",
        street: "Ulica",
        cityZip: "Miasto, kod pocztowy",
        country: "Kraj",
        selectCountry: "Wybierz kraj",
        bankAccount: "Rachunek bankowy",
        bankName: "Nazwa banku",
        accountNumber: "Numer rachunku (IBAN)",
        swift: "SWIFT",
        currency: "Waluta",
        selectCurrency: "Wybierz walutę",
        companyContacts: "Kontakty firmy",
        phoneOptional: "Telefon (opcjonalnie)",
        insurance: "Ubezpieczenie",
        companyBrokerName: "Nazwa firmy / brokera",
        contactPhone: "Telefon kontaktowy",
        companyDocuments: "Dokumenty firmy",
        allowedFiles: "Dozwolone pliki",
        saveDraft: "Zapisz szkic",
        submit: "Prześlij",
        errorWhileStoringApplication:
          "Coś poszło nie tak podczas zapisywania wniosku.",
        applicationUpdated: "Wniosek zaktualizowany.",
        errorWhileUpdatingApplication:
          "Coś poszło nie tak podczas aktualizacji wniosku.",
        errorWhileGettingApplication:
          "Coś poszło nie tak podczas pobierania wniosku.",
        applicationSent: "Wniosek wysłany. Proszę czekać na zatwierdzenie.",
        directions: "Kierunki",
        direction: "Kierunek",
        selectDirection: "Wybierz kierunek",
        pleaseChooseMainDirection: "Proszę wybrać główny kierunek operacji.",
        transId: "Trans ID",
        iHaveTransId: "Mam Trans ID",
        requiredDocs:
          "Wymagane dokumenty: <strong>Zaświadczenie o rejestracji VAT</strong>, <strong>Zaświadczenie o rejestracji firmy</strong>, <strong>Licencja przewoźnika</strong> (nie jest wymagana w przypadku spedytora), co najmniej jeden <strong>dokument ubezpieczenia CMR</strong> lub <strong>ubezpieczenia spedytora</strong>",
        questionnaire: "Kwestionariusz",
        yourAnswersAreImportant:
          "Twoje odpowiedzi są ważne dla dalszej współpracy.",
        yes: "Tak",
        no: "Nie",
        productSafetyManagement:
          "Czy posiadasz certyfikat potwierdzający Twoje kompetencje w zakresie zarządzania bezpieczeństwem produktów (ISO22001 / BRC / IFS)?",
        foodSafetyPolicy: "Czy prowadzisz politykę bezpieczeństwa żywności?",
        haccpPlan: "Czy masz udokumentowany i wdrożony plan HACCP?",
        chemicalsInCleansing:
          "Czy sprawdzasz, jakie substancje chemiczne są stosowane w istotnych dla bezpieczeństwa produktów procesach czyszczenia?",
        pestControlSystem: "Czy masz wdrożony system kontroli szkodników?",
        cleaningSchedule:
          "Czy masz wdrożoną procedurę i harmonogram czyszczenia naczep transportowych i pomieszczeń?",
        proceduresForOperation:
          "Czy masz wdrożoną procedurę eksploatacji i obsługi wszystkich pojazdów i sprzętu?",
        expertiseSecurityManagement:
          "Czy posiadasz certyfikat potwierdzający Twoje kompetencje w zakresie zarządzania bezpieczeństwem (TAPA TSR / ISO28001)?",
        securityPolicy: "Czy prowadzisz politykę bezpieczeństwa?",
        hvttCargo:
          "Czy masz wdrożone  procedury nadzoru i eliminacji zagrożenia na poziom bezpieczeństwa  ładunków o wysokiej wartości (HVTT) i wysokiego ryzyka?",
        secureVehicleParking:
          "Czy dobierasz bezpieczne parkingi planując trasę kierowcy?",
        routesAndStops: "Czy planujesz trasę i place parkingowe zawczasu?",
        driverAssessment:
          "Czy sprawdzasz biografię kierowcy, przewożącego ładunki o wysokiej wartości?",
        addIsoToCompanyDocuments:
          'Dodaj ISO22001/ BRC/ IFS do sekcji "Dokumentacja firmy".',
        addTapaToCompanyDocuments:
          'Dodaj TAPA TSR/ ISO28001 do sekcji "Dokumentacja firmy".',
        isoDocument: "ISO22001/ BRC/ IFS",
        tapaDocument: "TAPA TSR/ ISO28001",
        asOurValuedSupllier:
          'Ponieważ jesteś ważnym dostawcą i częścią łańcucha dostaw "Everwest", uprzejmie prosimy o dostarczenie nam najnowszych informacji o certyfikacji Twojej firmy. To pomoże nam zapewnić jakość naszych usług oraz zgodność z wymogami prawnymi i wymaganiami klientów. Prosimy o wypełnienie poniższego krótkiego kwestionariusza i załączenie wymaganych dokumentów.',
        needYourCertificates: "Potrzebujemy waszych certyfikatów",
        formSubmitted: "Formularz przesłany pomyślnie.",
      },
      applicationList: {
        applications: "Konta",
        edit: "Edytuj",
        delete: "Usuń",
        review: "Przegląd",
        deleteApplication: "Usunąć wniosek?",
        vatPayerCode: "Kod płatnika VAT",
        companyName: "Nazwa firmy",
        type: "Typ",
        status: "Status",
        submittedAt: "Zgłoszono na",
        actions: "Działania",
        errorWhileGettingApplications:
          "Coś poszło nie tak podczas pobierania wniosku.",
        applicationDeleted: "Wniosek usunięty.",
        errorWhileDeletingApplication:
          "Coś poszło nie tak podczas usuwania wniosku.",
      },
      applicationCreateButtons: {
        newGirtekaPartner: "Nowy Partner",
        chooseOptionIfNotPartnerYet:
          "Wybierz tę opcję, jeśli nie jesteś jeszcze partnerem.",
        existingGirtekaPartner: "Istniejący Partner",
        chooseOptionIfNotRegistered:
          "Wybierz tę opcję, jeśli jesteś już partnerem.",
      },
      projectStatusConfirmModal: {
        confirmNewPosition: "Aktualizacja statusu",
        confirmPlannedPosition: "Potwierdź planowaną pozycję",
        positionStatus: "Status",
        chooseStatus: "Wybierz status",
        requiredField: "Wymagane pole.",
        statusDate: "Według czasu miejscowego",
        comment: "Komentarz",
        cancel: "Anuluj",
        confirm: "Potwierdź",
        time: "Czas",
        projectStatusConfirmed: "Status projektu potwierdzony.",
        errorWhileConfirmingStatus:
          "Coś poszło nie tak podczas potwierdzania statusu.",
        dateMustBeBeforeTomorrow: "Data musi być przed jutrem.",
        dateCannotBeSevenDaysEarlierThanNow:
          "Data nie może być o 7 dni wcześniejsza niż teraz.",
        thereIsNoConfirmedLocationAtLoadedPlace:
          "Nie ma potwierdzonego miejsca w miejscu Załadunku.",
        cargoLocationTime: "(ładunku)",
        otherCargoStatesCanBeMarkedOnly:
          "Inne stany ładunku mogą być oznaczone tylko wtedy, gdy obecna jest pozycja załadunku.",
      },
      projectMarkerPanel: {
        time: "Czas:",
        type: "Typ:",
        latitude: "Szerokość geograficzna:",
        longtitude: "Długość geograficzna:",
        confirmedBy: "Potwierdzone przez:",
        confirmedAt: "Potwierdzone na:",
        comment: "Komentarz:",
        clickToSeeMoreDetails: "Kliknij, aby zobaczyć więcej szczegółów...",
        newAddress: "Nowy adres",
        confirm: "Potwierdź",
        start: "Początek",
        end: "Koniec",
      },
      notificationSettings: {
        notificationSettings: "Ustawienia poczty",
        notifyMeWhen: "Powiadom mnie gdy:",
        sendEmail: "Wyślij e-mail:",
        save: "Zapisz",
        errorWhileGettingSettings:
          "Błąd podczas pobierania ustawień powiadomień.",
        settingsUpdated: "Ustawienia zaktualizowane.",
        errorWhileUpdatingSettings:
          "Błąd podczas aktualizacji ustawień powiadomień.",
        instantly: "Natychmiast",
        periodically: "Codziennie (17:00 Wilno/Europa)",
        projectCreated: "Projekt stworzony",
        projectUpdated: "Projekt zaktualizowany",
        documentAccepted: "Dokument przyjęty",
        documentRejected: "Dokument odrzucony",
      },
      newDocumentModal: {
        uploadDocument: "Dodaj dokument",
        group: "Grupa",
        chooseGroup: "Wybierz grupę",
        requiredField: "Wymagane pole.",
        type: "Typ",
        chooseType: "Wybierz typ",
        currency: "Waluta",
        comment: "Komentarz",
        cancel: "Anuluj",
        upload: "Wgraj",
        documentUploaded: "Dokument wgrany.",
        errorWhileUploadingDocument:
          "Coś poszło nie tak podczas wgrywania dokumentu.",
        fieldMustContainValidValues: "Pole musi zawierać prawidłowe wartości.",
        youWillNotBeAbleToDeleteThisFileLater:
          "Nie będziesz mógł później usunąć tego pliku.",
        invalidPattern: "Dozwolone symbole: 0-9, '-', '/'.",
      },
      companySelectModal: {
        selectCompany: "Wybierz firmę",
        errorWhileChangingCompany: "Coś poszło nie tak przy zmianie firmy.",
        loadMore: "Pokaż więcej",
      },
      languageSelectModal: {
        selectLanguage: "Wybierz język",
        errorWhileLoadingTranslations:
          "Coś poszło nie tak podczas wczytywania tłumaczeń.",
      },
      projects: {
        projects: "Zlecenia",
        project: "Projekt",
        loadingInfo: "Załadunek",
        unloadingInfo: "Rozładunek",
        truckTrailer: "Przyczepa ciężarówki",
        loadType: "Informacje o ładunku",
        price: "Cena",
        freight: "Fracht",
        extra: "Extra",
        errorWhileGettingProjects:
          "Coś poszło nie tak podczas pobierania projektów.",
        errorWhileGettingProject:
          "Coś poszło nie tak podczas pobierania projektu.",
        refNo: "Ref. No.",
        pallets: "palety",
        byCMR: "Ilość pallet według CMR",
        order: "Nr. Zlecenia",
        createdAt: "Utworzone w",
        orderStatus: "Status zlecenia",
        locationStatus: "Status",
        notStarted: "Brak statusu",
        noData: "Brak danych",
        myAssignedOrders: "Moje aktualne",
        archivedOrders: "Moje archiwum",
        incompleteOrders: "Niedokonane",
        date: "Data i czas",
        archive: "Archiwum",
        unarchive: "Wyjmij z archiwum",
        archived: "Zarchiwizowane",
        details: "Szczegóły",
        other: "Inne",
        projectArchived: "Projekt zarchiwizowany.",
        projectUnarchived: "Projekt wyjęty z archiwum.",
        errorWhileArchivingProject:
          "Coś poszło nie tak podczas archiwizowania projektu.",
        errorWhileUnarchivingProject:
          "Coś poszło nie tak podczas wyjmowania projektu z archiwum.",
        archiveProject: "Zarchiwizować projekt?",
        unarchiveProject: "Wyjąć projekt z archiwum?",
        willBeChanged: "Wymiana palet",
        value: "Wartość",
        waitingForApproval: "Do akceptacji",
        confirmed: "Potwierdzone",
        pending: "Werefikacja",
        rejected: "Odrzucone",
        loadMore: "Pokaż więcej",
        orderRenewed: "Zlecenie odnowione",
        trailerType: "Typ naczepy",
        acceptedBy: "Potwierdzono przez",
        rejectedBy: "Odrzucono przez",
        youWillBeAbleToSeeArchived:
          'Zarchiwizowane zamówienia będą dostępne po wyborze filtru  "Moje archiwum"',
        completed: "Wykonane",
        documentStatus: "Status dokumentu",
        unkownDocumentType: "Nieznany typ dokumentu",
        uploaded: "Dodane",
        payment: "Płatność",
        noDocumentsUploaded: "Brak dokumentów",
        projectDocuments: "Dokumenty",
        mode: "Tryb",
        range: "Zakres",
        quickPayment: "Szybka płatność",
        quickPaymentTooltip:
          "Szybka płatność jest dostępna jednorazowo i tylko po zaakceptowaniu Faktury i CMR.",
        quickPaymentTooltipSubmitted: "Faktura kredytowa jest już złożona.",
        quickPaymentTooltipUploadOrGenerate:
          "Prześlij lub wygeneruj fakturę kredytową",
        paymentAfterInvoice:
          "Cena została przeliczona po zaakceptowaniu faktury kredytowej.",
        highValueIncreasedSecurity:
          "WYSOKA WARTOŚĆ / ZWIĘKSZONE BEZPIECZEŃSTWO",
        customer: "Zleceniodawca",
      },
      projectDocumentPreview: {
        invoice: "Faktura",
        message: "Wiadomość",
        uploaded: "Data dodania",
        paymentUntil: "Płatność do",
        comment: "Komentarz",
        download: "Pobierz",
        delete: "Usuń",
        documentDeleted: "Dokument usunięty.",
        errorWhileDeletingProject:
          "Coś poszło nie tak podczas usuwania projektu.",
        order: "Zamówienie",
        documentRemoved:
          "Dokument został usunięty. Jeśli nadal go potrzebujesz, skontaktuj się ze swoim menedżerem.",
      },
      projectPanel: {
        orderDetails: "Szczegóły zlecenia",
        route: "Trasa:",
      },
      detailsPanel: {
        otherDetails: "Inne szczegóły",
        responsibilities: "Zasoby",
        documents: "Dokumenty",
        changeLog: "Dziennik zmian",
      },
      documentTab: {
        deleteDocument: 'Usunąć dokument "{dokument}"?',
      },
      documentAlert: {
        allDocumentsAreGood: "Wszystkie dokumenty są dobre.",
        pleaseNoticeRejectedPendingDocuments:
          "Proszę zauważyć, że mają Państwo odrzucone i/lub oczekujące na rozpatrzenie dokumenty.",
        missingCmrInvoice:
          "Brakujące CMR i/lub faktura: Dokumenty dotyczące usług transportowych.",
      },
      documentItem: {
        invoice: "Faktura",
        uploaded: "Data dodania",
        payment: "Płatność",
        order: "Zamówienie",
        waitingForOriginal: "Czekam na oryginalne dokumenty.",
      },
      uploadDocumentModal: {
        uploadDocument: "Dodaj dokument",
      },
      projectMap: {
        somethingWentWrongWhileLoading:
          "Coś poszło nie tak podczas wczytywania mapy.",
      },
      routeToggle: {
        planned: "Planowane",
        confirmed: "Potwierdzone",
      },
      documents: {
        documents: "Dokumenty",
        project: "Projekt",
        group: "Grupa",
        type: "Typ",
        attributes: "Atrybuty",
        comment: "Komentarz",
        status: "Status",
        statusNote: "Status notatki",
        createdBy: "Stworzony przez",
        deletedBy: "Usunięty przez",
        download: "Pobierz",
        myDocuments: "Tylko moje dokumenty",
        showDeleted: "Uwzględnij usunięte",
        allStatuses: "Wszystkie statusy",
        fileDownloadFailed:
          "Dokument został usunięty. Jeśli nadal go potrzebujesz, skontaktuj się ze swoim menedżerem.",
        errorWhileGettingDocuments:
          "Coś poszło nie tak podczas pobierania dokumentów.",
        review: "Przegląd",
        actions: "Działania",
        deleteDocument: 'Usunąć dokument "{dokument}"?',
        documentStatus: "Status dokumentu",
        documentType: "Typ dokumentu",
      },
      pdfReader: {
        filePreviewFailed:
          "Dokument został usunięty. Jeśli nadal go potrzebujesz, skontaktuj się ze swoim menedżerem.",
        filePreviewNotSupported:
          "Podgląd pliku nie jest obsługiwany w tej przeglądarce.",
      },
      settings: {
        errorWhileDownloadingSettings:
          "Coś poszło nie tak podczas pobierania ustawień.",
      },
      noData: {
        noData: "Brak danych",
      },
      formInfo: {
        noData: "Brak danych",
      },
      footer: {
        copyRightGirtekaLogistics: "© {year} Everwest",
        termsAndConditions: "Warunki Użytkowania",
        privacyPolicy: "Polityka prywatności",
        marketingPolicy: "Polityka marketingu",
      },
      termsPolicyModal: {
        termsAndConditions: "Warunki Użytkowania",
        agree: "Zgadzam się",
        close: "Zamknij",
        iHaveReadAndAgreeGirtekaLogistics:
          "Przeczytałem i zgadzam się na Politykę Prywatności",
        privacyPolicy: "Everwest",
        iHaveReadAndAgreeToThe: "Przeczytałem i zgadzam się na",
      },
      privacyPolicyModal: {
        privacyPolicy: "Everwest",
        close: "Zamknij",
      },
      marketingPolicyModal: {
        marketingPolicy: "Polityka marketingu",
        iAgreeProcessing:
          "Wyrażam zgodę na przetwarzanie moich danych kontaktowych w celach marketingu bezpośredniego przez spółki z grupy Everwest zgodnie z Polityką marketingu bezpośredniego.",
        submit: "Zatwierdź",
        close: "Zamknij",
      },
      orderStatus: {
        draft:
          "Zamówienie nie zostało jeszcze wysłane do kierownika, czekamy na Twoje potwierdzenie.",
        pending: "Czekam na potwierdzenie od kierownika.",
        cancelled: "Samodzielnie anulowałeś swoje zamówienie.",
        rejected: "Odrzucone przez kierownika.",
        confirmed:
          "Zamówienie zostało pomyślnie potwierdzone i plan zostanie wkrótce przygotowany.",
        planned:
          "Twoje zamówienie zostało przypisane do przewoźnika i ciężarówki/przyczepy.",
        active: "Twój ładunek jest w drodze.",
        delivered:
          "Cały ładunek został rozładowany we wszystkich miejscach rozładunku.",
        completed:
          "Cały ładunek został rozładowany we wszystkich miejscach rozładunku, a dokument CMR jest załączony.",
      },
      carrierWizard: {
        carrierPortalUpdated: "Portal przewoźnika został zaktualizowany.",
        whatNeedToKnow: "Co potrzebujesz wiedzieć?",
        next: "Następny",
        close: "Zamknij",
        intro: "Wprowadzenie",
        projects: "Zlecenia",
        documents: "Dokumenty",
        users: "Użytkownicy",
        applications: "Konta",
      },
      introPageWizard: {
        weHaveUpdatedDesign:
          "Zaktualizowaliśmy rozwiązanie projektowe i zoptymalizowaliśmy niektóre funkcje, aby nasz system był wygodny dla naszych klientów.",
        pleaseReviewWhatChanged: "Proszę przejrzeć, co zostało zmienione.",
      },
      projectPageWizard: {
        dashboardWhatAbleToSee: "Zlecenia. Jakie informacje są dostępne?",
        onTheOrdersPage:
          "W sekcji Zlecenia zobaczysz wszystkie przypisane do Ciebie ładunki ze szczegółowymi informacjami dotyczącymi danego transportu.",
        statusOfYourOrders: "Statusy zleceń.",
        hoverOverOrderRow:
          "Po naprowadzeniu kursora na pasek zlecenia i kliknięcia, będzie wyswietlona bardziej szczegułowa informacja o nim.",
        statusOfYourAssignedOrders: "Statusy przypisanych zleceń.",
        documentsRelatedToOrder:
          "Dokumenty dodane do zlecenia, ich status oraz data płatności za wykonany transport.",
        managerWhoAccepted: "Prowadzący dane zlecenie.",
        dateYouReceivedTheOrder: "Data zlecenia.",
        filters: "Filtry ułatwiające zarządzanie zamówieniami.",
        dashboardWhatActions: "Zlecenia. Jak zarządzać informacją?",
        easilySwitchToAnotherCompany:
          "Jeśli masz kilka firm pracujących poprzez portal, możesz łatwo nimi zarządzać, po prostu przełączając profile.",
        searchForOrders:
          "Szukaj zleceń według kluczowego słowa (Nr. Zlecenia, adres, ładunek itd.)",
        filterOrderByStatus: "Tu możesz filtrować zlecenia według statusu.",
        filterBySpecificTime: "Filtruj według określonego okresu.",
        filterStillMissingOrders:
          "Dany filtr przeznaczony do filtrowania zleceń, w których nadal brakuje dokumentów lub są odrzucone przez księgowość (np. potrzebują uzupełnienia informacji).",
        filterOnlyAssignedOrders:
          "Wyświetl zlecenia za które jesteś odpowiedzialny.",
        filterArchivedOrders: "Pokaż wszystkie zarchiwizowane zlecenia.",
        clickOrderFindDetails: "Pokaż wszystkie zarchiwizowane zlecenia.",
        unconfirmedOrderView:
          "Przegląd niepotwierdzonego zlecenia. Jakie działania mogę podjąć?",
        assignManagerForOrder: "Wyznacz odpowiedzialnego kierownika zamówień.",
        clickSeeLocationTypeComment: "Kliknij, aby zobaczyć pełny komentarz.",
        enterTruckDriverInfo: "Tu możesz wprowadzić informację kierowcy.",
        readAdditionalInfo:
          "Kliknij i zapoznaj sie z szczegułami I dopełniającą informacją zlecenia.",
        readGeneralRules: "Tu znajdziesz ogólne zasady.",
        acceptRejectOrder: "Potwierdź lub odrzuć zlecenie.",
        confirmedOrderView:
          "Przegląd już potwierdzonego zlecenia. Jakie działania mogę podjąć?",
        readAllDetails: "Zapoznać się z zmianami w zleceniu.",
        seeAllPeople:
          "Sprawdzić wszystkich odpowiedzialny-przypisanych do zlecenia.",
        enterOrChangeTruckDriver: "Wpisać lub zmienić dane kierowcy.",
        changeManager: "Przypisanie odpowiedzialnego.",
        girtekaManagerInfo: "Everwest manager information.",
        saveChanges: "Zachować zmiany.",
        newSectionDocuments:
          "Nowa sekcja dokumentów, szybki wgląd do ich statusów.",
        autoGeneratedOrders:
          "Automatycznie wygenerowane zlecenie (zlecenie cyfrowe).",
        abilityToUploadDocument:
          "Możliwość wglądu dokumentów przed ich dodaniem do zlecenia.",
        qpServiceIsAvailable:
          "Usługa jest dostępna po otrzymaniu i zatwierdzeniu dokumentów CMR i INVOICE.",
        qpServiceCanBeOrdered:
          "Wymagane dokumenty są potwierdzone i nie ma braków, można zamówić usługę.",
        qpPending:
          "Faktura złożona, oczekuje na zatwierdzenie przez księgowość.",
        qpConfirmed:
          "Szybka płatność została zatwierdzona i zostanie zrealizowana w oczekiwanym terminie.",
        changelog:
          "Możesz szybko i wygodnie zobaczyć zmiany informacji o zamówieniu",
        clientCompanyFilter:
          "Możesz filtrować zamówienia według spółek grupy Everwest",
        ableToSeeGroupOrders: 'Możesz zobaczyć zamówienia grupy "Everwest".',
      },
      documentPageWizard: {
        documentsWhatActions:
          "Dokumenty. Jakie działania mogę wykonać w danym module?",
        onTheDocumentsPage:
          "Dokumenty. Jakie działania mogę wykonać w danym module?",
        filterDocumentsByStatus:
          "Możliwość filtrowania dokumentów według statusu.",
        filterDocumentsByType:
          "Możliwość filtrowani dokumentów według ich typu.",
        filterOwnUploadedDocuments:
          "Szybko sprawdzić status tylko moich dodanych dokumentów.",
        filterAllDeletedDocuments:
          "Możliwość wyfiltrować wszystkie usunięte dokumenty.",
        reviewDownloadDocuments:
          "Sprawdzisz i pobierzesz już dodane wcześniej dokumenty.",
      },
      userPageWizard: {
        whatAbleToSee: "Co mogę zobaczyć?",
        onUsersPageYouWillSee:
          "Na stronie Użytkownicy, zobaczysz wszystkich użytkowników, których utworzyłeś i przypisałeś do firmy wraz ze szczegółowymi informacjami.",
        whatActionsCanTake: "Jakie działania mogę podjąć?",
        searchUsersByDesiredKeyword:
          "Wyszukaj użytkowników według żądanego słowa kluczowego.",
        filterUsersByManagementRoles:
          "Filtrowanie użytkowników według ról zarządzania użytkownikami.",
        createNewUserByEnteringEmail:
          "Utwórz nowego użytkownika, podając jego adres e-mail i wybierając rolę zarządzania.",
        viewUserProfile: "Zobacz profil użytkownika.",
        changeUserManagementRole: "Zmień rolę zarządzania użytkownika.",
        removeUser: "Usuń użytkownika.",
      },
      applicationPageWizard: {
        whatAbleToSee: "Co mogę zobaczyć?",
        onApplicationsPageYouWillSee:
          "Na stronie Konta zobaczysz wszystkie utworzone przez siebie firmy i ich statusy.",
        whatActionsCanTake: "Jakie działania mogę podjąć?",
        createMoreThanOneCompany:
          "Utwórz więcej niż jedną firmę, która należy do Ciebie i jest już partnerem Everwest.",
        createMoreThanOneNewCompany:
          "Utwórz więcej niż jedną nową firmę, która należy do Ciebie i nie jest partnerem Everwest.",
        editOrDeleteCompany: 'Edytuj lub usuń firmę, która ma status "Szkic"',
      },
      hotspot: {
        previous: "Poprzedni",
        next: "Następny",
      },
      feedbackModal: {
        sendFeedback: "Wyślij opinię",
        topic: "Temat",
        idea: "Chcę się podzielić pomysłem.",
        issue: "Chcę zgłosić problem.",
        comment: "Komentarz",
        requiredField: "Wymagane pole.",
        close: "Zamknij",
        send: "Wyślij",
        feedbackSent: "Opinia wysłana.",
        errorWhileSendingFeedback:
          "Coś poszło nie tak podczas wysyłania opinii.",
        thisFormIsForDevelopmentPurposes:
          "Ten formularz jest do celów rozwoju strony.",
      },
      maintenance: {
        weWouldLikeToInformYou:
          "Informujemy, że w dniu <strong>{date}</strong> nastąpi aktualizacja portalu. Portal będzie niedostępny przez około <strong>{duration} godzin</strong>. Dziękujemy za twoją uwagę.",
        errorWhileGettingMaintenanceStatus:
          "Coś poszło nie tak podczas pobierania statusu konserwacji.",
      },
      addressSearchInput: {
        search: "Szukaj",
      },
      otherDetailsTab: {
        specialConditions: "Ważne informację dotyczące zlecenia",
        specialPaymentTerm: "Termin płatności",
        standardPaymentTerm: "Standardowy termin płatności:",
        crmPaymentNote: "CMR:",
        conditionApplies:
          "Stawka VAT jest stosowana zgodnie z trasą, według stawki urzędowej w dniu rozładunku określonym w Zleceniu spedycyjnym.",
      },
      documentLinks: {
        generalRulesForCarriers: "OGÓLNE ZASADY DLA PRZEWOŹNIKÓW",
        additionalInformation: "INFORMACJE DODATKOWE",
        specialClientRequirements: "SPECJALNE WYMAGANIA KLIENTA",
      },
      additionalInformationModal: {
        additionalInformation: "Informacje dodatkowe",
        close: "Zamknij",
      },
      responsibilitiesTab: {
        truckData: "Kierowca ciężarówki",
        driverFullName: "Imię i nazwisko kierowcy",
        driverPhone: "Telefon kierowcy",
        truckPlates: "Ciągnik",
        trailerPlates: "Naczepa",
        manager: "Kierownik",
        search: "Szukaj",
        saveChanges: "Zapisz zmiany",
        projectUpdated: "Projekt zaktualizowany.",
        errorWhileUpdatingProject:
          "Coś poszło nie tak podczas aktualizacji projektu",
        errorWhileGettingManagers:
          "Coś poszło nie tak podczas pobierania kierowników",
        documentWillBeGenerated:
          "Dokument zostanie wygenerowany w najbliższym czasie.",
      },
      projectAlerts: {
        pleaseFillTruckData:
          "Brak numerów rejestracyjnych ciągnika lub naczepy.",
        projectMissingStatus: "Brak statusu zamówienia.",
        thereAreSomeChanges:
          "Wprowadzono pewne zmiany w tym zamówieniu. Prosimy o sprawdzenie szczegółów i potwierdzenie zamówienia.",
        hasMissingCrmAndInvoice:
          "Aby dokonać opłatę potrzebujemy CMR i fakturę za wykonane usługi transportowe.",
        hasMissingCrm: "Brakujący dokument CMR.",
        hasMissingInvoice:
          "Brakująca faktura: Dokument dotyczący usług transportowych.",
        hasRejectedDocument: "Proszę zauważyć, że dokument został odrzucony.",
        projectIsArchived: "Projekt został zarchiwizowany.",
        projectCompleted: "Zlecenie wykonane. Wciąż możesz dodać dokumenty.",
        projectRejected: "Zamówienie odrzucone. Wciąż możesz dodać dokumenty.",
        highValueIncreasedSecurity:
          "WYSOKA WARTOŚĆ / ZWIĘKSZONE BEZPIECZEŃSTWO",
      },
      projectReviewModal: {
        orderReview: "Zlecenie",
        orderDetails: "Szczegóły zlecenia",
        customer: "Zleceniodawca",
        supplier: "Wykonawca",
        orderConditions: "Warunki zlecenia",
        goodsType: "Ładunek",
        weight: "Waga",
        pallets: "Palety",
        shipmentFixation: "Mocowanie",
        carriageValue: "Wartość ładunku",
        trailerType: "Typ naczepy",
        temperature: "Temperatura",
        refNumber: "Nr referencyjny",
        other: "Inne",
        locationType: "Lokacja",
        date: "Data",
        locationName: "Nazwa",
        address: "Adres",
        coordinates: "Współrzędne",
        comments: "Komentarze",
        byCMR: "Ilość pallet według CMR",
        willBeChanged: "Wymiana palet",
        search: "Szukaj",
        specialConditions: "Ważne informację dotyczące zlecenia",
        paymentConditions: "Warunki płatności",
        freight: "Fracht",
        specialPaymentTerm: "Termin płatności",
        standardPaymentTerm: "Standardowy termin płatności:",
        crmPaymentNote: "CMR:",
        truckData: "Kierowca ciężarówki",
        driverFullName: "Imię i nazwisko kierowcy",
        driverPhone: "Telefon kierowcy",
        truckPlates: "Nr samochodu ciężarowego",
        trailerPlates: "Nr przyczepy",
        rejectOrder: "Odrzuć zamówienie",
        acceptOrder: "Przyjmij zamówienie",
        projectAccepted: "Zamówienie przyjęte.",
        projectRejected: "Zamówienie odrzucone.",
        close: "Zamknij",
        submitChanges: "Zatwierdź zmiany",
        managerNotAssignedYet: "Brak menedżera przypisanego do zlecenia",
        route: "Trasa:",
        conditionApplies:
          "Stawka VAT jest stosowana zgodnie z trasą, według stawki urzędowej w dniu rozładunku określonym w Zleceniu spedycyjnym.",
        mode: "Tryb",
        range: "Zakres",
        clientOrderNumber: "Numer zamówienia klienta",
        changeLog: "Dziennik zmian",
        warning: "Ostrzeżenie",
        doYouReallyWantReject: "Czy na pewno chcesz odrzucić to zamówienie?",
        doYouReallyWantAccept: "Czy na pewno chcesz potwierdzić to zamówienie?",
        iWouldLikeToReceiveFerryOffer: "Chciałbym otrzymać ofertę promu.",
      },
      specialRequirementsModal: {
        specialCarrierRequirements: "Specjalne wymagania dotyczące przewoźnika",
        close: "Zamknij",
      },
      documentsTab: {
        erroWhileDownloadingDocuments:
          "Coś poszło nie tak podczas pobierania dokumentów.",
        uploadDocument: "Dodaj dokument",
        type: "Typ",
        status: "Status",
        uploaded: "Data dodania",
        payment: "Płatność",
        order: "Zamówienie",
      },
      refreshModal: {
        somethingHasChanged: "Weszły zmiany",
        reloadPage: "Odśwież stronę",
        pleaseTryLoginAgain: "Spróbuj zalogować się ponownie",
      },
      roleAlert: {
        yourAccountHasManagerRole:
          "Twoje konto jest ograniczone jako obserwator (rola menedżera). Możesz zobaczyć tylko zamówienia przypisane do Ciebie.",
        yourAccountHasGroupLeaderRole:
          "Twoje konto jest ograniczone jako obserwator (menedżer, lider grupy). Możesz zobaczyć tylko zamówienia swojej grupy.",
        yourAccountHasViewerRole:
          "Twoje konto jest ograniczone jako obserwator w kontekście firmy {company}.",
        yourAccountHasEditorRole:
          "Twoje konto jest ograniczone do {company} w kontekście firmy. Nie możesz kontrolować użytkowników.",
      },
      listFilters: {
        filters: "Filtry",
        close: "Zamknij",
      },
      quickPayModal: {
        quickPayment: "Szybka płatność",
        discount: "Rabat",
        number: "Nr.",
        order: "Zlecenie",
        freight: " Cena",
        paymentUntil: "Termin",
        newPaymentDay: "Nowy termin",
        newFreight: "Nowa cena",
        paymentWillBeDone: "*Nowy termin płatności",
        ifPaymentDaySaturday:
          "Jeżeli nowy termin płatności wypada na sobotę, płatność następuje dzień wcześniej, w piątek.",
        ifPaymentDaySunday:
          "Jeżeli nowy termin płatności wypada na niedzielę, płatność następuje dzień później, w poniedziałek.",
        ifPaymentDayHoliday:
          "Jeżeli nowy termin płatności wypada na dzień ustawowo wolny od pracy w Republice Litewskiej, płatność zostanie zrealizowana w następnym dniu roboczym",
        weekendAndWorkdaysNotIncluded:
          "Weekendy i dni wolne od pracy nie są uwzględnione.",
        invoiceWillBeSentToEmail:
          'Faktura zostanie wysłana e-mailem. Będzie również dostępna w zakładce "Dokumenty".',
        close: "Zamknąć",
        nextStep: "Następny krok",
        invoiceWillBeGenerated:
          "Wkrótce zostanie wygenerowana faktura. Proszę sprawdzić w dziale dokumenty.",
        generateInvoice: "Faktura korygująca",
        youCanUploadOrGenerateInvoice:
          "Możesz przesłać swoją fakturę lub wybrać nasz szablon faktury.",
        uploadMy: "Dodaj fakturę",
        previewInvoice: "Przejdź do szblonu",
        creditInvoice: "FAKTURA KORYGUJĄCA",
        invoiceSerialNumber: "Seria:",
        invoiceNumber: "Nr.:",
        supplier: "WYKONAWCA",
        customer: "ODBIORCA",
        name: "Nazwa firmy",
        companyCode: "REGON",
        vatCode: "VAT",
        adres: "Adres",
        bankName: "Bank",
        bankAccountNumber: "Konto",
        swift: "SWIFT",
        iban: "IBAN",
        nameOfService: "Nazwa usługi",
        unit: "J.m.",
        transportation: "Usługa",
        amount: "Ilość",
        vat: "Stawka VAT",
        price: "Cena",
        total: "Ogółem",
        generalSum: "Suma ogółem",
        transportServiceTax:
          "Usługi transportowe są opodatkowane stawką VAT 0% zgodnie ustawy Art. 45 pkt 1 o VAT Republiki Litewskiej.",
        foreignSupplierInvoice:
          "Zgodnie z artykułami 44 i 196 Dyrektywy Rady z dnia 28 listopada 2006 r., 06/112/WE, odbiorca usługi będzie podlegał zwrotowi obciążenia w kraju odbioru.",
        generate: "Generować",
        dataIsMissing: "Brakujące dane",
        invoiceUploaded: "Faktura załadowana",
        uploadInvoice: "Dodaj fakturę",
        upload: "Wrzucić plik",
        date: "Data:",
        iAgreeWithLatestAdditionalInfoPolicy:
          "Akceptuję “Warunki specjalne i ogólne dla zleceń spedycyjnych”",
        ifYouChooseUploadInvoice:
          'Jeżeli wybierzesz opcję "dodaj fakturę" zwróć uwagę, że faktura powinna być wystawiona na nazwę firmy <b>:companyName</b>',
      },
      projectChangeLog: {
        loadType: "Informacje o ładunku",
        trailerType: "Typ naczepy",
        temperature: "Temperatura",
        refNo: "Ref. No.",
        status: "Status",
        country: "Kraj",
        city: "Miasto",
        postalCode: "Kod pocztowy",
        latitude: "Szerokość geograficzna",
        longtitude: "Długość geograficzna",
        refNumber: "Nr referencyjny",
        addressName: "Adres",
        addressLine: "Linia adresu",
        region: "Region",
        start: "Początek",
        end: "Koniec",
        locationName: "Nazwa lokalizacji",
        totalPrice: "Cena całkowita",
        freightPrice: "Fracht",
        extraPrice: "Dodatkowa cena",
        loadMore: "Pokaż więcej",
        alsoPleaseCheck: "Sprawdź również informacje o ruchu ładunku.",
      },
      projectChangeLogModal: {
        changeLog: "Dziennik zmian",
        close: "Zamknij",
      },
      updateBar: {
        appVersionUpdated:
          "Zaktualizowano wersję strony internetowej. Odśwież stronę, aby zobaczyć zmiany.",
        reload: "Przeładować",
      },
      passwordRules: {
        minCharsRequired: "Wymagane minimum 8 znaków.",
        oneUpperCaseRequired: "Wymagana co najmniej 1 duża litera.",
        oneLowerCaseRequired: "Wymagana co najmniej 1 mała litera.",
        oneNumberRequired: "Co najmniej 1 liczba wymagana.",
        dictionaryWord: "Nie może zawierać słowa ze słownika.",
        repetetiveChars: "Nie może mieć powtarzających się znaków.",
        sequentialChars: "Nie może zawierać kolejnych znaków.",
        mustMatch: "Hasło musi pasować.",
      },
      policies: {
        privacy_policy: "Polityka prywatności",
        terms_policy: "Warunki Użytkowania",
        marketing_policy: "Polityka marketingu",
        additional_information: "Informacje dodatkowe",
        special_conditions: "Specjalne wymagania klienta",
        special_and_general_conditions:
          "Warunki specjalne i ogólne dla zleceń spedycyjnych",
        procedural_rules: "Zasady proceduralne dotyczące zleceń spedycyjnych",
      },
      maintenancePage: {
        applicationIsDown:
          "Aplikacja jest wyłączona z powodu planowanej konserwacji.",
        pleaseComeBackLater: "Proszę wrócić później.",
      },
      documentStatus: {
        uploaded: "Dokument oczekuje na rejestrację w systemie księgowym.",
        pending:
          "Dokument jest zarejestrowany w systemie księgowym i oczekuje na weryfikację.",
        rejected:
          "Dokument został odrzucony z powodu przyczyny, ukazanej w sekcji komentarzu.",
        accepted: "Dokument został zaakceptowany.",
        generated: "Dokument został wygenerowany automatycznie przez system.",
        waiting: "Oczekiwanie na oryginalne dokumenty.",
      },
    },
    ru: {
      notifications: {
        all: "Все",
        notifications: "Уведомления",
        showing: "Показывать",
        loadMore: "Загрузить больше",
        viewAllNotifications: "Просмотр всех уведомлений",
        errorWhileGettingNotifications: "Ошибка при получении уведомлений",
        errorWhileUpdatingNotifications: "Ошибка при обновлении уведомлений",
        errorWhileDeletingNotifications: "Ошибка при удалении уведомлений",
        application: "Статус приложения",
        document: "Статус документа",
        project: "Статус проекта",
        projectCreated: "Создан новый заказ",
        projectUpdated: "Заказ был обновлен",
        documentUploaded: "Новый документ был загружен",
        documentUpdated: "Статус документа был обновлен",
        applicationAccepted: "Заявка была подтверждена",
        applicationRejected: "Заявка была отклонена",
        markAllAsRead: "Отметить все как прочитанное",
      },
      login: {
        partnersLogin: "Вход для партнеров",
        email: "Эл. почта",
        password: "Пароль",
        forgotPassword: "Забыли пароль?",
        login: "Вход в систему",
        dontHaveAccount: "У вас уже есть учетная запись?",
        register: "Зарегистрироваться",
        requiredField: "Обязательное поле.",
        invalidEmail: "Неверный адрес эл. почты.",
        tooMuchLetters: "Слишком много буквенных символов",
        pleaseLogin: "Пожалуйста, войдите в систему.",
        invalidUsernamePassword:
          "Неверное имя пользователя или пароль. Попробуйте еще раз.",
        joinManager: "Подключение для сотрудников Гиртеки",
        here: "подключись здесь",
        inPartnershipWith: "В сотрудничестве с",
      },
      registration: {
        partnersRegistration: "Регистрация партнеров",
        email: "Эл. почта",
        name: "Имя",
        lastName: "Фамилия",
        phone: "Телефон",
        password: "Пароль",
        confirmPassword: "Подтверждение пароля",
        register: "Зарегистрироваться",
        requiredField: "Обязательное поле.",
        invalidEmail: "Неверный адрес эл. почты.",
        tooShortNumber: "Слишком короткий номер.",
        minCharsRequired: "Введите не менее 8 символов.",
        oneUpperCaseRequired: "Требуется как минимум 1 прописная буква.",
        oneLowerCaseRequired: "Требуется как минимум 1 строчная буква.",
        oneNumberRequired: "Требуется как минимум 1 цифра.",
        passwordsDontMatch: "Пароли не совпадают.",
        registrationSuccessful:
          "Регистрация прошла успешно. Пожалуйста, подтвердите адрес эл. почты.",
        errorWhileRegistering:
          "Что-то пошло не так при попытке зарегистрироваться.",
        verificationSent: "Подтверждение отправлено.",
        errorWhileResendingVerification:
          "Что-то пошло не так при попытке верификации.",
        emailVerified: "Адрес эл. почты проверен.",
        errorWhileVerifyingEmail:
          "Что-то пошло не так при попытке верифицировать адрес эл. почты.",
        iHaveReadAndAgreeGirtekaLogistics:
          "Я ознакомился с Политикой конфиденциальности",
        privacyPolicy: "Everwest и согласен с ней",
        iHaveReadAndAgreeToThe: "Я ознакомился с",
        termsAndConditions: "Положениями и условиями и согласен с ними",
        iAgreeProcessing:
          "Я согласен на обработку моих контактных данных в целях прямого маркетинга компаниями группы Everwest в соответствии с",
        directMarketing: "Политикой прямого маркетинга.",
      },
      forgotPassword: {
        partnersForgotPassword: "Партнеры Забыли пароль?",
        email: "Эл. почта",
        send: "Отправить",
        requiredField: "Обязательное поле.",
        invalidEmail: "Неверный адрес эл. почты.",
        resetLinkSent:
          "Ссылка на сброс пароля отправлена. Пожалуйста, проверьте свою эл. почту.",
        errorWhileSendingLink:
          "Что-то пошло не так при отправке ссылки на сброс пароля.",
      },
      resetPassword: {
        partnersResetPassword: "Партнеры Сброс пароля",
        email: "Эл. почта",
        password: "Пароль",
        confirmPassword: "Подтверждение пароля",
        reset: "Сброс",
        requiredField: "Обязательное поле.",
        invalidEmail: "Неверный адрес эл. почты.",
        minCharsRequired: "Введите не менее 8 символов.",
        oneUpperCaseRequired: "Требуется как минимум 1 прописная буква.",
        oneLowerCaseRequired: "Требуется как минимум 1 строчная буква.",
        oneNumberRequired: "Требуется как минимум 1 цифра.",
        passwordsDontMatch: "Пароли не совпадают.",
        passwordResetedPleaseLogin:
          "Пароль сброшен. Пожалуйста, войдите в систему.",
        errorWhileResetingPassword: "Что-то пошло не так при сбросе пароля.",
      },
      invitation: {
        invitation: "Приглашение",
        email: "Эл. почта",
        name: "Имя",
        lastName: "Фамилия",
        phone: "Телефон",
        password: "Пароль",
        confirmPassword: "Подтверждение пароля",
        createAccount: "Создать учетную запись",
        requiredField: "Обязательное поле.",
        invalidEmail: "Неверный адрес эл. почты.",
        tooShortNumber: "Слишком короткий номер.",
        minCharsRequired: "Введите не менее 8 символов.",
        oneUpperCaseRequired: "Требуется как минимум 1 прописная буква.",
        oneLowerCaseRequired: "Требуется как минимум 1 строчная буква.",
        oneNumberRequired: "Требуется как минимум 1 цифра.",
        passwordsDontMatch: "Пароли не совпадают.",
        iHaveReadAndAgreeGirtekaLogistics:
          "Я ознакомился с Политикой конфиденциальности",
        privacyPolicy: "Everwest и согласен с ней",
        iHaveReadAndAgreeToThe: "Я ознакомился с",
        termsAndConditions: "Положениями и условиями и согласен с ними",
        iAgreeProcessing:
          "Я согласен на обработку моих контактных данных в целях прямого маркетинга компаниями группы Everwest в соответствии с",
        directMarketing: "Политикой прямого маркетинга.",
      },
      profile: {
        myProfile: "Мой профиль",
        change: "Изменить",
        edit: "Редактировать",
        resendVerification: "Повторная отправка подтверждения",
        delete: "Удалить",
        verified: "Проверено",
        notVerified: "Не проверено",
        pleaseVerifyEmail: "Пожалуйста, подтвердите свой адрес эл. почты.",
        errorWhileFetchingProfile:
          "Что-то пошло не так при получении данных о профиле.",
        errorWhileAcceptingPolicy:
          "Что-то пошло не так при попытке принять политику.",
        leader: "Лидер",
        manager: "Менеджер",
        iAgreeProcessing:
          "Я согласен на обработку моих контактных данных в целях прямого маркетинга компаниями группы Everwest в соответствии с",
        directMarketing: "Политикой прямого маркетинга.",
      },
      profileEdit: {
        pleaseUpdateProfile:
          "Пожалуйста, обновите профиль, чтобы продолжить работу с порталом.",
        myProfile: "Мой профиль",
        firstName: "Имя",
        lastName: "Фамилия",
        requiredField: "Обязательное поле.",
        email: "Эл. почта",
        invalidEmail: "Неверный адрес эл. почты.",
        phone: "Телефон",
        tooShortNumber: "Слишком короткий номер.",
        cancel: "Отменить",
        save: "Сохранить",
        profileInformationUpdated: "Информация в профиле обновлена.",
        errorWhileUpdatingProfile:
          "Что-то пошло не так при обновлении профиля.",
      },
      deleteProfileModal: {
        deleteProfile: "Удалить профиль?",
        ifYouDeleteProfile:
          "После удаления вы не сможете снова войти в систему.",
        cancel: "Отменить",
        delete: "Удалить",
        profileDeleted: "Профиль удален.",
        errorWhileDeletingProfile: "Что-то пошло не так при удалении профиля.",
      },
      changePassword: {
        yourPasswordExpired:
          "Срок действия вашего пароля истек. Пожалуйста, обновите пароль.",
        changePassword: "Изменить пароль",
        existingPassword: "Текущий пароль",
        requiredField: "Обязательное поле.",
        minCharsRequired: "Введите не менее 8 символов.",
        oneUpperCaseRequired: "Требуется как минимум 1 прописная буква.",
        oneLowerCaseRequired: "Требуется как минимум 1 строчная буква.",
        oneNumberRequired: "Требуется как минимум 1 цифра.",
        passwordsDontMatch: "Пароли не совпадают.",
        confirmPassword: "Подтверждение пароля",
        cancel: "Отменить",
        save: "Сохранить",
        passwordChanged: "Пароль изменен.",
        errorWhileChangingPassword: "Что-то пошло не так при смене пароля.",
      },
      userEdit: {
        userEdit: "Редактирование данных пользователя",
        firstName: "Имя",
        requiredField: "Обязательное поле.",
        lastName: "Фамилия",
        email: "Эл. почта",
        invalidEmail: "Неверный адрес эл. почты.",
        phone: "Телефон",
        tooShortNumber: "Слишком короткий номер.",
        role: "Роль",
        cancel: "Отменить",
        save: "Сохранить",
        errorWhileFetchingUser:
          "Что-то пошло не так при попытке получить данные пользователя.",
        userUpdated: "Данные пользователя обновлены.",
        errorWhileUpdatingUser:
          "Что-то пошло не так при обновлении данных пользователя.",
        accountantRoleHasReadOnly:
          "Роль зрителя имеет разрешения только на чтение.",
      },
      userInvitation: {
        userInvitation: "Приглашение пользователя",
        email: "Эл. почта",
        requiredField: "Обязательное поле.",
        invalidEmail: "Неверный адрес эл. почты.",
        role: "Роль",
        invite: "Пригласить",
        userInvited: "Пользователь приглашен",
        errorWhileInvitingUser:
          "Что-то пошло не так при попытке пригласить пользователя.",
        tokenMissing: "Отсутствует токен",
        accountCreatedPleaseLogin:
          "Вы создали учетную запись. Пожалуйста, войдите в систему.",
        invitationExpired: "Срок действия приглашения истек.",
        errorWhileCreatingAccount:
          "Что-то пошло не так при попытке создания учетной записи.",
        accountantRoleHasReadOnly:
          "Роль зрителя имеет разрешения только на чтение.",
      },
      userList: {
        users: "Пользователи",
        invite: "Пригласить",
        review: "Просмотреть",
        edit: "Редактировать",
        remove: "Удалить",
        removeFromCompany: "Удалить из профиля компании",
        email: "Эл. почта",
        name: "Имя",
        surname: "Фамилия",
        phone: "Телефон",
        roles: "Роли",
        actions: "Действия",
        errorWhileGettingUsers:
          "Что-то пошло не так при попытке получить данные пользователей.",
        userRemoved: "Пользователь удален.",
        errorWhileDeletingUsers:
          "Что-то пошло не так при попытке удалить пользователя.",
      },
      userShow: {
        user: "Пользователь",
        changeRole: "Изменить роль",
        removeFromCompany: "Удалить из компании",
      },
      notFound: {
        pageNotFound: "Страница не найдена",
        pageDoesNotExist: "Страницы с таким URL-адресом не существует.",
        goBack: "Вернуться назад",
      },
      header: {
        profile: "Профиль",
        notificationSettings: "Настройки почты",
        logout: "Выйти из системы",
        projects: "Проекты",
        documents: "Документы",
        users: "Пользователи",
        applications: "Заявки",
      },
      sidebar: {
        applications: "Регистрационные формы",
        orders: "Заказы",
        contacts: "Контакты",
        projects: "Заказы",
        users: "Пользователи",
        adminPanel: "Панель администратора",
        developerPanel: "Панель разработчика",
        profile: "Профиль",
        logout: "Выйти из системы",
        documents: "Документы",
        notifications: "Уведомления",
        language: "Язык",
        ediUploader: "Загрузчик счетов EDI",
        translations: "Переводы",
        cache: "Кэш",
        emailTemplates: "Шаблоны эл. почты",
        issueMail: "Написать письмо",
        companies: "Компании",
        help: "Помощь",
        feedback: "Обратная связь",
        maintenance: "Обеспечение функционирования",
        files: "Файлы",
        addresses: "Адреса",
        sessions: "Сессии",
        inPartnershipWith: "В сотрудничестве с",
        mailSettings: "Настройки почты",
      },
      contacts: {
        languages: "Языки:",
        contactUs: "Свяжитесь с нами",
      },
      fileDropzone: {
        dropFileHere: "Перетащите файл сюда",
        dragDropByClicking: "Перетащите или выберите файл(-ы), нажав здесь",
        someFilesNotUploaded: "Невозможно загрузить некоторые файлы",
        tryAgain: "Попробуйте еще раз",
        dragDropFilesHere: "Перетащите свои файлы сюда или:",
        browseFiles: "Воспользуйтесь браузером для поиска файлов",
      },
      confirmModal: {
        no: "Нет",
        yes: "Да",
      },
      searchInput: {
        search: "Поиск",
      },
      showPages: {
        show: "Показать",
        entries: "записи",
      },
      showPagesInfo: {
        showingEntries:
          "Отображение {activePage} на {perPage} из {pages} записей",
      },
      toaster: {
        justNow: "Только сейчас",
        success: "Успешно",
        error: "Ошибка",
        info: "Информация",
        warning: "Предупреждение",
      },
      addressInput: {
        searchNew: "Найти новый",
        selectExisting: "Выбрать существующий",
        selectAddress: "Выбрать адрес",
        errorWhileGettingAddresses:
          "Что-то пошло не так при попытке получить адреса.",
      },
      applicationFiles: {
        selectType: "Выбрать тип",
        requiredField: "Обязательное поле.",
        allFiles: "Все файлы",
        totalSizeLimit: "Общее ограничение по размеру",
        documentDeleted: "Документ удален.",
        errorWhileDeletingDocument:
          "Что-то пошло не так при попытке удалить документ.",
        documentsUploaded: "Документы загружены.",
        errorWhileUploadingDocuments:
          "Что-то пошло не так при попытке загрузить документы.",
        allFilesSizeExceeds: "Превышение общего размера файлов",
        documentUpdated: "Документ обновлен.",
        errorWhileUpdatingDocument:
          "Что-то пошло не так при попытке обновить документы.",
      },
      application: {
        recommendation: "Рекомендация",
        companyName: "Наименование компании",
        requiredField: "Обязательное поле.",
        companyAddress: "Адрес компании",
        firstName: "Имя",
        lastName: "Фамилия",
        position: "Должность",
        emailAddress: "Адрес эл. почты",
        invalidEmail: "Неверный адрес эл. почты.",
        mobilePhone: "Мобильный телефон",
        newPartnerApplication: "Заявка нового партнера",
        existingPartnerApplication: "Заявка действующего партнера",
        cancellationComment: "Комментарий по отмене:",
        company: "Компания",
        details: "Данные",
        companyRegistrationNumber: "Регистрационный номер компании",
        vatNumber: "Код плательщика НДС",
        vatNumberValidation:
          "Неправильный формат НДС. Допустимый пример: LT999999999999.",
        address: "Адрес",
        street: "Улица",
        cityZip: "Город, почтовый индекс",
        country: "Страна",
        selectCountry: "Выбрать страну",
        bankAccount: "Банковский счет",
        bankName: "Наименование банка",
        accountNumber: "Номер счета (IBAN)",
        swift: "SWIFT",
        currency: "Валюта",
        selectCurrency: "Выбрать валюту",
        companyContacts: "Контактные лица компании",
        phoneOptional: "Телефон (необязательно)",
        insurance: "Страхование",
        companyBrokerName: "Наименование компании/брокера",
        contactPhone: "Контактный телефон",
        companyDocuments: "Документы компании",
        allowedFiles: "Разрешенные файлы",
        saveDraft: "Сохранить черновик",
        submit: "Отправить",
        errorWhileStoringApplication:
          "Что-то пошло не так при попытке сохранить заявку.",
        applicationUpdated: "Заявка обновлена.",
        errorWhileUpdatingApplication:
          "Что-то пошло не так при попытке обновить заявку.",
        errorWhileGettingApplication:
          "Что-то пошло не так при попытке загрузить данные текущей заявки.",
        applicationSent: "Заявка отправлена. Пожалуйста, дождитесь одобрения.",
        directions: "Направления",
        direction: "Направление",
        selectDirection: "Выбрать направление",
        pleaseChooseMainDirection:
          "Пожалуйста, выберите основное поддерживаемое направление.",
        transId: "Транс ID",
        iHaveTransId: "У меня есть Транс ID",
        requiredDocs:
          "Необходимые документы: <strong>Свидетельство о регистрации НДС</strong>, <strong>свидетельство о регистрации компании</strong>, <strong>лицензия перевозчика</strong> (для экспедитора не требуется), xотя бы один <strong>документ о страховании CMR</strong> или <strong>экспедиторском страховании</strong>",
        questionnaire: "Анкета",
        yourAnswersAreImportant:
          "Ваши ответы важны для дальнейшего сотрудничества.",
        yes: "Дa",
        no: "Нет",
        productSafetyManagement:
          "Имеется ли у Вас сертификат, подтверждающий Вашу компетентность в области управления безопасностью продукции (ISO22001 / BRC / IFS)?",
        foodSafetyPolicy:
          "Ведете ли вы политику безопасности пищевых продуктов?",
        haccpPlan: "Есть ли у вас план HACCP?",
        chemicalsInCleansing:
          "Проверяете ли вы состав химических средств, используемых в процессе очистки и влияющих на безопасность продукции?",
        pestControlSystem: "Есть ли у вас система борьбы с вредителями?",
        cleaningSchedule:
          "Ведете ли вы график чистки полуприцепов и помещений?",
        proceduresForOperation:
          "Имеются ли у вас процедуры по эксплуатации и техническому обслуживанию всех транспортных средств и оборудования?",
        expertiseSecurityManagement:
          "Имеется ли у Вас сертификат, подтверждающий Вашу компетентность в области управления безопасностью (TAPA TSR / ISO28001)?",
        securityPolicy:
          "Ведете ли вы политику безопасности по перевозке грузов?",
        hvttCargo:
          "Существуют ли специальные процедуры перевозки дорогостоящих и легко реализуемых на рынке товаров?",
        secureVehicleParking:
          "Планируя маршрут водителя, предусматриваете ли вы безопасные места и стоянки для остановок?",
        routesAndStops: "Планируете ли Вы заранее маршруты и остановки?",
        driverAssessment:
          "Проводится ли у Вас проверка анкетных данных при аттестации водителей, перевозящих дорогостоящие грузы?",
        addIsoToCompanyDocuments:
          'Добавить ISO22001/ BRC/ IFS в раздел "Документация компании".',
        addTapaToCompanyDocuments:
          'Добавить TAPA TSR/ ISO28001 в раздел "Документация компании".',
        isoDocument: "ISO22001/ BRC/ IFS",
        tapaDocument: "TAPA TSR/ ISO28001",
        asOurValuedSupllier:
          "Поскольку вы являетесь важным поставщиком и частью цепочки поставок “Everwest”, мы просим вас предоставить нам последнюю информацию о сертификации вашей компании. Это поможет нам обеспечить качество наших услуг и соответствовать законодательным актам и требованиям клиентов. Пожалуйста, заполните приведенную ниже краткую анкету и приложите необходимые документы.",
        needYourCertificates: "Необходимы ваши сертификаты",
        formSubmitted: "Форма успешно отправлена.",
      },
      applicationList: {
        applications: "Заявки",
        edit: "Редактировать",
        delete: "Удалить",
        review: "Просмотреть",
        deleteApplication: "Удалить заявку?",
        vatPayerCode: "Код плательщика НДС",
        companyName: "Наименование компании",
        type: "Тип",
        status: "Статус",
        submittedAt: "Представлено в",
        actions: "Действия",
        errorWhileGettingApplications:
          "Что-то пошло не так при попытке получить данные текущей заявки.",
        applicationDeleted: "Заявка удалена.",
        errorWhileDeletingApplication:
          "Что-то пошло не так при попытке удалить заявку.",
      },
      applicationCreateButtons: {
        newGirtekaPartner: "Новый партнер",
        chooseOptionIfNotPartnerYet:
          "Выберите этот вариант, если вы еще не являетесь партнером.",
        existingGirtekaPartner: "Действующий партнер",
        chooseOptionIfNotRegistered:
          "Выберите эту опцию, если вы уже являетесь партнером.",
      },
      projectStatusConfirmModal: {
        confirmNewPosition: "Подтвердить новую позицию",
        confirmPlannedPosition: "Подтвердить запланированную позицию",
        positionStatus: "Статус позиции",
        chooseStatus: "Выбрать статус",
        requiredField: "Обязательное поле.",
        statusDate: "Дата актуализации статуса",
        comment: "Комментарий",
        cancel: "Отменить",
        confirm: "Подтвердить",
        time: "Время",
        projectStatusConfirmed: "Статус проекта подтвержден.",
        errorWhileConfirmingStatus:
          "Что-то пошло не так при попытке подтверждить статус.",
        dateMustBeBeforeTomorrow:
          "Завтрашний день не может быть указан в качестве даты. Укажите более раннюю дату",
        dateCannotBeSevenDaysEarlierThanNow:
          "Можно указывать только дату, выпадающую на период не ранее 7 дней до сегодняшнего дня.",
        thereIsNoConfirmedLocationAtLoadedPlace:
          "По месту погрузочных работ отсутствует подтвержденная площадка.",
        cargoLocationTime:
          "время в месте проведения погрузочно-разгрузочных работ",
        otherCargoStatesCanBeMarkedOnly:
          "Другие состояния груза могут быть отмечены только при наличии положения загрузки.",
      },
      projectMarkerPanel: {
        time: "Время:",
        type: "Тип:",
        latitude: "Широта:",
        longtitude: "Долгота:",
        confirmedBy: "Подтвердил:",
        confirmedAt: "Подтверждено в:",
        comment: "Комментарий:",
        clickToSeeMoreDetails:
          "Нажмите, чтобы ознакомиться с более подробной информацией...",
        newAddress: "Новый адрес",
        confirm: "Подтвердить",
        start: "Начало",
        end: "Конец",
      },
      notificationSettings: {
        notificationSettings: "Настройки почты",
        notifyMeWhen: "Сообщите мне, когда:",
        sendEmail: "Отправить эл. письмо:",
        save: "Сохранить",
        errorWhileGettingSettings: "Ошибка при получении настроек уведомлений.",
        settingsUpdated: "Настройки обновлены.",
        errorWhileUpdatingSettings:
          "Ошибка при обновлении настроек уведомлений.",
        instantly: "Мгновенно",
        periodically: "Ежедневно (17:00 Вильнюс/Европа)",
        projectCreated: "Проект создан",
        projectUpdated: "Проект обновлен",
        documentAccepted: "Документ принят",
        documentRejected: "Документ отклонен",
      },
      newDocumentModal: {
        uploadDocument: "Загрузить документ",
        group: "Группа",
        chooseGroup: "Выбрать группу",
        requiredField: "Обязательное поле.",
        type: "Тип",
        chooseType: "Выбрать тип",
        currency: "Валюта",
        comment: "Комментарий",
        cancel: "Отменить",
        upload: "Загрузить",
        documentUploaded: "Документ загружен.",
        errorWhileUploadingDocument:
          "Что-то пошло не так при попытке загрузить документ.",
        fieldMustContainValidValues:
          "Поле должно содержать допустимые значения.",
        youWillNotBeAbleToDeleteThisFileLater:
          "Вы не сможете удалить этот файл позже.",
        invalidPattern: "Допустимые символы: 0-9, '-', '/'.",
      },
      companySelectModal: {
        selectCompany: "Выбрать компанию",
        errorWhileChangingCompany: "Что-то пошло не так при смене компании.",
        loadMore: "Загрузить больше",
      },
      languageSelectModal: {
        selectLanguage: "Выбрать язык",
        errorWhileLoadingTranslations:
          "Что-то пошло не так при попытке загрузить переводы.",
      },
      projects: {
        projects: "Заказы",
        project: "Проект",
        loadingInfo: "Информация о погрузке",
        unloadingInfo: "Информация о разгрузке",
        truckTrailer: "Данные ТП и Водителя",
        loadType: "Тип груза",
        price: "Сумма",
        freight: "Фрахт",
        extra: "Доп. расходы",
        errorWhileGettingProjects:
          "Что-то пошло не так при попытке получить данные о проектах.",
        errorWhileGettingProject:
          "Что-то пошло не так при попытке получить данные о проекте.",
        refNo: "Ref. No.",
        pallets: "паллеты",
        byCMR: "(по CMR)",
        order: "Заказ",
        createdAt: "Создан",
        orderStatus: "Статус заказа",
        locationStatus: "Статус перевозки",
        notStarted: "Перевозка не начата",
        noData: "Нет данных",
        myAssignedOrders: "Мои текущие",
        archivedOrders: "Архив",
        incompleteOrders: "Незавершенные",
        date: "Дата",
        archive: "Архивировать",
        unarchive: "Разархивировать",
        archived: "Архивировано",
        details: "Данные",
        other: "Прочее",
        projectArchived: "Проект архивирован.",
        projectUnarchived: "Проект разархивирован.",
        errorWhileArchivingProject:
          "Что-то пошло не так при попытке архивировать проект.",
        errorWhileUnarchivingProject:
          "Что-то пошло не так при попытке разархивировать проект.",
        archiveProject: "Архивировать проект?",
        unarchiveProject: "Разархивировать проект?",
        willBeChanged: "Смена поддонов",
        value: "Значение",
        waitingForApproval: "Ожидание одобрения",
        confirmed: "Подтвержден",
        pending: "в Обработке",
        rejected: "Отклонeнные",
        loadMore: "Загрузить больше",
        orderRenewed: "Договор - заявка обновлен",
        trailerType: "Тип прицепа",
        acceptedBy: "Подтвержден",
        rejectedBy: "Отклонен",
        youWillBeAbleToSeeArchived:
          'Архивные заявки будут доступны выбрав фильтр "Архивные"',
        completed: "Завершенный",
        documentStatus: "Статус документа",
        unkownDocumentType: "Неизвестный тип документа",
        uploaded: "Добавлено",
        payment: "Оплата",
        noDocumentsUploaded: "Документы не добавлены",
        projectDocuments: "Документы проекта",
        mode: "Режим",
        range: "Диапазон",
        quickPayment: "Быстрая оплата",
        quickPaymentTooltip:
          "Быстрая оплата доступна один раз и только тогда, когда приняты счет-фактура и CMR.",
        quickPaymentTooltipSubmitted: "Счет-фактура уже выставлен",
        quickPaymentTooltipUploadOrGenerate:
          "Загрузить или сгенерировать кредитный счет-фактуру",
        paymentAfterInvoice:
          "Цена была пересчитана после принятия кредитного счета.",
        highValueIncreasedSecurity:
          "БОЛЬШАЯ СТОИМОСТЬ / УВЕЛИЧЕННАЯ БЕЗОПАСНОСТЬ",
        customer: "Заказчик",
      },
      projectDocumentPreview: {
        invoice: "Счет",
        message: "Сообщение",
        uploaded: "Загружен",
        paymentUntil: "Оплатить до",
        comment: "Комментарий",
        download: "Скачать",
        delete: "Удалить",
        documentDeleted: "Документ удален.",
        errorWhileDeletingProject:
          "Что-то пошло не так при попытке удалить документ.",
        order: "Заказ",
        documentRemoved:
          "Документ был удален. Если он вам все еще необходим, обратитесь к своему менеджеру.",
      },
      projectPanel: {
        orderDetails: "ПОДРОБНЫЕ СВЕДЕНИЯ О ДОГОВОР - ЗАЯВКЕ",
        route: "Маршрут:",
      },
      detailsPanel: {
        otherDetails: "Прочие сведения",
        responsibilities: "Уполномоченные",
        documents: "Документы",
        changeLog: "Журнал изменений",
      },
      documentTab: {
        deleteDocument: 'Удалить "{document}" документ?',
      },
      documentAlert: {
        allDocumentsAreGood: "Все документы в порядке.",
        pleaseNoticeRejectedPendingDocuments:
          "Проверьте, есть ли у вас отклоненные и/или ожидающие одобрения документы.",
        missingCmrInvoice:
          "Отсутствует CMR и/или счет: транспортные документы.",
      },
      documentItem: {
        invoice: "Счет",
        uploaded: "Загружен",
        payment: "Оплата",
        order: "Заказ",
        waitingForOriginal: "Ждем оригиналы документов.",
      },
      uploadDocumentModal: {
        uploadDocument: "Загрузить документ",
      },
      projectMap: {
        somethingWentWrongWhileLoading:
          "Что-то пошло не так при попытке загрузить карту.",
      },
      routeToggle: {
        planned: "Планируется",
        confirmed: "Подтвержден",
      },
      documents: {
        documents: "Документы",
        project: "Проект",
        group: "Группа",
        type: "Тип",
        attributes: "Атрибуты",
        comment: "Комментарий",
        status: "Статус",
        statusNote: "Примечание о статусе",
        createdBy: "Создал",
        deletedBy: "Удалил",
        download: "Скачать",
        myDocuments: "Только мои документы",
        showDeleted: "Включить удаленные",
        allStatuses: "Все статусы",
        fileDownloadFailed:
          "Документ был удален. Если он вам все еще необходим, обратитесь к своему менеджеру.",
        errorWhileGettingDocuments:
          "Что-то пошло не так при попытке получить документы.",
        review: "Просмотреть",
        actions: "Действия",
        deleteDocument: 'Удалить "{document}" документ?',
        documentStatus: "Статус документа",
        documentType: "Тип документа",
      },
      pdfReader: {
        filePreviewFailed:
          "Документ был удален. Если он вам все еще необходим, обратитесь к своему менеджеру.",
        filePreviewNotSupported:
          "Предварительный просмотр файлов не поддерживается в этом браузере.",
      },
      settings: {
        errorWhileDownloadingSettings:
          "Что-то пошло не так при попытке загрузить настройки.",
      },
      noData: {
        noData: "Нет данных",
      },
      formInfo: {
        noData: "Нет данных",
      },
      footer: {
        copyRightGirtekaLogistics: "© {year} Everwest",
        termsAndConditions: "Положения и условия",
        privacyPolicy: "Политика конфиденциальности",
        marketingPolicy: "Политикой маркетинга",
      },
      termsPolicyModal: {
        termsAndConditions: "Положения и условия",
        agree: "Согласиться",
        close: "Закрыть",
        iHaveReadAndAgreeGirtekaLogistics:
          "Я ознакомился с Политикой конфиденциальности Everwest",
        privacyPolicy: "и согласен с ней.",
        iHaveReadAndAgreeToThe: "Я ознакомился с Политикой конфиденциальности",
      },
      privacyPolicyModal: {
        privacyPolicy: "и согласен с ней.",
        close: "Закрыть",
      },
      marketingPolicyModal: {
        marketingPolicy: "Политикой маркетинга",
        iAgreeProcessing:
          "Я согласен на обработку моих контактных данных в целях прямого маркетинга компаниями группы Everwest в соответствии с политикой прямого маркетинга.",
        submit: "Разместить",
        close: "Закрыть",
      },
      orderStatus: {
        draft: "Заказ еще не отправлен менеджеру, ждем вашего подтверждения.",
        pending: "Ожидание подтверждения от менеджера.",
        cancelled: "Вы самостоятельно отменили свой заказ.",
        rejected: "Отклонен менеджером.",
        confirmed:
          "Заказ был успешно подтвержден; план будет подготовлен в ближайшее время.",
        planned:
          "Для выполнения вашего заказа был назначен перевозчик и грузовик/прицеп.",
        active: "Ваш груз находится в пути.",
        delivered:
          "Груз был выгружен полностью на всех разгрузочных площадках.",
        completed:
          "Груз был выгружен полностью на всех разгрузочных площадках, CMR прилагается.",
      },
      carrierWizard: {
        carrierPortalUpdated: "Портал перевозчика был обновлен.",
        whatNeedToKnow: "Что вам нужно знать?",
        next: "Далее",
        close: "Закрыть",
        intro: "Введение",
        projects: "Проекты",
        documents: "Документы",
        users: "Пользователи",
        applications: "Заявки",
      },
      introPageWizard: {
        weHaveUpdatedDesign:
          "Мы обновили дизайнерское решение и оптимизировали некоторые функции, чтобы сделать нашу систему более удобной для наших клиентов.",
        pleaseReviewWhatChanged: "Пожалуйста, ознакомьтесь с изменениями.",
      },
      projectPageWizard: {
        dashboardWhatAbleToSee: "Панель задач. Что я могу видеть?",
        onTheOrdersPage:
          "На странице Заказов вы увидите все отправленные вам Заказы с подробной информацией о грузоперевозках.",
        statusOfYourOrders: "Статусы ваших заказов.",
        hoverOverOrderRow:
          "Наведите курсор на строку Заказ и после нажатия вы увидите более подробную информацию о заказе.",
        statusOfYourAssignedOrders: "Статус ваших заказов.",
        documentsRelatedToOrder:
          "Документы, относящиеся к заказу (статус документа, загружен, принят, день оплаты).",
        managerWhoAccepted: "Менеджер, ответственный за заказ.",
        dateYouReceivedTheOrder: "Менеджер, ответственный за заказ.",
        filters: "Фильтры, позволяющие легко управлять заказами.",
        dashboardWhatActions: "Панель задач. Что я могу сделать?",
        easilySwitchToAnotherCompany:
          "Если у вас есть несколько компаний, привязанных к Порталу Перевозчика, Вы можете легко управлять ими, просто переключив профиль на другую компанию.",
        searchForOrders: "Поиск заказов по желаемому ключевому слову.",
        filterOrderByStatus: "Фильтруйте заказы по статусам.",
        filterBySpecificTime:
          "Фильтруйте по определенному периоду времени, чтобы узнать, сколько заказов вы получили. ",
        filterStillMissingOrders:
          "Этот фильтр предназначен для фильтрации заказов, у которых еще отсутствуют документы или которые отклонены бухгалтерией (например, им нужна дополнительная информация).",
        filterOnlyAssignedOrders:
          "Отфильтруйте только заказы, за которые Вы ответственны  (как менеджер).",
        filterArchivedOrders: "Фильтр заархивированных заказов.",
        clickOrderFindDetails: "Нажмите на заказ и узнайте подробности.",
        unconfirmedOrderView:
          "Просмотр неподтвержденногo Договор- Заявки. Какие действия я могу предпринять?",
        assignManagerForOrder: "Назначьте ответственного менеджера по заказу.",
        clickSeeLocationTypeComment:
          "Щелкните, чтобы просмотреть полный комментарий к Договору – Заявке. ",
        enterTruckDriverInfo: "Введите информацию о водителе тягача.",
        readAdditionalInfo: "Щелкните и прочтите дополнительную информацию.",
        readGeneralRules: "Щелкните и прочтите общие правила для перевозок.",
        acceptRejectOrder: "Примите или отклоните Договор – заявку. ",
        confirmedOrderView:
          "Просмотр подтвержденного Договор - Заявки. Какие действия я могу предпринять?",
        readAllDetails: "Ознакомьтесь со всеми деталями Договора - Заявки.",
        seeAllPeople:
          "Пpосмотрите список всех ответственных лиц за доставку груза.",
        enterOrChangeTruckDriver:
          "Введите или измените информацию о водителе тягача.",
        changeManager: "Смените ответственного менеджера.",
        girtekaManagerInfo: "Everwest manager information.",
        saveChanges: "Сохранить изменения.",
        newSectionDocuments:
          "Новый раздел документов, куда вы можете загружать документы.",
        autoGeneratedOrders:
          "Автоматически сформированный Договор-Заявка (если была подтвержден).",
        abilityToUploadDocument:
          "Возможность загрузить и просмотреть документ перед загрузкой в систему.",
        qpServiceIsAvailable:
          "Услуга предоставляется после получения и утверждения документов CMR и INVOICE.",
        qpServiceCanBeOrdered:
          "Необходимые документы подтверждены и не имеют ошибок, услуга может быть заказана.",
        qpPending: "Счет-фактура выставлена, ожидает утверждения бухгалтерией.",
        qpConfirmed:
          "Быстрая оплата была одобрена и будет произведена в ожидаемые сроки.",
        changelog:
          "1.	Вы можете удобнои и быстро просматривать изменения в информации о вашем заказе.",
        clientCompanyFilter:
          "Вы можете отфильтровать заказы по компаниям группы „Everwest“.",
        ableToSeeGroupOrders:
          'Вы можете видеть заказы группы компаний "Everwest".',
      },
      documentPageWizard: {
        documentsWhatActions: "Документы. Какие есть возможные функции?",
        onTheDocumentsPage:
          "На странице «Документы» вы увидите все загруженные Вами документы, назначенные конкретному заказу, с подробной информацией.",
        filterDocumentsByStatus: "Фильтровать документы по статусу документа.",
        filterDocumentsByType: "Фильтровать документы по типу документа.",
        filterOwnUploadedDocuments:
          "Фильтруйте только свои собственные загруженные документы.",
        filterAllDeletedDocuments: "Отфильтровать все удаленные документы.",
        reviewDownloadDocuments: "Просмотрите и скачайте документы.",
      },
      userPageWizard: {
        whatAbleToSee: "Какая информация мне доступна?",
        onUsersPageYouWillSee:
          "На странице «Пользователи» отображаются все пользователи, которых вы создали и назначили для конкретной компании, с детализацией необходимых сведений.",
        whatActionsCanTake: "Какие действия я могу предпринять?",
        searchUsersByDesiredKeyword: "Поиск пользователей по ключевому слову.",
        filterUsersByManagementRoles:
          "Сортировка пользователей по ролям администратора пользователей.",
        createNewUserByEnteringEmail:
          "Создание новых пользователей – вводите адреса электронной почты и выбирайте роль администратора пользователей.",
        viewUserProfile: "Просмотр профилей пользователей.",
        changeUserManagementRole: "Смена роли администратора пользователей.",
        removeUser: "Удаление пользователей.",
      },
      applicationPageWizard: {
        whatAbleToSee: "Какая информация мне доступна?",
        onApplicationsPageYouWillSee:
          "На странице «Заявки» отображаются все созданные вами компании и их статусы.",
        whatActionsCanTake: "Какие действия я могу предпринять?",
        createMoreThanOneCompany:
          "Создание нескольких принадлежащих вам компаний, которые уже являются партнерами Everwest.",
        createMoreThanOneNewCompany:
          "Создание одной новой компании или большего количества компаний, которые принадлежат вам и не являются партнерами Everwest.",
        editOrDeleteCompany:
          "Редактирование или удаление компании в статусе «Черновик»",
      },
      hotspot: {
        previous: "Назад",
        next: "Далее",
      },
      feedbackModal: {
        sendFeedback: "Оставить отзыв",
        topic: "Тема",
        idea: "Хочу поделиться идеей.",
        issue: "Хочу сообщить о проблеме.",
        comment: "Комментарий",
        requiredField: "Обязательное поле.",
        close: "Закрыть",
        send: "Отправить",
        feedbackSent: "Отклик отправлен",
        errorWhileSendingFeedback:
          "Что-то пошло не так при попытке oставить отзыв.",
        thisFormIsForDevelopmentPurposes:
          "Эта форма предназначена для целей разработки веб-сайта.",
      },
      maintenance: {
        weWouldLikeToInformYou:
          "Портал будет обновлен <strong>{date}</strong>. Портал будет недоступен в течение примерно <strong>{duration} часов</strong>. Спасибо за понимание.",
        errorWhileGettingMaintenanceStatus:
          "Что-то пошло не так при попытке получить информацию о статусе выполняемых работ.",
      },
      addressSearchInput: {
        search: "Поиск",
      },
      otherDetailsTab: {
        specialConditions:
          "Особые условия погрузки и/или транспортировки груза:",
        specialPaymentTerm: "Термин оплаты",
        standardPaymentTerm: "Стандартный срок оплаты:",
        crmPaymentNote: "CMR:",
        conditionApplies:
          "Ставка НДС рассчитывается в соответствии с маршрутом, по официальному курсу указанного в Экспедиционном заказе дня выгрузки.",
      },
      documentLinks: {
        generalRulesForCarriers: "ОБЩИЕ ПРАВИЛА ДЛЯ ПЕРЕВОЗЧИКОВ",
        additionalInformation: "ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ",
        specialClientRequirements: "ОСОБЫЕ ТРЕБОВАНИЯ КЛИЕНТА",
      },
      additionalInformationModal: {
        additionalInformation: "Дополнительная информация",
        close: "Закрыть",
      },
      responsibilitiesTab: {
        truckData: "Водитель тягача",
        driverFullName: "Имя и фамилия водителя",
        driverPhone: "Телефон водителя",
        truckPlates: "Номер тягача",
        trailerPlates: "Номер прицепа",
        manager: "Менеджер",
        search: "Поиск",
        saveChanges: "Сохранить изменения",
        projectUpdated: "Проект обновлен.",
        errorWhileUpdatingProject:
          "Что-то пошло не так при обновлении данных о проекте",
        errorWhileGettingManagers:
          "Что-то пошло не так при попытке получить данные менеджеров",
        documentWillBeGenerated:
          "Документ будет сформирован в ближайшее время.",
      },
      projectAlerts: {
        pleaseFillTruckData: "Пожалуйста, укажите данные тягача либо прицепа.",
        projectMissingStatus: "Статус заказа отсутствует.",
        thereAreSomeChanges:
          "Изменения в заказе. Пожалуйста, проверьте данные и подтвердите заказ.",
        hasMissingCrmAndInvoice:
          "Отсутствует CMR и/или счет: транспортные документы.",
        hasMissingCrm: "Отсутствует CMR.",
        hasMissingInvoice: "Отсутствует счет: транспортный документ.",
        hasRejectedDocument: "Обратите внимание: документ отклонен.",
        projectIsArchived: "Проект архивирован.",
        projectCompleted:
          "Проект завершен. Вы все еще можете добавить документы.",
        projectRejected:
          "Проект отклонен. Вы все еще можете добавить документы.",
        highValueIncreasedSecurity:
          "БОЛЬШАЯ СТОИМОСТЬ / УВЕЛИЧЕННАЯ БЕЗОПАСНОСТЬ",
      },
      projectReviewModal: {
        orderReview: "Обзор Договора - Заявки",
        orderDetails: "ПОДРОБНЫЕ СВЕДЕНИЯ ДОГОВОРА-ЗАЯВКИ",
        customer: "Заказчик",
        supplier: "Поставщик",
        orderConditions: "Условия заказа",
        goodsType: "Тип товара",
        weight: "Вес",
        pallets: "Паллеты",
        shipmentFixation: "Фиксация груза",
        carriageValue: "Стоимость перевозки",
        trailerType: "Тип прицепа",
        temperature: "Температура",
        refNumber: "Справ. номер",
        other: "Прочее",
        locationType: "Локация",
        date: "Дата",
        locationName: "Наименование",
        address: "Адрес",
        coordinates: "Координаты",
        comments: "Комментарии",
        byCMR: "(по CMR)",
        willBeChanged: "Смена поддонов",
        search: "Поиск",
        specialConditions:
          "Особые условия погрузки и/или транспортировки груза:",
        paymentConditions: "Условия оплаты",
        freight: "Фрахт",
        specialPaymentTerm: "Термин оплаты",
        standardPaymentTerm: "Стандартный срок оплаты:",
        crmPaymentNote: "CMR:",
        truckData: "Водитель тягача",
        driverFullName: "Имя и фамилия водителя",
        driverPhone: "Телефон водителя",
        truckPlates: "Номер тягача",
        trailerPlates: "Номер прицепа",
        rejectOrder: "Отклонить заказ",
        acceptOrder: "Принять заказ",
        projectAccepted: "Заказ принят.",
        projectRejected: "Заказ отклонен.",
        close: "Закрыть",
        submitChanges: "Внести изменения",
        managerNotAssignedYet: "Сотрудник еще не назначен",
        route: "Маршрут:",
        conditionApplies:
          "Ставка НДС рассчитывается в соответствии с маршрутом, по официальному курсу указанного в Экспедиционном заказе дня выгрузки.",
        mode: "Режим",
        range: "Диапазон",
        clientOrderNumber: "Номер заказа клиента",
        changeLog: "Журнал изменений",
        warning: "Предупреждение",
        doYouReallyWantReject: "Вы уверены, что хотите отклонить этот заказ?",
        doYouReallyWantAccept: "Вы уверены, что хотите подтвердить этот заказ?",
        iWouldLikeToReceiveFerryOffer:
          "Я хотел бы получить предложение по парому.",
      },
      specialRequirementsModal: {
        specialCarrierRequirements: "Специальные требования к перевозчику",
        close: "Закрыть",
      },
      documentsTab: {
        erroWhileDownloadingDocuments:
          "Что-то пошло не так при попытке загрузить документы.",
        uploadDocument: "Загрузить документ",
        type: "Тип",
        status: "Статус",
        uploaded: "Загружен",
        payment: "Оплата",
        order: "Заказ",
      },
      refreshModal: {
        somethingHasChanged: "Произошли изменения",
        reloadPage: "Перезагрузите страницу",
        pleaseTryLoginAgain: "Попробуйте подключиться еще раз.",
      },
      roleAlert: {
        yourAccountHasManagerRole:
          "Ваша учетная запись ограничена в качестве наблюдателя (роль менеджера). Вы можете видеть только назначенные вам заказы.",
        yourAccountHasGroupLeaderRole:
          "Ваша учетная запись ограничена в качестве наблюдателя (менеджера, лидера группы). Вы можете видеть заказы только своей группы.",
        yourAccountHasViewerRole:
          "Ваша учетная запись ограничена в качестве наблюдателя {company}. в контексте компании.",
        yourAccountHasEditorRole:
          "Ваша учетная запись ограничена {company} в контексте компании. Вы не можете контролировать пользователей.",
      },
      listFilters: {
        filters: "Фильтры",
        close: "Закрыть",
      },
      quickPayModal: {
        quickPayment: "Быстрая оплата",
        discount: "Скидка",
        number: " №",
        order: "Заказ",
        freight: "Цена",
        paymentUntil: "Срок оплаты",
        newPaymentDay: "Новый срок оплаты",
        newFreight: "Новая цена",
        paymentWillBeDone: "*Новый срок оплаты",
        ifPaymentDaySaturday:
          "Если новый срок оплаты выпадает на субботу, то оплата производится на один день раньше, в пятницу",
        ifPaymentDaySunday:
          "Если новый срок оплаты выпадает на воскресенье, то оплата производится на один день позже, в понедельник",
        ifPaymentDayHoliday:
          "Если новый срок оплаты выпадает на государственный праздник в Литовской Республике, платеж будет произведен на следующий рабочий день",
        weekendAndWorkdaysNotIncluded: "Выходные и нерабочие дни не включены.",
        invoiceWillBeSentToEmail:
          'Счет-фактура будет отправлена по электронной почте. Она также будет доступна в разделе "Документы".',
        close: "Закрыть",
        nextStep: "Следующий шаг",
        invoiceWillBeGenerated:
          'Счет-фактура скоро будет сформирована. Пожалуйста, проверьте раздел "Документы".',
        generateInvoice: "Kорректировочный счет-фактура",
        youCanUploadOrGenerateInvoice:
          "Вы можете добавить свою счет-фактруру или использовать наш шаблон.",
        uploadMy: "Загрузить",
        previewInvoice: "Просмотр шаблона",
        creditInvoice: "КОРРЕКТИРОВОЧНЫЙ СЧЕТ-ФАКТУРА",
        invoiceSerialNumber: "Серия:",
        invoiceNumber: "№:",
        supplier: "ПОСТАВЩИК",
        customer: "ЗАКАЗЧИК",
        name: "Компания",
        companyCode: "ОГРН",
        vatCode: "ИНН/УНП",
        address: "Адрес",
        bankName: "Банк",
        bankAccountNumber: "Счет",
        swift: "SWIFT",
        iban: "IBAN",
        nameOfService: "Название услуги",
        unit: "Eд. измерения",
        transportation: "Перевозка",
        amount: "Количество",
        vat: "НДС",
        price: "Цена",
        suma: "Сумма",
        total: "Всего",
        generalSum: "Общая сумма",
        transportServiceTax:
          "Транспортные услуги облагаются налогом по ставке НДС 0% в соответствии со статьей 45 часть 1 закона о НДС Литовской Республики.",
        foreignSupplierInvoice:
          "В соответствии с положениями статей 44 и 196 Директивы Совета от 28 ноября 2006 г., 06/112/ЕС с получателя услуги будет взыматься налог на добавленную стоимость.",
        generate: " Потвердить",
        dataIsMissing: "Отсутствующие данные",
        invoiceUploaded: "Счет-фактура загружена",
        uploadInvoice: "Загрузить счет-фактуру",
        upload: "Загрузить",
        date: "Дата:",
        iAgreeWithLatestAdditionalInfoPolicy:
          "Акцептирую «Особые и общие условия экспедиционного заказа»",
        ifYouChooseUploadInvoice:
          'Если вы выбрали "загрузить счет", обратите внимание, что счет должен быть выставлен на имя компании <b>:companyName</b>',
      },
      projectChangeLog: {
        loadType: "Тип груза",
        trailerType: "Тип прицепа",
        temperature: "Температура",
        refNo: "Ref. No.",
        status: "Статус",
        country: "Страна",
        city: "Город",
        postalCode: "Почтовый индекс",
        latitude: "Широта",
        longtitude: "Долгота",
        refNumber: "Справ. номер",
        addressName: "Адрес",
        addressLine: "Адресная строка",
        region: "Регион",
        start: "Начало",
        end: "Конец",
        locationName: "Название местоположения",
        totalPrice: "Итоговая цена",
        freightPrice: "Фрахт",
        extraPrice: "Дополнительная цена",
        loadMore: "Загрузить больше",
        alsoPleaseCheck: "Также проверьте информацию о перемещении груза.",
      },
      projectChangeLogModal: {
        changeLog: "Журнал изменений",
        close: "Закрыть",
      },
      updateBar: {
        appVersionUpdated:
          "Версия сайта обновлена. Пожалуйста, перезагрузите страницу, чтобы увидеть изменения.",
        reload: "Перезагрузить",
      },
      passwordRules: {
        minCharsRequired: "Введите не менее 8 символов.",
        oneUpperCaseRequired: "Требуется как минимум 1 прописная буква.",
        oneLowerCaseRequired: "Требуется как минимум 1 строчная буква.",
        oneNumberRequired: "Требуется как минимум 1 цифра.",
        dictionaryWord: "Не может иметь словарное слово.",
        repetetiveChars: "Не может иметь повторяющихся символов.",
        sequentialChars: "Не может иметь последовательных символов.",
        mustMatch: "Пароли должны совпадать.",
      },
      policies: {
        privacy_policy: "Политика конфиденциальности",
        terms_policy: "Положения и условия",
        marketing_policy: "Политикой маркетинга",
        additional_information: "Дополнительная информация",
        special_conditions: "Особые требования клиента",
        special_and_general_conditions:
          "Особые и общие условия экспедиционного заказа",
        procedural_rules: "Процедурные правила экспедиционного заказа",
      },
      maintenancePage: {
        applicationIsDown: "Приложение не работает для планового обслуживания.",
        pleaseComeBackLater: "Пожалуйста, посетите сайт позже.",
      },
      documentStatus: {
        uploaded: "Документ загружен и ожидает регистрации в учетной системе.",
        pending:
          "Документ зарегистрирован в учетной системе и ожидает просмотра.",
        rejected:
          "Документ был отклонен, причину отклонения смотреть в комментариях.",
        accepted: "Документ подтвержден.",
        generated: "Документ был создан автоматически через систему.",
        waiting: "Ожидание оригинальных документов.",
      },
    },
    lt: {
      notifications: {
        all: "Visi",
        notifications: "Pranešimai",
        showing: "Rodoma",
        loadMore: "Įkelti daugiau",
        viewAllNotifications: "Peržiūrėti visus pranešimus",
        errorWhileGettingNotifications: "Klaida, kai gaunami pranešimai",
        errorWhileUpdatingNotifications: "Klaida, kai atnaujinami pranešimai",
        errorWhileDeletingNotifications: "Klaida, kai trinami pranešimai",
        application: "Paraiškos būsena",
        document: "Dokumento būsena",
        project: "Projekto būsena",
        projectCreated: "Sukurtas naujas užsakymas",
        projectUpdated: "Užsakymas buvo atnaujintas",
        documentUploaded: "Įkeltas naujas dokumentas",
        documentUpdated: "Dokumento būsena atnaujinta",
        applicationAccepted: "Paraiška buvo patvirtinta",
        applicationRejected: "Paraiška buvo atmesta",
        markAllAsRead: "Pažymėti visus kaip skaitytus",
      },
      login: {
        partnersLogin: "Prisijungimas partneriams",
        email: "El. pašto adresas",
        password: "Slaptažodis",
        forgotPassword: "Pamiršote slaptažodį?",
        login: "Prisijungti",
        dontHaveAccount: "Neturite paskyros?",
        register: "Registruotis",
        requiredField: "Privalomas laukas.",
        invalidEmail: "Netinkamas el. pašto adresas.",
        tooMuchLetters: "Per daug raidžių",
        pleaseLogin: "Prisijunkite.",
        invalidUsernamePassword:
          "Netinkamas naudotojo vardas arba slaptažodis. Pabandykite dar kartą.",
        joinManager: "Jei esi Girtekos vadybininkas",
        here: "prisijunk čia",
        inPartnershipWith: "Bendradarbiaujant su",
      },
      registration: {
        partnersRegistration: "Registracija partneriams",
        email: "El. pašto adresas",
        name: "Vardas",
        lastName: "Pavardė",
        phone: "Tel. nr.",
        password: "Slaptažodis",
        confirmPassword: "Patvirtinti slaptažodį",
        register: "Registruotis",
        requiredField: "Privalomas laukas.",
        invalidEmail: "Netinkamas el. pašto adresas.",
        tooShortNumber: "Per trumpas numeris.",
        minCharsRequired: "Būtini mažiausiai 8 simboliai.",
        oneUpperCaseRequired: "Būtina bent 1 didžioji raidė.",
        oneLowerCaseRequired: "Būtina bent 1 mažoji raidė.",
        oneNumberRequired: "Būtinas bent 1 skaičius.",
        passwordsDontMatch: "Slaptažodžiai nesutampa.",
        registrationSuccessful:
          "Registracija sėkminga. Patvirtinkite el. pašto adresą.",
        errorWhileRegistering: "Registruojantis įvyko klaida.",
        verificationSent: "Išsiųstas patvirtinimas.",
        errorWhileResendingVerification:
          "Iš naujo siunčiant patvirtinimą įvyko klaida.",
        emailVerified: "El. pašto adresas patvirtintas.",
        errorWhileVerifyingEmail: "Tvirtinant el. pašto adresą įvyko klaida.",
        iHaveReadAndAgreeGirtekaLogistics:
          "Aš perskaičiau ir sutinku su „Everwest“",
        privacyPolicy: "Privatumo politika",
        iHaveReadAndAgreeToThe: "Aš perskaičiau ir sutinku su",
        termsAndConditions: "Sąlygomis",
        iAgreeProcessing:
          "Sutinku, kad „Everwest“ grupės įmonės tvarkytų mano kontaktinius duomenis tiesioginės rinkodaros tikslu pagal",
        directMarketing: "Tiesioginės rinkodaros politiką.",
      },
      forgotPassword: {
        partnersForgotPassword: "Pamiršau partnerio slaptažodį",
        email: "El. pašto adresas",
        send: "Siųsti",
        requiredField: "Privalomas laukas.",
        invalidEmail: "Netinkamas el. pašto adresas.",
        resetLinkSent:
          "Atkūrimo nuoroda išsiųsta. Patikrinkite savo el. pašto adresą.",
        errorWhileSendingLink:
          "Siunčiant slaptažodžio atkūrimo nuorodą įvyko klaida.",
      },
      resetPassword: {
        partnersResetPassword: "Atkurti partnerio slaptažodį",
        email: "El. pašto adresas",
        password: "Slaptažodis",
        confirmPassword: "Patvirtinti slaptažodį",
        reset: "Atkurti",
        requiredField: "Privalomas laukas.",
        invalidEmail: "Netinkamas el. pašto adresas.",
        minCharsRequired: "Būtini mažiausiai 8 simboliai.",
        oneUpperCaseRequired: "Būtina bent 1 didžioji raidė.",
        oneLowerCaseRequired: "Būtina bent 1 mažoji raidė.",
        oneNumberRequired: "Būtinas bent 1 skaičius.",
        passwordsDontMatch: "Slaptažodžiai nesutampa.",
        passwordResetedPleaseLogin: "Slaptažodis atkurtas. Prisijunkite.",
        errorWhileResetingPassword: "Atkuriant slaptažodį įvyko klaida.",
      },
      invitation: {
        invitation: "Kvietimas",
        email: "El. pašto adresas",
        name: "Vardas",
        lastName: "Pavardė",
        phone: "Tel. nr.",
        password: "Slaptažodis",
        confirmPassword: "Patvirtinti slaptažodį",
        createAccount: "Sukurti paskyrą",
        requiredField: "Privalomas laukas.",
        invalidEmail: "Netinkamas el. pašto adresas.",
        tooShortNumber: "Per trumpas numeris.",
        minCharsRequired: "Būtini mažiausiai 8 simboliai.",
        oneUpperCaseRequired: "Būtina bent 1 didžioji raidė.",
        oneLowerCaseRequired: "Būtina bent 1 mažoji raidė.",
        oneNumberRequired: "Būtinas bent 1 skaičius.",
        passwordsDontMatch: "Slaptažodžiai nesutampa.",
        iHaveReadAndAgreeGirtekaLogistics:
          "Aš perskaičiau ir sutinku su „Everwest“",
        privacyPolicy: "Privatumo politika",
        iHaveReadAndAgreeToThe: "Aš perskaičiau ir sutinku su",
        termsAndConditions: "Sąlygomis",
        iAgreeProcessing:
          "Sutinku, kad „Everwest“ grupės įmonės tvarkytų mano kontaktinius duomenis tiesioginės rinkodaros tikslu pagal",
        directMarketing: "Tiesioginės rinkodaros politiką.",
      },
      profile: {
        myProfile: "Mano profilis",
        change: "Keisti",
        edit: "Redaguoti",
        resendVerification: "Iš naujo išsiųsti patvirtinimą",
        delete: "Ištrinti",
        verified: "Patvirtinta",
        notVerified: "Nepatvirtinta",
        pleaseVerifyEmail: "Patvirtinkite savo el. pašto adresą.",
        errorWhileFetchingProfile: "Prisijungiant prie paskyros įvyko klaida.",
        errorWhileAcceptingPolicy: "Priimant politiką įvyko klaida.",
        leader: "Lyderis",
        manager: "Vadybininkas",
        iAgreeProcessing:
          "Sutinku, kad „Everwest“ grupės įmonės tvarkytų mano kontaktinius duomenis tiesioginės rinkodaros tikslu pagal",
        directMarketing: "Tiesioginės rinkodaros politiką.",
      },
      profileEdit: {
        pleaseUpdateProfile:
          "Norėdami toliau naudotis portalu, atnaujinkite profilį.",
        myProfile: "Mano profilis",
        firstName: "Vardas",
        lastName: "Pavardė",
        requiredField: "Privalomas laukas.",
        email: "El. pašto adresas",
        invalidEmail: "Netinkamas el. pašto adresas.",
        phone: "Tel. nr.",
        tooShortNumber: "Per trumpas numeris.",
        cancel: "Atšaukti",
        save: "Išsaugoti",
        profileInformationUpdated: "Profilio informacija atnaujinta.",
        errorWhileUpdatingProfile: "Atnaujinant profilį įvyko klaida.",
      },
      deleteProfileModal: {
        deleteProfile: "Ištrinti profilį?",
        ifYouDeleteProfile: "Po ištrynimo negalėsite prisijungti dar kartą.",
        cancel: "Atšaukti",
        delete: "Ištrinti",
        profileDeleted: "Profilis ištrintas.",
        errorWhileDeletingProfile: "Ištrinant profilį įvyko klaida.",
      },
      changePassword: {
        yourPasswordExpired:
          "Jūsų slaptažodžio galiojimo laikas baigėsi. Atnaujinkite į naują.",
        changePassword: "Pakeisti slaptažodį",
        existingPassword: "Esamas slaptažodis",
        requiredField: "Privalomas laukas.",
        minCharsRequired: "Būtini mažiausiai 8 simboliai.",
        oneUpperCaseRequired: "Būtina bent 1 didžioji raidė.",
        oneLowerCaseRequired: "Būtina bent 1 mažoji raidė.",
        oneNumberRequired: "Būtinas bent 1 skaičius.",
        passwordsDontMatch: "Slaptažodžiai nesutampa.",
        confirmPassword: "Patvirtinti slaptažodį",
        cancel: "Atšaukti",
        save: "Išsaugoti",
        passwordChanged: "Slaptažodis pakeistas.",
        errorWhileChangingPassword: "Keičiant slaptažodį įvyko klaida.",
      },
      userEdit: {
        userEdit: "Naudotojo redagavimas",
        firstName: "Vardas",
        requiredField: "Privalomas laukas.",
        lastName: "Pavardė",
        email: "El. pašto adresas",
        invalidEmail: "Netinkamas el. pašto adresas.",
        phone: "Tel. nr.",
        tooShortNumber: "Per trumpas numeris.",
        role: "Funkcija",
        cancel: "Atšaukti",
        save: "Išsaugoti",
        errorWhileFetchingUser: "Gaunant naudotoją įvyko klaida.",
        userUpdated: "Naudotojas atnaujintas.",
        errorWhileUpdatingUser: "Atnaujinant naudotoją įvyko klaida.",
        accountantRoleHasReadOnly: "Stebėtojo rolė turi tik skaitymo teises",
      },
      userInvitation: {
        userInvitation: "Naudotojo kvietimas",
        email: "El. pašto adresas",
        requiredField: "Privalomas laukas.",
        invalidEmail: "Netinkamas el. pašto adresas.",
        role: "Funkcija",
        invite: "Pakviesti",
        userInvited: "Naudotojas pakviestas",
        errorWhileInvitingUser: "Pakviečiant naudotoją įvyko klaida.",
        tokenMissing: "Trūksta žetono",
        accountCreatedPleaseLogin: "Paskyra sukurta. Prisijunkite.",
        invitationExpired: "Kvietimo galiojimo laikas baigėsi.",
        errorWhileCreatingAccount: "Kuriant paskyrą įvyko klaida.",
        accountantRoleHasReadOnly: "Stebėtojo rolė turi tik skaitymo teises",
      },
      userList: {
        users: "Naudotojai",
        invite: "Pakviesti",
        review: "Peržiūrėti",
        edit: "Redaguoti",
        remove: "Pašalinti",
        removeFromCompany: "Pašalinti iš įmonės",
        email: "El. pašto adresas",
        name: "Vardas",
        surname: "Pavardė",
        phone: "Tel. nr.",
        roles: "Funkcijos",
        actions: "Veiksmai",
        errorWhileGettingUsers: "Gaunant naudotojus įvyko klaida.",
        userRemoved: "Naudotojas pašalintas.",
        errorWhileDeletingUsers: "Ištrinant naudotoją įvyko klaida.",
      },
      userShow: {
        user: "Naudotojas",
        changeRole: "Keisti funkciją",
        removeFromCompany: "Pašalinti iš įmonės",
      },
      notFound: {
        pageNotFound: "Puslapis nerastas",
        pageDoesNotExist: "Puslapis su dabartiniu URL adresu neegzistuoja.",
        goBack: "Grįžti atgal",
      },
      header: {
        profile: "Profilis",
        notificationSettings: "Pašto nustatymai",
        logout: "Atsijungti",
        projects: "Projektai",
        documents: "Dokumentai",
        users: "Naudotojai",
        applications: "Paraiškos",
      },
      sidebar: {
        applications: "Paraiškos",
        orders: "Užsakymai",
        contacts: "Kontaktai",
        projects: "Užsakymai",
        users: "Naudotojai",
        adminPanel: "Administratoriaus sritis",
        developerPanel: "Programuotojo sritis",
        profile: "Profilis",
        logout: "Atsijungti",
        documents: "Dokumentai",
        notifications: "Pranešimai",
        language: "Kalba",
        ediUploader: "EDI įkėlimas",
        translations: "Vertimai",
        cache: "Podėlis (angl. „Cache“)",
        emailTemplates: "El. pašto šablonai",
        issueMail: "Rašyti laišką",
        companies: "Įmonės",
        help: "Pagalba",
        feedback: "Atsiliepimai",
        maintenance: "Techninė priežiūra",
        files: "Failai",
        addresses: "Adresai",
        sessions: "Sesijos",
        inPartnershipWith: "Bendradarbiaujant su",
        mailSettings: "Pašto nustatymai",
      },
      contacts: {
        languages: "Kalbos:",
        contactUs: "Susisiekite su mumis",
      },
      fileDropzone: {
        dropFileHere: "Nuvilkite failą čia",
        dragDropByClicking:
          "Vilkite ir paleiskite arba pasirinkite failą (-us) spustelėdami čia",
        someFilesNotUploaded: "Kai kurių failų negalima įkelti",
        tryAgain: "Pabandykite dar kartą",
        dragDropFilesHere: "Vilkite ir paleiskite failus čia arba:",
        browseFiles: "Naršykite failus",
      },
      confirmModal: {
        no: "Ne",
        yes: "Taip",
      },
      searchInput: {
        search: "Paieška",
      },
      showPages: {
        show: "Rodyti",
        entries: "įrašai",
      },
      showPagesInfo: {
        showingEntries:
          "Rodoma nuo {activePage} iki {perPage} iš {pages} įrašų",
      },
      toaster: {
        justNow: "Ką tik",
        success: "Pavyko",
        error: "Klaida",
        info: "Informacija",
        warning: "Įspėjimas",
      },
      addressInput: {
        searchNew: "Ieškoti naujų",
        selectExisting: "Pasirinkti esamą",
        selectAddress: "Pasirinkti adresą",
        errorWhileGettingAddresses: "Gaunant adresus įvyko klaida.",
      },
      applicationFiles: {
        selectType: "Pasirinkti tipą",
        requiredField: "Privalomas laukas.",
        allFiles: "Visi failai",
        totalSizeLimit: "Didžiausias galimas dydis",
        documentDeleted: "Dokumentas ištrintas.",
        errorWhileDeletingDocument: "Ištrinant dokumentą įvyko klaida.",
        documentsUploaded: "Dokumentai įkelti.",
        errorWhileUploadingDocuments: "Įkeliant dokumentus įvyko klaida.",
        allFilesSizeExceeds: "Visų failų dydis viršija",
        documentUpdated: "Dokumentas atnaujintas.",
        errorWhileUpdatingDocument: "Atnaujinant dokumentus įvyko klaida.",
      },
      application: {
        recommendation: "Rekomendacija",
        companyName: "Įmonės pavadinimas",
        requiredField: "Privalomas laukas.",
        companyAddress: "Įmonės adresas",
        firstName: "Vardas",
        lastName: "Pavardė",
        position: "Pareigos",
        emailAddress: "El. pašto adresas",
        invalidEmail: "Netinkamas el. pašto adresas.",
        mobilePhone: "Mobilusis telefonas",
        newPartnerApplication: "Naujo partnerio paraiška",
        existingPartnerApplication: "Esamo partnerio paraiška",
        cancellationComment: "Atšaukimo komentaras:",
        company: "Įmonė",
        details: "Išsami informacija",
        companyRegistrationNumber: "Įmonės registracijos numeris",
        vatNumber: "PVM mokėtojo kodas",
        vatNumberValidation:
          "Neteisingas PVM formatas. Tinkamas pavyzdys: LT999999999999.",
        address: "Adresas",
        street: "Gatvė",
        cityZip: "Miestas, pašto kodas",
        country: "Šalis",
        selectCountry: "Pasirinkite šalį",
        bankAccount: "Atsiskaitomoji sąskaita",
        bankName: "Banko pavadinimas",
        accountNumber: "Atsiskaitomosios sąskaitos numeris (IBAN)",
        swift: "SWIFT kodas",
        currency: "Valiuta",
        selectCurrency: "Pasirinkite valiutą",
        companyContacts: "Įmonės kontaktai",
        phoneOptional: "Telefonas (neprivaloma)",
        insurance: "Draudimas",
        companyBrokerName: "Įmonės / brokerio pavadinimas",
        contactPhone: "Kontaktinis telefonas",
        companyDocuments: "Įmonės dokumentai",
        allowedFiles: "Leidžiami failai",
        saveDraft: "Išsaugoti juodraštį",
        submit: "Siųsti",
        errorWhileStoringApplication: "Saugant paraišką įvyko klaida.",
        applicationUpdated: "Paraiška atnaujinta.",
        errorWhileUpdatingApplication: "Atnaujinant paraišką įvyko klaida.",
        errorWhileGettingApplication: "Gaunant paraišką įvyko klaida.",
        applicationSent: "Paraiška išsiųsta. Laukite patvirtinimo.",
        directions: "Kryptys",
        direction: "Kryptis",
        selectDirection: "Pasirinkite kryptį",
        pleaseChooseMainDirection: "Pasirinkite pagrindinę veikimo kryptį.",
        transId: "Trans ID",
        iHaveTransId: "Turiu Trans ID",
        requiredDocs:
          "Reikalingi dokumentai: <strong>PVM mokėtojo registracijos pažymėjimas</strong>, <strong>įmonės registracijos pažymėjimas</strong>, <strong>vežėjo licenzija</strong> (ekspeditoriui nereikia), bent vienas <strong>CMR draudimo</strong> arba <strong>Ekspeditoriaus draudimo</strong> dokumentas",
        questionnaire: "Klausimynas",
        yourAnswersAreImportant:
          "Jūsų atsakymai svarbūs tolesniam bendradarbiavimui.",
        yes: "Taip",
        no: "Ne",
        productSafetyManagement:
          "Ar turite sertifikatą, įrodantį jūsų patirtį gaminių saugos valdymo srityje (ISO22001/BRC/IFS)?",
        foodSafetyPolicy:
          "Ar turite maisto ir (arba) produktų saugos politiką?",
        haccpPlan: "Ar turite HACCP planą?",
        chemicalsInCleansing:
          "Ar sekate, kokios cheminės medžiagos naudojamos valymo procesuose, kurie susiję su produktų sauga?",
        pestControlSystem: "Ar turite apsaugos nuo kenkėjų sistemą?",
        cleaningSchedule:
          "Ar turite priekabų ir patalpų valymo grafiką ir tvarką?",
        proceduresForOperation:
          "Ar turite visų transporto priemonių ir įrangos eksploatavimo ir techninės priežiūros procedūras?",
        expertiseSecurityManagement:
          "Ar turite sertifikatą, įrodantį jūsų patirtį saugumo valdymo srityje (TAPA TSR / ISO28001)?",
        securityPolicy: "Ar turite saugumo politiką?",
        hvttCargo:
          "Ar yra specialios procedūros, skirtos didelės vertės kroviniams bei vagiamiems, lengvai rinkoje realizuojamiems kroviniams, vežti?",
        secureVehicleParking:
          "Ar planuojant vairuotojo maršrutą yra parenkama saugi stovėjimo vieta/aikštelė?",
        routesAndStops: "Ar maršrutus ir sustojimus planuojate iš anksto?",
        driverAssessment:
          "Ar atliekate vairuotojo, vežančio didelės vertės krovinius, praeities, biografijos patikrinimą?",
        addIsoToCompanyDocuments:
          "Pridėkite ISO22001/ BRC/ IFS dokumentą prie “Kompanijos dokumentai” skilties.",
        addTapaToCompanyDocuments:
          "Pridėkite TAPA TSR/ ISO28001 dokumentą prie “Kompanijos dokumentai” skilties.",
        isoDocument: "ISO22001/ BRC/ IFS",
        tapaDocument: "TAPA TSR/ ISO28001",
        asOurValuedSupllier:
          'Kadangi esate svarbus mūsų tiekėjas ir "Everwest" tiekimo grandinės dalis, maloniai prašome Jūsų pateikti mums naujausią savo įmonės sertifikavimo informaciją. Tai padeda mums užtikrinti paslaugų kokybę, laikytis teisės aktų ir klientų reikalavimų. Prašome užpildyti toliau pateiktą trumpą klausimyną ir pridėti reikiamus dokumentus.',
        needYourCertificates: "Reikia Jūsų sertifikatų",
        formSubmitted: "Forma sėkmingai pateikta.",
      },
      applicationList: {
        applications: "Paraiškos",
        edit: "Redaguoti",
        delete: "Ištrinti",
        review: "Peržiūrėti",
        deleteApplication: "Ištrinti paraišką?",
        vatPayerCode: "PVM mokėtojo kodas",
        companyName: "Įmonės pavadinimas",
        type: "Tipas",
        status: "Būsena",
        submittedAt: "Pateikta",
        actions: "Veiksmai",
        errorWhileGettingApplications: "Gaunant paraiškas įvyko klaida.",
        applicationDeleted: "Paraiška ištrinta.",
        errorWhileDeletingApplication: "Ištrinant paraišką įvyko klaida.",
      },
      applicationCreateButtons: {
        newGirtekaPartner: "Naujas Partneris",
        chooseOptionIfNotPartnerYet:
          "Pasirinkite šią parinktį, jei dar nesate partneris.",
        existingGirtekaPartner: "Esamas Partneris",
        chooseOptionIfNotRegistered:
          "Pasirinkite šią parinktį, jei jau esate partneris.",
      },
      projectStatusConfirmModal: {
        confirmNewPosition: "Patvirtinti naują poziciją",
        confirmPlannedPosition: "Patvirtinti planuojamą poziciją",
        positionStatus: "Pozicijos būsena",
        chooseStatus: "Pasirinkite būseną",
        requiredField: "Privalomas laukas.",
        statusDate: "Būsenos data",
        comment: "Pastaba",
        cancel: "Atšaukti",
        confirm: "Patvirtinti",
        time: "Laikas",
        projectStatusConfirmed: "Projekto būsena patvirtinta.",
        errorWhileConfirmingStatus: "Tvirtinant būseną įvyko klaida.",
        dateMustBeBeforeTomorrow: "Data turi būti ne vėlesnė negu rytojus.",
        dateCannotBeSevenDaysEarlierThanNow:
          "Data negali būti 7 dienomis ankstesnė nei dabar.",
        thereIsNoConfirmedLocationAtLoadedPlace:
          "Nėra patvirtinta pakrovimo vieta.",
        cargoLocationTime: "krovinio buvimo vietos laikas",
        otherCargoStatesCanBeMarkedOnly:
          "Kitos krovinio būsenos gali būti pažymėtos tik esant pakrovimo vietai.",
      },
      projectMarkerPanel: {
        time: "Laikas:",
        type: "Tipas:",
        latitude: "Platuma:",
        longtitude: "Ilguma:",
        confirmedBy: "Patvirtino:",
        confirmedAt: "Patvirtinta:",
        comment: "Pastaba:",
        clickToSeeMoreDetails:
          "Spustelėkite, jei norite pamatyti daugiau informacijos...",
        newAddress: "Naujas adresas",
        confirm: "Patvirtinti",
        start: "Pradžia",
        end: "Pabaiga",
      },
      notificationSettings: {
        notificationSettings: "Pašto nustatymai",
        notifyMeWhen: "Praneškite man, kai:",
        sendEmail: "Siųsti el. paštu:",
        save: "Išsaugoti",
        errorWhileGettingSettings: "Klaida gaunant pranešimų nustatymus.",
        settingsUpdated: "Nustatymai atnaujinti.",
        errorWhileUpdatingSettings: "Klaida naujinant pranešimų nustatymus.",
        instantly: "Akimirksniu",
        periodically: "Kasdien (17:00 Vilnius/Europa)",
        projectCreated: "Projektas sukurtas",
        projectUpdated: "Projektas atnaujintas",
        documentAccepted: "Dokumentas priimtas",
        documentRejected: "Dokumentas atmestas",
      },
      newDocumentModal: {
        uploadDocument: "Įkelti dokumentą",
        group: "Grupė",
        chooseGroup: "Pasirinkite grupę",
        requiredField: "Privalomas laukas.",
        type: "Tipas",
        chooseType: "Pasirinkite tipą",
        currency: "Valiuta",
        comment: "Pastaba",
        cancel: "Atšaukti",
        upload: "Įkelti",
        documentUploaded: "Dokumentas įkeltas.",
        errorWhileUploadingDocument: "Įkeliant dokumentą įvyko klaida.",
        fieldMustContainValidValues: "Lauke turi būti galiojančios reikšmės.",
        youWillNotBeAbleToDeleteThisFileLater:
          "Vėliau šio failo ištrinti negalėsite.",
        invalidPattern: "Leidžiami simboliai: 0-9, '-', '/'.",
      },
      companySelectModal: {
        selectCompany: "Pasirinkti įmonę",
        errorWhileChangingCompany: "Keičiant įmonę įvyko klaida.",
        loadMore: "Įkelti daugiau",
      },
      languageSelectModal: {
        selectLanguage: "Pasirinkti kalbą",
        errorWhileLoadingTranslations: "Įkeliant vertimus įvyko klaida.",
      },
      projects: {
        projects: "Užsakymai",
        project: "Projektas",
        loadingInfo: "Pakrovimo informacija",
        unloadingInfo: "Iškrovimo informacija",
        truckTrailer: "Sunkvežimio priekaba",
        loadType: "Krovinio tipas",
        price: "Kaina",
        freight: "Frachtas",
        extra: "Papildoma",
        errorWhileGettingProjects: "Gaunant projektus įvyko klaida.",
        errorWhileGettingProject: "Gaunant projektą įvyko klaida.",
        refNo: "Ref. No.",
        pallets: "padėklai",
        byCMR: "(pagal CMR)",
        order: "Užsakymas",
        createdAt: "Sukurta",
        orderStatus: "Užsakymo būsena",
        locationStatus: "Vietos būsena",
        notStarted: "Nepradėta",
        noData: "Duomenų nėra",
        myAssignedOrders: "Mano paskirti",
        archivedOrders: "Archyvuoti",
        incompleteOrders: "Nebaigti",
        date: "Data",
        archive: "Archyvas",
        unarchive: "Išarchyvuoti",
        archived: "Archyvuota",
        details: "Išsami informacija",
        other: "Kita",
        projectArchived: "Projektas archyvuotas.",
        projectUnarchived: "Projektas išarchyvuotas.",
        errorWhileArchivingProject: "Archyvuojant projektą įvyko klaida.",
        errorWhileUnarchivingProject: "Išarchyvuojant projektą įvyko klaida.",
        archiveProject: "Archyvuoti projektą?",
        unarchiveProject: "Išarchyvuoti projektą?",
        willBeChanged: "Palečių keitimas",
        value: "Vertė",
        waitingForApproval: "Laukiama patvirtinimo",
        confirmed: "Patvirtinta",
        pending: "Laukiama",
        rejected: "Atmesta",
        loadMore: "Įkelti daugiau",
        orderRenewed: "Užsakymas atnaujintas",
        trailerType: "Priekabos tipas",
        acceptedBy: "Patvirtino",
        rejectedBy: "Atmetė",
        youWillBeAbleToSeeArchived:
          'Suarchyvuotus užsakymus galėsite matyti filtruodami "Archyvuoti"',
        completed: "Įvykdyti",
        documentStatus: "Dokumentų statusai",
        unkownDocumentType: "Nežinomas dokumento tipas",
        uploaded: "Įkelta",
        payment: "Mokėjimas",
        noDocumentsUploaded: "Nėra įkeltų dokumentų",
        projectDocuments: "Projekto dokumentai",
        mode: "Režimas",
        range: "Rėžiai",
        quickPayment: "Greitasis mokėjimas",
        quickPaymentTooltip:
          "Greitasis mokėjimas galimas vieną kartą ir tik tada, kai priimama sąskaita faktūra ir CMR.",
        quickPaymentTooltipSubmitted:
          "Kreditinė sąskaita faktūra jau pateikta.",
        quickPaymentTooltipUploadOrGenerate:
          "Įkelti arba generuoti kreditinę sąskaitą faktūrą.",
        paymentAfterInvoice:
          "Kaina buvo perskaičiuota priėmus kreditinę sąskaitą.",
        highValueIncreasedSecurity:
          "PREVENCIJA NUO VAGYSTĖS / PADIDINTAS SAUGUMAS",
        customer: "Užsakovas",
      },
      projectDocumentPreview: {
        invoice: "Sąskaita faktūra",
        message: "Žinutė",
        uploaded: "Įkelta",
        paymentUntil: "Mokėjimas iki",
        comment: "Pastaba",
        download: "Atsisiųsti",
        delete: "Ištrinti",
        documentDeleted: "Dokumentas ištrintas.",
        errorWhileDeletingProject: "Ištrinant projektą įvyko klaida.",
        order: "Užsakymas",
        documentRemoved:
          "Dokumentas buvo pašalintas. Jei vis tiek jo reikia, susisiekite su savo vadybininku.",
      },
      projectPanel: {
        orderDetails: "UŽSAKYMO INFORMACIJA",
        route: "Maršrutas:",
      },
      detailsPanel: {
        otherDetails: "Kita informacija",
        responsibilities: "Atsakomybės",
        documents: "Dokumentai",
        changeLog: "Pakeitimų žurnalas",
      },
      documentTab: {
        deleteDocument: "Ištrinti dokumentą „{document}“?",
      },
      documentAlert: {
        allDocumentsAreGood: "Visi dokumentai yra geri.",
        pleaseNoticeRejectedPendingDocuments:
          "Atkreipkite dėmesį, kad turite atmestų ir (arba) laukiamų dokumentų.",
        missingCmrInvoice:
          "Trūksta CMR ir (arba) sąskaitos faktūros: Transporto paslaugų dokumentai.",
      },
      documentItem: {
        invoice: "Sąskaita faktūra",
        uploaded: "Įkelta",
        payment: "Mokėjimas",
        order: "Užsakymas",
        waitingForOriginal: "Laukiamia originalaus dokumento.",
      },
      uploadDocumentModal: {
        uploadDocument: "Įkelti dokumentą",
      },
      projectMap: {
        somethingWentWrongWhileLoading: "Įkeliant žemėlapį įvyko klaida.",
      },
      routeToggle: {
        planned: "Suplanuota",
        confirmed: "Patvirtinta",
      },
      documents: {
        documents: "Dokumentai",
        project: "Projektas",
        group: "Grupė",
        type: "Tipas",
        attributes: "Požymiai",
        comment: "Pastaba",
        status: "Būsena",
        statusNote: "Būsenos pastaba",
        createdBy: "Sukūrė",
        deletedBy: "Ištrynė",
        download: "Atsisiųsti",
        myDocuments: "Tik mano dokumentai",
        showDeleted: "Įtraukti ištrintus",
        allStatuses: "Visos būsenos",
        fileDownloadFailed:
          "Dokumentas buvo pašalintas. Jei vis tiek jo reikia, susisiekite su savo vadybininku.",
        errorWhileGettingDocuments: "Gaunant dokumentus įvyko klaida.",
        review: "Peržiūrėti",
        actions: "Veiksmai",
        deleteDocument: "Ištrinti dokumentą „{document}“?",
        documentStatus: "Dokumento statusas",
        documentType: "Dokumento tipas",
      },
      pdfReader: {
        filePreviewFailed:
          "Dokumentas buvo pašalintas. Jei vis tiek jo reikia, susisiekite su savo vadybininku.",
        filePreviewNotSupported: "Šioje naršyklėje failų peržiūra nepalaikoma.",
      },
      settings: {
        errorWhileDownloadingSettings:
          "Parsisiunčiant nustatymus įvyko klaida.",
      },
      noData: {
        noData: "Duomenų nėra",
      },
      formInfo: {
        noData: "Duomenų nėra",
      },
      footer: {
        copyRightGirtekaLogistics: "© {year} „Everwest“",
        termsAndConditions: "Sąlygos",
        privacyPolicy: "Privatumo politika",
        marketingPolicy: "Marketingo politika",
      },
      termsPolicyModal: {
        termsAndConditions: "Sąlygos",
        agree: "Sutikti",
        close: "Uždaryti",
        iHaveReadAndAgreeGirtekaLogistics:
          "Aš perskaičiau ir sutinku su „Everwest“",
        privacyPolicy: "Privatumo politika",
        iHaveReadAndAgreeToThe: "Aš perskaičiau ir sutinku su",
      },
      privacyPolicyModal: {
        privacyPolicy: "Privatumo politika",
        close: "Uždaryti",
      },
      marketingPolicyModal: {
        marketingPolicy: "Marketingo politika",
        iAgreeProcessing:
          "Sutinku, kad „Everwest“ grupės įmonės tvarkytų mano kontaktinius duomenis tiesioginės rinkodaros tikslu pagal tiesioginės rinkodaros politiką.",
        submit: "Pateikti",
        close: "Uždaryti",
      },
      orderStatus: {
        draft:
          "Užsakymas dar neišsiųstas vadybininkui, laukiame jūsų patvirtinimo.",
        pending: "Laukiama patvirtinimo iš vadybininko.",
        cancelled: "Jūs patys atšaukėte užsakymą.",
        rejected: "Vadybininkas atmetė.",
        confirmed:
          "Užsakymas sėkmingai patvirtintas ir planas bus parengtas netrukus.",
        planned:
          "Jūsų užsakymas priskirtas vežėjui ir sunkvežimiui / priekabai.",
        active: "Jūsų krovinys yra kelyje.",
        delivered: "Visi kroviniai buvo iškrauti visose iškrovimo vietose.",
        completed:
          "Visi kroviniai iškrauti visose iškrovimo vietose, CMR dokumentas pridėtas.",
      },
      carrierWizard: {
        carrierPortalUpdated: "Vežėjų portalas yra atnaujintas.",
        whatNeedToKnow: "Ką reikia žinoti?",
        next: "Kitas",
        close: "Uždaryti",
        intro: "Įvadas",
        projects: "Projektai",
        documents: "Dokumentai",
        users: "Naudotojai",
        applications: "Paraiškos",
      },
      introPageWizard: {
        weHaveUpdatedDesign:
          "Atnaujinome dizainą ir optimizavome tam tikras funkcijas, kad mūsų sistema būtų patogi klientams.",
        pleaseReviewWhatChanged: "Peržiūrėkite, kas buvo pakeista.",
      },
      projectPageWizard: {
        dashboardWhatAbleToSee: "Užsakymų puslapis. Ką aš galiu matyti?",
        onTheOrdersPage:
          "Užsakymų puslapyje Jūs matysite visą detalią informaciją apie Jums pateiktus krovinių gabenimo užsakymus.",
        statusOfYourOrders: "Užsakymo statusas.",
        hoverOverOrderRow:
          "Užveskite pelės žymeklį ant užsakymo eilutės ir paspaudę pamatysite išsamesnę informaciją apie užsakymą.",
        statusOfYourAssignedOrders: "Užsakymo statusas.",
        documentsRelatedToOrder:
          "Su užsakymu susijusius dokumentus (dokumento būsena, įkeltas, priimtas, apmokėjimo datą).",
        managerWhoAccepted: "Vadybininkas, kuris priėmė užsakymą.",
        dateYouReceivedTheOrder: "Užsakymo pateikimo datą.",
        filters: "Filtrus, kurie leis lengvai valdyti užsakymus.",
        dashboardWhatActions:
          "Užsakymų puslapis. Kokius veiksmus galiu atlikti?",
        easilySwitchToAnotherCompany:
          "Jei vežėjo portale turite priskirtą daugiau nei vieną įmonę, tai galite lengvai valdyti pereidami prie kitos įmonės užsakymų.",
        searchForOrders: "Ieškokite užsakymų pagal norimą raktinį žodį.",
        filterOrderByStatus: "Filtruokite užsakymus pagal užsakymo būsenas.",
        filterBySpecificTime:
          "Filtruokite pagal konkretų laikotarpį, kad sužinotumėte, kiek užsakymų gavote.",
        filterStillMissingOrders:
          "Šis filtras skirtas filtruoti užsakymus, prie kurių nėra pridėtų dokumentų arba kuriuos atmetė buhalterija (pvz., reikia daugiau informacijos).",
        filterOnlyAssignedOrders: "Filtruokite tik savo priskirtus užsakymus.",
        filterArchivedOrders: "Filtruokite tik savo priskirtus užsakymus.",
        clickOrderFindDetails:
          "Paspauskite ant užsakymo ir sužinokite išsamią informaciją.",
        unconfirmedOrderView:
          "Nepatvirtintas užsakymo vaizdas. Kokius veiksmus galiu atlikti?",
        assignManagerForOrder:
          "Nepatvirtintas užsakymo vaizdas. Kokius veiksmus galiu atlikti?",
        clickSeeLocationTypeComment:
          "Nepatvirtintas užsakymo vaizdas. Kokius veiksmus galiu atlikti?",
        enterTruckDriverInfo: "Įveskite sunkvežimio vairuotojo informaciją.",
        readAdditionalInfo:
          "Paspauskite ir perskaitykite papildomą informaciją.",
        readGeneralRules:
          "Paspauskite ir perskaitykite bendrąsias vežėjams skirtas taisykles.",
        acceptRejectOrder: "Patvirtinkite arba atmeskite užsakymą.",
        confirmedOrderView:
          "Patvirtintas užsakymo vaizdas. Kokius veiksmus galiu atlikti?",
        readAllDetails:
          "Patvirtintas užsakymo vaizdas. Kokius veiksmus galiu atlikti?",
        seeAllPeople:
          "Pamatykite visus atsakingus asmenis, atsakingus už krovinio pristatymą.",
        enterOrChangeTruckDriver:
          "Įveskite arba pakeiskite sunkvežimio vairuotojo informaciją.",
        changeManager: "Pakeiskite savo užsakymo vadybininką.",
        girtekaManagerInfo: "Everwest vadybininko informacija.",
        saveChanges: "Išsaugokite pakeitimus.",
        newSectionDocuments: "Išsaugokite pakeitimus.",
        autoGeneratedOrders:
          "Automatiškai sugeneruotas užsakymas po patvirtinimo.",
        abilityToUploadDocument:
          "Įkelkite ir peržiūrėkite dokumentą prieš jį pateikiant mums.",
        qpServiceIsAvailable:
          "Paslauga pasiekiama, kai CMR ir INVOICE dokumentai bus įkelti ir patvirtinti.",
        qpServiceCanBeOrdered:
          "Reikalingi dokumentai yra patvirtinti ir neturi trūkumų, galima užsakyti paslaugą.",
        qpPending: "Sąskaita pateikta, laukiama buhalterijos patvirtinimo.",
        qpConfirmed:
          "Greitas mokėjimas patvirtintas ir bus atliktas numatytu terminu.",
        changelog:
          "1.	Galite patogiai ir greitai matyti pasikeitusią informaciją apie užsakymą.",
        clientCompanyFilter:
          'Galite filtruoti užsakymus pagal "Everwest" grupės įmones.',
        ableToSeeGroupOrders:
          'Galite matyti "Everwest" imonių grupės užsakymus.',
      },
      documentPageWizard: {
        documentsWhatActions: "Dokumentai. Kokius veiksmus galiu atlikti?",
        onTheDocumentsPage:
          "Dokumentų puslapyje matysite visus savo įkeltus dokumentus, kurie yra priskirti konkrečiam projektui.",
        filterDocumentsByStatus:
          "Filtruokite dokumentus pagal dokumento būseną.",
        filterDocumentsByType: "Filtruokite dokumentus pagal dokumento tipą.",
        filterOwnUploadedDocuments: "Filtruokite tik savo įkeltus dokumentus.",
        filterAllDeletedDocuments: "Filtruokite visus ištrintus dokumentus.",
        reviewDownloadDocuments: "Peržiūrėkite ir atsisiųskite dokumentus.",
      },
      userPageWizard: {
        whatAbleToSee: "Ką galiu matyti?",
        onUsersPageYouWillSee:
          "„Naudotojų“ puslapyje matysite visus naudotojus, kuriuos sukūrėte ir priskyrėte įmonei, ir išsamią informaciją apie juos.",
        whatActionsCanTake: "Kokių veiksmų galiu imtis?",
        searchUsersByDesiredKeyword:
          "Ieškoti naudotojų pagal norimą raktažodį.",
        filterUsersByManagementRoles:
          "Filtruoti naudotojus pagal naudotojų valdymo funkcijas.",
        createNewUserByEnteringEmail:
          "Sukurti naują naudotoją įvedant el. pašto adresą ir pasirinkant valdymo funkciją.",
        viewUserProfile: "Peržiūrėti naudotojo profilį.",
        changeUserManagementRole: "Pakeisti naudotojo valdymo funkciją.",
        removeUser: "Pašalinti naudotoją.",
      },
      applicationPageWizard: {
        whatAbleToSee: "Ką galiu matyti?",
        onApplicationsPageYouWillSee:
          "Puslapyje „Paraiškos“ matysite visas sukurtas įmones ir jų būsenas.",
        whatActionsCanTake: "Kokių veiksmų galiu imtis?",
        createMoreThanOneCompany:
          "Sukurti daugiau nei vieną jums priklausančią įmonę, kuri jau yra „Everwest“ partnerė.",
        createMoreThanOneNewCompany:
          "Sukurti daugiau nei vieną naują įmonę, kuri priklauso jums ir nėra „Everwest“ partnerė.",
        editOrDeleteCompany:
          "Redaguoti arba ištrinti įmonę, kurios būsena yra „Juodraštis“.",
      },
      hotspot: {
        previous: "Ankstesnis",
        next: "Kitas",
      },
      feedbackModal: {
        sendFeedback: "Siųsti atsiliepimą",
        topic: "Tema",
        idea: "Noriu pasidalyti idėja.",
        issue: "Noriu pranešti apie problemą.",
        comment: "Pastaba",
        requiredField: "Privalomas laukas.",
        close: "Uždaryti",
        send: "Siųsti",
        feedbackSent: "Atsiliepimas išsiųstas.",
        errorWhileSendingFeedback: "Siunčiant atsiliepimą įvyko klaida.",
        thisFormIsForDevelopmentPurposes:
          "Ši forma skirta svetainės kūrimo tikslais.",
      },
      maintenance: {
        weWouldLikeToInformYou:
          "Informuojame, kad <strong>{date}</strong> atnaujinsime portalą. Portalas bus neprieinamas maždaug <strong>{duration} val</strong>. Dėkojame už kantrybę.",
        errorWhileGettingMaintenanceStatus:
          "Tikrinant techninės priežiūros būseną įvyko klaida.",
      },
      addressSearchInput: {
        search: "Paieška",
      },
      otherDetailsTab: {
        specialConditions:
          "Specialiosios krovinio pakrovimo ir (arba) vežimo sąlygos:",
        specialPaymentTerm: "Mokėjimo terminas",
        standardPaymentTerm: "Standartinis mokėjimo terminas:",
        crmPaymentNote: "CMR:",
        conditionApplies:
          "PVM tarifas taikomas pagal maršrutą, Ekspediciniame Užsakyme nurodytos iškrovimo dienos oficialiu kursu.",
      },
      documentLinks: {
        generalRulesForCarriers: "BENDROSIOS VEŽĖJAMS TAIKOMOS TAISYKLĖS",
        additionalInformation: "PAPILDOMA INFORMACIJA",
        specialClientRequirements: "SPECIALŪS KLIENTO REIKALAVIMAI",
      },
      additionalInformationModal: {
        additionalInformation: "Papildoma informacija",
        close: "Uždaryti",
      },
      responsibilitiesTab: {
        truckData: "Sunkvežimio vairuotojas",
        driverFullName: "Vairuotojo vardas ir pavardė",
        driverPhone: "Vairuotojo tel. nr.",
        truckPlates: "Sunkvežimio nr.",
        trailerPlates: "Priekabos nr.",
        manager: "Vadybininkas",
        search: "Paieška",
        saveChanges: "Išsaugoti pakeitimus",
        projectUpdated: "Projektas atnaujintas.",
        errorWhileUpdatingProject: "Atnaujinant projektą įvyko klaida.",
        errorWhileGettingManagers: "Gaunant vadybininkus įvyko klaida.",
        documentWillBeGenerated: "Dokumentas bus parengtas netrukus.",
      },
      projectAlerts: {
        pleaseFillTruckData: "Užpildykite vairuotojo duomenis.",
        projectMissingStatus: "Nėra užsakymo būsenos.",
        thereAreSomeChanges:
          "Šiame užsakyme yra keletas pakeitimų. Patikrinkite informaciją ir patvirtinkite užsakymą.",
        hasMissingCrmAndInvoice:
          "Trūksta CMR ir sąskaitos faktūros: Transporto paslaugų dokumentai.",
        hasMissingCrm: "Trūksta CMR dokumento.",
        hasMissingInvoice:
          "Trūksta sąskaitos faktūros: transporto paslaugų dokumentas.",
        hasRejectedDocument: "Atkreipkite dėmesį, kad atmetėte dokumentą.",
        projectIsArchived: "Projektas yra archyvuotas.",
        projectCompleted:
          "Projektas įvykdytas. Jūs vis dar galite įkelti dokumentus.",
        projectRejected:
          "Projektas atšauktas. Jūs vis dar galite įkelti dokumentus.",
        highValueIncreasedSecurity:
          "PREVENCIJA NUO VAGYSTĖS / PADIDINTAS SAUGUMAS",
      },
      projectReviewModal: {
        orderReview: "Užsakymo peržiūra",
        orderDetails: "UŽSAKYMO INFORMACIJA",
        customer: "Klientas",
        supplier: "Tiekėjas",
        orderConditions: "Užsakymo sąlygos",
        goodsType: "Prekių tipas",
        weight: "Svoris",
        pallets: "Padėklai",
        shipmentFixation: "Krovinio tvirtinimas",
        carriageValue: "Krovinio vertė",
        trailerType: "Priekabos tipas",
        temperature: "Temperatūra",
        refNumber: "Referencinis numeris",
        other: "Kita",
        locationType: "Lokacija",
        date: "Data",
        locationName: "Vietos pavadinimas",
        address: "Adresas",
        coordinates: "Koordinatės",
        comments: "Pastabos",
        byCMR: "(pagal CMR)",
        willBeChanged: "Palečių keitimas",
        search: "Paieška",
        specialConditions:
          "Specialiosios krovinio pakrovimo ir (arba) vežimo sąlygos:",
        paymentConditions: "Mokėjimo sąlygos",
        freight: "Frachtas",
        specialPaymentTerm: "Mokėjimo terminas",
        standardPaymentTerm: "Standartinis mokėjimo terminas:",
        crmPaymentNote: "CMR:",
        truckData: "Vilkiko vairuotojas",
        driverFullName: "Vairuotojo vardas ir pavardė",
        driverPhone: "Vairuotojo tel. nr.",
        truckPlates: "Vilkiko nr.",
        trailerPlates: "Priekabos nr.",
        rejectOrder: "Atmesti užsakymą",
        acceptOrder: "Priimti užsakymą",
        projectAccepted: "Užsakymas priimtas.",
        projectRejected: "Užsakymas atmestas.",
        close: "Uždaryti",
        submitChanges: "Pateikti pakeitimus",
        managerNotAssignedYet: "Vadybininkas nėra dar priskirtas",
        route: "Maršrutas:",
        conditionApplies:
          "PVM tarifas taikomas pagal maršrutą, Ekspediciniame Užsakyme nurodytos iškrovimo dienos oficialiu kursu.",
        mode: "Režimas",
        range: "Rėžiai",
        clientOrderNumber: "Kliento užsakymo numeris",
        changeLog: "Pakeitimų žurnalas",
        warning: "Įspėjimas",
        doYouReallyWantReject: "Ar tikrai norite atmesti šį užsakymą?",
        doYouReallyWantAccept: "Ar tikrai norite patvirtinti šį užsakymą?",
        iWouldLikeToReceiveFerryOffer: "Norėčiau gauti kelto pasiūlymą.",
      },
      specialRequirementsModal: {
        specialCarrierRequirements: "Specialūs vežėjo reikalavimai",
        close: "Uždaryti",
      },
      documentsTab: {
        erroWhileDownloadingDocuments:
          "Parsisiunčiant dokumentus įvyko klaida.",
        uploadDocument: "Įkelti dokumentą",
        type: "Tipas",
        status: "Būsena",
        uploaded: "Įkelta",
        payment: "Mokėjimas",
        order: "Užsakymas",
      },
      refreshModal: {
        somethingHasChanged: "Kažkas atsinaujino portale",
        reloadPage: "Perkraukite puslapį",
        pleaseTryLoginAgain: "Prisijunkite dar kartą",
      },
      roleAlert: {
        yourAccountHasManagerRole:
          "Jūsų paskyra apribota stebėtojo teisėmis (vadybininko rolė). Jūs galite matyti tik jums priskirtus užsakymus.",
        yourAccountHasGroupLeaderRole:
          "Jūsų paskyra apribota stebėtojo teisėmis (vadybininko, grupės lyderio rolė). Jūs galite matyti tik savo grupės užsakymus.",
        yourAccountHasViewerRole:
          "Jūsų paskyra apribota stebėtojo teisėmis {company} kompanijos kontekste.",
        yourAccountHasEditorRole:
          "Jūsų paskyra apribota redaktoriaus teisėmis {company} kompanijos kontekste. Jūs negalite valdyti vartotojų.",
      },
      listFilters: {
        filters: "Filtrai",
        close: "Uždaryti",
      },
      quickPayModal: {
        quickPayment: "Greitasis mokėjimas",
        discount: "Nuolaida",
        number: "Nr.",
        order: "Užsakymas",
        freight: " Kaina",
        paymentUntil: "Mokėjimas iki",
        newPaymentDay: "Nauja mokėjimo diena",
        newFreight: "Nauja kaina",
        paymentWillBeDone: "*Mokėjimas bus atliktas iki",
        ifPaymentDaySaturday:
          "Jei greitojo mokėjimo diena yra šeštadienis - mokėjimas atliekamas viena diena anksčiau, penktadieni.",
        ifPaymentDaySunday:
          "Jei greitojo mokėjimo diena yra sekmadienis - mokėjimas atliekamas viena diena vėliau, pirmadieni.",
        ifPaymentDayHoliday:
          "Jei greitasis mokėjimas sutampa su Lietuvos Respublikoje esančia nedarbo diena - mokėjimas atliekamas kitą darbo dieną.",
        weekendAndWorkdaysNotIncluded:
          "savaitgaliai ir darbo dienos neįskaičiuotos",
        invoiceWillBeSentToEmail:
          'Sąskaita bus atsiųsta el.paštu. Taip pat ją bus galima rasti skiltyje "Dokumentai".',
        close: "Uždaryti",
        nextStep: "Kitas žingsnis",
        invoiceWillBeGenerated:
          "Netrukus bus sugeneruota sąskaita. Patikrinkite dokumentų skyrių.",
        generateInvoice: "Pateikite kredito sąskaitą faktūrą",
        youCanUploadOrGenerateInvoice:
          "Galite įkelti savo sąskaitą faktūrą arba pasirinkti mūsų sąskaitos šabloną.",
        uploadMy: "Įkelti",
        previewInvoice: "Peržiūrėti prieš generuojant",
        creditInvoice: "KREDITINĖ SĄSKAITA FAKTŪRA",
        invoiceSerialNumber: "Serija:",
        invoiceNumber: "Nr.:",
        supplier: "TIEKĖJAS",
        customer: "PIRKĖJAS",
        name: "Įmonė",
        companyCode: "Įm. kodas",
        vatCode: "PVM mok. kodas",
        address: "Adresas",
        bankName: "Banko pavadinimas",
        bankAccountNumber: "Banko sąskaitos numeris",
        swift: "SWIFT",
        iban: "IBAN",
        nameOfService: "Paslaugos pavadinimas",
        unit: "Mato vnt.",
        transportation: "Reisas",
        amount: "Kiekis",
        vat: "PVM",
        price: "Kaina",
        suma: "Suma",
        total: "Iš viso",
        generalSum: "Bendra suma",
        transportServiceTax:
          "Vežimo paslaugos apmokestinamos 0% PVM tarifu pagal LR PVM įstatymo 45 str. 1 dalį (reikia įtraukti šitą dalį, kaip PVM tarifas 0 proc.)",
        foreignSupplierInvoice:
          "Remiantis 2006 m. lapkričio 28 d. Tarybos direktyvos 06/112 / EB 44 ir 196 straipsniais, paslaugos gavėjui bus taikomas atvirkštinis apmokestinimas paslaugos gavimo šalyje.",
        generate: "Generuoti",
        dataIsMissing: "Trūksta duomenų",
        invoiceUploaded: "Įkelta sąskaita faktūra.",
        uploadInvoice: "Įkelti sąskaitą faktūrą",
        upload: "įkelti",
        date: "Data:",
        iAgreeWithLatestAdditionalInfoPolicy:
          "Sutinku su naujausiomis “Specialiosios ir bendrosios užsakymų persiuntimo sąlygomis”",
        ifYouChooseUploadInvoice:
          "Jei pasiriksite “įkelti sąskaitą” Prašome atkreipti dėmesį, kad sąskaita turi būti išrašyta <b>:companyName</b> įmonei.",
      },
      projectChangeLog: {
        loadType: "Krovinio tipas",
        trailerType: "Priekabos tipas",
        temperature: "Temperatura",
        refNo: "Ref. No.",
        status: "Būsena",
        country: "Šalis",
        city: "Miestas",
        postalCode: "Pašto kodas",
        latitude: "Platuma",
        longtitude: "Ilguma",
        refNumber: "Referencinis numeris",
        addressName: "Adresas",
        addressLine: "Adreso eilutė",
        region: "Regionas",
        start: "Pradžia",
        end: "Pabaiga",
        locationName: "Vietos pavadinimas",
        totalPrice: "Galutinė kaina",
        freightPrice: "Frachtas",
        extraPrice: "Papildoma kaina",
        loadMore: "Įkelti daugiau",
        alsoPleaseCheck:
          "Taip pat patikrinkite informaciją apie krovinio judėjimą.",
      },
      projectChangeLogModal: {
        changeLog: "Pakeitimų žurnalas",
        close: "Uždaryti",
      },
      updateBar: {
        appVersionUpdated:
          "Atnaujinta svetainės versija. Iš naujo įkelkite puslapį, kad pamatytumėte pakeitimus.",
        reload: "Perkrauti.",
      },
      passwordRules: {
        minCharsRequired: "Būtini mažiausiai 8 simboliai.",
        oneUpperCaseRequired: "Būtina bent 1 didžioji raidė.",
        oneLowerCaseRequired: "Būtina bent 1 mažoji raidė.",
        oneNumberRequired: "Būtinas bent 1 skaičius.",
        dictionaryWord: "Negali būti žodyno žodis.",
        repetetiveChars: "Negali turėti pasikartojančių simbolių.",
        sequentialChars: "Negali būti nuoseklių simbolių.",
        mustMatch: "Slaptažodžiai turi sutapti.",
      },
      policies: {
        privacy_policy: "Privatumo politika",
        terms_policy: "Sąlygos",
        marketing_policy: "Marketingo politika",
        additional_information: "Papildoma informacija",
        special_conditions: "Specialūs kliento reikalavimai",
        special_and_general_conditions:
          "Specialiosios ir bendrosios užsakymų persiuntimo sąlygos",
        procedural_rules: "Ekspedicinio užsakymo procedūrinės taisyklės",
      },
      maintenancePage: {
        applicationIsDown: "Vyksta planiniai sistemos atnaujimo darbai.",
        pleaseComeBackLater: "Pabandykite prisijungti vėliau.",
      },
      documentStatus: {
        uploaded: "Laukiama dokumento registracijos apskaitos sistemoje.",
        pending:
          "Dokumentas užregistruotas apskaitos sistemoje, laukia peržiūros.",
        rejected:
          "Dokumentas atmestas, atmetimo priežastį žiūrėti komentarų skiltyje.",
        accepted: "Dokumentas buvo patvirtintas apskaitos sistemoje.",
        generated: "Dokumentas  įkeltas skaitmeniniu būdu.",
        waiting: "Laukiama orginalių dokumentų.",
      },
    },
  },
  lng: LanguageCode.EN,
  fallbackLng: LanguageCode.EN,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

let translated: { [key: string]: string } = {};

const translateNonCompnents = () => {
  translated = {
    // Sagas
    errorWhileGettingProjects: i18n.t("projects:errorWhileGettingProjects"),
    errorWhileGettingProject: i18n.t("projects:errorWhileGettingProject"),
    projectStatusConfirmed: i18n.t(
      "projectStatusConfirmModal:projectStatusConfirmed"
    ),
    errorWhileConfirmingStatus: i18n.t(
      "projectStatusConfirmModal:errorWhileConfirmingStatus"
    ),
    documentUploaded: i18n.t("newDocumentModal:documentUploaded"),
    errorWhileUploadingDocument: i18n.t(
      "newDocumentModal:errorWhileUploadingDocument"
    ),
    documentDeleted: i18n.t("projectDocumentPreview:documentDeleted"),
    errorWhileDeletingProject: i18n.t(
      "projectDocumentPreview:errorWhileDeletingProject"
    ),
    errorWhileFetchingUser: i18n.t("userEdit:errorWhileFetchingUser"),
    userUpdated: i18n.t("userEdit:userUpdated"),
    errorWhileUpdatingUser: i18n.t("userEdit:errorWhileUpdatingUser"),
    userInvited: i18n.t("userInvitation:userInvited"),
    errorWhileInvitingUser: i18n.t("userInvitation:errorWhileInvitingUser"),
    tokenMissing: i18n.t("userInvitation:tokenMissing"),
    accountCreatedPleaseLogin: i18n.t(
      "userInvitation:accountCreatedPleaseLogin"
    ),
    invitationExpired: i18n.t("userInvitation:invitationExpired"),
    errorWhileCreatingAccount: i18n.t(
      "userInvitation:errorWhileCreatingAccount"
    ),
    errorWhileGettingUsers: i18n.t("userList:errorWhileGettingUsers"),
    userRemoved: i18n.t("userList:userRemoved"),
    errorWhileDeletingUsers: i18n.t("userList:errorWhileDeletingUsers"),
    fileDownloadFailed: i18n.t("documents:fileDownloadFailed"),
    errorWhileGettingDocuments: i18n.t("documents:errorWhileGettingDocuments"),
    errorWhileGettingSettings: i18n.t(
      "notificationSettings:errorWhileGettingSettings"
    ),
    errorWhileGettingNotifications: i18n.t(
      "notifications:errorWhileGettingNotifications"
    ),
    errorWhileUpdatingNotifications: i18n.t(
      "notifications:errorWhileUpdatingNotifications"
    ),
    errorWhileDeletingNotifications: i18n.t(
      "notifications:errorWhileDeletingNotifications"
    ),
    settingsUpdated: i18n.t("notificationSettings:settingsUpdated"),
    errorWhileUpdatingSettings: i18n.t(
      "notificationSettings:errorWhileUpdatingSettings"
    ),
    applicationDocumentDeleted: i18n.t("applicationFiles:documentDeleted"),
    errorWhileDeletingDocument: i18n.t(
      "applicationFiles:errorWhileDeletingDocument"
    ),
    documentsUploaded: i18n.t("applicationFiles:documentsUploaded"),
    errorWhileUploadingDocuments: i18n.t(
      "applicationFiles:errorWhileUploadingDocuments"
    ),
    allFilesSizeExceeds: i18n.t("applicationFiles:allFilesSizeExceeds"),
    documentUpdated: i18n.t("applicationFiles:documentUpdated"),
    errorWhileUpdatingDocument: i18n.t(
      "applicationFiles:errorWhileUpdatingDocument"
    ),
    errorWhileStoringApplication: i18n.t(
      "application:errorWhileStoringApplication"
    ),
    applicationUpdated: i18n.t("application:applicationUpdated"),
    errorWhileUpdatingApplication: i18n.t(
      "application:errorWhileUpdatingApplication"
    ),
    errorWhileGettingApplications: i18n.t(
      "applicationList:errorWhileGettingApplications"
    ),
    applicationDeleted: i18n.t("applicationList:applicationDeleted"),
    errorWhileDeletingApplication: i18n.t(
      "applicationList:errorWhileDeletingApplication"
    ),
    errorWhileGettingApplication: i18n.t(
      "application:errorWhileGettingApplication"
    ),
    applicationSent: i18n.t("application:applicationSent"),
    pleaseVerifyEmail: i18n.t("profile:pleaseVerifyEmail"),
    errorWhileFetchingProfile: i18n.t("profile:errorWhileFetchingProfile"),
    profileInformationUpdated: i18n.t("profileEdit:profileInformationUpdated"),
    errorWhileUpdatingProfile: i18n.t("profileEdit:errorWhileUpdatingProfile"),
    profileDeleted: i18n.t("deleteProfileModal:profileDeleted"),
    errorWhileDeletingProfile: i18n.t(
      "deleteProfileModal:errorWhileDeletingProfile"
    ),
    passwordChanged: i18n.t("changePassword:passwordChanged"),
    errorWhileChangingPassword: i18n.t(
      "changePassword:errorWhileChangingPassword"
    ),
    errorWhileChangingCompany: i18n.t(
      "companySelectModal:errorWhileChangingCompany"
    ),
    errorWhileDownloadingSettings: i18n.t(
      "settings:errorWhileDownloadingSettings"
    ),
    orderSaved: i18n.t("cargoForm:orderSaved"),
    orderCreated: i18n.t("cargoForm:orderCreated"),
    errorWhileSavingOrder: i18n.t("cargoForm:errorWhileSavingOrder"),
    errorWhileGettingOrders: i18n.t("orderList:errorWhileGettingOrders"),
    orderCancelled: i18n.t("orderList:orderCancelled"),
    errorWhileCancellingOrder: i18n.t("orderList:errorWhileCancellingOrder"),
    orderCopied: i18n.t("orderList:orderCopied"),
    errorWhileCopyingOrder: i18n.t("orderList:errorWhileCopyingOrder"),
    orderDeleted: i18n.t("orderList:orderDeleted"),
    errorWhileDeletingOrder: i18n.t("orderList:errorWhileDeletingOrder"),
    refNo: i18n.t("orderList:refNo"),
    noData: i18n.t("orderList:noData"),
    errorWhileGettingOrder: i18n.t("orderShow:errorWhileGettingOrder"),
    commentWasUpdated: i18n.t("orderShow:commentWasUpdated"),
    cargoInformationSaved: i18n.t("orderShow:cargoInformationSaved"),
    locationUpdated: i18n.t("orderShow:locationUpdated"),
    errorWhileUpdatingOrderLocation: i18n.t(
      "orderShow:errorWhileUpdatingOrderLocation"
    ),
    locationSaved: i18n.t("orderShow:locationSaved"),
    errorWhileSavingOrderLocation: i18n.t(
      "orderShow:errorWhileSavingOrderLocation"
    ),
    locationDeleted: i18n.t("orderShow:locationDeleted"),
    errorWhileDeletingOrderLocation: i18n.t(
      "orderShow:errorWhileDeletingOrderLocation"
    ),
    orderSentPleaseWaitApproval: i18n.t(
      "orderShow:orderSentPleaseWaitApproval"
    ),
    errorWhileSendingOrder: i18n.t("orderShow:errorWhileSendingOrder"),
    orderShowOrderCancelled: i18n.t("orderShow:orderCancelled"),
    orderShowErrorWhileCancellingOrder: i18n.t(
      "orderShow:errorWhileCancellingOrder"
    ),
    errorWhileDownloadingDocument: i18n.t(
      "orderShow:errorWhileDownloadingDocument"
    ),
    errorWhileGettingAddresses: i18n.t(
      "addressInput:errorWhileGettingAddresses"
    ),
    resetLinkSent: i18n.t("forgotPassword:resetLinkSent"),
    errorWhileSendingLink: i18n.t("forgotPassword:errorWhileSendingLink"),
    passwordResetedPleaseLogin: i18n.t(
      "resetPassword:passwordResetedPleaseLogin"
    ),
    errorWhileResetingPassword: i18n.t(
      "resetPassword:errorWhileResetingPassword"
    ),
    invalidUsernamePassword: i18n.t("login:invalidUsernamePassword"),
    registrationSuccessful: i18n.t("registration:registrationSuccessful"),
    errorWhileRegistering: i18n.t("registration:errorWhileRegistering"),
    verificationSent: i18n.t("registration:verificationSent"),
    errorWhileResendingVerification: i18n.t(
      "registration:errorWhileResendingVerification"
    ),
    emailVerified: i18n.t("registration:emailVerified"),
    errorWhileVerifyingEmail: i18n.t("registration:errorWhileVerifyingEmail"),
    errorWhileLoadingTranslations: i18n.t(
      "languageSelectModal:errorWhileLoadingTranslations"
    ),

    // Sagas > Backend
    dateMustBeBeforeTomorrow: i18n.t(
      "projectStatusConfirmModal:dateMustBeBeforeTomorrow"
    ),
    dateCannotBeSevenDaysEarlierThanNow: i18n.t(
      "projectStatusConfirmModal:dateCannotBeSevenDaysEarlierThanNow"
    ),
    thereIsNoConfirmedLocationAtLoadedPlace: i18n.t(
      "projectStatusConfirmModal:thereIsNoConfirmedLocationAtLoadedPlace"
    ),
    fieldMustContainValidValues: i18n.t(
      "newDocumentModal:fieldMustContainValidValues"
    ),
    errorWhileAcceptingPolicy: i18n.t("profile:errorWhileAcceptingPolicy"),
    feedbackSent: i18n.t("feedbackModal:feedbackSent"),
    errorWhileSendingFeedback: i18n.t(
      "feedbackModal:errorWhileSendingFeedback"
    ),
    errorWhileGettingMaintenanceStatus: i18n.t(
      "maintenance:errorWhileGettingMaintenanceStatus"
    ),
    formSubmitted: i18n.t("application:formSubmitted"),

    // Other
    coordinatesRequired: i18n.t("locationsTable:coordinatesRequired"),
    streetAddressRequired: i18n.t("locationsTable:streetAddressRequired"),
    requiredField: i18n.t("locationsTable:requiredField"),
    projectArchived: i18n.t("projects:projectArchived"),
    projectUnarchived: i18n.t("projects:projectUnarchived"),
    errorWhileArchivingProject: i18n.t("projects:errorWhileArchivingProject"),
    errorWhileUnarchivingProject: i18n.t(
      "projects:errorWhileUnarchivingProject"
    ),
    projectUpdated: i18n.t("responsibilitiesTab:projectUpdated"),
    documentWillBeGenerated: i18n.t(
      "responsibilitiesTab:documentWillBeGenerated"
    ),
    projectAccepted: i18n.t("projectReviewModal:projectAccepted"),
    projectRejected: i18n.t("projectReviewModal:projectRejected"),
    errorWhileUpdatingProject: i18n.t(
      "responsibilitiesTab:errorWhileUpdatingProject"
    ),
    errorWhileGettingManagers: i18n.t(
      "responsibilitiesTab:errorWhileGettingManagers"
    ),
    erroWhileDownloadingDocuments: i18n.t(
      "documentsTab:erroWhileDownloadingDocuments"
    ),
    invoiceWillBeGenerated: i18n.t("quickPayModal:invoiceWillBeGenerated"),
    invoiceUploaded: i18n.t("quickPayModal:invoiceUploaded"),
  };
};

translateNonCompnents();

i18n.on("languageChanged init", () => {
  translateNonCompnents();
});

// Register datepicker locales
registerLocale("ru", ru);
registerLocale("pl", pl);
registerLocale("lt", lt);

export { translated };

export default i18n;
