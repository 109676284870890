import { ApiResource, API, HTTPMethod } from ".";
import { INotificationSettingsForm } from "../../components/notification-settings/NotificationSettings";

export enum NotificationInterval {
  Instantly = "instantly",
  Periodically = "periodically",
}

export interface INotificationSettings {
  types: { [type: string]: boolean };
  interval: NotificationInterval;
  updatedAt: string;
}

const fetchNotificationSettings = async (): Promise<
  ApiResource<INotificationSettings>
> => {
  const response = await API.request<ApiResource<INotificationSettings>>(
    `/carrier/notifications/settings`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const updateNotificationSettings = async (
  data: INotificationSettingsForm
): Promise<ApiResource<INotificationSettings>> => {
  const response = await API.request<ApiResource<INotificationSettings>>(
    `/carrier/notifications/settings`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const notificationSettingsAPI = {
  fetchNotificationSettings,
  updateNotificationSettings,
};

export { notificationSettingsAPI };
