import { FC, useCallback, useEffect } from "react";
import { Row, Container, Form, Col, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { email as emailRegex } from "../../../constants/regex";
import { userInvite } from "./store/slices";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { Cover } from "../../cover/Cover";
import { LanguageSwitcher } from "../../translations/LanguageSwitcher";
import PasswordRules from "../../password-rules/PasswordRules";
import { policies } from "../../policies/store/slices";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";

export interface IUserInvitationData {
  email: string;
  name: string;
  surname: string;
  phone: string;
  password: string;
  password_confirmation: string;
  token: string;
  marketing_consent: boolean;
  // privacy_consent: boolean;
  terms_consent: boolean;
}

const Invitation: FC = () => {
  const { t } = useTranslation("invitation");

  const isLoading = useSelector((state) => state.loader.isLoading);
  const invited = useSelector((state) => state.userInvite.invited);

  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { email } = queryString.parse(location.search);

  const acceptInvitation = useCallback(
    (data: IUserInvitationData) =>
      dispatch(userInvite.actions.acceptInvitation(data)),
    [dispatch]
  );

  // const togglePrivacyPolicy = useCallback(
  //   () => dispatch(policies.actions.togglePrivacyPolicy()),
  //   [dispatch]
  // );

  const toggleTermsConditions = useCallback(
    () => dispatch(policies.actions.toggleTermsConditions()),
    [dispatch]
  );

  const toggleMarketingPolicy = useCallback(
    () => dispatch(policies.actions.toggleMarketingPolicy()),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(userInvite.actions.reset()), [
    dispatch,
  ]);

  const { register, errors, handleSubmit, watch } = useForm<
    IUserInvitationData
  >({
    defaultValues: {
      email: email ? (email as string) : "",
    },
  });

  useEffect(() => {
    localStorage.removeItem("jwt_tokens");
    return () => {
      reset();
    };
  }, [reset]);

  useEffect(() => {
    if (invited) {
      history.push("/");
    }
  }, [history, invited]);

  const onSubmit = async (data: IUserInvitationData) => {
    acceptInvitation({
      ...data,
      token,
    });
  };

  return (
    <Container className="user-invite-page" fluid>
      <Cover />

      <Row className="language-row">
        <LanguageSwitcher />
      </Row>
      <Row className="form-row">
        <Col />
        <Col xs={12} md={6} lg={5} xl={3}>
          <Row>
            <Col className="logo-place">
              <Link to="/">
                <Image src="/images/logo.svg" />
              </Link>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} controlId="email">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  ref={register({ required: true, pattern: emailRegex })}
                  isInvalid={!!errors.email}
                  maxLength={190}
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.type === "required" &&
                    (t("requiredField") as any)}
                  {errors.email?.type === "pattern" &&
                    (t("invalidEmail") as any)}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="name">
                <Form.Label>{t("name")}</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  ref={register({ required: true })}
                  isInvalid={!!errors.name}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.type === "required" &&
                    (t("requiredField") as any)}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="surname">
                <Form.Label>{t("lastName")}</Form.Label>
                <Form.Control
                  name="surname"
                  type="text"
                  ref={register({ required: true })}
                  isInvalid={!!errors.surname}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.surname?.type === "required" &&
                    (t("requiredField") as any)}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="phone">
                <Form.Label>{t("phone")}</Form.Label>
                <Form.Control
                  name="phone"
                  type="text"
                  ref={register({ required: true, minLength: 8 })}
                  isInvalid={!!errors.phone}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone?.type === "required" &&
                    (t("requiredField") as any)}
                  {errors.phone?.type === "minLength" &&
                    (t("tooShortNumber") as any)}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="password">
                <Form.Label>{t("password")}</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  ref={register({
                    required: true,
                    minLength: 8,
                    validate: {
                      containsUpper: (value) => /[A-Z]/.test(value),
                      containsLower: (value) => /[a-z]/.test(value),
                      containsDigits: (value) => /[0-9]/.test(value),
                    },
                  })}
                  isInvalid={!!errors.password}
                  maxLength={255}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.type === "required" &&
                    (t("requiredField") as any)}
                  {errors.password?.type === "minLength" &&
                    (t("minCharsRequired") as any)}
                  {errors.password?.type === "containsUpper" &&
                    (t("oneUpperCaseRequired") as any)}
                  {errors.password?.type === "containsLower" &&
                    (t("oneLowerCaseRequired") as any)}
                  {errors.password?.type === "containsDigits" &&
                    (t("oneNumberRequired") as any)}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="password_confirmation">
                <Form.Label>{t("confirmPassword")}</Form.Label>
                <Form.Control
                  name="password_confirmation"
                  type="password"
                  ref={register({
                    required: true,
                    minLength: 8,
                    validate: (value) => value === watch("password"),
                  })}
                  isInvalid={!!errors.password_confirmation}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation?.type === "required" &&
                    (t("requiredField") as any)}
                  {errors.password_confirmation?.type === "validate" &&
                    (t("passwordsDontMatch") as any)}
                  {errors.password_confirmation?.type === "minLength" &&
                    (t("minCharsRequired") as any)}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <PasswordRules />
            </Form.Row>

            {/* <Form.Row>
              <Form.Group as={Col} controlId="privacy_consent">
                <Form.Check
                  name="privacy_consent"
                  type="checkbox"
                  ref={register({ required: true })}
                  label={
                    <>
                      {t("iHaveReadAndAgreeGirtekaLogistics")}{" "}
                      <Link onClick={togglePrivacyPolicy} to="#">
                        {t("privacyPolicy")}
                      </Link>
                    </>
                  }
                />
              </Form.Group>
            </Form.Row> */}

            <Form.Row>
              <Form.Group as={Col} controlId="terms_consent">
                <Form.Check
                  name="terms_consent"
                  type="checkbox"
                  ref={register({ required: true })}
                  label={
                    <>
                      {t("iHaveReadAndAgreeToThe")}{" "}
                      <Link onClick={toggleTermsConditions} to="#">
                        {t("termsAndConditions")}
                      </Link>
                    </>
                  }
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="marketing_consent">
                <Form.Check
                  name="marketing_consent"
                  type="checkbox"
                  ref={register}
                  label={
                    <>
                      {t("iAgreeProcessing")}{" "}
                      <Link onClick={toggleMarketingPolicy} to="#">
                        {t("directMarketing")}
                      </Link>
                    </>
                  }
                />
              </Form.Group>
            </Form.Row>

            <div className="create-button">
              <ButtonLoader
                variant="primary"
                type="submit"
                disabled={isLoading}
                buttonDisabled={
                  // !watch("privacy_consent") || !watch("terms_consent")
                  !watch("terms_consent")
                }
              >
                {t("createAccount")}
              </ButtonLoader>
            </div>
          </Form>
        </Col>
        <Col />
      </Row>
    </Container>
  );
};

export { Invitation };
