import { FC, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaExclamationCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { log } from "../../services/logger";
import { profile } from "../profile/store/slices";
import { refreshModal } from "./store/slices";

const RefreshModal: FC = () => {
  const { t } = useTranslation("refreshModal");

  const isVisible = useSelector((state) => state.refreshModal.isVisible);

  const dispatch = useDispatch();

  const logout = useCallback(() => dispatch(profile.actions.logoutProfile()), [
    dispatch,
  ]);
  const setVisibility = useCallback(
    (visibility: boolean) =>
      dispatch(refreshModal.actions.setVisibility(visibility)),
    [dispatch]
  );

  const handleRefresh = () => {
    log.warning(
      "Unauthorized resource access. Something has changed modal occured."
    );

    logout();

    setVisibility(false);

    window.location.reload();
  };

  return (
    <Modal show={isVisible} backdrop="static">
      <Modal.Header>
        <Modal.Title
          style={{
            overflowWrap: "anywhere",
          }}
        >
          <FaExclamationCircle /> {t("somethingHasChanged")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("pleaseTryLoginAgain")}</Modal.Body>
      <Modal.Footer>
        <Button
          style={{ minWidth: "130px" }}
          variant="primary"
          onClick={handleRefresh}
        >
          {t("reloadPage")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { RefreshModal };
