import { FC } from "react";
import { IRole, Role } from "../../../../../services/api/profile";
import { IUser } from "../../../../../services/api/users";

interface IProps {
  cell: any;
}

const RolesCell: FC<IProps> = ({ cell }) => {
  const user: IUser = cell.row.original;
  const roles: IRole<Role>[] = user.roles;

  return (
    <span>
      {roles && roles.length
        ? roles
            .map((role) => {
              let name = role.name;

              // If doesn't have translation
              // that mean it's department role
              if (name.includes("roles.")) {
                name = `${name.replace("roles.", "")} leader`;
              }

              return name;
            })
            .join(", ")
        : "-"}
    </span>
  );
};

export { RolesCell };
