import { createSlice } from "@reduxjs/toolkit";

export enum Workspace {
  ProfileSpace = "profileSpace",
  CarrierSpace = "carrierSpace",
  AdminSpace = "adminSpace",
  DeveloperSpace = "developerSpace",
}

export type WorkspaceSwitcherState = {
  currentWorkspace: Workspace | null;
};

const initialState: WorkspaceSwitcherState = {
  currentWorkspace: null,
};

const workspaceSwitcherSlice = createSlice({
  name: "workspaceSwitcherSlice",
  initialState,
  reducers: {
    change: (state, action) => {
      state.currentWorkspace = action.payload;
    },
    reset: () => initialState,
  },
});

export default workspaceSwitcherSlice;
