import { createSlice } from "@reduxjs/toolkit";
import queryString from "query-string";
import { IProjectListGroupState } from ".";
import { ProjectConfirmStatus } from "../../../../../services/api/projects";
import { IProjectUpdateForm } from "../../../show/details/tabs/ResponsibilitiesTab";

const generateState = (): IProjectListGroupState => {
  const params = window.location.search;

  const { perPage, page } = queryString.parse(params);

  return {
    id: ProjectConfirmStatus.Rejected,
    data: [],

    perPage: perPage && typeof perPage === "string" ? Number(perPage) : 3,
    activePage: typeof page === "string" ? Number(page) : 1,
    meta: null,

    isLoading: true,
  };
};

const rejectedProjects = createSlice({
  name: "rejectedProjects",
  initialState: generateState(),
  reducers: {
    setData: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    },
    update: (state, action) => {
      const id = action.payload.id;
      const {
        driverFullName,
        truckPlates,
        trailerPlates,
      }: IProjectUpdateForm = action.payload.data;

      const index = state.data.findIndex((project) => project.id === id);

      if (state.data[index]) {
        state.data[index] = {
          ...state.data[index],
          driverName: driverFullName,
          truckPlates,
          trailerPlates,
        };
      }
    },
    concatData: (state, action) => {
      state.data = state.data.concat(action.payload.data);
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
      state.isLoading = true;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.isLoading = true;
    },
    archive: (state, action) => {
      //
    },
    unarchive: (state, action) => {
      //
    },
    setLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    resetData: (state) => {
      state.data = [];
    },
    resetPagination: (state) => {
      state.activePage = 1;
      state.meta = null;
    },
    reset: generateState,
  },
});

export { rejectedProjects };
