import { API, ApiResource, HTTPMethod } from "..";
import { IDocument } from "../documents";

const updateDirector = async (
  directorId: number,
  firstName: string,
  lastName: string
): Promise<ApiResource<IDocument>> => {
  const response = await API.request<ApiResource<IDocument>>(
    `/admin/directors/${directorId}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({
      first_name: firstName,
      last_name: lastName,
    })
  );

  return response;
};

const adminDirectorsAPI = {
  updateDirector,
};

export { adminDirectorsAPI };
