import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { capitalize, startCase } from "lodash";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  code: string;
};

export const DocumentStatusTooltip = ({ name, code }: Props) => {
  const { t } = useTranslation("documentStatus");

  return (
    <div className="status-heading">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id={`tooltip-${name}`}>{capitalize(t(code))}</Tooltip>
        }
      >
        <span>{startCase(name)}</span>
      </OverlayTrigger>
    </div>
  );
};
