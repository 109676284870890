import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Column, TableOptions, useTable } from "react-table";
import { documents } from "../store/slices";
import { Loader } from "../../common/loaders/Loader";
import { DocumentStatus, IDocument } from "../../../services/api/documents";
import { NoData } from "../../common/no-data/NoData";
import { formatUnixDate, formatUnixTime } from "../../../utils/date-format";
import { StatusBadge } from "../StatusBadge";
import { useTranslation } from "react-i18next";
import { DocumentPreviewModal } from "../../projects/show/details/modals/DocumentPreviewModal";
import { ActionCell } from "./cells/ActionCell";
import { CarrierRoute } from "../../../constants/routes";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaInfoCircle } from "react-icons/fa";

const DocumentsTable: FC = () => {
  const { t } = useTranslation("documents");
  const dispatch = useDispatch();
  const [previewDocument, setPreviewDocument] = useState<IDocument | null>(
    null
  );
  const [previewIsVisible, setPreviewIsVisible] = useState<boolean>(false);

  const profile = useSelector((state) => state.profile);
  const data = useSelector((state) => state.documents.data);
  const isLoading = useSelector((state) => state.documents.isLoading);
  const activePage = useSelector((state) => state.documents.activePage);
  const meta = useSelector((state) => state.documents.meta);

  const setActivePage = useCallback(
    (page: number) => dispatch(documents.actions.setActivePage(page)),
    [dispatch]
  );

  const resetPagination = useCallback(
    () => dispatch(documents.actions.resetPagination()),
    [dispatch]
  );

  const fetchMore = useCallback(() => {
    if (meta && activePage < meta.total) {
      setActivePage(activePage + 1);
    }
  }, [activePage, meta, setActivePage]);

  const columns: Column<TableOptions<IDocument>>[] = useMemo(
    () => [
      {
        Header: t("project") as string,
        Cell: ({ cell }) => {
          const document = (cell.row.original as unknown) as IDocument;

          if (document.axCode) {
            const route =
              CarrierRoute.Orders.List.replace(
                ":orderId?",
                document.modelId ? document.modelId.toString() : ""
              ) + `?search=${document.axCode}`;

            return <Link to={route}>{document.axCode}</Link>;
          }

          return <span>-</span>;
        },
      },
      {
        Header: t("group") as string,
        Cell: ({ cell }) => {
          const { type } = (cell.row.original as unknown) as IDocument;

          if (type && type.group) {
            return <span>{type.group.name}</span>;
          }

          return <span>-</span>;
        },
      },
      {
        Header: t("type") as string,
        Cell: ({ cell }) => {
          const { type } = (cell.row.original as unknown) as IDocument;

          if (!type) {
            return <span>-</span>;
          }

          return <span>{type.name}</span>;
        },
      },
      {
        Header: t("attributes") as string,
        Cell: ({ cell }) => {
          const { data } = (cell.row.original as unknown) as IDocument;

          if (!data) return <span>-</span>;

          return (
            <div>
              {data.map(({ value, type }) => {
                const dataName = type.name;
                const dataCode = type.code;
                let dataValue = value;

                if (dataCode === "DATA_DOC_DATE" && typeof value === "number") {
                  dataValue = `${formatUnixDate(value)} ${formatUnixTime(
                    value
                  )}`;
                }

                if (
                  (dataCode === "DATA_DOC_AMOUNT" ||
                    dataCode === "DATA_DOC_AMNOUNT") &&
                  typeof value === "object"
                ) {
                  dataValue = `${value.amount} ${value.currency}`;
                }

                return (
                  <>
                    <span>{dataName}</span>
                    <span className="list-time">{dataValue as any}</span>
                  </>
                );
              })}
            </div>
          );
        },
      },
      {
        Header: t("comment") as string,
        accessor: "comment",
        Cell: ({ cell }) => {
          const { comment } = (cell.row.original as unknown) as IDocument;

          return comment ? <span>{comment}</span> : <span>-</span>;
        },
      },
      {
        Header: t("status") as string,
        accessor: "status",
        Cell: ({ cell }) => {
          const { status } = (cell.row.original as unknown) as IDocument;

          if (!status) {
            return <span>-</span>;
          }

          return (
            <div className="status-div">
              <StatusBadge status={status} />
              {status.code === DocumentStatus.Waiting && (
                <span className="waiting-info ">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="question-tooltip">
                        {t("waitingForOriginal", {
                          ns: "documentItem",
                        })}
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle />
                  </OverlayTrigger>
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: t("statusNote") as string,
        accessor: "statusNote",
        Cell: ({ cell }) => {
          const { axMessage } = (cell.row.original as unknown) as IDocument;

          return axMessage ? <span>{axMessage}</span> : <span>-</span>;
        },
      },
      {
        Header: t("createdBy") as string,
        accessor: "createdAt",
        Cell: ({ cell }) => {
          const { createdBy: user, createdAt } = (cell.row
            .original as unknown) as IDocument;

          if (!user && !createdAt) {
            return <span>-</span>;
          }

          return (
            <>
              <span>{user ? user.email : ""}</span>
              <span className="list-time">{`${formatUnixDate(
                createdAt
              )} ${formatUnixTime(createdAt)}`}</span>
            </>
          );
        },
      },
      {
        Header: t("actions") as string,
        accessor: "actions",
        Cell: (cell) => (
          <ActionCell
            cell={cell}
            onReviewClick={(document) => {
              setPreviewDocument(document);
              setPreviewIsVisible(true);
            }}
          />
        ),
        disableSortBy: true,
      },
    ],
    [t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<TableOptions<IDocument>>({
    columns,
    data,
    initialState: {
      pageIndex: activePage,
    },
  });

  useEffect(() => {
    return () => {
      resetPagination();
    };
  }, [resetPagination]);

  const hasMore = useMemo(
    () => !!(rows.length > 0 && meta && rows.length !== meta.total),
    [meta, rows]
  );

  const isInactive = profile.hasAccountantRole;

  return (
    <>
      <InfiniteScroll
        dataLength={rows.length}
        next={fetchMore}
        hasMore={hasMore}
        loader={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader isLoading={true} />
          </div>
        }
        style={{ overflow: "hidden" }}
      >
        <Table
          striped
          bordered
          hover
          {...getTableProps()}
          className="documents-table"
          responsive
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading && (
              <tr>
                <td colSpan={columns.length}>
                  <div className="list-loader">
                    <Loader />
                  </div>
                </td>
              </tr>
            )}
            {rows.length === 0 && !isLoading && (
              <tr>
                <td colSpan={columns.length}>
                  <NoData />
                </td>
              </tr>
            )}
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <Fragment key={index}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          style={
                            cell.column.id === "statusNote" ||
                            cell.column.id === "comment"
                              ? { maxWidth: "200px" }
                              : {}
                          }
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </InfiniteScroll>

      {previewDocument && (
        <>
          <DocumentPreviewModal
            isVisible={previewIsVisible}
            isInactive={isInactive}
            document={previewDocument}
            onClose={() => {
              setPreviewIsVisible(false);
              setPreviewDocument(null);
            }}
          />
        </>
      )}
    </>
  );
};

export { DocumentsTable };
