import { createSlice } from "@reduxjs/toolkit";
import { IDocument } from "../../../services/api/documents";

type State = {
  isVisible: boolean;
  isSubmitting: boolean;
  documents: IDocument[];
  documentsFetching: boolean;
  formSubmitted: boolean;
};

const initialState: State = {
  isVisible: false,
  isSubmitting: false,
  documents: [],
  documentsFetching: false,
  formSubmitted: false,
};

const questionnaireModalSlice = createSlice({
  name: "questionnaireModalSlice",
  initialState,
  reducers: {
    showPopup: (state, action) => {
      state.isVisible = action.payload;
    },
    submitForm: (state, action) => {
      state.isSubmitting = true;
    },
    uploadDocuments: (state, action) => {
      state.documentsFetching = true;
    },
    updateDocument: (state, action) => {
      state.documentsFetching = true;
    },
    deleteDocument: (state, action) => {
      state.documentsFetching = true;
    },
    removeDocument: (state, action) => {
      const documentId = action.payload;

      const index = state.documents.findIndex(
        (document) => document.id === Number(documentId)
      );

      if (index !== -1) {
        state.documents.splice(index, 1);
      }

      state.documentsFetching = false;
    },
    addDocuments: (state, action) => {
      state.documents = [...state.documents, ...action.payload];
      state.documentsFetching = false;
    },
    replaceDocument: (state, action) => {
      const updatedDocument: IDocument = action.payload;

      const index = state.documents.findIndex(
        (document) => document.id === Number(updatedDocument.id)
      );

      state.documents[index] = updatedDocument;

      state.documentsFetching = false;
    },
    setFormSubmitted: (state) => {
      state.formSubmitted = true;
    },
    resetDocuments: (state) => {
      state.documents = [];
      state.documentsFetching = false;
    },
    reset: () => initialState,
  },
});

export default questionnaireModalSlice;
