import { FC, useState } from "react";
import { FaFileUpload } from "react-icons/fa";
import { NewDocumentModal } from "./modals/NewDocumentModal";
import { useTranslation } from "react-i18next";

const UploadDocument: FC = () => {
  const { t } = useTranslation("uploadDocumentModal");

  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);

  return (
    <>
      <div
        className="upload-document"
        onClick={() => setUploadModalVisible(true)}
      >
        <div className="upload-document-icon">
          <FaFileUpload />
        </div>
        <span>{t("uploadDocument")}</span>
      </div>
      <NewDocumentModal
        isVisible={uploadModalVisible}
        onClose={() => setUploadModalVisible(false)}
      />
    </>
  );
};

export { UploadDocument };
