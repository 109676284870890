import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { call, fork, put, select, take, takeLatest } from "typed-redux-saga";
import { translated } from "../../../../i18n";
import { CompanyRole } from "../../../../services/api/profile";
import {
  projectAPI,
  ProjectConfirmStatus,
} from "../../../../services/api/projects";
import { usersAPI } from "../../../../services/api/users";
import { AppState } from "../../../../store";
import { adjustTimezone } from "../../../../utils/timezone";
import {
  showError,
  showInfo,
  showSuccess,
  showWarning,
} from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { projectList } from "../../list/store/slices";
import { confirmedProjects } from "../../list/store/slices/confirmed";
import { rejectedProjects } from "../../list/store/slices/rejected";
import { waitingProjects } from "../../list/store/slices/waiting";
import { INewDocumentForm } from "../details/modals/NewDocumentModal";
import { IProjectUpdateForm } from "../details/tabs/ResponsibilitiesTab";
import { IConfirmationForm } from "../map/modals/ConfirmPositionModal";
import { projectShow } from "./slices";

function* projectShowSaga() {
  yield* takeLatest(projectShow.actions.fetchProject, function* ({
    payload: id,
  }: PayloadAction<string>) {
    yield* call(fetchProject, id);
  });
  yield* fork(confirmationWatcher);
  yield* fork(uploadDocumentWatcher);
  yield* fork(updateWatcher);
  yield* fork(acceptWatcher);
  yield* fork(rejectWatcher);
  yield* fork(fetchManagersWatcher);
  yield* fork(fetchDocumentsWatcher);
}

function* confirmationWatcher() {
  while (true) {
    const {
      payload: { projectId, data },
    } = yield take(projectShow.actions.confirmStatus);

    yield* call(confirmStatus, projectId, data);
  }
}

function* uploadDocumentWatcher() {
  while (true) {
    const {
      payload: { projectId, data, document },
    } = yield take(projectShow.actions.uploadDocument);

    yield* call(uploadDocument, projectId, data, document);
  }
}

function* updateWatcher() {
  while (true) {
    const { payload: data } = yield take(projectShow.actions.updateProject);

    yield* call(updateProject, data);
  }
}

function* acceptWatcher() {
  while (true) {
    const { payload: data } = yield take(projectShow.actions.acceptProject);

    yield* call(acceptProject, data);
  }
}

function* rejectWatcher() {
  while (true) {
    yield take(projectShow.actions.rejectProject);

    yield* call(rejectProject);
  }
}

function* fetchManagersWatcher() {
  while (true) {
    yield take(projectShow.actions.fetchManagers);

    yield* call(fetchManagers);
  }
}

function* fetchDocumentsWatcher() {
  while (true) {
    yield take(projectShow.actions.fetchDocuments);

    yield* call(fetchDocuments);
  }
}

function* fetchProject(id: string) {
  const result = yield* call(projectAPI.fetchProject, id);

  if (result && result.data) {
    yield put(projectShow.actions.setData(result));
  } else {
    yield put(showError(translated.errorWhileGettingProject));
  }
}

function* fetchDocuments() {
  const project = yield* select((state: AppState) => state.projectShow.data);

  if (project) {
    const result = yield* call(projectAPI.fetchDocuments, project.id);

    if (result && result.data) {
      yield put(projectShow.actions.setDocuments(result.data));
    } else {
      yield put(showError(translated.erroWhileDownloadingDocuments));
    }
  }
}

function* updateProject(data: IProjectUpdateForm) {
  const project = yield* select((state: AppState) => state.projectShow.data);

  if (project) {
    const result = yield* call(projectAPI.updateProject, project.id, data);

    if (result && result.success) {
      yield put(projectShow.actions.setUpdated(data));

      if (project.confirmStatus) {
        const status = project.confirmStatus.code;

        if (
          status === ProjectConfirmStatus.WaitingForApproval ||
          status === ProjectConfirmStatus.ConfirmUpdate
        ) {
          yield put(waitingProjects.actions.update({ id: project.id, data }));
        }

        if (status === ProjectConfirmStatus.Confirmed) {
          yield put(confirmedProjects.actions.update({ id: project.id, data }));
        }

        if (status === ProjectConfirmStatus.Rejected) {
          yield put(rejectedProjects.actions.update({ id: project.id, data }));
        }
      }

      yield put(showSuccess(translated.projectUpdated));
      yield put(showInfo(translated.documentWillBeGenerated));
    } else {
      yield put(showError(translated.errorWhileUpdatingProject));
    }
  }
}

function* acceptProject(data: IProjectUpdateForm) {
  const project = yield* select((state: AppState) => state.projectShow.data);

  if (project) {
    const result = yield* call(projectAPI.acceptProject, project.id, data);

    if (result && result.success) {
      yield put(projectList.actions.setSearch(""));

      yield put(showSuccess(translated.projectAccepted));
    } else {
      yield put(showError(translated.errorWhileUpdatingProject));
    }
  }
}

function* rejectProject() {
  const project = yield* select((state: AppState) => state.projectShow.data);

  if (project) {
    const result = yield* call(projectAPI.rejectProject, project.id);

    if (result && result.success) {
      yield put(projectList.actions.setSearch(""));

      yield put(showSuccess(translated.projectRejected));
    } else {
      yield put(showError(translated.errorWhileUpdatingProject));
    }
  }
}

function* fetchManagers() {
  const project = yield* select((state: AppState) => state.projectShow.data);
  const settings = yield* select((state: AppState) => state.settings.carrier);

  if (project && settings) {
    const roles = settings.users.companyRoles.filter((role) =>
      [CompanyRole.Owner, CompanyRole.Manager].includes(role.code)
    );

    const result = yield* call(usersAPI.fetchList, project.company.id, {
      perPage: 100,
      roleIds: roles.map((role) => role.id).join(","),
      includeSelf: true,
    });

    if (result && result.data) {
      yield put(projectShow.actions.setManagers(result.data));
    } else {
      yield put(showError(translated.errorWhileGettingManagers));
    }
  }
}

function* confirmStatus(projectId: string, data: IConfirmationForm) {
  const { selectedLocationId } = yield* select((state: AppState) => ({
    selectedLocationId: state.projectShow.selectedLocationId,
  }));

  const result = yield* call(
    projectAPI.confirmStatus,
    projectId,
    {
      ...data,
      dateFrom: moment(adjustTimezone(data.dateFrom, "remove")).unix(),
      dateTo: moment(adjustTimezone(data.dateFrom, "remove")).unix(),
    },
    selectedLocationId
  );

  if (result && result.data) {
    yield put(showSuccess(translated.projectStatusConfirmed));

    yield put(projectShow.actions.reset());

    yield* call(fetchProject, projectId);
  } else if (result && result.errors) {
    const messages: string[] = [];

    Object.values(result.errors).forEach((error: any) => {
      let message = error[0];

      if (error[0] in translated) {
        message = translated[error[0]];
      }

      messages.push(message);
    });

    for (let index = 0; index < messages.length; index++) {
      yield put(showWarning(messages[index]));
    }
  } else {
    yield put(showError(translated.errorWhileConfirmingStatus));
  }
}

function* uploadDocument(
  projectId: string,
  data: INewDocumentForm,
  document: File
) {
  yield put(loader.actions.startLoader());

  const result = yield* call(
    projectAPI.uploadDocument,
    projectId,
    data,
    document
  );

  if (result && result.data) {
    yield put(showSuccess(translated.documentUploaded));

    yield* call(fetchProject, projectId);

    yield* put(projectShow.actions.fetchDocuments());
  } else if (result && result.errors) {
    const messages: string[] = [];

    Object.entries(result.errors).forEach(([key, error]: any) => {
      let message = `${key}: ${error[0]}`;

      if (error[0] in translated) {
        message = `${key} ${translated[error[0]]}`;
      }

      messages.push(message);
    });

    for (let index = 0; index < messages.length; index++) {
      yield put(showWarning(messages[index]));
    }
  } else {
    yield put(showError(translated.errorWhileUploadingDocument));
  }

  yield put(loader.actions.stopLoader());
}

export { projectShowSaga };
