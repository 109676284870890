import { FC } from "react";

interface IProps {
  left: JSX.Element;
  right?: JSX.Element;
}

const SubHeader: FC<IProps> = ({ left, right }) => {
  return (
    <div className="sub-header">
      {left}
      {right}
    </div>
  );
};

export { SubHeader };
