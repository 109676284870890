import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AdminRoute } from "../../../constants/routes";
import { formatDateTime } from "../../../utils/date-format";
import { Loader } from "../../common/loaders/Loader";
import { Header } from "../../layout/Header";
import { adminGeneralPolicies } from "./store/slices";
import { BsFillCaretUpFill } from "react-icons/bs";
import { SubHeader } from "../../layout/SubHeader";
import { GeneralPolicyCode } from "../../../services/api/general-policies";
import { useTranslation } from "react-i18next";

const GeneralPolicies: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedGroup, setSelectedGroup] = useState<GeneralPolicyCode>();

  const policies = useSelector((state) => state.adminGeneralPolicies.policies);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const fetchPolicies = useCallback(
    () => dispatch(adminGeneralPolicies.actions.fetchPolicies()),
    [dispatch]
  );

  const deletePolicy = useCallback(
    (id: number) => dispatch(adminGeneralPolicies.actions.deletePolicy(id)),
    [dispatch]
  );

  const activatePolicy = useCallback(
    (id: number) => dispatch(adminGeneralPolicies.actions.activatePolicy(id)),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(adminGeneralPolicies.actions.resetState()),
    [dispatch]
  );

  useEffect(() => {
    fetchPolicies();

    return () => {
      reset();
    };
  }, [fetchPolicies, reset]);

  const filteredPolicies = useMemo(() => {
    let filtered = policies;

    if (selectedGroup) {
      filtered = filtered.filter(
        (policy) => policy.group.code === selectedGroup
      );
    }

    return filtered;
  }, [policies, selectedGroup]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Header title="General policies" languageDisabled />
          <SubHeader
            left={<div />}
            right={
              <Button as={Link} to={AdminRoute.GeneralPolicies.Store}>
                New Policy
              </Button>
            }
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ marginBottom: "5rem" }}>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>
                    {" "}
                    <div>
                      <span>Group</span>
                      <Form.Group
                        controlId="client-group-code"
                        style={{ marginBottom: "0rem" }}
                      >
                        <Form.Control
                          name="client-group-code"
                          as="select"
                          size="sm"
                          value={selectedGroup}
                          onChange={(e) =>
                            setSelectedGroup(
                              e.target.value as GeneralPolicyCode
                            )
                          }
                        >
                          <option value="">All</option>
                          {Object.values(GeneralPolicyCode).map(
                            (code, index) => (
                              <option key={index} value={code}>
                                {t(code, { ns: "policies" })}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </th>
                  <th>
                    <BsFillCaretUpFill /> Created
                  </th>
                  <th>Activated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={5}>
                      <Loader isLoading={isLoading} />
                    </td>
                  </tr>
                )}

                {!!(!isLoading && policies.length === 0) && (
                  <tr>
                    <td colSpan={5}>No data</td>
                  </tr>
                )}

                {!isLoading &&
                  filteredPolicies.map((policy, index) => (
                    <tr key={index}>
                      <td>{policy.id}</td>
                      <td>{t(policy.group.code, { ns: "policies" })}</td>
                      <td>
                        {policy.created_at && (
                          <span>{formatDateTime(policy.created_at)}</span>
                        )}
                      </td>
                      <td>
                        <span>
                          <Badge
                            variant={policy.is_active ? "success" : "danger"}
                          >
                            {policy.is_active ? "Active" : "Inactive"}
                          </Badge>
                        </span>
                      </td>
                      <td>
                        <Dropdown id="dropdown">
                          <Dropdown.Toggle
                            size="sm"
                            variant="outline-primary"
                            id="dropdown-toggle"
                          >
                            <FaCaretDown />
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            popperConfig={{ strategy: "fixed" }}
                            renderOnMount
                          >
                            <Dropdown.Item
                              as={Link}
                              to={`${AdminRoute.GeneralPolicies.Update.replace(
                                ":id",
                                policy.id.toString()
                              )}`}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={policy.is_active}
                              onClick={() => activatePolicy(policy.id)}
                            >
                              Activate
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={policy.is_active}
                              onClick={() => deletePolicy(policy.id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export { GeneralPolicies };
