import { startCase } from "lodash";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AdminRoute } from "../../../constants/routes";
import { formatDateTime } from "../../../utils/date-format";
import { Loader } from "../../common/loaders/Loader";
import { Header } from "../../layout/Header";
import { adminProjectPolicies } from "./store/slices";
import {
  ProjectPolicy,
  ProjectPolicyCode,
  ProjectPolicyTitleCode,
} from "../../../services/api/admin/project-policies";
import { BsFillCaretUpFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { SubHeader } from "../../layout/SubHeader";

const ProjectPolicies: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const params: any = queryString.parse(search);

  const [selectedGroup, setSelectedGroup] = useState<ProjectPolicyCode>();
  const [selectedTitle, setSelectedTitle] = useState<ProjectPolicyTitleCode>();

  const policies = useSelector((state) => state.adminProjectPolicies.policies);
  const clientCompanies = useSelector(
    (state) => state.adminProjectPolicies.clientCompanies
  );
  const clientCompaniesLoading = useSelector(
    (state) => state.adminProjectPolicies.clientCompaniesLoading
  );
  const policiesLoading = useSelector(
    (state) => state.adminProjectPolicies.policiesLoading
  );

  const fetchPolicies = useCallback(
    (clientCompanyId: number) =>
      dispatch(adminProjectPolicies.actions.fetchPolicies(clientCompanyId)),
    [dispatch]
  );

  const fetchClientCompanies = useCallback(
    () => dispatch(adminProjectPolicies.actions.fetchClientCompanies()),
    [dispatch]
  );

  const deletePolicy = useCallback(
    (policy: ProjectPolicy) =>
      dispatch(adminProjectPolicies.actions.deletePolicy(policy)),
    [dispatch]
  );

  const activatePolicy = useCallback(
    (policy: ProjectPolicy) =>
      dispatch(adminProjectPolicies.actions.activatePolicy(policy)),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(adminProjectPolicies.actions.resetState()),
    [dispatch]
  );

  useEffect(() => {
    fetchClientCompanies();

    return () => {
      reset();
    };
  }, [fetchClientCompanies, reset]);

  const clientCompanyId = useMemo(() => {
    if (params && params.client_company_id) {
      return params.client_company_id;
    }

    return undefined;
  }, [params]);

  useEffect(() => {
    fetchPolicies(clientCompanyId);
  }, [clientCompanyId, fetchPolicies, reset]);

  const filteredPolicies = useMemo(() => {
    let filtered = policies;

    if (selectedGroup) {
      filtered = filtered.filter(
        (policy) => policy.group.code === selectedGroup
      );
    }

    if (selectedTitle) {
      filtered = filtered.filter(
        (policy) => policy.title.code === selectedTitle
      );
    }

    return filtered;
  }, [policies, selectedGroup, selectedTitle]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Header title="Order policies" languageDisabled />
          <SubHeader
            left={<div />}
            right={
              <Button as={Link} to={AdminRoute.ProjectPolicies.Store}>
                New policy
              </Button>
            }
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ marginBottom: "5rem" }}>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>
                    <div>
                      <span>Company</span>
                      <Form.Group
                        controlId="client-company-id"
                        style={{ marginBottom: "0rem" }}
                      >
                        <Form.Control
                          name="client-company-id"
                          as="select"
                          size="sm"
                          value={clientCompanyId}
                          onChange={(e) =>
                            history.push(
                              AdminRoute.ProjectPolicies.List +
                                "?client_company_id=" +
                                e.target.value
                            )
                          }
                        >
                          <option value="">All</option>
                          {Object.values(clientCompanies).map(
                            (company, index) => (
                              <option key={index} value={company.id}>
                                {startCase(company.name)}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </th>
                  <th>
                    <div>
                      <span>Group</span>
                      <Form.Group
                        controlId="client-group-code"
                        style={{ marginBottom: "0rem" }}
                      >
                        <Form.Control
                          name="client-group-code"
                          as="select"
                          size="sm"
                          value={selectedGroup}
                          onChange={(e) =>
                            setSelectedGroup(
                              e.target.value as ProjectPolicyCode
                            )
                          }
                        >
                          <option value="">All</option>
                          {Object.values(ProjectPolicyCode).map(
                            (code, index) => (
                              <option key={index} value={code}>
                                {startCase(code)}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </th>
                  <th>
                    <div>
                      <span>Title</span>
                      <Form.Group
                        controlId="client-title-code"
                        style={{ marginBottom: "0rem" }}
                      >
                        <Form.Control
                          name="client-title-code"
                          as="select"
                          size="sm"
                          value={selectedTitle}
                          onChange={(e) =>
                            setSelectedTitle(
                              e.target.value as ProjectPolicyTitleCode
                            )
                          }
                        >
                          <option value="">All</option>
                          {Object.values(ProjectPolicyTitleCode).map(
                            (code, index) => (
                              <option key={index} value={code}>
                                {t(code, { ns: "policies" })}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </th>
                  <th>
                    <BsFillCaretUpFill /> Created
                  </th>
                  <th>Orders</th>
                  <th>Activated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!!(clientCompaniesLoading || policiesLoading) && (
                  <tr>
                    <td colSpan={8}>
                      <Loader isLoading={true} />
                    </td>
                  </tr>
                )}

                {!!(
                  !clientCompaniesLoading &&
                  !policiesLoading &&
                  policies.length === 0
                ) && (
                  <tr>
                    <td colSpan={8}>No data</td>
                  </tr>
                )}

                {!!(!clientCompaniesLoading && !policiesLoading) &&
                  filteredPolicies.map((policy, index) => (
                    <tr key={index}>
                      <td>{policy.id}</td>
                      <td>
                        <Link
                          to={`${AdminRoute.ClientCompanies.List}?search=${policy.client_company.name}`}
                        >
                          {policy.client_company.name}
                        </Link>
                      </td>
                      <td>{startCase(policy.group.code)}</td>
                      <td>{t(policy.title.code, { ns: "policies" })}</td>
                      <td>
                        {policy.created_at && (
                          <span>{formatDateTime(policy.created_at)}</span>
                        )}
                      </td>
                      <td>{policy.project_count}</td>
                      <td>
                        <span>
                          <Badge
                            variant={policy.is_active ? "success" : "danger"}
                          >
                            {policy.is_active ? "Active" : "Inactive"}
                          </Badge>
                        </span>
                      </td>
                      <td>
                        <Dropdown id="dropdown">
                          <Dropdown.Toggle
                            size="sm"
                            variant="outline-primary"
                            id="dropdown-toggle"
                          >
                            <FaCaretDown />
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            popperConfig={{ strategy: "fixed" }}
                            renderOnMount
                          >
                            <Dropdown.Item
                              as={Link}
                              to={`${AdminRoute.ProjectPolicies.Update.replace(
                                ":id",
                                policy.id.toString()
                              )}`}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={policy.is_active}
                              onClick={() => activatePolicy(policy)}
                            >
                              Activate
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={
                                policy.is_active || !!policy.project_count
                              }
                              onClick={() => deletePolicy(policy)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export { ProjectPolicies };
