import { fork, take, call, put } from "typed-redux-saga";
import { loader } from "../../../common/loaders/store/slices";
import { showError, showSuccess } from "../../../alert/store/slices";
import { applications } from "./../slices";
import { applicationAPI } from "../../../../services/api/application";
import { translated } from "../../../../i18n";

function* applicationListSaga() {
  yield* fork(fetchListListener);
  yield* fork(deleteListener);
}

function* fetchListListener() {
  while (true) {
    yield take(applications.actions.fetchList);

    yield* call(fetchList);
  }
}

function* deleteListener() {
  while (true) {
    const { payload } = yield take(applications.actions.deleteApplication);

    yield* call(deleteApplication, payload.id, payload.silent);
  }
}

function* fetchList() {
  yield put(loader.actions.startLoader());

  const result = yield* call(applicationAPI.fetchList);

  if (result && result.data && result.meta) {
    yield put(applications.actions.setListData(result));
  } else {
    yield put(showError(translated.errorWhileGettingApplications));
  }

  yield put(loader.actions.stopLoader());
}

function* deleteApplication(id: string, silent: boolean = false) {
  yield put(loader.actions.startLoader());

  const result = yield* call(applicationAPI.deleteApplication, id);

  if (result && result.success) {
    if (!silent) {
      yield put(showSuccess(translated.applicationDeleted));
    }

    yield* call(fetchList);
  } else {
    yield put(showError(translated.errorWhileDeletingApplication));
  }

  yield put(loader.actions.stopLoader());
}

export { applicationListSaga };
