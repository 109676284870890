import { IUser } from "../../../../services/api/users";

const showUserEmail = (user: IUser | null): string => {
  if (!user) {
    return "";
  }

  return user.email;
};

export { showUserEmail };
