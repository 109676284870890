import { FC, useCallback, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { email } from "../../../constants/regex";
import { AdminRoute } from "../../../constants/routes";
import { Header } from "../../layout/Header";
import { adminUsers } from "./store/slices";

interface IInviteForm {
  email: string;
}

const UserInvite: FC = () => {
  const invitedUser = useSelector((state) => state.adminUsers.invitedUser);

  const { register, handleSubmit, errors } = useForm<IInviteForm>();

  const history = useHistory();
  const dispatch = useDispatch();

  const invite = useCallback(
    (email: string) => dispatch(adminUsers.actions.invite(email)),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(adminUsers.actions.resetInvitedUser()),
    [dispatch]
  );

  const onSubmit = (data: IInviteForm) => {
    invite(data.email);
  };

  useEffect(() => {
    if (invitedUser) {
      history.push(
        AdminRoute.Users.EditRoles.replace(":userId", invitedUser.id.toString())
      );
    }
  }, [invitedUser, history]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Container fluid className="user-invite">
      <Row>
        <Header title="User invite" languageDisabled />
      </Row>
      <Row>
        <p>Invited user will fill it's credentials manually.</p>
      </Row>
      <Row>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Form.Group as={Col} controlId="email">
              <Form.Control
                className="email-input"
                name="email"
                type="text"
                ref={register({ required: true, pattern: email })}
                isInvalid={!!errors.email}
                maxLength={190}
                autoComplete="off"
                placeholder="Email"
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.type === "required" && "Required field."}
                {errors.email?.type === "pattern" && "Must be a valid email."}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Button
            type="submit"
            style={{
              minWidth: "100px",
            }}
          >
            Invite
          </Button>
        </Form>
      </Row>
    </Container>
  );
};

export { UserInvite };
