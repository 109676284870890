import { createSlice } from "@reduxjs/toolkit";
import { IApplication } from "../../../services/api/application";

export interface IApplicationState {
  listData: any;
  data: IApplication | null;
  dataSaved: boolean;
  newPartnerDraftCreated: boolean;
  existingPartnerDraftCreated: boolean;
  existingPartnerDraftIsFetching: boolean;
  newPartnerDraftIsFetching: boolean;
  documentsFetching: boolean;
}

const initialState: IApplicationState = {
  listData: [],
  data: null,
  dataSaved: false,
  newPartnerDraftCreated: false,
  existingPartnerDraftCreated: false,
  existingPartnerDraftIsFetching: false,
  newPartnerDraftIsFetching: false,
  documentsFetching: false,
};

const applications = createSlice({
  name: "applications",
  initialState,
  reducers: {
    // List
    fetchList: (state) => {
      //
    },
    setListData: (state, action) => {
      state.listData = action.payload.data;
    },
    resetList: (state) => {
      state.listData = [];
    },

    // CRUD
    fetchApplication: (state, action) => {
      //
    },
    storeNewPartner: (state, action) => {
      //
    },
    storeExistingPartner: (state, action) => {
      //
    },
    storeNewPartnerDraft: (state) => {
      state.newPartnerDraftIsFetching = true;
    },
    updateNewPartnerDraft: (state, action) => {
      //
    },
    storeExistingPartnerDraft: (state) => {
      state.existingPartnerDraftIsFetching = true;
    },
    updateExistingPartnerDraft: (state, action) => {
      //
    },
    deleteApplication: (state, action) => {
      //
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDataSaved: (state) => {
      state.dataSaved = true;
    },
    setNewPartnerDraftCreated: (state) => {
      state.newPartnerDraftCreated = true;
      state.newPartnerDraftIsFetching = false;
    },
    resetNewPartnerDraftCreated: (state) => {
      state.newPartnerDraftCreated = false;
    },
    setExistingPartnerDraftCreated: (state) => {
      state.existingPartnerDraftCreated = true;
      state.existingPartnerDraftIsFetching = false;
    },
    resetExistingPartnerDraftCreated: (state) => {
      state.existingPartnerDraftCreated = false;
    },
    resetData: (state) => {
      state.data = null;
      state.dataSaved = false;
    },

    // Documents
    uploadDocuments: (state, action) => {
      state.documentsFetching = true;
    },
    updateDocument: (state, action) => {
      state.documentsFetching = true;
    },
    deleteDocument: (state, action) => {
      state.documentsFetching = true;
    },
    resetDocuments: (state, action) => {
      if (state.data && action.payload) {
        state.data.documents = action.payload;
      }

      state.documentsFetching = false;
    },
    reset: () => initialState,
  },
});

export { applications };
