import { API, ApiResource, HTTPMethod, SuccessResponse } from "..";
import { IMaintenanceForm } from "../../../components/admin/maintenance/Maintenance";
import { IMaintenance } from "../maintenance";

const storeMaintenance = async (
  data: IMaintenanceForm
): Promise<ApiResource<IMaintenance>> => {
  const response = await API.request<ApiResource<IMaintenance>>(
    `/super-admin/maintenance`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const deleteMaintenance = async (): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/super-admin/maintenance`,
    {
      method: HTTPMethod.DELETE,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const adminMaintenanceAPI = {
  storeMaintenance,
  deleteMaintenance,
};

export { adminMaintenanceAPI };
