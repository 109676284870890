import { FC, useCallback, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CarrierRoute } from "../../constants/routes";
import { ButtonLoader } from "../common/button-loader/ButtonLoader";
import { applications } from "./store/slices";
import { useTranslation } from "react-i18next";

const CreateButtons: FC = () => {
  const { t } = useTranslation("applicationCreateButtons");

  const profile = useSelector((state) => state.profile);
  const data = useSelector((state) => state.applications.data);
  const newPartnerDraftCreated = useSelector(
    (state) => state.applications.newPartnerDraftCreated
  );
  const newPartnerDraftIsFetching = useSelector(
    (state) => state.applications.newPartnerDraftIsFetching
  );
  const existingPartnerDraftCreated = useSelector(
    (state) => state.applications.existingPartnerDraftCreated
  );
  const existingPartnerDraftIsFetching = useSelector(
    (state) => state.applications.existingPartnerDraftIsFetching
  );

  const dispatch = useDispatch();

  const history = useHistory();

  const storeNewPartnerDraft = useCallback(
    () => dispatch(applications.actions.storeNewPartnerDraft()),
    [dispatch]
  );
  const resetNewPartnerDraftCreated = useCallback(
    () => dispatch(applications.actions.resetNewPartnerDraftCreated()),
    [dispatch]
  );
  const storeExistingPartnerDraft = useCallback(
    () => dispatch(applications.actions.storeExistingPartnerDraft()),
    [dispatch]
  );
  const resetExistingPartnerDraftCreated = useCallback(
    () => dispatch(applications.actions.resetExistingPartnerDraftCreated()),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      resetNewPartnerDraftCreated();
      resetExistingPartnerDraftCreated();
    };
  }, [resetNewPartnerDraftCreated, resetExistingPartnerDraftCreated]);

  useEffect(() => {
    if (newPartnerDraftCreated && data) {
      history.push(
        CarrierRoute.Applications.NewPartner.replace(
          ":applicationId",
          data.id.toString()
        )
      );
    }
  }, [newPartnerDraftCreated, data, history]);

  useEffect(() => {
    if (existingPartnerDraftCreated && data) {
      history.push(
        CarrierRoute.Applications.ExistingPartner.replace(
          ":applicationId",
          data.id.toString()
        )
      );
    }
  }, [existingPartnerDraftCreated, data, history]);

  const buttonsDisabled = profile.isManager;

  return (
    <>
      <Col xs={1} />
      <Col className="create-buttons">
        <ButtonLoader
          onClick={storeNewPartnerDraft}
          size="lg"
          disabled={newPartnerDraftIsFetching}
          buttonDisabled={buttonsDisabled}
        >
          {t("newGirtekaPartner")}
        </ButtonLoader>
        <span>{t("chooseOptionIfNotPartnerYet")}</span>

        <ButtonLoader
          onClick={storeExistingPartnerDraft}
          size="lg"
          variant="outline-secondary"
          disabled={existingPartnerDraftIsFetching}
          buttonDisabled={buttonsDisabled}
        >
          {t("existingGirtekaPartner")}
        </ButtonLoader>
        <span>{t("chooseOptionIfNotRegistered")}</span>
      </Col>
      <Col className="laptop-logo">
        <img src="/images/laptop.svg" alt="Laptop logo" />
      </Col>
      <Col xs={1} />
    </>
  );
};

export { CreateButtons };
