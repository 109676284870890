import { FC, useCallback, useEffect } from "react";
import { Row, Container, Image, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { email } from "../../../constants/regex";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { registration } from "./store/slices";
import {
  RecaptchaAction,
  removeRecaptcha,
  useRecaptcha,
} from "../../../hooks/recaptcha";
import { log } from "../../../services/logger";
import { useTranslation } from "react-i18next";
import { policies } from "../../policies/store/slices";
import { LanguageSwitcher } from "../../translations/LanguageSwitcher";
import { LanguageCode } from "../../../i18n";
import { Cover } from "../../cover/Cover";
import PasswordRules from "../../password-rules/PasswordRules";

export interface IRegisterData {
  email: string;
  name: string;
  surname: string;
  phone: string;
  password: string;
  password_confirmation: string;
  recaptchaToken: string;
  recaptchaAction: RecaptchaAction;
  languageCode: LanguageCode;
  marketing_consent: boolean;
  // privacy_consent: boolean;
  terms_consent: boolean;
}

const Register: FC = () => {
  const { t } = useTranslation("registration");

  const recaptcha = useRecaptcha();

  const isLoading = useSelector((state) => state.loader.isLoading);
  const registered = useSelector((state) => state.registration.registered);
  const languageCode = useSelector((state) => state.translations.current.code);

  const history = useHistory();
  const dispatch = useDispatch();

  const registerUser = useCallback(
    (data: IRegisterData) => dispatch(registration.actions.register(data)),
    [dispatch]
  );

  // const togglePrivacyPolicy = useCallback(
  //   () => dispatch(policies.actions.togglePrivacyPolicy()),
  //   [dispatch]
  // );

  const toggleTermsConditions = useCallback(
    () => dispatch(policies.actions.toggleTermsConditions()),
    [dispatch]
  );

  const toggleMarketingPolicy = useCallback(
    () => dispatch(policies.actions.toggleMarketingPolicy()),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(registration.actions.reset()), [
    dispatch,
  ]);

  const { register, errors, handleSubmit, watch } = useForm<IRegisterData>();

  const onSubmit = async (data: IRegisterData) => {
    const recaptchaAction = RecaptchaAction.Register;
    let recaptchaToken: string = "";

    try {
      recaptchaToken = await recaptcha.validate(recaptchaAction);
    } catch (error) {
      log.error(error as string);
    }

    if (recaptchaToken) {
      registerUser({
        ...data,
        recaptchaToken,
        recaptchaAction,
        languageCode,
      });
    }
  };

  useEffect(() => {
    if (registered) {
      history.push("/");
    }
  }, [registered, history]);

  useEffect(() => {
    return () => {
      removeRecaptcha();

      reset();
    };
  }, [reset]);

  return (
    <Container className="register" fluid>
      <Cover />

      <Row className="language-row">
        <LanguageSwitcher />
      </Row>
      <Row className="form-row">
        <Col />
        <Col xs={12} md={6} lg={5} xl={3}>
          <Row>
            <Col className="logo-place">
              <Link to="/">
                <Image src="/images/logo.svg" />
              </Link>
            </Col>
          </Row>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} controlId="email">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  ref={register({ required: true, pattern: email })}
                  isInvalid={!!errors.email}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.type === "required" && t("requiredField")}
                  {errors.email?.type === "pattern" && t("invalidEmail")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="name">
                <Form.Label>{t("name")}</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  ref={register({ required: true })}
                  isInvalid={!!errors.name}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.type === "required" && t("requiredField")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="surname">
                <Form.Label>{t("lastName")}</Form.Label>
                <Form.Control
                  name="surname"
                  type="text"
                  ref={register({ required: true })}
                  isInvalid={!!errors.surname}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.surname?.type === "required" && t("requiredField")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="phone">
                <Form.Label>{t("phone")}</Form.Label>
                <Form.Control
                  name="phone"
                  type="text"
                  ref={register({ required: true, minLength: 8 })}
                  isInvalid={!!errors.phone}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone?.type === "required" && t("requiredField")}
                  {errors.phone?.type === "minLength" && t("tooShortNumber")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="password">
                <Form.Label>{t("password")}</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  ref={register({
                    required: true,
                    minLength: 8,
                    validate: {
                      containsUpper: (value) => /[A-Z]/.test(value),
                      containsLower: (value) => /[a-z]/.test(value),
                      containsDigits: (value) => /[0-9]/.test(value),
                    },
                  })}
                  isInvalid={!!errors.password}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.type === "required" && t("requiredField")}
                  {errors.password?.type === "minLength" &&
                    t("minCharsRequired")}
                  {errors.password?.type === "containsUpper" &&
                    t("oneUpperCaseRequired")}
                  {errors.password?.type === "containsLower" &&
                    t("oneLowerCaseRequired")}
                  {errors.password?.type === "containsDigits" &&
                    t("oneNumberRequired")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="password_confirmation">
                <Form.Label>{t("confirmPassword")}</Form.Label>
                <Form.Control
                  name="password_confirmation"
                  type="password"
                  ref={register({
                    required: true,
                    minLength: 8,
                    validate: (value) => value === watch("password"),
                  })}
                  isInvalid={!!errors.password_confirmation}
                  maxLength={190}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation?.type === "required" &&
                    t("requiredField")}
                  {errors.password_confirmation?.type === "validate" &&
                    t("passwordsDontMatch")}
                  {errors.password_confirmation?.type === "minLength" &&
                    t("minCharsRequired")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <PasswordRules />
            </Form.Row>

            {/* <Form.Row>
              <Form.Group as={Col} controlId="privacy_consent">
                <Form.Check
                  name="privacy_consent"
                  type="checkbox"
                  ref={register({ required: true })}
                  label={
                    <>
                      {t("iHaveReadAndAgreeGirtekaLogistics")}{" "}
                      <Link onClick={togglePrivacyPolicy} to="#">
                        {t("privacyPolicy")}
                      </Link>
                    </>
                  }
                />
              </Form.Group>
            </Form.Row> */}

            <Form.Row>
              <Form.Group as={Col} controlId="terms_consent">
                <Form.Check
                  name="terms_consent"
                  type="checkbox"
                  ref={register({ required: true })}
                  label={
                    <>
                      {t("iHaveReadAndAgreeToThe")}{" "}
                      <Link onClick={toggleTermsConditions} to="#">
                        {t("termsAndConditions")}
                      </Link>
                    </>
                  }
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="marketing_consent">
                <Form.Check
                  name="marketing_consent"
                  type="checkbox"
                  ref={register}
                  label={
                    <>
                      {t("iAgreeProcessing")}{" "}
                      <Link onClick={toggleMarketingPolicy} to="#">
                        {t("directMarketing")}
                      </Link>
                    </>
                  }
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Text className="text-muted">
                  This site is protected by reCAPTCHA and the Google{" "}
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>{" "}
                  apply.
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <div className="register-button">
              <ButtonLoader
                variant="primary"
                type="submit"
                disabled={isLoading}
                buttonDisabled={
                  // !watch("privacy_consent") || !watch("terms_consent")
                  !watch("terms_consent")
                }
              >
                {t("register")}
              </ButtonLoader>
            </div>
          </Form>
        </Col>
        <Col />
      </Row>
    </Container>
  );
};

export { Register };
