import { FC, useCallback, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AdminRoute } from "../../../constants/routes";
import { Loader } from "../../common/loaders/Loader";
import { Header } from "../../layout/Header";
import { CompanyForm } from "./form/CompanyForm";
import { adminCompanies } from "./store/slices";

const Company: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { companyId } = useParams<{ companyId: string }>();

  const company = useSelector((state) => state.adminCompanies.company);
  const companyUpdated = useSelector(
    (state) => state.adminCompanies.companyUpdated
  );

  const fetchCompany = useCallback(
    (companyId: string) =>
      dispatch(adminCompanies.actions.fetchCompany(companyId)),
    [dispatch]
  );

  useEffect(() => {
    if (companyUpdated) {
      history.push(AdminRoute.Companies.List);
    }
  }, [history, companyUpdated]);

  useEffect(() => {
    if (companyId) {
      fetchCompany(companyId);
    }
  }, [fetchCompany, companyId]);

  return (
    <Container fluid>
      <Row>
        <Header
          title={companyId ? "Company update" : "Create company"}
          subTitle={company && company.name ? company.name : ""}
          languageDisabled
        />
      </Row>
      <Row>
        {companyId && !company ? <Loader /> : <CompanyForm company={company} />}
      </Row>
    </Container>
  );
};

export { Company };
