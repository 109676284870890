import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Container,
  ListGroup,
} from "react-bootstrap";
import { FormContext, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaBuilding, FaFileAlt, FaQuestionCircle } from "react-icons/fa";
import { vatNumber } from "../../../constants/regex";
import { IApplication } from "../../../services/api/application";
import { InfoAlert, InfoAlertStatus } from "../../alert/InfoAlert";
import { Header } from "../../layout/Header";
import { StatusBadge } from "../StatusBadge";
import { ApplicationQuestions, NoNullFields } from "./NewPartnerForm";
import { QuestionTooltip } from "../../common/tooltips/QuestionTooltip";
import { useDispatch, useSelector } from "react-redux";
import { applications } from "../store/slices";
import { Loader } from "../../common/loaders/Loader";
import { FileDropzone } from "../../common/files/FileDropzone";
import { FileDropdown } from "./FileDropdown";

export type IShortApplicationForm = NoNullFields<IApplication> & {
  questions: ApplicationQuestions;
};

interface IProps {
  data: IShortApplicationForm;
  reviewMode?: boolean;
  documentsFetching?: boolean;
  onSubmit: (data: IShortApplicationForm) => void;
  onUpdateDraft: (data: IShortApplicationForm, silent?: boolean) => void;
}

const ExistingPartnerForm: FC<IProps> = ({
  data,
  reviewMode,
  documentsFetching,
  onSubmit,
  onUpdateDraft,
}) => {
  const { t } = useTranslation("application");

  const dispatch = useDispatch();

  const [required, setRequired] = useState<boolean>(true);
  const [
    showProductSafetyFileValidation,
    setShowProductSafetyFileValidation,
  ] = useState(false);
  const [
    showExpertiseSecurityFileValidation,
    setShowExpertiseSecurityFileValidation,
  ] = useState(false);

  const settings = useSelector((state) => state.settings.carrier);

  const methods = useForm<IShortApplicationForm>({
    defaultValues: data,
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    clearError,
    watch,
  } = methods;

  const uploadDocuments = useCallback(
    (documents: File[]) => {
      dispatch(
        applications.actions.uploadDocuments({
          applicationId: data.id,
          documents,
        })
      );

      clearError("documents");
    },
    [data.id, dispatch, clearError]
  );

  const updateDocument = useCallback(
    (documentId: number, documentTypeId: number) => {
      dispatch(
        applications.actions.updateDocument({
          applicationId: data.id,
          documentId,
          data: {
            documentTypeId,
          },
        })
      );

      clearError("documents");
    },
    [data, dispatch, clearError]
  );

  const deleteDocument = useCallback(
    (id: number) => {
      dispatch(
        applications.actions.deleteDocument({
          applicationId: data.id,
          documentId: id,
        })
      );

      clearError("documents");
    },
    [data.id, dispatch, clearError]
  );

  const validateFiles = (formData: IShortApplicationForm) => {
    let isValid = true;

    const documents = Object.values(data.documents);

    if (
      // has "ISO22001/ BRC/ IFS"
      Number(formData.questions.productSafetyManagement) &&
      !documents.find((document) => document.type?.code === "PS_COMP")
    ) {
      setShowProductSafetyFileValidation(true);

      if (productSafetyRef && productSafetyRef.current) {
        productSafetyRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }

      isValid = false;
    } else if (showProductSafetyFileValidation) {
      setShowProductSafetyFileValidation(false);
    }

    if (
      // has "TAPA TSR/ ISO28001"
      Number(formData.questions.expertiseSecurityManagement) &&
      !documents.find((document) => document.type?.code === "SEC_COMP")
    ) {
      setShowExpertiseSecurityFileValidation(true);

      if (expertiseSecurityRef && expertiseSecurityRef.current) {
        expertiseSecurityRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }

      isValid = false;
    } else if (showExpertiseSecurityFileValidation) {
      setShowExpertiseSecurityFileValidation(false);
    }

    if (isValid) {
      onSubmit(formData);
    }
  };

  useEffect(() => {
    if (!required) {
      handleSubmit((data) => {
        onUpdateDraft(data);
      })();

      setRequired(true);
    }
  }, [required, handleSubmit, onUpdateDraft]);

  useEffect(() => {
    if (data.documents) {
      Object.values(data.documents).forEach((document, index) => {
        setValue(
          `documents.${index}.type.id`,
          document.type ? document.type.id : ""
        );
      });
    }
  }, [data.documents, setValue]);

  const productSafetyManagement = watch("questions.productSafetyManagement");
  const expertiseSecurityManagement = watch(
    "questions.expertiseSecurityManagement"
  );

  const showProductSafetyQuestions =
    productSafetyManagement !== "" && !Number(productSafetyManagement);
  const showExpertiseSecurityQuestions =
    expertiseSecurityManagement !== "" && !Number(expertiseSecurityManagement);

  const showProductSafetyFileMessage =
    productSafetyManagement !== "" && Boolean(Number(productSafetyManagement));
  const showExpertiseSecurityFileMessage =
    expertiseSecurityManagement !== "" &&
    Boolean(Number(expertiseSecurityManagement));

  const productSafetyRef = useRef<null | HTMLDivElement>(null);
  const expertiseSecurityRef = useRef<null | HTMLDivElement>(null);

  if (!settings) {
    return <Loader />;
  }

  return (
    <Container fluid>
      <Row className="application-form-header">
        <Header
          title={t("existingPartnerApplication") as string}
          titleBadge={
            data && data.status && <StatusBadge status={data.status} />
          }
        />
      </Row>
      <Row className="application-form">
        <Col></Col>
        <Col md="10" lg="8" xl="6">
          {data && data.rejectComment && (
            <InfoAlert
              label={t("cancellationComment") as string}
              message={data.rejectComment}
              status={InfoAlertStatus.Error}
            />
          )}

          <FormContext {...methods}>
            <Form onSubmit={handleSubmit(validateFiles)} autoComplete="off">
              <Card>
                <Card.Header>
                  <FaBuilding /> {t("company")}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col></Col>
                    <Col lg="8">
                      <h3>{t("details")}</h3>
                      <Form.Row>
                        <Form.Group as={Col} controlId="company.name">
                          <Form.Label>{t("companyName")}</Form.Label>
                          <Form.Control
                            name="company.name"
                            type="text"
                            ref={register({
                              required,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.company?.name}
                            maxLength={120}
                            disabled={reviewMode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {["required", "validate"].includes(
                              errors.company?.name?.type as string
                            ) && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="company.vatNumber">
                          <Form.Label>{t("vatNumber")}</Form.Label>
                          <Form.Control
                            name="company.vatNumber"
                            type="text"
                            ref={register({
                              required,
                              pattern: vatNumber,
                              validate: (value) =>
                                required ? value.trim() !== "" : true,
                            })}
                            isInvalid={!!errors.company?.vatNumber}
                            maxLength={20}
                            disabled={reviewMode}
                            style={{ textTransform: "uppercase" }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.company?.vatNumber?.type === "required" &&
                              t("requiredField")}
                            {errors.company?.vatNumber?.type === "pattern" &&
                              t("vatNumberValidation")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Col>
                    <Col></Col>
                  </Row>
                </Card.Body>
              </Card>

              {data.questions && (
                <Card className="questionnaire-card">
                  <Card.Header>
                    <span>
                      <FaQuestionCircle /> {t("questionnaire")}
                    </span>
                    <QuestionTooltip
                      explanation={t("yourAnswersAreImportant")}
                      iconStyle="white"
                    />
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col></Col>
                      <Col xl="8">
                        <div className="main-question">
                          <div>{t("productSafetyManagement")}</div>
                          <div className="radios" key="inline-radio">
                            <Form.Check
                              inline
                              label={t("yes")}
                              name="questions.productSafetyManagement"
                              value={1}
                              type="radio"
                              id={`productSafetyManagement-radio-1`}
                              ref={register({
                                required,
                              })}
                              isInvalid={
                                !!errors.questions?.productSafetyManagement
                              }
                              disabled={reviewMode}
                            />
                            <Form.Check
                              inline
                              defaultChecked
                              label={t("no")}
                              name="questions.productSafetyManagement"
                              value={0}
                              type="radio"
                              id={`productSafetyManagement-radio-2`}
                              ref={register({
                                required,
                              })}
                              isInvalid={
                                !!errors.questions?.productSafetyManagement
                              }
                              disabled={reviewMode}
                            />
                          </div>
                        </div>

                        {showProductSafetyFileMessage && (
                          <small
                            ref={productSafetyRef}
                            className={`add-document-warning ${
                              showProductSafetyFileValidation
                                ? "text-danger"
                                : "text-secondary"
                            }`}
                          >
                            {t("addIsoToCompanyDocuments")}
                          </small>
                        )}

                        {showProductSafetyQuestions && (
                          <ListGroup style={{ marginBottom: "2.5rem" }}>
                            <ListGroup.Item>
                              <div>{t("foodSafetyPolicy")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.foodSafetyPolicy"
                                  value={1}
                                  type="radio"
                                  id={`foodSafetyPolicy-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.foodSafetyPolicy
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.foodSafetyPolicy"
                                  value={0}
                                  type="radio"
                                  id={`foodSafetyPolicy-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.foodSafetyPolicy
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("haccpPlan")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.haccpPlan"
                                  value={1}
                                  type="radio"
                                  id={`haccpPlan-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.haccpPlan}
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.haccpPlan"
                                  value={0}
                                  type="radio"
                                  id={`haccpPlan-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.haccpPlan}
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("chemicalsInCleansing")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.chemicalsInCleansing"
                                  value={1}
                                  type="radio"
                                  id={`chemicalsInCleansing-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.chemicalsInCleansing
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.chemicalsInCleansing"
                                  value={0}
                                  type="radio"
                                  id={`chemicalsInCleansing-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.chemicalsInCleansing
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("pestControlSystem")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.pestControlSystem"
                                  value={1}
                                  type="radio"
                                  id={`pestControlSystem-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.pestControlSystem
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.pestControlSystem"
                                  value={0}
                                  type="radio"
                                  id={`pestControlSystem-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.pestControlSystem
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("cleaningSchedule")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.cleaningSchedule"
                                  value={1}
                                  type="radio"
                                  id={`cleaningSchedule-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.cleaningSchedule
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.cleaningSchedule"
                                  value={0}
                                  type="radio"
                                  id={`cleaningSchedule-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.cleaningSchedule
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("proceduresForOperation")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.proceduresForOperation"
                                  value={1}
                                  type="radio"
                                  id={`proceduresForOperation-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.proceduresForOperation
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.proceduresForOperation"
                                  value={0}
                                  type="radio"
                                  id={`proceduresForOperation-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.proceduresForOperation
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                        )}

                        <hr />

                        <div className="main-question">
                          <div>{t("expertiseSecurityManagement")}</div>
                          <div className="radios" key="inline-radio">
                            <Form.Check
                              inline
                              label={t("yes")}
                              name="questions.expertiseSecurityManagement"
                              value={1}
                              type="radio"
                              id={`expertiseSecurityManagement-radio-1`}
                              ref={register({
                                required,
                              })}
                              isInvalid={
                                !!errors.questions?.expertiseSecurityManagement
                              }
                              disabled={reviewMode}
                            />
                            <Form.Check
                              inline
                              defaultChecked
                              label={t("no")}
                              name="questions.expertiseSecurityManagement"
                              value={0}
                              type="radio"
                              id={`expertiseSecurityManagement-radio-2`}
                              ref={register({
                                required,
                              })}
                              isInvalid={
                                !!errors.questions?.expertiseSecurityManagement
                              }
                              disabled={reviewMode}
                            />
                          </div>
                        </div>

                        {showExpertiseSecurityFileMessage && (
                          <small
                            ref={expertiseSecurityRef}
                            className={`add-document-warning ${
                              showExpertiseSecurityFileValidation
                                ? "text-danger"
                                : "text-secondary"
                            }`}
                          >
                            {t("addTapaToCompanyDocuments")}
                          </small>
                        )}

                        {showExpertiseSecurityQuestions && (
                          <ListGroup>
                            <ListGroup.Item>
                              <div>{t("securityPolicy")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.securityPolicy"
                                  value={1}
                                  type="radio"
                                  id={`securityPolicy-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.securityPolicy}
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.securityPolicy"
                                  value={0}
                                  type="radio"
                                  id={`securityPolicy-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.securityPolicy}
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("hvttCargo")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.hvttCargo"
                                  value={1}
                                  type="radio"
                                  id={`hvttCargo-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.hvttCargo}
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.hvttCargo"
                                  value={0}
                                  type="radio"
                                  id={`hvttCargo-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.hvttCargo}
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("secureVehicleParking")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.secureVehicleParking"
                                  value={1}
                                  type="radio"
                                  id={`secureVehicleParking-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.secureVehicleParking
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.secureVehicleParking"
                                  value={0}
                                  type="radio"
                                  id={`secureVehicleParking-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.secureVehicleParking
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("routesAndStops")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.routesAndStops"
                                  value={1}
                                  type="radio"
                                  id={`routesAndStops-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.routesAndStops}
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.routesAndStops"
                                  value={0}
                                  type="radio"
                                  id={`routesAndStops-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={!!errors.questions?.routesAndStops}
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <div>{t("driverAssessment")}</div>
                              <div className="radios" key="inline-radio">
                                <Form.Check
                                  inline
                                  label={t("yes")}
                                  name="questions.driverAssessment"
                                  value={1}
                                  type="radio"
                                  id={`driverAssessment-radio-1`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.driverAssessment
                                  }
                                  disabled={reviewMode}
                                />
                                <Form.Check
                                  inline
                                  defaultChecked
                                  label={t("no")}
                                  name="questions.driverAssessment"
                                  value={0}
                                  type="radio"
                                  id={`driverAssessment-radio-2`}
                                  ref={register({
                                    required,
                                  })}
                                  isInvalid={
                                    !!errors.questions?.driverAssessment
                                  }
                                  disabled={reviewMode}
                                />
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                        )}
                      </Col>
                      <Col></Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}

              {reviewMode &&
              Object.values(data.documents).length === 0 ? null : (
                <Card className="documents-card">
                  <Card.Header>
                    <span>
                      <FaFileAlt /> {t("companyDocuments")}{" "}
                    </span>
                    <QuestionTooltip
                      explanation={`${t(
                        "allowedFiles"
                      )}: images, pdf, word, excel, text documents.`}
                      iconStyle="white"
                    />
                  </Card.Header>
                  <Card.Body>
                    {documentsFetching && (
                      <div className="documents-fetching">
                        <Loader isLoading />
                      </div>
                    )}
                    {!reviewMode && (
                      <FileDropzone
                        onDropAccepted={uploadDocuments}
                        maxSize={settings.general.maxUploadSize * 1000}
                        multiple
                      />
                    )}
                    {data && data.documents && (
                      <FileDropdown
                        files={data.documents}
                        onDelete={deleteDocument}
                        onUpdate={updateDocument}
                        required={required}
                        disabled={reviewMode}
                        documentTypes={
                          settings
                            ? settings.applications.documentTypes.filter(
                                (type) =>
                                  ["PS_COMP", "SEC_COMP"].includes(
                                    type.code as string
                                  )
                              )
                            : []
                        }
                      />
                    )}
                  </Card.Body>
                </Card>
              )}

              <div className="form-buttons">
                <Button
                  variant="outline-secondary"
                  onClick={() => setRequired(false)}
                  disabled={reviewMode}
                >
                  {t("saveDraft")}
                </Button>

                <Button type="submit" disabled={reviewMode}>
                  {t("submit")}
                </Button>
              </div>
            </Form>
          </FormContext>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export { ExistingPartnerForm };
