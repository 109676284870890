import { createSlice } from "@reduxjs/toolkit";
import i18n, { ILanguage, LanguageCode, languages } from "../../../i18n";

export interface ILanguageState {
  current: ILanguage;
  selectable: ILanguage[];
  ready: boolean;
  profileSynced: boolean;
}

const generateState = (): ILanguageState => {
  const cachedCode = localStorage.getItem("defaultLanguage");
  const currentLanguage = languages.find(
    (language) => language.code === (cachedCode ? cachedCode : LanguageCode.EN)
  )!;

  if (currentLanguage.code !== i18n.language) {
    i18n.changeLanguage(currentLanguage.code);
  }

  return {
    current: currentLanguage,
    selectable: languages,
    ready: true,
    profileSynced: false,
  };
};

const translations = createSlice({
  name: "translations",
  initialState: generateState(),
  reducers: {
    change: (state, action) => {
      state.current = action.payload;
      state.ready = false;
    },
    setReady: (state) => {
      state.ready = true;
    },
    profileSynced: (state) => {
      state.profileSynced = true;
    },
    resetProfileSynced: (state) => {
      state.profileSynced = false;
    },
  },
});

export { translations };
