import { call, fork, take } from "typed-redux-saga";
import { log } from "../../../services/logger";
import { alert } from "./slices";

function* alertSaga() {
  yield* fork(alertListener);
}

function* alertListener() {
  while (true) {
    const { payload } = yield take(alert.actions.show);

    switch (payload.variant) {
      case "danger":
        yield* call(log.error, payload.message);
        break;
      case "warning":
        yield* call(log.warning, payload.message);
        break;
      case "info":
        yield* call(log.info, payload.message);
        break;
    }
  }
}

export { alertSaga };
