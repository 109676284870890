import { ApiResource, API, HTTPMethod, SuccessResponse } from ".";
import queryString from "query-string";
import { IUpdateDocumentData } from "../../components/application/form/FileDropdown";
import { IDocument } from "./documents";
import { IUser } from "./users";

export enum ApplicationStatus {
  Open = "open",
  Filled = "filled",
  Approved = "approved",
  Rejected = "rejected",
}

export enum ApplicationType {
  New = "new",
  Existing = "existing",
}

export interface IFetchListRequest {
  search?: string;
  perPage?: number;
  page?: number;
}

export interface IApplicationRecommendation {
  id: number;
  name: string | null;
  surname: string | null;
  position: string | null;
  phone: string | null;
  email: string | null;
  companyName: string | null;
  companyAddress: string | null;
}

export interface IApplicationType {
  id: number;
  name: string;
  code: ApplicationType;
}

export interface IApplicationStatus {
  id: number;
  name: string;
  code: ApplicationStatus;
}

export interface IApplicationInsurance {
  id: number;
  brokerName: string | null;
  email: string | null;
  phone: string | null;
}

export interface IApplicationBankAccount {
  id: number;
  bankName: string | null;
  swift: string | null;
  accountIBAN: string | null;
  number: string | null;
  current: boolean;
  currency: string | null;
}

export interface IApplicationAddress {
  id: number;
  street: string | null;
  city: string | null;
  country: string | null;
}

export interface IApplicationCompany {
  id: number;
  name: string | null;
  registrationNumber: string | null;
  vatNumber: string | null;
  email: string | null;
  phone: string | null;
}

export interface IApplicationDirection {
  id: number;
  name: string;
  code: string;
}

export interface IApplication {
  id: number;
  createdAt: number;
  updatedAt: number;
  submittedAt: number | null;
  confirmedAt: number | null;
  rejectComment: string | null;
  company: IApplicationCompany | null;
  address: IApplicationAddress | null;
  bankAccount: IApplicationBankAccount | null;
  insurance: IApplicationInsurance | null;
  trans_id: string | null;
  recommendations: IApplicationRecommendation[];
  status: IApplicationStatus;
  type: IApplicationType;
  documents: IDocument[];
  user: IUser | null;
  direction: IApplicationDirection;
}

const fetchList = async (
  requestParameters?: IFetchListRequest
): Promise<ApiResource<IApplication[]>> => {
  let params = "";

  if (requestParameters) {
    params = queryString.stringify(requestParameters);
  }

  const response = await API.request<ApiResource<IApplication[]>>(
    `/carrier/applications?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchApplication = async (
  id: string
): Promise<ApiResource<IApplication>> => {
  const response = await API.request<ApiResource<IApplication>>(
    `/carrier/applications/${id}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const storeNewPartner = async (
  id: string,
  application: IApplication
): Promise<ApiResource<IApplication>> => {
  const data = JSON.stringify(application);

  const response = await API.request<ApiResource<IApplication>>(
    `/carrier/applications/new-partner/${id}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    data
  );

  return response;
};

const storeNewPartnerDraft = async (): Promise<ApiResource<IApplication>> => {
  const response = await API.request<ApiResource<IApplication>>(
    `/carrier/applications/new-partner/draft`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const updateNewPartnerDraft = async (
  id: string,
  application: IApplication
): Promise<ApiResource<IApplication>> => {
  const data = JSON.stringify(application);

  const response = await API.request<ApiResource<IApplication>>(
    `/carrier/applications/new-partner/draft/${id}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    data
  );

  return response;
};

const storeExistingPartner = async (
  id: string,
  application: IApplication
): Promise<ApiResource<IApplication>> => {
  const data = JSON.stringify(application);

  const response = await API.request<ApiResource<IApplication>>(
    `/carrier/applications/existing-partner/${id}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    data
  );

  return response;
};

const storeExistingPartnerDraft = async (): Promise<
  ApiResource<IApplication>
> => {
  const response = await API.request<ApiResource<IApplication>>(
    `/carrier/applications/existing-partner/draft`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const updateExistingPartnerDraft = async (
  id: string,
  application: IApplication
): Promise<ApiResource<IApplication>> => {
  const data = JSON.stringify(application);

  const response = await API.request<ApiResource<IApplication>>(
    `/carrier/applications/existing-partner/draft/${id}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    data
  );

  return response;
};

const deleteApplication = async (id: string): Promise<SuccessResponse> => {
  const response = await API.request<ApiResource<SuccessResponse>>(
    `/carrier/applications/${id}/delete`,
    {
      method: HTTPMethod.DELETE,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const deleteDocument = async (
  applicationId: string,
  documentId: string
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/carrier/applications/${applicationId}/document/${documentId}/delete`,
    {
      method: HTTPMethod.DELETE,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const uploadDocuments = async (
  applicationId: string,
  documents: File[]
): Promise<ApiResource<IDocument[]>> => {
  const data = new FormData();

  documents.forEach((file, index) => {
    data.append(`documents[${index}]`, file);
  });

  const response = await API.request<ApiResource<IDocument[]>>(
    `/carrier/applications/${applicationId}/document`,
    {
      method: HTTPMethod.POST,
      version: "v2",
    },
    data
  );

  return response;
};

const updateDocument = async (
  applicationId: string,
  documentId: string,
  data: IUpdateDocumentData
): Promise<ApiResource<IDocument>> => {
  const response = await API.request<ApiResource<IDocument>>(
    `/carrier/applications/${applicationId}/document/${documentId}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const applicationAPI = {
  fetchList,
  fetchApplication,
  storeNewPartner,
  storeNewPartnerDraft,
  updateNewPartnerDraft,
  storeExistingPartner,
  storeExistingPartnerDraft,
  updateExistingPartnerDraft,
  deleteApplication,
  deleteDocument,
  uploadDocuments,
  updateDocument,
};

export { applicationAPI };
