import { FC, useEffect, useState } from "react";
import { Row, Container, Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Cover } from "../cover/Cover";
import { PublicTemplate } from "../layout/PublicTemplate";
import { LanguageSwitcher } from "../translations/LanguageSwitcher";

const NotFound: FC = () => {
  const { t } = useTranslation("notFound");
  const history = useHistory();

  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds <= 0) {
        history.push("/");
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [history, seconds]);

  return (
    <PublicTemplate>
      <Container className="error-page" fluid>
        <Cover />

        <Row className="language-row">
          <LanguageSwitcher />
        </Row>
        <Row className="form-row">
          <Col />
          <Col xs={12} md={6} lg={5} xl={3}>
            <h1>{t("pageNotFound")} 404</h1>
            <p>{t("pageDoesNotExist")}</p>
            <Button onClick={() => history.push("/")}>
              {t("goBack")} ({seconds})
            </Button>
          </Col>
          <Col />
        </Row>
      </Container>
    </PublicTemplate>
  );
};

export { NotFound };
