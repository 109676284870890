import { FC, CSSProperties } from "react";
import { Nav } from "react-bootstrap";
import { LanguageCode } from "../../../i18n";

interface IProps {
  onSelect: (value: string | null) => void;
  style?: CSSProperties;
  defaultLanguage?: LanguageCode;
  languages?: LanguageCode[];
}

const LanguagePills: FC<IProps> = ({
  onSelect,
  style,
  defaultLanguage,
  languages,
}) => (
  <Nav
    variant="pills"
    defaultActiveKey={defaultLanguage || LanguageCode.EN}
    style={style}
    onSelect={onSelect}
  >
    {(languages || Object.values(LanguageCode)).map((language, index) => (
      <Nav.Item key={index}>
        <Nav.Link eventKey={language}>{language}</Nav.Link>
      </Nav.Item>
    ))}
  </Nav>
);

export { LanguagePills };
