import { ApiResource, SuccessResponse, API, HTTPMethod } from ".";
import { ICompany, IRole, IWorkingConditions, Role } from "./profile";
import queryString from "query-string";

export interface IUserShort {
  id: number;
  name: string;
  surname: string;
  email: string;
}
export interface IUser extends IUserShort {
  phone: string;
  roles: IRole<Role>[];
  companies: ICompany[];
  selectedCompany: ICompany | null;
  workingConditions?: IWorkingConditions | null;
}

export interface IFetchUserListRequest {
  search?: string;
  roleIds?: string;
  page?: number;
  perPage?: number;
  includeSelf?: boolean;
}

export interface IUpdateUserRequest {
  roleId: string;
}

export interface IInviteUserRequest {
  email: string;
  roleId: string;
}

export interface IAcceptUserInviteRequest {
  email: string;
  name: string;
  surname: string;
  phone: string;
  password: string;
  password_confirmation: string;
}

const fetchList = async (
  companyId: number,
  requestParameters: IFetchUserListRequest
): Promise<ApiResource<IUser[]>> => {
  let params = "";

  if (requestParameters) {
    params = queryString.stringify({
      ...requestParameters,
      includeSelf: requestParameters.includeSelf ? 1 : 0,
    });
  }

  const response = await API.request<ApiResource<IUser[]>>(
    `/carrier/companies/${companyId}/user?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchUser = async (
  companyId: number,
  userId: string
): Promise<ApiResource<IUser>> => {
  const response = await API.request<ApiResource<IUser>>(
    `/carrier/companies/${companyId}/user/${userId}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const updateUser = async (
  companyId: number,
  userId: number,
  data: IUpdateUserRequest
): Promise<ApiResource<IUser>> => {
  const response = await API.request<ApiResource<IUser>>(
    `/carrier/companies/${companyId}/user/${userId}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const deleteUser = async (
  companyId: number,
  userId: string
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/carrier/companies/${companyId}/user/${userId}`,
    {
      method: HTTPMethod.DELETE,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const inviteUser = async (
  companyId: number,
  data: IInviteUserRequest
): Promise<ApiResource<IUser>> => {
  const response = await API.request<ApiResource<IUser>>(
    `/carrier/companies/${companyId}/invite`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const getInvitation = async (
  email: string,
  token: string
): Promise<ApiResource<IUser>> => {
  const params = queryString.stringify({ email, token });

  const response = await API.request<ApiResource<IUser>>(
    `/users/invite?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const acceptInvitation = async (
  data: IAcceptUserInviteRequest
): Promise<ApiResource<IUser>> => {
  const response = await API.request<ApiResource<IUser>>(
    `/users/invite`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const usersAPI = {
  fetchList,
  fetchUser,
  updateUser,
  deleteUser,
  inviteUser,
  getInvitation,
  acceptInvitation,
};

export { usersAPI };
