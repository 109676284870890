import { Link } from "react-router-dom";
import { GeneralRoute } from "../../../constants/routes";
import { NotificationType } from "../types";
import styles from "../Notification.module.scss";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

interface IProps {
  type: NotificationType | null;
}

export const NotificationTypeTabs = ({ type }: IProps) => {
  const { t } = useTranslation("notifications");

  return (
    <Row>
      <Col>
        <ul className={styles.notificationTabs}>
          <li className={`${styles.tabItem} ${!type ? styles.active : ""}`}>
            <Link to={GeneralRoute.Notifications}>{t("all")}</Link>
          </li>
          {Object.values(NotificationType).map((notificationType) => (
            <li
              key={notificationType}
              className={`${styles.tabItem} ${
                type === notificationType ? styles.active : ""
              }`}
            >
              <Link
                to={`${GeneralRoute.Notifications}?type=${notificationType}`}
              >
                {t(notificationType)}
              </Link>
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};
