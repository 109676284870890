import { ListGroup, Modal } from "react-bootstrap";
import { FaDesktop } from "react-icons/fa";
import { Workspace } from "./store/slice";
import { startCase } from "lodash";
import { useHistory } from "react-router-dom";
import {
  AdminRoute,
  CarrierRoute,
  GeneralRoute,
  SuperAdminRoute,
} from "../../constants/routes";
import { useMemo } from "react";
import { IProfile, Role } from "../../services/api/profile";

export const WorkspaceFirstPage = {
  [Workspace.DeveloperSpace]: SuperAdminRoute.EDI.Upload,
  [Workspace.AdminSpace]: AdminRoute.Users.List,
  [Workspace.CarrierSpace]: CarrierRoute.Orders.List.replace("/:orderId?", ""),
  [Workspace.ProfileSpace]: GeneralRoute.Profile.Show,
};

type Props = {
  isVisible: boolean;
  profile: IProfile | null;
  currentWorkspace: Workspace | null;
  changeWorkspace: (workspace: Workspace) => void;
  onClose: () => void;
};

const WorkspaceSelectModal = ({
  isVisible,
  profile,
  currentWorkspace,
  changeWorkspace,
  onClose,
}: Props) => {
  const history = useHistory();

  const workspaceCodes = useMemo(() => {
    const workspaceCodes = Object.values(Workspace);

    if (profile) {
      const roleCodes = profile.roles.map((role) => role.code);

      return workspaceCodes.filter(
        (workspaceCode) =>
          (workspaceCode === Workspace.DeveloperSpace &&
            roleCodes.includes(Role.SuperAdmin)) ||
          (workspaceCode === Workspace.AdminSpace &&
            roleCodes.includes(Role.Admin)) ||
          (workspaceCode === Workspace.CarrierSpace &&
            (roleCodes.includes(Role.Carrier) ||
              roleCodes.includes(Role.Manager))) ||
          workspaceCode === Workspace.ProfileSpace
      );
    }

    return workspaceCodes;
  }, [profile]);

  return (
    <Modal show={isVisible} onHide={onClose} className="company-select-modal">
      <Modal.Header>
        <Modal.Title>
          <FaDesktop /> Select workspace
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup defaultActiveKey={`#${currentWorkspace}`}>
          {workspaceCodes.map((workspaceCode) => (
            <ListGroup.Item
              key={workspaceCode}
              action
              eventKey={`#${workspaceCode}`}
              onClick={() => {
                setTimeout(() => {
                  changeWorkspace(workspaceCode);

                  history.push(WorkspaceFirstPage[workspaceCode]);

                  onClose();
                }, 50);
              }}
            >
              {startCase(workspaceCode)}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default WorkspaceSelectModal;
