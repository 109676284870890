import { ApiResource, API, HTTPMethod, SuccessResponse } from ".";
import { IUpdateDocumentData } from "../../components/application/form/FileDropdown";
import { ApplicationQuestions } from "../../components/application/form/NewPartnerForm";
import { IDocument } from "./documents";

const submitQuestionnaire = async (
  companyId: string,
  questions: ApplicationQuestions
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/carrier/companies/${companyId}/questionnaires`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(questions)
  );

  return response;
};

const deleteDocument = async (
  companyId: string,
  documentId: string
): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/carrier/companies/${companyId}/documents/${documentId}/delete`,
    {
      method: HTTPMethod.DELETE,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const uploadDocuments = async (
  companyId: string,
  documents: File[]
): Promise<ApiResource<IDocument[]>> => {
  const data = new FormData();

  documents.forEach((file, index) => {
    data.append(`documents[${index}]`, file);
  });

  const response = await API.request<ApiResource<IDocument[]>>(
    `/carrier/companies/${companyId}/documents`,
    {
      method: HTTPMethod.POST,
      version: "v2",
    },
    data
  );

  return response;
};

const updateDocument = async (
  companyId: string,
  documentId: string,
  data: IUpdateDocumentData
): Promise<ApiResource<IDocument>> => {
  const response = await API.request<ApiResource<IDocument>>(
    `/carrier/companies/${companyId}/documents/${documentId}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify(data)
  );

  return response;
};

const companyAPI = {
  submitQuestionnaire,
  deleteDocument,
  uploadDocuments,
  updateDocument,
};

export { companyAPI };
