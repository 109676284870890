import { createSlice } from "@reduxjs/toolkit";

export interface IForgotPasswordState {
  resetEmailSent: boolean;
  passwordReseted: boolean;
}

const initialState: IForgotPasswordState = {
  resetEmailSent: false,
  passwordReseted: false,
};

const forgotPassword = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    sendForgotPassword: (state, action) => {
      //
    },
    resetPassword: (state, action) => {
      //
    },
    setResetEmailSent: (state) => {
      state.resetEmailSent = true;
    },
    setPasswordReseted: (state) => {
      state.passwordReseted = true;
    },
    reset: () => initialState,
  },
});

export { forgotPassword };
