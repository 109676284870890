import {
  take,
  call,
  put,
  fork,
  select,
  takeLatest,
  delay,
} from "typed-redux-saga";
import { profile } from "./slices";
import { loader } from "../../common/loaders/store/slices";
import {
  profileAPI,
  IProfile,
  SapEnv,
  CompanyRole,
} from "../../../services/api/profile";
import { IChangePasswordData } from "../ChangePassword";
import { showError, showSuccess, showWarning } from "../../alert/store/slices";
import { IProfileData } from "../ProfileEdit";
import * as Sentry from "@sentry/react";
import { ApiResource } from "../../../services/api";
import { translated } from "../../../i18n";
import { policies } from "../../policies/store/slices";
import { startCase } from "lodash";
import { AppState } from "../../../store";
import { adminApplications } from "../../admin/applications/store/slices";
import { adminActivityLog } from "../../admin/activity-log/store/slices";
import { adminCompanies } from "../../admin/companies/store/slices";
import { adminDocuments } from "../../admin/documents/store/slices";
import { adminProjects } from "../../admin/projects/store/slices";
import { adminUsers } from "../../admin/users/store/slices";
import { documents } from "../../documents/store/slices";
import { notificationSettings } from "../../notification-settings/store/slices";
import { projectList } from "../../projects/list/store/slices";
import { projectShow } from "../../projects/show/store/slices";
import { settings } from "../../settings/store/slices";
import { userEdit } from "../../users/edit/store/slices";
import { userInvite } from "../../users/invite/store/slices";
import { userList } from "../../users/list/store/slices";
import { userShow } from "../../users/show/store/slices";
import { authAPI } from "../../../services/api/auth";
import { translations } from "../../translations/store/slice";
import { AppModule } from "../../../services/api/settings";
import { PayloadAction } from "@reduxjs/toolkit";
import { policyAPI } from "../../../services/api/policies";
import workspaceSwitcherSlice, {
  Workspace,
} from "../../workspace-switcher/store/slice";
import questionnaireModalSlice from "../../questionnaire-modal/store/slices";

function* profileSaga() {
  yield* takeLatest(profile.actions.setCompanySearch, debounceCompanySearch);
  yield* takeLatest(profile.actions.loadMoreCompanies, fetchCompanies);
  yield* takeLatest(profile.actions.fetchProfile, fetchProfile);
  yield* takeLatest(profile.actions.setPoliciesAccepted, checkMarketingPolicy);
  yield* takeLatest(profile.actions.changeSapEnv, handleChangeSapEnv);
  yield* takeLatest(
    profile.actions.submitMarketingPolicy,
    submitMarketingPolicy
  );
  yield* fork(changePasswordWatcher);
  yield* fork(changeCompanyWatcher);
  yield* fork(editWatcher);
  yield* fork(deleteWatcher);
  yield* fork(logoutProfileWatcher);

  yield* takeLatest(workspaceSwitcherSlice.actions.change, function* () {
    yield call(checkMissingDocuments);
  });

  yield call(tryToFetchProfile);
}

function* debounceCompanySearch() {
  yield* delay(500);

  yield call(fetchCompanies);
}

function* changePasswordWatcher() {
  while (true) {
    const { payload } = yield take(profile.actions.changePassword);

    yield* call(changePassword, payload);
  }
}

function* changeCompanyWatcher() {
  while (true) {
    const {
      payload: { id, appModule },
    } = yield take(profile.actions.changeCompany);

    yield* call(changeCompany, id, appModule);
  }
}

function* editWatcher() {
  while (true) {
    const { payload } = yield take(profile.actions.editProfile);

    yield* call(edit, payload);
  }
}

function* deleteWatcher() {
  while (true) {
    yield take(profile.actions.deleteProfile);

    yield* call(deleteProfile);
  }
}

function* logoutProfileWatcher() {
  while (true) {
    yield take(profile.actions.logoutProfile);

    yield* call(logoutProfile);
  }
}

function* tryToFetchProfile() {
  if (authAPI.hasTokens()) {
    yield put(profile.actions.fetchProfile());
  }
}

function* fetchProfile() {
  const result: ApiResource<IProfile> = yield* call(profileAPI.fetchProfile);

  if (result && result.data) {
    const profileData = result.data;

    yield put(profile.actions.setProfileData(profileData));

    yield call(checkPolicies);
    yield call(checkMarketingPolicy);
    yield call(checkMissingDocuments);

    if (process.env.NODE_ENV === "production") {
      const { name, surname, email } = profileData;

      Sentry.setUser({
        email,
        username: `${name} ${surname}`,
      });
    }
  } else {
    yield put(showError(translated.errorWhileFetchingProfile));

    yield put(profile.actions.logoutProfile());
  }
}

function* edit(data: IProfileData) {
  yield put(loader.actions.startLoader());

  const result = yield* call(profileAPI.editProfile, data);

  if (result && result.data) {
    yield put(showSuccess(translated.profileInformationUpdated));

    yield put(profile.actions.setProfileData(result.data));

    yield put(profile.actions.setProfileEdited());
  } else if (result && result.errors) {
    const firstErrorKey = Object.keys(result.errors)[0];

    yield put(
      showWarning(
        `${startCase(firstErrorKey)}: ${result.errors[firstErrorKey]}`
      )
    );
  } else {
    yield put(showError(translated.errorWhileUpdatingProfile));
  }

  yield put(loader.actions.stopLoader());
}

function* deleteProfile() {
  yield put(loader.actions.startLoader());

  const result = yield* call(profileAPI.deleteProfile);

  if (result && result.success) {
    yield put(showSuccess(translated.profileDeleted));

    yield put(profile.actions.logoutProfile());
  } else {
    yield put(showError(translated.errorWhileDeletingProfile));
  }

  yield put(loader.actions.stopLoader());
}

function* changePassword(data: IChangePasswordData) {
  yield put(loader.actions.startLoader());

  const result = yield* call(profileAPI.changePassword, data);

  if (result && result.success) {
    yield put(profile.actions.setPasswordChanged());

    yield put(showSuccess(translated.passwordChanged));
  } else if (result && result.errors && result.errors.password) {
    yield put(showWarning(result.errors.password[0]));
  } else {
    yield put(showError(translated.errorWhileChangingPassword));
  }

  yield put(loader.actions.stopLoader());
}

function* changeCompany(id: number, appModule: AppModule) {
  const result = yield* call(profileAPI.changeCompany, id);

  if (result && result.data) {
    yield put(
      profile.actions.setCompanyChanged({ data: result.data, appModule })
    );

    yield call(checkMissingDocuments);
  } else if (result && result.errors && result.errors.company) {
    yield put(showError(result.errors.company[0]));
  } else {
    yield put(showError(translated.errorWhileChangingCompany));
  }
}

function* logoutProfile() {
  localStorage.removeItem("jwt_tokens");

  const profileState = yield* select((state: AppState) => state.profile);

  yield put(profile.actions.reset());
  yield put(settings.actions.reset());
  yield put(translations.actions.resetProfileSynced());
  yield put(workspaceSwitcherSlice.actions.reset());

  if (profileState.isCarrier || profileState.isManager) {
    yield put(notificationSettings.actions.reset());
    yield put(policies.actions.reset());
    yield put(projectList.actions.reset());
    yield put(projectShow.actions.reset());
    yield put(userList.actions.reset());
    yield put(userShow.actions.reset());
    yield put(userEdit.actions.reset());
    yield put(userInvite.actions.reset());
    yield put(documents.actions.reset());
  }

  if (profileState.isAdmin) {
    yield put(adminApplications.actions.reset());
    yield put(adminActivityLog.actions.reset());
    yield put(adminDocuments.actions.reset());
    yield put(adminProjects.actions.reset());
    yield put(adminUsers.actions.reset());
    yield put(adminCompanies.actions.reset());
  }

  yield put(profile.actions.reset());
}

function* fetchCompanies() {
  const query = yield* select((state: AppState) => state.profile.companySearch);
  const meta = yield* select((state: AppState) => state.profile.companiesMeta);

  const page = meta ? meta.current_page : 1;

  const result = yield* call(profileAPI.fetchCompanies, query, page);

  if (result && result.data && result.meta) {
    if (page > 1) {
      yield put(profile.actions.addCompanies(result));
    } else {
      yield put(profile.actions.setCompanies(result));
    }
  }

  yield put(profile.actions.setIsCompaniesLoading(false));
}

function* checkPolicies() {
  const profileData = yield* select((state: AppState) => state.profile.data);

  if (!profileData) return null;

  // if ((!profileData.termsPolicyAccepted || !profileData.privacyPolicyAccepted) && profileData.emailVerifiedAt) {
  if (!profileData.termsPolicyAccepted && profileData.emailVerifiedAt) {
    const isModalOpen = yield* select(
      (state: AppState) => state.policies.termsConditionsVisible
    );

    if (!isModalOpen) {
      yield put(policies.actions.toggleTermsConditions());
    }
  }
}

function* checkMarketingPolicy() {
  const profileData = yield* select((state: AppState) => state.profile.data);

  if (!profileData) return null;

  if (
    profileData.marketingPolicySubmitted === null &&
    profileData.termsPolicyAccepted &&
    // profileData.privacyPolicyAccepted &&
    profileData.emailVerifiedAt
  ) {
    const isModalOpen = yield* select(
      (state: AppState) => state.policies.marketingPolicyVisible
    );

    if (!isModalOpen) {
      yield put(policies.actions.toggleMarketingPolicy());
    }
  }
}

function* submitMarketingPolicy({ payload: consent }: PayloadAction<boolean>) {
  const result = yield* call(policyAPI.submitMarketingPolicy, consent);

  if (result && result.success) {
    yield put(profile.actions.setMarketingPolicySubmitted(consent));

    yield put(showSuccess(translated.settingsUpdated));
  } else {
    yield put(showError(translated.errorWhileAcceptingPolicy));
  }
}

function* checkMissingDocuments() {
  const profileData = yield* select((state: AppState) => state.profile.data);
  const currentWorkspace = yield* select(
    (state: AppState) => state.workspace.currentWorkspace
  );

  if (
    (currentWorkspace && currentWorkspace !== Workspace.CarrierSpace) ||
    !profileData?.selectedCompany ||
    !profileData.selectedCompany.showQuestionnairePopup
  )
    return null;

  const termsConditionsVisible = yield* select(
    (state: AppState) => state.policies.termsConditionsVisible
  );
  const marketingPolicyVisible = yield* select(
    (state: AppState) => state.policies.marketingPolicyVisible
  );
  const wizardVisible = yield* select(
    (state: AppState) => state.carrierWizard.isVisible
  );

  const allowedRoles = [CompanyRole.Owner, CompanyRole.Manager];

  const hasRoles =
    profileData.selectedCompany &&
    profileData.selectedCompany.roles &&
    profileData.selectedCompany.roles.every((role) =>
      allowedRoles.includes(role.code)
    );

  if (
    hasRoles &&
    !termsConditionsVisible &&
    !marketingPolicyVisible &&
    !wizardVisible
  ) {
    yield put(questionnaireModalSlice.actions.showPopup(true));
  }
}

function* handleChangeSapEnv({ payload: env }: PayloadAction<SapEnv>) {
  const result = yield* call(profileAPI.changeSapEnv, env);

  if (result && result.success) {
    yield put(showSuccess("Environment updated."));
  } else {
    yield put(showError("Something went wrong."));
  }
}

export { profileSaga };
