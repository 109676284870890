import { FC, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../store";
import { GeneralRoute } from "./routes/GeneralRoute";
import { PublicRoute } from "./routes/PublicRoute";
import { NotFound } from "./error-pages/NotFound";
import { Sidebar } from "./layout/sidebar/Sidebar";
import {
  adminRoutes,
  carrierRoutes,
  generalRoutes,
  publicRoutes,
  superAdminRoutes,
} from "./routes/index";
import { Toaster } from "./alert/Toaster";
import { CarrierRoute } from "./routes/CarrierRoute";
import { SuperAdminRoute } from "./routes/SuperAdminRoute";
import { AdminRoute } from "./routes/AdminRoute";
import { Modals } from "./modals/Modals";
import { RefreshModal } from "./refresh-modal/RefreshModal";
import { startSession } from "../utils/session";
import UpdateBar from "./update-bar/UpdateBar";
import { MaintenanceRedirect } from "./maintenance/MaintenanceRedirect";

const App: FC = () => {
  useEffect(() => {
    startSession();
  }, []);

  return (
    <Router>
      <Provider store={store}>
        <UpdateBar />
        <Toaster />
        <Sidebar />
        <Modals />
        <RefreshModal />
        <MaintenanceRedirect />
        <Switch>
          {publicRoutes.map((route) => (
            <PublicRoute key={route.path} {...route} />
          ))}

          {generalRoutes.map((route) => (
            <GeneralRoute key={route.path} {...route} />
          ))}

          {carrierRoutes.map((route) => (
            <CarrierRoute key={route.path} {...route} />
          ))}

          {superAdminRoutes.map((route) => (
            <SuperAdminRoute key={route.path} {...route} />
          ))}

          {adminRoutes.map((route) => (
            <AdminRoute key={route.path} {...route} />
          ))}

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Provider>
    </Router>
  );
};

export default App;
