import { FC, useCallback, useEffect } from "react";
import { FormControl, ListGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaBuilding } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ICompany } from "../../../services/api/profile";
import { AppModule } from "../../../services/api/settings";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { Loader } from "../../common/loaders/Loader";
import { profile } from "../store/slices";

interface IProps {
  selectedCompany?: ICompany;
  appModule: AppModule;
  onClose: () => void;
}

const CompanySelectModal: FC<IProps> = ({
  selectedCompany,
  appModule,
  onClose,
}) => {
  const { t } = useTranslation("companySelectModal");

  const dispatch = useDispatch();

  const search = useSelector((state) => state.profile.companySearch);
  const companies = useSelector((state) => state.profile.companies);
  const isLoading = useSelector((state) => state.profile.companiesIsLoading);
  const meta = useSelector((state) => state.profile.companiesMeta);

  const changeCompany = useCallback(
    (id: number) => dispatch(profile.actions.changeCompany({ id, appModule })),
    [dispatch, appModule]
  );

  const setSearch = useCallback(
    (query: string) => dispatch(profile.actions.setCompanySearch(query)),
    [dispatch]
  );

  const loadMoreCompanies = useCallback(
    (page: number) => dispatch(profile.actions.loadMoreCompanies(page)),
    [dispatch]
  );

  const resetCompanies = useCallback(
    () => dispatch(profile.actions.resetCompanies()),
    [dispatch]
  );

  useEffect(() => {
    setSearch("");

    return () => {
      resetCompanies();
    };
  }, [setSearch, resetCompanies]);

  if (!selectedCompany) return null;

  return (
    <Modal
      scrollable
      show={true}
      onHide={onClose}
      className="company-select-modal"
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>
          <FaBuilding /> {t("selectCompany")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="company-filter">
          <FormControl
            autoFocus
            placeholder="Search for a company"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>

        <ListGroup defaultActiveKey={`#${selectedCompany.id}`}>
          <ListGroup.Item
            onClick={onClose}
            action
            eventKey={`#${selectedCompany.id}`}
          >
            {selectedCompany.name}
          </ListGroup.Item>

          {companies.map((company, index) => (
            <ListGroup.Item
              action
              key={index}
              eventKey={`#${company.id}`}
              onClick={() => {
                changeCompany(company.id);
                onClose();
              }}
            >
              {company.name}
            </ListGroup.Item>
          ))}

          {isLoading && (
            <ListGroup.Item>
              <Loader isLoading />
            </ListGroup.Item>
          )}
        </ListGroup>

        {!!(meta && meta.current_page < meta.last_page) && (
          <div className="load-more">
            <ButtonLoader
              onClick={() => loadMoreCompanies(meta.current_page + 1)}
              variant="outline-secondary"
              size="sm"
              disabled={isLoading}
            >
              {t("loadMore")}
            </ButtonLoader>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export { CompanySelectModal };
