import { FC, useState } from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { DocumentType, adminEdiAPI } from "../../../services/api/admin/edi";
import { showError } from "../../alert/store/slices";
import { Loader } from "../../common/loaders/Loader";
import { Header } from "../../layout/Header";

export interface IUploadXmlFormData {
  file: FileList;
  documentType: DocumentType;
  documentDate: string;
  documentNumber: string;
  senderCode: string;
  receiverCode: string;
}

const UploadXml: FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);

  const { handleSubmit, register, errors } = useForm<IUploadXmlFormData>();

  const dispatch = useDispatch();
  const onSubmit = async (data: IUploadXmlFormData) => {
    setLoading(true);

    const response = await adminEdiAPI.uploadXml(data);

    setLoading(false);

    if (response && response.data) {
      setResponse(JSON.stringify(response.data, null, 2));
    } else {
      dispatch(showError("Something went wrong while uploading file."));
    }
  };

  if (isLoading) {
    return <Loader isLoading={true} />;
  }

  return (
    <Container fluid>
      <Row>
        <Header title="EDI uploader" languageDisabled />
      </Row>
      {!response && (
        <>
          <Row>
            <p>Upload files to EDI for testing purposes.</p>
          </Row>
          <Row>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                <Form.File.Input
                  accept="text/xml"
                  ref={register({ required: true })}
                  name="file"
                  isInvalid={!!errors.file}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.file?.type === "required" && "Required field."}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Document number</Form.Label>
                <Form.Control
                  placeholder=""
                  ref={register({ required: true })}
                  name="documentNumber"
                  isInvalid={!!errors.documentNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.documentNumber?.type === "required" &&
                    "Required field."}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Document date</Form.Label>
                <Form.Control
                  placeholder="2020-02-20"
                  ref={register({ required: true })}
                  name="documentDate"
                  isInvalid={!!errors.documentDate}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.documentDate?.type === "required" &&
                    "Required field."}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Document type</Form.Label>
                <Form.Control
                  as="select"
                  ref={register({ required: true })}
                  name="documentType"
                  isInvalid={!!errors.documentType}
                >
                  {Object.values(DocumentType).map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.documentType?.type === "required" &&
                    "Required field."}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Sender code</Form.Label>
                <Form.Control
                  ref={register({ required: true })}
                  name="senderCode"
                  isInvalid={!!errors.senderCode}
                />
                <Form.Text className="text-muted">"Axapta"</Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.senderCode?.type === "required" && "Required field."}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Receiver code</Form.Label>
                <Form.Control
                  placeholder=""
                  ref={register({ required: true })}
                  name="receiverCode"
                  isInvalid={!!errors.receiverCode}
                />
                <Form.Text className="text-muted">"CarrierPortal"</Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.receiverCode?.type === "required" &&
                    "Required field."}
                </Form.Control.Feedback>
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Row>
        </>
      )}

      {response && (
        <>
          <Row className="edi-response">
            <div>
              <p>EDI response:</p>
              <pre>{response}</pre>
            </div>
          </Row>
          <Row>
            <Button onClick={() => setResponse(null)}>Send new</Button>
          </Row>
        </>
      )}
    </Container>
  );
};

export { UploadXml };
