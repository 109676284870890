import { FC, useEffect } from "react";
import { Card, Nav } from "react-bootstrap";
import {
  IProject,
  ProjectConfirmStatus,
} from "../../../../services/api/projects";
import { DocumentTab } from "./tabs/DocumentTab";
import { useTranslation } from "react-i18next";
import { OtherDetailsTab } from "./tabs/OtherDetailsTab";
import { ResponsibilitiesTab } from "./tabs/ResponsibilitiesTab";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { projectShow } from "../store/slices";
import { scrollToContent } from "../utils/scroller";
import { ChangeLog } from "../../change-log/ChangeLog";

export enum PanelTab {
  OtherDetails = "otherDetails",
  Responsibilities = "responsibilities",
  Documents = "documents",
  ChangeLog = "changeLog",
}

interface IProps {
  project: IProject;
}

const DetailPanel: FC<IProps> = ({ project }) => {
  const { t } = useTranslation("detailsPanel");

  const tab = useSelector((state) => state.projectShow.tab);

  const dispatch = useDispatch();

  const setTab = useCallback(
    (tab: PanelTab) => dispatch(projectShow.actions.setTab(tab)),
    [dispatch]
  );

  const handleTabClick = (selectedTab: string | null) => {
    setTab(selectedTab as PanelTab);
  };

  useEffect(() => {
    const { confirmStatus, driverName } = project;

    const missingDetails =
      confirmStatus &&
      confirmStatus.code === ProjectConfirmStatus.Confirmed &&
      !driverName;

    if (missingDetails && tab !== PanelTab.Documents) {
      setTab(PanelTab.Responsibilities);

      scrollToContent(100);
    }
    // eslint-disable-next-line
  }, [setTab, project]);

  return (
    <Card className="details-panel">
      <Card.Header>
        <Nav
          fill
          variant="tabs"
          activeKey={tab}
          defaultActiveKey={tab}
          onSelect={handleTabClick}
        >
          <Nav.Item>
            <Nav.Link eventKey={PanelTab.OtherDetails}>
              {t("otherDetails")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={PanelTab.Responsibilities}>
              {t("responsibilities")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={PanelTab.Documents}>{t("documents")}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={PanelTab.ChangeLog}>{t("changeLog")}</Nav.Link>
          </Nav.Item>
        </Nav>
      </Card.Header>
      <Card.Body className="details-body">
        {tab === PanelTab.OtherDetails && <OtherDetailsTab project={project} />}
        {tab === PanelTab.Responsibilities && (
          <ResponsibilitiesTab project={project} />
        )}
        {tab === PanelTab.Documents && <DocumentTab project={project} />}
        {tab === PanelTab.ChangeLog && <ChangeLog project={project} />}
      </Card.Body>
    </Card>
  );
};

export { DetailPanel };
