import queryString from "query-string";
import { API, ApiResource, HTTPMethod } from "..";
import { DocumentType } from "../../../services/api/admin/edi";

export interface IFile {
  id: number;
  documentId: number;
  documentType: DocumentType | null;
  documentDirection: DocumentDirection | null;
  client: EDIClient | null;
  modelId: number | null;
  modelType: string | null;
  fileName: string | null;
  model: any;
  companyAxCode: string | null;
  axStatusCode: string | null;
  createdAt: string;
  updatedAt: string;
}

export enum DocumentDirection {
  Received = "Received",
  Sent = "Sent",
}

export enum EDIClient {
  Carrier = "carrier",
  Admin = "admin",
}

export interface IFetchFilesParams {
  search?: string;
  perPage?: number;
  page?: number;
  documentType?: DocumentType;
  documentDirection?: DocumentDirection;
  client?: EDIClient;
  dateFrom?: string;
  dateTo?: string;
}

const fetchDocuments = async (
  requestParams: IFetchFilesParams
): Promise<ApiResource<IFile[]>> => {
  const params = queryString.stringify({
    ...requestParams,
  });

  const response = await API.request<ApiResource<IFile[]>>(
    `/admin/files?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const downloadDocument = async (
  documentId: number
): Promise<ApiResource<any>> => {
  const response = await API.request<ApiResource<any>>(
    `/admin/files/download/${documentId}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
    }
  );

  return response;
};

const adminFilesAPI = {
  fetchDocuments,
  downloadDocument,
};

export { adminFilesAPI };
