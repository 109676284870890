import { API, ApiResource, HTTPMethod, SuccessResponse } from "..";
import { ClientCompany } from "../projects";

export enum ProjectPolicyCode {
  FirstPolicy = "first_policy",
  SecondPolicy = "second_policy",
}

export enum ProjectPolicyTitleCode {
  AdditionalInformation = "additional_information",
  SpecialConditions = "special_conditions",
  SpecialAndGeneralConditions = "special_and_general_conditions",
  ProceduralRules = "procedural_rules",
}

export type ProjectPolicyGroup = {
  id: number;
  code: ProjectPolicyCode;
};

export type ProjectPolicyTitle = {
  id: number;
  code: ProjectPolicyTitleCode;
  group?: ProjectPolicyGroup;
};

export type ProjectPolicyData = {
  en: string;
  ru: string;
  pl: string;
  lt: string;
};

export type ProjectPolicy = {
  id: number;
  data: ProjectPolicyData;
  is_active: boolean;
  group: ProjectPolicyGroup;
  title: ProjectPolicyTitle;
  client_company: ClientCompany;
  project_count: number;
  created_at?: string;
  updated_at?: string;
};

const fetchPolicies = async (
  clientCompanyId?: number
): Promise<ApiResource<ProjectPolicy[]>> => {
  const response = await API.request<ApiResource<ProjectPolicy[]>>(
    `/admin/project-policies?client_company_id=${clientCompanyId || ""}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchPolicy = async (id: string): Promise<ProjectPolicy> => {
  const response = await API.request<ProjectPolicy>(
    `/admin/project-policies/${id}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const storePolicy = async (
  groupCode: ProjectPolicyCode,
  titleCode: ProjectPolicyTitleCode,
  data: ProjectPolicyData,
  clientCompanyId?: number
): Promise<ApiResource<ProjectPolicy[]>> => {
  const response = await API.request<ApiResource<ProjectPolicy[]>>(
    `/admin/project-policies`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({
      group_code: groupCode,
      title_code: titleCode,
      client_company_id: clientCompanyId ? clientCompanyId : "",
      data,
    })
  );

  return response;
};

const updatePolicy = async (
  id: number,
  groupCode: ProjectPolicyCode,
  titleCode: ProjectPolicyTitleCode,
  clientCompanyId: number,
  data: ProjectPolicyData
): Promise<ProjectPolicy> => {
  const response = await API.request<ProjectPolicy>(
    `/admin/project-policies/${id}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({
      group_code: groupCode,
      title_code: titleCode,
      client_company_id: clientCompanyId,
      data,
    })
  );

  return response;
};

const deletePolicy = async (id: number): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/admin/project-policies/${id}`,
    {
      method: HTTPMethod.DELETE,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const activatePolicy = async (id: number): Promise<ProjectPolicy> => {
  const response = await API.request<ProjectPolicy>(
    `/admin/project-policies/${id}/activate`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const adminProjectPoliciesAPI = {
  fetchPolicies,
  fetchPolicy,
  storePolicy,
  updatePolicy,
  deletePolicy,
  activatePolicy,
};

export { adminProjectPoliciesAPI };
