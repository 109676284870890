import { Container, Row } from "react-bootstrap";
import { Footer } from "./Footer";

type Props = {
  children: React.ReactNode;
};

const OrdersTemplate = ({ children }: Props) => {
  return (
    <Container fluid className="orders-template">
      {children}
      <Row>
        <Footer loggedIn />
      </Row>
    </Container>
  );
};

export { OrdersTemplate };
