import { FC, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

interface IProps {
  initialSearch?: string;
  search: (query: string) => void;
  noMargin?: boolean;
  disabled?: boolean;
}

const NewSearchInput: FC<IProps> = ({
  initialSearch,
  search,
  noMargin,
  disabled,
}) => {
  const { t } = useTranslation("searchInput");
  const location = useLocation();
  const { search: defaultQuery } = queryString.parse(location.search);

  const [query, setQuery] = useState<string>(
    typeof defaultQuery === "string" ? defaultQuery : ""
  );

  useEffect(() => {
    search(query);
  }, [query, search]);

  useEffect(() => {
    if (initialSearch) {
      setQuery(initialSearch);
    }
  }, [initialSearch]);

  return (
    <Form.Group
      className="new-search"
      style={noMargin ? { margin: "0px" } : {}}
    >
      <Form.Control
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        type="text"
        placeholder={t("search") as string}
        disabled={disabled}
      />
      {query.length === 0 && <BsSearch />}
      {query.length > 0 && (
        <FaTimes onClick={() => setQuery("")} style={{ cursor: "pointer" }} />
      )}
    </Form.Group>
  );
};

export { NewSearchInput };
