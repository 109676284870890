import { FC, useCallback } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { adminActivityLog } from "../../store/slices";
import DatePicker from "react-datepicker";
import { FaTimes } from "react-icons/fa";

export interface IDates {
  dateFrom?: string | null;
  dateTo?: string | null;
}

const DateFilter: FC = () => {
  const dateFrom = useSelector((state) => state.adminActivityLog.dateFrom);
  const dateTo = useSelector((state) => state.adminActivityLog.dateTo);

  const dispatch = useDispatch();
  const setDates = useCallback(
    (dates: IDates) => dispatch(adminActivityLog.actions.setDates(dates)),
    [dispatch]
  );

  return (
    <div className="date-filter">
      <div>
        <Form.Control
          onChange={(date) => setDates({ dateFrom: date.toString() })}
          as={DatePicker as any}
          autoComplete="off"
          size="sm"
          selected={dateFrom ? new Date(dateFrom) : undefined}
          showTimeSelect
          dateFormat="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          placeholderText="Date from"
        />
        {dateFrom && <FaTimes onClick={() => setDates({ dateFrom: null })} />}
      </div>
      <div>
        <Form.Control
          onChange={(date) => setDates({ dateTo: date.toString() })}
          as={DatePicker as any}
          autoComplete="off"
          size="sm"
          selected={dateTo ? new Date(dateTo) : undefined}
          showTimeSelect
          dateFormat="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          placeholderText="Date to"
        />
        {dateTo && <FaTimes onClick={() => setDates({ dateTo: null })} />}
      </div>
    </div>
  );
};

export { DateFilter };
