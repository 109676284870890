import React from "react";
import { FC } from "react";

interface IProps {
  activeSpot?: number;
  toggle?: (order: number) => void;
  children: React.ReactNode;
}

const Hotspots: FC<IProps> = ({ activeSpot, children, toggle }) => {
  const totalChildren = (children as any).length;

  const clonedChildren: any = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement<any>(child, {
        toggle,
        activeSpot,
        isVisible: child.props.order === activeSpot,
        showNext: child.props.order !== totalChildren,
        isPulsing: !activeSpot,
      });
    }

    return child;
  });

  return clonedChildren;
};

export { Hotspots };
