import { createSlice } from "@reduxjs/toolkit";
import { INotificationSettings } from "../../../services/api/notificationSettings";

export interface INotificationSettingsState {
  data: INotificationSettings | null;
}

const initialState: INotificationSettingsState = {
  data: null,
};

const notificationSettings = createSlice({
  name: "notificationSettings",
  initialState,
  reducers: {
    fetchSettings: (state) => {
      //
    },
    updateSettings: (state, action) => {
      //
    },
    setSettings: (state, action) => {
      state.data = action.payload;
    },
    reset: () => initialState,
  },
});

export { notificationSettings };
