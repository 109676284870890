import { createSlice } from "@reduxjs/toolkit";
import { uniqueId } from "lodash";
import { AlertProps } from "react-bootstrap";

const generateAlert = (
  variant: AlertProps["variant"],
  message: string,
  tag?: string
): IAlert => ({
  id: uniqueId(),
  message,
  variant,
  tag,
});

interface IAlert {
  id: string;
  message: string;
  variant: AlertProps["variant"];
  tag?: string;
}

export interface ILoaderState {
  queue: IAlert[];
}

const initialState: ILoaderState = {
  queue: [],
};

const alert = createSlice({
  name: "alert",
  initialState,
  reducers: {
    show: (state, action) => {
      const variant = action.payload.variant;
      const message = action.payload.message;
      const tag = action.payload.tag;

      const isDuplicate = state.queue.some(
        (record) => record.message === message
      );

      if (variant && message && !isDuplicate) {
        const alert = generateAlert(variant, message, tag);

        state.queue.push(alert);
      }
    },
    hide: (state, action) => {
      const index = state.queue.findIndex(
        (alert) => alert.id === action.payload
      );

      state.queue.splice(index, 1);
    },
    reset: () => initialState,
  },
});

const show = (variant: AlertProps["variant"], message: string, tag?: string) =>
  alert.actions.show({
    variant,
    message,
    tag,
  });

const showSuccess = (message: string, tag?: string) =>
  show("success", message, tag);
const showError = (message: string, tag?: string) =>
  show("danger", message, tag);
const showWarning = (message: string, tag?: string) =>
  show("warning", message, tag);
const showInfo = (message: string, tag?: string) => show("info", message, tag);

export { alert, showSuccess, showError, showWarning, showInfo };
