import { call, fork, put, select, take } from "typed-redux-saga";
import { translated } from "../../../i18n";
import { settingsAPI } from "../../../services/api/settings";
import { AppState } from "../../../store";
import { showError } from "../../alert/store/slices";
import {
  IProfileState,
  profile,
  profile as profileSlice,
} from "../../profile/store/slices";
import { settings } from "./slices";

function* settingsSaga() {
  yield* fork(fetchProfileWatcher);
  yield* fork(fetchCarrierSettingsWatcher);
}

function* fetchProfileWatcher() {
  while (true) {
    yield take(profileSlice.actions.setProfileData);

    const profile: IProfileState = yield* select(
      (state: AppState) => state.profile
    );

    if (profile && profile.data && profile.data.emailVerifiedAt) {
      if (profile.isCarrier || profile.isManager || profile.isGuest) {
        yield* call(fetchCarrierSettings);
      }
    }
  }
}

function* fetchCarrierSettingsWatcher() {
  while (true) {
    yield take(settings.actions.fetchCarrierSettings);

    yield* call(fetchCarrierSettings);
  }
}

function* fetchCarrierSettings() {
  const result = yield* call(settingsAPI.fetchCarrierSettings);

  if (result && result.general) {
    yield put(settings.actions.setCarrierSettings(result));
  } else {
    yield put(showError(translated.errorWhileDownloadingSettings));

    yield put(profile.actions.logoutProfile());
  }
}

export { settingsSaga, fetchCarrierSettings };
