import { FC, useCallback, useEffect, useMemo } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { AdminRoute } from "../../../constants/routes";
import { Role } from "../../../services/api/profile";
import { Loader } from "../../common/loaders/Loader";
import { Header } from "../../layout/Header";
import { adminUsers } from "./store/slices";
import { showUserEmail } from "./utils/helpers";

export interface IEditRoleForm {
  roleId: string;
  isCarrierAdmin: boolean;
}

const UserRoleEdit: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const user = useSelector((state) => state.adminUsers.userData);
  const roles = useSelector((state) => state.adminUsers.roles);
  const updatedUser = useSelector((state) => state.adminUsers.updatedUser);

  const history = useHistory();

  const { register, handleSubmit, watch, reset } = useForm<IEditRoleForm>();

  const userEmail = useMemo(() => showUserEmail(user), [user]);

  const dispatch = useDispatch();
  const fetchRoles = useCallback(
    (userId: string) => dispatch(adminUsers.actions.fetchUserRoles({ userId })),
    [dispatch]
  );
  const changeRole = useCallback(
    (userId: string, data: IEditRoleForm) =>
      dispatch(adminUsers.actions.changeRole({ userId, data })),
    [dispatch]
  );
  const resetRoles = useCallback(
    () => dispatch(adminUsers.actions.resetUserRoles()),
    [dispatch]
  );
  const resetUpdatedUser = useCallback(
    () => dispatch(adminUsers.actions.resetUpdatedUser()),
    [dispatch]
  );
  const resetUser = useCallback(
    () => dispatch(adminUsers.actions.resetUser()),
    [dispatch]
  );

  const onSubmit = (data: IEditRoleForm) => {
    changeRole(userId, data);
  };

  useEffect(() => {
    fetchRoles(userId);

    return () => {
      resetRoles();
      resetUpdatedUser();
      resetUser();
    };
  }, [userId, fetchRoles, resetRoles, resetUpdatedUser, resetUser]);

  const departmentRoles = useMemo(() => {
    if (user) {
      const roles = user.roles.filter((role) => role.name.includes("roles."));

      return roles.length > 0 ? roles : [];
    }

    return [];
  }, [user]);

  const carrierRole = useMemo(() => {
    if (roles) {
      return roles.find((role) => role.code === Role.Carrier);
    }

    return null;
  }, [roles]);

  useEffect(() => {
    if (updatedUser) {
      history.push(AdminRoute.Users.List.replace(":userId", userId));
    }
  }, [updatedUser, userId, history]);

  const isCarrierAdmin = watch("isCarrierAdmin");
  const roleId = Number(watch("roleId"));

  const showCompanyRoleWarning = useMemo(() => {
    const carrierRole = roles.find((role) => role.code === Role.Carrier);

    if (
      carrierRole &&
      user &&
      carrierRole.id === roleId &&
      user.roles.length > 0 &&
      user.roles[0].code !== Role.Carrier
    ) {
      return true;
    }

    return false;
  }, [roles, roleId, user]);

  useEffect(() => {
    if (user && user.roles.length > 0 && !roleId) {
      const primaryRole = user.roles.find(
        (role) =>
          !role.name.includes("roles.") && role.code !== Role.CarrierAdmin
      );
      const isCarrierAdmin = !!user.roles.find(
        (role) => role.code === Role.CarrierAdmin
      );

      if (primaryRole) {
        reset({ roleId: primaryRole.id.toString(), isCarrierAdmin });
      }
    }
  }, [user, roles, reset, roleId]);

  return (
    <Container fluid className="user-role">
      <Row>
        <Header title="User role" subTitle={userEmail} languageDisabled />
      </Row>
      <Row>
        {user && departmentRoles.length > 0 ? (
          <div className="text-danger">
            <p>In order to change primary role, departments must be removed.</p>
            <Link
              to={`${AdminRoute.Users.EditDepartmentRoles.replace(
                ":userId",
                user.id.toString()
              )}`}
            >
              <Button>Manage departments</Button>
            </Link>
          </div>
        ) : user && roles.length > 0 ? (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Form.Group as={Col} controlId="roleId">
                <Form.Label>Primary role</Form.Label>
                <Form.Control
                  as="select"
                  name="roleId"
                  ref={register({ required: true })}
                  disabled={isCarrierAdmin}
                >
                  {roles.map((role, index) => (
                    <option key={index} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Control>
                {showCompanyRoleWarning && (
                  <Form.Text className="text-warning">
                    "Viewer" role will be assigned for companies without roles.
                  </Form.Text>
                )}

                <div
                  className="custom-control custom-control-right custom-switch"
                  style={{ zIndex: 0, marginTop: "15px" }}
                >
                  <input
                    id="isCarrierAdmin"
                    name="isCarrierAdmin"
                    type="checkbox"
                    className="custom-control-input"
                    ref={register}
                    disabled={!!(carrierRole && carrierRole.id !== roleId)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="isCarrierAdmin"
                  >
                    Carrier administrator
                  </label>
                  <Form.Text>User will be invisible in users list.</Form.Text>
                </div>
              </Form.Group>
            </Row>

            <Button
              type="submit"
              style={{
                minWidth: "100px",
              }}
            >
              Change
            </Button>
          </Form>
        ) : (
          <Loader />
        )}
      </Row>
    </Container>
  );
};

export { UserRoleEdit };
