import classNames from "classnames";
import { FC, Fragment, useCallback } from "react";
import { Tooltip as TooltipOriginal, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFile, FaCheck, FaStopwatch } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  IProjectShort,
  ProjectConfirmStatus,
} from "../../../../services/api/projects";
import {
  formatUTCDateTime,
  formatUnixDateTime,
  formatUnixDate,
  formatUTCDate,
} from "../../../../utils/date-format";
import { ButtonLoader } from "../../../common/button-loader/ButtonLoader";
import { PanelTab } from "../../show/details/DetailPanel";
import { projectShow } from "../../show/store/slices";
import { IProjectListGroupState } from "../store/slices";
import { Tooltip } from "../Tooltip";
import { getStatusColor, ListColumn } from "./ProjectTable";

interface IListGroupProps {
  group: IProjectListGroupState;
  selectedProjectId: number;
  columns: ListColumn[];
  setQuickPayProject?: (project: IProjectShort | null) => void;
  handleActiveRow: (id: number) => void;
  fetchMore: (status: ProjectConfirmStatus) => void;
  isRightPanelOpen?: boolean;
}

const ListGroup: FC<IListGroupProps> = ({
  group,
  selectedProjectId,
  columns,
  handleActiveRow,
  setQuickPayProject,
  fetchMore,
  isRightPanelOpen,
}) => {
  const { t } = useTranslation("projects");

  const dispatch = useDispatch();

  const setTab = useCallback(
    (tab: PanelTab) => dispatch(projectShow.actions.setTab(tab)),
    [dispatch]
  );

  const handleDocumentClick = () => {
    setTimeout(() => {
      setTab(PanelTab.Documents);

      const panelDiv = document.getElementById("panel-content");

      if (panelDiv) {
        panelDiv.scrollTop = panelDiv.scrollHeight;
      }
    }, 1000);
  };

  return (
    <>
      <div className="project-group">
        <span>{t(group.id)}</span>
      </div>
      {group.data.map((project, index) => {
        const {
          isQuickPayVisible,
          isQuickPayEnabled,
          isQuickPaySubmitted,
          acceptedCreditInvoiceExists,
          confirmStatus,
          refNumber,
          paymentUntil,
          archivedBy,
          archivedAt,
          clientCompany,
        } = project;

        if (!confirmStatus) return null;

        const statusColor = getStatusColor(confirmStatus.code);

        const documents = project.documents
          ? project.documents.filter(
              (document) =>
                document.status && document.type && document.type.group
            )
          : [];

        return (
          <Fragment key={index}>
            <div
              className="client-company-wrapper"
              style={{ borderBottom: `3px solid ${statusColor}` }}
            >
              <div
                className="client-company"
                style={{ backgroundColor: statusColor }}
              >
                {clientCompany.name}
              </div>
            </div>
            <div
              key={index}
              className={classNames(
                "list-item",
                selectedProjectId === project.id && "active-row"
              )}
              onClick={() => handleActiveRow(project.id)}
            >
              {archivedAt && (
                <Tooltip
                  id={`tooltip-archive-badge-${project.id}`}
                  text={`${t("archived")}: ${
                    archivedBy && archivedAt
                      ? `${archivedBy.name} ${
                          archivedBy.surname
                        } ${formatUTCDateTime(archivedAt)}`
                      : archivedAt
                      ? formatUTCDateTime(archivedAt)
                      : ""
                  }`}
                >
                  <div
                    className="archived-badge"
                    style={{ backgroundColor: statusColor }}
                  >
                    {t("archived")}
                  </div>
                </Tooltip>
              )}

              <div className="main-content">
                {columns.map(({ ContentColumn, width }, index) => (
                  <ContentColumn key={index} data={project} width={width} />
                ))}
              </div>

              <div className="bottom-content">
                <div>
                  {refNumber && (
                    <span className="ref-nr" style={{ fontWeight: "normal" }}>
                      {t("refNo")} {refNumber}
                    </span>
                  )}
                  {refNumber && <span style={{ marginLeft: "20px" }}>|</span>}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div className="documents">
                    {documents.length > 0 ? (
                      documents.map((document, index) => {
                        if (
                          !document.status ||
                          !document.type ||
                          (document.type && !document.type.group)
                        ) {
                          return null;
                        }

                        const documentName = document.type.name;

                        return (
                          <div
                            key={index}
                            className="bottom-doc"
                            onClick={handleDocumentClick}
                          >
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <TooltipOriginal id={document.id.toString()}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      {t("uploaded")}:{" "}
                                      {formatUnixDateTime(document.createdAt)}
                                    </span>
                                    {document.paymentUntil && (
                                      <span>
                                        {t("payment")}:{" "}
                                        {formatUnixDate(document.paymentUntil)}
                                      </span>
                                    )}
                                  </div>
                                </TooltipOriginal>
                              }
                            >
                              <span>
                                <FaFile
                                  className={`doc-${document.status.code}`}
                                />{" "}
                                {documentName}
                              </span>
                            </OverlayTrigger>
                          </div>
                        );
                      })
                    ) : (
                      <span className="no-documents">
                        {t("noDocumentsUploaded")}
                      </span>
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    {paymentUntil &&
                    [
                      ProjectConfirmStatus.Confirmed,
                      ProjectConfirmStatus.Completed,
                    ].includes(confirmStatus.code) ? (
                      <div className="payment-date">
                        {t("payment")}: {formatUTCDate(paymentUntil)}
                      </div>
                    ) : (
                      <div />
                    )}
                    {isQuickPayVisible && (
                      <div
                        className={classNames(
                          "quick-payment",
                          isQuickPayEnabled && !isQuickPaySubmitted
                            ? "bg-primary"
                            : isQuickPaySubmitted
                            ? acceptedCreditInvoiceExists
                              ? "bg-success"
                              : "bg-secondary"
                            : "inactive-qp-button"
                        )}
                        style={{
                          cursor:
                            !isQuickPayEnabled && !isQuickPaySubmitted
                              ? "default"
                              : "pointer",
                        }}
                        onClick={(event) => {
                          if (
                            isRightPanelOpen ||
                            (!isQuickPayEnabled && !isQuickPaySubmitted)
                          ) {
                            event.stopPropagation();
                          }

                          if (isQuickPaySubmitted) {
                            handleDocumentClick();

                            return;
                          }

                          if (!isQuickPayEnabled) return;

                          if (setQuickPayProject) {
                            setQuickPayProject(project);
                          }
                        }}
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <TooltipOriginal id={`quick-payment-${project.id}`}>
                              {isQuickPaySubmitted
                                ? t("quickPaymentTooltipSubmitted")
                                : isQuickPayEnabled
                                ? t("quickPaymentTooltipUploadOrGenerate")
                                : t("quickPaymentTooltip")}
                            </TooltipOriginal>
                          }
                        >
                          <span>
                            {isQuickPaySubmitted ? (
                              <FaCheck />
                            ) : (
                              <FaStopwatch />
                            )}{" "}
                            {t("quickPayment")}
                          </span>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        );
      })}
      {!!(group.meta && group.meta?.current_page < group.meta?.last_page) && (
        <div className="load-more">
          <ButtonLoader
            onClick={() => fetchMore(group.id)}
            variant="outline-secondary"
            size="sm"
            disabled={group.isLoading}
          >
            {t("loadMore")}
          </ButtonLoader>
        </div>
      )}
    </>
  );
};

export default ListGroup;
