const Cover = () => {
  return (
    <>
      <img
        className="login-background"
        src="/images/login-background.svg"
        alt="login-background"
      />
    </>
  );
};

export { Cover };
