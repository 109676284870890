import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FaInfoCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { LanguageCode } from "../../../../../i18n";
import { IProject } from "../../../../../services/api/projects";
import { Tooltip } from "../../../list/Tooltip";
import { DocumentLinks } from "../../DocumentLinks";

interface IProps {
  project: IProject;
}

const OtherDetailsTab: FC<IProps> = ({ project }) => {
  const { t } = useTranslation("otherDetailsTab");

  const profile = useSelector((state) => state.profile.data);

  const language = profile ? profile.language : LanguageCode.EN;

  const title = project.firstPolicy ? project.firstPolicy.title.code : "";
  const text = project.firstPolicy ? project.firstPolicy.data[language] : "";

  return (
    <div className="other-details-tab">
      <div>
        <div className="detail">
          <span>
            {t(title, {
              ns: "policies",
            })}
          </span>
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </div>
        {project.specialPaymentTerm && (
          <div
            className="detail"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span>{t("specialPaymentTerm")}:</span>
            <span>{project.specialPaymentTerm.replace(/[^0-9]/g, "")}</span>
            <Tooltip
              id={`tooltip-pallets-column`}
              text={t("conditionApplies")}
              placement="right"
            >
              <FaInfoCircle
                className="text-primary"
                style={{
                  fontSize: "14px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </div>
        )}
        {project.crmPaymentNote && (
          <div className="detail">
            <span>{t("crmPaymentNote")}</span>
            <span>{project.crmPaymentNote}</span>
          </div>
        )}
      </div>
      <DocumentLinks project={project} defaultLanguage={language} />
    </div>
  );
};

export { OtherDetailsTab };
