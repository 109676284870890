import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import MarketingPolicyModal from "../policies/modals/MarketingPolicyModal";
import { PrivacyPolicyModal } from "../policies/modals/PrivacyPolicyModal";
import { TermsAndConditionsModal } from "../policies/modals/TermsAndConditionsModal";
import { policies } from "../policies/store/slices";
import { CarrierWizardModal } from "../wizards/carrier/CarrierWizardModal";
import { carrierWizard } from "../wizards/carrier/store/slices";
import QuestionnaireModal from "../questionnaire-modal/QuestionnaireModal";
import questionnaireModalSlice from "../questionnaire-modal/store/slices";

const Modals: FC = () => {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile);

  const user = profile.data;

  const termsConditionsVisible = useSelector(
    (state) => state.policies.termsConditionsVisible
  );
  const privacyPolicyVisible = useSelector(
    (state) => state.policies.privacyPolicyVisible
  );
  const carrierWizardVisible = useSelector(
    (state) => state.carrierWizard.isVisible
  );
  const marketingPolicyVisible = useSelector(
    (state) => state.policies.marketingPolicyVisible
  );
  const misingDocumentsVisible = useSelector(
    (state) => state.questionnaireModal.isVisible
  );

  const acceptPolicies = useCallback(
    () => dispatch(policies.actions.acceptPolicies()),
    [dispatch]
  );

  const submitMarketingPolicy = useCallback(
    (consent: boolean) =>
      dispatch(policies.actions.submitMarketingPolicy(consent)),
    [dispatch]
  );

  const togglePrivacyPolicy = useCallback(
    () => dispatch(policies.actions.togglePrivacyPolicy()),
    [dispatch]
  );

  const toggleMarketingPolicy = useCallback(
    () => dispatch(policies.actions.toggleMarketingPolicy()),
    [dispatch]
  );

  const toggleTermsConditions = useCallback(
    () => dispatch(policies.actions.toggleTermsConditions()),
    [dispatch]
  );

  const toggleCarrierWizard = useCallback(
    () => dispatch(carrierWizard.actions.toggle()),
    [dispatch]
  );

  const checkSeenCarrierWizard = useCallback(
    () => dispatch(carrierWizard.actions.checkSeen()),
    [dispatch]
  );

  const showMissingDocoumentsPopup = useCallback(
    (show: boolean) =>
      dispatch(questionnaireModalSlice.actions.showPopup(show)),
    [dispatch]
  );

  return (
    <>
      <TermsAndConditionsModal
        isVisible={termsConditionsVisible}
        // handleDeny={
        //   user && (!user.termsPolicyAccepted || !user.privacyPolicyAccepted) ? undefined : toggleTermsConditions
        // }
        // handleConfirm={
        //   user && (!user.termsPolicyAccepted || !user.privacyPolicyAccepted) ? acceptPolicies : undefined
        // }
        handleDeny={
          user && !user.termsPolicyAccepted ? undefined : toggleTermsConditions
        }
        handleConfirm={
          user && !user.termsPolicyAccepted ? acceptPolicies : undefined
        }
      />
      <PrivacyPolicyModal
        isVisible={privacyPolicyVisible}
        handleDeny={() => {
          togglePrivacyPolicy();

          if (user && !user.termsPolicyAccepted) {
            toggleTermsConditions();
          }
        }}
      />
      <MarketingPolicyModal
        isVisible={marketingPolicyVisible}
        handleDeny={() => {
          toggleMarketingPolicy();
        }}
        handleConfirm={
          user && user.marketingPolicySubmitted === null
            ? submitMarketingPolicy
            : undefined
        }
      />
      <CarrierWizardModal
        isVisible={carrierWizardVisible}
        handleDeny={() => {
          toggleCarrierWizard();
          checkSeenCarrierWizard();
        }}
      />

      <QuestionnaireModal
        isVisible={misingDocumentsVisible}
        handleDeny={() => showMissingDocoumentsPopup(false)}
      />
    </>
  );
};

export { Modals };
