import { FC, useCallback, useEffect, useMemo } from "react";
import { Alert, Button, Col, Form, Modal } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../../../../common/button-loader/ButtonLoader";
import { projectShow } from "../../store/slices";
import { useTranslation } from "react-i18next";
import { DateTimeInput } from "../../../../common/datetime-input/DateTimeInput";
import { ProjectLocationTypeCode } from "../../../../../services/api/projects";

export interface IConfirmationForm<T = Date> {
  status: number;
  dateFrom: T;
  dateTo: T;
  latitude: number;
  longtitude: number;
  comment: string;
}

const ConfirmPositionModal: FC = () => {
  const { t } = useTranslation("projectStatusConfirmModal");

  const project = useSelector((state) => state.projectShow.data);
  const selectedPosition = useSelector(
    (state) => state.projectShow.selectedPositon
  );
  const isLoading = useSelector((state) => state.projectShow.isLoading);
  const isVisible = useSelector(
    (state) => state.projectShow.isConfirmationVisible
  );
  const selectedLocationId = useSelector(
    (state) => state.projectShow.selectedLocationId
  );
  const settings = useSelector((state) => state.settings.carrier);

  const dispatch = useDispatch();

  const setConfirmationVisible = useCallback(
    (visible: boolean) =>
      dispatch(projectShow.actions.setConfirmationVisible(visible)),
    [dispatch]
  );

  const confirmStatus = useCallback(
    (projectId: number, data: IConfirmationForm) =>
      dispatch(projectShow.actions.confirmStatus({ projectId, data })),
    [dispatch]
  );

  const { register, watch, errors, handleSubmit, control, setValue } = useForm<
    IConfirmationForm
  >();

  const hasAtTheLoadingPlaceStatus = project
    ? !!project.confirmedLocations.find(
        (location) =>
          location.type.axCode === ProjectLocationTypeCode.LoadingPlace
      )
    : false;

  const statuses = useMemo(() => {
    if (settings && project) {
      const mostImportant = [
        "ATV_I_PAKR",
        "PAKRAUTA",
        "ATV_I_ISKR",
        "ISKRAUTA",
      ];

      const important = ["VAZIUOJA", "MUITINE", "SIENA"];

      type MappedStatuses = (
        | { id?: number; value: string; axCode?: string; disabled?: boolean }
        | undefined
      )[];

      const mappedStatuses: MappedStatuses = Object.values(
        settings.projects.locationStatuses
      ).map(({ id, name, axCode }) => ({
        id,
        value: name,
        axCode,
        disabled:
          !hasAtTheLoadingPlaceStatus &&
          axCode !== ProjectLocationTypeCode.LoadingPlace,
      }));

      const withoutImportant = mappedStatuses.filter(
        (status) =>
          status &&
          status.axCode &&
          !mostImportant.concat(important).includes(status.axCode)
      );

      const statusSorted: MappedStatuses = [
        { value: t("chooseStatus") as string, disabled: true },
        mappedStatuses.find(
          (status) => status && status.axCode === mostImportant[0]
        ),
        mappedStatuses.find(
          (status) => status && status.axCode === mostImportant[1]
        ),
        mappedStatuses.find(
          (status) => status && status.axCode === mostImportant[2]
        ),
        mappedStatuses.find(
          (status) => status && status.axCode === mostImportant[3]
        ),
        { value: "──────────", disabled: true },
        mappedStatuses.find(
          (status) => status && status.axCode === important[0]
        ),
        mappedStatuses.find(
          (status) => status && status.axCode === important[1]
        ),
        mappedStatuses.find(
          (status) => status && status.axCode === important[2]
        ),
        { value: "──────────", disabled: true },
        ...withoutImportant,
      ];

      return statusSorted.map((type, index) =>
        type ? (
          <option key={index} value={type.id} disabled={type.disabled}>
            {type.value}
          </option>
        ) : null
      );
    }
  }, [project, settings, t, hasAtTheLoadingPlaceStatus]);

  useEffect(() => {
    if (isVisible && selectedLocationId && project) {
      let selectedLocation = null;

      selectedLocation = project.plannedLocations.find(
        (location) => location.id === selectedLocationId
      );

      if (selectedLocation) {
        setValue("status", selectedLocation.type.id);
      }
    }
  }, [project, selectedLocationId, setValue, isVisible]);

  const handleLocationSubmit = (data: IConfirmationForm) => {
    if (project && project.id) {
      if (selectedLocationId) {
        let selectedLocation = null;

        selectedLocation = project.plannedLocations.find(
          (location) => location.id === selectedLocationId
        );

        if (selectedLocation) {
          confirmStatus(project.id, {
            ...data,
            latitude: Number(selectedLocation.latitude),
            longtitude: Number(selectedLocation.longtitude),
          });
        }
      } else if (selectedPosition) {
        confirmStatus(project.id, {
          ...data,
          latitude: selectedPosition.lat(),
          longtitude: selectedPosition.lng(),
        });
      }
    }
  };

  return (
    <Modal
      show={isVisible}
      onHide={() => setConfirmationVisible(false)}
      className="confirm-position-modal"
      backdrop="static"
    >
      <Form onSubmit={handleSubmit(handleLocationSubmit)}>
        <Modal.Header className="no-bottom-border">
          <Modal.Title>
            <FaMapMarkerAlt />
            {selectedLocationId
              ? t("confirmPlannedPosition")
              : t("confirmNewPosition")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!hasAtTheLoadingPlaceStatus && (
            <Alert variant="warning">
              {t("otherCargoStatesCanBeMarkedOnly")}
            </Alert>
          )}

          <Form.Row>
            <Form.Group as={Col} sm={12} controlId="status">
              <Form.Label>{t("positionStatus")}</Form.Label>
              <Form.Control
                name="status"
                as="select"
                ref={register({ required: true })}
                isInvalid={!!errors.status}
                defaultValue=""
              >
                {statuses}
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                {errors.status?.type === "required" && t("requiredField")}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={12} controlId="dateFrom">
              <Form.Label>
                {t("statusDate")} ({t("cargoLocationTime")})
              </Form.Label>
              <DateTimeInput
                name="dateFrom"
                isInvalid={!!errors.dateFrom}
                showTimeSelect
                selected={watch(`dateFrom`)}
                timeIntervals={15}
                timeCaption={t("time")}
                dateFormat="yyyy-MM-dd HH:mm"
                className="date-input"
                control={control}
                rules={{ required: true }}
              />

              {errors.dateFrom && (
                <div className="text-danger">
                  <small>{t("requiredField")}</small>
                </div>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={12} controlId="comment">
              <Form.Label>{t("comment")}</Form.Label>
              <Form.Control
                name="comment"
                as="textarea"
                rows={5}
                ref={register}
                maxLength={1000}
              />

              <Form.Control.Feedback type="invalid">
                {errors.comment?.type === "required" && t("requiredField")}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setConfirmationVisible(false)}
          >
            {t("cancel")}
          </Button>
          <ButtonLoader variant="primary" type="submit" disabled={isLoading}>
            {t("confirm")}
          </ButtonLoader>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export { ConfirmPositionModal };
