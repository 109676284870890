import { API, ApiResource, HTTPMethod } from ".";

export interface IMaintenance {
  date: string;
  durationHours: number;
  enabled: boolean;
}

const check = async (): Promise<ApiResource<IMaintenance[]>> => {
  const response = await API.request<ApiResource<IMaintenance[]>>(
    `/maintenance`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const maintenanceAPI = {
  check,
};

export { maintenanceAPI };
