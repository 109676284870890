import { FC, MutableRefObject } from "react";
import { Button, Spinner, ButtonProps } from "react-bootstrap";

interface IProps extends ButtonProps {
  onClick?: () => void;
  innerRef?: MutableRefObject<null>;
  buttonDisabled?: boolean;
  form?: string;
}

const ButtonLoader: FC<IProps> = (props) => {
  const { buttonDisabled, ...rest } = props;

  return (
    <Button
      {...rest}
      ref={rest.innerRef}
      onClick={rest.onClick}
      disabled={buttonDisabled}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "38px",
      }}
    >
      {rest.disabled ? (
        <Spinner
          animation="border"
          role="status"
          size={rest.size === "lg" ? undefined : "sm"}
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        rest.children
      )}
    </Button>
  );
};

export { ButtonLoader };
