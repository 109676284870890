import { API, HTTPMethod } from ".";
import { IApplicationDirection } from "./application";
import { IDocumentGroup, IDocumentStatus, IDocumentType } from "./documents";
import { NotificationInterval } from "./notificationSettings";
import { CompanyRole, IRole, Role } from "./profile";
import { IProjectLocationType, IProjectConfirmStatus } from "./projects";

export interface ICountry {
  code: string;
  name: string;
}

export interface ICurrency {
  code: string;
  name: string;
}

export enum AppModule {
  General = "general",
  Applications = "applications",
  Profile = "profile",
  Users = "users",
  Orders = "orders",
  Projects = "projects",
  Documents = "documents",
  MailSettings = "mail-settings",
}

export enum AdminAppModule {
  Applications = "applications",
  Users = "users",
  Projects = "projects",
  Orders = "orders",
  Documents = "documents",
  GeneralPolicies = "general-policies",
  ProjectPolicies = "project-policies",
  StatisticPolicies = "statistic-policies",
  Edi = "edi",
  ActivityLog = "activity-log",
  Cache = "cache",
  Sessions = "sessions",
  Queues = "queues",
  Logs = "logs",
  Mailing = "mailing",
  Companies = "companies",
  ClientCompanies = "client-companies",
  Maintenance = "maintenance",
  Files = "files",
}

export interface ICarrierSettings {
  [AppModule.General]: {
    maxUploadSize: number; // KB
    countries: ICountry[];
    currencies: ICurrency[];
  };
  [AppModule.Applications]: {
    documentTypes: IDocumentType[];
    directions: IApplicationDirection[];
  };
  [AppModule.Users]: {
    roles: IRole<Role>[];
    companyRoles: IRole<CompanyRole>[];
  };
  [AppModule.Documents]: {
    statuses: IDocumentStatus[];
    groups: IDocumentGroup[];
    types: IDocumentType[];
  };
  [AppModule.Projects]: {
    locationStatuses: IProjectLocationType[];
    confirmStatuses: IProjectConfirmStatus[];
  };
  ["notifications"]: {
    types: string[];
    intervals: NotificationInterval[];
  };
}

const fetchCarrierSettings = async (): Promise<ICarrierSettings> => {
  const response = await API.request<ICarrierSettings>(`/carrier/settings`, {
    method: HTTPMethod.GET,
    version: "v2",
    jsonRequest: true,
  });

  return response;
};

const settingsAPI = {
  fetchCarrierSettings,
};

export { settingsAPI };
