import { FC, useCallback, useEffect, useMemo } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AdminRoute } from "../../../constants/routes";
import { Loader } from "../../common/loaders/Loader";
import { Header } from "../../layout/Header";
import { adminUsers } from "./store/slices";
import { showUserEmail } from "./utils/helpers";
import Select, { StylesConfig } from "react-select";
import { Option } from "react-select/src/filters";

const selectStyle: StylesConfig<any, false> = {
  control: (provided) => ({
    ...provided,
    width: "300px",
  }),
};

interface IEditDepartmentForm {
  roles: Option[];
}

const DepartmentRoleEdit: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const user = useSelector((state) => state.adminUsers.userData);
  const departments = useSelector((state) => state.adminUsers.departments);
  const updatedUser = useSelector((state) => state.adminUsers.updatedUser);

  const history = useHistory();

  const { control, handleSubmit } = useForm<IEditDepartmentForm>();

  const userEmail = useMemo(() => showUserEmail(user), [user]);

  const dispatch = useDispatch();
  const fetchRoles = useCallback(
    (userId: string) =>
      dispatch(adminUsers.actions.fetchDepartmentRoles({ userId })),
    [dispatch]
  );
  const changeRole = useCallback(
    (userId: string, roleIds: string[]) =>
      dispatch(
        adminUsers.actions.changeDepartmentRole({
          userId,
          roleIds,
        })
      ),
    [dispatch]
  );
  const resetRoles = useCallback(
    () => dispatch(adminUsers.actions.resetDepartments()),
    [dispatch]
  );
  const resetUpdatedUser = useCallback(
    () => dispatch(adminUsers.actions.resetUpdatedUser()),
    [dispatch]
  );
  const resetUser = useCallback(
    () => dispatch(adminUsers.actions.resetUser()),
    [dispatch]
  );

  const onSubmit = (data: IEditDepartmentForm) => {
    const roleIds =
      data && data.roles ? data.roles.map((role) => role.value) : [];

    changeRole(userId, roleIds);
  };

  useEffect(() => {
    fetchRoles(userId);

    return () => {
      resetRoles();
      resetUpdatedUser();
      resetUser();
    };
  }, [userId, fetchRoles, resetRoles, resetUpdatedUser, resetUser]);

  useEffect(() => {
    if (updatedUser) {
      history.push(AdminRoute.Users.List.replace(":userId", userId));
    }
  }, [updatedUser, userId, history]);

  const options = useMemo(() => {
    return departments.map((department) => ({
      label: department.name.replace("roles.", ""),
      value: department.id,
    }));
  }, [departments]);

  const defaultOptions = useMemo(() => {
    if (user) {
      return (
        user.roles
          // If doesn't have translation
          // that mean it's department role
          .filter((role) => role.name.includes("roles."))
          .map((role) => ({
            label: role.name.replace("roles.", ""),
            value: role.id,
          }))
      );
    }

    return [];
  }, [user]);

  return (
    <Container fluid className="user-role">
      <Row>
        <Header
          title="Department leader role"
          subTitle={userEmail}
          languageDisabled
        />
      </Row>
      <Row>
        {user && departments.length > 0 ? (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Form.Group as={Col} controlId="roleId">
                <Controller
                  name="roles"
                  as={Select as any}
                  isMulti
                  cacheOptions
                  options={options}
                  control={control}
                  placeholder="Select departments"
                  styles={selectStyle}
                  defaultValue={defaultOptions}
                />
              </Form.Group>
            </Row>

            <Button
              type="submit"
              style={{
                minWidth: "100px",
              }}
            >
              Change
            </Button>
          </Form>
        ) : (
          <Loader />
        )}
      </Row>
    </Container>
  );
};

export { DepartmentRoleEdit };
