import { call, fork, put, select, take } from "typed-redux-saga";
import { fetchUser } from ".";
import {
  adminUsersAPI,
  RoleLevel,
} from "../../../../../services/api/admin/users";
import { AppState } from "../../../../../store";
import { showError, showSuccess } from "../../../../alert/store/slices";
import { loader } from "../../../../common/loaders/store/slices";
import { adminUsers } from "../slices";

function* adminUserDepartmentsSaga() {
  yield* fork(fetchUserRolesListener);
  yield* fork(roleChangeListener);
}

function* fetchUserRolesListener() {
  while (true) {
    const { payload } = yield take(adminUsers.actions.fetchDepartmentRoles);

    if (payload.userId) {
      const { user } = yield* select((state: AppState) => ({
        user: state.adminUsers.userData,
      }));

      if (!user) {
        yield* call(fetchUser, payload.userId);
      }
    }

    yield* call(fetchUserRoles);
  }
}

function* roleChangeListener() {
  while (true) {
    const { payload } = yield take(adminUsers.actions.changeDepartmentRole);

    if (payload.userId && payload.roleIds) {
      yield* call(syncDepartments, payload.userId, payload.roleIds);
    }
  }
}

function* syncDepartments(userId: string, roleIds: string[]) {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminUsersAPI.syncDepartments, userId, roleIds);

  if (response && response.data) {
    yield put(adminUsers.actions.setUpdatedUser(response.data));

    yield put(showSuccess("User departments adjusted."));
    yield put(showSuccess("Companies will be synced shortly."));
  } else {
    yield put(showError("Something went wrong while changing role."));
  }

  yield put(loader.actions.stopLoader());
}

function* fetchUserRoles() {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminUsersAPI.fetchRoles, RoleLevel.Department);

  if (response && response.data) {
    yield put(adminUsers.actions.setDepartments(response.data));
  } else {
    yield put(showError("Something went wrong while getting roles."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminUserDepartmentsSaga };
