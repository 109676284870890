import { API, HTTPMethod, SuccessResponse } from "..";

const resetCache = async (): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/super-admin/settings/reset-cache`,
    {
      method: HTTPMethod.POST,
      version: "v2",
    }
  );

  return response;
};

const resetSessions = async (): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/super-admin/settings/reset-sessions`,
    {
      method: HTTPMethod.POST,
      version: "v2",
    }
  );

  return response;
};

const adminSettingsAPI = {
  resetCache,
  resetSessions,
};

export { adminSettingsAPI };
