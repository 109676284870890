import { FC } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { FaHistory } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { IProject } from "../../../services/api/projects";
import { ChangeLog } from "./ChangeLog";

interface IProps {
  project: IProject;
  handleClose: () => void;
}

const ChangeLogModal: FC<IProps> = ({ project, handleClose }) => {
  const { t } = useTranslation("projectChangeLogModal");

  const isLoading = useSelector((state) => state.loader.isLoading);

  return (
    <Modal
      size="xl"
      backdrop="static"
      show
      onHide={handleClose}
      id="order-change-log-modal"
      scrollable
    >
      <Modal.Header>
        <Modal.Title
          style={{
            overflowWrap: "anywhere",
          }}
        >
          <FaHistory /> {t("changeLog")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "left" }}>
        <ChangeLog project={project} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={handleClose}
          disabled={isLoading}
        >
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ChangeLogModal };
