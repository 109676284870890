import { FC, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { adminSettingsAPI } from "../../../services/api/admin/settings";
import { showError, showSuccess } from "../../alert/store/slices";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { Header } from "../../layout/Header";

const Cache: FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleCacheClear = async () => {
    setLoading(true);

    const response = await adminSettingsAPI.resetCache();

    setLoading(false);

    if (response && response.success) {
      dispatch(showSuccess("Cache cleared."));
    } else {
      dispatch(showError("Something went wrong while clearing cache."));
    }
  };

  return (
    <Container fluid>
      <Row>
        <Header title="Cache" languageDisabled />
      </Row>
      <Row className="site-cache">
        <span>Clear all application cache (settings, translations etc.)</span>
        <ButtonLoader
          className="cache-button"
          onClick={handleCacheClear}
          disabled={isLoading}
        >
          Clear
        </ButtonLoader>
      </Row>
    </Container>
  );
};

export { Cache };
