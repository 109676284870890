import { FC } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { FaTimesCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface IProps {
  question: string;
  isVisible: boolean;
  icon?: JSX.Element;
  body?: JSX.Element;
  handleDeny: () => void;
  handleConfirm: () => void;
}

const ConfirmModal: FC<IProps> = ({
  question,
  isVisible,
  icon,
  body,
  handleDeny,
  handleConfirm,
}) => {
  const { t } = useTranslation("confirmModal");

  const isLoading = useSelector((state) => state.loader.isLoading);

  return (
    <Modal show={isVisible} onHide={handleDeny} backdrop="static">
      <Modal.Header>
        <Modal.Title
          style={{
            overflowWrap: "anywhere",
          }}
        >
          {icon ? icon : <FaTimesCircle />} {question}
        </Modal.Title>
      </Modal.Header>
      {body && <Modal.Body>{body}</Modal.Body>}
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={handleDeny}
          disabled={isLoading}
        >
          {t("no")}
        </Button>
        <Button variant="primary" onClick={handleConfirm} disabled={isLoading}>
          {t("yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ConfirmModal };
