import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { GeneralRoute } from "../../constants/routes";
import { showInfo } from "../alert/store/slices";
import { IProfileState } from "../profile/store/slices";

interface IProps {
  profile: IProfileState;
}

const ForceRedirect: FC<IProps> = ({ profile }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const showForceUpdateNote = useCallback(
    (message: string) => dispatch(showInfo(message)),
    [dispatch]
  );

  if (profile.data) {
    // if (!profile.data.privacyPolicyAccepted || !profile.data.termsPolicyAccepted) {
    if (!profile.data.termsPolicyAccepted) {
      return <Redirect to={GeneralRoute.Profile.Show} />;
    }

    if (!profile.data.emailVerifiedAt) {
      showForceUpdateNote(t("pleaseVerifyEmail", { ns: "profile" }) as string);

      return <Redirect to={GeneralRoute.Profile.Show} />;
    }

    if (profile.data.forceUpdateProfile) {
      showForceUpdateNote(
        t("pleaseUpdateProfile", { ns: "profileEdit" }) as string
      );

      return <Redirect to={GeneralRoute.Profile.Edit} />;
    }

    if (profile.data.forceUpdatePassword) {
      showForceUpdateNote(
        t("yourPasswordExpired", { ns: "changePassword" }) as string
      );

      return <Redirect to={GeneralRoute.Profile.ChangePassword} />;
    }
  }

  return <Redirect to="/" />;
};

export { ForceRedirect };
