import { call, fork, put, take } from "typed-redux-saga";
import { translated } from "../../../../i18n";
import { authAPI, ITokens } from "../../../../services/api/auth";
import { showWarning } from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { profile } from "../../../profile/store/slices";
import { ILoginData } from "../Login";
import { authentication } from "./slices";

function* authenticationSaga() {
  yield* fork(fetchTokensWatcher);
}

function* fetchTokensWatcher() {
  while (true) {
    const { payload } = yield take(authentication.actions.fetchTokens);

    if (payload) {
      yield* call(fetchTokens, payload);
    }
  }
}

function* fetchTokens(data: ILoginData) {
  yield put(loader.actions.startLoader());

  const response: ITokens = yield* call(authAPI.getTokens, data);

  if (response && response.access_token) {
    const { access_token, refresh_token } = response;

    if (access_token && refresh_token) {
      localStorage.setItem("jwt_tokens", JSON.stringify(response));

      yield put(profile.actions.fetchProfile());
    }
  } else {
    yield put(showWarning(translated.invalidUsernamePassword));
  }

  yield put(loader.actions.stopLoader());
}

export { authenticationSaga };
