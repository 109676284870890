import { FC, useEffect, useState } from "react";
import { PDFObject } from "react-pdfobject";
import { documentsAPI } from "../../../services/api/documents";
import store from "../../../store";
import { showError } from "../../alert/store/slices";
import { Loader } from "../loaders/Loader";
import { useTranslation } from "react-i18next";

interface IProps {
  documentId: number;
}

const PDFReader: FC<IProps> = ({ documentId }) => {
  const { t } = useTranslation("pdfReader");

  const [url, setUrl] = useState<string | null>();

  useEffect(() => {
    const downloadDocument = async (documentId: number) => {
      try {
        const file = await documentsAPI.downloadDocument(documentId);

        if (file) {
          setUrl(window.URL.createObjectURL(file as any));
        }
      } catch (error) {
        store.dispatch(showError(t("filePreviewFailed") as string));

        setUrl(null);
      }
    };

    downloadDocument(documentId);
  }, [documentId, t]);

  if (url === null) {
    return null;
  }

  if (!url) {
    return <Loader isLoading={true} />;
  }

  return (
    <PDFObject
      url={url}
      containerId="pdf-reader"
      fallbackLink={t("filePreviewNotSupported") as string}
    />
  );
};

export { PDFReader };
