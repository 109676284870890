import { API, ApiResource, HTTPMethod, SuccessResponse } from ".";
import queryString from "query-string";
import { IUser } from "./users";

export enum DocumentInputType {
  Number = "number",
  Date = "date",
  Text = "text",
  Money = "money",
}
export interface IDocumentData {
  id: number;
  value: string | number | { amount: number; currency: string };
  createdAt: number;
  type: IDocumentDataType;
}

export interface IDocumentDataType {
  id: number;
  code: string;
  name: string;
  type: DocumentInputType;
  required?: boolean;
}

export enum DocumentType {
  Cmr = "cmr",
  Invoice = "invoice",
}

export enum DocumentStatus {
  Accepted = "accepted",
  Rejected = "rejected",
  Pending = "pending",
  Uploaded = "uploaded",
  Generated = "generated",
  Waiting = "waiting",
}

export enum DocumentGroupCode {
  Order = "P_EX_UZS",
  CMR = "CMR",
  Invoice = "V_INVOICE",
  Other = "Pap.dokum",
  ConfirmationDemmurages = "PrastLapas",
  CustomDeclaration = "EXP_DEKLAR",
  Termograma = "termograma",
  CreditOrder = "Credit order",
}

export interface IDocumentGroup {
  id: number;
  name: string;
  code: DocumentGroupCode;
}

export interface IDocumentType {
  id: number;
  name: string;
  code: string | null;
  groupId: number | null;
  group: IDocumentGroup;
  dataTypes?: IDocumentDataType[];
}

export interface IDocumentStatus {
  id: number;
  name: string;
  code: DocumentStatus;
}

export interface IDocumentShort {
  id: number;
  name: string;
  fileName: string;
  updatedAt: string;
}

export interface IDocument {
  id: number;
  name: string;
  fileName: string;
  size: number;
  type?: IDocumentType | null;
  status?: IDocumentStatus | null;
  paymentUntil: number | null;
  modelId: number;
  modelType: string;
  axMessage: string | null;
  data?: IDocumentData[] | null;
  createdAt: number;
  createdBy?: IUser | null;
  deletedBy?: IUser | null;
  deletedAt: number;
  comment: string | null;
  axCode: string | null;
  mimeType: string;
  model?: any;
  fileRemoved: boolean;
}

export interface IFetchDocumentParams {
  search?: string;
  perPage?: number;
  page?: number;
  showOnlyMy?: boolean;
  showDeleted?: boolean;
  status?: DocumentStatus;
  companyId?: number;
  typeId?: number;
}

const fetchDocuments = async (
  requestParams: IFetchDocumentParams
): Promise<ApiResource<IDocument[]>> => {
  const params = queryString.stringify({
    ...requestParams,
    showDeleted: requestParams.showDeleted ? 1 : 0,
    showOnlyMy: requestParams.showOnlyMy ? 1 : 0,
  });

  const response = await API.request<ApiResource<IDocument[]>>(
    `/documents?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const downloadDocument = async (
  documentId: number
): Promise<ApiResource<any>> => {
  const response = await API.request<ApiResource<any>>(
    `/documents/${documentId}/download`,
    {
      method: HTTPMethod.GET,
      version: "v2",
    }
  );

  return response;
};

const deleteDocument = async (documentId: string): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/documents/${documentId}`,
    {
      method: HTTPMethod.DELETE,
      version: "v2",
    }
  );

  return response;
};

const documentsAPI = {
  fetchDocuments,
  downloadDocument,
  deleteDocument,
};

export { documentsAPI };
