import { FC, useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { TFunctionResult } from "i18next";

interface IPopoverCloseProps {
  onClick: () => void;
}

const PopoverClose: FC<IPopoverCloseProps> = ({ onClick }) => {
  return (
    <div aria-hidden="true" className="close-popover" onClick={onClick}>
      ×
    </div>
  );
};

export interface IHotspotProps {
  isVisible?: boolean;
  showNext?: boolean;
  isPulsing?: boolean;
  order: number;
  guideLines: TFunctionResult[] | undefined;
  style: React.CSSProperties;
  toggle?: (order: number) => void;
}

const Hotspot: FC<IHotspotProps> = ({
  isVisible,
  showNext,
  isPulsing,
  guideLines,
  style,
  order,
  toggle,
}) => {
  const { t } = useTranslation("hotspot");

  const [pulseStyle, setPulseStyle] = useState<
    React.CSSProperties | undefined
  >();
  const [pulsation, setPulsation] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (isPulsing) {
      const pulseTimeout = setTimeout(() => {
        setPulseStyle(
          pulseStyle
            ? undefined
            : {
                boxShadow: "0px 0px 0px 8px rgb(26 122 180 / 50%)",
              }
        );
      }, 500);

      if (!pulsation) {
        setPulsation(pulseTimeout);
      }
    }

    if (!isPulsing && pulsation) {
      clearTimeout(pulsation);
      setPulseStyle(undefined);
    }
  }, [isPulsing, pulsation, pulseStyle]);

  if (!guideLines) {
    return null;
  }

  return (
    <div className="hotspot" style={style}>
      <OverlayTrigger
        show={isVisible}
        placement="right"
        overlay={
          <Popover id="popover-wizard">
            <Popover.Content>
              <PopoverClose
                onClick={() => (toggle ? toggle(order) : undefined)}
              />
              {guideLines.map((guideLine) => (
                <span>{guideLine as any}</span>
              ))}
              <div
                className="popover-buttons"
                style={{
                  justifyContent: order > 1 ? "space-between" : "flex-end",
                }}
              >
                {order > 1 && (
                  <Button
                    onClick={() => (toggle ? toggle(order - 1) : undefined)}
                    size="sm"
                    variant="outline-secondary"
                  >
                    {t("previous")}
                  </Button>
                )}
                {showNext && (
                  <Button
                    onClick={() => (toggle ? toggle(order + 1) : undefined)}
                    size="sm"
                  >
                    {t("next")}
                  </Button>
                )}
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <div
          className="trigger"
          onClick={() => (toggle ? toggle(order) : undefined)}
          style={pulseStyle}
        >
          <FaEye />
        </div>
      </OverlayTrigger>
    </div>
  );
};

export { Hotspot };
