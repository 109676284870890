import { FC } from "react";
import { Badge, BadgeProps } from "react-bootstrap";
import {
  ApplicationStatus,
  IApplicationStatus,
} from "../../services/api/application";

const StatusColor: { [key in ApplicationStatus]: BadgeProps["variant"] } = {
  [ApplicationStatus.Open]: "warning",
  [ApplicationStatus.Filled]: "secondary",
  [ApplicationStatus.Approved]: "success",
  [ApplicationStatus.Rejected]: "danger",
};

interface IProps {
  status: IApplicationStatus;
}

const StatusBadge: FC<IProps> = ({ status }) => {
  return (
    <Badge className="status-badge" variant={StatusColor[status.code]}>
      {status.name}
    </Badge>
  );
};

export { StatusBadge };
