import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import {
  FaCloudUploadAlt,
  FaExclamationTriangle,
  FaTrash,
} from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../../../../common/button-loader/ButtonLoader";
import { projectShow } from "../../store/slices";
import { useTranslation } from "react-i18next";
import {
  DocumentGroupCode,
  DocumentInputType,
} from "../../../../../services/api/documents";
import { FileDropzone } from "../../../../common/files/FileDropzone";
import { formatBytes } from "../../../../../utils/byte-format";
import { DateTimeInput } from "../../../../common/datetime-input/DateTimeInput";
import { PDFObject } from "react-pdfobject";
import moment from "moment";

export interface INewDocumentForm {
  groupId: number;
  typeId: number;
  comment: string;
}

interface IProps {
  isVisible: boolean;
  onClose: () => void;
}

const NewDocumentModal: FC<IProps> = ({ isVisible, onClose }) => {
  const { t } = useTranslation("newDocumentModal");

  const [documentForUpload, setDocument] = useState<File>();

  const project = useSelector((state) => state.projectShow.data);
  const settings = useSelector((state) => state.settings.carrier);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const dispatch = useDispatch();

  const uploadDocument = useCallback(
    (projectId: number, data: INewDocumentForm, document: File) =>
      dispatch(
        projectShow.actions.uploadDocument({ projectId, data, document })
      ),
    [dispatch]
  );

  const { register, watch, errors, handleSubmit, control, setValue } = useForm<
    INewDocumentForm
  >();

  const handleDocumentSubmit = (data: INewDocumentForm) => {
    if (project && documentForUpload) {
      uploadDocument(project.id, data, documentForUpload);

      onClose();
    }
  };

  const hasError = (firstLevel: string, secondLevel?: string): boolean => {
    if (secondLevel) {
      return (errors as any)[firstLevel]
        ? !!(errors as any)[firstLevel][secondLevel]
        : false;
    }

    return !!(errors as any)[firstLevel];
  };

  const groupId = watch("groupId");
  const typeId = watch("typeId");

  const groups = useMemo(() => {
    if (settings) {
      const groupCodesToExclude: Array<string | null> = [
        DocumentGroupCode.CreditOrder,
      ];

      return settings.documents.groups.filter(
        (group) => !groupCodesToExclude.includes(group.code)
      );
    }

    return [];
  }, [settings]);

  const types = useMemo(() => {
    if (settings) {
      const typeCodesToExclude: Array<string | null> = ["ORDER_AUTO"];

      const types = settings.documents.types.filter(
        (type) => type.groupId === Number(groupId)
      );

      return types.filter((type) => !typeCodesToExclude.includes(type.code));
    }

    return [];
  }, [settings, groupId]);

  const dataTypes = useMemo(() => {
    if (types.length > 0) {
      const type = types.find((type) => type.id === Number(typeId));

      if (type && type.dataTypes) {
        return type.dataTypes;
      }
    }

    return [];
  }, [types, typeId]);

  const isPreviewAvailable = useMemo(() => {
    if (documentForUpload) {
      const fileType = documentForUpload.type;

      return ["application/pdf", "image/jpeg", "image/png"].includes(fileType);
    }

    return false;
  }, [documentForUpload]);

  useEffect(() => {
    if (types.length === 1 && !typeId) {
      setValue("typeId", types[0].id, true);
    }
  }, [typeId, types, setValue]);

  useEffect(() => {
    if (documentForUpload && !isVisible) {
      setDocument(undefined);
    }
  }, [documentForUpload, isVisible]);

  if (!settings) {
    return null;
  }

  return (
    <Modal
      show={isVisible}
      size={isPreviewAvailable ? "xl" : undefined}
      onHide={onClose}
      className="upload-document-modal"
      backdrop="static"
    >
      <Form onSubmit={handleSubmit(handleDocumentSubmit)}>
        <Modal.Header className="no-bottom-border">
          <Modal.Title>
            <FaCloudUploadAlt />
            {t("uploadDocument")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col>
              <Alert
                variant="warning"
                style={{ width: "100%", textAlign: "center" }}
              >
                <FaExclamationTriangle />{" "}
                {t("youWillNotBeAbleToDeleteThisFileLater")}
              </Alert>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Row>
                <Form.Group as={Col} sm={12} controlId="groupId">
                  <Form.Label>{t("group")}</Form.Label>
                  <Form.Control
                    name="groupId"
                    as="select"
                    ref={register({ required: true })}
                    isInvalid={!!errors.groupId}
                    defaultValue=""
                  >
                    <option disabled value="">
                      {t("chooseGroup")}
                    </option>
                    {settings &&
                      groups.map((group, index) => (
                        <option key={index} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">
                    {errors.groupId?.type === "required" && t("requiredField")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm={12} controlId="typeId">
                  <Form.Label>{t("type")}</Form.Label>
                  <Form.Control
                    name="typeId"
                    as="select"
                    ref={register({ required: true })}
                    isInvalid={!!errors.typeId}
                    defaultValue=""
                    disabled={!groupId || types.length <= 1}
                  >
                    <option disabled value="">
                      {t("chooseType")}
                    </option>
                    {types.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">
                    {errors.typeId?.type === "required" && t("requiredField")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              {dataTypes.length > 0 && <hr />}

              {dataTypes.map((dataType, index) => {
                switch (dataType.type) {
                  case DocumentInputType.Text:
                    return (
                      <Form.Row key={index}>
                        <Form.Group as={Col} sm={12} controlId={dataType.code}>
                          <Form.Label>{dataType.name}</Form.Label>
                          <Form.Control
                            name={dataType.code}
                            type="text"
                            ref={register({ required: !!dataType.required })}
                            maxLength={190}
                            isInvalid={!!(errors as any)[dataType.code]}
                          />

                          <Form.Control.Feedback type="invalid">
                            {(errors as any)[dataType.code]?.type ===
                              "required" && t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    );

                  case DocumentInputType.Date:
                    return (
                      <Form.Row key={index}>
                        <Form.Group as={Col} sm={12} controlId={dataType.code}>
                          <Form.Label>{dataType.name}</Form.Label>
                          <DateTimeInput
                            name={dataType.code}
                            isInvalid={!!(errors as any)[dataType.code]}
                            selected={watch(dataType.code)}
                            dateFormat="yyyy-MM-dd"
                            minDate={moment().subtract(1, "year").toDate()}
                            maxDate={moment().toDate()}
                            className="date-input"
                            control={control}
                            rules={{ required: !!dataType.required }}
                          />

                          {(errors as any)[dataType.code] && (
                            <div className="text-danger">
                              <small>{t("requiredField")}</small>
                            </div>
                          )}
                        </Form.Group>
                      </Form.Row>
                    );

                  case DocumentInputType.Money:
                    return (
                      <Form.Row key={index}>
                        <Form.Group
                          as={Col}
                          sm={6}
                          controlId={`${dataType.code}.amount`}
                        >
                          <Form.Label>{dataType.name}</Form.Label>
                          <Form.Control
                            name={`${dataType.code}.amount`}
                            type="number"
                            step="0.01"
                            min={1}
                            ref={register({ required: !!dataType.required })}
                            maxLength={190}
                            isInvalid={hasError(dataType.code, "amount")}
                          />

                          <Form.Control.Feedback type="invalid">
                            {hasError(dataType.code, "amount") &&
                              t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          sm={6}
                          controlId={`${dataType.code}.currency`}
                        >
                          <Form.Label>{t("currency")}</Form.Label>
                          <Form.Control
                            name={`${dataType.code}.currency`}
                            as="select"
                            ref={register({ required: !!dataType.required })}
                            isInvalid={hasError(dataType.code, "currency")}
                            defaultValue="EUR"
                          >
                            {settings.general.currencies.map(
                              (currency, index) => (
                                <option key={index} value={currency.code}>
                                  {currency.code}
                                </option>
                              )
                            )}
                          </Form.Control>

                          <Form.Control.Feedback type="invalid">
                            {hasError(dataType.code, "currency") &&
                              t("requiredField")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    );
                  default:
                    return null;
                }
              })}

              {dataTypes.length > 0 && <hr />}

              <Form.Row>
                <Form.Group as={Col} sm={12} controlId="comment">
                  <Form.Label>{t("comment")}</Form.Label>
                  <Form.Control
                    name="comment"
                    as="textarea"
                    rows={5}
                    ref={register}
                    maxLength={1000}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.comment?.type === "required" && t("requiredField")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row className="document-dropzone">
                {documentForUpload ? (
                  <div>
                    {documentForUpload.name}{" "}
                    {formatBytes(documentForUpload.size)}
                    <FaTrash onClick={() => setDocument(undefined)} />
                  </div>
                ) : (
                  <FileDropzone
                    onDropAccepted={(files) => setDocument(files[0])}
                    maxSize={settings.general.maxUploadSize * 1000}
                  />
                )}
              </Form.Row>
            </Col>
            {isPreviewAvailable && (
              <Col>
                <PDFObject
                  url={URL.createObjectURL(documentForUpload as any)}
                  containerId="pdf-reader"
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onClose}>
            {t("cancel")}
          </Button>
          <ButtonLoader
            variant="primary"
            type="submit"
            disabled={isLoading}
            buttonDisabled={!documentForUpload}
          >
            {t("upload")}
          </ButtonLoader>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export { NewDocumentModal };
