import { API, ApiResource, HTTPMethod, SuccessResponse } from ".";
import {
  UnreadNotificationsCount,
  Notification,
  NotificationType,
} from "../../components/notifications/types";

const fetchNotifications = async (
  type: NotificationType | null,
  page: number
): Promise<ApiResource<Notification[]>> => {
  return await API.request<ApiResource<Notification[]>>(
    `/carrier/notifications?page=${page}${type ? `&type_name=${type}` : ""}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );
};

const fetchRecentNotifications = async (): Promise<
  ApiResource<Notification[]>
> => {
  return await API.request<ApiResource<Notification[]>>(
    `/carrier/notifications/recent`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );
};

const fetchUnreadNotificationsCount = async (): Promise<
  ApiResource<UnreadNotificationsCount>
> => {
  return await API.request<ApiResource<UnreadNotificationsCount>>(
    `/carrier/notifications/count/unread`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );
};

const markAsRead = async (ids: number[]): Promise<SuccessResponse> => {
  return await API.request<SuccessResponse>(
    `/carrier/notifications/mark-read`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({ notification_ids: ids })
  );
};

const notificationsAPI = {
  fetchNotifications,
  fetchRecentNotifications,
  fetchUnreadNotificationsCount,
  markAsRead,
};

export { notificationsAPI };
