import { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { formatBytes } from "../../../utils/byte-format";
import { WarningModal } from "../modals/WarningModal";

const imageMimeTypes = ["image/jpeg", "image/png"];

const acceptedMimeTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/csv",
  "text/plain",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  ...imageMimeTypes,
];

interface IProps {
  disabled?: boolean;
  maxSize?: number;
  multiple?: boolean;
  onlyImages?: boolean;
  onDropAccepted: (files: File[]) => void;
}

const FileDropzone: FC<IProps> = ({
  disabled,
  maxSize,
  multiple,
  onlyImages,
  onDropAccepted,
}) => {
  const { t } = useTranslation("fileDropzone");

  const [warningVisible, setWarningVisible] = useState<boolean>(false);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>();

  const settings = useSelector((state) => state.settings.carrier);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    maxSize,
    multiple: !!multiple,
    accept: onlyImages ? imageMimeTypes.join(",") : acceptedMimeTypes.join(","),
    onDropRejected: setRejectedFiles,
    onDropAccepted,
  });

  useEffect(() => {
    if (rejectedFiles) {
      setWarningVisible(true);
    }
  }, [rejectedFiles]);

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="text-place-active">
            <FaCloudUploadAlt />
            <span>{t("dropFileHere")}</span>
          </div>
        ) : (
          <div className="text-place">
            <FaCloudUploadAlt />
            <span>{t("dragDropFilesHere")}</span>
            <Button variant="outline-primary">{t("browseFiles")}</Button>
          </div>
        )}
      </div>

      <WarningModal
        isVisible={warningVisible}
        handleOk={() => {
          setRejectedFiles(undefined);
          setWarningVisible(false);
        }}
        warningText={t("someFilesNotUploaded") as string}
        buttonText={t("tryAgain") as string}
        body={
          rejectedFiles && (
            <ul className="file-error-list">
              {rejectedFiles.map(({ file, errors }) => (
                <li key={file.name}>
                  {file.name}
                  <ul>
                    {errors.map((error, index) => {
                      if (error.code === "file-too-large") {
                        return (
                          <li key={index}>
                            File is larger than{" "}
                            {formatBytes(
                              settings
                                ? settings.general.maxUploadSize * 1000
                                : 0
                            )}
                          </li>
                        );
                      }

                      return <li key={index}>{error.message}</li>;
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          )
        }
      />
    </>
  );
};

export { FileDropzone };
