import { useTranslation } from "react-i18next";
import styles from "../Notification.module.scss";
import { Link } from "react-router-dom";
import { CarrierRoot } from "../../../constants/routes";
import { NotificationItem } from "./NotificationItem";
import { useDispatch, useSelector } from "react-redux";
import { NoData } from "../../common/no-data/NoData";
import { useCallback } from "react";
import { notifications } from "../store/slices";
import { Loader } from "../../common/loaders/Loader";

const NotificationDropdown = () => {
  const { t } = useTranslation("notifications");
  const dispatch = useDispatch();

  const recentNotifications = useSelector(
    (state) => state.notifications.recent
  );
  const isLoading = useSelector((state) => state.notifications.isRecentLoading);
  const unreadCount = useSelector((state) => state.notifications.unreadCount);

  const markAllAsRead = useCallback(() => {
    dispatch(notifications.actions.markAsRead([]));
  }, [dispatch]);

  return (
    <div className={styles.notifications}>
      <div className={styles.notificationsHeader}>
        <span className={styles.notificationsTitle}>{t("notifications")}</span>
        {unreadCount > 0 && (
          <Link className={styles.markAllAsRead} to="#" onClick={markAllAsRead}>
            {t("markAllAsRead")}
          </Link>
        )}
      </div>

      {!isLoading && !recentNotifications.length && <NoData />}

      {isLoading ? (
        <div className={styles.loader}>
          <Loader isLoading />
        </div>
      ) : (
        recentNotifications.map((notification, index) => (
          <NotificationItem key={index} notification={notification} />
        ))
      )}

      {!isLoading && recentNotifications.length > 0 && (
        <div className={styles.viewAll}>
          <Link to={CarrierRoot.Notifications}>
            {t("viewAllNotifications")}
          </Link>
        </div>
      )}
    </div>
  );
};

export { NotificationDropdown };
