import { CompanyShort } from "../../profile/store/slices";

export interface Notification {
  id: number;
  ax_code: string;
  type_id: number;
  message_key: string;
  date: string;
  is_read: boolean;
  type: NotificationType;
  company?: CompanyShort;
  model_id: number;
  model_type: "App\\Project" | "App\\Application";
}

export interface UnreadNotificationsCount {
  count: number;
}

export enum NotificationType {
  project = "project",
  document = "document",
  application = "application",
}

export interface NotificationProps {
  notification: Notification;
}

export type NotificationTypeColors = {
  [key in NotificationType]: string;
};
