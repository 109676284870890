import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import {
  FaAddressCard,
  FaBuilding,
  FaCloudUploadAlt,
  FaDatabase,
  FaEnvelope,
  FaExclamationTriangle,
  FaFileCode,
  FaFolderOpen,
  FaHistory,
  FaMailBulk,
  FaMapMarkedAlt,
  FaSearch,
  FaSignOutAlt,
  FaSortDown,
  FaTasks,
  FaUser,
  FaUserClock,
  FaUsers,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  AdminRoute,
  CarrierRoute,
  GeneralRoute,
  SuperAdminRoute,
} from "../../constants/routes";
import { NewSearchInput } from "../common/search/NewSearchInput";
import { CompanySelectModal } from "../profile/modals/CompanySelectModal";
import { MaintenanceAlert } from "../maintenance/MaintenanceAlert";
import { LanguageSwitcher } from "../translations/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { AdminAppModule, AppModule } from "../../services/api/settings";
import { RoleAlert } from "../role-alert/RoleAlert";
import useMobileScreen from "../../hooks/mobile-screen";
import WorkspaceSwitcher from "../workspace-switcher/WorkspaceSwitcher";
import { Workspace } from "../workspace-switcher/store/slice";
import { profile as profileSlice } from "../profile/store/slices";
import { NotificationBell } from "../notifications/Dropdown/NotificationBell";

const Divider = () => (
  <div
    style={{
      borderRight: "1px solid #ced4da",
      margin: "0rem 1.5rem",
      height: "25px",
    }}
  />
);

interface IProps {
  title: string;
  subTitle?: string;
  showCompanySelect?: boolean;
  companySelectDisabled?: boolean;
  profileDisabled?: boolean;
  languageDisabled?: boolean;
  style?: CSSProperties;
  titleBadge?: JSX.Element | null;
  initialSearch?: string;
  searchDisabled?: boolean;
  appModule?: AppModule;
  handleSearch?: (query: string) => void;
}

const Header: FC<IProps> = ({
  title,
  subTitle,
  showCompanySelect,
  companySelectDisabled,
  languageDisabled,
  titleBadge,
  style,
  initialSearch,
  searchDisabled,
  appModule,
  handleSearch,
}) => {
  const dispatch = useDispatch();

  const isMobileScreen = useMobileScreen();

  const { t } = useTranslation("header");

  const location = useLocation();

  const [companyModalOpen, setCompanyModalOpen] = useState(false);

  const profile = useSelector((state) => state.profile);
  const currentWorkspace = useSelector(
    (state) => state.workspace.currentWorkspace
  );

  const isActive = useCallback(
    (moduleName: AppModule | AdminAppModule | string) => {
      return location.pathname.includes(moduleName);
    },
    [location.pathname]
  );

  const logout = useCallback(
    () => dispatch(profileSlice.actions.logoutProfile()),
    [dispatch]
  );

  const notificationsAvailable = useMemo(
    () =>
      profile.isCarrier &&
      !!profile.data &&
      !!profile.data.emailVerifiedAt &&
      // profile.data.privacyPolicyAccepted &&
      profile.data.termsPolicyAccepted &&
      !profile.isManager &&
      !profile.isGroupLeader,
    [profile]
  );

  const profileMobileMenu = useMemo(
    () => (
      <Navbar className="mobile-menu" bg="light" expand="lg">
        <Navbar.Brand>{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link
              active={isActive(AppModule.Profile)}
              as={Link}
              to={GeneralRoute.Profile.Show}
            >
              <FaUser /> {t("profile")}
            </Nav.Link>

            {notificationsAvailable && (
              <Nav.Link
                active={isActive(AppModule.MailSettings)}
                as={Link}
                to={GeneralRoute.MailSettings}
              >
                <FaEnvelope /> {t("notificationSettings")}
              </Nav.Link>
            )}

            <NavDropdown.Divider />

            {notificationsAvailable &&
              currentWorkspace === Workspace.CarrierSpace && (
                <>
                  <NotificationBell />
                  <NavDropdown.Divider />
                </>
              )}

            {!languageDisabled && (
              <Nav.Link href="#">
                <LanguageSwitcher />
              </Nav.Link>
            )}

            <NavDropdown.Divider />

            <Nav.Link href="#">
              <WorkspaceSwitcher />
            </Nav.Link>

            <NavDropdown.Divider />

            <Nav.Link onClick={logout}>
              <FaSignOutAlt /> {t("logout")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        {handleSearch && (
          <div className="header-search">
            <NewSearchInput
              search={handleSearch}
              initialSearch={initialSearch}
              noMargin
              disabled={searchDisabled}
            />
          </div>
        )}
      </Navbar>
    ),
    [
      handleSearch,
      initialSearch,
      isActive,
      languageDisabled,
      searchDisabled,
      currentWorkspace,
      t,
      title,
      logout,
      notificationsAvailable,
    ]
  );

  const carrierMobileMenu = useMemo(
    () => (
      <Navbar className="mobile-menu" bg="light" expand="xl">
        <Navbar.Brand>{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <div className="company-switch">
              {!!(
                showCompanySelect &&
                appModule &&
                profile.data &&
                profile.data.selectedCompany
              ) && (
                <Button
                  size="sm"
                  block
                  variant="secondary"
                  onClick={() => setCompanyModalOpen(true)}
                  disabled={companySelectDisabled}
                >
                  {profile.data.selectedCompany.name} <FaSortDown />
                </Button>
              )}
            </div>
            {profile.isCarrier && (
              <>
                <Nav.Link
                  active={isActive(AppModule.Orders)}
                  as={Link}
                  to={CarrierRoute.Orders.List.replace(":orderId", "")}
                >
                  <FaMapMarkedAlt /> {t("projects")}
                </Nav.Link>
                <Nav.Link
                  active={isActive(AppModule.Documents)}
                  as={Link}
                  to={CarrierRoute.Documents.List}
                >
                  <FaFolderOpen /> {t("documents")}
                </Nav.Link>
                <Nav.Link
                  active={isActive(AppModule.Users)}
                  as={Link}
                  to={CarrierRoute.Users.List}
                >
                  <FaUsers /> {t("users")}
                </Nav.Link>
              </>
            )}
            {!!(profile.isGuest || profile.isCarrier) && (
              <Nav.Link
                active={isActive(AppModule.Applications)}
                as={Link}
                to={CarrierRoute.Applications.List}
              >
                <FaAddressCard /> {t("applications")}
              </Nav.Link>
            )}

            <NavDropdown.Divider />

            {notificationsAvailable &&
              currentWorkspace === Workspace.CarrierSpace && (
                <>
                  <NotificationBell />
                  <NavDropdown.Divider />
                </>
              )}

            {!languageDisabled && (
              <Nav.Link href="#">
                <LanguageSwitcher />
              </Nav.Link>
            )}

            <NavDropdown.Divider />

            <Nav.Link href="#">
              <WorkspaceSwitcher />
            </Nav.Link>

            <NavDropdown.Divider />

            <Nav.Link onClick={logout}>
              <FaSignOutAlt /> {t("logout")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        {handleSearch && (
          <div className="header-search">
            <NewSearchInput
              search={handleSearch}
              initialSearch={initialSearch}
              noMargin
              disabled={searchDisabled}
            />
          </div>
        )}
      </Navbar>
    ),
    [
      appModule,
      companySelectDisabled,
      handleSearch,
      initialSearch,
      isActive,
      languageDisabled,
      profile.data,
      profile.isCarrier,
      profile.isGuest,
      searchDisabled,
      showCompanySelect,
      currentWorkspace,
      t,
      title,
      logout,
      notificationsAvailable,
    ]
  );

  const adminMobileMenu = useMemo(
    () => (
      <Navbar className="mobile-menu" bg="light" expand="xl">
        <Navbar.Brand>{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link
              active={isActive(AdminAppModule.Users)}
              as={Link}
              to={AdminRoute.Users.List}
            >
              <FaUsers /> Users
            </Nav.Link>

            <Nav.Link
              active={isActive(AdminAppModule.Applications)}
              as={Link}
              to={AdminRoute.Applications.List}
            >
              <FaAddressCard /> Applications
            </Nav.Link>

            <Nav.Link
              active={isActive(AdminAppModule.ActivityLog)}
              as={Link}
              to={AdminRoute.ActivityLog.List}
            >
              <FaSearch /> Activity log
            </Nav.Link>

            <Nav.Link
              active={isActive(AdminAppModule.Documents)}
              as={Link}
              to={AdminRoute.Documents.List}
            >
              <FaFolderOpen /> Documents
            </Nav.Link>

            <Nav.Link
              active={isActive(AdminAppModule.Orders)}
              as={Link}
              to={AdminRoute.Orders.List}
            >
              <FaMapMarkedAlt /> Projects
            </Nav.Link>

            <Nav.Link
              active={isActive("admin/" + AdminAppModule.Companies)}
              as={Link}
              to={AdminRoute.Companies.List}
            >
              <FaBuilding /> Companies
            </Nav.Link>

            <Nav.Link
              active={isActive("admin/" + AdminAppModule.ClientCompanies)}
              as={Link}
              to={AdminRoute.ClientCompanies.List}
            >
              <FaBuilding /> Everwest companies
            </Nav.Link>

            <Nav.Link
              active={isActive("admin/" + AdminAppModule.Files)}
              as={Link}
              to={AdminRoute.Files.List}
            >
              <FaFileCode /> Axapta files
            </Nav.Link>

            <Nav.Link
              active={isActive("admin/" + AdminAppModule.GeneralPolicies)}
              as={Link}
              to={AdminRoute.GeneralPolicies.List}
            >
              <FaFileCode /> General policies
            </Nav.Link>

            <Nav.Link
              active={isActive("admin/" + AdminAppModule.ProjectPolicies)}
              as={Link}
              to={AdminRoute.ProjectPolicies.List}
            >
              <FaFileCode /> Order policies
            </Nav.Link>

            <Nav.Link
              active={isActive("admin/" + AdminAppModule.StatisticPolicies)}
              as={Link}
              to={AdminRoute.StatisticPolicies.List}
            >
              <FaFileCode /> Special client policies
            </Nav.Link>

            <NavDropdown.Divider />

            <Nav.Link href="#">
              <WorkspaceSwitcher />
            </Nav.Link>

            <NavDropdown.Divider />

            <Nav.Link onClick={logout}>
              <FaSignOutAlt /> {t("logout")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        {handleSearch && (
          <div className="header-search">
            <NewSearchInput
              search={handleSearch}
              initialSearch={initialSearch}
              noMargin
              disabled={searchDisabled}
            />
          </div>
        )}
      </Navbar>
    ),
    [handleSearch, initialSearch, isActive, searchDisabled, title, logout, t]
  );

  const superAdminMobileMenu = useMemo(
    () => (
      <Navbar className="mobile-menu" bg="light" expand="xl">
        <Navbar.Brand>{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link
              active={isActive(AdminAppModule.Edi)}
              as={Link}
              to={SuperAdminRoute.EDI.Upload}
            >
              <FaCloudUploadAlt /> EDI uploader
            </Nav.Link>

            <Nav.Link
              active={isActive(AdminAppModule.Cache)}
              as={Link}
              to={SuperAdminRoute.Settings.Cache}
            >
              <FaDatabase /> Cache
            </Nav.Link>

            <Nav.Link
              active={isActive(AdminAppModule.Sessions)}
              as={Link}
              to={SuperAdminRoute.Settings.Sessions}
            >
              <FaUserClock /> Sessions
            </Nav.Link>

            <Nav.Link
              active={isActive(AdminAppModule.Queues)}
              as={Link}
              to={SuperAdminRoute.Settings.Queues}
            >
              <FaTasks /> Queues
            </Nav.Link>

            <Nav.Link
              active={isActive(AdminAppModule.Logs)}
              as={Link}
              to={SuperAdminRoute.Settings.Logs}
            >
              <FaHistory /> Logs
            </Nav.Link>

            <Nav.Link
              active={location.pathname.includes(
                SuperAdminRoute.Mailing.TestTemplates
              )}
              as={Link}
              to={SuperAdminRoute.Mailing.TestTemplates}
            >
              <FaMailBulk /> Email templates
            </Nav.Link>

            <Nav.Link
              active={location.pathname.includes(
                SuperAdminRoute.Mailing.SendIssueMail
              )}
              as={Link}
              to={SuperAdminRoute.Mailing.SendIssueMail}
            >
              <FaEnvelope /> Issue mail
            </Nav.Link>

            <Nav.Link
              active={isActive(AdminAppModule.Maintenance)}
              as={Link}
              to={SuperAdminRoute.Maintenance.Show}
            >
              <FaExclamationTriangle /> Maintenance
            </Nav.Link>

            <NavDropdown.Divider />

            <Nav.Link href="#">
              <WorkspaceSwitcher />
            </Nav.Link>

            <NavDropdown.Divider />

            <Nav.Link onClick={logout}>
              <FaSignOutAlt /> {t("logout")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        {handleSearch && (
          <div className="header-search">
            <NewSearchInput
              search={handleSearch}
              initialSearch={initialSearch}
              noMargin
              disabled={searchDisabled}
            />
          </div>
        )}
      </Navbar>
    ),
    [
      handleSearch,
      initialSearch,
      isActive,
      location.pathname,
      searchDisabled,
      title,
      logout,
      t,
    ]
  );

  const mobileMenu = useMemo(() => {
    let menu = null;

    if (currentWorkspace === Workspace.ProfileSpace) {
      menu = profileMobileMenu;
    }

    if (currentWorkspace === Workspace.CarrierSpace) {
      menu = carrierMobileMenu;
    }

    if (currentWorkspace === Workspace.AdminSpace) {
      menu = adminMobileMenu;
    }

    if (currentWorkspace === Workspace.DeveloperSpace) {
      menu = superAdminMobileMenu;
    }

    return menu;
  }, [
    carrierMobileMenu,
    adminMobileMenu,
    superAdminMobileMenu,
    profileMobileMenu,
    currentWorkspace,
  ]);

  const desktopMenu = (
    <div className="header" style={style}>
      <div
        className="header-heading"
        style={{
          alignItems: subTitle ? "baseline" : "center",
        }}
      >
        <h1>{title}</h1>
        {titleBadge && <div style={{ marginLeft: "20px" }}>{titleBadge}</div>}
        {subTitle && <h6>({subTitle})</h6>}
        {!!(
          showCompanySelect &&
          appModule &&
          profile.data &&
          profile.data.selectedCompany
        ) && (
          <Button
            size="sm"
            variant="light"
            onClick={() => setCompanyModalOpen(true)}
            disabled={companySelectDisabled}
          >
            {profile.data.selectedCompany.name} <FaSortDown />
          </Button>
        )}
      </div>
      {handleSearch && (
        <div className="header-search">
          <NewSearchInput
            search={handleSearch}
            initialSearch={initialSearch}
            noMargin
            disabled={searchDisabled}
          />
        </div>
      )}
      <div className="header-right">
        {notificationsAvailable && currentWorkspace === Workspace.CarrierSpace && (
          <>
            <NotificationBell />
            <Divider />
          </>
        )}

        {!languageDisabled && (
          <>
            <LanguageSwitcher />
            <Divider />
          </>
        )}
        <WorkspaceSwitcher />
        <Divider />
        <div className="logout" onClick={logout}>
          <FaSignOutAlt /> {t("logout")}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobileScreen ? mobileMenu : desktopMenu}

      {!!(
        showCompanySelect &&
        companyModalOpen &&
        appModule &&
        profile.data &&
        profile.data.selectedCompany
      ) && (
        <CompanySelectModal
          selectedCompany={profile.data.selectedCompany}
          onClose={() => setCompanyModalOpen(false)}
          appModule={appModule}
        />
      )}
      <MaintenanceAlert />
      <RoleAlert />
    </>
  );
};

export { Header };
