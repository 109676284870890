import { FC } from "react";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

interface IProps {
  collapsed: boolean;
  onClick: () => void;
}

const SidebarToggle: FC<IProps> = ({ collapsed, onClick }) => {
  return (
    <div
      className="sidebar-toggle"
      style={{
        left: collapsed ? "71px" : "230px",
      }}
      onClick={onClick}
    >
      {collapsed ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}
    </div>
  );
};

export { SidebarToggle };
