import { FC } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";

interface IProps {
  warningText: string;
  buttonText: string;
  body?: JSX.Element;
  isVisible: boolean;
  handleOk: () => void;
}

const WarningModal: FC<IProps> = ({
  warningText,
  buttonText,
  body,
  isVisible,
  handleOk,
}) => {
  const isLoading = useSelector((state) => state.loader.isLoading);

  return (
    <Modal
      show={isVisible}
      onHide={handleOk}
      backdropClassName="warning-modal-backdrop"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          <FaExclamationTriangle /> {warningText}
        </Modal.Title>
      </Modal.Header>
      {body && <Modal.Body>{body}</Modal.Body>}
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={handleOk}
          disabled={isLoading}
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { WarningModal };
