import { FC, useCallback } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { FaGlobeEurope } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { IProfile, SapEnv } from "../../../services/api/profile";
import { profile } from "../store/slices";

interface IProps {
  user: IProfile;
  isVisible: boolean;
  onClose: () => void;
}

const SapEnvSelectModal: FC<IProps> = ({ user, isVisible, onClose }) => {
  const dispatch = useDispatch();

  const changeEnv = useCallback(
    (env: SapEnv) => dispatch(profile.actions.changeSapEnv(env)),
    [dispatch]
  );

  const currentEnvironment = user.currentSapEnvironment
    ? user.currentSapEnvironment
    : undefined;

  return (
    <Modal show={isVisible} onHide={onClose} className="company-select-modal">
      <Modal.Header>
        <Modal.Title>
          <FaGlobeEurope /> Select environment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup defaultActiveKey={currentEnvironment}>
          {Object.values(SapEnv).map((env, index) => (
            <ListGroup.Item
              key={index}
              action
              eventKey={env}
              onClick={() => {
                changeEnv(env);
                onClose();
              }}
            >
              {env.toUpperCase()}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export { SapEnvSelectModal };
