import { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFileAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { LanguageCode } from "../../../../../i18n";
import { StatisticPolicy } from "../../../../../services/api/admin/statistic-policies";

interface IProps {
  statisticPolicy: StatisticPolicy;
  isVisible: boolean;
  onHide: () => void;
}

const SpecialRequirementsModal: FC<IProps> = ({
  statisticPolicy,
  isVisible,
  onHide,
}) => {
  const { t } = useTranslation("specialRequirementsModal");

  const profile = useSelector((state) => state.profile);

  const language =
    profile && profile.data ? profile.data.language : LanguageCode.EN;

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      size="xl"
      scrollable
      className="private-policy-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <FaFileAlt /> {t("specialCarrierRequirements")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowX: "hidden", textAlign: "left" }}>
        <div
          dangerouslySetInnerHTML={{
            __html: statisticPolicy.data[language],
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { SpecialRequirementsModal };
