import { fork } from "typed-redux-saga";
import { applicationDocumentSaga } from "./documents";
import { applicationListSaga } from "./list";
import newPartnerApplicationSaga from "./new-partner";
import existingPartnerApplicationSaga from "./existing-partner";

function* applicationSaga() {
  yield* fork(applicationListSaga);
  yield* fork(applicationDocumentSaga);
  yield* fork(newPartnerApplicationSaga);
  yield* fork(existingPartnerApplicationSaga);
}

export { applicationSaga };
