import { BsFillCaretDownFill } from "react-icons/bs";
import { Column, TableOptions } from "react-table";
import { IUser } from "../../../../services/api/users";
import { ActionCell } from "./cells/ActionCell";
import { RolesCell } from "./cells/RolesCell";

const usersListColumn: Column<TableOptions<IUser>>[] = [
  {
    Header: () => (
      <span>
        <BsFillCaretDownFill /> ID
      </span>
    ),
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Last Name",
    accessor: "surname",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Roles",
    accessor: "roles",
    Cell: (cell) => <RolesCell cell={cell} />,
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: (cell) => <ActionCell cell={cell} />,
    disableSortBy: true,
  },
];

export { usersListColumn };
