import { API, ApiResource, HTTPMethod, SuccessResponse } from "..";
import { StatisticGroup, IProjectStatisticGroup } from "../projects";

export type StatisticPolicyData = {
  en: string;
  ru: string;
  pl: string;
  lt: string;
};

export type StatisticPolicy = {
  id: number;
  data: StatisticPolicyData;
  is_active: boolean;
  group: IProjectStatisticGroup;
  project_count: number;
  created_at?: string;
  updated_at?: string;
};

const fetchPolicies = async (): Promise<ApiResource<StatisticPolicy[]>> => {
  const response = await API.request<ApiResource<StatisticPolicy[]>>(
    `/admin/statistic-policies`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const fetchPolicy = async (id: string): Promise<StatisticPolicy> => {
  const response = await API.request<StatisticPolicy>(
    `/admin/statistic-policies/${id}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const storePolicy = async (
  groupCode: StatisticGroup,
  data: StatisticPolicyData
): Promise<ApiResource<StatisticPolicy[]>> => {
  const response = await API.request<ApiResource<StatisticPolicy[]>>(
    `/admin/statistic-policies`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({
      group_code: groupCode,
      data,
    })
  );

  return response;
};

const updatePolicy = async (
  id: number,
  groupCode: StatisticGroup,
  data: StatisticPolicyData
): Promise<StatisticPolicy> => {
  const response = await API.request<StatisticPolicy>(
    `/admin/statistic-policies/${id}`,
    {
      method: HTTPMethod.PUT,
      version: "v2",
      jsonRequest: true,
    },
    JSON.stringify({
      group_code: groupCode,
      data,
    })
  );

  return response;
};

const deletePolicy = async (id: number): Promise<SuccessResponse> => {
  const response = await API.request<SuccessResponse>(
    `/admin/statistic-policies/${id}`,
    {
      method: HTTPMethod.DELETE,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const activatePolicy = async (id: number): Promise<StatisticPolicy> => {
  const response = await API.request<StatisticPolicy>(
    `/admin/statistic-policies/${id}/activate`,
    {
      method: HTTPMethod.POST,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const adminStatisticPoliciesAPI = {
  fetchPolicies,
  fetchPolicy,
  storePolicy,
  updatePolicy,
  deletePolicy,
  activatePolicy,
};

export { adminStatisticPoliciesAPI };
