import queryString from "query-string";
import { API, ApiResource, HTTPMethod } from "..";
import { ICompany } from "../profile";
import { IDocument } from "../documents";

export interface IClientCompaniesRequest {
  search?: string;
}

const fetchClientCompanies = async (
  requestParameters?: IClientCompaniesRequest
): Promise<ApiResource<ICompany[]>> => {
  const params = queryString.stringify(requestParameters as any);

  const response = await API.request<ApiResource<ICompany[]>>(
    `/admin/client-companies?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const downloadDocument = async (
  clientCompanyId: number,
  documentId: number
): Promise<ApiResource<any>> => {
  const response = await API.request<ApiResource<any>>(
    `/admin/client-companies/${clientCompanyId}/documents/${documentId}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
    }
  );

  return response;
};

const uploadStamp = async (
  clientCompanyId: number,
  file: File
): Promise<ApiResource<IDocument>> => {
  const data = new FormData();

  data.append("document", file);

  const response = await API.request<ApiResource<IDocument>>(
    `/admin/client-companies/${clientCompanyId}/documents/stamp`,
    {
      method: HTTPMethod.POST,
      version: "v2",
    },
    data
  );

  return response;
};

const adminClientCompaniesAPI = {
  fetchClientCompanies,
  downloadDocument,
  uploadStamp,
};

export { adminClientCompaniesAPI };
