import { FC, useCallback, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ICompany } from "../../../../services/api/profile";
import { ButtonLoader } from "../../../common/button-loader/ButtonLoader";
import { DateTimeInput } from "../../../common/datetime-input/DateTimeInput";
import { adminCompanies } from "../store/slices";

export interface ICompanyForm {
  name: string;
  axCode: string;
  code: string;
  vatCode: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  approvedAt: Date;
  applicationId: number;
}

interface IProps {
  company?: ICompany | null;
}

const CompanyForm: FC<IProps> = ({ company }) => {
  const isLoading = useSelector((state) => state.loader.isLoading);

  const { register, errors, handleSubmit, setValue, control, watch } = useForm<
    ICompanyForm
  >();

  const dispatch = useDispatch();

  const storeCompany = useCallback(
    (data: ICompanyForm) => dispatch(adminCompanies.actions.storeCompany(data)),
    [dispatch]
  );

  const updateCompany = useCallback(
    (companyId: number, data: ICompanyForm) =>
      dispatch(adminCompanies.actions.updateCompany({ companyId, data })),
    [dispatch]
  );

  const resetCompany = useCallback(
    () => dispatch(adminCompanies.actions.resetCompany()),
    [dispatch]
  );

  const onSubmit = (data: ICompanyForm) => {
    if (company) {
      updateCompany(company.id, data);
    } else {
      storeCompany(data);
    }
  };

  useEffect(() => {
    if (company) {
      Object.entries(company).forEach(([field, value]) => {
        if (value) {
          setValue(field, field === "approvedAt" ? new Date(value) : value);
        }
      });
    }
  }, [company, setValue]);

  useEffect(() => {
    return () => {
      resetCompany();
    };
  }, [resetCompany]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="company-form">
      <Form.Row>
        <Form.Group as={Col} controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            type="text"
            ref={register({ required: true })}
            isInvalid={!!errors.name}
            maxLength={190}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name?.type === "required" && "Required field."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="axCode">
          <Form.Label>AX code</Form.Label>
          <Form.Control
            name="axCode"
            type="text"
            ref={register}
            isInvalid={!!errors.axCode}
            maxLength={190}
          />
          <Form.Control.Feedback type="invalid">
            {errors.axCode?.type === "required" && "Required field."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="code">
          <Form.Label>Code</Form.Label>
          <Form.Control
            name="code"
            type="text"
            ref={register}
            isInvalid={!!errors.code}
            maxLength={190}
          />
          <Form.Control.Feedback type="invalid">
            {errors.code?.type === "required" && "Required field."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="vatCode">
          <Form.Label>VAT code</Form.Label>
          <Form.Control
            name="vatCode"
            type="text"
            ref={register}
            isInvalid={!!errors.vatCode}
            maxLength={190}
          />
          <Form.Control.Feedback type="invalid">
            {errors.vatCode?.type === "required" && "Required field."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="address">
          <Form.Label>Address</Form.Label>
          <Form.Control
            name="address"
            type="text"
            ref={register}
            isInvalid={!!errors.address}
            maxLength={190}
          />
          <Form.Control.Feedback type="invalid">
            {errors.address?.type === "required" && "Required field."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="postalCode">
          <Form.Label>Postal code</Form.Label>
          <Form.Control
            name="postalCode"
            type="text"
            ref={register}
            isInvalid={!!errors.postalCode}
            maxLength={190}
          />
          <Form.Control.Feedback type="invalid">
            {errors.postalCode?.type === "required" && "Required field."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="city">
          <Form.Label>City</Form.Label>
          <Form.Control
            name="city"
            type="text"
            ref={register}
            isInvalid={!!errors.city}
            maxLength={190}
          />
          <Form.Control.Feedback type="invalid">
            {errors.city?.type === "required" && "Required field."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="country">
          <Form.Label>Country</Form.Label>
          <Form.Control
            name="country"
            type="text"
            ref={register}
            isInvalid={!!errors.country}
            maxLength={190}
          />
          <Form.Control.Feedback type="invalid">
            {errors.country?.type === "required" && "Required field."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="applicationId">
          <Form.Label>Application ID</Form.Label>
          <Form.Control
            name="applicationId"
            type="number"
            min={1}
            ref={register}
            isInvalid={!!errors.applicationId}
            maxLength={190}
          />
          <Form.Control.Feedback type="invalid">
            {errors.applicationId?.type === "required" && "Required field."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="approvedAt">
          <Form.Label>Approved at</Form.Label>
          <DateTimeInput
            name={`approvedAt`}
            isInvalid={!!errors.approvedAt}
            selected={watch("approvedAt")}
            showTimeSelect
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="yyyy-MM-dd HH:mm"
            timeFormat="HH:mm"
            control={control}
            className="date-input"
          />
          <Form.Control.Feedback type="invalid">
            {errors.approvedAt?.type === "required" && "Required field."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="company-form-buttons">
          <ButtonLoader type="submit" disabled={isLoading}>
            {company ? "Update" : "Create"}
          </ButtonLoader>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export { CompanyForm };
