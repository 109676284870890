import { PayloadAction } from "@reduxjs/toolkit";
import { fork, take, call, put, takeLatest, select } from "typed-redux-saga";
import { translated } from "../../../../i18n";
import { projectPolicyAPI } from "../../../../services/api/project-policies";
import { projectAPI } from "../../../../services/api/projects";
import { AppState } from "../../../../store";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../alert/store/slices";
import { confirmedProjects } from "../../list/store/slices/confirmed";
import { projectShow } from "../../show/store/slices";
import { InvoiceFormData } from "../QuickPayModal";
import { quickPayModal } from "./slice";

function* quickPaySaga() {
  yield* fork(generateInvoiceListener);
  yield* fork(uploadInvoiceListener);
  yield* takeLatest(
    quickPayModal.actions.fetchLatestPolicies,
    handleLatestPolicies
  );
}

function* generateInvoiceListener() {
  while (true) {
    const { payload } = yield take(quickPayModal.actions.generateInvoice);

    if (payload.projectId && payload.data) {
      yield* call(generateInvoice, payload.projectId, payload.data);
    }
  }
}

function* uploadInvoiceListener() {
  while (true) {
    const { payload } = yield take(quickPayModal.actions.uploadInvoice);

    if (payload.projectId && payload.data) {
      yield* call(uploadInvoice, payload.projectId, payload.data);
    }
  }
}

function* generateInvoice(projectId: number, data: InvoiceFormData) {
  yield* put(quickPayModal.actions.setIsLoading(true));

  const confirmedPolicies = yield* select(
    (state: AppState) => state.quickPayModal.confirmedPolicies
  );

  const result = yield* call(projectAPI.generateQuickPay, projectId, {
    ...data,
    agreed_with_latest_additional_info_policy: confirmedPolicies,
  });

  if (result && result.success) {
    yield* put(quickPayModal.actions.setModalMustBeClosed(true));

    yield* put(confirmedProjects.actions.disableQuickPay(projectId));

    yield* put(showSuccess(translated.invoiceWillBeGenerated));
  }

  yield* put(quickPayModal.actions.setIsLoading(false));
}

function* uploadInvoice(projectId: number, data: InvoiceFormData) {
  yield* put(quickPayModal.actions.setIsLoading(true));

  const confirmedPolicies = yield* select(
    (state: AppState) => state.quickPayModal.confirmedPolicies
  );

  const result = yield* call(projectAPI.uploadQuickPayDocument, projectId, {
    ...data,
    agreed_with_latest_additional_info_policy: confirmedPolicies,
  });

  if (result && result.success) {
    yield* put(quickPayModal.actions.setModalMustBeClosed(true));

    yield* put(confirmedProjects.actions.disableQuickPay(projectId));

    yield* put(showSuccess(translated.invoiceUploaded));

    yield* put(projectShow.actions.fetchProject(projectId));
  } else if (result && result.errors) {
    const messages: string[] = [];

    Object.entries(result.errors).forEach(([key, error]: any) => {
      let message = `${key}: ${error[0]}`;

      if (error[0] in translated) {
        message = `${key} ${translated[error[0]]}`;
      }

      messages.push(message);
    });

    for (let index = 0; index < messages.length; index++) {
      yield put(showWarning(messages[index]));
    }
  } else {
    yield put(showError(translated.errorWhileUploadingDocument));
  }

  yield* put(quickPayModal.actions.setIsLoading(false));
}

function* handleLatestPolicies({ payload: projectId }: PayloadAction<number>) {
  yield* put(quickPayModal.actions.setLatestPoliciesLoading(true));

  const result = yield* call(projectPolicyAPI.fetchPolicies, projectId);

  if (result && result.data) {
    yield* put(quickPayModal.actions.setLatestPolicies(result.data));
  }

  yield* put(quickPayModal.actions.setLatestPoliciesLoading(false));
}

export { quickPaySaga };
