import { CSSProperties, FC, useEffect, useRef } from "react";
import { FaComment, FaLocationArrow, FaMapMarkerAlt } from "react-icons/fa";
import { IProjectLocation } from "../../../../services/api/projects";
import {
  formatUTCDateTime,
  formatUTCTime,
} from "../../../../utils/date-format";
import { confirmedColor, plannedColor } from "../map/ProjectMap";
import { LocationType } from "./Timeline";

interface IProps {
  type: LocationType;
  location: IProjectLocation;
  isActive: boolean;
  isCurrent?: boolean;
  onClick: () => void;
}

const TimelineItem: FC<IProps> = ({
  type,
  location,
  isActive,
  isCurrent,
  onClick,
}) => {
  const color = type === LocationType.Planned ? plannedColor : confirmedColor;
  const activeDivStyle: CSSProperties = { color, fontWeight: 800 };
  const activeIconStyle: CSSProperties = { fontSize: "30px" };
  const activeDataStyle: CSSProperties = {
    border: `2px solid ${color}`,
    borderRadius: "5px",
    padding: "5px",
  };
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive && ref && ref.current) {
      (ref.current as any).scrollIntoViewIfNeeded();
    }
  }, [isActive, ref]);

  return (
    <div
      className="timeline-item"
      style={isActive ? activeDivStyle : {}}
      onClick={onClick}
      ref={ref}
    >
      <div
        className={`timeline-icon-${type}`}
        style={isActive ? activeIconStyle : {}}
      >
        <FaMapMarkerAlt />
      </div>
      <div
        className="timeline-data"
        style={
          isActive
            ? activeDataStyle
            : {
                padding: "5px",
                border: `2px solid white`,
                borderRadius: "5px",
              }
        }
      >
        <span>{location.addressName}</span>
        <span className="faded">
          {location.dateFrom
            ? formatUTCDateTime(location.dateFrom) +
              (location.dateTo ? " - " + formatUTCTime(location.dateTo) : "")
            : "-"}
        </span>
        <span className="faded">
          {location.latitude} - {location.longtitude}
        </span>
        <span className={`location-type-${type}`}>
          {location.type.name} {isCurrent && <FaLocationArrow />}
        </span>
        {location.comment && (
          <div className="location-comment">
            <span>
              <FaComment />
            </span>
            <span>{location.comment}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export { TimelineItem };
