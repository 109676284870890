import { FC, useCallback, useEffect, useMemo } from "react";
import { Row, Container, Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { email } from "../../../constants/regex";
import { CarrierRoute } from "../../../constants/routes";
import { CompanyRole } from "../../../services/api/profile";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import { Header } from "../../layout/Header";
import { userInvite } from "./store/slices";

export interface IUserInviteData {
  email: string;
  role: string;
}

const UserInvite: FC = () => {
  const { t } = useTranslation("userInvitation");

  const isLoading = useSelector((state) => state.loader.isLoading);
  const invited = useSelector((state) => state.userInvite.invited);
  const settings = useSelector((state) => state.settings.carrier);

  const history = useHistory();

  const dispatch = useDispatch();

  const inviteUser = useCallback(
    (data: IUserInviteData) => dispatch(userInvite.actions.inviteUser(data)),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(userInvite.actions.reset()), [
    dispatch,
  ]);

  const { register, errors, handleSubmit, watch } = useForm<IUserInviteData>();

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  useEffect(() => {
    if (invited) {
      history.push(CarrierRoute.Users.List);
    }
  }, [history, invited]);

  const onSubmit = (data: IUserInviteData) => {
    inviteUser(data);
  };

  const selectedRoleId = watch("role");

  const selectedRole = useMemo(() => {
    if (settings) {
      const role = settings.users.companyRoles.find(
        (role) => role.id.toString() === selectedRoleId
      );

      if (role) {
        return role.code;
      }
    }

    return null;
  }, [settings, selectedRoleId]);

  if (!settings) return null;

  return (
    <Container className="users-invite" fluid>
      <Row>
        <Header
          title={t("userInvitation") as string}
          showCompanySelect
          companySelectDisabled
        />
      </Row>
      <Row className="user-invitation">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label>{t("email")}</Form.Label>
              <Form.Control
                name="email"
                type="text"
                ref={register({ required: true, pattern: email })}
                isInvalid={!!errors.email}
                maxLength={190}
                disabled={isLoading}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.type === "required" &&
                  (t("requiredField") as any)}
                {errors.email?.type === "pattern" && (t("invalidEmail") as any)}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="role">
              <Form.Label>{t("role")}</Form.Label>
              <Form.Control
                name="role"
                as="select"
                ref={register({ required: true })}
                isInvalid={!!errors.role}
                defaultValue={settings.users.companyRoles[0].id}
                disabled={isLoading}
              >
                {Object.values(settings.users.companyRoles).map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Form.Control>
              {selectedRole === CompanyRole.Accountant && (
                <Form.Text className="text-danger">
                  {t("accountantRoleHasReadOnly") as any}
                </Form.Text>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.role?.type === "required" &&
                  (t("requiredField") as any)}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <div className="profile-buttons">
            <ButtonLoader
              variant="primary"
              type="submit"
              disabled={isLoading}
              buttonDisabled={isLoading}
            >
              {t("invite")}
            </ButtonLoader>
          </div>
        </Form>
      </Row>
    </Container>
  );
};

export { UserInvite };
