import { API, HTTPMethod } from ".";
import { LanguageCode } from "../../i18n";

export type GeneralPolicyGroup = {
  id: number;
  code: GeneralPolicyCode;
};

export type GeneralPolicyData = {
  [LanguageCode.EN]: string;
  [LanguageCode.RU]: string;
  [LanguageCode.PL]: string;
  [LanguageCode.LT]: string;
};

export type GeneralPolicy = {
  id: number;
  group: GeneralPolicyGroup;
  data: GeneralPolicyData;
  is_active: boolean;
  created_at?: string;
  updated_at?: string;
};

export enum GeneralPolicyCode {
  PrivacyPolicy = "privacy_policy",
  TermsPolicy = "terms_policy",
  MarketingPolicy = "marketing_policy",
}

const fetchPolicy = async (code: GeneralPolicyCode): Promise<GeneralPolicy> => {
  const response = await API.request<GeneralPolicy>(
    `/general-policies?code=${code}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const generalPolicyAPI = {
  fetchPolicy,
};

export { generalPolicyAPI };
