import { FC } from "react";
import { ReactComponent as Circle } from "./circle.svg";

interface IProps extends google.maps.LatLngLiteral {
  lat: number;
  lng: number;
  mainColor?: string;
}

const CurrentPositionMarker: FC<IProps> = ({ mainColor }) => {
  return (
    <div
      className="current-position-marker"
      style={{
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Circle fill={mainColor} />
    </div>
  );
};

export { CurrentPositionMarker };
