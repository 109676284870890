import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "typed-redux-saga";
import { adminMaintenanceAPI } from "../../../../services/api/admin/maintenance";
import { showError, showSuccess } from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { checkMaintenanceStatus } from "../../../maintenance/store/sagas";
import { maintenance } from "../../../maintenance/store/slices";
import { IMaintenanceForm } from "../Maintenance";
import { adminMaintenance } from "./slices";

function* adminMaintenanceSagas() {
  yield* takeLatest(adminMaintenance.actions.start, start);
  yield* takeLatest(adminMaintenance.actions.stop, stop);
}

function* start({ payload: data }: PayloadAction<IMaintenanceForm>) {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminMaintenanceAPI.storeMaintenance, data);

  if (response && response.data) {
    yield put(maintenance.actions.setData([response.data]));

    yield put(maintenance.actions.showAlert(true));

    yield put(showSuccess("Maintenance mode started."));
  } else {
    yield put(
      showError("Something went wrong while starting maintenance mode.")
    );
  }

  yield put(loader.actions.stopLoader());
}

function* stop() {
  yield put(loader.actions.startLoader());

  const response = yield* call(adminMaintenanceAPI.deleteMaintenance);

  if (response && response.success) {
    yield call(checkMaintenanceStatus);

    yield put(showSuccess("Maintenance mode stopped."));
  } else {
    yield put(
      showError("Something went wrong while stopping maintenance mode.")
    );
  }

  yield put(loader.actions.stopLoader());
}

export { adminMaintenanceSagas };
