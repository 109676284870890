import { maintenance } from "../../components/maintenance/store/slices";
import { profile } from "../../components/profile/store/slices";
import { refreshModal } from "../../components/refresh-modal/store/slices";
import updateBarSlice from "../../components/update-bar/store/slice";
import store from "../../store";
import { log } from "../logger";
import { ITokens } from "./auth";

export type URL = string;

export enum HTTPMethod {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
}

export type Options = {
  method: HTTPMethod;
  jsonRequest?: boolean;
  version?: "v2";
};
export interface IPagination {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export type ApiResource<T, M = null> =
  | {
      data?: T;
      meta?: IPagination | M;
      errors?: any;
      message?: string;
      version?: string;
    }
  | undefined;

export type SuccessResponse =
  | {
      success: boolean;
      errors?: any;
      message?: string;
    }
  | undefined;

const request = async <T>(
  url: URL,
  options: Options,
  data?: any
): Promise<T | any | undefined> => {
  const requestOptions: RequestInit = {
    method: options.method,
    headers: {
      Accept: "application/json",
    },
  };

  // Attach data
  if (data) {
    requestOptions.body = data;
  }

  // Attach JSON headers
  if (options.jsonRequest) {
    requestOptions.headers = {
      ...requestOptions.headers,
      "Content-Type": "application/json",
    };
  }

  // Attach JWT token
  if (options.version) {
    let accessToken = "";

    try {
      const tokens = localStorage.getItem("jwt_tokens");

      if (tokens) {
        const parsedTokens = JSON.parse(tokens) as ITokens;

        if (parsedTokens && parsedTokens.access_token) {
          accessToken = parsedTokens.access_token;
        } else {
          log.error("Access key in JWT token not found");

          return;
        }
      }
    } catch (error) {
      log.error("JWT token parsing error", error);

      return;
    }

    if (accessToken) {
      requestOptions.headers = {
        ...requestOptions.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }
  }

  // Attach API version
  if (options.version) {
    url = `/${options.version}${url}`;
  }

  const protocol = process.env.REACT_APP_PROTOCOL;
  const hostname = process.env.REACT_APP_HOSTNAME;

  if (protocol && hostname) {
    url = protocol + hostname + url;
  }

  try {
    const response = await fetch(url, requestOptions);

    const appVersion = response.headers.get("X-Client-Version");
    const storedAppVersion = localStorage.getItem("appVersion");

    const appVersionChanged = !!(
      appVersion &&
      storedAppVersion &&
      appVersion !== storedAppVersion
    );

    if (appVersionChanged || !storedAppVersion) {
      localStorage.setItem("appVersion", appVersion ? appVersion : "1");
    }

    const sessionSeconds = sessionStorage.getItem("client-time");
    const userNotOpenedPageRecently = Number(sessionSeconds) > 3;

    if (appVersionChanged && userNotOpenedPageRecently) {
      store.dispatch(updateBarSlice.actions.show());
    }

    if (response.status && [401, 403, 500].includes(response.status)) {
      store.dispatch(refreshModal.actions.setVisibility(true));
    }

    if (response.status && [503].includes(response.status)) {
      store.dispatch(profile.actions.logoutProfile());
      store.dispatch(maintenance.actions.showPage(true));
    }

    const contentType = response.headers.get("content-type");

    // Return JSON
    if (
      response &&
      contentType &&
      contentType.indexOf("application/json") !== -1
    ) {
      return response.json();
    }

    return response.blob();
  } catch (error) {
    log.error("Request error", error);
  }
};

const API = {
  request,
};

export { API };
