import { FC, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../common/loaders/Loader";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { email } from "../../constants/regex";
import { GeneralRoute } from "../../constants/routes";
import { Link, useHistory } from "react-router-dom";
import { profile } from "./store/slices";
import { useTranslation } from "react-i18next";
import { Header } from "../layout/Header";

export interface IProfileData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const ProfileEdit: FC = () => {
  const { t } = useTranslation("profileEdit");

  const isLoading = useSelector((state) => state.loader.isLoading);
  const user = useSelector((state) => state.profile.data);
  const profileEdited = useSelector((state) => state.profile.profileEdited);

  const history = useHistory();

  const dispatch = useDispatch();

  const editProfile = useCallback(
    (data: IProfileData) => dispatch(profile.actions.editProfile(data)),
    [dispatch]
  );
  const resetState = useCallback(
    () => dispatch(profile.actions.resetActionStates()),
    [dispatch]
  );

  const { register, errors, handleSubmit, setValue } = useForm<IProfileData>({
    defaultValues: {
      firstName: user?.name,
      lastName: user?.surname,
      email: user?.email,
      phone: user?.phone || "",
    },
  });

  const onSubmit = (data: IProfileData) => {
    editProfile({
      ...data,
      phone: data.phone.includes("+") ? data.phone : "+" + data.phone,
    });
  };

  useEffect(() => {
    if (user) {
      setValue([
        { firstName: user.name },
        { lastName: user.surname },
        { email: user.email },
        { phone: user.phone || "" },
      ]);
    }
  }, [user, setValue]);

  useEffect(() => {
    if (profileEdited) {
      history.push(GeneralRoute.Profile.Show);
    }
  }, [history, profileEdited]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  if (!user) {
    return <Loader isLoading={true} />;
  }

  return (
    <Container fluid className="profile">
      <Row>
        <Header title={t("myProfile") as string} />
      </Row>
      <Row className="profile-edit-form">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} controlId="firstName">
              <Form.Label>{t("firstName")}</Form.Label>
              <Form.Control
                name="firstName"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors.firstName}
                maxLength={190}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName?.type === "required" && t("requiredField")}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="lastName">
              <Form.Label>{t("lastName")}</Form.Label>
              <Form.Control
                name="lastName"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors.lastName}
                maxLength={190}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName?.type === "required" && t("requiredField")}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label>{t("email")}</Form.Label>
              <Form.Control
                name="email"
                type="text"
                ref={register({ required: true, pattern: email })}
                isInvalid={!!errors.email}
                maxLength={190}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.type === "required" && t("requiredField")}
                {errors.email?.type === "pattern" && t("invalidEmail")}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="phone">
              <Form.Label>{t("phone")}</Form.Label>
              <Form.Control
                name="phone"
                type="text"
                ref={register({ required: true, minLength: 8 })}
                isInvalid={!!errors.phone}
                maxLength={190}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone?.type === "required" && t("requiredField")}
                {errors.phone?.type === "minLength" && t("tooShortNumber")}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <div className="profile-buttons">
            <Button
              as={Link}
              variant="outline-secondary"
              to={GeneralRoute.Profile.Show}
              disabled={isLoading}
            >
              {t("cancel")}
            </Button>

            <Button variant="primary" type="submit" disabled={isLoading}>
              {t("save")}
            </Button>
          </div>
        </Form>
      </Row>
    </Container>
  );
};

export { ProfileEdit };
