import { useCallback, useEffect } from "react";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { LanguageCode } from "../../../i18n";
import { Loader } from "../../common/loaders/Loader";
import { Header } from "../../layout/Header";
import { adminGeneralPolicies } from "./store/slices";
import { ButtonLoader } from "../../common/button-loader/ButtonLoader";
import Editor from "../../editor/Editor";
import { GeneralPolicyCode } from "../../../services/api/general-policies";
import { AdminRoute } from "../../../constants/routes";
import { useTranslation } from "react-i18next";

const GeneralPolicyEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const policy = useSelector((state) => state.adminGeneralPolicies.policy);
  const updatedPolicyPublished = useSelector(
    (state) => state.adminGeneralPolicies.updatedPolicyPublished
  );
  const isLoading = useSelector((state) => state.loader.isLoading);

  const fetchPolicy = useCallback(
    (id: string) => dispatch(adminGeneralPolicies.actions.fetchPolicy(id)),
    [dispatch]
  );

  const updatePolicy = useCallback(
    (id: number, policyCode: GeneralPolicyCode) =>
      dispatch(adminGeneralPolicies.actions.updatePolicy({ id, policyCode })),
    [dispatch]
  );

  const setUpdatedPolicyData = useCallback(
    (languageCode: LanguageCode, html: string) =>
      dispatch(
        adminGeneralPolicies.actions.setUpdatedPolicyData({
          languageCode,
          html,
        })
      ),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(adminGeneralPolicies.actions.resetState()),
    [dispatch]
  );

  useEffect(() => {
    if (id) {
      fetchPolicy(id);
    }

    return () => {
      reset();
    };
  }, [id, fetchPolicy, reset]);

  useEffect(() => {
    if (updatedPolicyPublished) {
      history.push(AdminRoute.GeneralPolicies.List);
    }
  }, [history, updatedPolicyPublished]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Header
            title="Edit policy"
            subTitle={policy ? t(policy.group.code, { ns: "policies" }) : ""}
            languageDisabled
          />
        </Col>
      </Row>

      {!!(isLoading && !policy) && <Loader isLoading={isLoading} />}

      {policy && (
        <>
          <Row>
            <Col>
              <Accordion className="language-accordion">
                {Object.values(LanguageCode).map((languageCode) => (
                  <Card key={languageCode}>
                    <Accordion.Toggle as={Card.Header} eventKey={languageCode}>
                      <Link to="#">{languageCode.toUpperCase()}</Link>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={languageCode}>
                      <Card.Body>
                        <Editor
                          initialHtml={policy.data[languageCode]}
                          handleUpdate={(html) =>
                            setUpdatedPolicyData(languageCode, html)
                          }
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="publish-wrapper">
                <ButtonLoader
                  buttonDisabled={isLoading}
                  disabled={isLoading}
                  onClick={() => {
                    if (policy) {
                      updatePolicy(policy.id, policy.group.code);
                    }
                  }}
                >
                  Update
                </ButtonLoader>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default GeneralPolicyEdit;
