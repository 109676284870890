import { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import { FaRegQuestionCircle } from "react-icons/fa";

interface IProps {
  explanation: string;
  iconStyle?: "black" | "white";
}

const QuestionTooltip: FC<IProps> = ({ explanation, iconStyle }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="question-tooltip">{explanation}</Tooltip>}
    >
      <span className="question-tooltip">
        {iconStyle === "white" ? <FaRegQuestionCircle /> : <FaQuestionCircle />}
      </span>
    </OverlayTrigger>
  );
};

export { QuestionTooltip };
