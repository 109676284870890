import classNames from "classnames";
import { FC } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FaExclamationTriangle,
  FaCheck,
  FaInfoCircle,
  FaUser,
  FaTruck,
  FaTrailer,
  FaStopwatch,
} from "react-icons/fa";
import {
  IProjectShort,
  ProjectConfirmStatus,
} from "../../../../services/api/projects";
import { formatCurrency } from "../../../../utils/currency-format";
import {
  formatUTCDateTime,
  formatUTCDate,
  formatUTCTime,
} from "../../../../utils/date-format";
import { ButtonLoader } from "../../../common/button-loader/ButtonLoader";
import { IProfileState } from "../../../profile/store/slices";
import { LocationStatusBadge } from "../LocationStatusBadge";
import { StatusBadge } from "../StatusBadge";
import { IProjectListGroupState } from "../store/slices";
import { Tooltip } from "../Tooltip";
import { getStatusColor, IProjectForArchivation } from "./ProjectTable";

interface IMobileListGroupProps {
  group: IProjectListGroupState;
  handleActiveRow: (id: number) => void;
  fetchMore: (status: ProjectConfirmStatus) => void;
  setQuickPayProject?: (project: IProjectShort | null) => void;
  profile: IProfileState;
  setProjectForArchivation: (project: IProjectForArchivation | null) => void;
  setProjectForUnarchivation: (project: IProjectForArchivation | null) => void;
}

const MobileListGroup: FC<IMobileListGroupProps> = ({
  group,
  handleActiveRow,
  fetchMore,
  setQuickPayProject,
  profile,
  setProjectForArchivation,
  setProjectForUnarchivation,
}) => {
  const { t } = useTranslation("projects");

  return (
    <>
      <div className="project-group">{t(group.id)}</div>
      {group.data.map((project, index) => {
        const {
          id,
          confirmStatus,
          axCode,
          refNumber,
          totalCharge,
          totalChargeCurrency,
          loadingPlace,
          loadingDateFrom,
          loadingDateTo,
          unloadingPlace,
          unloadingDateFrom,
          unloadingDateTo,
          pallets,
          goodsType,
          weight,
          palletsWillChange,
          carriageValue,
          latestConfirmedStatus,
          trailerType,
          temperatureType,
          temperature,
          truckPlates,
          trailerPlates,
          driverName,
          acceptedBy,
          paymentUntil,
          isQuickPayVisible,
          isQuickPayEnabled,
          isQuickPaySubmitted,
          acceptedCreditInvoiceExists,
          archivedAt,
          archivedBy,
          allowArchive,
          allowUnarchive,
          increasedSecurity,
          clientCompany,
        } = project;

        if (!confirmStatus) return null;

        const palletsNumber = pallets
          ? (pallets.match(/\d+/g) || []).join("")
          : "";

        const palletsType =
          pallets && pallets.includes("PAGAL_CMR") ? t("byCMR") : "";

        const palletsText = `${
          palletsNumber ? palletsNumber : palletsType ? palletsType : ""
        }`;

        const missingDetails =
          confirmStatus &&
          confirmStatus.code === ProjectConfirmStatus.Confirmed &&
          !driverName;

        const statusColor = getStatusColor(confirmStatus.code);

        return (
          <>
            <div className="client-company-mobile-wrapper">
              <div
                className="client-company-mobile"
                style={{ backgroundColor: statusColor }}
              >
                {clientCompany.name}
              </div>
            </div>
            <div
              className="mobile-list-item"
              style={{ borderTop: `4px solid ${statusColor}` }}
            >
              {!!(
                !!(
                  confirmStatus &&
                  confirmStatus.code === ProjectConfirmStatus.ConfirmUpdate
                ) || acceptedBy
              ) && (
                <div className="header-data">
                  {!!(
                    confirmStatus &&
                    confirmStatus.code === ProjectConfirmStatus.ConfirmUpdate
                  ) ? (
                    <span className="order-renewed">
                      <FaExclamationTriangle /> {t("orderRenewed")}
                    </span>
                  ) : (
                    <span />
                  )}
                  {acceptedBy ? (
                    <span className="accepted-by">
                      <FaCheck className="text-success" />{" "}
                      {acceptedBy.name || ""} {acceptedBy.surname || ""}
                    </span>
                  ) : (
                    <span />
                  )}
                </div>
              )}

              <div className="top-data">
                <div>
                  <span>{axCode}</span>
                  <div className="ref-nr">
                    <span>{t("refNo")}</span> <span>{refNumber || "-"}</span>
                  </div>
                </div>
                <div>
                  <span>
                    {totalCharge ? formatCurrency(totalCharge) : "-"}{" "}
                    {totalChargeCurrency || "EUR"}
                  </span>
                </div>
              </div>

              {archivedAt && (
                <div>
                  <Tooltip
                    id={`tooltip-archive-badge-${project.id}`}
                    text={`${t("archived")}: ${
                      archivedBy && archivedAt
                        ? `${archivedBy.name} ${
                            archivedBy.surname
                          } ${formatUTCDateTime(archivedAt)}`
                        : archivedAt
                        ? formatUTCDateTime(archivedAt)
                        : ""
                    }`}
                  >
                    <div className="archived-badge">{t("archived")}</div>
                  </Tooltip>
                </div>
              )}

              <div className="confirm-status">
                {confirmStatus && <StatusBadge status={confirmStatus} />}
              </div>

              {!!(
                paymentUntil &&
                [
                  ProjectConfirmStatus.Confirmed,
                  ProjectConfirmStatus.Completed,
                ].includes(confirmStatus.code)
              ) && (
                <div className="payment-date">
                  {t("payment")}: {formatUTCDate(paymentUntil)}
                </div>
              )}

              <div className="middle-data">
                <div>
                  <div>{t("loadingInfo")}</div>
                  <div>
                    <span>{loadingPlace}</span>
                    <span>
                      {loadingDateFrom ? (
                        <span className="faded">
                          {formatUTCDate(loadingDateFrom)}
                        </span>
                      ) : (
                        "-"
                      )}
                    </span>
                    <span>
                      {loadingDateFrom ? (
                        <span className="faded">
                          {formatUTCTime(loadingDateFrom)}
                          {loadingDateTo &&
                            ` - ${formatUTCTime(loadingDateTo)}`}
                        </span>
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <div>{t("unloadingInfo")}</div>
                  <div>
                    <span>{unloadingPlace}</span>
                    <span>
                      {unloadingDateFrom ? (
                        <span className="faded">
                          {formatUTCDate(unloadingDateFrom)}
                        </span>
                      ) : (
                        "-"
                      )}
                    </span>
                    <span>
                      {unloadingDateFrom ? (
                        <span className="faded">
                          {formatUTCTime(unloadingDateFrom)}
                          {unloadingDateTo &&
                            ` - ${formatUTCTime(unloadingDateTo)}`}
                        </span>
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <div>{t("loadType")}</div>
                  <div>
                    <span>{goodsType}</span>
                    <span>
                      {palletsText} {t("pallets")}{" "}
                      {palletsWillChange && (
                        <>
                          <FaInfoCircle
                            className="text-primary"
                            style={{ fontSize: "14px" }}
                          />{" "}
                          {t("willBeChanged")}
                        </>
                      )}
                    </span>
                    {typeof weight !== "object" && (
                      <span className="faded">{weight} KG</span>
                    )}
                    {carriageValue && (
                      <>
                        {increasedSecurity ? (
                          <Tooltip
                            id={`tooltip-${id}-increased-security`}
                            text={t("highValueIncreasedSecurity")}
                            placement="top"
                          >
                            <span className={"bold increased-security"}>
                              {t("value")}: {carriageValue} EUR
                            </span>
                          </Tooltip>
                        ) : (
                          <span className={"bold"}>
                            {t("value")}: {carriageValue} EUR
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div>{t("locationStatus")}</div>
                  <div>
                    {latestConfirmedStatus && latestConfirmedStatus.type ? (
                      <span>
                        <LocationStatusBadge
                          status={latestConfirmedStatus.type}
                        />
                      </span>
                    ) : (
                      <span className="text-danger">{t("notStarted")}</span>
                    )}
                  </div>
                </div>
                <div>
                  <div>{t("trailerType")}</div>
                  <div>
                    <span>{trailerType}</span>
                    <span>
                      {temperatureType ? `${temperatureType} -` : ""}{" "}
                      {temperature || ""}
                    </span>
                  </div>
                </div>
              </div>

              <div className={classNames("bottom-data", "driver-info")}>
                <div
                  className={classNames(
                    !driverName && "text-danger",
                    missingDetails && "driver-info-missing"
                  )}
                >
                  <FaUser /> {driverName ? driverName : t("noData")}
                </div>
                <hr />
                <div>
                  <FaTruck /> {truckPlates ? truckPlates : t("noData")}
                </div>
                <hr />
                <div>
                  <FaTrailer /> {trailerPlates ? trailerPlates : t("noData")}
                </div>
              </div>

              <Button onClick={() => handleActiveRow(id)} size="sm" block>
                Show more
              </Button>

              {isQuickPayVisible && (
                <>
                  <Button
                    block
                    size="sm"
                    variant={
                      isQuickPayEnabled && !isQuickPaySubmitted
                        ? "primary"
                        : isQuickPaySubmitted
                        ? acceptedCreditInvoiceExists
                          ? "success"
                          : "secondary"
                        : "outline-secondary"
                    }
                    disabled={!isQuickPayEnabled || isQuickPaySubmitted}
                    onClick={(event) => {
                      event.stopPropagation();

                      if (!isQuickPayEnabled) return;

                      if (setQuickPayProject) {
                        setQuickPayProject(project);
                      }
                    }}
                  >
                    <span>
                      {isQuickPaySubmitted ? <FaCheck /> : <FaStopwatch />}{" "}
                      {t("quickPayment")}
                    </span>
                  </Button>

                  <div className="qpay-description">
                    <small>
                      {isQuickPaySubmitted
                        ? t("quickPaymentTooltipSubmitted")
                        : isQuickPayEnabled
                        ? t("quickPaymentTooltipUploadOrGenerate")
                        : t("quickPaymentTooltip")}
                    </small>
                  </div>
                </>
              )}

              {!!(allowArchive && !profile.hasAccountantRole) && (
                <Button
                  block
                  size="sm"
                  variant="outline-secondary"
                  onClick={(e) => {
                    if (confirmStatus) {
                      setProjectForArchivation({
                        id,
                        status: confirmStatus.code,
                      });
                    }
                  }}
                >
                  {t("archive")}
                </Button>
              )}

              {!!(allowUnarchive && !profile.hasAccountantRole) && (
                <Button
                  block
                  size="sm"
                  variant="outline-secondary"
                  onClick={(e) => {
                    if (confirmStatus) {
                      setProjectForUnarchivation({
                        id,
                        status: confirmStatus.code,
                      });
                    }
                  }}
                >
                  {t("unarchive")}
                </Button>
              )}
            </div>
            <hr />
          </>
        );
      })}
      {!!(group.meta && group.meta?.current_page < group.meta?.last_page) && (
        <div className="load-more">
          <ButtonLoader
            onClick={() => fetchMore(group.id)}
            variant="outline-secondary"
            size="sm"
            disabled={group.isLoading}
          >
            {t("loadMore")}
          </ButtonLoader>
        </div>
      )}
    </>
  );
};

export default MobileListGroup;
