import { CSSProperties, FC, useState } from "react";
import { FaGlobeEurope, FaSortDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { LanguageSelectModal } from "./LanguageSelectModal";

interface IProps {
  style?: CSSProperties;
}

const LanguageSwitcher: FC<IProps> = ({ style }) => {
  const [languageModalOpen, setLanguageModalOpen] = useState(false);

  const translations = useSelector((state) => state.translations);

  return (
    <div className="language-switcher" style={style}>
      <span onClick={() => setLanguageModalOpen(true)}>
        <FaGlobeEurope /> {translations.current.code.toUpperCase()}{" "}
        <FaSortDown
          style={{
            margin: "0px 0px 8px 5px",
          }}
        />
      </span>
      <LanguageSelectModal
        languageState={translations}
        isVisible={languageModalOpen}
        onClose={() => {
          setLanguageModalOpen(false);
        }}
      />
    </div>
  );
};

export { LanguageSwitcher };
