import { call, put, delay, select, takeLatest } from "typed-redux-saga";
import { adminActivityLog } from "./slices";
import moment from "moment";
import { loader } from "../../../common/loaders/store/slices";
import { AppState } from "../../../../store";
import {
  adminActivityLogAPI,
  IActivityLogRequest,
} from "../../../../services/api/admin/activity-log";
import { showError } from "../../../alert/store/slices";

function* adminActivityLogSagas() {
  yield* takeLatest(adminActivityLog.actions.setSearch, debounceSearch);
  yield* takeLatest(adminActivityLog.actions.setPerPage, fetchList);
  yield* takeLatest(adminActivityLog.actions.setActivePage, fetchList);
  yield* takeLatest(adminActivityLog.actions.setDates, fetchList);
}

function* debounceSearch() {
  const data = yield* select((state: AppState) => state.adminActivityLog.data);

  if (data.length > 0) {
    yield delay(500);
  }

  yield* call(fetchList);
}

function* fetchList() {
  yield put(loader.actions.startLoader());

  const { search, perPage, page, dateFrom, dateTo } = yield* select(
    (state: AppState) => ({
      search: state.adminActivityLog.search,
      perPage: state.adminActivityLog.perPage,
      page: state.adminActivityLog.activePage,
      dateFrom: state.adminActivityLog.dateFrom,
      dateTo: state.adminActivityLog.dateTo,
    })
  );

  const params: IActivityLogRequest = {
    perPage,
    page,
  };

  if (search) {
    params.search = search;
  }

  if (dateFrom) {
    params.dateFrom = moment(new Date(dateFrom)).unix();
  }

  if (dateTo) {
    params.dateTo = moment(new Date(dateTo)).unix();
  }

  const result = yield* call(adminActivityLogAPI.fetch, params);

  if (result && result.data && result.meta) {
    yield put(
      adminActivityLog.actions.storeData({
        data: result.data,
        meta: result.meta,
      })
    );
  } else {
    yield put(showError("Something went wrong while fetching data."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminActivityLogSagas };
