import { FC, useCallback, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Header } from "../layout/Header";
import { ProjectTable } from "./list/table/ProjectTable";
import { projectList } from "./list/store/slices";
import queryString from "query-string";
import { AppModule } from "../../services/api/settings";
import { confirmedProjects } from "./list/store/slices/confirmed";
import { waitingProjects } from "./list/store/slices/waiting";
import { rejectedProjects } from "./list/store/slices/rejected";
import { completedProjects } from "./list/store/slices/completed";

const Projects: FC = () => {
  const { t } = useTranslation("projects");
  // const { orderId: projectId } = useParams<{ orderId: string }>();
  const { search } = useLocation();
  const params = queryString.parse(search);

  const dispatch = useDispatch();

  const searchQuery = useSelector((state) => state.projectList.search);

  const fetchProjects = useCallback(
    (query: string) => dispatch(projectList.actions.setSearch(query)),
    [dispatch]
  );

  const resetData = useCallback(() => {
    dispatch(confirmedProjects.actions.resetData());
    dispatch(waitingProjects.actions.resetData());
    dispatch(rejectedProjects.actions.resetData());
    dispatch(completedProjects.actions.resetData());
  }, [dispatch]);

  const resetPagination = useCallback(() => {
    dispatch(confirmedProjects.actions.resetPagination());
    dispatch(waitingProjects.actions.resetPagination());
    dispatch(rejectedProjects.actions.resetPagination());
    dispatch(completedProjects.actions.resetPagination());
  }, [dispatch]);

  const handleSearch = useCallback(
    (query: string) => {
      fetchProjects(query);
    },
    [fetchProjects]
  );

  useEffect(() => {
    return () => {
      resetData();
      resetPagination();
    };
  }, [resetData, resetPagination]);

  return (
    <>
      <Row className="projects">
        <Header
          title={t("projects") as string}
          handleSearch={handleSearch}
          initialSearch={(params.search as string) || searchQuery}
          showCompanySelect
          appModule={AppModule.Projects}
        />
      </Row>
      <ProjectTable />
    </>
  );
};

export { Projects };
