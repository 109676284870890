import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaExternalLinkAlt,
  FaFileAlt,
  FaMinusSquare,
  FaPlusSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { LanguageCode } from "../../../i18n";
import { ProjectPolicyTitleCode } from "../../../services/api/admin/project-policies";
import { IProject } from "../../../services/api/projects";
import { LanguagePills } from "../../common/language-pills/LanguagePills";

interface IProps {
  project: IProject;
  style?: React.CSSProperties;
  defaultLanguage?: LanguageCode;
}

const DocumentLinksExpandable: FC<IProps> = ({
  project,
  style,
  defaultLanguage,
}) => {
  const { t } = useTranslation("documentLinks");

  const [additionalExpanded, setAdditionalExpanded] = useState<boolean>(false);
  const [specialExpanded, setSpecialExpanded] = useState<boolean>(false);

  const [additionalLanguage, setAdditionalLanguage] = useState<LanguageCode>(
    defaultLanguage || LanguageCode.EN
  );
  const [specialLanguage, setSpecialLanguage] = useState<LanguageCode>(
    defaultLanguage || LanguageCode.EN
  );

  const generalRulesUrl = useMemo(() => {
    switch (defaultLanguage) {
      case LanguageCode.EN:
        return "https://www.girteka.eu/legal-notices/legal-notices-for-carriers";
      case LanguageCode.PL:
        return "https://www.girteka.eu/dokumenty-prawne-dla-przewoznikow";
      case LanguageCode.LT:
        return "https://www.girteka.eu/lt/teisiniai-dokumentai-vezejams";
      case LanguageCode.RU:
        return "https://www.girteka.eu/ru/juridicheskie-dokumenty/juridicheskie-dokumenty-dlja-perevozchikov";
      default:
        return "https://www.girteka.eu/legal-notices/legal-notices-for-carriers";
    }
  }, [defaultLanguage]);

  const secondPolicyTitle = project.secondPolicy
    ? project.secondPolicy.title.code
    : "";
  const secondPolicyText = project.secondPolicy
    ? project.secondPolicy.data[additionalLanguage]
    : "";
  const statisticPolicyText = project.statisticPolicy
    ? project.statisticPolicy.data[specialLanguage]
    : "";

  return (
    <>
      <div className="document-links" style={style}>
        <div>
          <span>
            <FaFileAlt />
            <Link
              to="#"
              onClick={() => setAdditionalExpanded(!additionalExpanded)}
            >
              {t(secondPolicyTitle, { ns: "policies" }).toUpperCase()}
            </Link>
          </span>
          {additionalExpanded ? (
            <FaMinusSquare onClick={() => setAdditionalExpanded(false)} />
          ) : (
            <FaPlusSquare onClick={() => setAdditionalExpanded(true)} />
          )}
        </div>
        {additionalExpanded ? (
          <div className="expanded">
            <LanguagePills
              onSelect={(language) =>
                setAdditionalLanguage(language as LanguageCode)
              }
              defaultLanguage={additionalLanguage}
              style={{
                textTransform: "uppercase",
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: secondPolicyText }} />
          </div>
        ) : null}
        {project.statisticPolicy && (
          <>
            <hr />
            <div>
              <span>
                <FaFileAlt />
                <Link
                  to="#"
                  onClick={() => setSpecialExpanded(!specialExpanded)}
                >
                  {t("specialClientRequirements")}
                </Link>
              </span>
              {specialExpanded ? (
                <FaMinusSquare onClick={() => setSpecialExpanded(false)} />
              ) : (
                <FaPlusSquare onClick={() => setSpecialExpanded(true)} />
              )}
            </div>
            {specialExpanded ? (
              <div className="expanded">
                <LanguagePills
                  onSelect={(language) =>
                    setSpecialLanguage(language as LanguageCode)
                  }
                  defaultLanguage={specialLanguage}
                  style={{
                    textTransform: "uppercase",
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: statisticPolicyText }}
                />
              </div>
            ) : null}
          </>
        )}

        {!!(
          project.secondPolicy &&
          project.secondPolicy.title.code ===
            ProjectPolicyTitleCode.AdditionalInformation
        ) && (
          <>
            <hr />
            <div>
              <span>
                <FaFileAlt />
                <Link
                  to={{
                    pathname: generalRulesUrl,
                  }}
                  target="_blank"
                >
                  {t("generalRulesForCarriers")}
                </Link>
              </span>
              <Link
                to={{
                  pathname: generalRulesUrl,
                }}
                target="_blank"
              >
                <FaExternalLinkAlt />
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export { DocumentLinksExpandable };
