import moment from "moment";
import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "typed-redux-saga";
import { translated } from "../../../../../i18n";
import { IFetchListRequest } from "../../../../../services/api/projects";
import { projectAPI } from "../../../../../services/api/projects";
import { AppModule } from "../../../../../services/api/settings";
import { AppState } from "../../../../../store";
import { showError, showSuccess } from "../../../../alert/store/slices";
import { loader } from "../../../../common/loaders/store/slices";
import { profile } from "../../../../profile/store/slices";
import { projectList } from "../slices";
import { confirmedProjects } from "../slices/confirmed";

function* confirmedProjectSaga() {
  yield* takeLatest(projectList.actions.setSearch, debounceSearch);
  yield* fork(perPageListener);
  yield* fork(paginationListener);
  yield* fork(companyChangeListener);
  yield* fork(clientCompanyWatcher);
  yield* fork(confirmStatusWatcher);
  yield* fork(locationStatusWatcher);
  yield* fork(dateRangeWatcher);
  yield* fork(onlyArchivedWatcher);
  yield* fork(onlyAssignedWatcher);
  yield* fork(onlyIncompleteWatcher);
  yield* fork(archiveWatcher);
  yield* fork(unarchiveWatcher);
}

function* debounceSearch() {
  yield* delay(500);

  yield* put(confirmedProjects.actions.reset());

  yield* call(fetchList);
}

function* perPageListener() {
  while (true) {
    yield take(confirmedProjects.actions.setPerPage);

    yield* call(fetchList);
  }
}

function* paginationListener() {
  while (true) {
    yield take(confirmedProjects.actions.setActivePage);

    yield* call(fetchList, true);
  }
}

function* clientCompanyWatcher() {
  while (true) {
    yield take(projectList.actions.setClientCompanyId);

    yield put(confirmedProjects.actions.reset());

    yield* call(fetchList);
  }
}

function* confirmStatusWatcher() {
  while (true) {
    yield take(projectList.actions.setConfirmStatus);

    yield put(confirmedProjects.actions.reset());

    yield* call(fetchList);
  }
}

function* locationStatusWatcher() {
  while (true) {
    yield take(projectList.actions.setLocationStatus);

    yield put(confirmedProjects.actions.reset());

    yield* call(fetchList);
  }
}

function* dateRangeWatcher() {
  while (true) {
    yield take(projectList.actions.setDateRange);

    yield put(confirmedProjects.actions.reset());

    yield* call(fetchList);
  }
}

function* onlyArchivedWatcher() {
  while (true) {
    yield take(projectList.actions.setOnlyArchived);

    yield put(confirmedProjects.actions.reset());

    yield* call(fetchList);
  }
}

function* onlyAssignedWatcher() {
  while (true) {
    yield take(projectList.actions.setOnlyAssigned);

    yield put(confirmedProjects.actions.reset());

    yield* call(fetchList);
  }
}

function* onlyIncompleteWatcher() {
  while (true) {
    yield take(projectList.actions.setOnlyIncomplete);

    yield put(confirmedProjects.actions.reset());

    yield* call(fetchList);
  }
}

function* companyChangeListener() {
  while (true) {
    const {
      payload: { appModule },
    } = yield take(profile.actions.setCompanyChanged);

    if (appModule === AppModule.Projects) {
      yield put(confirmedProjects.actions.reset());

      yield* call(fetchList);
    }
  }
}

function* archiveWatcher() {
  while (true) {
    const { payload: projectId } = yield take(
      confirmedProjects.actions.archive
    );

    yield* call(archive, projectId);
  }
}

function* unarchiveWatcher() {
  while (true) {
    const { payload: projectId } = yield take(
      confirmedProjects.actions.unarchive
    );

    yield* call(unarchive, projectId);
  }
}

function* fetchList(concatData?: boolean) {
  yield put(confirmedProjects.actions.setLoader(true));

  const {
    id,
    search,
    clientCompanyId,
    confirmStatus,
    locationStatus,
    dateRange,
    onlyArchived,
    onlyAssigned,
    onlyIncomplete,
    perPage,
    page,
    selectedCompany,
  } = yield* select((state: AppState) => ({
    id: state.confirmedProjects.id,
    search: state.projectList.search,
    clientCompanyId: state.projectList.selectedClientCompanyId,
    confirmStatus: state.projectList.confirmStatus,
    locationStatus: state.projectList.locationStatus,
    dateRange: state.projectList.dateRange,
    onlyArchived: state.projectList.onlyArchived,
    onlyAssigned: state.projectList.onlyAssigned,
    onlyIncomplete: state.projectList.onlyIncomplete,
    perPage: state.confirmedProjects.perPage,
    page: state.confirmedProjects.activePage,
    selectedCompany: state.profile.data?.selectedCompany,
  }));

  if (confirmStatus && confirmStatus !== id) {
    yield put(confirmedProjects.actions.setLoader(false));

    return;
  }

  if (selectedCompany) {
    const params: IFetchListRequest = {
      search,
      perPage,
      page,
      companyId: selectedCompany.id,
      onlyArchived: onlyArchived ? 1 : 0,
      onlyAssigned: onlyAssigned ? 1 : 0,
      onlyIncomplete: onlyIncomplete ? 1 : 0,
    };

    params.confirmStatus = id;

    if (locationStatus) {
      params.locationStatus = locationStatus;
    }

    if (clientCompanyId) {
      params.clientCompanyId = clientCompanyId;
    }

    if (dateRange) {
      const [startDate, endDate] = dateRange;

      if (startDate) {
        params.startDate = moment.utc(startDate).format();
      }

      if (endDate) {
        params.endDate = moment.utc(endDate).format();
      }
    }

    const result = yield* call(projectAPI.fetchList, params);

    if (result && result.data && result.meta) {
      if (concatData) {
        yield put(confirmedProjects.actions.concatData(result));
      } else {
        yield put(confirmedProjects.actions.setData(result));
      }
    } else {
      yield put(showError(translated.errorWhileGettingProjects));
    }
  }

  yield put(confirmedProjects.actions.setLoader(false));
}

function* archive(projectId: number) {
  const result = yield* call(projectAPI.archiveProject, projectId);

  if (result && result.success) {
    yield* put(confirmedProjects.actions.reset());

    yield* call(fetchList);

    yield put(showSuccess(translated.projectArchived));
  } else {
    yield put(showError(translated.errorWhileArchivingProject));
  }

  yield put(loader.actions.stopLoader());
}

function* unarchive(projectId: number) {
  const result = yield* call(projectAPI.unarchiveProject, projectId);

  if (result && result.success) {
    yield* put(confirmedProjects.actions.reset());

    yield* call(fetchList);

    yield put(showSuccess(translated.projectUnarchived));
  } else {
    yield put(showError(translated.errorWhileUnarchivingProject));
  }

  yield put(loader.actions.stopLoader());
}

export { confirmedProjectSaga };
