import { call, delay, put, select, takeLatest } from "typed-redux-saga";
import {
  adminApplicationsAPI,
  IFetchApplicationsParams,
} from "../../../../services/api/admin/applications";
import { AppState } from "../../../../store";
import { showError } from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { adminApplications } from "./slices";

function* adminApplicationSagas() {
  yield* takeLatest(adminApplications.actions.setSearch, debounceSearch);
  yield* takeLatest(adminApplications.actions.setPerPage, fetchApplications);
  yield* takeLatest(adminApplications.actions.setActivePage, fetchApplications);
}

function* debounceSearch() {
  yield delay(500);

  yield* call(fetchApplications);
}

function* fetchApplications() {
  yield put(loader.actions.startLoader());

  const { search, perPage, page } = yield* select((state: AppState) => ({
    search: state.adminApplications.search,
    perPage: state.adminApplications.perPage,
    page: state.adminApplications.activePage,
  }));

  const requestParams: IFetchApplicationsParams = {
    search,
    perPage,
    page,
  };

  const response = yield* call(
    adminApplicationsAPI.fetchApplications,
    requestParams
  );

  if (response && response.data && response.meta) {
    yield put(
      adminApplications.actions.setData({
        data: response.data,
        meta: response.meta,
      })
    );
  } else {
    yield put(showError("Something went wrong while getting applications."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminApplicationSagas };
