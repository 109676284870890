import { FC, useCallback, useEffect } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { GeneralRoute } from "../../constants/routes";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profile } from "./store/slices";
import { Loader } from "../common/loaders/Loader";
import { useTranslation } from "react-i18next";
import { Header } from "../layout/Header";
import PasswordRules from "../password-rules/PasswordRules";

export interface IChangePasswordData {
  existingPassword: string;
  password: string;
  password_confirmation: string;
}

const ChangePassword: FC = () => {
  const { t } = useTranslation("changePassword");

  const { register, errors, handleSubmit, watch } = useForm<
    IChangePasswordData
  >();

  const history = useHistory();

  const isLoading = useSelector((state) => state.loader.isLoading);
  const user = useSelector((state) => state.profile.data);
  const passwordChanged = useSelector((state) => state.profile.passwordChanged);

  const dispatch = useDispatch();

  const changePassword = useCallback(
    (data: IChangePasswordData) =>
      dispatch(profile.actions.changePassword(data)),
    [dispatch]
  );

  const resetState = useCallback(
    () => dispatch(profile.actions.resetActionStates()),
    [dispatch]
  );

  const onSubmit = (data: IChangePasswordData) => {
    changePassword(data);
  };

  useEffect(() => {
    if (passwordChanged) {
      history.push(GeneralRoute.Profile.Show);
    }
  }, [history, passwordChanged]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  if (!user) {
    return <Loader isLoading={true} />;
  }

  return (
    <Container fluid className="profile">
      <Row>
        <Header title={t("changePassword") as string} />
      </Row>
      <Row className="profile-edit-form">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} controlId="existingPassword">
              <Form.Label>{t("existingPassword")}</Form.Label>
              <Form.Control
                name="existingPassword"
                type="password"
                ref={register({ required: true })}
                isInvalid={!!errors.existingPassword}
                maxLength={255}
              />
              <Form.Control.Feedback type="invalid">
                {errors.existingPassword?.type === "required" &&
                  t("requiredField")}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="password">
              <Form.Label>New password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                ref={register({
                  required: true,
                  minLength: 8,
                  validate: {
                    containsUpper: (value) => /[A-Z]/.test(value),
                    containsLower: (value) => /[a-z]/.test(value),
                    containsDigits: (value) => /[0-9]/.test(value),
                  },
                })}
                isInvalid={!!errors.password}
                maxLength={190}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password?.type === "required" && "Required field."}
                {errors.password?.type === "minLength" && t("minCharsRequired")}
                {errors.password?.type === "containsUpper" &&
                  t("oneUpperCaseRequired")}
                {errors.password?.type === "containsLower" &&
                  t("oneLowerCaseRequired")}
                {errors.password?.type === "containsDigits" &&
                  t("oneNumberRequired")}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="password_confirmation">
              <Form.Label>{t("confirmPassword")}</Form.Label>
              <Form.Control
                name="password_confirmation"
                type="password"
                ref={register({
                  required: true,
                  minLength: 8,
                  validate: (value) => value === watch("password"),
                })}
                isInvalid={!!errors.password_confirmation}
                maxLength={255}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password_confirmation?.type === "required" &&
                  t("requiredField")}
                {errors.password_confirmation?.type === "validate" &&
                  t("passwordsDontMatch")}
                {errors.password?.type === "minLength" && t("minCharsRequired")}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <PasswordRules />
          </Form.Row>

          <div className="profile-buttons">
            <Button
              as={Link}
              variant="outline-secondary"
              to={GeneralRoute.Profile.Show}
              disabled={isLoading}
            >
              {t("cancel")}
            </Button>

            <Button variant="primary" type="submit" disabled={isLoading}>
              {t("save")}
            </Button>
          </div>
        </Form>
      </Row>
    </Container>
  );
};

export { ChangePassword };
