import { FC, useEffect, useState } from "react";

const GlobalLoader: FC = () => {
  const [logoStyle, setLogoStyle] = useState({
    opacity: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      setLogoStyle({ opacity: 1 });
    }, 1);
  }, []);

  return (
    <div className="global-loader">
      <div style={logoStyle}>
        <img src="/images/logo.svg" alt="logo" />
        <div className="dot-pulse"></div>
      </div>
    </div>
  );
};

export { GlobalLoader };
