import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "../../../../services/api";

interface IApplicationState {
  data: any[];
  perPage: number;
  activePage: number;
  meta: IPagination | null;
  search: string;
}

const initialState: IApplicationState = {
  data: [],
  perPage: 10,
  activePage: 1,
  meta: null,
  search: "",
};

const adminApplications = createSlice({
  name: "adminApplications",
  initialState,
  reducers: {
    fetchApplications: (state, action) => {
      //
    },
    setData: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.activePage = 1;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    reset: () => initialState,
  },
});

export { adminApplications };
