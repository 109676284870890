import { FC, useState } from "react";
import { Image } from "react-bootstrap";
import truck from "../truck.jpg";
import { useTranslation } from "react-i18next";

const IntroPage: FC = () => {
  const { t } = useTranslation("introPageWizard");

  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <div className="intro-page" style={{ opacity: isLoading ? 0 : 1 }}>
      <Image src={truck} fluid onLoad={() => setIsLoading(false)} />
      <div>
        <p>{t("weHaveUpdatedDesign")}</p>
        <p>{t("pleaseReviewWhatChanged")}</p>
      </div>
    </div>
  );
};

export { IntroPage };
