import { FC, useCallback, useMemo, useState } from "react";
import {
  FaMapMarkedAlt,
  FaAddressCard,
  FaFolderOpen,
  FaUsers,
  FaSearch,
  FaCloudUploadAlt,
  FaDatabase,
  FaBuilding,
  FaQuestionCircle,
  FaMailBulk,
  FaExclamationTriangle,
  FaEnvelope,
  FaFileCode,
  FaUserClock,
  FaHistory,
  FaTasks,
  FaRegBuilding,
  FaClipboardCheck,
  FaUser,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { SidebarItem } from "./SidebarItem";
import { useDispatch, useSelector } from "react-redux";
import { authAPI } from "../../../services/api/auth";
import { SidebarLayout } from "./SidebarLayout";
import { SidebarOverlay } from "./SidebarOverlay";
import { AdminAppModule, AppModule } from "../../../services/api/settings";
import { carrierWizard } from "../../wizards/carrier/store/slices";
import {
  AdminRoute,
  CarrierRoute,
  GeneralRoute,
  SuperAdminRoute,
} from "../../../constants/routes";
import { Workspace } from "../../workspace-switcher/store/slice";

const Sidebar: FC = () => {
  const { t } = useTranslation("sidebar");
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(true);

  const profile = useSelector((state) => state.profile);
  const currentWorkspace = useSelector(
    (state) => state.workspace.currentWorkspace
  );

  const toggleCarrierWizard = useCallback(
    () => dispatch(carrierWizard.actions.toggle()),
    [dispatch]
  );

  const handleClick = useCallback(
    (link?: string) => {
      if (link === "/admin" || link === "/developer/login") {
        window.open(link, "_blank");
      } else if (link) {
        history.push(link);
      }

      setCollapsed(true);
    },
    [history]
  );

  const isActive = (moduleName: AppModule | AdminAppModule | string) => {
    return location.pathname.includes(moduleName);
  };

  const showSidebar = useMemo(() => {
    if (profile.data) {
      // const { emailVerifiedAt, termsPolicyAccepted, privacyPolicyAccepted } = profile.data;
      const { emailVerifiedAt, termsPolicyAccepted } = profile.data;

      if (emailVerifiedAt && termsPolicyAccepted) {
        return true;
      }
    }

    return false;
  }, [profile]);

  const notificationsEnabled = useMemo(
    () =>
      profile.isCarrier &&
      !!profile.data &&
      !!profile.data.emailVerifiedAt &&
      // profile.data.privacyPolicyAccepted &&
      profile.data.termsPolicyAccepted &&
      !profile.isManager &&
      !profile.isGroupLeader,
    [profile]
  );

  if (!authAPI.hasTokens() || !profile.data) {
    return null;
  }

  return (
    <>
      <SidebarLayout
        collapsed={collapsed}
        onToggle={() => setCollapsed(!collapsed)}
        profile={profile}
        bottomItems={
          profile.data && profile.data.emailVerifiedAt ? (
            <>
              {currentWorkspace === Workspace.CarrierSpace &&
                (profile.isCarrier || profile.isManager) && (
                  <SidebarItem
                    text={t("help") as string}
                    icon={<FaQuestionCircle />}
                    collapsed={collapsed}
                    active={false}
                    onClick={toggleCarrierWizard}
                  />
                )}
            </>
          ) : (
            <div />
          )
        }
      >
        {showSidebar && (
          <>
            {currentWorkspace === Workspace.ProfileSpace && (
              <>
                <SidebarItem
                  text={t("profile") as string}
                  icon={<FaUser />}
                  link={GeneralRoute.Profile.Show}
                  collapsed={collapsed}
                  active={isActive(AppModule.Profile)}
                  onClick={handleClick}
                />
                {notificationsEnabled && (
                  <>
                    <SidebarItem
                      text={t("mailSettings") as string}
                      icon={<FaEnvelope />}
                      link={GeneralRoute.MailSettings}
                      collapsed={collapsed}
                      active={isActive(AppModule.MailSettings)}
                      onClick={handleClick}
                    />
                  </>
                )}
              </>
            )}
            {currentWorkspace === Workspace.CarrierSpace &&
              (profile.isCarrier || profile.isManager) &&
              !!profile.data.selectedCompany && (
                <>
                  <SidebarItem
                    text={t("projects") as string}
                    icon={<FaMapMarkedAlt />}
                    link={CarrierRoute.Orders.List.replace(":orderId?", "")}
                    collapsed={collapsed}
                    active={isActive(AppModule.Orders)}
                    onClick={handleClick}
                  />
                  <SidebarItem
                    text={t("documents") as string}
                    icon={<FaFolderOpen />}
                    link={CarrierRoute.Documents.List}
                    collapsed={collapsed}
                    active={isActive(AppModule.Documents)}
                    onClick={handleClick}
                  />
                  <SidebarItem
                    text={t("users") as string}
                    icon={<FaUsers />}
                    link={CarrierRoute.Users.List}
                    collapsed={collapsed}
                    active={isActive(AppModule.Users)}
                    onClick={handleClick}
                  />
                </>
              )}
            {currentWorkspace === Workspace.CarrierSpace &&
              (profile.isGuest || profile.isCarrier) && (
                <SidebarItem
                  text={t("applications") as string}
                  icon={<FaAddressCard />}
                  link={CarrierRoute.Applications.List}
                  collapsed={collapsed}
                  active={isActive(AppModule.Applications)}
                  onClick={handleClick}
                />
              )}
            {profile.isSuperAdmin &&
              currentWorkspace === Workspace.DeveloperSpace && (
                <>
                  <SidebarItem
                    text={t("ediUploader") as string}
                    icon={<FaCloudUploadAlt />}
                    link={SuperAdminRoute.EDI.Upload}
                    collapsed={collapsed}
                    active={isActive(AdminAppModule.Edi)}
                    onClick={handleClick}
                  />
                  <SidebarItem
                    text={t("cache") as string}
                    icon={<FaDatabase />}
                    link={SuperAdminRoute.Settings.Cache}
                    collapsed={collapsed}
                    active={isActive(AdminAppModule.Cache)}
                    onClick={handleClick}
                  />
                  <SidebarItem
                    text={t("sessions") as string}
                    icon={<FaUserClock />}
                    link={SuperAdminRoute.Settings.Sessions}
                    collapsed={collapsed}
                    active={isActive(AdminAppModule.Sessions)}
                    onClick={handleClick}
                  />
                  <SidebarItem
                    text="Queues"
                    icon={<FaTasks />}
                    link={SuperAdminRoute.Settings.Queues}
                    collapsed={collapsed}
                    active={isActive(AdminAppModule.Queues)}
                    onClick={handleClick}
                  />
                  <SidebarItem
                    text="Logs"
                    icon={<FaHistory />}
                    link={SuperAdminRoute.Settings.Logs}
                    collapsed={collapsed}
                    active={isActive(AdminAppModule.Logs)}
                    onClick={handleClick}
                  />
                  <SidebarItem
                    text={t("emailTemplates") as string}
                    icon={<FaMailBulk />}
                    link={SuperAdminRoute.Mailing.TestTemplates}
                    collapsed={collapsed}
                    active={location.pathname.includes(
                      SuperAdminRoute.Mailing.TestTemplates
                    )}
                    onClick={handleClick}
                  />
                  <SidebarItem
                    text={t("issueMail") as string}
                    icon={<FaEnvelope />}
                    link={SuperAdminRoute.Mailing.SendIssueMail}
                    collapsed={collapsed}
                    active={location.pathname.includes(
                      SuperAdminRoute.Mailing.SendIssueMail
                    )}
                    onClick={handleClick}
                  />
                  <SidebarItem
                    text={t("maintenance") as string}
                    icon={<FaExclamationTriangle />}
                    link={SuperAdminRoute.Maintenance.Show}
                    collapsed={collapsed}
                    active={isActive(AdminAppModule.Maintenance)}
                    onClick={handleClick}
                  />
                </>
              )}
            {profile.isAdmin && currentWorkspace === Workspace.AdminSpace && (
              <>
                <SidebarItem
                  text={t("users") as string}
                  icon={<FaUsers />}
                  link={AdminRoute.Users.List}
                  collapsed={collapsed}
                  active={isActive(AdminAppModule.Users)}
                  onClick={handleClick}
                />
                <SidebarItem
                  text={t("applications") as string}
                  icon={<FaAddressCard />}
                  link={AdminRoute.Applications.List}
                  collapsed={collapsed}
                  active={isActive(AdminAppModule.Applications)}
                  onClick={handleClick}
                />
                <SidebarItem
                  text={t("activityLog") as string}
                  icon={<FaSearch />}
                  link={AdminRoute.ActivityLog.List}
                  collapsed={collapsed}
                  active={isActive(AdminAppModule.ActivityLog)}
                  onClick={handleClick}
                />
                <SidebarItem
                  text={t("documents") as string}
                  icon={<FaFolderOpen />}
                  link={AdminRoute.Documents.List}
                  collapsed={collapsed}
                  active={isActive(AdminAppModule.Documents)}
                  onClick={handleClick}
                />
                <SidebarItem
                  text={t("projects") as string}
                  icon={<FaMapMarkedAlt />}
                  link={AdminRoute.Orders.List}
                  collapsed={collapsed}
                  active={isActive(AdminAppModule.Orders)}
                  onClick={handleClick}
                />
                <SidebarItem
                  text={"Carrier companies"}
                  icon={<FaBuilding />}
                  link={AdminRoute.Companies.List}
                  collapsed={collapsed}
                  active={isActive("admin/" + AdminAppModule.Companies)}
                  onClick={handleClick}
                />
                <SidebarItem
                  text="Everwest companies"
                  icon={<FaRegBuilding />}
                  link={AdminRoute.ClientCompanies.List}
                  collapsed={collapsed}
                  active={isActive("admin/" + AdminAppModule.ClientCompanies)}
                  onClick={handleClick}
                />
                <SidebarItem
                  text={"Axapta files"}
                  icon={<FaFileCode />}
                  link={AdminRoute.Files.List}
                  collapsed={collapsed}
                  active={isActive("admin/" + AdminAppModule.Files)}
                  onClick={handleClick}
                />
                <SidebarItem
                  text="General policies"
                  icon={<FaClipboardCheck />}
                  link={AdminRoute.GeneralPolicies.List}
                  collapsed={collapsed}
                  active={isActive(AdminAppModule.GeneralPolicies)}
                  onClick={handleClick}
                />
                <SidebarItem
                  text="Order policies"
                  icon={<FaClipboardCheck />}
                  link={AdminRoute.ProjectPolicies.List}
                  collapsed={collapsed}
                  active={isActive(AdminAppModule.ProjectPolicies)}
                  onClick={handleClick}
                />
                <SidebarItem
                  text="Special client policies"
                  icon={<FaClipboardCheck />}
                  link={AdminRoute.StatisticPolicies.List}
                  collapsed={collapsed}
                  active={isActive(AdminAppModule.StatisticPolicies)}
                  onClick={handleClick}
                />
              </>
            )}
          </>
        )}
      </SidebarLayout>
      <SidebarOverlay
        collapsed={collapsed}
        onClick={() => setCollapsed(true)}
      />
    </>
  );
};

export { Sidebar };
