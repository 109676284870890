import { call, put, select, takeLatest } from "typed-redux-saga";
import i18n, { ILanguage, LanguageCode } from "../../../i18n";
import { IProfile, profileAPI } from "../../../services/api/profile";
import { AppState } from "../../../store";
import { profile as profileSlice } from "../../profile/store/slices";
import { translations, translations as translationsSlice } from "./slice";
import { setDefaultLocale } from "react-datepicker";
import { PayloadAction } from "@reduxjs/toolkit";

function* translationSaga() {
  yield* takeLatest(profileSlice.actions.setProfileData, syncProfile);
  yield* takeLatest(translationsSlice.actions.change, changeLanguage);
}

function* changeLanguage({ payload: { code } }: PayloadAction<ILanguage>) {
  const profile = yield* select((state: AppState) => state.profile);

  yield i18n.changeLanguage(code);

  if (profile.data) {
    const response = yield* call(profileAPI.changeLanguage, code);

    if (response && response.success) {
      yield put(profileSlice.actions.fetchProfile());
    }
  }

  localStorage.setItem("defaultLanguage", code);

  // Set react-datepicker locale
  yield call(setDefaultLocale, code);

  yield put(translationsSlice.actions.setReady());
}

function* syncProfile({ payload: data }: PayloadAction<IProfile>) {
  const currentLanguage = yield* select(
    (state: AppState) => state.translations.current
  );

  if (data.language !== currentLanguage.code) {
    const language = yield* call(findLanguage, data.language);

    yield put(translations.actions.change(language));
  }

  yield put(translations.actions.profileSynced());
}

function* findLanguage(languageCode: LanguageCode) {
  const selectableLanguages = yield* select(
    (state: AppState) => state.translations.selectable
  );

  const language = selectableLanguages.find(
    (language) => language.code === languageCode
  );

  return language ? language : selectableLanguages[0];
}

export { translationSaga };
