import { Footer } from "./Footer";

type Props = {
  children: React.ReactNode;
};

const PrivateTemplate = ({ children }: Props) => {
  return (
    <div className="private-template">
      {children}
      <Footer loggedIn />
    </div>
  );
};

export { PrivateTemplate };
