import { API, ApiResource, HTTPMethod } from "..";
import { IUploadXmlFormData } from "../../../components/admin/edi/UploadXml";

export enum DocumentType {
  TransportationStatus = "TransportationStatus",
  Invoice = "Invoice",
  Tender = "Tender",
  ForwardingInstructions = "ForwardingInstructions",
  OrderStatus = "OrderStatus",
  TenderStatus = "TenderStatus",
  OrderResponse = "OrderResponse",
}

export interface IUploadXmlResponse {
  successful: string;
  message: string;
  errorCode: string | number;
}

const uploadXml = async ({
  file,
  documentDate,
  documentNumber,
  documentType,
  senderCode,
  receiverCode,
}: IUploadXmlFormData): Promise<ApiResource<IUploadXmlResponse[]>> => {
  const data = new FormData();

  data.append("document", file[0]);
  data.append("documentDate", documentDate);
  data.append("documentNumber", documentNumber);
  data.append("documentType", documentType);
  data.append("senderCode", senderCode);
  data.append("receiverCode", receiverCode);

  const response = await API.request<ApiResource<IUploadXmlResponse[]>>(
    `/super-admin/edi/upload`,
    {
      method: HTTPMethod.POST,
      version: "v2",
    },
    data
  );

  return response;
};

const adminEdiAPI = {
  uploadXml,
};

export { adminEdiAPI };
