import { FC, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaRegEnvelope,
  FaPhoneAlt,
  FaRegBuilding,
  FaTrash,
} from "react-icons/fa";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { IUser } from "../../../services/api/users";
import { userShow } from "./store/slices";
import { Loader } from "../../common/loaders/Loader";
import { CarrierRoute } from "../../../constants/routes";
import { ConfirmModal } from "../../common/modals/ConfirmModal";
import { useTranslation } from "react-i18next";

interface IProps {
  user: IUser;
}

const UserShow: FC<IProps> = ({ user }) => {
  const { t } = useTranslation("userShow");

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const profile = useSelector((state) => state.profile);
  const removed = useSelector((state) => state.userShow.removed);

  const history = useHistory();

  const dispatch = useDispatch();
  const removeUser = useCallback(
    () => dispatch(userShow.actions.removeUser(user.id)),
    [dispatch, user.id]
  );

  useEffect(() => {
    if (removed) {
      history.push(CarrierRoute.Users.List);
    }
  }, [removed, history]);

  const handleDelete = () => {
    removeUser();
    setDeleteModalVisible(false);
  };

  if (!profile.data) return null;

  if (!user) {
    return <Loader isLoading={true} />;
  }

  const buttonsDisabled = profile.hasAccountantRole || profile.hasManagerRole;

  return (
    <div className="profile">
      <div className="info">
        <span className="name">
          {user.name && user.surname ? `${user.name} ${user.surname}` : "-"}
        </span>
        <span className="faded">
          {user.roles.map((role) => role.name).join(", ")}
        </span>
      </div>
      <div className="details">
        {user.companies.map((company, index) => (
          <span key={index}>
            <FaRegBuilding /> {company.name}{" "}
            {!!company.roles?.length && (
              <>({company.roles?.map((role) => role.name).join(", ")})</>
            )}
          </span>
        ))}
        <span>
          <FaRegEnvelope /> {user.email}
        </span>
        <span>
          <FaPhoneAlt /> {user.phone || "-"}
        </span>
      </div>
      <div className="user-buttons">
        <Button
          as={Link}
          to={CarrierRoute.Users.Edit.replace(":userId", user.id.toString())}
          variant="primary"
          className={buttonsDisabled ? "disabled" : ""}
        >
          {t("changeRole")}
        </Button>
        <Button
          onClick={() => {
            setDeleteModalVisible(true);
          }}
          variant="outline-secondary"
          disabled={buttonsDisabled}
        >
          {t("removeFromCompany")}
        </Button>
      </div>

      <ConfirmModal
        icon={<FaTrash />}
        question={`${t("removeFromCompany")}?`}
        handleDeny={() => {
          setDeleteModalVisible(false);
        }}
        handleConfirm={handleDelete}
        isVisible={deleteModalVisible}
      />
    </div>
  );
};

export { UserShow };
