import moment from "moment";
import { call, delay, put, select, takeLatest } from "typed-redux-saga";
import {
  adminFilesAPI,
  IFetchFilesParams,
} from "../../../../services/api/admin/files";
import { AppState } from "../../../../store";
import { showError } from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { adminFiles } from "./slices";

function* adminFilesSagas() {
  yield* takeLatest(adminFiles.actions.setSearch, debounceSearch);
  yield* takeLatest(adminFiles.actions.setPerPage, fetchDocuments);
  yield* takeLatest(adminFiles.actions.setActivePage, fetchDocuments);
  yield* takeLatest(adminFiles.actions.setDocumentType, fetchDocuments);
  yield* takeLatest(adminFiles.actions.setDocumentDirection, fetchDocuments);
  yield* takeLatest(adminFiles.actions.setClient, fetchDocuments);
  yield* takeLatest(adminFiles.actions.setDates, fetchDocuments);
}

function* debounceSearch() {
  const data = yield* select((state: AppState) => state.adminFiles.data);

  if (data.length > 0) {
    yield delay(500);
  }

  yield* call(fetchDocuments);
}

function* fetchDocuments() {
  yield put(loader.actions.startLoader());

  const {
    search,
    perPage,
    page,
    documentType,
    documentDirection,
    client,
    dateFrom,
    dateTo,
  } = yield* select((state) => ({
    search: state.adminFiles.search,
    perPage: state.adminFiles.perPage,
    page: state.adminFiles.activePage,
    documentType: state.adminFiles.documentType,
    documentDirection: state.adminFiles.documentDirection,
    client: state.adminFiles.client,
    dateFrom: state.adminFiles.dateFrom,
    dateTo: state.adminFiles.dateTo,
  }));

  const requestParams: IFetchFilesParams = {
    search,
    perPage,
    page,
    documentType,
    documentDirection,
    client,
    dateFrom,
    dateTo,
  };

  if (dateFrom) {
    requestParams.dateFrom = moment.utc(dateFrom).format();
  }

  if (dateTo) {
    requestParams.dateTo = moment.utc(dateTo).format();
  }

  const respose = yield* call(adminFilesAPI.fetchDocuments, requestParams);

  if (respose && respose.data && respose.meta) {
    yield put(
      adminFiles.actions.setData({
        data: respose.data,
        meta: respose.meta,
      })
    );
  } else {
    yield put(showError("Something went wrong while getting documents."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminFilesSagas };
