import { createSlice } from "@reduxjs/toolkit";
import { ProjectPolicy } from "../../../services/api/admin/project-policies";

export interface IPolicyState {
  privacyPolicy: ProjectPolicy | null;
  marketingPolicy: ProjectPolicy | null;
  termsPolicy: ProjectPolicy | null;

  privacyPolicyVisible: boolean;
  termsConditionsVisible: boolean;
  marketingPolicyVisible: boolean;

  isLoading: boolean;
}

const initialState: IPolicyState = {
  privacyPolicy: null,
  marketingPolicy: null,
  termsPolicy: null,

  privacyPolicyVisible: false,
  termsConditionsVisible: false,
  marketingPolicyVisible: false,

  isLoading: false,
};

const policies = createSlice({
  name: "policies",
  initialState,
  reducers: {
    acceptPolicies: (state) => {
      //
    },
    submitMarketingPolicy: (state, action) => {
      //
    },
    togglePrivacyPolicy: (state) => {
      state.privacyPolicyVisible = !state.privacyPolicyVisible;
    },
    toggleTermsConditions: (state) => {
      state.termsConditionsVisible = !state.termsConditionsVisible;
    },
    toggleMarketingPolicy: (state) => {
      state.marketingPolicyVisible = !state.marketingPolicyVisible;
    },
    fetchPrivacyPolicy: (state) => {
      //
    },
    fetchMarketingPolicy: (state) => {
      //
    },
    fetchTermsPolicy: (state) => {
      //
    },
    setPrivacyPolicy: (state, action) => {
      state.privacyPolicy = action.payload;
    },
    setMarketingPolicy: (state, action) => {
      state.marketingPolicy = action.payload;
    },
    setTermsPolicy: (state, action) => {
      state.termsPolicy = action.payload;
    },
    resetPrivacyPolicy: (state) => {
      state.privacyPolicy = null;
    },
    resetMarketingPolicy: (state) => {
      state.marketingPolicy = null;
    },
    resetTermsPolicy: (state) => {
      state.termsPolicy = null;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    reset: () => initialState,
  },
});

export { policies };
