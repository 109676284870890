import { BsFillCaretUpFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import { AdminRoute } from "../../../../constants/routes";
import { ICompany } from "../../../../services/api/profile";
import { formatDateTime } from "../../../../utils/date-format";
import { ActionCell } from "./cells/ActionCell";

const companiesListColumn: Column<ICompany>[] = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "AX code",
    accessor: "axCode",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Code",
    accessor: "code",
  },
  {
    Header: "VAT code",
    accessor: "vatCode",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Postal code",
    accessor: "postalCode",
  },
  {
    Header: "City",
    accessor: "city",
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "Application ID",
    Cell: ({ cell }: any) => {
      const company = (cell.row.original as unknown) as ICompany;

      if (!company.applicationId) {
        return null;
      }

      return (
        <>
          <span>
            <Link
              to={`${AdminRoute.Applications.List}?search=${company.applicationId}`}
            >
              {company.applicationId}
            </Link>
          </span>
        </>
      );
    },
  },
  {
    Header: "Approved at",
    Cell: ({ cell }: any) => {
      const { approvedAt } = cell.row.original as ICompany;

      return <span>{formatDateTime(approvedAt)}</span>;
    },
  },
  {
    id: "Created at",
    Header: () => (
      <span>
        <BsFillCaretUpFill /> Created at
      </span>
    ),
    Cell: ({ cell }: any) => {
      const { createdAt } = cell.row.original as ICompany;

      return <span>{formatDateTime(createdAt)}</span>;
    },
  },
  {
    Header: "Updated at",
    Cell: ({ cell }: any) => {
      const { updatedAt } = cell.row.original as ICompany;

      return <span>{formatDateTime(updatedAt)}</span>;
    },
  },
  {
    Header: "Actions",
    Cell: (cell: any) => <ActionCell cell={cell} />,
    disableSortBy: true,
  },
];

export { companiesListColumn };
