import { call, put, select, takeLatest } from "typed-redux-saga";
import { IProfile, Role } from "../../../../services/api/profile";
import { wizardsAPI } from "../../../../services/api/wizards";
import { AppState } from "../../../../store";
import { findRole } from "../../../profile/store/helpers";
import { profile } from "../../../profile/store/slices";
import { carrierWizard } from "./slices";
import { profile as profileSlices } from "../../../profile/store/slices";
import { PayloadAction } from "@reduxjs/toolkit";

function* carrierWizardSagas() {
  yield* takeLatest(carrierWizard.actions.checkSeen, checkSeen);
  yield* takeLatest(profile.actions.setProfileData, toggleWizard);
}

function* checkSeen() {
  const profile = yield* select((state: AppState) => state.profile);

  if (
    profile.data &&
    (profile.isCarrier || profile.isManager) &&
    profile.data.showCarrierWizard
  ) {
    const response = yield* call(wizardsAPI.checkSeenCarrierWizard);

    if (response && response.success) {
      yield put(profileSlices.actions.hideCarrierWizard());
    }
  }
}

function* toggleWizard({ payload: data }: PayloadAction<IProfile>) {
  const isVisible = yield* select(
    (state: AppState) => state.carrierWizard.isVisible
  );

  const {
    showCarrierWizard,
    roles,
    forceUpdatePassword,
    forceUpdateProfile,
    emailVerifiedAt,
    name,
    surname,
    // privacyPolicyAccepted,
    termsPolicyAccepted,
    marketingPolicySubmitted,
  } = data;

  if (
    !isVisible &&
    data &&
    showCarrierWizard &&
    roles &&
    findRole(roles, Role.Carrier) &&
    !forceUpdatePassword &&
    !forceUpdateProfile &&
    emailVerifiedAt &&
    name &&
    surname &&
    // privacyPolicyAccepted &&
    termsPolicyAccepted &&
    marketingPolicySubmitted
  ) {
    yield put(carrierWizard.actions.toggle());
  }
}

export { carrierWizardSagas };
