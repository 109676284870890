/**
 * Scrolls to the document section
 */
export const scrollToContent = (timeout: number) => {
  setTimeout(() => {
    const panelDiv = document.getElementById("panel-content");
    const modalDiv = document.getElementsByClassName("modal-body")[0];

    if (panelDiv) {
      panelDiv.scrollTop = panelDiv.scrollHeight;
    } else if (modalDiv) {
      modalDiv.scrollTop = modalDiv.scrollHeight;
    }
  }, timeout);
};
