import { FC } from "react";
import { Col, Form, ListGroup, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import Truncate from "react-truncate";
import { IDocument, IDocumentType } from "../../../services/api/documents";
import { formatBytes } from "../../../utils/byte-format";
import { QuestionTooltip } from "../../common/tooltips/QuestionTooltip";
import { ILongApplicationForm } from "./NewPartnerForm";
import { useSelector } from "react-redux";

export interface IUpdateDocumentData {
  documentTypeId: number;
}

interface IProps {
  files: IDocument[];
  disabled?: boolean;
  required?: boolean;
  onUpdate: (id: number, documentTypeId: number) => void;
  onDelete: (id: number) => void;
  documentTypes: IDocumentType[];
}

const FileDropdown: FC<IProps> = ({
  files,
  disabled,
  required,
  onUpdate,
  onDelete,
  documentTypes,
}) => {
  const { t } = useTranslation("applicationFiles");

  const settings = useSelector((state) => state.settings.carrier);

  const { register, errors } = useFormContext<ILongApplicationForm>();

  const handleDelete = (id: number) => {
    if (!disabled) {
      onDelete(id);
    }
  };

  const error = errors as any;

  const getError = (id: number): any | null => {
    if (
      error &&
      error["documents"] &&
      error["documents"][id] &&
      error["documents"][id]["type"] &&
      error["documents"][id]["type"]["id"]
    ) {
      return error["documents"][id]["type"]["id"];
    }

    return null;
  };

  const fileSizeSum = Object.values(files)
    .map((file) => file.size)
    .reduce((a, b) => a + b, 0);

  const fileList = Object.values(files);

  return (
    <ListGroup
      variant="flush"
      className="file-dropdown"
      style={{ marginTop: fileList.length > 0 ? "20px" : "0px" }}
    >
      {fileList.map((file, index) => (
        <ListGroup.Item key={index}>
          <Row>
            <Col xs={12} sm={4}>
              <Truncate title={file.fileName}>{file.fileName}</Truncate>
            </Col>
            <Col xs={12} sm={3}>
              {formatBytes(file.size)}
            </Col>
            <Col xs={12} sm={4} className="document-type">
              <Form.Control
                style={{ width: "100%" }}
                size="sm"
                name={`documents.${index}.type.id`}
                as="select"
                onChange={(e) => onUpdate(file.id, Number(e.target.value))}
                isInvalid={!!getError(index)}
                ref={register({ required })}
                disabled={disabled}
                defaultValue=""
              >
                <option disabled value="">
                  {t("selectType")}
                </option>
                {documentTypes.map((type, index) => (
                  <option key={index} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {getError(index) &&
                  getError(index).type === "required" &&
                  t("requiredField")}
              </Form.Control.Feedback>
            </Col>
            <Col xs={12} sm={1} className="document-delete">
              <FaTrash
                className={disabled ? "disabled" : ""}
                onClick={() => handleDelete(file.id)}
              />
            </Col>
          </Row>
        </ListGroup.Item>
      ))}
      {fileSizeSum > 0 && (
        <ListGroup.Item className="all-files">
          <Row>
            <Col xs={12} sm={4}>
              {t("allFiles")}
            </Col>
            <Col xs={12} sm={3} className="max-file-size">
              {formatBytes(fileSizeSum)}{" "}
              <QuestionTooltip
                explanation={`${t("totalSizeLimit")}: ${formatBytes(
                  settings ? settings.general.maxUploadSize * 1000 : 0
                )}`}
                iconStyle="white"
              />
            </Col>
            <Col sm={4} className="document-type"></Col>
            <Col sm={1} className="document-delete"></Col>
          </Row>
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export { FileDropdown };
