import { startCase } from "lodash";
import { call, fork, put, take } from "typed-redux-saga";
import { translated } from "../../../../i18n";
import { authAPI } from "../../../../services/api/auth";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { IForgotPasswordData } from "../ForgotPassword";
import { IResetPasswordData } from "../ResetPassword";
import { forgotPassword } from "./slices";

function* forgotPasswordSaga() {
  yield* fork(forgotPasswordWatcher);
  yield* fork(resetPasswordWatcher);
}

function* forgotPasswordWatcher() {
  while (true) {
    const { payload } = yield take(forgotPassword.actions.sendForgotPassword);

    if (payload) {
      yield* call(sendForgotPassword, payload);
    }
  }
}

function* resetPasswordWatcher() {
  while (true) {
    const { payload } = yield take(forgotPassword.actions.resetPassword);

    if (payload) {
      yield* call(resetPassword, payload);
    }
  }
}

function* sendForgotPassword(data: IForgotPasswordData) {
  yield put(loader.actions.startLoader());

  const response = yield* call(authAPI.forgotPassword, data);

  if (response && response.success) {
    yield put(forgotPassword.actions.setResetEmailSent());

    yield put(showSuccess(translated.resetLinkSent));
  } else if (response && response.errors) {
    const firstErrorKey = Object.keys(response.errors)[0];

    yield put(
      showWarning(
        `${startCase(firstErrorKey)}: ${response.errors[firstErrorKey]}`
      )
    );
  } else {
    yield put(showError(translated.errorWhileSendingLink));
  }

  yield put(loader.actions.stopLoader());
}

function* resetPassword(data: IResetPasswordData) {
  yield put(loader.actions.startLoader());

  const response = yield* call(authAPI.resetPassword, data);

  if (response && response.success) {
    yield put(forgotPassword.actions.setPasswordReseted());

    yield put(showSuccess(translated.passwordResetedPleaseLogin));
  } else if (response && response.errors) {
    const firstErrorKey = Object.keys(response.errors)[0];

    yield put(
      showWarning(
        `${startCase(firstErrorKey)}: ${response.errors[firstErrorKey]}`
      )
    );
  } else {
    yield put(showError(translated.errorWhileResetingPassword));
  }

  yield put(loader.actions.stopLoader());
}

export { forgotPasswordSaga };
