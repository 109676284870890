import { FC, Fragment, useCallback, useMemo } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TableOptions, useTable } from "react-table";
import { usersListColumn } from "./columns";
import Pagination from "react-js-pagination";
import { adminUsers } from "../store/slices";
import { IUser } from "../../../../services/api/users";
import { Loader } from "../../../common/loaders/Loader";
import { NoData } from "../../../common/no-data/NoData";
import { ShowPages } from "../../../common/show-pages/ShowPages";
import { ShowPagesInfo } from "../../../common/show-pages/ShowPagesInfo";

const UsersTable: FC = () => {
  const isLoading = useSelector((state) => state.loader.isLoading);
  const data = useSelector((state) => state.adminUsers.usersData);
  const activePage = useSelector((state) => state.adminUsers.usersActivePage);
  const perPage = useSelector((state) => state.adminUsers.usersPerPage);
  const meta = useSelector((state) => state.adminUsers.meta);

  const dispatch = useDispatch();

  const setActivePage = useCallback(
    (page: number) => dispatch(adminUsers.actions.setUsersActivePage(page)),
    [dispatch]
  );

  const setPerPage = useCallback(
    (pages: number) => dispatch(adminUsers.actions.setUsersPerPage(pages)),
    [dispatch]
  );

  const columns = useMemo(() => usersListColumn, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<TableOptions<IUser>>({
    columns,
    data,
    initialState: {
      pageIndex: activePage,
    },
  });

  return (
    <>
      <Table
        striped
        bordered
        responsive
        hover
        {...getTableProps()}
        className="users-table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <div className="list-loader">
                  <Loader />
                </div>
              </td>
            </tr>
          )}
          {rows.length === 0 && !isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <NoData />
              </td>
            </tr>
          )}
          {!isLoading &&
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <Fragment key={index}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
        </tbody>
      </Table>
      <div className="list-footer">
        <ShowPages setPerPage={setPerPage} perPage={perPage} />
        <ShowPagesInfo activePage={activePage} perPage={perPage} meta={meta} />
        <Pagination
          hideNavigation
          activePage={activePage}
          itemsCountPerPage={perPage}
          totalItemsCount={meta && meta.total ? meta.total : 0}
          pageRangeDisplayed={5}
          onChange={(page) => setActivePage(page)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </>
  );
};

export { UsersTable };
