import { FC, useCallback, useEffect, useMemo } from "react";
import { Container, Row } from "react-bootstrap";
import { DocumentsTable } from "./table/DocumentsTable";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Header } from "../layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { documents } from "./store/slices";
import { SubHeader } from "../layout/SubHeader";
import {
  DocumentGroupCode,
  DocumentStatus,
} from "../../services/api/documents";
import { AppModule } from "../../services/api/settings";
import { ListFilter } from "../common/list-filter/ListFilter";
import { AuthorFilter } from "./table/filters/AuthorFilter";
import { DeletedFilter } from "./table/filters/DeletedFilter";
import Select, { StylesConfig } from "react-select";

const getStatusColor = (code: DocumentStatus | null) => {
  let statusColor = "";

  switch (code) {
    case DocumentStatus.Uploaded:
      statusColor = "#17a2b8";
      break;
    case DocumentStatus.Pending:
      statusColor = "#939598";
      break;
    case DocumentStatus.Accepted:
      statusColor = "#3EB073";
      break;
    case DocumentStatus.Rejected:
      statusColor = "#ce181e";
      break;
    case DocumentStatus.Generated:
      statusColor = "#8BDC97";
      break;
    case DocumentStatus.Waiting:
      statusColor = "#f68b28";
      break;
  }

  return statusColor;
};

const dot = (projectConfirmStatus = null) => {
  const color = getStatusColor(projectConfirmStatus);

  return {
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color ? color : "none",
      borderRadius: 10,
      content: color ? '" "' : "",
      display: "block",
      marginRight: color ? 8 : 0,
      height: color ? 10 : 0,
      width: color ? 10 : 0,
    },
  };
};

const selectStyle: StylesConfig<any, false> = {
  control: (provided) => ({
    ...provided,
    width: "220px",
    border: "1px solid #ced4da",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #94d9b4",
    },
    minHeight: 31,
    height: 31,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0b5669" : undefined,
    "&:hover": {
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0b5669" : "#f1f2f4",
    },
    "&:active": {
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0b5669" : "#f1f2f4",
    },
    ...dot(state.data.value),
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
    ...dot(),
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 6px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
    cursor: "pointer",
  }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.value) }),
};

const Documents: FC = () => {
  const { t } = useTranslation("documents");
  const { search } = useLocation();
  const dispatch = useDispatch();

  const params = queryString.parse(search);

  const typeId = useSelector((state) => state.documents.typeId);
  const status = useSelector((state) => state.documents.status);
  const searchQuery = useSelector((state) => state.documents.search);
  const settings = useSelector((state) => state.settings.carrier);

  const handleSearch = useCallback(
    (query: string) => dispatch(documents.actions.setSearch(query)),
    [dispatch]
  );

  const resetData = useCallback(() => dispatch(documents.actions.resetData()), [
    dispatch,
  ]);

  const setStatus = useCallback(
    (status: DocumentStatus | null) =>
      dispatch(documents.actions.setStatus(status)),
    [dispatch]
  );

  const setTypeId = useCallback(
    (typeId: number | null) => dispatch(documents.actions.setTypeId(typeId)),
    [dispatch]
  );

  const handleStatus = (selectedStatus: DocumentStatus) => {
    if (selectedStatus === status) {
      setStatus(null);
    } else {
      setStatus(selectedStatus);
    }
  };

  const handleType = (selectedTypeId: number | null) => {
    if (selectedTypeId === typeId) {
      setTypeId(null);
    } else {
      setTypeId(selectedTypeId);
    }
  };

  const selectedStatus = useMemo(() => {
    if (status) {
      const selected = settings?.documents.statuses.find(
        (documentStatus) => documentStatus.code === status
      );

      if (selected) {
        return {
          label: selected.name,
          value: selected.code,
        };
      }
    }

    return undefined;
  }, [settings, status]);

  const statusOptions = useMemo(() => {
    return settings?.documents.statuses.map((status) => ({
      label: status.name,
      value: status.code,
    }));
  }, [settings]);

  const selectedTypeId = useMemo(() => {
    if (typeId) {
      const selected = settings?.documents.types.find(
        (type) => type.id === typeId
      );

      if (selected) {
        return {
          label: selected.name,
          value: selected.id,
        };
      }
    }

    return undefined;
  }, [settings, typeId]);

  const groupedTypeOptions = useMemo(() => {
    if (settings && settings.documents) {
      const groups = settings.documents.groups;
      const types = settings.documents.types;

      const groupOptions = groups.map((group) => {
        const groupTypes = types.filter((type) => type.groupId === group.id);
        const typeOptions = groupTypes.map((groupType) => ({
          label: groupType.name,
          value: groupType.id,
        }));

        return {
          label: group.name,
          code: group.code,
          options: typeOptions,
        };
      });

      const prioritizedGroupOptions = [
        groupOptions.find((option) => option.code === DocumentGroupCode.CMR),
        groupOptions.find(
          (option) => option.code === DocumentGroupCode.Invoice
        ),
      ];

      prioritizedGroupOptions.forEach((prioritizedOption) => {
        if (prioritizedOption) {
          groupOptions.splice(
            groupOptions.findIndex(
              (option) => option.code === prioritizedOption.code
            ),
            1
          );
        }
      });

      return [...prioritizedGroupOptions, ...groupOptions];
    }

    return [];
  }, [settings]);

  useEffect(() => {
    return () => {
      resetData();
    };
  }, [resetData]);

  return (
    <Container fluid className="documents-container">
      <Row>
        <Header
          title={t("documents") as string}
          handleSearch={handleSearch}
          initialSearch={(params.search as string) || searchQuery}
          showCompanySelect
          appModule={AppModule.Documents}
        />
        <SubHeader
          left={
            settings && settings.documents ? (
              <>
                <div className="filters">
                  <Select
                    value={selectedStatus}
                    onChange={(option) => {
                      handleStatus(option ? option.value : null);
                    }}
                    options={statusOptions}
                    isClearable
                    styles={selectStyle}
                    placeholder={t("documentStatus")}
                  />

                  <Select
                    value={selectedTypeId}
                    onChange={(option) => {
                      handleType(option ? option.value : null);
                    }}
                    options={groupedTypeOptions}
                    isClearable
                    styles={selectStyle}
                    placeholder={t("documentType")}
                  />

                  <AuthorFilter />
                  <DeletedFilter />
                </div>

                <ListFilter>
                  <div className="document-table-filters">
                    <Select
                      value={selectedStatus}
                      onChange={(option) => {
                        handleStatus(option ? option.value : null);
                      }}
                      options={statusOptions}
                      isClearable
                      styles={selectStyle}
                      placeholder={t("documentStatus")}
                    />

                    <Select
                      value={selectedTypeId}
                      onChange={(option) => {
                        handleType(option ? option.value : null);
                      }}
                      options={groupedTypeOptions}
                      isClearable
                      styles={selectStyle}
                      placeholder={t("documentType")}
                    />

                    <AuthorFilter />
                    <DeletedFilter />
                  </div>
                </ListFilter>
              </>
            ) : (
              <div />
            )
          }
        />
      </Row>
      <Row>
        <DocumentsTable />
      </Row>
    </Container>
  );
};

export { Documents };
