import { FC } from "react";
import ContentLoader from "react-content-loader";
import useMobileScreen from "../../../hooks/mobile-screen";

const ListLoader: FC = () => {
  const isMobileScreen = useMobileScreen();

  const width = isMobileScreen ? window.innerWidth : window.innerWidth - 130;

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={1000}
      viewBox={`0 0 ${width} 1000`}
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      style={{ marginTop: "15px" }}
    >
      <rect x="0" y="0" rx="5" ry="5" width={width} height="120" />
      <rect x="0" y="125" rx="5" ry="5" width={width} height="30" />
      <rect x="0" y="175" rx="5" ry="5" width={width} height="120" />
      <rect x="0" y="300" rx="5" ry="5" width={width} height="30" />
      <rect x="0" y="350" rx="5" ry="5" width={width} height="120" />
      <rect x="0" y="475" rx="5" ry="5" width={width} height="30" />
      <rect x="0" y="525" rx="5" ry="5" width={width} height="120" />
      <rect x="0" y="650" rx="5" ry="5" width={width} height="30" />
      <rect x="0" y="700" rx="5" ry="5" width={width} height="120" />
      <rect x="0" y="825" rx="5" ry="5" width={width} height="30" />
    </ContentLoader>
  );
};

export { ListLoader };
