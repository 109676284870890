import { call, delay, put, select, takeLatest } from "typed-redux-saga";
import { adminDocumentsAPI } from "../../../../services/api/admin/documents";
import { IFetchDocumentParams } from "../../../../services/api/documents";
import { AppState } from "../../../../store";
import { showError } from "../../../alert/store/slices";
import { loader } from "../../../common/loaders/store/slices";
import { adminDocuments } from "./slices";

function* adminDocumentSagas() {
  yield* takeLatest(adminDocuments.actions.setSearch, debounceSearch);
  yield* takeLatest(adminDocuments.actions.setPerPage, fetchDocuments);
  yield* takeLatest(adminDocuments.actions.setActivePage, fetchDocuments);
}

function* debounceSearch() {
  const data = yield* select((state: AppState) => state.adminDocuments.data);

  if (data.length > 0) {
    yield delay(500);
  }

  yield* call(fetchDocuments);
}

function* fetchDocuments() {
  yield put(loader.actions.startLoader());

  const { search, perPage, page } = yield* select((state: AppState) => ({
    search: state.adminDocuments.search,
    perPage: state.adminDocuments.perPage,
    page: state.adminDocuments.activePage,
  }));

  const requestParams: IFetchDocumentParams = {
    search,
    perPage,
    page,
  };

  const respose = yield* call(adminDocumentsAPI.fetchDocuments, requestParams);

  if (respose && respose.data && respose.meta) {
    yield put(
      adminDocuments.actions.setData({
        data: respose.data,
        meta: respose.meta,
      })
    );
  } else {
    yield put(showError("Something went wrong while getting documents."));
  }

  yield put(loader.actions.stopLoader());
}

export { adminDocumentSagas };
