import { FC } from "react";
import { FaInfo } from "react-icons/fa";
import classNames from "classnames";

export enum InfoAlertStatus {
  Success,
  Error,
  Active,
  Delivered,
  Info,
  Pending,
}

interface IProps {
  status: InfoAlertStatus;
  label: string;
  message: string;
}

const InfoAlert: FC<IProps> = ({ status, label, message }) => {
  let statusClassName: string;

  switch (status) {
    case InfoAlertStatus.Success:
      statusClassName = "info-alert-success";
      break;
    case InfoAlertStatus.Error:
      statusClassName = "info-alert-error";
      break;
    case InfoAlertStatus.Active:
      statusClassName = "info-alert-active";
      break;
    case InfoAlertStatus.Delivered:
      statusClassName = "info-alert-delivered";
      break;
    case InfoAlertStatus.Info:
      statusClassName = "info-alert-info";
      break;
    case InfoAlertStatus.Pending:
      statusClassName = "info-alert-pending";
      break;
  }

  return (
    <div className={classNames("info-alert", statusClassName)}>
      <div>
        <FaInfo />
      </div>
      <div>
        <span>{label}</span>
        <span>{message}</span>
      </div>
    </div>
  );
};

export { InfoAlert };
