import queryString from "query-string";
import { API, ApiResource, HTTPMethod } from "..";
import { IDocument } from "../documents";

export interface IFetchDocumentParams {
  search?: string;
  perPage?: number;
  page?: number;
}

const fetchDocuments = async (
  requestParams: IFetchDocumentParams
): Promise<ApiResource<IDocument[]>> => {
  const params = queryString.stringify({
    ...requestParams,
  });

  const response = await API.request<ApiResource<IDocument[]>>(
    `/admin/documents?${params}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
      jsonRequest: true,
    }
  );

  return response;
};

const downloadDocument = async (
  documentId: number
): Promise<ApiResource<any>> => {
  const response = await API.request<ApiResource<any>>(
    `/admin/documents/download/${documentId}`,
    {
      method: HTTPMethod.GET,
      version: "v2",
    }
  );

  return response;
};

const adminDocumentsAPI = {
  fetchDocuments,
  downloadDocument,
};

export { adminDocumentsAPI };
