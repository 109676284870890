import { useLayoutEffect, useState } from "react";

const useMobileScreen = () => {
  const [isMobileScreen, setIsMobileScreen] = useState<boolean>(false);

  useLayoutEffect(() => {
    const updateSize = () => {
      setIsMobileScreen(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", updateSize);

    updateSize();

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return isMobileScreen;
};

export default useMobileScreen;
