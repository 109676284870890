import { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { CarrierRoute as CarrierRoutes } from "../../constants/routes";
import { authAPI } from "../../services/api/auth";
import { GlobalLoader } from "../common/loaders/GlobalLoader";
import { PrivateTemplate } from "../layout/PrivateTemplate";
import { ForceRedirect } from "./ForceRedirect";
import workspaceSwitcherSlice, {
  Workspace,
} from "../workspace-switcher/store/slice";
import useWorkspaceStatus from "../../hooks/workspace-status";

const workspace = Workspace.CarrierSpace;

interface IProps extends RouteProps {
  Component: React.FC<{}>;
  Template?: React.FC<{}>;
}

const CarrierRoute: FC<IProps> = ({ Component, Template, ...rest }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const isCarrier = useSelector((state) => state.profile.isCarrier);
  const isManager = useSelector((state) => state.profile.isManager);
  const isGuest = useSelector((state) => state.profile.isGuest);
  const translationsReady = useSelector((state) => state.translations.ready);
  const profileSynced = useSelector(
    (state) => state.translations.profileSynced
  );
  const workspaceChanged = useWorkspaceStatus(workspace);

  const changeWorkspace = useCallback(
    (workspace: Workspace) =>
      dispatch(workspaceSwitcherSlice.actions.change(workspace)),
    [dispatch]
  );

  const showGlobalLoader = useMemo(
    () =>
      authAPI.hasTokens() &&
      (!profile.data || !translationsReady || !profileSynced),
    [profile, translationsReady, profileSynced]
  );

  const forceRedirect = !!(
    profile.data &&
    (!profile.data.emailVerifiedAt ||
      profile.data.forceUpdateProfile ||
      profile.data.forceUpdatePassword)
  );

  const roleNotAuthorized =
    isGuest && rest.path
      ? !rest.path.includes(CarrierRoutes.Applications.List)
      : !isCarrier && !isManager;

  const CustomTemplate = Template ? Template : PrivateTemplate;

  useEffect(() => {
    if (workspaceChanged) {
      changeWorkspace(workspace);
    }
  }, [workspaceChanged, changeWorkspace]);

  return (
    <Route
      {...rest}
      render={() =>
        showGlobalLoader ? (
          <GlobalLoader />
        ) : forceRedirect ? (
          <ForceRedirect profile={profile} />
        ) : !profile.data || roleNotAuthorized ? (
          <Redirect to="/" />
        ) : (
          <CustomTemplate>
            <Component />
          </CustomTemplate>
        )
      }
    />
  );
};

export { CarrierRoute };
