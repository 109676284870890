import { FC, useCallback, useEffect } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { CompaniesTable } from "./table/CompaniesTable";
import queryString from "query-string";
import { Link, useLocation } from "react-router-dom";
import { Header } from "../../layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { adminCompanies } from "./store/slices";
import { SubHeader } from "../../layout/SubHeader";
import { AdminRoute } from "../../../constants/routes";

const Companies: FC = () => {
  const { search } = useLocation();
  const params = queryString.parse(search);

  const searchQuery = useSelector((state) => state.adminCompanies.search);

  const dispatch = useDispatch();

  const handleSearch = useCallback(
    (query: string) => dispatch(adminCompanies.actions.setSearch(query)),
    [dispatch]
  );

  const reset = useCallback(() => dispatch(adminCompanies.actions.reset()), [
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Container fluid>
      <Row>
        <Header
          title="Carrier companies"
          handleSearch={handleSearch}
          initialSearch={(params.search as string) || searchQuery}
          languageDisabled
        />
        <SubHeader
          left={<div />}
          right={
            <Button
              as={Link}
              to={AdminRoute.Companies.Create}
              style={{
                minWidth: "100px",
              }}
              className="assign-company-button"
            >
              Create
            </Button>
          }
        />
      </Row>
      <Row>
        <CompaniesTable />
      </Row>
    </Container>
  );
};

export { Companies };
