import { FC } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFileAlt } from "react-icons/fa";
import { IProject } from "../../../services/api/projects";

interface IProps {
  project: IProject;
  onHide: () => void;
  children: any;
}

const ProjectReviewModalMobile: FC<IProps> = ({
  project,
  onHide,
  children,
}) => {
  const { t } = useTranslation("projectReviewModal");

  return (
    <Modal
      show={true}
      onHide={onHide}
      size="xl"
      scrollable
      className="project-review-modal-mobile"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FaFileAlt /> {t("orderReview")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          textAlign: "left",
          paddingTop: "0px",
        }}
      >
        {children}
      </Modal.Body>
    </Modal>
  );
};

export { ProjectReviewModalMobile };
