import { FC, useCallback, useEffect, useMemo } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AdminRoute } from "../../../../constants/routes";
import { CompanyRole } from "../../../../services/api/profile";
import { Loader } from "../../../common/loaders/Loader";
import { Header } from "../../../layout/Header";
import { adminUsers } from "../store/slices";
import { showUserEmail } from "../utils/helpers";

interface IEditRoleForm {
  roleId: string;
}

const UserCompanyRoleEdit: FC = () => {
  const { userId, companyId } = useParams<{
    userId: string;
    companyId: string;
  }>();
  const user = useSelector((state) => state.adminUsers.userData);
  const company = useSelector((state) => state.adminUsers.company);
  const roles = useSelector((state) => state.adminUsers.roles);
  const updatedUser = useSelector((state) => state.adminUsers.updatedUser);

  const history = useHistory();

  const { register, handleSubmit, watch } = useForm<IEditRoleForm>();

  const userEmail = useMemo(() => showUserEmail(user), [user]);
  const companyName = useMemo(
    () => (company && company.name ? company.name : ""),
    [company]
  );

  let companyRole = null;

  if (user && user.companies && user.companies.length > 0) {
    const company = user.companies.find(
      (company) => company.id.toString() === companyId
    );

    if (company && company.roles && company.roles.length > 0) {
      companyRole = company.roles[0];
    }
  }

  const dispatch = useDispatch();
  const fetchRoles = useCallback(
    (userId: string, companyId: string) =>
      dispatch(adminUsers.actions.fetchCompanyRoles({ userId, companyId })),
    [dispatch]
  );
  const changeRole = useCallback(
    (userId: string, roleId: string, companyId: string) =>
      dispatch(
        adminUsers.actions.changeCompanyRole({
          userId,
          roleId,
          companyId,
        })
      ),
    [dispatch]
  );
  const resetRoles = useCallback(
    () => dispatch(adminUsers.actions.resetCompanyRoles()),
    [dispatch]
  );
  const resetUpdatedUser = useCallback(
    () => dispatch(adminUsers.actions.resetUpdatedUser()),
    [dispatch]
  );
  const resetCompany = useCallback(
    () => dispatch(adminUsers.actions.resetCompany()),
    [dispatch]
  );

  const onSubmit = (data: IEditRoleForm) => {
    changeRole(userId, data.roleId, companyId);
  };

  useEffect(() => {
    fetchRoles(userId, companyId);

    return () => {
      resetRoles();
      resetUpdatedUser();
      resetCompany();
    };
  }, [
    userId,
    companyId,
    fetchRoles,
    resetRoles,
    resetUpdatedUser,
    resetCompany,
  ]);

  useEffect(() => {
    if (updatedUser) {
      history.push(
        AdminRoute.Users.Companies.List.replace(":userId", userId).replace(
          ":companyId",
          companyId
        )
      );
    }
  }, [updatedUser, userId, companyId, history]);

  const selectedRoleId = watch("roleId");

  const selectedRole = useMemo(() => {
    if (roles) {
      const role = roles.find((role) => role.id.toString() === selectedRoleId);

      if (role) {
        return role.code;
      }
    }

    return null;
  }, [roles, selectedRoleId]);

  return (
    <Container fluid className="user-role">
      <Row>
        <Header
          title="User company role"
          subTitle={`${companyName} | ${userEmail}`}
          languageDisabled
        />
      </Row>
      <Row>
        {!user || !company || !roles.length ? (
          <Loader />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Form.Group as={Col} controlId="roleId">
                <Form.Label>Company role</Form.Label>
                <Form.Control
                  as="select"
                  name="roleId"
                  ref={register({ required: true })}
                  defaultValue={companyRole ? companyRole.id : undefined}
                >
                  {roles.map((role, index) => (
                    <option key={index} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Control>
                {(selectedRole as any) === CompanyRole.Accountant && (
                  <Form.Text className="text-danger">
                    Viewer role has read-only permissions.
                  </Form.Text>
                )}
              </Form.Group>
            </Row>

            <Button
              type="submit"
              style={{
                minWidth: "100px",
              }}
            >
              Change
            </Button>
          </Form>
        )}
      </Row>
    </Container>
  );
};

export { UserCompanyRoleEdit };
